import colorMod from 'color';
import { get } from 'lodash';

export default ({ color, typography, fontSize }) => ({
  menuButton: {
    color: `${get(color, 'headerLink')} !important`,
    fontFamily: `${get(typography, 'siteTitle')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    fontStyle: 'normal !important',
    fontWeight: '600 !important',
    margin: '0 6px 0 6px !important',
    textTransform: 'none',
    lineHeight: '40px !important',
    padding: '0 16px 0 16px !important',
    border: '1px solid !important',
    borderColor: 'rgba(0, 0, 0, 0) !important',
    borderRadius: '3px !important',
    '&:hover': {
      borderColor: `${get(color, 'headerLink')} !important`,
      backgroundColor: `${colorMod(get(color, 'headerLink')).alpha(0.1).string()} !important`,
    },
    '&:focus': {
      borderColor: `${colorMod(get(color, 'headerLink')).alpha(0.7).string()} !important`,
    },
    '&:active': {
      borderColor: `${get(color, 'headerLink')} !important`,
    },
  },
  collapsedMenuButton: {
    padding: 8,
    color: get(color, 'headerLink'),
    border: '1px solid',
    borderRadius: 3,
    borderColor: 'rgba(0, 0, 0, 0)',
    marginRight: 8,
    '&:hover': {
      color: colorMod(get(color, 'headerLink')).alpha(0.7).string(),
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&:focus': {
      borderColor: colorMod(get(color, 'headerLink')).alpha(0.7).string(),
    },
    '&:active': {
      borderColor: get(color, 'headerLink'),
    },
  },
  MenuIcon: {
    height: 24,
    width: 24,
  },
  MenuPaper: {
    borderRadius: 3,
    boxShadow: 'none',
    filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))',
    marginTop: 4,
  },
  MenuList: {
    margin: '0 0 0 0 !important',
    padding: '4px 0 12px 0 !important',
    maxHeight: 500,
    minWidth: '192 !important',
    overflowY: 'auto',
    '&:focus': {
      outline: 'none',
    },
  },
  MenuItems: {
    borderLeft: '3px solid !important',
    borderColor: 'rgba(0, 0, 0, 0) !important',
    color: `${get(color, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
    justifyContent: 'space-between !important',
    lineHeight: '36px !important',
    padding: '0 12px 0 12px !important',
    textDecoration: 'none !important',
    '&:hover': {
      backgroundColor: `${colorMod(get(color, 'headerLink')).alpha(0.08).string()} !important`,
    },
    '&:focus': {
      backgroundColor: `${colorMod(get(color, 'headerLink')).alpha(0.08).string()} !important`,
      borderColor: `${get(color, 'headerLink')} !important`,
      outline: 'none !important',
    },
  },
  BackBtn: {
    justifyContent: 'left !important',
    paddingLeft: '0 !important',
  },
  arrowLeft: {
    fill: `${get(color, 'headerLink')} !important`,
  },
  arrowRight: {
    fill: `${get(color, 'headerLink')} !important`,
  },
});
