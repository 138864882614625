export default ({ color, fontSize, typography }) => ({
  chartHeader: {
    borderBottom: `1px solid ${color.borderColor}`,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 36px',
    paddingBottom: 8,
  },
  chartTitle: {
    color: color.pageTitle,
    fontFamily: typography.pageTitle,
    fontSize: fontSize.sectionTitle,
  },
  titleRight: {
    display: 'flex',
  },
  dateSpan: {
    color: color.pageTitle,
    fontFamily: typography.pageTitle,
    fontSize: fontSize.smallTitle,
    lineHeight: 1.6,
    marginTop: 4,
  },
  chartPanel: {
    marginTop: 36,
    marginBottom: 11,
  },
  fadeInPanel__show: {
    transition: 'all 0.5s ease-in',
    opacity: 1,
    borderBottom: `1px solid ${color.borderColor}`,
  },
  fadeInPanel__hide: {
    height: 0,
    overflow: 'hidden',
    transition: 'all 0.5s ease-in',
    opacity: 0,
  },
  chart: {
    paddingBottom: 24,
  },
});
