import modifyColour from '../../../utils/modifyColour';
import { get } from 'lodash';

export default ({ color, fontSize, misc, typography }) => ({
  Menu: {
    fontFamily: get(typography, 'bodyText'),
    width: 250,
    padding: '10px 0 8px',
  },

  UserMenu: {
    zIndex: 100,
  },

  UserMenuButton: {
    display: 'none',
    color: `${get(color, 'headerLink')} !important`,
    fontFamily: get(typography, 'bodyText'),
    '& span': {
      verticalAlign: 'middle',
    },
    '& button': {
      color: `${get(color, 'headerLink')} !important`,
    },
  },

  UserMenuContent_Padded: {
    padding: '5px 20px',
  },

  UserMenuContent__Item: {
    '& button, & a': {
      color: get(color, 'link'),
      display: 'inline-block',
      transition: get(misc, 'colorTransition'),
      textTransform: 'capitalize',

      '& span': {
        fontSize: get(fontSize, 'bodyText'),
        fontFamily: get(typography, 'bodyText'),
        fontWeight: 'normal',
        marginLeft: 0,
        textDecoration: 'none',
      },

      '&:hover': {
        color: modifyColour(get(color, 'link'), 'lighten', 0.4),
        backgroundColor: `${get(color, 'white')} !important`,
      },

      '&:focus': {
        boxShadow: 'none',
        outline: 'none',
      },
    },
  },

  UserMenuContent__AccessibleOrgWrapper: {
    display: 'flex',
    margin: '2px 0',
    justifyContent: 'space-between',
    width: '100%',
  },

  UserMenuContent__AccessibleOrgs: {
    backgroundColor: 'transparent !important',
    border: 'none',
    color: get(color, 'link'),
    fontSize: get(fontSize, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    paddingLeft: 0,
    textAlign: 'left',
    cursor: 'pointer',
    outline: 0,
    textTransform: 'none',
  },

  UserMenuContent__Check: {
    color: `${get(color, 'link')} !important`,
    margin: '0.5em',
    fontSize: get(fontSize, 'bodyText'),
  },

  UserMenuContent__ErrorMessage: {
    padding: '10px 0',
    width: '100%',

    backgroundColor: get(color, 'error'),
    color: get(color, 'white'),
    fontFamily: get(typography, 'bodyText'),
    margin: '8px 0',
    textAlign: 'center',
  },

  UserMenuContent_OrgsWrapper: {
    overflowY: 'auto',
    maxHeight: 200,
    margin: 8,
  },

  [`@media (min-width: ${get(misc, 'breakpointMedium')}px)`]: {
    UserMenuButton: {
      display: 'inline-block',
    },
  },

  SubHeader: {
    marginBottom: 10,
    color: get(color, 'gray'),
    fontSize: get(fontSize, 'smallText'),
  },

  SubHeaderUppercase: {
    textTransform: 'uppercase',
  },

  user: {
    cursor: 'pointer',
  },
});
