import React, { Fragment, useState, useEffect, useRef } from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { get, findIndex, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { getUserDetails, getUseLegacyApiPages } from '../../../reducers/portalConfig';
import {
  fetchAvailableUsers,
  fetchSelectedUsers,
  fetchApi,
  fetchApiPermitted,
  fetchOrganizations,
  fetchSelectedOrganizations,
  fetchAllTags,
  fetchAPITags,
  fetchSwaggerFile,
  saveApiAccessStatus,
  showLoading,
  savePermissions,
  saveTagsAssociation,
  createTags,
  fetchApiEulas,
  checkApiNameUnique,
  checkProxyUrlUnique,
  saveApi,
  updateApi,
  updateApiDetails,
  updateApiDetailsWithAssets,
  fetchAssets,
  fetchUsage,
  fetchCustomFields,
  saveApiCustomFields,
  fetchPolicyTemplates,
  saveApiPolicyEntities,
  publishApi,
  fetchAllOrganizations,
  fetchSelectedManageOrg,
  fetchRateLimitQuotas,
  fetchApiRateLimitQuota,
  fetchApiOrgAccess,
  addApiRateLimitQuota,
  removeApiRateLimitQuota,
  addApiVisibility,
  removeApiVisibility,
} from '../../../actions/api';
import {
  hasPublisherRole,
  hasAdminRole,
  hasOrgPublisherRole,
} from '../../../utils';
import {
  getApiPortalStatus,
  getApiCanEditPermitted,
  getOrganizations,
  getSelectedOrganizations,
  getApiAccessStatus,
  getOrganizationsPage,
  getAvailableUsers,
  getSelectedUsers,
  getDefaultSelectedUsers,
  getAllPortalTags,
  getAPITags,
  getAPISwaggerData,
  getErrors,
  getIsError,
  getIsLoading,
  getNewTags,
  getEulas,
  getIsApiNameUnique,
  getManagedGateways,
  getIsProxyUrlUnique,
  getApiDetails,
  getIsSaveApiSuccess,
  getPolicyTemplates,
  getPublisherOrganizations,
  getSelectedManageOrg,
  getAssets,
  getUsage,
  getCustomFields,
  getRateLimitQuotas,
  getRateLimitQuotasPage,
  getOrgsAccessListResults,
  getOrgsAccessListTotalElements,
  getOrgsAccessListTotalPages,
  getIsOrgAccessSaveSuccess,
} from '../../../reducers/api';
import {
  TabsContainer,
  TabPanel,
} from '../../../components';
import EditContainer from '../../edit';
import Tabs from './tabs';
import {
  HELPITEMS,
  UNSAVED_DIALOG_SUBMIT_TEXT,
  UNSAVED_DIALOG_EXIT_TEXT,
  API_DETAILS_UPDATE_SUCCESS,
  API_CUSTOM_FIELDS_UPDATE_SUCCESS,
  SPEC_AUTHENTICATION_SAVE_SUCCESS,
  MANAGEMENT_SAVE_SUCCESS,
  TAGS_ASSOCIATION_SAVE_SUCCESS,
  ERROR_TITLE_TEXT,
  API_DETAILS_SAVE_SUCCESS,
  ID_REST,
  REST_TYPE,
  SUCCESS_TEXT,
  ERROR_TEXT,
  POLICY_TEMPLATE_SAVE_SUCCESS,
} from './labels';
import styles from './styles';

const PAGE_SIZE = '10';

const getApiUuid = (props) => get(props, 'match.path')
  && (
    get(props, 'match.path').includes('/edit/')
    || get(props, 'match.path').includes('/permissions/')
  )
  && get(props, 'match.params.apiUuid');

const getTabs = (tabProps) => Tabs
  .filter(({ isHidden }) => !(isHidden && isHidden(tabProps)))
  .map(tab => ({
    ...tab,
    disabled: tab.isDisabled && tab.isDisabled(tabProps),
  }));

export const getDefaultSelectedTabIndex = (tabs, match) => {
  const tabName = get(match, 'params.tabName');
  const index = findIndex(tabs, tab => (tab.id === tabName));
  // if none found default to 0
  return (index > 0 ? index : 0);
};

export function ApiEdit(props) {
  const {
    userContext = {},
    canEdit,
    match,
    organizationsPage,
    isError,
    isLoading,
    apiErrors,
    customFields,
    apiDetails,
    assets,
    isApiSaveSuccess,
    useLegacyApiPages,
    rateLimitQuotasPage,
    rateLimitQuotas,
    isOrgAccessSaveSuccess,
  } = props;
  const apiUuid = getApiUuid(props) || '';

  const headerRef = useRef(null);

  const getHelpItems = (id) => {
    let helpItems = { title: '', descriptions: '', subItems: [] };
    switch (id) {
      case 'details-tab':
        helpItems = HELPITEMS.detailsHelp;
        break;
      case 'custom-fields-tab':
        helpItems = HELPITEMS.customFieldsHelp;
        break;
      case 'policy-templates-tab':
        helpItems = HELPITEMS.policyTemplatesHelp;
        break;
      case 'spec-authentication-tab':
        helpItems = HELPITEMS.specAuthenticationHelp;
        break;
      case 'management-tab': {
        helpItems = HELPITEMS.permissionsHelp;
        if (hasAdminRole(userContext)) {
          helpItems.subItems = helpItems.subItems.filter(item => item.id !== 1);
        }
        break;
      }
      case 'tags-tab':
        helpItems = HELPITEMS.tagsHelp;
        break;
      default:
    }
    return helpItems;
  };

  const getHeaderTitle = () => {
    if (apiUuid) {
      return `Edit API: ${apiDetails.name}`;
    }
    return 'Add API';
  };

  const [apiType, setApiType] = useState(ID_REST);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCancelButton, setIsCancelButton] = useState(false);
  const [dialogSubmitText, setDialogSubmitText] = useState(UNSAVED_DIALOG_EXIT_TEXT);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [isLoadRateLimitQuotaMore, setIsLoadRateLimitQuotaMore] = useState(false);
  const [errors, setErrors] = useState([]);
  const [tabs, setTabs] = useState(getTabs({
    userContext, apiUuid, apiType, customFields, apiDetails, useLegacyApiPages }));
  const defaultIndex = getDefaultSelectedTabIndex(tabs, match);
  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const [currentTab, setCurrentTab] = useState(tabs[defaultIndex].tabId);
  const [redirectTabValue, setRedirectTabValue] = useState(tabs[defaultIndex].tabId);
  const [helpItems, setHelpItems] = useState(getHelpItems(tabs[defaultIndex].tabId));
  const [headerTitle, setHeaderTitle] = useState(getHeaderTitle());

  const fetchInit = async () => {
    localStorage.setItem('isSaveApi', false);
    localStorage.setItem('isUnSavedChanges', false);
    await props.fetchCustomFields();
    await props.fetchApiEulas();
    await props.fetchPolicyTemplates();
    await props.fetchAllTags();
  };

  const fetchApiData = async () => {
    await props.fetchApi(apiUuid);
    await props.fetchAssets(apiUuid);
    await props.fetchUsage(apiUuid);
    await props.fetchApiPermitted(apiUuid);
    await props.fetchApiOrgAccess({ apiUuid, filterByName: '',
      page:0, rowsPerPage: 12 });
    await props.fetchAPITags(apiUuid);
    if (hasPublisherRole(userContext) || hasOrgPublisherRole(userContext)) {
      await props.fetchOrganizations(PAGE_SIZE, 0);
    }
  };

  useEffect(() => {
    if (!apiUuid && userContext.permissions && userContext.permissions.API
      && !userContext.permissions.API.includes('CREATE')) {
      props.push('/404');
    }
  }, [userContext]);

  useEffect(() => {
    fetchInit();
    if (apiUuid) {
      if (hasOrgPublisherRole(userContext) && tabs[tabIndex].id === 'management') {
        props.push('/404');
      }
      fetchApiData();
    }
  }, [apiUuid]);

  useEffect(() => {
    if (apiUuid && (!canEdit
      || (userContext.permissions.API
        && !userContext.permissions.API.includes('UPDATE')))) {
        props.push('/404');
    }
  }, [canEdit]);

  useEffect(() => {
    setHeaderTitle(getHeaderTitle());
  }, [apiDetails]);

  useEffect(() => {
    const filterTabs = getTabs({
      userContext, apiUuid, apiType, customFields, apiDetails, useLegacyApiPages,
    });
    setTabs(filterTabs);
    setTabIndex(getDefaultSelectedTabIndex(filterTabs, match));
  }, [apiUuid, apiType, customFields, apiDetails]);
  useEffect(() => {
    const { id, tabId } = tabs[tabIndex];
    setCurrentTab(tabId);
    setHelpItems(getHelpItems(tabId));

    if (id === 'tags' && apiDetails.apiServiceType === REST_TYPE) {
      props.fetchSwaggerFile(apiUuid);
    }
  }, [tabIndex, tabs]);

  useEffect(() => {
    setIsLoadMore(organizationsPage.currentPage < (organizationsPage.totalPages - 1));
  }, [organizationsPage]);

  useEffect(() => {
    setIsLoadRateLimitQuotaMore(rateLimitQuotasPage.currentPage
      < (rateLimitQuotasPage.totalPages - 1));
  }, [rateLimitQuotasPage]);

  const onLoadRateLimitQuotaMore = () => {
    const currentPage = rateLimitQuotasPage.currentPage + 1;
    props.fetchRateLimitQuotas(PAGE_SIZE, currentPage);
  };

  const notifyMessages = (message, status) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  const setStateTabs = (value) => {
    setTabIndex(tabs.findIndex(({ tabId }) => (tabId === value)));
  };

  useEffect(() => {
    const defaultIndex = getDefaultSelectedTabIndex(tabs, match);
    const tabName = get(match, 'params.tabName');
    if(tabName === 'tags') {
      notifyMessages('', '');
    }
    setTabIndex(defaultIndex);
  },[match]);

  useEffect(() => {
    if (isApiSaveSuccess) {
      setErrors([]);
      switch (currentTab) {
        case 'details-tab':
          if (apiDetails.uuid) {
            props.fetchApi(apiDetails.uuid);
            props.fetchAssets(apiDetails.uuid);
            props.fetchUsage(apiDetails.uuid);
          }
          if (!isEmpty(customFields) && apiDetails.publishedByPortal) {
            setStateTabs('custom-fields-tab');
            props.push(`/publish/apis/edit/${apiDetails.uuid}/custom-fields`);
          } else if (apiDetails.publishedByPortal === false) {
            if (hasPublisherRole(userContext)) {
              setStateTabs('management-tab');
              props.push(`/publish/apis/edit/${apiDetails.uuid}/management`);
            } else {
              setStateTabs('tags');
              props.push(`/publish/apis/edit/${apiDetails.uuid}/tags`);
            }
          } else {
            setStateTabs('policy-templates-tab');
            props.push(`/publish/apis/edit/${apiDetails.uuid}/policy-templates`);
          }
          notifyMessages(
            apiUuid ? API_DETAILS_UPDATE_SUCCESS : API_DETAILS_SAVE_SUCCESS,
            SUCCESS_TEXT,
          );
          window.scrollTo(0, headerRef.offsetTop);
          break;
        case 'custom-fields-tab':
          setStateTabs('policy-templates-tab');
          props.push(`/publish/apis/edit/${apiDetails.uuid}/policy-templates`);
          notifyMessages(API_CUSTOM_FIELDS_UPDATE_SUCCESS, SUCCESS_TEXT);
          window.scrollTo(0, headerRef.offsetTop);
          break;
        case 'policy-templates-tab':
          props.fetchApi(apiDetails.uuid); // TODO: Re-fetch the API in store
          if (apiDetails.apiServiceType === REST_TYPE) {
            setStateTabs('spec-authentication-tab');
            props.push(`/publish/apis/edit/${apiDetails.uuid}/spec-authentication`);
          } else if (hasPublisherRole(userContext)) {
            setStateTabs('management-tab');
            props.push(`/publish/apis/edit/${apiDetails.uuid}/management`);
          } else {
            setStateTabs('tags-tab');
            props.push(`/publish/apis/edit/${apiDetails.uuid}/tags`);
          }
          notifyMessages(POLICY_TEMPLATE_SAVE_SUCCESS, SUCCESS_TEXT);
          window.scrollTo(0, headerRef.offsetTop);
          break;
        case 'spec-authentication-tab':
          props.fetchApi(apiDetails.uuid);
          if (hasPublisherRole(userContext)) {
            setStateTabs('management-tab');
            props.push(`/publish/apis/edit/${apiDetails.uuid}/management`);
          } else {
            setStateTabs('tags-tab');
            props.push(`/publish/apis/edit/${apiDetails.uuid}/tags`);
          }
          notifyMessages(SPEC_AUTHENTICATION_SAVE_SUCCESS, SUCCESS_TEXT);
          window.scrollTo(0, headerRef.offsetTop);
          break;
        case 'management-tab':
          props.fetchApi(apiDetails.uuid);
          setStateTabs('tags-tab');
          props.push(`/publish/apis/edit/${apiDetails.uuid}/tags`);
          notifyMessages(MANAGEMENT_SAVE_SUCCESS, SUCCESS_TEXT);
          window.scrollTo(0, headerRef.offsetTop);
          break;
        case 'tags-tab':
          localStorage.setItem('isSaveApi', true);
          window.location.href = `/publish/apis/details/${apiDetails.uuid}`;
          notifyMessages(TAGS_ASSOCIATION_SAVE_SUCCESS, SUCCESS_TEXT);
          window.scrollTo(0, headerRef.offsetTop);
          break;
        default:
          break;
      }
    }
  }, [isApiSaveSuccess]);

  useEffect(() => {
    if (isError) {
      notifyMessages(ERROR_TITLE_TEXT, ERROR_TEXT);
      if (apiErrors.length > 0) {
        setErrors(apiErrors);
      }
    }
  }, [isError, apiErrors]);

  useEffect(() => {
    if (isOrgAccessSaveSuccess) {
      notifyMessages('', '');
      setErrors([]);
    }
  }, [isOrgAccessSaveSuccess]);

  const onLoadMore = () => {
    const currentPage = organizationsPage.currentPage + 1;
    props.fetchOrganizations(PAGE_SIZE, currentPage);
  };

  const redirectTab = (value) => {
    setStateTabs(value);
    notifyMessages('', '');
    setErrors([]);
    const tIndex = tabs.findIndex(({ tabId }) => (tabId === value));
    const { id } = tabs[tIndex];
    props.push(`/publish/apis/edit/${apiDetails.uuid}/${id}`);
  };

  const onDialogClose = () => {
    setIsDialogOpen(false);
    setDialogSubmitText(UNSAVED_DIALOG_EXIT_TEXT);
  };

  const onDialogSubmit = () => {
    localStorage.setItem('isUnSavedChanges', false);
    setDialogSubmitText(UNSAVED_DIALOG_EXIT_TEXT);
    if (isCancelButton) {
      setIsCancelButton(false);
      window.location.href = (tabs[tabIndex].id === 'details') ? '/publish/apis'
        : `/publish/apis/details/${apiUuid}`;
    } else {
      setIsDialogOpen(false);
      redirectTab(redirectTabValue);
    }
  };

  const onCancel = (isUnsavedChanges) => {
    if (isUnsavedChanges) {
      setIsDialogOpen(true);
      setIsCancelButton(true);
    } else {
      window.location.href = `/publish/apis/details/${apiUuid}`;
    }
  };

  const onCancelDetails = (isUnsavedChanges) => {
    if (isUnsavedChanges) {
      setIsDialogOpen(true);
      setIsCancelButton(true);
    } else {
      window.location.href = '/publish/apis/';
    }
  };

  const handleTabChange = (e, value) => {
    if (currentTab === value) { return; }
    const hasUnsavedChanges = localStorage.getItem('isUnSavedChanges');
    if (hasUnsavedChanges === 'true') {
      setIsCancelButton(false);
      setDialogSubmitText(UNSAVED_DIALOG_SUBMIT_TEXT);
      setIsDialogOpen(true);
      setRedirectTabValue(value);
    } else {
      redirectTab(value);
    }
  };

  return (
    <EditContainer
      editPageId="api-edit-page"
      dialogId="api-unsaved-dialog"
      isDialogOpen={isDialogOpen}
      onDialogClose={onDialogClose}
      dialogSubmitText={dialogSubmitText}
      onDialogSubmit={onDialogSubmit}
      isLoading={isLoading}
      leftSidebarId="api-left-sidebar"
      mainContentId="api-main-content"
      notificationId="api-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationMessage}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      tabsContainer={(
        <TabsContainer
          useTabId
          tabValue={currentTab}
          tabItems={tabs}
          handleTabChange={handleTabChange}
        />
      )}
      pageHeaderRef={headerRef}
      pageHeaderTitle={headerTitle}
      pageContent={
        <Fragment>
          {tabs.map(
            ({ tabId, tabPanel }) => (
              <TabPanel
                id={`${tabId}-panel`}
                visible={currentTab === tabId}
                key={tabId}
              >
                {
                  tabPanel({
                    ...props,
                    apiUuid,
                    apiDetails,
                    assets,
                    rateLimitQuotas,
                    tabValue: currentTab,
                    isLoadMore,
                    isLoadRateLimitQuotaMore,
                    errors,
                    onLoadMore,
                    onLoadRateLimitQuotaMore,
                    onCancel,
                    apiType,
                    setApiType,
                    onCancelDetails,
                    notifyMessages,
                    visible: (currentTab === tabId),
                    onTabChange: handleTabChange,
                  })
                }
              </TabPanel>
            ),
          )}
        </Fragment>
      }
      rightSidebarId="permissions-right-sidebar"
      helpItems={helpItems}
    />
  );
}

const mapStateToProps = state => ({
  userContext: getUserDetails(state),
  apiPortalStatus: getApiPortalStatus(state),
  canEdit: getApiCanEditPermitted(state),
  organizations: getOrganizations(state),
  selectedOrganizations: getSelectedOrganizations(state),
  apiAccessStatus: getApiAccessStatus(state),
  organizationsPage: getOrganizationsPage(state),
  users: getAvailableUsers(state),
  selectedUsers: getSelectedUsers(state),
  defaultSelectedUsers: getDefaultSelectedUsers(state),
  allPortalTags: getAllPortalTags(state),
  apiTags: getAPITags(state),
  newTags: getNewTags(state),
  swaggerData: getAPISwaggerData(state),
  apiErrors: getErrors(state),
  isError: getIsError(state),
  isLoading: getIsLoading(state),
  assets: getAssets(state),
  usage: getUsage(state),
  customFields: getCustomFields(state),
  eulas: getEulas(state),
  isApiNameUnique: getIsApiNameUnique(state),
  managedGateways: getManagedGateways(state),
  isProxyUrlUnique: getIsProxyUrlUnique(state),
  apiDetails: getApiDetails(state),
  isApiSaveSuccess: getIsSaveApiSuccess(state),
  policyTemplates: getPolicyTemplates(state),
  publisherOrgs: getPublisherOrganizations(state),
  defaultSelectedManageOrg: getSelectedManageOrg(state),
  useLegacyApiPages: getUseLegacyApiPages(state),
  rateLimitQuotas: getRateLimitQuotas(state),
  rateLimitQuotasPage: getRateLimitQuotasPage(state),
  orgsAccessList: getOrgsAccessListResults(state),
  orgsAccessListTotalElements: getOrgsAccessListTotalElements(state),
  orgsAccessListPage: getOrgsAccessListTotalPages(state),
  isOrgAccessSaveSuccess: getIsOrgAccessSaveSuccess(state),
});

const mapDispatchToProps = {
  fetchAvailableUsers,
  fetchApi,
  push,
  fetchSelectedUsers,
  fetchApiPermitted,
  fetchOrganizations,
  fetchSelectedOrganizations,
  fetchAllTags,
  fetchAPITags,
  fetchSwaggerFile,
  showLoading,
  saveApiAccessStatus,
  savePermissions,
  saveTagsAssociation,
  createTags,
  fetchApiEulas,
  checkApiNameUnique,
  checkProxyUrlUnique,
  saveApi,
  updateApi,
  updateApiDetails,
  updateApiDetailsWithAssets,
  fetchAssets,
  fetchUsage,
  fetchCustomFields,
  saveApiCustomFields,
  fetchPolicyTemplates,
  saveApiPolicyEntities,
  publishApi,
  fetchAllOrganizations,
  fetchSelectedManageOrg,
  fetchRateLimitQuotas,
  fetchApiRateLimitQuota,
  fetchApiOrgAccess,
  addApiRateLimitQuota,
  removeApiRateLimitQuota,
  addApiVisibility,
  removeApiVisibility,
};

ApiEdit.propTypes = {
  userContext: PropTypes.shape({
    orgAdmin: PropTypes.bool,
    developer: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    uuid: PropTypes.string,
  }),
  organizationsPage: PropTypes.shape({
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  isError: PropTypes.bool,
  apiErrors: PropTypes.arrayOf(PropTypes.object),
  assets: PropTypes.arrayOf(PropTypes.object),
  customFields: PropTypes.arrayOf(PropTypes.object),
  rateLimitQuotas: PropTypes.arrayOf(PropTypes.object),
  rateLimitQuotasPage: PropTypes.shape({
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  orgsAccessList: PropTypes.arrayOf(PropTypes.object),
  orgsAccessListPage: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      tabId: PropTypes.string,
      apiUuid: PropTypes.string,
    }),
  }),
  isLoading: PropTypes.bool,
  apiDetails: PropTypes.object,
  isApiSaveSuccess: PropTypes.bool,
  canEdit: PropTypes.bool,
  useLegacyApiPages: PropTypes.bool,
  push: PropTypes.func,
  fetchCustomFields: PropTypes.func,
  fetchApiEulas: PropTypes.func,
  fetchPolicyTemplates: PropTypes.func,
  fetchAllTags: PropTypes.func,
  fetchApi: PropTypes.func,
  fetchAssets: PropTypes.func,
  fetchUsage: PropTypes.func,
  fetchApiPermitted: PropTypes.func,
  fetchAPITags: PropTypes.func,
  fetchOrganizations: PropTypes.func,
  fetchSwaggerFile: PropTypes.func,
  fetchRateLimitQuotas: PropTypes.func,
  fetchApiOrgAccess: PropTypes.func,
  isOrgAccessSaveSuccess: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ApiEdit);
