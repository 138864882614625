import React, { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import copy from 'copy-to-clipboard';
import { get, some } from 'lodash';
import PropTypes from 'prop-types';
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Button,
  Checkbox,
  Typography,
  Grid,
  withStyles,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';

import {
  FormTextField,
  FormButtonGroup,
} from '../../../../../components';
import styles from './styles';
import { hasError, uuidv4 } from '../../../../../utils';
import { getI18n, getI18nFormattedMessage } from '../../../../../utils/intl';
import {
  API_KEY_STATUS_ENABLED,
  API_KEY_STATUS_DISABLED,
  APPLICATION_APP_KEY_SECRET_DEFAULT_HASH_FROM_DB,
  ALERT_SUCCESS,
} from '../../../../../constants';

const keyStatusOptions = [
  {
    key: API_KEY_STATUS_ENABLED,
    label: getI18nFormattedMessage('label.application.key.state.enabled'),
  },
  {
    key: API_KEY_STATUS_DISABLED,
    label: getI18nFormattedMessage('label.application.key.state.disabled'),
  },
];

export const isStatusFieldDisabled =
  ({ isDefault, keyIsDefault }) => (isDefault || keyIsDefault);

export const isDefaultKeyFieldDisabled =
  ({ editable, isDefault, status, keyStatus }) => (
    some([
      !editable,
      isDefault,
      status === API_KEY_STATUS_DISABLED, // Status persisted is Disabled
      keyStatus === API_KEY_STATUS_DISABLED, // Status on the form is Disabled
    ])
  );

const Key = (props) => {
  const {
    idx,
    editable = true,
    applicationUuid,
    details: {
      name = '',
      status = '',
      isDefault = false,
      callbackUrl = '',
      scope = '',
      type = null,
      clientId = '',
      clientSecret = '',
      isHashed = '',
      isRegenerateSecret = false,
      isCreatedKey,
    },
    isUniqueKey,
    notifyMessages,
    appSecretHashingMetadata,
    closeAction,
    classes,
  } = props;

  const intl = getI18n(useIntl());
  const [keyName, setKeyName] = useState(name);
  const [keyStatus, setKeyStatus] = useState(status);
  const [keyIsDefault, setKeyIsDefault] = useState(isDefault);
  const [keyOAuthCallbackUrl, setKeyOAuthCallbackUrl] = useState(callbackUrl);
  const [keyOAuthScope, setKeyOAuthScope] = useState(scope);
  const [keyOAuthType, setKeyOAuthType] = useState('PUBLIC');
  const [keyClientSecret, setKeyClientSecret] = useState(clientSecret);
  const [keyIsHashed, setKeyIsHashed] = useState(isHashed);
  const [keyErrors, setKeyErrors] = useState({});
  const [keyRegenerateSecret, setRegenerateSecret] = useState(isRegenerateSecret);

  const isSecretCopyButtonVisible =
    (keyClientSecret !== APPLICATION_APP_KEY_SECRET_DEFAULT_HASH_FROM_DB);

  useEffect(() => { setKeyName(name); }, [name]);
  useEffect(() => { setKeyStatus(status); }, [status]);
  useEffect(() => { setKeyIsDefault(isDefault); }, [isDefault]);
  useEffect(() => { setKeyOAuthCallbackUrl(callbackUrl); }, [callbackUrl]);
  useEffect(() => { setKeyOAuthScope(scope); }, [scope]);
  useEffect(() => { type ? setKeyOAuthType(type) : '' }, [type]);
  useEffect(() => { setKeyClientSecret(clientSecret); }, [clientSecret]);
  useEffect(() => { setKeyIsHashed(isHashed); }, [isHashed]);
  useEffect(() => { setRegenerateSecret(isRegenerateSecret); }, [isRegenerateSecret]);

  useEffect(() => {
    setKeyErrors({
      ...keyErrors,
      name: (isUniqueKey === false),
    });
  }, [isUniqueKey]);

  useEffect(() => {
    if (keyName === name &&
        keyIsDefault === isDefault &&
        keyOAuthCallbackUrl === callbackUrl &&
        keyOAuthScope === scope &&
        keyOAuthType === type &&
        !keyRegenerateSecret
      ) {
      props.setHasUnsavedChanges(false);
    } else {
      props.setHasUnsavedChanges(true);
    }
  }, [
    keyName, keyIsDefault, keyOAuthCallbackUrl, keyOAuthScope,
    keyOAuthType, keyClientSecret, keyRegenerateSecret,
  ]);

  const generateSecret = () => {
    const newSecret = uuidv4();
    setKeyClientSecret(newSecret.replace(/-/g, ''));
    setRegenerateSecret(true);
  };

  const checkErrors = (obj) => Object.keys(obj).filter(key => obj[key]).length > 0;

  const saveKey = () => {
    let hasErrors = checkErrors(keyErrors);
    let errors = { ...keyErrors };

    if (keyName === '') {
      errors = { ...errors, name: true };
      hasErrors = true;
    } else if (keyName.length > 255) {
      errors = { ...errors, name: true };
      hasErrors = true;
    }

    if (hasErrors) {
      setKeyErrors(errors);
    } else {
      const keyData = {
        appUuid: applicationUuid,
        name: keyName,
        defaultKey: keyIsDefault,
        oauthCallbackUrl: keyOAuthCallbackUrl,
        oauthScope: keyOAuthScope,
        oauthType: keyOAuthType,
        keySecretHashed: keyIsHashed === 'HASHED_SECRET',
      };

      if (clientId) {
        let data = {
          ...keyData,
          status: keyStatus,
          apiKey: clientId,
        };

        if (keyRegenerateSecret) {
          data = {
            ...data,
            keySecret: keyClientSecret,
          };
        }
        setRegenerateSecret(false);
        props.updateKey(data);
      } else {
        const data = {
          ...keyData,
        };
        props.createKey(data);
      }
    }
  };

  const handleChange = async (fieldName, fieldValue) => {
    switch (fieldName) {
      case 'name':
        setKeyName(fieldValue);
        setKeyErrors({ ...keyErrors, name: hasError(fieldValue, true) });
        await props.fetchKeyNameUnique(fieldValue, clientId, applicationUuid);
        break;
      case 'status':
        setKeyStatus(fieldValue);
        break;
      case 'callbackUrl':
        setKeyOAuthCallbackUrl(fieldValue);
        break;
      case 'scope':
        setKeyOAuthScope(fieldValue);
        break;
      case 'type':
        setKeyOAuthType(fieldValue);
        break;
      case 'isDefault':
        setKeyIsDefault(fieldValue);
        break;
      case 'isHashed':
        setKeyIsHashed(fieldValue);
        setKeyErrors({ ...keyErrors, isHashed: hasError(fieldValue, true) });
        break;
      default:
    }
  };

  const displayHashingOptions = get(appSecretHashingMetadata, 'plaintextAllowed');
  const secretOptions = [
    {
      id: 0,
      key: 'HASHED_SECRET',
      name: 'Hashed',
    },
    {
      id: 1,
      key: 'PLAIN_SECRET',
      name: 'Plaintext',
    },
  ];

  useEffect(() => {
    if (!displayHashingOptions) {
      if (get(appSecretHashingMetadata, 'algorithm') && !get(appSecretHashingMetadata, 'plaintextAllowed')) {
        setKeyIsHashed('HASHED_SECRET');
      } else {
        setKeyIsHashed('PLAIN_SECRET');
      }
    } else {
      setKeyIsHashed('HASHED_SECRET');
    }
  }, [appSecretHashingMetadata]);

  const showCopied = (value, valueType) => {
    let strType = '';
    if (valueType === 'API_KEY') {
      strType = 'API Key';
    } else if (valueType === 'SECRET') {
      strType = 'Secret';
    }
    notifyMessages(`${strType}: ${value} was copied to the clipboard.`, ALERT_SUCCESS);
    setTimeout(() => {
      notifyMessages('', '');
    }, 3000);
  };

  return (
    <Fragment>
      <FormTextField
        id={`key-name-${idx}`}
        name={intl.getI18nMessage('label.application.key.name')}
        value={keyName}
        helperText={intl.getI18nMessage('label.application.key.name.help')}
        handleChange={(value) => { handleChange('name', value); }}
        error={keyErrors.name || (isUniqueKey === false)}
        maxLength="255"
        disabled={!editable}
      />
      <FormControlLabel
        classes={{
          root: classes.defaultCheckboxContainer,
        }}
        control={
          <Checkbox
            classes={{ root: classes.iconRoot }}
            defaultChecked={isDefault}
            disabled={isDefaultKeyFieldDisabled({ editable, isDefault, status, keyStatus })}
            size="small"
            checked={keyIsDefault}
            onChange={(event) => { handleChange('isDefault', event.target.checked); }}
          />
        }
        label={intl.getI18nMessage('label.application.key.default')}
      />
      <FormHelperText classes={{ root: classes.checkboxHelpText }}>
        {intl.getI18nMessage('label.application.key.default.help')}
      </FormHelperText>
      {(isDefault !== keyIsDefault) &&
        <FormHelperText classes={{ root: classes.defaultKeyWarningText }}>
          {'Devs will be able to edit this key'}
        </FormHelperText>
      }
      {!editable ?
        <Fragment>
          <Typography classes={{ root: classes.sectionTitle }} gutterBottom>
            {'Disabled'}
          </Typography>
          <FormHelperText classes={{ root: classes.helperText }}>
            {'This key is disabled because the application is pending approval or disabled.'}
          </FormHelperText>
        </Fragment> :
        <FormControl component="fieldset" className={classes.statusField}>
          <FormLabel component="legend" focused={false}>
            {'Status'}
          </FormLabel>
          <RadioGroup
            value={keyStatus}
            onChange={(e) => setKeyStatus(e.target.value)}
          >
            {keyStatusOptions.map((item) => (
              <Fragment key={item.key}>
                <FormControlLabel
                  control={
                    <Radio
                      classes={{ root: classes.iconRoot }}
                      disabled={isStatusFieldDisabled({ isDefault, keyIsDefault })}
                    />
                  }
                  key={item.key}
                  label={item.label}
                  value={item.key}
                />
              </Fragment>
            ))}
          </RadioGroup>
        </FormControl>
      }
      <Typography classes={{ root: classes.sectionTitle }} gutterBottom>
        {'OAuth'}
      </Typography>
      <FormTextField
        id={`oauth-callback-url-key-${idx}`}
        name={intl.getI18nMessage('label.application.key.oauth.callback.url')}
        value={keyOAuthCallbackUrl}
        helperText={intl.getI18nMessage('label.application.key.oauth.callback.url.help')}
        multiline
        rows={1}
        optional
        maxLength="2048"
        handleChange={(value) => { handleChange('callbackUrl', value); }}
        disabled={!editable}
      />
      <FormTextField
        id={`oauth-scope-key-${idx}`}
        name={intl.getI18nMessage('label.application.key.oauth.scope')}
        value={keyOAuthScope}
        helperText={intl.getI18nMessage('label.application.key.oauth.scope.help')}
        multiline
        rows={1}
        optional
        maxLength="4000"
        handleChange={(value) => { handleChange('scope', value); }}
        disabled={!editable}
      />
      <FormButtonGroup
        id={`oauth-type-key-${idx}`}
        name={'Type'}
        value={keyOAuthType}
        data={[
          { uuid: 'PUBLIC', name: 'Public' },
          { uuid: 'CONFIDENTIAL', name: 'Confidential' },
        ]}
        handleChange={(value) => { handleChange('type', value || null); }}
        disabled={!editable}
      />
      <Typography classes={{ root: classes.sectionTitle }} gutterBottom>
        {'Client ID & Secret'}
      </Typography>
      {clientId ?
        <Fragment>
          <Grid container className={classes.gridContainer}>
            <Grid md={12} item>
              <Grid container>
                <Grid xs={9} item>
                  <FormTextField
                    id={`client-id-key-${idx}`}
                    name={intl.getI18nMessage('label.application.key')}
                    value={clientId}
                    disabled
                  />
                </Grid>
                <Grid xs={3} item>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      copy(clientId);
                      showCopied(clientId, 'API_KEY');
                    }}
                    className={classes.copyButton}
                  >
                    {'Copy'}
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid xs={9} item>
                  <FormTextField
                    id={`client-secret-key-${idx}`}
                    name={intl.getI18nMessage('label.application.key.shared.secret')}
                    value={keyClientSecret}
                    disabled
                    {...(isSecretCopyButtonVisible && isCreatedKey && isHashed && {
                      helperText: intl.getI18nMessage('label.application.key.shared.secret.hashed.generated.help'),
                      errorHelperText: true,
                    })}
                  />
                </Grid>
                { isSecretCopyButtonVisible &&
                  (isHashed === false || keyRegenerateSecret || isCreatedKey) &&
                  <Grid xs={3} item>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        copy(keyClientSecret);
                        showCopied(keyClientSecret, 'SECRET');
                      }}
                      className={classes.copyButton}
                    >
                      {'Copy'}
                    </Button>
                  </Grid>
                }
              </Grid>
              {!keyRegenerateSecret ? <Fragment>
                <Grid container>
                  <Grid xs={9} item>
                    <Button
                      color="primary"
                      variant="outlined"
                      className={classes.generateSecretButton}
                      id={`btn-key-generate-secret-${idx}`}
                      onClick={() => generateSecret()}
                      disabled={!editable}
                    >
                      {'Generate New Secret'}
                    </Button>
                  </Grid>
                </Grid>
              </Fragment> :
              <div className={classes.secretOptionsContainer}>
                <FormHelperText classes={{ root: classes.helperText }}>
                  <InfoIcon className={classes.helpIcon} /> {getI18nFormattedMessage('label.application.key.shared.secret.generated.info.1')}
                </FormHelperText>
                <FormHelperText classes={{ root: classes.helperText }}>
                  <WarningIcon className={classes.warningIcon} /> {getI18nFormattedMessage('label.application.key.shared.secret.generated.info.2')}
                </FormHelperText>
                <br />
                {displayHashingOptions &&
                <Fragment>
                  <Typography classes={{ root: classes.sectionTitle }} gutterBottom>
                    {'Secret Type'}
                  </Typography>
                  <RadioGroup
                    value={keyIsHashed}
                    onChange={(e) => handleChange('isHashed', e.target.value)}
                    defaultValue={'HASHED_SECRET'}
                  >
                    {secretOptions.map((item) =>
                      <FormControlLabel
                        control={<Radio />}
                        key={item.key}
                        label={item.name}
                        value={item.key}
                      />,
                    )}
                  </RadioGroup>
                </Fragment>
                }
              </div>
            }
            </Grid>
          </Grid>
          <br />
        </Fragment> :
        <div className={classes.secretOptionsContainer}>
          <FormHelperText classes={{ root: classes.helperText }}>
            {'The Client ID & Secret will be generated on save'}
          </FormHelperText>
          {displayHashingOptions &&
            <Fragment>
              <Typography classes={{ root: classes.sectionTitle }} gutterBottom>
                {'Secret Type'}
              </Typography>
              <RadioGroup
                value={keyIsHashed}
                onChange={(e) => handleChange('isHashed', e.target.value)}
                defaultValue={'HASHED_SECRET'}
              >
                {secretOptions.map((item) =>
                  <FormControlLabel
                    control={<Radio />}
                    key={item.key}
                    label={item.name}
                    value={item.key}
                  />,
                )}
              </RadioGroup>
            </Fragment>
          }
          <br />
        </div>
      }
      <Button
        id={`btn-key-save-${idx}`}
        className={classes.saveButton}
        color="secondary"
        variant="contained"
        onClick={saveKey}
        disabled={!editable}
      >
        {'Save Key'}
      </Button>
      <Button
        id={`btn-key-save-${idx}`}
        color="primary"
        variant="outlined"
        onClick={() => closeAction()}
        className={classes.closeButton}
      >
        {'Close'}
      </Button>
    </Fragment>
  );
};

Key.propTypes = {
  idx: PropTypes.number,
  applicationUuid: PropTypes.string,
  editable: PropTypes.bool,
  details: PropTypes.object,
  isUniqueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  notifyMessages: PropTypes.func,
  appSecretHashingMetadata: PropTypes.object,
  closeAction: PropTypes.func,
  classes: PropTypes.object,
  setHasUnsavedChanges: PropTypes.func,
  updateKey: PropTypes.func,
  createKey: PropTypes.func,
  fetchKeyNameUnique: PropTypes.func,
};

export default withStyles(styles)(Key);
