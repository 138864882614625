import { get } from 'lodash';

const styles = ({ padding, breakpoint, color, fontSize, typography }) => ({
  pageTitle: {
    color: get(color, 'pageTitle'),
    fontFamily: get(typography, 'pageTitle'),
    fontSize: get(fontSize, 'pageTitle'),
    marginTop: 6,
  },
  smallTitle: {
    color: get(color, 'smallTitle'),
    fontFamily: get(typography, 'smallTitle'),
    fontSize: get(fontSize, 'smallTitle'),
    marginTop: 0,
    marginBottom: 0,
    verticalAlign: 'middle',
    lineHeight: '34px',
  },
  smallLabel: {
    color: get(color, 'smallLabel'),
    fontFamily: get(typography, 'smallLabel'),
    fontSize: get(fontSize, 'smallLabel'),
  },
  hr: {
    color: get(color, 'border'),
    borderTopWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
  },
  warningDiv: {
    display: 'flex',
    marginTop: 8,
  },
  warningIcon: {
    fill: get(color, 'warning'),
  },
  workflowHead: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
    marginBottom: 0,
    verticalAlign: 'middle',
  },
  bodyText: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
  },
  bodyTextWarning: {
    marginLeft: 10,
    color: get(color, 'warning'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
  },
  disabledSmallTitle: {
    color: get(color, 'disabledTitle'),
    fontFamily: get(typography, 'disabledTitle'),
    fontSize: get(fontSize, 'disabledText'),
  },
  outerDiv: {
    margin: 36,
    paddingLeft: get(padding, 'p36'),
    paddingRight: get(padding, 'p36'),
    paddingBottom: get(padding, 'p36'),
    paddingTop: get(padding, 'p36'),
    backgroundColor: color.uiBackground,
  },
  details: {
    marginBottom: 50,
  },
  notification: {
    marginLeft: 40,
  },
  [`@media (min-width: ${get(breakpoint, 'md')})`]: {
    details: {
      width: '50%',
    },
  },
  [`@media (max-width: ${get(breakpoint, 'md')})`]: {
    outerDiv: {
      margin: 0,
    },
  },
    [`@media (max-width: ${get(breakpoint, 'sm')})`]: {
    outerDiv: {
      paddingLeft: get(padding, 'p16'),
      paddingRight: get(padding, 'p16'),
    },
  },
});

export default styles;
