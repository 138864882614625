import React from 'react';
import {
  withStyles,
  SnackbarContent,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { func, shape, string, bool } from 'prop-types';
import styles from './styles';

const AlertMessages = (props) => {
  const { id, message, classes, variant, onClose, containerClass = '', className = '', hasAction = true } = props;
  return (
    <SnackbarContent
      className={clsx(
        classes[variant],
        classes.messageContainer,
        containerClass,
        className,
      )}
      id={id} data-apim-test={id} data-layer7-test={id}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {message}
        </span>
      }
      action={hasAction && [
        <IconButton
          className={classes.closeButton} color="inherit"
          onClick={onClose}
          id="close-alert-message" data-layer7-test="close-alert-message"
          key="close" aria-label="close"
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  );
};

AlertMessages.propTypes = {
  id: string,
  message: string,
  classes: shape({}),
  containerClass: string,
  variant: string,
  onClose: func,
  className: string,
  hasAction: bool,
};

export default withStyles(styles)(AlertMessages);
