import axios from 'axios';
import { get, isEmpty } from 'lodash';

import * as constants from '../../constants';
import { getValidationErrors } from '../../utils';
import { getConfig } from "../../reducers/portalConfig";
import { AXIOS_DEFAULT_OPTIONS } from '../';

export const showLoading = ({ isLoading, dispatch }) => {
  dispatch({
    type: constants.API_PROXY_LOADING,
    payload: isLoading,
  });
};

export const reinitializeState = ({ dispatch }) => {
  dispatch({
    type: constants.API_PROXY_RESET,
    payload: null,
  });
};

const isExistingProxyName = (current, existing) =>
  current && existing && current.trim().toLowerCase() === existing.trim().toLowerCase();

export const validateProxyUniqueName = ({ config, proxyName, proxyDetails, dispatch }) => {
  if (isEmpty(proxyName) || isExistingProxyName(proxyName, get(proxyDetails, 'name'))) {
    dispatch({
      type: constants.API_PROXY_VALIDATE_UNIQUE_NAME_RESET,
      payload: '',
    });
  } else {
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/internal/proxies/isNameUnique?name=${proxyName}`;
    axios.get(url, { credentials: 'include' })
    .then((response) => {
      dispatch({
        type: constants.API_PROXY_VALIDATE_UNIQUE_NAME_SUCCESS,
        payload: get(response, 'data.isUnique') || false,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.API_PROXY_VALIDATE_UNIQUE_NAME_ERROR,
        payload: getValidationErrors(error),
      });
    });
  }
};

export const fetchProxyDeploymentTypes = ({ config, dispatch, proxyUuid }) => {
  showLoading({ isLoading: true, dispatch });

  const { portal } = config;
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/0.1/proxies/${proxyUuid}/deployment-type`;
  axios.get(url, { credentials: 'include' })
    .then((response) => {
      dispatch({
        type: constants.API_PROXY_FETCH_DEPLOYMENT_TYPE_SUCCESS,
        payload: get(response, 'data') || {},
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.API_PROXY_FETCH_DEPLOYMENT_TYPE_ERROR,
        payload: getValidationErrors(error),
      });
    });
};

export const updateProxyWithKeyDeploymentType = ({ config, dispatch, proxyUuid, data }) => {
  showLoading({ isLoading: true, dispatch });

  const { portal } = config;
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/0.1/proxies/${proxyUuid}/deployment-type/APPLICATION`;
  axios.put(url, data, { credentials: 'include' })
    .then((response) => {
      dispatch({
        type: constants.API_PROXY_UPDATE_KEY_DEPLOYMENT_TYPE_SUCCESS,
        payload: get(response, 'data') || {},
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.API_PROXY_UPDATE_KEY_DEPLOYMENT_TYPE_ERROR,
        payload: getValidationErrors(error),
      });
    });
};

export const createProxy = ({ config, dispatch, data, apiKeyProxyType }) => {
  showLoading({ isLoading: true, dispatch });

  const { portal } = config;
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies`;
  axios.post(url, data, { credentials: 'include' })
    .then((response) => {
      dispatch({
        type: constants.API_PROXY_CREATE_SUCCESS,
        payload: get(response, 'data') || {},
      });
      const proxyUuid = get(response, 'data.uuid');
      const reqData = { type: apiKeyProxyType };
      updateProxyWithKeyDeploymentType({ config, dispatch, proxyUuid, data: reqData });
    })
    .catch((error) => {
      dispatch({
        type: constants.API_PROXY_CREATE_ERROR,
        payload: getValidationErrors(error),
      });
    });
};

export const updateProxy = ({ config, dispatch, proxyUuid, data, apiKeyProxyType }) => {
  showLoading({ isLoading: true, dispatch });

  const { portal } = config;
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}`;
  axios.put(url, data, { credentials: 'include' })
    .then((response) => {
      dispatch({
        type: constants.API_PROXY_UPDATE_SUCCESS,
        payload: get(response, 'data') || {},
      });
      const reqData = { type: apiKeyProxyType };
      updateProxyWithKeyDeploymentType({ config, dispatch, proxyUuid, data: reqData });
    })
    .catch((error) => {
      dispatch({
        type: constants.API_PROXY_UPDATE_ERROR,
        payload: getValidationErrors(error),
      });
    });
};

export const saveProxy = ({ config, dispatch, data, proxyUuid, apiKeyProxyType }) => {
  dispatch({
    type: constants.API_PROXY_SAVE_STATUS_RESET,
    payload: '',
  });

  if (proxyUuid) {
    updateProxy({ config, dispatch, data, proxyUuid, apiKeyProxyType });
  } else {
    createProxy({ config, dispatch, data, apiKeyProxyType });
  }
};

export const deleteProxy = ({ config, dispatch, proxyUuid }) => {
  showLoading({ isLoading: true, dispatch });

  const { portal } = config;
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}`;
  axios.delete(url, { credentials: 'include' })
    .then((response) => {
      dispatch({
        type: constants.API_PROXY_DELETE_SUCCESS,
        payload: get(response, 'data') || '',
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.API_PROXY_DELETE_ERROR,
        payload: getValidationErrors(error),
      });
    });
};

export const fetchAllOrganizations = ({ config, dispatch }) => {
  const { portal } = config;
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/internal/organizations?status=ENABLED&type=PUBLISHER&size=1000&page=0`;
  axios.get(url, { credentials: 'include' })
    .then((response) => {
      dispatch({
        type: constants.API_PROXY_FETCH_ORGANIZATIONS_SUCCESS,
        payload: get(response, 'data.results') || [],
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.API_PROXY_FETCH_ORGANIZATIONS_ERROR,
        payload: getValidationErrors(error),
      });
    });
};

export const fetchSelectedOrganizations = ({ config, dispatch, proxyUuid }) => {
  const { portal } = config;
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/organizations`;
  axios.get(url, { credentials: 'include' })
    .then((response) => {
      dispatch({
        type: constants.API_PROXY_FETCH_SELECTED_ORGANIZATIONS_SUCCESS,
        payload: get(response, 'data') || [],
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.API_PROXY_FETCH_SELECTED_ORGANIZATIONS_ERROR,
        payload: getValidationErrors(error),
      });
    });
};

export const updateProxyOrganizations = ({ config, dispatch, proxyUuid, data }) => {
  showLoading({ isLoading: true, dispatch });

  const { portal } = config;
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/organizations`;
  axios.put(url, data, { credentials: 'include' })
    .then((response) => {
      dispatch({
        type: constants.API_PROXY_UPDATE_ORGS_SUCCESS,
        payload: get(response, 'data') || {},
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.API_PROXY_UPDATE_ORGS_ERROR,
        payload: getValidationErrors(error),
      });
    });
};

export const fetchProxy = (proxyUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    await Promise.all([
      axios.get(
        `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}`,
        AXIOS_DEFAULT_OPTIONS,
      ),
      axios.get(
        `${portal.hostname}/api/${portal.tenantPrefix}/deployments/0.1/proxies/${proxyUuid}/deployment-type`,
        AXIOS_DEFAULT_OPTIONS,
      ),
    ])
      .then((response = []) => {
        dispatch({
          type: constants.PROXY_GET_SUCCESS,
          payload: {
            proxyDetails: get(response[0], 'data', {}),
            deploymentType: get(response[1], 'data', {}),
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.PROXY_GET_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchGateway = (proxyUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    await Promise.all([
      axios.get(
        `${portal.hostname}/admin/Portal.svc/Gateways?inlinecount=allpages&filter=(EnrollmentStatus=ACTIVE or EnrollmentStatus=PENDING)`,
        AXIOS_DEFAULT_OPTIONS,
      ),
      axios.get(
        `${portal.hostname}/admin/Portal.svc/Gateways('${proxyUuid}')`,
        AXIOS_DEFAULT_OPTIONS,
      ),
    ])
      .then((response = []) => {
        dispatch({
          type: constants.PROXY_GATEWAY_GET_SUCCESS,
          payload: {
            gateways: get(response[0], 'data.d.results', []),
            gatewayDetails: get(response[1], 'data.d', {}),
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.PROXY_GATEWAY_GET_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchProxyDetails = ({ config, dispatch, proxyUuid }) => {
  const { portal } = config;
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}`;
  axios.get(url, { credentials: 'include' })
    .then((response) => {
      dispatch({
        type: constants.API_PROXY_FETCH_DETAILS_SUCCESS,
        payload: get(response, 'data') || {},
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.API_PROXY_FETCH_DETAILS_ERROR,
        payload: getValidationErrors(error),
      });
    });
};

// Details Page
export const fetchGateways = ({ config, dispatch }) => {
  const { portal } = config;
  const url = `${portal.hostname}/admin/Portal.svc/Gateways?inlinecount=allpages&filter=(EnrollmentStatus=ACTIVE or EnrollmentStatus=PENDING)`;
  axios.get(url, { credentials: 'include' })
  .then((response) => {
    dispatch({
      type: constants.FETCH_GATEWAYS_SUCCESS,
      payload: get(response, 'data.d.results') || [],
    });
  })
  .catch((error) => {
    dispatch({
      type: constants.FETCH_GATEWAYS_ERROR,
      payload: getValidationErrors(error),
    });
  });
};

export const fetchGatewayDetails = ({ config, dispatch, proxyUuid }) => {
  const { portal } = config;
  const url = `${portal.hostname}/admin/Portal.svc/Gateways('${proxyUuid}')`;
  axios.get(url, { credentials: 'include' })
  .then((response) => {
    dispatch({
      type: constants.FETCH_GATEWAY_DETAILS_SUCCESS,
      payload: get(response, 'data.d') || {},
    });
  })
  .catch((error) => {
    dispatch({
      type: constants.FETCH_GATEWAY_DETAILS_ERROR,
      payload: getValidationErrors(error),
    });
  });
};

export const fetchApis = ({ config, dispatch }) => {
  const { portal } = config;
  const url = `${portal.hostname}/admin/Portal.svc/Api2.0s?skip=0&top=10&inlinecount=allpages`;
  axios.get(url, { credentials: 'include' })
  .then((response) => {
    dispatch({
      type: constants.FETCH_APIS_SUCCESS,
      payload: get(response, 'data.d.results') || [],
    });
  })
  .catch((error) => {
    dispatch({
      type: constants.FETCH_APIS_ERROR,
      payload: getValidationErrors(error),
    });
  });
};

export const fetchApps = ({ config, dispatch }) => {
  const { portal } = config;
  const url = `${portal.hostname}/admin/Portal.svc/Applications?skip=0&top=10&inlinecount=allpages`;
  axios.get(url, { credentials: 'include' })
  .then((response) => {
    dispatch({
      type: constants.FETCH_APPS_SUCCESS,
      payload: get(response, 'data.d.results') || [],
    });
  })
  .catch((error) => {
    dispatch({
      type: constants.FETCH_APPS_ERROR,
      payload: getValidationErrors(error),
    });
  });
};

export const fetchAccountPlans = ({ config, dispatch }) => {
  const { portal } = config;
  const url = `${portal.hostname}/admin/Portal.svc/AccountPlans?skip=0&top=10&inlinecount=allpages`;
  axios.get(url, { credentials: 'include' })
  .then((response) => {
    dispatch({
      type: constants.FETCH_ACCOUNT_PLANS_SUCCESS,
      payload: get(response, 'data.d.results') || [],
    });
  })
  .catch((error) => {
    dispatch({
      type: constants.FETCH_ACCOUNT_PLANS_ERROR,
      payload: getValidationErrors(error),
    });
  });
};

export const fetchApiPlans = ({ config, dispatch }) => {
  const { portal } = config;
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/api-plans`;
  axios.get(url, { credentials: 'include' })
  .then((response) => {
    dispatch({
      type: constants.FETCH_API_PLANS_SUCCESS,
      payload: {
        apiPlans: get(response, 'data.results', []),
        apiPlansCount: get(response, 'data.totalElements', 0),
      },
    });
  })
  .catch((error) => {
    dispatch({
      type: constants.FETCH_API_PLANS_ERROR,
      payload: getValidationErrors(error),
    });
  });
};

export const fetchApplicationApiKeysCount = ({ config, dispatch }) => {
  const { portal } = config;
  const url = `${portal.hostname}/admin/api-management/internal/applications/api-keys/count`;
  axios.get(url, { credentials: 'include' })
    .then((response) => {
      dispatch({
        type: constants.APPLICATION_ALL_KEYS_COUNT_GET_SUCCESS,
        payload: get(response, 'data'),
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.APPLICATION_ALL_KEYS_COUNT_GET_ERROR,
        payload: getValidationErrors(error),
      });
    });
};
export const fetchApiSyncFeatureFlag = ({ config, dispatch, proxyUuid }) => {
  const { portal } = config;
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/0.1/proxies/${proxyUuid}/config`;
  axios.get(url, { credentials: 'include' })
  .then((response) => {
    dispatch({
      type: constants.FETCH_API_SYNC_FEATURE_FLAG_SUCCESS,
      payload: get(response, 'data.useNewAutoSync'),
    });
  })
  .catch((error) => {
    dispatch({
      type: constants.FETCH_API_SYNC_FEATURE_FLAG_ERROR,
      payload: getValidationErrors(error),
    });
  });
};

export const fetchApiSyncCounts = async ({ config, dispatch, proxyUuid }) => {
  const { portal } = config;
  const urlPortal = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/apis?sort=lastTimeDeployed,desc`;
  const urlPortalApis = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/apis?publishedByPortal=true`;
  const urlGatewayApis = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/apis?publishedByPortal=false`;
  await Promise.all([
    axios.get(urlPortal, { credentials: 'include' }),
    axios.get(urlPortalApis, { credentials: 'include' }),
    axios.get(urlGatewayApis, { credentials: 'include' }),
  ])
  .then(responseArr => {
    dispatch({
      type: constants.FETCH_API_SYNC_COUNT_SUCCESS,
      payload: {
        portalApis: responseArr[0].data,
        publishedByPortal: responseArr[1].data,
        publishedByGateway: responseArr[2].data,
      },
    });
  })
  .catch((error) => {
    dispatch({
      type: constants.FETCH_API_SYNC_COUNT_ERROR,
      payload: getValidationErrors(error),
    });
  });
};
