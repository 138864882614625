import colorMod from 'color';
import get from 'lodash/get';

export default ({ color, fontSize, misc, typography }) => ({
  HeaderNavigationLink: {
    border: '1px solid !important',
    borderColor: 'rgba(0, 0, 0, 0) !important',
    borderRadius: '3px !important',
    color: `${get(color, 'headerLink')} !important`,
    display: 'block',
    fontFamily: `${get(typography, 'siteTitle')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    fontStyle: 'normal !important',
    fontWeight: '600 !important',
    lineHeight: '40px !important',
    margin: '0 6px 0 6px !important',
    padding: '0 16px 0 16px !important',
    textDecoration: 'none !important',
    transition: get(misc, 'colorTransition'),

    '&:hover': {
      borderColor: `${get(color, 'headerLink')} !important`,
      backgroundColor: `${colorMod(get(color, 'headerLink')).alpha(0.1).string()} !important`,
    },
    '&:focus': {
      borderColor: `${colorMod(get(color, 'headerLink')).alpha(0.7).string()} !important`,
      outline: 'none !important',
    },
    '&:active': {
      borderColor: `${get(color, 'headerLink')} !important`,
    },
  },
});
