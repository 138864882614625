import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import isNaN from 'lodash/isNaN';
import {
  Button,
  IconButton,
  Typography, withStyles,
} from '@material-ui/core';
import { IconRefresh } from 'mineral-ui-icons';
import { func, shape, string } from 'prop-types';

import { getI18n, getI18nFormattedMessage } from '../../utils/intl';
import styles from './styles';

export const getDefaultTimer = (refreshTime) => {
  const defaultTimer = parseInt(refreshTime, 10);
  return refreshTime && !isNaN(defaultTimer) ? defaultTimer : 10;
}

export const RefreshCounter = (props) => {
  const {
    classes = {},
    refreshTime,
    checkStatus,
  } = props;
  const defaultTimer = getDefaultTimer(refreshTime);

  const intl = getI18n(useIntl());
  const [timer, setTimer] = useState(null);

  const checkNow = () => {
    setTimer(defaultTimer);
    checkStatus();
  };

  useEffect(() => {
    setTimer(defaultTimer);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(timer - 1);
      if (timer === 1) {
        checkNow();
        clearInterval(interval);
      }
    }, 1000);
    return (
      () => clearInterval(interval)
    );
  }, [timer]);

  return (
    <div
      id="refresh-counter"
      data-layer7-test="refresh-counter"
    >
      <IconButton className={classes.runningIndicatorIcon}>
        <IconRefresh />
      </IconButton>
      <Typography variant="body1">
        {` ${intl.getI18nMessage('label.update.in')} ${timer}${intl.getI18nMessage('label.unit.seconds')}...`}
      </Typography>
      <Button variant="text" color="primary" onClick={() => checkNow()}>
        {getI18nFormattedMessage('label.check.now')}
      </Button>
    </div>
  );
}

export default (
  withStyles(styles)
)(RefreshCounter);

RefreshCounter.propTypes = {
  classes: shape({}),
  refreshTime: string,
  checkStatus: func,
};
RefreshCounter.displayName = 'RefreshCounter';
