import { connect } from 'react-redux';
import React from 'react';
import {
  branch,
  compose,
  renderComponent,
} from 'recompose';
import {
  fetchAvailableApis,
  fetchAvailableApps,
  fetchAvailableOrgs,
} from '../../actions/application';
import fetchDashboardEntityCount, {
  fetchDashboardChartData,
} from '../../actions/dashboard';
import {
  getAvailableApis,
  getAvailableApps,
  getAvailableOrgs,
} from '../../reducers/application';
import {
  getDashboardEntityCount,
  getDashboardChartData,
} from '../../reducers/dashboard';
import { getUserDetails } from '../../reducers/portalConfig';
import Dashboard from './Main';
import GuestDashboard from './GuestDashboard';

const GUEST_DASHBOARD_URL = '/admin/app/guest/dashboard';

const isGuestUser = () => (window.location.href.indexOf(GUEST_DASHBOARD_URL) > -1);

const mapStateToProps = state => ({
  autoSuggestionsApis: getAvailableApis(state),
  autoSuggestionsApps: getAvailableApps(state),
  autoSuggestionsOrgs: getAvailableOrgs(state),
  userContexts: getUserDetails(state),
  entities: getDashboardEntityCount(state),
  chartData: getDashboardChartData(state),
});

const mapDispatchToProps = (dispatch: Function) => ({
  fetchAvailableApis: (searchText: string) =>
    dispatch(
      fetchAvailableApis({
        searchText,
      }),
    ),
  fetchAvailableApps: (searchText: string) =>
    dispatch(
      fetchAvailableApps({
        searchText,
      }),
    ),
  fetchAvailableOrgs: (searchText: string) =>
    dispatch(
      fetchAvailableOrgs({
        searchText,
      }),
    ),
  fetchDashboardEntityCount: () => dispatch(fetchDashboardEntityCount()),
  fetchDashboardChartData: (query, entity) =>
    dispatch(fetchDashboardChartData(query, entity)),
});

const Wrapper = (props: Object) => (
  <div>
    {props.children}
  </div>
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  branch(
    isGuestUser,
    renderComponent(GuestDashboard),
    renderComponent(Dashboard),
  ),
)(Wrapper);
