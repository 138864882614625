import { get } from 'lodash';

const styles = ({ color, fontSize, typography }) => ({
  pageTitle: {
    color: get(color, 'pageTitle'),
    fontFamily: get(typography, 'pageTitle'),
    fontSize: get(fontSize, 'pageTitle'),
    marginTop: 6,
  },
  smallTitle: {
    color: get(color, 'smallTitle'),
    fontFamily: get(typography, 'smallTitle'),
    fontSize: get(fontSize, 'smallTitle'),
    marginTop: 6,
  },
  bodyText: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: 16,
    marginTop: 6,
  },
  bullets: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
  },
  outerDiv: {
    margin: 30,
    paddingLeft: 80,
    paddingBottom: 30,
    paddingTop: 30,
    backgroundColor: color.uiBackground,
  },
  buttonsPanel: {
    marginTop: 25,
    marginBottom: 30,
  },
  cancelButton: {
    marginLeft: 10,
  },
  passwordManagement: {
    display: 'flex',
  },
  passwordFields: {
    width: 364,
    marginRight: 50,
  },
  details: {
    width: 364,
  },
  notification: {
    marginLeft: 40,
  },
  dialog: {
    width: 300,
    '& .MuiTypography-h6': {
      fontSize: get(fontSize, 'smallTitle'),
    },
  },
});

export default styles;
