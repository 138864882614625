
export const moveDocument = ({
    documentUuid,
    newParentUuid,
    ordinal: newOrdinal,
    allDocuments,
}) => {
    const updatedDocument = allDocuments.find(doc => doc.uuid === documentUuid);

    if (!updatedDocument) {
        return;
    }

    const oldOrdinal = updatedDocument.ordinal;
    const oldParentUuid = updatedDocument.parentUuid;

    const result = allDocuments.map((doc) => {
        if (doc.uuid === updatedDocument.uuid) {
            const newSiblings =
                allDocuments.find(doc => doc.parentUuid === newParentUuid) ||
                [];

            const siblingsCount =
                oldParentUuid !== newParentUuid
                    ? newSiblings.length + 1
                    : newSiblings.length;

            const finalOrdinal =
                newOrdinal > siblingsCount - 1 ? siblingsCount - 1 : newOrdinal;

            return {
                ...doc,
                parentUuid: newParentUuid,
                ordinal: finalOrdinal < 0 ? 0 : finalOrdinal,
            };
        }
        if (
            oldParentUuid === newParentUuid &&
            doc.parentUuid === oldParentUuid
        ) {
            if (oldOrdinal < newOrdinal) {
                if (doc.ordinal > oldOrdinal && doc.ordinal <= newOrdinal) {
                    return {
                        ...doc,
                        ordinal: doc.ordinal - 1,
                    };
                }
            }

            if (oldOrdinal > newOrdinal) {
                if (doc.ordinal < oldOrdinal && doc.ordinal >= newOrdinal) {
                    return {
                        ...doc,
                        ordinal: doc.ordinal + 1,
                    };
                }
            }
        }
        if (oldParentUuid !== newParentUuid) {
            if (doc.parentUuid === oldParentUuid && doc.ordinal >= oldOrdinal) {
                return {
                    ...doc,
                    ordinal: doc.ordinal - 1,
                };
            }

            if (doc.parentUuid === newParentUuid && doc.ordinal >= newOrdinal) {
                return {
                    ...doc,
                    ordinal: doc.ordinal + 1,
                };
            }
        }

        return doc;
    });

    return result;
};