import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import PropTypes, { shape } from 'prop-types';
import {
  Button,
  Collapse,
  Grid,
  Link,
  Typography,
  withStyles,
} from '@material-ui/core';
import find from 'lodash/find';
import map from 'lodash/map';
import { FormSelect } from '../../../../components';
import {
  getAvailableApps,
  getKeysResults,
} from '../../../../reducers/application';
import {
  getAPISwaggerData,
} from '../../../../reducers/api';
import {
  fetchApplicationKeys,
  fetchAvailableApps,
} from '../../../../actions/application';
import {
  fetchSwaggerFile,
} from '../../../../actions/api';
import initializeSwaggerUiBundle, { SWAGGER_UI_EL } from './swagger';
import styles from './styles';
import { getI18n } from '../../../../utils/intl';

export const Spec = ({
  classes,
  apiDetails,
  apiSwagger,
  apiUuid,
  applicationAPIKeys,
  availableApps,
  fetchApplicationKeys,
  fetchAvailableApps,
  fetchSwaggerFile,
}) => {

  const intl = getI18n(useIntl());

  useEffect(() => {
    if (apiUuid) {
      fetchAvailableApps({ apiUuid });
      fetchSwaggerFile(apiUuid);
    }
  }, [apiUuid]);

  const [selectedApp, setSelectedApp] = useState('0');
  const [selectedAppAPIKey, setSelectedAppAPIKey] = useState('0');
  const [selectionCollapsed, setSelectionCollapsed] = useState(false);

  useEffect(() => {
    if (selectedApp !== '0') {
      fetchApplicationKeys(selectedApp, 0, 200);
    }
    setSelectedAppAPIKey('0');
  }, [selectedApp]);

  useEffect(() => {
    if (apiSwagger) {
      initializeSwaggerUiBundle({
        api: apiDetails,
        apiKey: {},
        apiSwagger,
      });
    }
  }, [apiSwagger]);

  useEffect(() => {
    if ((selectedApp !== '0') && (selectedAppAPIKey !== '0')) {
      const apiKeyDetails = find(applicationAPIKeys, item => item.apiKey === selectedAppAPIKey);
      if (apiKeyDetails) {
        initializeSwaggerUiBundle({
          api: apiDetails,
          apiKey: apiKeyDetails,
          apiSwagger,
        });
      }
    }
  }, [selectedApp, selectedAppAPIKey]);

  const renderApplicationsDropdown = () => {
    return (<FormSelect
        fieldContainerClass={classes.selectionDropdown}
        data={availableApps}
        defaultText={intl.getI18nMessage('label.api.details.spec.select.application')}
        handleChange={setSelectedApp}
        id="api-spec-apps-select"
        labelId="api-spec-apps-select-label"
        name={intl.getI18nMessage('label.application')}
        noNativeSelect
        value={selectedApp}
      />);
  };

  const getApiKeys = () => map(applicationAPIKeys,
    item => ({ name: item.name, uuid: item.apiKey }));

  const renderAPIKeysDropdown = () => {
    return (<FormSelect
      fieldContainerClass={classes.selectionDropdown}
      data={getApiKeys()}
      defaultText={intl.getI18nMessage('label.api.details.spec.select.application.apikey')}
      disabled={selectedApp === '0'}
      handleChange={setSelectedAppAPIKey}
      id="api-spec-apps-apikey-select"
      labelId="api-spec-apps-apikey-select-label"
      name={intl.getI18nMessage('label.apikey')}
      noNativeSelect
      value={selectedAppAPIKey}
    />);
  };

  return (<div>
    <Grid
      container
      className={classes.selectionContainer}
      data-apim-test="api-details-spec-container"
      data-layer7-test="api-details-spec-container"
      id="api-details-spec-container"
      maxWidth={false}
    >
      <Grid item lg={12}>
        <Typography id="api-spec-connection-details" variant="h4">
          {intl.getI18nMessage('label.api.details.authentication.details')}
        </Typography>
        <Button
          classes={{
            root: classes.btnRoot,
            label: classes.btnLabel,
          }}
          className={classes.collapseLink}
          onClick={() => setSelectionCollapsed(!selectionCollapsed)}
        >
          {
            selectionCollapsed ?
            intl.getI18nMessage('label.api.details.authentication.details.show')
            :
            intl.getI18nMessage('label.api.details.authentication.details.hide')
          }
        </Button>
      </Grid>
      <Collapse className={classes.collapse} in={!selectionCollapsed}>
        <Grid
          alignItems={'baseline'}
          container
          lg={12}
          spacing={3}
        >
          <Grid item lg={5} xs={12}>
            {renderApplicationsDropdown()}
          </Grid>
          <Grid item lg={5} xs={12}>
            {renderAPIKeysDropdown()}
          </Grid>
          <Grid item lg={2} xs={12}>
            <div>
              <Link
                className={classes.applicationLink}
                href={'/publish/applications/add'}
              >
                {intl.getI18nMessage('label.api.details.spec.create.application')}
              </Link>
            </div>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
    <div id={SWAGGER_UI_EL} />
  </div>);
};

const mapStateToProps = state => ({
  apiSwagger: getAPISwaggerData(state),
  applicationAPIKeys: getKeysResults(state),
  availableApps: getAvailableApps(state),
});

const mapDispatchToProps = {
  fetchApplicationKeys,
  fetchAvailableApps,
  fetchSwaggerFile,
};

Spec.propTypes = {
  apiUuid: PropTypes.string,
  apiDetails: PropTypes.object,
  apiSwagger: PropTypes.object,
  applicationAPIKeys: PropTypes.arrayOf(PropTypes.object),
  availableApps: PropTypes.arrayOf(PropTypes.object),
  classes: shape({}),
  fetchApplicationKeys: PropTypes.func,
  fetchAvailableApps: PropTypes.func,
  fetchSwaggerFile: PropTypes.func,
};

Spec.displayName = 'Spec';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  )(Spec);
