import { get } from 'lodash';

const styles = ({ color, fontSize, typography }) => ({
  keyNameContainer: {
    marginTop: 20,
    marginBottom: 40,
  },
  inputFieldContainer: {
    display: 'block',
  },
  inputField: {
    '& input': {
      margin: 0,
      padding: 10,
      border: `1px solid ${get(color, 'borderColor')}`,
      borderRadius: 3,
    },
  },
  notchedOutline: {
    outline: 0,
    border: 0,
  },
  inputHelpText: {
    color: get(color, 'bodyText'),
    margin: 1,
    marginTop: 8,
    marginBottom: 10,
  },
  statusField: {
    '& legend': {
      color: `${get(color, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontWeight: 'bold',
    },
    color: `${get(color, 'bodyText')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    marginBottom: 10,
  },
  helperText: {
    color: `${get(color, 'smallText')} !important`,
    fontFamily: `${get(typography, 'smallText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
  },
  helpIcon: {
    fontSize: 16,
    marginLeft: 10,
    verticalAlign: 'middle',
  },
  warningIcon: {
    fontSize: 16,
    marginLeft: 10,
    verticalAlign: 'middle',
  },
  sectionTitle: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    fontWeight: 'bold',
  },
  defaultCheckboxContainer: {
    color: `${get(color, 'bodyText')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    fontWeight: 'bold',
    marginTop: -20,
  },
  checkboxHelpText: {
    color: `${get(color, 'bodyText')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    marginBottom: 20,
    marginTop: -10,
  },
  defaultKeyWarningText: {
    marginTop: -20,
    marginBottom: 20,
    fontFamily: `${get(typography, 'smallText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    color: get(color, 'warning'),
  },
  secretTypeErrorText: {
    marginTop: -5,
    marginBottom: 5,
    color: get(color, 'error'),
  },
  generateSecretButton: {
    border: `1px solid ${get(color, 'borderColor')}`,
    color: get(color, 'red'),
    '&:hover': {
      color: get(color, 'red'),
    },
  },
  secretOptionsContainer: {
    color: get(color, 'bodyText'),
  },
  copyButton: {
    color: get(color, 'primaryButtonBackground'),
    marginTop: 51,
    width: '70%',
  },
  saveButton: {
    marginBottom: 10,
  },
  closeButton: {
    marginBottom: 10,
    marginLeft: 10,
  },
  iconRoot: {
    '& svg': {
      width: 16,
      height: 16,
    },
  },
});

export default styles;
