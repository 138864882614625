import colorMod from 'color';

const styles = ({ color, fontSize, typography }) => ({
  card: {
    minWidth: 150,
    height: 116,
    justifyContent: 'center',
    boxSizing: 'border-box',
    flexGrow: 1,
    borderLeft: `1px solid ${color.borderColor}`,
    borderTop: `1px solid ${color.borderColor}`,
    borderBottom: `1px solid ${color.borderColor}`,
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: 12,
    display: 'flex',
    backgroundColor: `${colorMod(color.borderColor).alpha(0.1).string()} !important`,
    '&:hover': {
      borderColor: color.primaryButtonHover,
      cursor: 'pointer',
      background: `${colorMod(color.primaryButtonHover).alpha(0.05).string()} !important`,
      '& div:last-child': {
        '& div': {
          visibility: 'visible',
        },
      },
    },
    '&:focus-within': {
      borderColor: color.primaryButtonHover,
      outline: 'none',
      cursor: 'pointer',
      background: `${colorMod(color.primaryButtonHover).alpha(0.05).string()} !important`,
      '& div:last-child': {
        '& div': {
          visibility: 'visible',
        },
      },
    },
    '&:active': {
      border: `3px double ${color.primaryButtonHover}`,
    },
  },
  addButtonContainer: {
    height: 20,
  },
  addButton: {
    visibility: 'hidden',
    borderRadius: '50%',
    background: color.primaryButtonBackground,
    width: 20,
    height: 20,
    display: 'flex',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: color.primaryButtonHover,
      cursor: 'pointer',
      visibility: 'visible !important',
    },
    '&:focus': {
      outline: 'none',
      visibility: 'visible !important',
      background: color.primaryButtonHover,
      cursor: 'pointer',
    },
  },
  entityTitle: {
    color: color.link,
    fontFamily: typography.bodyText,
    fontSize: fontSize.label,
    marginTop: 12,
  },
  entityCount: {
    fontFamily: typography.bodyText,
    fontSize: fontSize.pageTitle,
    color: color.bodyText,
  },
  tooltiptext: {
    fontSize: fontSize.bodyText,
    fontFamily: typography.bodyText,
    width: 120,
    opacity: 0.9,
    visibility: 'hidden',
    backgroundColor: '#5a5f6d',
    color: '#fff',
    textAlign: 'center',
    borderRadius: 3,
    padding: '5px 0',
    position: 'absolute',
    zIndex: 1,
    bottom: '155%',
    left: '-478%',
    '&:after': {
      content: 'close-quote',
      position: 'absolute',
      top: '100%',
      left: '86%',
      marginLeft: -5,
      borderWidth: 7,
      borderStyle: 'solid',
      borderColor: '#5a5f6d transparent transparent transparent',
    },
  },
  tooltip: {
    position: 'relative',
    '&:hover': {
      '& span': {
        visibility: 'visible',
      },
    },
  },
});

export default styles;
