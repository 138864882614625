import React from 'react';
import { map, isEmpty, trim, some, every, keys } from 'lodash';

import Details from './Details';
import CustomFields from './CustomFields';
import ApiManagement from './ApiManagement';
import Auth from './Auth';
import Key from './Key';
import KeyManagement from './KeyManagement';

import {
  hasOrgBoundRole,
  isEditApplicationDisabled,
} from '../../../utils';

const isOrganizationAndNameSet = ({ userContext, applicationDetails, isApplicationNameUnique }) =>
  (
    hasOrgBoundRole(userContext) ?
    (trim(applicationDetails.name) && isApplicationNameUnique !== false) :
    (trim(applicationDetails.name) && isApplicationNameUnique !== false &&
      applicationDetails.organizationUuid)
  );

export const isRequiredCustomFieldsNotSet = ({ applicationDetails, customFields = [] }) => {
  const { customFieldValues } = applicationDetails;
  return some(
    customFields.filter(({ required }) => required),
    ({ uuid }) => !customFieldValues[uuid],
  );
};

const tabs = [
  {
    id: 'details',
    label: 'Details',
    tabPanel: props => (<Details {...props} />),
    isDisabled: ({ userContext, applicationDetails }) => (
      isEditApplicationDisabled(userContext, applicationDetails)
    ),
  },
  {
    id: 'custom-fields',
    label: 'Custom Fields',
    tabPanel: (props) => (<CustomFields {...props} />),
    isDisabled: ({ userContext, applicationDetails, isApplicationNameUnique }) => (
      isEditApplicationDisabled(userContext, applicationDetails) ||
      !isOrganizationAndNameSet({ userContext, applicationDetails, isApplicationNameUnique })
    ),
    isHidden: ({ customFields }) => (isEmpty(customFields)),
  },
  {
    id: 'api-management',
    label: 'API Management',
    tabPanel: props => (<ApiManagement {...props} />),
    isDisabled: ({ userContext, applicationDetails, isApplicationNameUnique, customFields }) => (
      isEditApplicationDisabled(userContext, applicationDetails) ||
      !isOrganizationAndNameSet({ userContext, applicationDetails, isApplicationNameUnique }) ||
      isRequiredCustomFieldsNotSet({ applicationDetails, customFields })
    ),
  },
  {
    id: 'authentication',
    label: 'Authentication',
    tabPanel: props => (<Auth {...props} />),
    isDisabled: ({
      userContext, applicationDetails, isApplicationNameUnique, featureFlagApiPlans,
      availableApiPlans, customFields,
    }) => (
      isEditApplicationDisabled(userContext, applicationDetails) ||
      !isOrganizationAndNameSet({ userContext, applicationDetails, isApplicationNameUnique }) ||
      isRequiredCustomFieldsNotSet({ applicationDetails, customFields }) ||
      (isEmpty(applicationDetails.apiIds) && isEmpty(applicationDetails.apiGroupIds)) ||
      (featureFlagApiPlans && !every(
        keys(applicationDetails.apiApiPlanIds),
        apiApiPlanId => (
          isEmpty(availableApiPlans[apiApiPlanId]) ||
          !!applicationDetails.apiApiPlanIds[apiApiPlanId]
        ),
      ))
    ),
    isHidden: ({ isMultiKeySupport }) => isMultiKeySupport,
  },
  {
    id: 'key',
    label: 'Key',
    tabPanel: props => (<Key {...props} />),
    isDisabled: ({ applicationUuid }) => (!applicationUuid),
    isHidden: ({ isMultiKeySupport }) => isMultiKeySupport,
  },
  {
    id: 'keys',
    label: 'Authentication & Keys',
    tabPanel: props => (<KeyManagement {...props} />),
    isDisabled: ({ applicationUuid }) => (!applicationUuid),
    isHidden: ({ isMultiKeySupport }) => (!isMultiKeySupport),
  },
];

export default map(tabs, tab => ({ ...tab, tabId: `${tab.id}-tab` }));
