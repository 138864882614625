import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import AppsIcon from '@material-ui/icons/Apps';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import theme from './index.theme';

function Services({
 classes = {},
 serviceItems = [],
}) {
  const [anchorEl = false, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const hostname = `https://${window.location.hostname}`;
  const getImageSource = (serviceItem) =>
    hostname + serviceItem.link.imagePath.replace('.png', '.svg');
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.HeaderServicesButton}>
      <IconButton onClick={handleClick}>
        <AppsIcon fontSize="small" />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {!isEmpty(serviceItems) &&
        <Paper>
          <div className={classes.HeaderServicesPanel}>
            {serviceItems.map(serviceItem => (
              <a
                key={serviceItem.link.title}
                className={clsx(classes.ServiceLink, classes.HeaderServiceLink)}
                href={serviceItem.link.href}
                target={serviceItem.link.externalLink ? '_blank' : '_self'}
              >
                <div className={classes.ServiceLinkImageContainer}>
                  <img
                    alt={`${serviceItem.link.title} Icon`}
                    className={classes.ServiceLinkImage}
                    src={getImageSource(serviceItem)}
                  />
                </div>
                <span>{serviceItem.link.title}</span>
              </a>
            ))}
          </div>
        </Paper>
        }
      </Popover>
    </div>
  );
}

Services.propTypes = {
  classes: PropTypes.shape(),
  serviceItems: PropTypes.arrayOf(PropTypes.shape()),
};

export default withStyles(theme)(Services);
