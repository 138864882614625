import { get } from 'lodash';
import { convertHexToRGB } from '../../utils';

const styles = theme => ({
  listTitle: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.title'),
    marginBottom: 2,
  },
  textField: {
    padding: '10px 0',
    width: '99%',
    '& .MuiInputBase-root': {
      backgroundColor: get(theme, 'color.uiBackground'),
      borderRadius: get(theme, 'borderRadius.br3'),
      color: get(theme, 'color.bodyText'),
      fontFamily: get(theme, 'typography.bodyText'),
      fontSize: get(theme, 'fontSize.bodyText'),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '99%',
      '& input': {
        height: '40px',
        padding: '0 10px',
      },
      '& textarea': {
        padding: '0 10px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: convertHexToRGB(get(theme, 'color.primaryButtonBackground'), 0.4),
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.primaryButtonHover'),
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.primaryButtonBackground'),
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.error'),
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      backgroundColor: convertHexToRGB(get(theme, 'color.bodyText'), 0.1),
      borderColor: convertHexToRGB(get(theme, 'color.bodyText'), 0.5),
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 0',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 1,
    },
  },
  listContainer: {
    width: '99%',
    height: '98%',
    '& .MuiListItem-button:hover': {
      backgroundColor: '#c1e9fb',
    },
  },
  listSubtitle: {
    color: get(theme, 'color.bodyText'),
  },
  loadMore: {
    textTransform: 'none',
  },
  addItem: {
    textTransform: 'none',
    minWidth: 100,
  },
  checkbox: {
    height: 8,
    width: 8,
  },
  sizeIcon: {
    fontSize: 8,
  },
  listItem: {
    padding: 2,
    paddingLeft: 16,
    paddingRight: 16,
    '& .MuiCheckbox-root': {
      padding: '4px',
    },
  },
  listItemText: {
    color: get(theme, 'color.bodyText'),
    marginLeft: 8,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& .MuiTypography-root': {
      lineHeight: '1',
    },
  },
  listItemSingleSelected: {
    paddingLeft: 0,
    padding: 4,
  },
  listItemIcon: {
    minWidth: 0,
    '& .MuiSvgIcon-root': {
      paddingRight: '2px',
      width: '20px',
      height: '20px',
    },
  },
  listItemOption: {
    color: get(theme, 'color.primaryButtonBackground'),
    marginRight: 6,
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
  },
  listError: {
    color: get(theme, 'color.error'),
    borderColor: get(theme, 'color.error'),
  },
  lineError: {
    backgroundColor: get(theme, 'color.error'),
  },
  secondaryListText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 10,
    margin: 0,
    lineHeight: '1 !important',
  },
  divider: {
    backgroundColor: get(theme, 'color.borderColor'),
  },
});

export default styles;
