import get from 'lodash/get';

const styles = ({ color, spacing }) => ({
  root: {
    height: '100%',
    width: '100%',
    '& .MuiCircularProgress-colorPrimary': {
      color: get(color, 'primaryButtonBackground'),
    },
  },
  progress: {
    lineHeight: '600px',
    margin: spacing(2),
    textAlign: 'center',
  },
  pageLoader: {
    textAlign: 'center',
    lineHeight: '600px',
    left: 0,
    right: 0,
    top: 70,
    bottom: 0,
    position: 'fixed',
    background: get(color, 'overlayBackground'),
    opacity: '0.5',
    zIndex: '2300',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionLoader: {
    alignItems: 'center',
    background: get(color, 'overlayBackground'),
    display: 'flex',
    justifyContent: 'center',
    opacity: '0.5',
    zIndex: '2300',
  },
});

export default styles;
