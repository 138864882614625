import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Grid,
  Divider,
  FormLabel,
  Collapse,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isLength, isEmpty } from 'validator';
import { get, includes, isArray, map, some, find } from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import {
  FormActionButtons,
  ErrorContainer,
  FormTextField,
  FormSelect,
  FormDefaultTextField,
  RadioGroupSection,
  FileDropzone,
  AlertMessages,
} from '../../../../components';
import {
  SAVE_AND_NEXT_TEXT,
  API_DETAILS_TYPE,
  ID_REST,
  REST_TYPE,
  SOAP_TYPE,
  ID_SOAP,
  REST_FILE_HELPER_TEXT,
  SOAP_FILE_HELPER_TEXT,
  VALIDATION_FILE_NAME_UNIQUE_MESSAGE,
  VALIDATION_ONE_WSDL_MESSAGE,
  API_PUBLISH_STATE_TYPES,
  API_PUBLISH_STATE_NEW,
  API_PUBLISH_STATE_ENABLED,
  API_PUBLISH_STATE_INCOMPLETE,
} from '../labels';
import { isNumericVersion, getFileExtension } from '../../../../utils';
import { getI18n } from '../../../../utils/intl';
import {
  ALERT_ERROR,
  API_EDIT_ACCESS_STATUS,
} from '../../../../constants';

const MAX_LENGTH = 255;
const REST_FILE_TYPE = '.wadl,.json';
const SOAP_FILE_TYPE = '.wsdl,.xsd';
const MAX_BUNDLE_SIZE_IN_MB = 10;
const ALLOWED_FILE_EXTENSIONS_FOR_SOAP = ['wsdl', 'xsd'];
const ALLOWED_FILE_EXTENSIONS_FOR_REST = ['wadl', 'json'];
const WSDL_FILE_EXTENSION = 'wsdl';
const JSON_FILE_EXTENSION = 'json';
const MAX_FILE_SIZE = MAX_BUNDLE_SIZE_IN_MB * 1024 * 1024;
const MIME_TYPES = {
  xsd: 'application/x-xsd+xml',
  wsdl: 'application/octet-stream',
  json: 'application/json',
  wadl: 'application/vnd.sun.wadl+xml',
};

const isGatewayPublishedSoapAPI = ({ publishedByPortal, apiServiceType }) =>
  (publishedByPortal === false && apiServiceType === SOAP_TYPE);

export default function Details(props) {
  const {
    classes,
    errors,
    eulas,
    apiUuid,
    apiType,
    setApiType,
    isApiNameUnique,
    isProxyUrlUnique,
    apiDetails,
    assets = [],
    usage = {},
    onCancelDetails,
   } = props;

  const intl = getI18n(useIntl());
  const [apiFile, setApiFile] = useState({
    files: assets,
    acceptFileTypes: REST_FILE_TYPE,
    maxFiles: 1,
    error: false,
    helperText: REST_FILE_HELPER_TEXT,
    maxSize: MAX_FILE_SIZE,
  });
  const [apiName, setApiName] = useState('');
  const [portalStatus, setPortalStatus] = useState('');
  const [accessStatus, setAccessStatus] = useState('PRIVATE');
  const [isApiNameError, setIsApiNameError] = useState(false);
  const [isApiNameUniqueError, setIsApiNameUniqueError] = useState(false);
  const [isFileValidation, setIsFileValidation] = useState(false);
  const [fileValidationMessage, setFileValidationMessage] = useState('');
  const [version, setVersion] = useState('');
  const [isVersionError, setIsVersionError] = useState(false);
  const [location, setLocation] = useState('');
  const [isLocationError, setIsLocationError] = useState(false);
  const [eulaUuid, setEulaUuid] = useState('0');
  const [isEulaError, setIsEulaError] = useState(false);
  const [publicDescription, setPublicDescription] = useState('');
  const [isPublicDescriptionError, setIsPublicDescriptionError] = useState(false);
  const [privateDescription, setPrivateDescription] = useState('');
  const [isPrivateDescriptionError, setIsPrivateDescriptionError] = useState(false);
  const [proxyUrl, setProxyUrl] = useState('');
  const [isProxyUrlError, setIsProxyUrlError] = useState(false);
  const [isProxyUrlUniqueError, setIsProxyUrlUniqueError] = useState(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [isToggleForm, setIsToggleForm] = useState(false);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [filesToDelete, setIsFilesToDelete] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');

  useEffect(() => {
    if (apiDetails.apiServiceType) {
      const type = apiDetails.apiServiceType === REST_TYPE ? ID_REST : ID_SOAP;
      apiFile.acceptFileTypes = type === ID_REST ? REST_FILE_TYPE : SOAP_FILE_TYPE;
      apiFile.maxFiles = type === ID_REST ? 1 : 2;
      apiFile.helperText = type === ID_REST ? REST_FILE_HELPER_TEXT : SOAP_FILE_HELPER_TEXT;
      setApiFile({ ...apiFile, files: assets });
      setApiType(type);
      setIsToggleForm(true);
    }
    if (apiDetails.name) {
      setApiName(apiDetails.name);
    }
    if (apiDetails.portalStatus) {
      setPortalStatus(apiDetails.portalStatus);
    }
    if (apiDetails.version) {
      setVersion(apiDetails.version);
    }
    if (apiDetails.locationUrl) {
      setLocation(apiDetails.locationUrl);
    }
    if (apiDetails.accessStatus) {
      setAccessStatus(apiDetails.accessStatus);
    }
    if (apiDetails.apiEulaUuid) {
      setEulaUuid(apiDetails.apiEulaUuid);
    }
    if (apiDetails.description) {
      setPublicDescription(apiDetails.description);
    }
    if (apiDetails.privateDescription) {
      setPrivateDescription(apiDetails.privateDescription);
    }
    if (apiDetails.ssgUrl) {
      setProxyUrl(apiDetails.ssgUrl);
    }
  }, [apiDetails, assets]);

  useEffect(() => {
    setIsApiNameUniqueError(!isApiNameUnique);
    setIsProxyUrlUniqueError(!isProxyUrlUnique);
  }, [isApiNameUnique, isProxyUrlUnique, apiName, proxyUrl]);

  useEffect(() => {
    const apiNameErrorObject = errors.find(o => o.field === 'name');
    const proxyUrlErrorObject = errors.find(o => o.field === 'ssgUrl');
    setIsApiNameError(!!apiNameErrorObject);
    setIsProxyUrlError(!!proxyUrlErrorObject);
  }, [errors]);

  const hasError = (isRequired, value, max = MAX_LENGTH) => {
    let isError = false;
    isError = isRequired ? isEmpty(value) : isError;
    isError = !isError ? !isLength(value, { max }) : isError;
    return isError;
  };

  const handleChange = (fieldName, value) => {
    switch (fieldName) {
      case 'apiType': {
        const typeValue = get(value, 'target.value');
        apiFile.acceptFileTypes = typeValue === ID_REST ? REST_FILE_TYPE : SOAP_FILE_TYPE;
        apiFile.maxFiles = typeValue === ID_REST ? 1 : 2;
        apiFile.files = [];
        apiFile.error = false;
        apiFile.helperText = typeValue === ID_REST ? REST_FILE_HELPER_TEXT : SOAP_FILE_HELPER_TEXT;
        setApiFile(apiFile);
        setApiType(get(value, 'target.value') || ID_REST);
        if (typeValue !== ID_REST) {
          setIsToggleForm(true);
        }
        break;
      }
      case 'apiName': {
        const apiUiError = hasError(true, value);
        setApiName(value);
        setIsApiNameError(apiUiError);
        break;
      }
      case 'version': {
        const isError = hasError(true, value) || isNumericVersion(value);
        setIsVersionError(isError);
        setVersion(value);
        break;
      }
      case 'location':
        setIsLocationError(hasError(true, value));
        setLocation(value);
        break;
      case 'accessStatus':
        setAccessStatus(value);
        break;
      case 'eula': {
        if (value === '0') {
          setEulaUuid(value);
          setIsEulaError(true);
        } else {
          setIsEulaError(false);
          setEulaUuid(value);
        }
        break;
      }
      case 'publicDescription':
        setIsPublicDescriptionError(hasError(false, value));
        setPublicDescription(value);
        break;
      case 'privateDescription':
        setIsPrivateDescriptionError(hasError(false, value));
        setPrivateDescription(value);
        break;
      case 'proxyUrl': {
        const UrlUiError = hasError(true, value, 127);
        setProxyUrl(value);
        setIsProxyUrlError(UrlUiError);
        break;
      }
      default:
        break;
    }
    localStorage.setItem('isUnSavedChanges', true);
    setIsUnsavedChanges(true);
  };

  const onCheckApiNameUnique = async (name, currentId) => {
    await props.checkApiNameUnique(name, currentId);
  };

  const onCheckProxyUrlUnique = async (url) => {
    await props.checkProxyUrlUnique(url);
  };

  const handleBlur = (fieldName, value) => {
    switch (fieldName) {
      case 'apiName': {
        if (value) {
          onCheckApiNameUnique(value, apiUuid);
        } else {
          setIsApiNameError(true);
        }
        break;
      }
      case 'proxyUrl':
        if (value) {
          onCheckProxyUrlUnique(value);
        } else {
          setIsProxyUrlError(true);
        }
        break;
      default:
        break;
    }
  };

  const hasWsdlForSoap = () => {
    const exts = map(apiFile.files, (file => file.ext));
    return includes(exts, 'wsdl');
  };

  const onNextDetails = async (
    data, initialAssets, uploadedAssets, hasUploadFile, filesDelete,
  ) => {
    if (apiUuid) {
      const details = data;
      // Gateway published api: To change the portal status as enabled when the status is new
      if (details.publishedByPortal === false && details.portalStatus === API_PUBLISH_STATE_NEW) {
        details.portalStatus = API_PUBLISH_STATE_ENABLED;
      }
      if (isGatewayPublishedSoapAPI(details)) {
        await props.updateApiDetails(apiUuid, details);
      } else {
        await props.updateApiDetailsWithAssets(apiUuid, details, initialAssets,
          uploadedAssets, hasUploadFile, filesDelete);
      }
    } else {
      await props.saveApi(data, uploadedAssets);
    }
  };

  const onNext = () => {
    setIsToggleForm(true);
    const apiNameUiError = hasError(true, apiName);
    const versionUiError = hasError(true, version);
    const locationUiError = (apiDetails.publishedByPortal !== false) && hasError(true, location);
    const eulaUuidUiError = (eulaUuid === '0');
    const publicDescriptionUiError = hasError(false, publicDescription);
    const privateDescriptionUiError = hasError(false, privateDescription);
    const proxyUrlUiError = hasError(true, proxyUrl, 127);
    const soapFileError = apiType === ID_REST ? false : !hasWsdlForSoap();
    if (some([
      apiNameUiError, locationUiError, eulaUuidUiError, proxyUrlUiError,
      isProxyUrlError, isApiNameError, soapFileError,
    ])) {
      if (apiNameUiError || isApiNameError) {
        setIsApiNameError(true);
      }
      setIsVersionError(versionUiError);
      setIsLocationError(locationUiError);
      setIsEulaError(eulaUuidUiError);
      if (proxyUrlUiError || isProxyUrlError) {
        setIsProxyUrlError(true);
      }
      if (soapFileError) {
        apiFile.error = true;
        setApiFile({ ...apiFile });
      }
      setNotificationStatus(ALERT_ERROR);
      setNotificationMessage(intl.getI18nMessage('error.required.fields.missing'));
    } else if (isApiNameUniqueError) {
      setNotificationStatus(ALERT_ERROR);
      setNotificationMessage(intl.getI18nMessage('error.api.field.unique.name'));
    } else if (isProxyUrlUniqueError) {
      setNotificationStatus(ALERT_ERROR);
      setNotificationMessage(intl.getI18nMessage('error.api.field.unique.proxyUrl'));
    } else if (some([
      publicDescriptionUiError, privateDescriptionUiError,
    ])) {
      setIsPublicDescriptionError(publicDescriptionUiError);
      setIsPrivateDescriptionError(privateDescriptionUiError);
      setNotificationStatus(ALERT_ERROR);
      setNotificationMessage(intl.getI18nMessage('error.form'));
    } else {
      setNotificationStatus('');
      setNotificationMessage('');
      localStorage.setItem('isUnSavedChanges', false);
      setIsUnsavedChanges(false);
      setIsFileValidation(false);
      setFileValidationMessage('');
      const uploadFileFlag = isUploadFile;
      setIsUploadFile(false);
      const serviceType = apiType === ID_REST ? REST_TYPE : SOAP_TYPE;
      onNextDetails({
        ...apiDetails,
        serviceType,
        apiName,
        portalStatus,
        accessStatus,
        version,
        location,
        eulaUuid,
        publicDescription,
        privateDescription,
        proxyUrl,
      }, assets, apiFile, uploadFileFlag, filesToDelete);
    }
  };

  const onCancel = () => {
    onCancelDetails(isUnsavedChanges);
  };

  const populateFieldsFromFile = (payload) => {
    try {
      const parsedResult = JSON.parse(payload);
      if (parsedResult.info) {
        if (parsedResult.info.title) {
          handleChange('apiName', parsedResult.info.title);
          onCheckApiNameUnique(parsedResult.info.title);
        }
        if (parsedResult.info.version) {
          handleChange('version', parsedResult.info.version);
        }
        if (parsedResult.info.description) {
          handleChange('publicDescription', parsedResult.info.description);
        }
        if (parsedResult.host) {
          const protocol = typeof parsedResult.schemes !== 'undefined' &&
          parsedResult.schemes.length > 0 ? parsedResult.schemes[0] : 'http';
          const host = `${protocol}://${parsedResult.host}${parsedResult.basePath}`;
          handleChange('location', host);
        }

        const eulaStandard = find(eulas, { name: 'Standard EULA' });
        if (eulaStandard && eulaStandard.uuid) {
          setEulaUuid(eulaStandard.uuid);
        }
      }
    } catch (e) {
      setIsFileValidation(true);
      setFileValidationMessage(intl.getI18nMessage('error.api.file.invalid.json.swagger'));
    }
  };

  const isValidFile = (file, allowedExtension) => {
    const extension = getFileExtension(file.name);
    let allowedFileExtensions = allowedExtension;
    if (!allowedFileExtensions) {
      allowedFileExtensions = apiType === ID_REST ?
      ALLOWED_FILE_EXTENSIONS_FOR_REST : ALLOWED_FILE_EXTENSIONS_FOR_SOAP;
    }
    if (includes(allowedFileExtensions, extension)) {
      return true;
    }
    return false;
  };

  const fileLoad = (file) => {
    const reader = new FileReader();
    reader.onabort = () => {
      apiFile.error = true;
      setApiFile({ ...apiFile });
    };
    reader.onerror = () => {
      apiFile.error = true;
      setApiFile({ ...apiFile });
    };
    reader.onload = () => {
      if (isValidFile(file)) {
        // Todo: Refactor the below code
        apiFile.error = false;
        setIsFileValidation(false);
        setFileValidationMessage('');
        const fileContent = reader.result;
        const fileExtenstion = getFileExtension(file.name);
        const fileData = file;
        fileData.ext = fileExtenstion;
        if (apiType === ID_REST) {
          if (!apiUuid && fileExtenstion === JSON_FILE_EXTENSION) {
            populateFieldsFromFile(fileContent);
          }
        }
        fileData.blob = new Blob(
          [fileContent],
          { type: MIME_TYPES[fileExtenstion] },
        );
        if (apiType === ID_REST) {
          apiFile.files = [fileData];
          setApiFile({ ...apiFile });
        } else if (fileExtenstion === WSDL_FILE_EXTENSION) {
          if (hasWsdlForSoap(fileData)) {
            setIsFileValidation(true);
            setFileValidationMessage(VALIDATION_ONE_WSDL_MESSAGE);
            setApiFile({ ...apiFile });
          } else {
            apiFile.files.push(fileData);
            setApiFile({ ...apiFile });
          }
        } else if (some(apiFile.files, { name: file.name })) {
          setIsFileValidation(true);
          setFileValidationMessage(VALIDATION_FILE_NAME_UNIQUE_MESSAGE);
        } else {
          apiFile.files.push(fileData);
          setApiFile({ ...apiFile });
        }
      } else {
        apiFile.error = true;
        setApiFile({ ...apiFile });
      }
    };

    reader.readAsText(file);
  };

  const handleFiles = (files) => {
    setIsToggleForm(true);
    if (apiUuid) {
      setIsUploadFile(true);
    }
    if (isArray(files)) {
      files.forEach((file) => {
        fileLoad(file);
      });
    } else {
      fileLoad(files);
    }
  };

  const onRemoveFile = (file) => {
    if (apiUuid && file.uuid && !includes(filesToDelete, file.uuid)) {
      filesToDelete.push(file.uuid);
      setIsFilesToDelete(filesToDelete);
    }
    const remainApiFiles = apiFile.files.filter((obj) => obj.name !== file.name);
    setApiFile({ ...apiFile, files: remainApiFiles });
  };

  const selectedEula = find(eulas, ({ uuid }) => (uuid === eulaUuid));
  const { directAppUsage = 0, indirectAppUsage = 0 } = usage;

  return (
    <div id="api-details-container">
      {notificationMessage &&
        <AlertMessages
          id="api-details-notifications"
          message={notificationMessage}
          variant={notificationStatus}
          onClose={() => {
            setNotificationMessage('');
            setNotificationStatus('');
          }}
        />
      }
      {errors.length > 0 &&
        <ErrorContainer errors={errors} />
      }
      <Typography variant="h1" className={classes.pageTitle}>
        {intl.getI18nMessage('label.details')}
      </Typography>
      {!isGatewayPublishedSoapAPI(apiDetails) &&
        <div className={classes.fileContainer}>
          <Grid md={12} item>
            <div className={classes.apiTypeContainer}>
              <FormLabel component="h4" className={classes.formLabel}>API Type</FormLabel>
              {apiUuid ?
                <div className={classes.apiServiceType}>{apiDetails.apiServiceType}</div>
                :
                <div>
                  <RadioGroupSection
                    selected={apiType}
                    onChange={(value) => handleChange('apiType', value)}
                    data={API_DETAILS_TYPE}
                    id="api-details-type"
                  />
                </div>
              }
            </div>
            <FileDropzone
              handleFiles={handleFiles}
              selectedFiles={apiFile}
              helperText={''}
              title={'Upload Definition File'}
              id={'api-file-upload'}
              onRemoveFile={onRemoveFile}
              isValidation={isFileValidation}
              validationMessage={fileValidationMessage}
            />
          </Grid>
        </div>
      }
      <div
        className={clsx(classes.pageTitle, classes.expandManualContainer)}
        onClick={() => setIsToggleForm(!isToggleForm)}
      >
        <div>
          <h2 className={classes.manualContainer}>Add API Details</h2>
        </div>
        <div className={classes.expandIconContainer}>
          {!isToggleForm ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </div>
      </div>
      <Divider className={classes.detailDivider} />
      <Collapse in={isToggleForm}>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid md={12} item>
            <FormTextField
              id={'api-details-name'}
              name={'API Name'}
              value={apiName}
              error={isApiNameError || isApiNameUniqueError}
              handleChange={(value) => handleChange('apiName', value)}
              handleBlur={(value) => handleBlur('apiName', value)}
              helperText={'Maximum name length is 255 characters. Name must be unique.'}
            />
            {apiUuid && (apiDetails.portalStatus !== API_PUBLISH_STATE_INCOMPLETE) &&
              <div id="api-publish-state-container">
                <div className={classes.formLabel}>Publish State</div>
                {(apiDetails.portalStatus !== API_PUBLISH_STATE_NEW) ?
                  <RadioGroupSection
                    selected={portalStatus}
                    onChange={(e) => setPortalStatus(e.target.value)}
                    data={API_PUBLISH_STATE_TYPES}
                    id="api-publish-state"
                    isDisable
                    disableOptions={apiDetails.possibleStatuses}
                    defaultSelect={apiDetails.portalStatus}
                  />
                :
                  <div id="api-publish-state-new" className={classes.statusContainer}>
                    {'Unpublished'}
                    <FormHelperText>
                      The API will be enabled after save.
                    </FormHelperText>
                  </div>
                }
              </div>
            }
            <FormTextField
              id={'api-details-version'}
              name={'Version'}
              value={version}
              error={isVersionError}
              handleChange={(value) => handleChange('version', value, 50)}
              helperText={'The value for this field can only contain 0-9 and delimited with . _ and - characters.'}
            />
            {(apiDetails.publishedByPortal !== false) &&
              <FormTextField
                id={'api-details-location'}
                name={intl.getI18nMessage('label.api.location')}
                value={location}
                error={isLocationError}
                handleChange={(value) => handleChange('location', value)}
                helperText={intl.getI18nMessage('label.api.location.help')}
              />
            }
            <FormSelect
                id={'api-details-access-status'}
                name={'Access'}
                value={accessStatus}
                data={API_EDIT_ACCESS_STATUS}
                handleChange={(value) => handleChange('accessStatus', value)}
                helperText={'Control organization visibility with private APIs.'}
                noNativeSelect
                noNoneOption
              />
            {(directAppUsage !== 0 || indirectAppUsage !== 0) ?
              <FormTextField
                id={'api-details-eula'}
                name={'API EULA'}
                value={get(selectedEula, 'name')}
                error={isEulaError}
                helperText={'The EULA can\'t be changed after it has been added to an application.'}
                disabled
              /> :
              <FormSelect
                id={'api-details-eula'}
                name={'API EULA'}
                value={eulaUuid}
                error={isEulaError}
                data={eulas}
                handleChange={(value) => handleChange('eula', value)}
                helperText={'Select at least one.'}
                defaultText={'Select an API EULA.'}
                noNativeSelect
              />
            }
            <FormTextField
              id={'api-details-public-description'}
              name={'Public Description'}
              value={publicDescription}
              error={isPublicDescriptionError}
              handleChange={(value) => handleChange('publicDescription', value)}
              helperText={'Maximum description length is 255 characters.'}
              multiline
              rows={4}
              optional
            />
            <FormTextField
              id={'api-details-private-description'}
              name={'Private Description'}
              value={privateDescription}
              error={isPrivateDescriptionError}
              handleChange={(value) => handleChange('privateDescription', value)}
              helperText={'Maximum description length is 255 characters.'}
              multiline
              rows={4}
              optional
            />
            <FormDefaultTextField
              id={'api-proxy-details'}
              name={intl.getI18nMessage('label.api.proxyUrl')}
              value={proxyUrl}
              error={isProxyUrlError || isProxyUrlUniqueError}
              defaultText={intl.getI18nMessage('label.api.proxyUrl.default')}
              handleChange={(value) => handleChange('proxyUrl', value, 127)}
              handleBlur={(value) => handleBlur('proxyUrl', value, 127)}
              helperText={intl.getI18nMessage('label.api.proxyUrl.help')}
              isDisabled={!!apiUuid}
            />
          </Grid>
        </Grid>
      </Collapse>
      <FormActionButtons
        onNextClick={onNext}
        nextText={SAVE_AND_NEXT_TEXT}
        onCancelClick={onCancel}
        id="visibility-form-buttons"
      />
    </div>
  );
}

Details.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  eulas: PropTypes.arrayOf(PropTypes.object),
  apiUuid: PropTypes.string,
  apiType: PropTypes.string,
  setApiType: PropTypes.func,
  isApiNameUnique: PropTypes.bool,
  isProxyUrlUnique: PropTypes.bool,
  apiDetails: PropTypes.object,
  assets: PropTypes.arrayOf(PropTypes.object),
  usage: PropTypes.object,
  onCancelDetails: PropTypes.func,
  checkApiNameUnique: PropTypes.func,
  checkProxyUrlUnique: PropTypes.func,
  updateApiDetails: PropTypes.func,
  updateApiDetailsWithAssets: PropTypes.func,
  saveApi: PropTypes.func,
};
