import React from 'react';
import { unescape, get } from 'lodash';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';

import styles from './styles';

export default function ErrorContainer(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { errors } = props;
  
  return (
    <div className={classes.errorContainer}>
      <Grid container direction="row">
        <WarningIcon className={classes.errorIcon} />
        <h3 className={classes.errorTitle}>Errors ({errors.length})</h3>
      </Grid>
      <Grid container direction="row">
        <ul>
          {errors && errors.map((error, i) => {
            const message = unescape(error.error || error.message);
            const field = get(error, 'field') ? `${get(error, 'field')}:`: '';
            return (
              <li key={i.toString()}>
                <div className={classes.errorSubtitleText}>{field} {message}</div>
              </li>
            );
          })
          }
        </ul>
      </Grid>
    </div>
  );
}

ErrorContainer.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({})),
};
