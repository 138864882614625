import { get } from 'lodash';
import * as Constants from '../../constants';

const resetState = {
  isError: false,
  errors: [],
};
const initialState = {
  ...resetState,
  isLoading: false,
  users: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.APP_RESET:
      return {
        ...state,
        ...resetState,
        isUserDeleteSuccess: false,
      };
    case Constants.USERS_DELETE_RESET:
      return {
        ...state,
        ...resetState,
        isUserDeleteSuccess: false,
      };
    case Constants.APP_LOADING:
      return {
        ...state,
        ...resetState,
        isLoading: action.isLoading,
      };
    case Constants.USERS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        users: action.payload,
        isUserDeleteSuccess: localStorage.getItem('isUserDeleteSuccess') === 'true',
      };
    case Constants.USERS_GET_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
        isUserDeleteSuccess: localStorage.getItem('isUserDeleteSuccess') === 'true',
      };
    default:
      return state;
  }
}

export const getIsLoading = (state) => get(state, 'user.isLoading');
export const getIsError = (state) => get(state, 'user.isError');
export const getErrors = state => get(state, 'user.errors');
export const getIsUserDeleteSuccess = state => get(state, 'user.isUserDeleteSuccess');

export const getUserListTotalElements = (state) => get(state, 'user.users.count');
export const getUserListResults = (state) => get(state, 'user.users.list');
