import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  makeStyles,
  FormLabel,
 } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { includes } from 'lodash';
import styles from './styles';


export default function RadioGroupSection(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    selected,
    data,
    id,
    name,
    isDisable = false,
    onChange,
    disableOptions = [],
    defaultSelect = '',
  } = props;

  const getIsDisabled = (obj) => {
    if (isDisable && obj.id !== defaultSelect) {
      return !includes(disableOptions, obj.id);
    }
    return false;
  };

  const getDefaultLabel = (nameParam, labelClass) =>
    <Typography className={labelClass}>{nameParam}</Typography>;

  return (
    <FormControl
      component="fieldset"
      className={classes.formControl}
      id={id}
      data-apim-test={id}
      data-layer7-test={id}
    >
      {name &&
        <FormLabel className={classes.formLabel} >
          {name}
        </FormLabel>
      }
      <RadioGroup
        value={selected}
        onChange={(e) => onChange(e)}
        className={classes.radioGroupContainer}
      >
        {data.map(obj => {
          const disabled = getIsDisabled(obj);
          const labelClass = disabled ? clsx(classes.radioLabel, classes.disabled)
            : classes.radioLabel;
          const titleClass = disabled ? clsx(classes.radioSubtitle, classes.disabled)
            : classes.radioSubtitle;
          return (
            <div key={obj.id}>
              <FormControlLabel
                value={obj.id}
                control={<Radio />}
                label={obj.labelComponent || getDefaultLabel(obj.name, labelClass)}
                disabled={disabled}
              />
              <div className={titleClass}>{obj.description}</div>
              <br />
            </div>
          );
        },
        )}
      </RadioGroup>
    </FormControl>
  );
}


RadioGroupSection.propTypes = {
  selected: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  isDisable: PropTypes.bool,
  disableOptions: PropTypes.arrayOf(PropTypes.string),
  defaultSelect: PropTypes.string,
};
