import { get } from "lodash";

const styles = ({ color, fontSize, typography }) => ({
  assetText: {
    fontFamily: get(typography, 'bodyText') + ` !important`,
    fontSize: get(fontSize, 'bodyText') + ` !important`,
    color: get(color, 'bodyText') + ` !important`,
    backgroundColor: 'transparent !important',
    outline: 'transparent !important',
    marginBottom: '0px !important',
    marginTop: '0px !important',
  },

  assetLinkText: {
    fontFamily: get(typography, 'bodyText') + ` !important`,
    // hardcode fontsize for now until we handle it explicitly through Appearance page
    fontSize: '10px !important',
    backgroundColor: 'transparent',
    outline: 'transparent !important',
    marginBottom: '0px !important',
    marginTop: '0px !important',
    color: get(color, 'smallText'),
    '& span': {
      color: get(color, 'link') + ` !important`,
    },
    '&:hover span': {
      color: get(color, 'linkHover') + ` !important`,
    },
  },

  truncateText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  assetLinkStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  noTextUnderline: {
    textDecoration: 'none',
  },

  removeSpacing: {
    marginBottom: '0px !important',
    marginTop: '0px !important',
  },

  customBullets: {
    listStyleType: 'none',
    position: 'relative',
    '&::before': {
      content: '""',
      width: '3px',
      height: '3px',
      backgroundColor: '#000000',
      position: 'absolute',
      top: '50%',
      left: '-10px',
      transform: 'translateY(-50%)',
      borderRadius: '50px',
    },
  },

  downloadButton: {
    color: get(color, 'secondaryButtonText') + ` !important`,
    backgroundColor: get(color, 'secondaryButtonText') + ` !important`,
    borderColor: get(color, 'secondaryButtonBorder') + ` !important`,
    borderRadius: 4,
    height: 36,
    fontFamily: get(typography, 'bodyText') + ` !important`,
    marginTop: '20px !important',
    '&:hover': {
      backgroundColor: get(color, 'secondaryButtonBackgroundHover') + ` !important`,
      borderColor: get(color, 'secondaryButtonBorderHover') + ` !important`,
    },
    '&:focus': {
      boxShadow: 'none !important',
    },
    // text in button
    '& > span > span': {
      fontWeight: 100,
      fontSize: get(fontSize, 'buttonText') + ` !important`,
    },
  },
});

export default styles;
