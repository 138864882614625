import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { withStyles, Grid } from '@material-ui/core';
import { bool, func, shape, string } from 'prop-types';

import {
  AlertDialog,
  AlertMessages,
  HelpContainer,
  Loading,
} from '../components';
import { getI18n } from '../utils/intl';
import styles from './edit.styles';

export const Edit = (props) => {
  const {
    classes,
    editPageId,
    dialogId,
    isDialogOpen,
    onDialogClose,
    dialogSubmitText,
    onDialogSubmit,
    isLoading,
    leftSidebarId,
    mainContentId,
    notificationId,
    notificationStatus,
    setNotificationStatus,
    notificationMessage,
    setNotificationMessage,
    tabsContainer,
    pageHeaderRef,
    pageHeaderTitle,
    pageContent,
    rightSidebarId,
    helpItems,
  } = props;

  const intl = getI18n(useIntl());

  return (
    <div
      className={classes.content}
      id={editPageId}
      data-apim-test={editPageId}
    >
      <AlertDialog
        isOpen={isDialogOpen}
        title={intl.getI18nMessage('label.dialog.unsaved.changes.title')}
        description={intl.getI18nMessage('label.dialog.unsaved.changes.description')}
        submitText={dialogSubmitText}
        cancelText={intl.getI18nMessage('label.dialog.unsaved.changes.cancel')}
        onClose={onDialogClose}
        onSubmit={onDialogSubmit}
        onCancel={onDialogClose}
        dialogId={dialogId}
      />
      { isLoading ?
        <Loading />
        :
        <Grid container className={classes.gridContainer}>
          <Grid
            md={2} item
            className={classes.leftSidebar}
            id={leftSidebarId} data-apim-test={leftSidebarId}
          >
            {tabsContainer}
          </Grid>
          <Grid
            md={7} item
            className={classes.mainContent}
            id={mainContentId} data-apim-test={mainContentId}
          >
            {notificationMessage &&
              <AlertMessages
                id={notificationId} data-apim-test={notificationId}
                variant={notificationStatus}
                message={notificationMessage}
                onClose={() => {
                  setNotificationMessage('');
                  setNotificationStatus('');
                }}
              />
            }
            <div className={classes.pageHeaderTitle} ref={pageHeaderRef}>{pageHeaderTitle}</div>
            <Fragment>{pageContent}</Fragment>
          </Grid>
          <Grid
            md={3} item
            className={classes.rightSidebar}
            id={rightSidebarId} data-apim-test={rightSidebarId}
          >
            <HelpContainer helpItems={helpItems} />
          </Grid>
        </Grid>
      }
    </div>
  );
};

Edit.propTypes = {
  classes: shape({}),
  editPageId: string,
  dialogId: string,
  isDialogOpen: bool,
  onDialogClose: func,
  dialogSubmitText: string,
  onDialogSubmit: func,
  isLoading: bool,
  leftSidebarId: string,
  mainContentId: string,
  notificationId: string,
  notificationStatus: string,
  setNotificationStatus: func,
  notificationMessage: string,
  setNotificationMessage: func,
  tabsContainer: shape({}),
  pageHeaderRef: shape({}),
  pageHeaderTitle: string,
  pageContent: shape({}),
  rightSidebarId: string,
  helpItems: shape({}),
};

export default compose(
  withStyles(styles),
)(Edit);

