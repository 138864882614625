// @flow
import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import theme from './index.theme';

const Link = ({
  classes = {},
  link,
  useExternalNavLinks,
}: Object) => (
  <li className={classes.HeaderNavLink}>
    {useExternalNavLinks ?
      (
        <a
          className={classes.button}
          href={link.href}
        >
          {link.title}
        </a>
      ) : (
        <NavLink
          className={classes.button}
          to={link.href}
          activeClassName={classes.active}
        >
          {link.title}
        </NavLink>
      )
    }
  </li>
);

export default withStyles(theme)(Link);
