import React from 'react';
import { Grid } from '@material-ui/core';

import { FormTextField, FormSelect } from '../../../../components';

import {
  PROXY_APIS_SORT_BY,
  PROXY_APIS_KEY_STATUS_FILTER_BY,
  APIS_DEPLOYMENT_STATUS_FILTER_BY,
} from '../../../../constants';

export const FilterByKeyName = (props) => (
  <Grid item md={2}>
    <FormTextField
      {...props}
      id="proxy-details-keys-filter-by-key-name"
      data-apim-test="proxy-details-keys-filter-by-key-name"
    />
  </Grid>
);

export const FilterByApiKey = (props) => (
  <Grid item md={3}>
    <FormTextField
      {...props}
      id="proxy-details-keys-filter-by-api-key"
      data-apim-test="proxy-details-keys-filter-by-api-key"
    />
  </Grid>
);

export const FilterByDeploymentStatus = (props) => (
  <Grid item md={2}>
    <FormSelect
      {...props}
      id="proxy-details-keys-filter-status-by"
      data-apim-test="proxy-details-keys-filter-status-by"
      data={APIS_DEPLOYMENT_STATUS_FILTER_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterByKeyStatus = (props) => (
  <Grid item md={2}>
    <FormSelect
      {...props}
      id="proxy-details-keys-filter-by-key-status"
      data-apim-test="proxy-details-keys-filter-by-key-status"
      data={PROXY_APIS_KEY_STATUS_FILTER_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const SortBy = (props) => (
  <Grid item md={2}>
    <FormSelect
      {...props}
      id="proxy-details-keys-sort-by"
      data-apim-test="proxy-details-keys-sort-by"
      data={PROXY_APIS_SORT_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterAndSortSeparator = () => (
  <Grid item md={1} />
);
