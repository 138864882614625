import { get } from 'lodash';

const styles = ({ color }) => ({
  copyUrl: {
    padding: 0,
    '& > span > p': {
      color: get(color, 'link'),
    },
  },
});

export default styles;
