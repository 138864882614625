import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { map, isEmpty, get } from 'lodash';
import clsx from 'clsx';
import { bool, shape, arrayOf, func, string } from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Collapse,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  AlertDialog,
} from '../../../../components';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import styles from './styles';
import {
  ALERT_ERROR,
} from '../../../../constants';

export const processErrors = (errors, defaultMessage) => {
  let errorMessage;
  if (errors.length > 0) {
    errorMessage = map(errors, (validationError) => validationError.error).join('\n');
  }
  return errorMessage || defaultMessage;
};


export const getActions = ({
  props,
  eulaUuid,
  openEditDialog,
  openDeleteDialog,
  userContext,
  eulaApisList,
}) => {
  const intl = getI18n(useIntl());
  const isApiUsed = eulaApisList.length >= 1;
  return [
    {
      key: 'edit',
      value: intl.getI18nMessage('label.eula.details.edit.button'),
      onClick: () => {
        if(isApiUsed) {
          openEditDialog();
        } else {
          props.push(`/publish/eulas/edit/${eulaUuid}`);
        }
      },
      visible: get(userContext, 'permissions.API_EULA')
      && userContext.permissions.API_EULA.includes('UPDATE'),
      disabled: false,
    },
    {
      key: 'delete',
      value: intl.getI18nMessage('label.eula.details.delete.button'),
      onClick: () => {
        if(isApiUsed) { return; }
        openDeleteDialog();
      },
      visible: get(userContext, 'permissions.API_EULA')
      && userContext.permissions.API_EULA.includes('DELETE'),
      disabled: isApiUsed,
      disabledTooltip: isApiUsed ? getI18nFormattedMessage('label.eula.actions.delete.disabled.tooltip') : '',
    },
  ];
}

export const ActionsRaw = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    notifyMessages,
    deleteEulaErrors,
    isEulaDeleteSuccess,
    eulaUuid,
    userContext,
    eulaApisList,
  } = props;

  const intl = getI18n(useIntl());
  const [open, setOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const handleClick = () => { setOpen(!open); };
  const handleClickAway = () => { setOpen(false); };
  const openDeleteDialog = () => setShowDeleteDialog(true);
  const closeDeleteDialog = () => setShowDeleteDialog(false);
  const openEditDialog = () => setShowEditDialog(true);
  const closeEditDialog = () => setShowEditDialog(false);

  const onDelete = () => {
    props.deleteEula(eulaUuid);
    closeDeleteDialog();
  };

  const onEdit = () => {
    props.push(`/publish/eulas/edit/${eulaUuid}`);
    closeDeleteDialog();
  };

  const actions = getActions({
    props,
    eulaUuid,
    openDeleteDialog,
    openEditDialog,
    userContext,
    eulaApisList,
  });

  useEffect(() => {
    if (isEulaDeleteSuccess) { props.push('/publish/eulas'); }
  }, [isEulaDeleteSuccess]);

  useEffect(() => {
    if (!isEmpty(deleteEulaErrors)) {
      notifyMessages(
        ALERT_ERROR,
        processErrors(deleteEulaErrors, intl.getI18nMessage('error.eula.delete')),
      );
    }
  }, [deleteEulaErrors]);

  return (
    <div
      id="eula-details-actions-container"
      data-apim-test={'eula-details-actions-container'}
      className={classes.actionsContainer}
    >
      {showDeleteDialog &&
        <AlertDialog
          id="eula-details-delete-dialog"
          isOpen={showDeleteDialog}
          title={intl.getI18nMessage('label.delete.confirmation.title')}
          description={intl.getI18nMessage('label.eula.details.delete.confirmation.text')}
          submitText={intl.getI18nMessage('label.delete.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={closeDeleteDialog}
          onSubmit={onDelete}
          onCancel={closeDeleteDialog}
          submitButtonClass={classes.submitButton}
        />
      }
      {showEditDialog &&
        <AlertDialog
          id="eula-details-edit-dialog"
          isOpen={showEditDialog}
          title={intl.getI18nMessage('label.eula.edit.confirmation.title')}
          description={intl.getI18nMessage('label.eula.details.edit.confirmation.text')}
          submitText={intl.getI18nMessage('label.eula.edit.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={closeEditDialog}
          onSubmit={onEdit}
          onCancel={closeEditDialog}
          submitButtonClass={classes.submitButton}
        />
      }
      <ClickAwayListener onClickAway={handleClickAway}>
        <List component="nav">
          <ListItem id="eula-details-actions-button" data-apim-test="eula-details-actions-button" classes={{ root: classes.listButton }} button onClick={handleClick}>
            <ListItemText primary={getI18nFormattedMessage('label.actions.button')} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse className={classes.collapseContainer} in={open}>
            <List className={classes.list} component="div" disablePadding>
              {actions
                .filter(({ visible }) => visible)
                .map(({ key, value, disabled, disabledTooltip, onClick }) => (
                  disabledTooltip ?
                    <Tooltip
                      arrow disableFocusListener disableTouchListener placement="top"
                      title={disabledTooltip}
                      key={key}
                    >
                      <ListItem
                        key={key}
                        id={key} data-apim-test={key} data-layer7-test={key}
                        button
                        className={clsx('action', classes.listItem)}
                        disabled={disabled}
                        onClick={onClick}
                      >
                        <ListItemText primary={value} />
                      </ListItem>
                    </Tooltip>
                  :
                    <ListItem
                      key={key}
                      id={key} data-apim-test={key} data-layer7-test={key}
                      button
                      className={clsx('action', classes.listItem)}
                      disabled={disabled}
                      onClick={onClick}
                    >
                      <ListItemText primary={value} />
                    </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </ClickAwayListener>
    </div>
  );
};

ActionsRaw.propTypes = {
  notifyMessages: func,
  eulaUuid: string,
  deleteEulaErrors: arrayOf(shape({})),
  userContext: shape({}),
  isEulaDeleteSuccess: bool,
  deleteEula: func,
  eulaApisList: arrayOf(shape({})),
  push: func,
};
ActionsRaw.displayName = 'Actions';

export default ActionsRaw;
