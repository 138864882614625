export const APPLICATION = 'Application';
export const API = 'API';
export const ORGANIZATION = 'Organization';
export const PROXY = 'Proxy';
export const API_PLAN = 'API Plan';
export const API_GROUP = 'API Group';
export const USER = 'User';
export const RATE_AND_QUOTA_PLAN = 'Rate Limit & Quota';
export const HELLO = 'Hello';
export const GO = 'Go';
export const COLLAPSE_PANEL = 'Collapse Panel';
export const EXPAND_PANEL = 'Expand Panel';
export const CHART = 'Chart';
export const TOP_3_APIS = 'Top 3 APIs';
export const TOP_3_ORGS = 'Top 3 Organizations';
export const TOP_3_APPS = 'Top 3 Applications';
export const TOP_3_PROXIES = 'Top 3 Proxies';
export const UNAUTHORIZED = 'UNAUTHORIZED';

export const MOVE_PANEL_UP = 'Move Panel Up';
export const MOVE_PANEL_DOWN = 'Move Panel Down';
export const APIS_LABEL = 'APIS';
export const APPLICATIONS_LABEL = 'APPLICATIONS';
export const API_GROUPS_LABEL = 'API GROUPS';
export const PROXIES_LABEL = 'PROXIES';
export const USERS_LABEL = 'USERS';
export const RATE_AND_QUOTA_PLANS_LABEL = 'RATE LIMITS & QUOTAS';
export const API_PLANS_LABEL = 'API PLANS';
export const ORGANIZATIONS_LABEL = 'ORGANIZATIONS';
export const APIS_TRAFFIC = 'APIs: Traffic';
export const APPLICATIONS_TRAFFIC = 'Applications: Traffic';
export const ORGANIZATIONS_TRAFFIC = 'Organizations: Traffic';
export const APIS_PANEL_TITLE = 'APIs';
export const APPS_PANEL_TITLE = 'Applications';
export const ORGS_PANEL_TITLE = 'Organizations';
export const PROXIES_PANEL_TITLE = 'Proxies';
export const PROXIES_TRAFFIC = 'Proxies: Traffic';
export const APPLICATION_ENTITY = 'app';
export const API_ENTITY = 'api';
export const ORGANIZATION_ENTITY = 'org';
export const PROXY_ENTITY = 'proxy';
export const ADD_TO_CHART = 'Add to chart';
export const REMOVE_FROM_CHART = 'Remove from chart';

export const TRAFFIC = 'TRAFFIC';


export const GUEST_USER_TITLE = 'One more step before you can access Portal.';
export const GUEST_USER_MESSAGE = 'For access, contact your Portal Administrator to get your account request approved.';

