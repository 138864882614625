import { sentenceCase } from 'change-case';
import * as Constants from '../constants';

export const getApiStatusLabel = (status = '') =>
  ((status.toUpperCase() === Constants.API_STATUS_NEW) ?
    'Unpublished' : sentenceCase(status));

export const getApiStatusColor = (status = '') => {
  const greenStates = [Constants.API_STATUS_ENABLED];
  const grayStates = [Constants.API_STATUS_DISABLED];
  const orangeStates = [Constants.API_STATUS_DEPRECATED];
  const darkBlueStates = [Constants.API_STATUS_NEW];

  let statusColor = '#d84332';
  const apiStatus = status.toUpperCase();
  if (greenStates.includes(apiStatus)) {
    statusColor = '#59bb52';
  } else if (orangeStates.includes(apiStatus)) {
    statusColor = '#f7922f';
  } else if (grayStates.includes(apiStatus)) {
    statusColor = '#b3bdc2';
  } else if (darkBlueStates.includes(apiStatus)) {
    statusColor = '#20465f';
  }
  return statusColor;
};
