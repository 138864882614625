import { get } from 'lodash';

const styles = (theme) => ({
  paper: {
    backgroundColor: get(theme, 'color.uiBackground'),
  },
  title: {
    color: get(theme, 'color.bodyText'),
    fontSize: get(theme, 'fontSize.title'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontWeight: 'bold',
  },
  cancelButton: {
    textTransform: 'none',
    color: get(theme, 'color.primaryButtonBackground'),
    backgroundColor: get(theme, 'color.primaryButtonText'),
    borderColor: get(theme, 'color.primaryButtonBackground'),
  },
  saveButton: {
    textTransform: 'none',
    color: get(theme, 'color.primaryButtonText'),
    backgroundColor: get(theme, 'color.primaryButtonBackground'),
    borderColor: get(theme, 'color.primaryButtonBackground'),
  },
});

export default styles;
