import get from 'lodash/get';

import jsonParse from '../../utils/json';

export const getParsedGatewayDetails = (gatewayDetails) => ({
  accountPlanSyncDisruption: get(gatewayDetails, 'AccountPlanSyncDisruption'),
  accountPlanSyncLog: jsonParse(get(gatewayDetails, 'AccountPlanSyncLog'), {}),
  accountPlanSyncTime: get(gatewayDetails, 'AccountPlanSyncTime'),
  apiPlanSyncDisruption: get(gatewayDetails, 'ApiPlanSyncDisruption'),
  apiPlanSyncLog: jsonParse(get(gatewayDetails, 'ApiPlanSyncLog'), {}),
  apiPlanSyncTime: get(gatewayDetails, 'ApiPlanSyncTime'),
  apiSyncDisruption: get(gatewayDetails, 'ApiSyncDisruption'),
  apiSyncLog: jsonParse(get(gatewayDetails, 'ApiSyncLog'), {}),
  apiSyncTime: get(gatewayDetails, 'ApiSyncTime'),
  appSyncDisruption: get(gatewayDetails, 'AppSyncDisruption'),
  appSyncLog: jsonParse(get(gatewayDetails, 'AppSyncLog'), {}),
  appSyncTime: get(gatewayDetails, 'AppSyncTime'),
  enrollmentStatus: get(gatewayDetails, 'EnrollmentStatus'),
  logs: jsonParse(get(gatewayDetails, 'Logs'), {}),
});

export default () => {};
