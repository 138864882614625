import get from 'lodash/get';

const styles = theme => ({
  root: {
    borderRadius: 4,
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)',
    padding: 5,
    width: '80%',
  },
  rootTop: {
    top: 0,
  },
  rootBottom: {
    bottom: 0,
  },
  rootDark: {
    backgroundColor: get(theme, 'color.darkGreyBackground'),
    color: get(theme, 'color.white'),
    '& a': {
      color: `${get(theme, 'color.white')} !important`,
      textDecoration: 'underline !important',
    },
  },
  rootLight: {
    backgroundColor: get(theme, 'color.white'),
    color: get(theme, 'color.black'),
    '& a': {
      color: `${get(theme, 'color.link')} !important`,
      textDecoration: 'underline !important',
    },
  },
  infoButton: {
    padding: get(theme, 'padding.p6'),
  },
  messageContainer: {
    fontSize: get(theme, 'fontSize.smallText'),
    fontFamily: get(theme, 'typography.bodyText'),
    padding: get(theme, 'padding.p6'),
  },
  iconInfo: {
    fontSize: 30,
  },
  infoContainer: {
    width: '5%',
    float: 'left',
  },
  closeButton: {
    float: 'right',
    padding: get(theme, 'padding.p6'),
  },
});

export default styles;
