import axios from 'axios';
import { isEmpty } from 'lodash';
import { stringify } from 'query-string';

import * as Constants from '../../constants';
import { getConfig } from '../../reducers/portalConfig';
import { getValidationErrors } from '../../utils';
import { AXIOS_DEFAULT_OPTIONS } from '../';

const init = (dispatch, isLoading = true) =>
  dispatch({
    type: Constants.APP_LOADING,
    isLoading,
  });

export const resetApp = () => dispatch => dispatch({ type: Constants.APP_RESET });

export const showLoading = isLoading => dispatch => init(dispatch, isLoading);

export const fetchEulasList = ({ filterByName = null, page = 0, rowsPerPage = 12 }) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      name: !isEmpty(filterByName) ? filterByName : undefined,
      page,
      size: rowsPerPage,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/eulas?${params}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.EULAS_GET_LIST_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.EULAS_GET_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
    };

export const fetchEula = (eulaUuid) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/eulas/${eulaUuid}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.EULAS_DETAILS_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.EULAS_DETAILS_ERROR,
          payload: getValidationErrors(error),
        }),
      );
    };

export const fetchEulaApis = (eulaUuid) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis?apiEulaUuid=${eulaUuid}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.EULAS_APIS_LIST_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.EULAS_APIS_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
    };

export const saveEula = (name, content) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = { name: name, content: content };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/eulas`;
    await axios.post(url, data, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.EULA_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.EULA_SAVE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateEula = (name, content, uuid) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = { name: name, content: content, uuid: uuid };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/eulas/${uuid}?forceUpdate=true`;
    await axios.put(url, data, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        response = { data: { uuid: uuid } };
        dispatch({
          type: Constants.EULA_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.EULA_SAVE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const checkEulaNameUnique = (name, currentId) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/Portal.svc/ApiEulaNameUnique()`;
    await axios.get(url,
      {
        params: {
          Name: `'${name}'`,
          CurrentId: `'${currentId}'`,
        },
      },
      {
        credentials: 'include',
      })
      .then((response) => {
        dispatch({
          type: Constants.EULA_NAME_UNIQUE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.EULA_NAME_UNIQUE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const deleteEula = (uuid) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/eulas/${uuid}`;
    await axios.delete(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.EULA_DELETE_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.EULA_DELETE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };
