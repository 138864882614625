import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Grid, Button, Typography,
} from '@material-ui/core';
import copy from 'copy-to-clipboard';
import { isEmpty } from 'lodash';
import get from 'lodash/get';

import {
  ErrorContainer,
  FormTextField,
  FormDialog,
  AlertMessages,
  FormActionButtons,
  FormSelect,
} from '../../../../components';
import {
  APPLICATION_STATUS_DISABLED,
  APPLICATION_STATUS_PENDING_APPROVAL,
  APPLICATION_STATUS_EDIT_PENDING_APPROVAL,
  ALERT_SUCCESS,
} from '../../../../constants';
import {
  APPLICATION_APP_KEY_COPY_BUTTON_LABEL,
  APPLICATION_APP_KEY_GENERATE_SECRET_DIALOG_SUBTITLE,
  APPLICATION_APP_KEY_SECRET_DEFAULT_HASH_FROM_DB,
  APPLICATION_SECRET_COPY_MESSAGE1,
  APPLICATION_SECRET_COPY_MESSAGE2,
  APPLICATION_API_KEY_MESSAGE1,
  APPLICATION_API_KEY_MESSAGE2,
  APPLICATION_SECRET_COPY_CLASS,
  CANCEL_TEXT,
  DONE_TEXT,
  SAVE_TEXT,
} from '../labels';
import { getI18n } from '../../../../utils/intl';
import { hasOrgBoundRole } from '../../../../utils';

const Key = (props) => {
  const {
    classes,
    userContext,
    errors = [],
    applicationDetails = {},
    applicationStatus,
    setApplicationDetails,
    applicationUuid,
    generateSecret,
    secret,
    onCancel,
    appSecretHashingMetadata,
    notifyMessages,
    appKeys,
    push,
    onUpdateApplicationSecret,
    isSaveApplicationSecretSuccess,
    isEditApplicationRequestWorkflowEnabled,
  } = props;

  const intl = getI18n(useIntl());
  let { apiKey, keySecret } = applicationDetails;
  const { status } = applicationDetails;
  const isSaveApplicationDisabled = ([
    APPLICATION_STATUS_PENDING_APPROVAL,
    APPLICATION_STATUS_EDIT_PENDING_APPROVAL,
  ])
    .includes(status);
  const isGenerateSecretDisabled = ([
    APPLICATION_STATUS_DISABLED,
    APPLICATION_STATUS_PENDING_APPROVAL,
    APPLICATION_STATUS_EDIT_PENDING_APPROVAL,
  ])
    .includes(applicationStatus);

  if (appKeys && appKeys.length > 0) {
    const defaultKey = appKeys.find(key => get(key, 'defaultKey'));
    apiKey = get(defaultKey, 'apiKey');
    keySecret = get(defaultKey, 'keySecret');
  }
  const [sharedSecret, setSharedSecret] = useState(secret || keySecret);
  const [isSecretCopyButtonVisible, setIsSecretCopyButtonVisible] = useState(false);
  const [isGenerateSecretDialogOpen, setIsGenerateSecretDialogOpen] = useState(false);
  const [isPlainTextSelected, setIsPlainTextSelected] = useState(false);
  const [
    showOneTimePasswordWarning,
    setShowOneTimePasswordWarning,
  ] = useState(props.showOneTimePasswordWarning);
  const [notificationMessage, setNotificationMessage] = useState('');
  const allowSelectHashing = get(appSecretHashingMetadata, 'plaintextAllowed');

  const showSecretCopyButton = () => {
    if (isEmpty(secret) && keySecret === APPLICATION_APP_KEY_SECRET_DEFAULT_HASH_FROM_DB) {
      setIsSecretCopyButtonVisible(false);
    } else if (secret !== APPLICATION_APP_KEY_SECRET_DEFAULT_HASH_FROM_DB) {
      setIsSecretCopyButtonVisible(true);
    }
  };

  useEffect(() => {
    setTimeout(() => { notifyMessages('', ''); }, 2500);
  }, []);
  useEffect(() => {
    const newSecret = secret || keySecret;
    setNotificationMessage('');
    setSharedSecret(newSecret);
    setApplicationDetails({ ...applicationDetails, keySecret: newSecret });
    showSecretCopyButton();
  }, [secret, keySecret]);
  useEffect(() => {
    setShowOneTimePasswordWarning(props.showOneTimePasswordWarning);
  }, [props.showOneTimePasswordWarning]);
  useEffect(() => {
    if (isSaveApplicationSecretSuccess) {
      push(`/publish/applications/details/${applicationUuid}`);
      notifyMessages(
        hasOrgBoundRole(userContext) && isEditApplicationRequestWorkflowEnabled ?
          intl.getI18nMessage('label.application.request.submit.success') :
          intl.getI18nMessage('label.application.update.success'),
        ALERT_SUCCESS,
      );
    }
  }, [isSaveApplicationSecretSuccess]);

  const generateSecretPopupSubmit = () => {
    setNotificationMessage('');
    notifyMessages('', '');
    setIsGenerateSecretDialogOpen(false);
    localStorage.setItem('isAppUnSavedChanges', true);
    generateSecret(applicationUuid, isPlainTextSelected);
    if (!isEmpty(props.appSecretHashingMetadata) && !isPlainTextSelected) {
      setShowOneTimePasswordWarning(true);
    } else {
      setShowOneTimePasswordWarning(false);
    }
  };

  const showGenerateSecretPopup = (dropDownValue) => {
    if (dropDownValue === '0') {
      return;
    }
    if (dropDownValue === '1') {
      setIsPlainTextSelected(true);
    } else {
      setIsPlainTextSelected(false);
    }
    setIsGenerateSecretDialogOpen(true);
  };

  const showKeyCopyMessage = (e) => {
    const message = APPLICATION_API_KEY_MESSAGE1 + e + APPLICATION_API_KEY_MESSAGE2;
    setNotificationMessage(message);
  };

  const showSecretCopyMessage = (e) => {
    const message = APPLICATION_SECRET_COPY_MESSAGE1 + e + APPLICATION_SECRET_COPY_MESSAGE2;
    setNotificationMessage(message);
  };

  const onNext = async () => {
    localStorage.setItem('isAppUnSavedChanges', false);
    if (isSaveApplicationDisabled) {
      setTimeout(
        () => { push(`/publish/applications/details/${applicationUuid}`); },
        1000,
      );
    } else {
      await onUpdateApplicationSecret(!isPlainTextSelected);
    }
  };

  const getNewSecretLabel =
    hasOrgBoundRole(userContext) && isEditApplicationRequestWorkflowEnabled
      ? intl.getI18nMessage('label.application.key.shared.secret.request.new')
      : intl.getI18nMessage('label.application.key.shared.secret.generate.new');

  return (
    <Fragment>
      <div>
        {notificationMessage &&
          <AlertMessages
            message={notificationMessage}
            variant={APPLICATION_SECRET_COPY_CLASS}
            onClose={() => {
              setNotificationMessage('');
            }}
          />
        }
      </div>
      <Typography variant="h1" className={classes.pageTitle}>
        Key
      </Typography>
      {errors.length > 0 &&
        <ErrorContainer errors={errors} />
      }
      <FormDialog
        dialogId="application-generate-secret-dialog"
        isOpen={isGenerateSecretDialogOpen}
        title={getNewSecretLabel}
        submitText={getNewSecretLabel}
        onSubmit={() => {
          generateSecretPopupSubmit();
        }}
        cancelText={CANCEL_TEXT}
        onCancel={() => { setIsGenerateSecretDialogOpen(false); }}
      >
        <Fragment>
          <Typography component={'span'}>
            {APPLICATION_APP_KEY_GENERATE_SECRET_DIALOG_SUBTITLE}
          </Typography>
          <br />
        </Fragment>
      </FormDialog>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid md={12} item>
          <Grid container spacing={3} >
            <Grid xs={9} item>
              <FormTextField
                id={'application-details-api-key'}
                name={intl.getI18nMessage('label.application.key')}
                value={apiKey}
                disabled
              />
            </Grid>
            <Grid xs={3} item>
              <Button
                className={classes.copyKeyButton}
                variant="outlined"
                onClick={() => {
                  copy(apiKey);
                  showKeyCopyMessage(apiKey);
                }}
              >
                {APPLICATION_APP_KEY_COPY_BUTTON_LABEL}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3} >
            <Grid xs={9} item>
              <FormTextField
                id={'application-details-key-secret'}
                name={intl.getI18nMessage('label.application.key.shared.secret')}
                value={sharedSecret}
                disabled
                {...((errors.length < 1) && showOneTimePasswordWarning && {
                  helperText: intl.getI18nMessage('label.application.key.shared.secret.hashed.generated.help'),
                  errorHelperText: true,
                })}
              />
            </Grid>
            {isSecretCopyButtonVisible &&
              <Grid xs={3} item>
                <Button
                  className={classes.copyKeyButton}
                  variant="outlined"
                  onClick={() => {
                    copy(sharedSecret);
                    showSecretCopyMessage(sharedSecret);
                  }}
                >
                  {APPLICATION_APP_KEY_COPY_BUTTON_LABEL}
                </Button>
              </Grid>
            }
          </Grid>

          {allowSelectHashing &&
            <Grid container>
              <Grid item md={4} xs={12}>
                <FormSelect
                  value={'0'}
                  data={[
                    { uuid: '0', name: getNewSecretLabel },
                    { uuid: '1', name: intl.getI18nMessage('label.application.key.shared.secret.plain') },
                    { uuid: '2', name: intl.getI18nMessage('label.application.key.shared.secret.hashed') },
                  ]}
                  handleChange={(e) => showGenerateSecretPopup(e.target.value)}
                  noNoneOption
                  warning
                  disabled={isGenerateSecretDisabled}
                />
              </Grid>
            </Grid>
          }
          {!allowSelectHashing &&
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.resetSecretButton}
                  onClick={(e) => showGenerateSecretPopup(e.value)}
                  disabled={isGenerateSecretDisabled}
                >
                  {getNewSecretLabel}
                </Button>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
      <FormActionButtons
        onNextClick={onNext}
        nextText={isSaveApplicationDisabled ? DONE_TEXT : SAVE_TEXT}
        onCancelClick={onCancel}
        id="key-form-buttons"
      />
    </Fragment>
  );
};

Key.propTypes = {
  classes: PropTypes.object,
  userContext: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  applicationDetails: PropTypes.object,
  applicationStatus: PropTypes.string,
  applicationUuid: PropTypes.string,
  generateSecret: PropTypes.func,
  secret: PropTypes.string,
  showOneTimePasswordWarning: PropTypes.bool,
  onCancel: PropTypes.func,
  appSecretHashingMetadata: PropTypes.object,
  notifyMessages: PropTypes.func,
  appKeys: PropTypes.arrayOf(PropTypes.object),
  setApplicationDetails: PropTypes.func,
  push: PropTypes.func,
  onUpdateApplicationSecret: PropTypes.func,
  isSaveApplicationSecretSuccess: PropTypes.bool,
  isEditApplicationRequestWorkflowEnabled: PropTypes.bool,
};

export default Key;
