import React, { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Divider,
  FormLabel,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  get, find, isEmpty, map, uniq, forEach, isUndefined, difference, union, intersection, keys, every,
} from 'lodash';

import {
  ExpandPanelContainer,
  FormDialog as Dialog,
  FormActionButtons,
  ErrorContainer,
  TabsContainer,
  TabPanel,
  MultiSelect,
  TagsGroup,
} from '../../../../components';
import {
  APPLICATION_API_SELECTED_APIS_TITLE,
  APPLICATION_API_SELECTED_APIS_AND_API_GROUPS_TITLE,
  APPLICATION_API_EULA_DIALOG_TITLE,
  APPLICATION_API_EULA_DIALOG_SUBMIT_TEXT,
  CANCEL_TEXT,
  APPLICATION_API_MANAGEMENT_ASSIST_TEXT,
  APPLICATION_API_TAG_SELECT_DIALOG_TITLE,
  APPLICATION_API_TAG_SELECT_DIALOG_FILTER_TEXT,
  APPLICATION_API_TAG_NO_OPTIONS_TEXT,
  APPLICATION_API_TAG_PLACEHOLDER_TEXT,
} from '../labels';
import {
  TYPE_API,
  TYPE_API_GROUP,
} from '../../../../constants';
import Tabs from './tabs';
import { hasOrgBoundRole, listToObject } from '../../../../utils';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';

const getSelectedApis = (ids = [], selected, available, apiApiPlanIds, cached) =>
  uniq(ids).map(id => {
    const selectedApi = selected[id] || available[id] || cached[id];
    return {
      ...selectedApi,
      apiPlanId: apiApiPlanIds[id],
    };
  });

const getSelectedApiGroups = (ids = [], selected, available, cached) =>
  uniq(ids).map(
    id => (selected[id] || available[id] || cached[id]),
  );

const getSelectedApiPlan = ({ availableItems, apiPlanId }) => find(
  availableItems,
  ({ uuid }) => (uuid === apiPlanId),
);

const getTabs = (tabProps) =>
  Tabs.filter(({ isHidden }) => !(isHidden && isHidden(tabProps)));

export default function ApiManagement(props) {
  const {
    classes,
    headerRef,
    errors = [],
    userContext = {},
    userActiveOrgUuid,
    featureFlagApiPlans,
    currentTab,
    setCurrentTab,
    applicationDetails = {},
    setApplicationDetails,
    availableApis = [],
    availableApisCount,
    apiEula = {},
    fetchAvailableApis,
    availableApiGroups1 = {},
    apiGroupEulas = [],
    fetchAvailableApiGroups,
    fetchAllTags,
    allTags = [],
    visible,
    fetchAvailableApiPlans,
    availableApiPlans,
    onCancel,
    applicationUuid,
    isSaveApplicationSuccess,
    isMultiKeySupport,
  } = props;

  const tabs = getTabs({ featureFlagApiPlans });
  const { organizationUuid, apiIds, apiApiPlanIds, apiGroupIds } = applicationDetails;
  const [currentAvailableTab, setCurrentAvailableTab] = useState(tabs[0].tabId);
  const [error, setError] = useState(false);

  const orgUuid = hasOrgBoundRole(userContext) ? userActiveOrgUuid : organizationUuid;

  const getAvailableApiPlans =
    async (selectedApis) => forEach(
      selectedApis,
      async ({ uuid }) => {
        if (isUndefined(availableApiPlans[uuid])) {
          await fetchAvailableApiPlans(orgUuid, uuid);
        }
      },
    );

  /**
   * APIs
   */
  const [apiUuidToAdd, setApiUuidToAdd] = useState('');
  const [isApiEulaDialogOpen, setIsApiEulaDialogOpen] = useState(false);
  const [apiPageNumber, setApiPageNumber] = useState(0);
  const [apiSearchText, setApiSearchText] = useState('');
  const [apiGroupPageNumber, setApiGroupPageNumber] = useState(0);
  const [apiGroupSearchText, setApiGroupSearchText] = useState('');
  const [cachedApis, setCachedApis] = useState([]);
  const [cachedApiGroups, setCachedApiGroups] = useState([]);
  const [selectedApis, setSelectedApis] = useState(getSelectedApis(
    apiIds,
    listToObject(props.selectedApis, TYPE_API),
    listToObject(availableApis, TYPE_API),
    apiApiPlanIds,
    listToObject(cachedApis, TYPE_API),
  ));

  useEffect(() => {
    if (!isEmpty(apiEula)) { setIsApiEulaDialogOpen(true); }
  }, [apiEula]);

  // TODO: temp. disabling the excess get calls for available API Plans
  // if (selectedApis.length > 0 && featureFlagApiPlans) { getAvailableApiPlans(selectedApis); }

  useEffect(() => {
    if (currentTab !== 'api-management-tab') { return; }
    if (selectedApis.length > 0 && featureFlagApiPlans) { getAvailableApiPlans(selectedApis); }
  }, [selectedApis]);

  const onDeleteApi = (uuid) => {
    localStorage.setItem('isAppUnSavedChanges', true);
    if (featureFlagApiPlans) {
      delete applicationDetails.apiApiPlanIds[uuid];
    }
    setApplicationDetails({
      ...applicationDetails,
      apiIds: apiIds.filter(apiId => (apiId !== uuid)),
    });
  };

  const onAddApi = () => {
    localStorage.setItem('isAppUnSavedChanges', true);
    const applicationDetailsApiIds = uniq(apiIds.concat(apiUuidToAdd));
    setApplicationDetails({
      ...applicationDetails,
      apiIds: applicationDetailsApiIds,
      // add a null entry in apiApiPlanIds when feature flag is enabled
      ...(featureFlagApiPlans && {
        apiApiPlanIds: {
          ...apiApiPlanIds,
          [apiUuidToAdd]: null,
        },
      }),
    });
    setApiUuidToAdd('');
    setError(false);
    setCachedApis(getSelectedApis(
      applicationDetailsApiIds,
      listToObject(props.selectedApis, TYPE_API),
      listToObject(availableApis, TYPE_API),
      apiApiPlanIds,
      listToObject(cachedApis, TYPE_API),
    ));
  };

  useEffect(() => {
    setSelectedApis(getSelectedApis(
      applicationDetails.apiIds,
      listToObject(props.selectedApis, TYPE_API),
      listToObject(availableApis, TYPE_API),
      applicationDetails.apiApiPlanIds,
      listToObject(cachedApis, TYPE_API),
    ));
  }, [applicationDetails]);


  const onSelectApiPlan = (apiUuid, apiPlanUuid) => {
    localStorage.setItem('isAppUnSavedChanges', true);
    setApplicationDetails({
      ...applicationDetails,
      apiApiPlanIds: {
        ...apiApiPlanIds,
        [apiUuid]: apiPlanUuid,
      },
    });
    setError(false);
  };

  const getApiPlanSelectTable = (item) => {
    const selectedApiPlan = getSelectedApiPlan(item);
    return (!isEmpty(item.availableItems) &&
      <Fragment>
        <FormLabel classes={{ root: classes.labelRoot }}>
          {getI18nFormattedMessage('label.application.apis.selected.api.plan')}
        </FormLabel>
        {selectedApiPlan ?
          <FormLabel classes={{ root: classes.selectedApiPlan }}>
            {get(selectedApiPlan, 'name')}
          </FormLabel> :
          <FormLabel classes={{ root: classes.selectApiPlanPlaceholder }}>
            {getI18nFormattedMessage('label.application.apis.selected.api.plan.placeholder')}
          </FormLabel>
        }
      </Fragment>
    );
  };

  const onLoadMoreApisProps = (availableApisCount > availableApis.length) ? {
    isLoadMore: true,
    onLoadMore: () => {
      setApiPageNumber(apiPageNumber + 1);
    },
    loadMoreText: 'Load More',
  } : {};

  /**
   * API Groups
   */
  const [apiGroupUuidToAdd, setApiGroupUuidToAdd] = useState('');
  const [isApiGroupEulaDialogOpen, setIsApiGroupEulaDialogOpen] = useState(false);
  useEffect(() => {
    if (!isEmpty(apiGroupEulas)) { setIsApiGroupEulaDialogOpen(true); }
  }, [apiGroupEulas]);

  const selectedApiGroups = getSelectedApiGroups(
    apiGroupIds,
    listToObject(props.selectedApiGroups, TYPE_API_GROUP),
    listToObject(availableApiGroups1.results, TYPE_API_GROUP),
    listToObject(cachedApiGroups, TYPE_API_GROUP),
  );

  const onDeleteApiGroup = (uuid) => {
    localStorage.setItem('isAppUnSavedChanges', true);
    setApplicationDetails({
      ...applicationDetails,
      apiGroupIds: apiGroupIds.filter(apiGroupId => (apiGroupId !== uuid)),
    });
  };

  const onAddApiGroup = () => {
    localStorage.setItem('isAppUnSavedChanges', true);
    const applicationDetailsApiGroupIds = uniq(apiGroupIds.concat(apiGroupUuidToAdd));
    setApplicationDetails({
      ...applicationDetails,
      apiGroupIds: applicationDetailsApiGroupIds,
    });
    setApiGroupUuidToAdd('');
    setError(false);
    setCachedApiGroups(getSelectedApiGroups(
      applicationDetailsApiGroupIds,
      listToObject(props.selectedApiGroups, TYPE_API_GROUP),
      listToObject(availableApiGroups1.results, TYPE_API_GROUP),
      listToObject(cachedApiGroups, TYPE_API_GROUP),
    ));
  };

  const onLoadMoreApiGroupsProps =
    (availableApiGroups1.currentPage + 1) < (availableApiGroups1.totalPages) ? {
      isLoadMore: true,
      onLoadMore: () => {
        setApiGroupPageNumber(availableApiGroups1.currentPage + 1);
      },
      loadMoreText: 'Load More',
    } : {};

  /**
   * Tag Selection
   */
  const [isApiTagSelectDialogOpen, setIsApiTagSelectDialogOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [popupTags, setPopupTags] = useState([]);
  const [popupSelectedTags, setPopupSelectedTags] = useState([]);

  const onTagsSelect = (tags, apiTags) => {
    if (!tags) {
      setPopupTags(apiTags);
      const popupSelectedTagsParams = intersection(selectedTags, apiTags);
      setPopupSelectedTags(popupSelectedTagsParams);
      setIsApiTagSelectDialogOpen(true);
    } else {
      setApiPageNumber(0);
      setSelectedTags(uniq(tags));
    }
  };

  const onAPISearchTextChange = (searchterm) => {
    setApiPageNumber(0);
    setApiSearchText(searchterm);
  };

  const onAPIGroupSearchTextChange = (searchterm) => {
    setApiGroupPageNumber(0);
    setApiGroupSearchText(searchterm);
  };

  const allTagsOptions = map(allTags, ({ name }) => ({ value: name, label: name }));
  const onPopupTagsSelectionChange = (tags) =>
    setPopupSelectedTags(map(tags, item => item.value));

  const onTagsPopupSubmit = () => {
    setApiPageNumber(0);
    const newTags = union(difference(selectedTags, popupTags), popupSelectedTags);
    setSelectedTags(newTags);
    setIsApiTagSelectDialogOpen(false);
  };

  const onTagsSelectionChange = (items) => {
    onTagsSelect(uniq(map(items, item => item.value)));
  };

  const onTagClick = (tag, apiTags) => {
    if (tag) {
      onTagsSelect(uniq([...selectedTags, tag]), apiTags);
    } else {
      onTagsSelect(null, apiTags);
    }
  };

  const renderTagsLabel = ({ item }) => {
    if (item && item.tags && (item.tags.length > 0)) {
      return (<div className={classes.tagGroupContainer}>
        <TagsGroup
          tags={item.tags}
          onTagClick={(tag) => onTagClick(tag, item.tags)}
        />
      </div>);
    }
    return null;
  };
  renderTagsLabel.propTypes = {
    item: PropTypes.shape({}),
  };
  const { getI18nMessage } = getI18n(useIntl());
  const noOptionsText = getI18nMessage(APPLICATION_API_TAG_NO_OPTIONS_TEXT);
  const placeHolderText = getI18nMessage(APPLICATION_API_TAG_PLACEHOLDER_TEXT);
  const tagPopupDialogTitle = getI18nMessage(APPLICATION_API_TAG_SELECT_DIALOG_TITLE);
  const tagPopupDialogBtnText = getI18nMessage(APPLICATION_API_TAG_SELECT_DIALOG_FILTER_TEXT);
  const renderTagsFilter = () =>
    <MultiSelect
      name="tags"
      onChange={onTagsSelectionChange}
      noOptionsMessage={() => noOptionsText}
      optionsData={allTagsOptions}
      placeholder={placeHolderText}
      value={selectedTags}
    />;

  useEffect(() => {
    if (visible) {
      fetchAllTags();
    }
  }, [visible]);

  const renderTagModalDialog = () => {
    const apiTagsOptions = popupTags.map(name => ({ value: name, label: name }));
    return (
      <div className={classes.selectWrapper}>
        <MultiSelect
          menuIsOpen
          name="tags"
          onChange={onPopupTagsSelectionChange}
          noOptionsMessage={() => noOptionsText}
          optionsData={apiTagsOptions}
          placeholder={placeHolderText}
          value={popupSelectedTags}
        />
      </div>
    );
  };

  const renderApiGroupDialog = () => (
    <Fragment>
      {apiGroupEulas.map((apiGroupEula) => (
        <ExpansionPanel className={classes.apiGroupPanel} key={apiGroupEula.uuid}>
          <ExpansionPanelSummary
            aria-controls="panel1a-content"
            expandIcon={<ExpandMoreIcon />}
            id="panel1a-header"
          >
            <span className={classes.heading}>
              {apiGroupEula.name}
            </span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <span
              className={classes.panelContent}
              dangerouslySetInnerHTML={{ __html: (apiGroupEula.content) }}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Fragment>
  );

  useEffect(() => {
    if (currentTab !== 'api-management-tab') { return; }
    fetchAvailableApis({
      orgUuid,
      page: apiPageNumber,
      tags: selectedTags,
      searchText: apiSearchText,
    });
  }, [apiPageNumber, apiSearchText, selectedTags]);

  useEffect(() => {
    if (currentTab !== 'api-management-tab') { return; }
    if (orgUuid) {
      fetchAvailableApiGroups({
        orgUuid,
        page: apiGroupPageNumber,
        searchText: apiGroupSearchText,
      });
    }
  }, [apiGroupPageNumber, apiGroupSearchText]);

  const selectedApisApiGroups = featureFlagApiPlans ?
    selectedApis.map(selectedApi => ({
      ...selectedApi,
      availableItems: availableApiPlans[selectedApi.uuid],
    })) :
    selectedApis.concat(selectedApiGroups);

  const onNext = () => {
    // If no APIs / APIGroups have been selected
    if (isEmpty(selectedApisApiGroups)) {
      setError(true);
      return;
    }
    // If no API Plan has been mapped to a selected API (which as an available plan)
    if (featureFlagApiPlans &&
      !every(
        keys(apiApiPlanIds),
        apiApiPlanId => (
          isEmpty(availableApiPlans[apiApiPlanId]) || !!apiApiPlanIds[apiApiPlanId]
        ),
      )
    ) {
      setError(true);
      return;
    }
    if (isMultiKeySupport) {
      if (applicationUuid) {
        props.updateApplication(applicationDetails);
      }
    } else {
      setCurrentTab('authentication-tab');
    }
    window.scrollTo(0, headerRef.offsetTop);
  };

  useEffect(() => {
    if (isMultiKeySupport && isSaveApplicationSuccess) {
      setCurrentTab('keys-tab');
    }
  }, [isSaveApplicationSuccess]);

  return (
    <Fragment>
      <Typography variant="h1" className={classes.pageTitle}>
        Api Management
      </Typography>
      {(errors.length > 0) &&
        <ErrorContainer errors={errors} />
      }
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid md={12} item>
          <Dialog
            dialogId="application-api-eula-dialog"
            isOpen={isApiEulaDialogOpen}
            title={APPLICATION_API_EULA_DIALOG_TITLE}
            submitText={APPLICATION_API_EULA_DIALOG_SUBMIT_TEXT}
            onSubmit={() => {
              onAddApi();
              setIsApiEulaDialogOpen(false);
            }}
            cancelText={CANCEL_TEXT}
            onCancel={() => { setIsApiEulaDialogOpen(false); }}
          >
            <span
              dangerouslySetInnerHTML={{ __html: apiEula.content }}
            />
          </Dialog>
          <Dialog
            dialogId="application-api-group-eula-dialog"
            isOpen={isApiGroupEulaDialogOpen}
            title={APPLICATION_API_EULA_DIALOG_TITLE}
            submitText={APPLICATION_API_EULA_DIALOG_SUBMIT_TEXT}
            isDialogContentText={false}
            onSubmit={() => {
              onAddApiGroup();
              setIsApiGroupEulaDialogOpen(false);
            }}
            cancelText={CANCEL_TEXT}
            onCancel={() => { setIsApiGroupEulaDialogOpen(false); }}
          >
            {renderApiGroupDialog()}
          </Dialog>
          <Dialog
            dialogId="application-api-tag-dialog"
            isOpen={isApiTagSelectDialogOpen}
            title={tagPopupDialogTitle}
            submitText={tagPopupDialogBtnText}
            onSubmit={onTagsPopupSubmit}
            cancelText={CANCEL_TEXT}
            onCancel={() => { setIsApiTagSelectDialogOpen(false); }}
          >
            {renderTagModalDialog()}
          </Dialog>
          <ExpandPanelContainer
            id="application-selected-apis-api-groups"
            defaultList={selectedApisApiGroups}
            error={error}
            isApiDetails
            isApiGroup
            subtitle={featureFlagApiPlans ?
              APPLICATION_API_SELECTED_APIS_TITLE :
              APPLICATION_API_SELECTED_APIS_AND_API_GROUPS_TITLE}
            {...(featureFlagApiPlans && { panelSubtitle: getApiPlanSelectTable })}
            isDeleteIcon
            onDeleteItem={({ type, uuid }) => {
              switch (type) {
                case TYPE_API: { return onDeleteApi(uuid); }
                case TYPE_API_GROUP: { return onDeleteApiGroup(uuid); }
                default: { return null; }
              }
            }}
            getSelectedItem={item => item.apiPlanId}
            onSelectItem={onSelectApiPlan}
            emptyListMessage={getI18nMessage('label.application.apis.selected.empty')}
          />
          <Divider className={classes.divider} />
          <TabsContainer
            useTabId
            orientation="horizontal"
            tabValue={currentAvailableTab}
            tabItems={tabs}
            handleTabChange={(e, newTab) => { setCurrentAvailableTab(newTab); }}
          />
          {tabs.map(
            ({ tabId, tabPanel }) => (
              <TabPanel
                id={`${tabId}-panel`}
                visible={currentAvailableTab === tabId}
                key={tabId}
              >
                {
                  tabPanel({
                    ...props,
                    currentAvailableTab,
                    setApiUuidToAdd,
                    onLoadMoreApisProps,
                    setApiGroupUuidToAdd,
                    onLoadMoreApiGroupsProps,
                    SecondaryLabelComponent: renderTagsLabel,
                    ExternalFilter: renderTagsFilter,
                    onAPISearchTextChange,
                    onAPIGroupSearchTextChange,
                  })
                }
              </TabPanel>
            ))}
        </Grid>
      </Grid>
      <FormActionButtons
        onNextClick={onNext}
        assistText={APPLICATION_API_MANAGEMENT_ASSIST_TEXT}
        nextText={isMultiKeySupport ? getI18nFormattedMessage('label.save.button') : getI18nFormattedMessage('label.next.button')}
        onCancelClick={onCancel}
        id={'organization-form-buttons'}
      />
    </Fragment>
  );
}

ApiManagement.propTypes = {
  classes: PropTypes.object,
  headerRef: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  featureFlagApiPlans: PropTypes.bool,
  userContext: PropTypes.object,
  userActiveOrgUuid: PropTypes.string,
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  visible: PropTypes.bool,
  applicationDetails: PropTypes.object,
  setApplicationDetails: PropTypes.func,
  selectedApis: PropTypes.arrayOf(PropTypes.object),
  availableApis: PropTypes.arrayOf(PropTypes.object),
  availableApisCount: PropTypes.number,
  fetchAvailableApis: PropTypes.func,
  apiEula: PropTypes.shape({
    content: PropTypes.string,
  }),
  selectedApiGroups: PropTypes.arrayOf(PropTypes.object),
  availableApiGroups1: PropTypes.object,
  fetchAvailableApiGroups: PropTypes.func,
  apiGroupEulas: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string,
  })),
  fetchAllTags: PropTypes.func,
  allTags: PropTypes.arrayOf(PropTypes.object),
  fetchAvailableApiPlans: PropTypes.func,
  availableApiPlans: PropTypes.object,
  onCancel: PropTypes.func,
  applicationUuid: PropTypes.string,
  isSaveApplicationSuccess: PropTypes.bool,
  isMultiKeySupport: PropTypes.bool,
  updateApplication: PropTypes.func,
};
