import * as React from 'react';
import { Grid } from '@material-ui/core';

import { FormSelect, FormTextField } from '../../../components';

import {
  GATEWAY_BUNDLES_LIST_SORT_BY,
  GATEWAY_BUNDLES_LIST_TYPE_BY,
  GATEWAY_BUNDLES_LIST_PORTAL_TEMPLATE_BY,
} from '../../../constants';

export const FilterByName = (props) => (
  <Grid item md={3} sm={3} xs={12}>
    <FormTextField
      {...props}
      id="gateway-bundles-filter-by-name"
      data-apim-test="gateway-bundles-filter-by-name"
    />
  </Grid>
);

export const FilterByGroup = (props) => (
  <Grid item md={2} sm={3} xs={12}>
    <FormTextField
      {...props}
      id="gateway-bundles-filter-by-group"
      data-apim-test="gateway-bundles-filter-by-group"
    />
  </Grid>
);

export const FilterByType = (props) => (
  <Grid item md={2} sm={3} xs={12}>
    <FormSelect
      {...props}
      id="gateway-bundles-filter-by-type"
      data-apim-test="gateway-bundles-filter-by-type"
      data={GATEWAY_BUNDLES_LIST_TYPE_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterByPortalTemplate = (props) => (
  <Grid item md={2} sm={3} xs={12}>
    <FormSelect
      {...props}
      id="gateway-bundles-filter-by-portalTemplate"
      data-apim-test="gateway-bundles-filter-by-portalTemplate"
      data={GATEWAY_BUNDLES_LIST_PORTAL_TEMPLATE_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const SortBy = (props) => (
  <Grid item md={2} sm={3} xs={12}>
    <FormSelect
      {...props}
      id="gateway-bundles-sort-by"
      data-apim-test="gateway-bundles-sort-by"
      data={GATEWAY_BUNDLES_LIST_SORT_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterAndSortSeparator = () => (
  <Grid item md={1} />
);
