import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import parse from 'html-react-parser';
import { Snackbar, withStyles, IconButton, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { readCookie, createCookie } from '../../utils/cookie';
import { getCookieConsent } from '../../reducers/portalConfig';
import styles from './styles';

import base64 from 'base-64';
import sanitizeHtml from 'sanitize-html';
import utf8 from 'utf8';

export const getDecodedSanitizedConsentMessage = (message) => {
  const text = utf8.decode(base64.decode(message));
  return sanitizeHtml(
    text,
    {
      allowedTags: [ 'p', 'strong', 'a' ],
      allowedAttributes: {
        'a': [ 'href', 'target' ],
      },
    },
  );
};

const CookiePolicyBanner = (props) => {
  const { classes, cookieConsent } = props;
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    root: classes.rootDark,
    content: '',
  });

  const { vertical, horizontal, open, root, content } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
    const cookie = readCookie('cpb');
    if (cookie === null || cookie.toLowerCase() === 'true') {
      createCookie('cpb', 'false', 365, '/');
    }
  };

  useEffect(() => {
    if (cookieConsent) {
      const rootThemeClass = (cookieConsent.theme === 'light' ? classes.rootLight : classes.rootDark);
      const rootPositionClass = (cookieConsent.position === 'top' ? classes.rootTop : classes.rootBottom);
      const contentText = getDecodedSanitizedConsentMessage(cookieConsent.message);
      const cookie = readCookie('cpb');
      if (!cookie || (cookie.toLowerCase() === 'true')) {
        setState({ ...state,
          root: cx(classes.root, rootThemeClass, rootPositionClass),
          content: contentText,
          vertical: cookieConsent.position,
          open: cookieConsent.enabled,
        });
      }
    }
  }, [cookieConsent]);

  return (
    <div id="cookie-policy-container">
      <Snackbar
        classes={{
          root,
        }}
        open={open}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Grid container>
          <Grid item md={1}>
            <IconButton className={classes.infoButton} color="inherit">
              <InfoIcon className={classes.iconInfo} />
            </IconButton>
          </Grid>
          <Grid item md={10} className={classes.messageContainer}>
            {parse(content)}
          </Grid>
          <Grid item md={1}>
            <IconButton className={classes.closeButton} color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Snackbar>
    </div>
  );
};


const mapStateToProps = state => ({
  cookieConsent: getCookieConsent(state),
});

CookiePolicyBanner.propTypes = {
  classes: PropTypes.object,
  cookieConsent: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {}),
)(CookiePolicyBanner);

