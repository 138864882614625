import React from 'react';
import { makeStyles } from '@material-ui/core';
import styles from './styles';

export default function DashboardChartLegend() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  return (
    <svg
      tabIndex="0"
      id="Chart_3rd_Button_Full_Interaction"
      data-name="Chart 3rd Button Full Interaction"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={classes.graphIcon}
    >
      <path
        id="BG_-_ACTIVE"
        data-name="BG - ACTIVE"
        d="M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z"
      />
      <g id="Graph_icon" data-name="Graph icon" transform="translate(0 -1)">
        <rect
          id="Rectangle_668"
          data-name="Rectangle 668"
          width="12"
          height="2"
          rx="1"
          transform="translate(3 12)"
          className={classes.icon}
        />
        <rect
          id="Rectangle_824"
          data-name="Rectangle 824"
          width="3"
          height="2"
          transform="translate(4 12) rotate(-90)"
          className={classes.icon}
        />
        <rect
          id="Rectangle_825"
          data-name="Rectangle 825"
          width="7"
          height="2"
          transform="translate(12 12) rotate(-90)"
          className={classes.icon}
        />
        <rect
          id="Rectangle_826"
          data-name="Rectangle 826"
          width="5"
          height="2"
          transform="translate(8 12) rotate(-90)"
          className={classes.icon}
        />
      </g>
    </svg>
  );
}
