import React from 'react';
import { Grid } from '@material-ui/core';

import { FormSelect, FormTextField, FormAutoCompleteCombo } from '../../../../components';

import {
  GATEWAY_BUNDLES_API_SORT_BY,
  GATEWAY_BUNDLES_API_STATES_FILTER_BY,
} from '../../../../constants';

export const FilterByApiName = (props) => (
  <Grid item md={3}>
    <FormTextField
      {...props}
      id="gateway-bundles-apis-filter-by-apiName"
      data-apim-test="gateway-bundles-apis-filter-by-apiName"
    />
  </Grid>
);

export const FilterByProxyName = (props) => (
  <Grid item md={3}>
    <FormTextField
      {...props}
      id="gateway-bundles-apis-filter-by-proxyName"
      data-apim-test="gateway-bundles-apis-filter-by-proxyName"
    />
  </Grid>
);

export const FilterByTags = (props) => (
  <Grid item md={3}>
    <FormAutoCompleteCombo
      {...props}
      id="gateway-bundles-apis-filter-by-tags"
      data-apim-test="gateway-bundles-apis-filter-by-tags"
    />
  </Grid>
);

export const FilterByStates = (props) => (
  <Grid item md={2}>
    <FormSelect
      {...props}
      id="gateway-bundles-apis-filter-state-by"
      data-apim-test="gateway-bundles-apis-filter-state-by"
      data={GATEWAY_BUNDLES_API_STATES_FILTER_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const SortBy = (props) => (
  <Grid item md={3}>
    <FormSelect
      {...props}
      id="gateway-bundles-apis-sort-by"
      data-apim-test="gateway-bundles-apis-sort-by"
      data={GATEWAY_BUNDLES_API_SORT_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterAndSortSeparator = () => (
  <Grid item md={1} />
);
