import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Chip, Grid } from '@material-ui/core';
import { find, get, map } from 'lodash';
import { arrayOf, func, object, string } from 'prop-types';

import { OrganizationListContext } from '../';
import { getI18n } from '../../../../utils/intl';
import {
  ORGANIZATION_LIST_TYPE_FILTER_BY,
  ORGANIZATION_LIST_STATUS_FILTER_BY,
} from '../../../../constants';
import { FormSelect, FormTextField, FormAutoCompleteCombo } from '../../../../components';

export const FilterByName = (props) => (
  <Grid item md={2} sm={2} xs={12}>
    <FormTextField
      {...props}
      id="organizations-filter-by-name"
      data-apim-test="organizations-filter-by-name"
    />
  </Grid>
);

export const FilterByRateLimitQuota = (props) => {
  const intl = getI18n(useIntl());
  const value = find(
    props.data,
    ({ uuid }) => (uuid === props.value),
  );
  const onChange = (e, value) =>
    props.handleChange(get(value, 'uuid'));
  return (
    <Grid item md={2} sm={2} xs={12}>
      <FormAutoCompleteCombo
        {...props}
        id="organizations-filter-by-rate-limit-quota-name"
        inputLabel={intl.getI18nMessage('label.organization.list.page.filter.by.all.rate.limit.quota')}
        value={value}
        onChange={onChange}
      />
    </Grid>
  );
};

FilterByRateLimitQuota.propTypes = {
  data: arrayOf(object),
  value: string,
  handleChange: func,
};

export const FilterByType = (props) => (
  <Grid item md={2} sm={2} xs={12}>
    <FormSelect
      {...props}
      id="organizations-filter-by-type"
      data-apim-test="organizations-filter-by-type"
      data={ORGANIZATION_LIST_TYPE_FILTER_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterByStatus = (props) => (
  <Grid item md={2} sm={2} xs={12}>
    <FormSelect
      {...props}
      id="organizations-filter-by-status"
      data-apim-test="organizations-filter-by-status"
      data={ORGANIZATION_LIST_STATUS_FILTER_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const Tags = ({ tags, numSelectedRows }) => {
  const organizationListContext = useContext(OrganizationListContext);
  const clickable = (numSelectedRows === 0);
  return map(
    tags,
    tag => (
      <Chip
        variant="outlined" size="small"
        clickable={clickable}
        style={{ marginRight: '2px' }}
        key={tag} label={tag}
        onClick={() => {
          if(clickable) { organizationListContext.onClickTag(tag) }
        }}
      />
    ),
  );
}
