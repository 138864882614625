import React, { Component } from 'react';
import {
  VictoryAxis,
  VictoryLine,
  VictoryScatter,
  VictoryGroup,
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryLabel,
  VictoryArea,
} from 'victory';

// import sizeMe from 'react-sizeme';
import _ from 'lodash';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import Theme from './styles';
import ChartTooltip from '../ChartTooltip';
import { getStrokeColor, getYAxisTicks } from '../analytics_utils';

const STROKE_HIGHLIGHT_WIDTH = 3;
const STROKE_NORMAL_WIDTH = 2;

type Props = {
  chartData: Array,
  chartScale?: Object,
  formatXAxisData: Function,
  hoverHandler: Function,
  lineColors: Array,
  xAxisLabel: string,
  xAxisValues: Array,
  lightShadeLineColors: Array,
  theme: Object,
  yAxisLabel: string,
};

const getYAxisFormatter = yAxisObj => item => {
  if (item === 0) {
    return `${item}`;
  } else if (yAxisObj.paddingFactor === 1) {
    return `${item} ${yAxisObj.suffix}`;
  }
  const result = (item / yAxisObj.paddingFactor).toFixed(0);
  return `${result} ${yAxisObj.suffix}`;
};

const getYAxisValues = chartData => {
  let yMax = -1000;
  _.each(chartData, line => {
    const yVal = line.type === 'CHART_ELEMENT_AREA' ? 'high' : 'y';
    _.each(line && line.data, item => {
      yMax = item[yVal] > yMax ? item[yVal] : yMax;
    });
  });
  yMax = yMax === 0 ? 1 : yMax;

  return getYAxisTicks(yMax);
};

const renderAreaElement = (elementData, idx, lineColors, theme) => {
  const {
    highLight,
    colorIndex,
    data,
    customLabelComponent,
    id,
    metric,
  } = elementData;
  const idMetricStr = `${id}___${metric}`;
  const strokeWidth = highLight ? STROKE_HIGHLIGHT_WIDTH : STROKE_NORMAL_WIDTH;
  const scatterOpacity = highLight || data.length === 1 ? 1 : 0;
  const upperLineName = `${idx}-area-upper-line____${idMetricStr}`;
  const upperScatterName = `${idx}-area-upper-scatter____${idMetricStr}`;
  const areaName = `${idx}-area____${idMetricStr}`;
  const lowerLineName = `${idx}-area-lower-line____${idMetricStr}`;
  const lowerScatterName = `${idx}-area-lower-scatter____${idMetricStr}`;
  return (
    <VictoryGroup key={`${id}-${metric}`}>
      {highLight && (
        <VictoryLine
          name={upperLineName}
          y={'high'}
          data={data}
          labelComponent={ChartTooltip(customLabelComponent, '', highLight, theme)}
          style={{
            data: {
              stroke: () => getStrokeColor(lineColors, colorIndex),
              strokeWidth: () => strokeWidth,
            },
          }}
        />
      )}
      <VictoryScatter
        data={data}
        symbol={'circle'}
        name={upperScatterName}
        size={3}
        style={{
          data: {
            fill: getStrokeColor(lineColors, colorIndex),
            fillOpacity: () => scatterOpacity,
          },
        }}
        labels={datum => datum.y}
        labelComponent={ChartTooltip(
          customLabelComponent,
          'highLabel',
          highLight,
          theme,
        )}
        y={'high'}
      />
      <VictoryArea
        data={data}
        name={areaName}
        y={'high'}
        y0={'low'}
        style={{
          data: {
            fill: getStrokeColor(lineColors, colorIndex),
            opacity: 0.2,
          },
        }}
        labelComponent={ChartTooltip(customLabelComponent, '', highLight, theme)}
      />
      {highLight && (
        <VictoryLine
          name={lowerLineName}
          y={'low'}
          data={data}
          labelComponent={ChartTooltip(customLabelComponent, '', highLight, theme)}
          style={{
            data: {
              stroke: getStrokeColor(lineColors, colorIndex),
              strokeWidth: () => strokeWidth,
            },
          }}
        />
      )}
      <VictoryScatter
        data={data}
        y={'low'}
        symbol={'circle'}
        name={lowerScatterName}
        size={3}
        style={{
          data: {
            fill: getStrokeColor(lineColors, colorIndex),
            fillOpacity: () => scatterOpacity,
          },
        }}
        labels={datum => datum.y}
        labelComponent={ChartTooltip(
          customLabelComponent,
          'lowLabel',
          highLight,
          theme,
        )}
      />
    </VictoryGroup>
  );
};

const renderLineElement = (
  elementData,
  idx,
  lineColors,
  lightShadeLineColors,
  theme,
) => {
  const {
    highLight,
    colorIndex,
    id,
    metric,
    useLightShadedColor,
  } = elementData;
  const idMetricStr = `${id}___${metric}`;
  const strokeWidth = highLight ? STROKE_HIGHLIGHT_WIDTH : STROKE_NORMAL_WIDTH;
  const scatterOpacity =
    highLight ||
    (elementData && elementData.data && elementData.data.length === 1)
      ? 1
      : 0;
  const lineName = `${idx}-line____${idMetricStr}`;
  const scatterName = `${idx}-scatter____${idMetricStr}`;
  const strokeDashArray = elementData.pattern === 'dashed' ? '4,4' : null;
  const colors = useLightShadedColor ? lightShadeLineColors : lineColors;
  return (
    <VictoryGroup key={`${id}-${metric}`}>
      <VictoryLine
        data={elementData.data}
        labelComponent={ChartTooltip()}
        name={lineName}
        style={{
          data: {
            strokeDasharray: strokeDashArray,
            stroke: getStrokeColor(colors, colorIndex),
            strokeWidth: () => strokeWidth,
          },
        }}
      />
      <VictoryScatter
        data={elementData.data}
        symbol={'circle'}
        name={scatterName}
        size={3}
        style={{
          data: {
            fill: getStrokeColor(colors, colorIndex),
            fillOpacity: () => scatterOpacity,
          },
        }}
        labels={datum => datum.y}
        labelComponent={ChartTooltip(
          elementData.customLabelComponent,
          '',
          highLight,
          theme,
        )}
      />
    </VictoryGroup>
  );
};

const renderChartElement = (
  elementData,
  idx,
  lineColors,
  lightShadeLineColors,
  theme,
) => {
  if (elementData.type === 'CHART_ELEMENT_AREA') {
    return renderAreaElement(elementData, idx, lineColors, theme);
  }
  return renderLineElement(elementData, idx, lineColors, lightShadeLineColors, theme);
};

type State = {
  activeCharts: Object,
  windowWidth: number,
};

class CompositeXYChartRaw extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  state: State = {
    activeCharts: {},
    windowWidth: window.innerWidth,
  };
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }
  props: Props;
  activationChangeHandler = (points, hoverHandler, activated) => {
    const idMetricMap = {};
    _.each(points, point => {
      idMetricMap[point.childName.split('____')[1]] = true;
    });
    const updatedActiveCharts = this.state.activeCharts;
    _.each(idMetricMap, (value, idMetricCombination) => {
      const id = idMetricCombination.split('___')[0];
      let count = updatedActiveCharts[idMetricCombination] || 0;
      if (activated) {
        count++;
        hoverHandler(id, true);
      } else {
        count--;
        if (count === 0) {
          hoverHandler(id, false);
        }
      }
      updatedActiveCharts[idMetricCombination] = count;
    });
    this.setState({
      activeCharts: updatedActiveCharts,
    });
  };

  render() {
    const {
      chartData,
      chartScale,
      formatXAxisData,
      hoverHandler = () => {},
      lineColors,
      lightShadeLineColors,
      theme,
      xAxisLabel,
      xAxisValues,
      yAxisLabel,
    }: Props = this.props;
    const yAxisObj = getYAxisValues(chartData);
    const yAxisValues = !_.isEmpty(yAxisObj) ? yAxisObj.yTicks : [];
    return (
      <VictoryChart
        containerComponent={
          <VictoryVoronoiContainer
            onActivated={points =>
              this.activationChangeHandler(points, hoverHandler, true)
            }
            onDeactivated={points =>
              this.activationChangeHandler(points, hoverHandler, false)
            }
            radius={50}
            responsive={false}
          />
        }
        domainPadding={{ x: 5 }}
        padding={{ top: 100, bottom: 0, right: 20, left: 25 }}
        width={this.state.windowWidth - 135}
        scale={chartScale}
      >
        <VictoryAxis
          tickValues={xAxisValues}
          tickCount={24}
          width={900}
          tickFormat={tick => formatXAxisData(tick)}
          label={xAxisLabel}
          axisLabelComponent={
            <VictoryLabel
              dy={10}
            />
          }
          style={{
            ticks: { stroke: theme.color.bodyText, size: 5 },
            tickLabels: {
              fontSize: theme.fontSize.bodyText,
              fontFamily: theme.typography.bodyText,
              fill: theme.color.bodyText,
            },
            axis: { stroke: theme.color.bodyText },
          }}
          fixLabelOverlap
        />
        <VictoryAxis
          tickValues={yAxisValues}
          tickFormat={getYAxisFormatter(yAxisObj)}
          dependentAxis
          label={yAxisLabel}
          tickLabelComponent={<VictoryLabel dy={-10} />}
          domainPadding={0}
          style={{
            tickLabels: {
              fontSize: theme.fontSize.bodyText,
              fontFamily: theme.typography.bodyText,
              fill: theme.color.bodyText,
              padding: -25,
            },
            grid: { stroke: theme.color.bodyText },
            axis: { display: 'none' },
          }}
        />
        {chartData.map((elementData, idx) =>
          renderChartElement(
            elementData,
            idx,
            lineColors,
            lightShadeLineColors,
            theme,
          ),
        )}
      </VictoryChart>
    );
  }
}

export default compose(withStyles(Theme))(withTheme(CompositeXYChartRaw));
