import axios from 'axios';
import { flatten, get, isEmpty } from 'lodash';
import { stringify } from 'query-string';

import * as Constants from '../../constants';
import { AXIOS_DEFAULT_OPTIONS, AXIOS_DEFAULT_OPTIONS_HEADERS } from '../';
import { getValidationErrors } from '../../utils';
import { debouncedAxiosGet, stringifyUrl } from '../../utils/actions';
import { getConfig } from '../../reducers/portalConfig';
import { getOrgPayload } from './requestParser';

const initApplication = (dispatch, isLoading = true) =>
  dispatch({
    type: Constants.APP_LOADING,
    isLoading,
  });

export const resetApp = () => dispatch => dispatch({ type: Constants.APP_RESET });
export const showLoading = isLoading => dispatch => initApplication(dispatch, isLoading);

export const init = (dispatch, isLoading = true) =>
  dispatch({
    type: Constants.APP_LOADING,
    isLoading,
  });

export const fetchAllOrganizations = ({ size = 100 } = {}) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const urlPrefix = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/internal/organizations`;

    const getCascadingRequests = (totalPages) => {
      const cascadingRequests = [];
      for (let page = 1; page < totalPages; page++) {
        const url = stringifyUrl({
          url: urlPrefix,
          query: { status: Constants.ORGANIZATION_STATUS_ENABLED, page, size },
        });
        cascadingRequests.push(
          axios.get(url, AXIOS_DEFAULT_OPTIONS),
        );
      }
      return cascadingRequests;
    };

    const url = stringifyUrl({
      url: urlPrefix,
      query: { status: Constants.ORGANIZATION_STATUS_ENABLED, page: 0, size },
    });

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(async response => {
        const { totalPages } = response.data;
        if (totalPages > 1) {
          const cascadingResponses = await Promise.all(
            getCascadingRequests(totalPages),
          );
          const cascadingResponsesResults = cascadingResponses
            .map(res => res.data.results);
          return {
            ...response.data,
            results: [
              ...response.data.results,
              ...flatten(cascadingResponsesResults),
            ],
          };
        }
        return response.data;
      })
      .then(response =>
        dispatch({
          type: Constants.ORGANIZATIONS_GET_SUCCESS,
          payload: response,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.ORGANIZATIONS_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchOrganizations = ({
  name = null,
  tag,
  accountPlanUuid,
  type = Constants.ANY,
  status = Constants.ALL,
  page = 0,
  size = 12,
} = {}) =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    resetApp();
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      name: !isEmpty(name) ? name : undefined,
      tag: !isEmpty(tag) ? tag : undefined,
      accountPlanUuid: !isEmpty(accountPlanUuid) ? accountPlanUuid : undefined,
      type: (type !== Constants.ANY) ? type : undefined,
      status: (status !== Constants.ALL) ? status : undefined,
      page,
      size,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/1.0/organizations?${params}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.ORGANIZATION_LIST_GET_SUCCESS,
          payload: response.data,
        }),
      )
      .catch((error) =>
        dispatch({
          type: Constants.ORGANIZATION_LIST_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchOrganization = (organizationUuid) =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
        `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/1.0/organizations/${organizationUuid}`;
    dispatch({
      type: Constants.ORGANIZATION_GET_REQUEST,
    });
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.ORGANIZATION_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() =>
        dispatch({
          type: Constants.ORGANIZATION_GET_ERROR,
          payload: [''],
        }),
      );
  };

export const fetchAccountPlans = () =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      $select: 'Uuid,Name',
      $inlinecount: 'allpages',
    }, true);

    dispatch({
      type: Constants.ACCOUNTPLANS_GET_REQUEST,
    });
    const url =
        `${portal.hostname}/admin/Portal.svc/AccountPlans?${params}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.ACCOUNTPLANS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() =>
        dispatch({
          type: Constants.ACCOUNTPLANS_GET_ERROR,
          payload: [''],
        }),
      );
  };

export const fetchOrganizationNameUnique = (orgName) =>
  (dispatch, getState) => {
    // Run Unique check when orgName is non-null.
    if (!orgName) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const queryParam = encodeURIComponent(`'${orgName}'`);
    const url =
      `${portal.hostname}/admin/Portal.svc/OrganizationNameUnique()?Name=${queryParam}`;

    dispatch({
      type: Constants.ORGANIZATION_NAME_UNIQUE_REQUEST,
    });
    debouncedAxiosGet.cancel();
    debouncedAxiosGet(
      url,
      { credentials: 'include' },
      (response) => dispatch({
        type: Constants.ORGANIZATION_NAME_UNIQUE_SUCCESS,
        payload: response.data,
      }),
      () => dispatch({
        type: Constants.ORGANIZATION_NAME_UNIQUE_ERROR,
        payload: [''],
      }),
    );
  };

export const createOrganization = (orgDetails) =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = getOrgPayload(orgDetails);
    dispatch({
      type: Constants.ORGANIZATION_CREATE_REQUEST,
    });

    const url = `${portal.hostname}/admin/Portal.svc/Organizations`;
    await axios.post(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
      .then((response) => {
        dispatch({
          type: Constants.ORGANIZATION_CREATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.ORGANIZATION_CREATE_ERROR,
          payload: get(error, 'response.data'),
        });
      });
  };

export const updateOrganization = (orgDetails) =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = getOrgPayload(orgDetails);
    dispatch({
      type: Constants.ORGANIZATION_UPDATE_REQUEST,
    });

    const url = `${portal.hostname}/admin/Portal.svc/Organizations('${orgDetails.orgUuid}')`;
    await axios.put(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
      .then((response) => {
        dispatch({
          type: Constants.ORGANIZATION_UPDATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.ORGANIZATION_UPDATE_ERROR,
          payload: get(error, 'response.data'),
        });
      });
  };

const cacheTags = {};
export const fetchTags = ({ config, name, setResponse }) => {
  if (name && cacheTags[name]) {
    setResponse(cacheTags[name]);
    return Promise.resolve(cacheTags[name]);
  }

  const { portal } = config;

  const params = stringify({
    name: !isEmpty(name) ? name : undefined,
  }, true);
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/1.0/tags?${params}`;

  debouncedAxiosGet.cancel();
  return debouncedAxiosGet(
      url,
      AXIOS_DEFAULT_OPTIONS,
      (response) => {
        cacheTags[name] = response.data;
        setResponse(response.data);
        return response.data;
      },
    );
}

export const createAndUpdateOrganizationTag = ({ tags, orgUuid, action }) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    let tagUuid;

    dispatch({
      type: Constants.ORG_TAG_POST_PUT_REQUEST,
    });

    const createUrl = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/1.0/tags`;
    await axios.post(createUrl, tags, AXIOS_DEFAULT_OPTIONS_HEADERS)
      .then((response) => {
        const tagName = get(response, 'data[0].name');
        cacheTags[tagName] = undefined;
        tagUuid = get(response, 'data[0].uuid');
        const params = stringify({
          action,
        }, true);
        const patchUrl =
          `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/1.0/tags/${tagUuid}/organizations?${params}`;
        return axios.patch(patchUrl, orgUuid, AXIOS_DEFAULT_OPTIONS);
      })
      .then((response) => {
        dispatch({
          type: Constants.ORG_TAG_POST_PUT_SUCCESS,
          payload: { ...response.data, tagUuid },
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.ORG_TAG_POST_PUT_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const updateOrganizationTags = ({ tagUuid, orgUuid, action }) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    dispatch({
      type: Constants.ORG_TAG_PUT_REQUEST,
    });

    const params = stringify({
      action,
    }, true);
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/1.0/tags/${tagUuid}/organizations?${params}`;
    await axios.patch(url, orgUuid, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.ORG_TAG_PUT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.ORG_TAG_PUT_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchRateLimitQuotas = (size = 1000, page = 0) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    
    const params = stringify({
      assignmentLevel: 'ORGANIZATION',
      page,
      size,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/rate-quotas?${params}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.ORG_RATE_QUOTA_GET_LIST_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.ORG_RATE_QUOTA_GET_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
    };

export const fetchOrgApiAccess = ({
  apiName = null,
  apiTags,
  orgUuid: orgUuid,
  apiAccessStatus,
  orgApiAccess,
  page = 0,
  size = 12,
} = {}) =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const params = stringify({
      apiName: !isEmpty(apiName) ? apiName : undefined,
      apiTags: !isEmpty(apiTags) ? apiTags : undefined,
      apiAccessStatus: !isEmpty(apiAccessStatus) &&
                apiAccessStatus !== 'any' ? apiAccessStatus : undefined,
      apiOrgAccessStatusList: orgApiAccess,
      page,
      size,
    }, true);
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/internal/access/apis-organization/${orgUuid}?${params}`;
    dispatch({
      type: Constants.ORG_APIACCESS_GET_LIST_REQUEST,
    });
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.ORG_APIACCESS_GET_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() =>
        dispatch({
          type: Constants.ORG_APIACCESS_GET_LIST_ERROR,
          payload: [''],
        }),
      );
  };

  export const updateApisAccessWithOrg = (data, orgUuid, action) =>
    async (dispatch, getState) => {
      initApplication(dispatch);
      const config = getConfig(getState());
      const { portal } = config;
      const url = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/1.0/organizations/${orgUuid}/apis?action=${action}`;
      dispatch({
        type: Constants.API_ORGANIZATION_ACCESS_PATCH_REQUEST,
      });
      axios.patch(url, data, { credentials: 'include' })
        .then(() => {
          dispatch({
            type: Constants.API_ORGANIZATION_ACCESS_PATCH_SUCCESS,
          });
        })
        .catch((error) =>
          dispatch({
            type: Constants.API_ORGANIZATION_ACCESS_PATCH_ERROR,
            payload: getValidationErrors(error),
          }),
        );
  };

  export const updateApiRateLimitQuota = (orgUuid, data, action) =>
    async (dispatch, getState) => {
      initApplication(dispatch);
      const config = getConfig(getState());
      const { portal } = config;

      let url = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/1.0/organizations/${orgUuid}/api-rate-quotas`;
      if(action === 'remove') {
        url = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/1.0/organizations/${orgUuid}/api-rate-quotas?action=${action}`;
      }
      await axios.patch(url, data, { credentials: 'include' })
        .then((response) => {
          dispatch({
            type: Constants.API_ORG_ACCESS_RATE_QUOTA_PATCH_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) =>
          dispatch({
            type: Constants.API_ORG_ACCESS_RATE_QUOTA_PATCH_ERROR,
            payload: getValidationErrors(error),
          }),
        );
  };