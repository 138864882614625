import { get, set } from 'lodash';
import * as constants from '../../constants';

const DEFAULT_API_DEPLOYMENT_TYPE = 'ON_DEMAND';
const DEFAULT_API_KEY_DEPLOYMENT_TYPE = 'AUTOMATIC';
const initialState = {
  hasError: false,
  pageErrors: [],
  proxyUuid: '',
  proxyName: '',
  proxyNameUniqueStatus: '',
  apiProxyType: DEFAULT_API_DEPLOYMENT_TYPE,
  apiKeyProxyType: DEFAULT_API_KEY_DEPLOYMENT_TYPE,
  proxyDetails: {},
  gatewayDetails: {},
  gateways: [],
  apis: [],
  apps: [],
  applicationApiKeysCount: 0,
  accountPlans: [],
  apiPlans: [],
  apiPlansCount: 0,
  saveProxyStatus: '',
  updateKeyDeploymentTypeStatus: '',
  deleteProxyStatus: '',
  proxyOrgsData: {},
  updateProxyOrgsStatus: '',
  organizations: [],
  selectedOrganizations: [],
  isLoading: false,
  useNewAutoSync: false,
  syncApis: {
    portalApis: {
      totalElements: 0,
      results: [],
    },
    publishedByPortal: {
      totalElements: 0,
      results: [],
    },
    publishedByGateway: {
      totalElements: 0,
      results: [],
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.API_PROXY_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case constants.API_PROXY_RESET:
      return {
        ...initialState,
      };
    case constants.CHANGE: {
      const { key, value } = action.payload;
      const newState = Object.assign({}, state);

      return set(newState, key, value);
    }
    case constants.API_PROXY_VALIDATE_UNIQUE_NAME_RESET:
      return {
        ...state,
        proxyNameUniqueStatus: constants.RESET,
      };
    case constants.API_PROXY_VALIDATE_UNIQUE_NAME_SUCCESS:
      return {
        ...state,
        proxyNameUniqueStatus: action.payload ? constants.SUCCESS : constants.FAIL,
        hasError: false,
        pageErrors: [],
        isLoading: false,
      };
    case constants.API_PROXY_VALIDATE_UNIQUE_NAME_ERROR:
      return {
        ...state,
        proxyNameUniqueStatus: constants.FAIL,
        hasError: true,
        pageErrors: action.payload,
        isLoading: false,
      };
    case constants.API_PROXY_SAVE_STATUS_RESET:
      return {
        ...state,
        saveProxyStatus: action.payload,
      };
    case constants.API_PROXY_CREATE_SUCCESS:
      return {
        ...state,
        proxyUuid: get(action.payload, 'uuid'),
        proxyName: get(action.payload, 'name'),
        apiProxyType: get(action.payload, 'deploymentType'),
        proxyDetails: action.payload,
        saveProxyStatus: constants.CREATE_SUCCESS,
        hasError: false,
        pageErrors: [],
        isLoading: false,
      };
    case constants.API_PROXY_UPDATE_SUCCESS:
      return {
        ...state,
        proxyUuid: get(action.payload, 'uuid'),
        proxyName: get(action.payload, 'name'),
        apiProxyType: get(action.payload, 'deploymentType'),
        proxyDetails: action.payload,
        saveProxyStatus: constants.UPDATE_SUCCESS,
        hasError: false,
        pageErrors: [],
        isLoading: false,
      };
    case constants.API_PROXY_CREATE_ERROR:
      return {
        ...state,
        saveProxyStatus: constants.CREATE_FAIL,
        hasError: true,
        pageErrors: action.payload,
        isLoading: false,
      };
    case constants.API_PROXY_UPDATE_ERROR:
      return {
        ...state,
        saveProxyStatus: constants.UPDATE_FAIL,
        hasError: true,
        pageErrors: action.payload,
        isLoading: false,
      };
    case constants.API_PROXY_DELETE_SUCCESS:
      return {
        ...state,
        deleteProxyStatus: constants.SUCCESS,
        hasError: false,
        pageErrors: [],
        isLoading: false,
      };
    case constants.API_PROXY_DELETE_ERROR:
      return {
        ...state,
        deleteProxyStatus: constants.FAIL,
        hasError: true,
        pageErrors: action.payload,
        isLoading: false,
      };
    case constants.API_PROXY_FETCH_DEPLOYMENT_TYPE_SUCCESS:
      return {
        ...state,
        apiProxyType: get(action.payload, 'api', DEFAULT_API_DEPLOYMENT_TYPE),
        apiKeyProxyType: get(action.payload, 'application', DEFAULT_API_KEY_DEPLOYMENT_TYPE),
        hasError: false,
        pageErrors: [],
        isLoading: false,
      };
    case constants.API_PROXY_FETCH_DEPLOYMENT_TYPE_ERROR:
      return {
        ...state,
        hasError: true,
        pageErrors: action.payload,
        isLoading: false,
      };
    case constants.API_PROXY_UPDATE_KEY_DEPLOYMENT_TYPE_SUCCESS:
      return {
        ...state,
        updateKeyDeploymentTypeStatus: constants.SUCCESS,
        hasError: false,
        pageErrors: [],
        isLoading: false,
      };
    case constants.API_PROXY_UPDATE_KEY_DEPLOYMENT_TYPE_ERROR:
      return {
        ...state,
        updateKeyDeploymentTypeStatus: constants.FAIL,
        hasError: true,
        pageErrors: action.payload,
        isLoading: false,
      };
    case constants.API_PROXY_FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
        hasError: false,
        pageErrors: [],
      };
    case constants.API_PROXY_FETCH_ORGANIZATIONS_ERROR:
      return {
        ...state,
        organizations: [],
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.API_PROXY_FETCH_SELECTED_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        selectedOrganizations: action.payload,
        hasError: false,
        pageErrors: [],
      };
    case constants.API_PROXY_FETCH_SELECTED_ORGANIZATIONS_ERROR:
      return {
        ...state,
        selectedOrganizations: [],
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.API_PROXY_UPDATE_ORGS_SUCCESS:
      return {
        ...state,
        proxyOrgsData: action.payload,
        updateProxyOrgsStatus: constants.SUCCESS,
        hasError: false,
        pageErrors: [],
        isLoading: false,
      };
    case constants.API_PROXY_UPDATE_ORGS_ERROR:
      return {
        ...state,
        proxyOrgsData: {},
        updateProxyOrgsStatus: constants.FAIL,
        hasError: true,
        pageErrors: action.payload,
        isLoading: false,
      };
    case constants.API_PROXY_FETCH_DETAILS_SUCCESS:
      return {
        ...state,
        proxyUuid: get(action.payload, 'uuid'),
        proxyName: get(action.payload, 'name'),
        apiProxyType: get(action.payload, 'deploymentType'),
        proxyDetails: action.payload,
        fetchProxyStatus: constants.SUCCESS,
        hasError: false,
        pageErrors: [],
      };
    case constants.API_PROXY_FETCH_DETAILS_ERROR:
      return {
        ...state,
        proxyUuid: '',
        proxyDetails: {},
        fetchProxyStatus: constants.FAIL,
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.FETCH_GATEWAY_DETAILS_SUCCESS:
      return {
        ...state,
        gatewayDetails: action.payload,
        hasError: false,
        pageErrors: [],
      };
    case constants.FETCH_GATEWAY_DETAILS_ERROR:
      return {
        ...state,
        gatewayDetails: {},
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.FETCH_GATEWAYS_SUCCESS:
      return {
        ...state,
        gateways: action.payload,
        hasError: false,
        pageErrors: [],
      };
    case constants.FETCH_GATEWAYS_ERROR:
      return {
        ...state,
        gateways: [],
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.FETCH_APIS_SUCCESS:
      return {
        ...state,
        apis: action.payload,
        hasError: false,
        pageErrors: [],
      };
    case constants.FETCH_APIS_ERROR:
      return {
        ...state,
        apis: [],
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.FETCH_APPS_SUCCESS:
      return {
        ...state,
        apps: action.payload,
        hasError: false,
        pageErrors: [],
      };
    case constants.FETCH_APPS_ERROR:
      return {
        ...state,
        apps: [],
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.FETCH_ACCOUNT_PLANS_SUCCESS:
      return {
        ...state,
        accountPlans: action.payload,
        hasError: false,
        pageErrors: [],
      };
    case constants.FETCH_ACCOUNT_PLANS_ERROR:
      return {
        ...state,
        accountPlans: [],
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.FETCH_API_PLANS_SUCCESS:
      return {
        ...state,
        apiPlans: get(action.payload, 'apiPlans'),
        apiPlansCount: get(action.payload, 'apiPlansCount'),
        hasError: false,
        pageErrors: [],
      };
    case constants.FETCH_API_PLANS_ERROR:
      return {
        ...state,
        apiPlans: [],
        apiPlansCount: 0,
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.APPLICATION_ALL_KEYS_COUNT_GET_SUCCESS:
      return {
        ...state,
        applicationApiKeysCount: get(action.payload, 'count'),
        hasError: false,
        pageErrors: [],
      };
    case constants.APPLICATION_ALL_KEYS_COUNT_GET_ERROR:
      return {
        ...state,
        applicationApiKeysCount: 0,
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.FETCH_API_SYNC_FEATURE_FLAG_SUCCESS:
      return {
        ...state,
        useNewAutoSync: action.payload,
      };
    case constants.FETCH_API_SYNC_FEATURE_FLAG_ERROR:
      return {
        ...state,
        useNewAutoSync: false,
      };
    case constants.FETCH_API_SYNC_COUNT_SUCCESS:
      return {
        ...state,
        syncApis: action.payload,
      };
    case constants.FETCH_API_SYNC_COUNT_ERROR:
      return {
        ...state,
        syncApis: {
          portalApis: {
            totalElements: 0,
            results: [],
          },
          publishedByPortal: {
            totalElements: 0,
            results: [],
          },
          publishedByGateway: {
            totalElements: 0,
            results: [],
          },
        },
      };
    case constants.PROXY_GET_SUCCESS:
      return {
        ...state,
        proxyDetails: get(action.payload, 'proxyDetails'),
        deploymentType: get(action.payload, 'deploymentType'),
        fetchProxyStatus: constants.SUCCESS,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_GET_ERROR:
      return {
        ...state,
        proxyUuid: '',
        proxyDetails: {},
        fetchProxyStatus: constants.FAIL,
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.PROXY_GATEWAY_GET_SUCCESS:
      return {
        ...state,
        gatewayDetails: action.payload.gatewayDetails,
        gateways: action.payload.gateways,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_GATEWAY_GET_ERROR:
      return {
        ...state,
        gatewayDetails: {},
        gateways: [],
        hasError: true,
        pageErrors: action.payload,
      };
    default:
      return state;
  }
}

const getFilteredProxyOrgs = (orgs, proxyOrgs) => {
  const filteredList = proxyOrgs.reduce((selectedOrgs, proxyOrg) => {
    const matchedProxyOrg = orgs.find(org => org.uuid === proxyOrg.uuid);
    if (matchedProxyOrg) {
      selectedOrgs.push(Object.assign({}, proxyOrg, matchedProxyOrg));
    }
    return selectedOrgs;
  }, []);

  return filteredList;
};

export const getApiProxy = (state) => get(state, 'apiProxy');
export const getProxyUuid = (state) => get(state, 'apiProxy.proxyUuid');
export const getProxyName = (state) => get(state, 'apiProxy.proxyName');
export const getProxyNameUniqueStatus = (state) => get(state, 'apiProxy.proxyNameUniqueStatus');
export const getApiProxyType = (state) => get(state, 'apiProxy.apiProxyType');
export const getApiKeyProxyType = (state) => get(state, 'apiProxy.apiKeyProxyType');
export const getProxyDetails = (state) => get(state, 'apiProxy.proxyDetails');
export const getSaveProxyStatus = (state) => get(state, 'apiProxy.saveProxyStatus');
export const getUpdateKeyDeploymentTypeStatus = (state) => get(state, 'apiProxy.updateKeyDeploymentTypeStatus');
export const getDeleteProxyStatus = (state) => get(state, 'apiProxy.deleteProxyStatus');
export const getOrganizations = (state) => get(state, 'apiProxy.organizations');
export const getSelectedOrganizations = (state) => {
  const organizations = get(state, 'apiProxy.organizations');
  const selectedOrganizations = get(state, 'apiProxy.selectedOrganizations');
  return getFilteredProxyOrgs(organizations, selectedOrganizations);
};
export const getUpdateProxyOrgsStatus = (state) => get(state, 'apiProxy.updateProxyOrgsStatus');
export const getIsLoading = (state) => get(state, 'apiProxy.isLoading');
export const getGatewayDetails = (state) => get(state, 'apiProxy.gatewayDetails');
export const getGateways = (state) => get(state, 'apiProxy.gateways');
export const getApis = (state) => get(state, 'apiProxy.apis');
export const getApps = (state) => get(state, 'apiProxy.apps');
export const getAccountPlans = (state) => get(state, 'apiProxy.accountPlans');
export const getApiPlans = (state) => get(state, 'apiProxy.apiPlans');
export const getApiPlansCount = (state) => get(state, 'apiProxy.apiPlansCount');
export const getApplicationApiKeysCount = (state) => get(state, 'apiProxy.applicationApiKeysCount');
export const getApiSyncFeatureFlag = (state) => get(state, 'apiProxy.useNewAutoSync');
export const getPortalApis = (state) => get(state, 'apiProxy.syncApis.portalApis');
export const getPortalPublishedApis = (state) => get(state, 'apiProxy.syncApis.publishedByPortal');
export const getGatewayPublishedApis = (state) => get(state, 'apiProxy.syncApis.publishedByGateway');

export const getDeploymentType = (state) => get(state, 'apiProxy.deploymentType');
