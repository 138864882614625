import axios from 'axios';
import qs from 'query-string';
import { get, isArray, map } from 'lodash';

import * as Constants from '../../constants';
import { getConfig } from '../../reducers/portalConfig';
import { AXIOS_DEFAULT_OPTIONS, AXIOS_DEFAULT_OPTIONS_HEADERS } from '../';
import { getValidationErrors } from '../../utils';
import { debouncedAxiosGet } from '../../utils/actions';
import { getCreateData, getUpdateData, getDeleteData } from './requestParser';
import { checkSession } from '../portalConfig';

const initApplication = (dispatch, isLoading = true) =>
  dispatch({
    type: Constants.APPLICATION_LOADING,
    isLoading,
  });

export const showLoading = isLoading => dispatch => initApplication(dispatch, isLoading);
export const resetApp = () =>
    dispatch => dispatch({ type: Constants.APP_RESET });
export const resetDeleteApplicationStatus = () =>
    dispatch => dispatch({ type: Constants.APPLICATION_DELETE_RESET });

export const fetchApplicationKeys = (appUuid, page = 0, size = 10) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/api-keys?page=${page}&size=${size}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_ALL_KEYS_GET_SUCCESS,
          payload: get(response, 'data'),
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ALL_KEYS_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchProxies = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/internal/proxies?status=ACTIVE`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.PROXIES_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.PROXIES_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchProxiesByApiKey = (apikey) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/api-keys/${apikey}/proxies`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.API_KEY_PROXIES_GET_SUCCESS,
          payload: {
            key: apikey,
            proxies: response.data,
          },
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.API_KEY_PROXIES_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

const getDeploymentValidationErrors = (error) => get(error, 'validationErrors');
const processDeploymentErrors = (validationErrors, defaultMessage) => {
  let errorMessage = defaultMessage;
  const keys = Object.keys(validationErrors);
  if (keys.length > 0) {
    errorMessage = map(keys, (key) => validationErrors[key].devMessage).join('\n');
  }
  return errorMessage;
};

export const deployApiKeyToProxy = (apiKey, uuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = { proxyUuid: uuid };

    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/api-keys/${apiKey}/proxies`;

    await axios.post(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
      .then((response) => {
        dispatch({
          type: Constants.API_KEY_DEPLOY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        const statusCode = get(error, 'response.status');
        if (statusCode === Constants.HTTP_STATUS_UNAUTHORIZED) {
          window.location.href =
            `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
        }

        const responseError = get(error, 'response.data');
        const strErrorMessage = (statusCode === Constants.HTTP_STATUS_BAD_REQUEST)
          ? processDeploymentErrors(getDeploymentValidationErrors(responseError), '')
          : get(error, 'message');
        dispatch({
          type: Constants.API_KEY_DEPLOY_ERROR,
          payload: {
            apiKey,
            proxyUuid: uuid,
            errorMessage: strErrorMessage,
          },
        });
      });
  };

export const redeployApiKeyToProxy = (apiKey, uuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = {
      status: 'PENDING_DEPLOYMENT',
    };

    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/api-keys/${apiKey}/proxies/${uuid}`;
    await axios.put(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
      .then((response) => {
        dispatch({
          type: Constants.API_KEY_REDEPLOY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        const statusCode = get(error, 'response.status');
        if (statusCode === Constants.HTTP_STATUS_UNAUTHORIZED) {
          window.location.href =
            `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
        }

        const responseError = get(error, 'response.data');
        const strErrorMessage = (statusCode === Constants.HTTP_STATUS_BAD_REQUEST)
          ? processDeploymentErrors(getDeploymentValidationErrors(responseError), '')
          : get(error, 'message');
        dispatch({
          type: Constants.API_KEY_REDEPLOY_ERROR,
          payload: {
            apiKey,
            proxyUuid: uuid,
            errorMessage: strErrorMessage,
          },
        });
      });
  };

export const undeployApiKeyFromProxy = (apiKey, uuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/api-keys/${apiKey}/proxies/${uuid}`;
    await axios.delete(url, AXIOS_DEFAULT_OPTIONS)
      .then(() => {
        dispatch({
          type: Constants.API_KEY_UNDEPLOY_SUCCESS,
          payload: null,
        });
      })
      .catch((error) => {
        const statusCode = get(error, 'response.status');
        if (statusCode === Constants.HTTP_STATUS_UNAUTHORIZED) {
          window.location.href =
            `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
        }

        const responseError = get(error, 'response.data');
        const strErrorMessage = (statusCode === Constants.HTTP_STATUS_BAD_REQUEST)
          ? processDeploymentErrors(getDeploymentValidationErrors(responseError), '')
          : get(error, 'message');
        dispatch({
          type: Constants.API_KEY_UNDEPLOY_ERROR,
          payload: {
            apiKey,
            proxyUuid: uuid,
            errorMessage: strErrorMessage,
          },
        });
      });
  };

 export const fetchSingleApplication = (applicationUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/Applications('${applicationUuid}')`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_SINGLE_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateApplicationStatus = (applicationDetails) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.APPLICATION_STATUS_UPDATE_RESET,
      payload: '',
    });

    const config = getConfig(getState());
    const { portal } = config;
    const data = getUpdateData(applicationDetails);

    const url = `${portal.hostname}/admin/Portal.svc/Applications('${applicationDetails.uuid}')`;
    await axios.put(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_STATUS_UPDATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_STATUS_UPDATE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAllApiPlans = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/api-plans`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_API_PLANS_GET_SUCCESS,
          payload: get(response, 'data.results', []),
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApplication = (applicationUuid) =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/Portal.svc/Applications('${applicationUuid}')`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const createApplication = (applicationDetails) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = getCreateData(applicationDetails);
    const url = `${portal.hostname}/admin/Portal.svc/Applications`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.post(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_CREATE_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const updateApplication = (applicationDetails) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = getUpdateData(applicationDetails);
    const url = `${portal.hostname}/admin/Portal.svc/Applications('${applicationDetails.uuid}')`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.put(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_UPDATE_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const updateAndGetApplication = (applicationDetails) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = getUpdateData(applicationDetails);
    const updateUrl = `${portal.hostname}/admin/Portal.svc/Applications('${applicationDetails.uuid}')`;
    const fetchUrl = `${portal.hostname}/admin/Portal.svc/Applications('${applicationDetails.uuid}')`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await Promise.all([
        await axios.put(updateUrl, data, AXIOS_DEFAULT_OPTIONS_HEADERS),
        await axios.get(fetchUrl, AXIOS_DEFAULT_OPTIONS),
      ])
        .then(responseArr => {
          dispatch({
            type: Constants.APPLICATION_UPDATE_AND_GET_SUCCESS,
            isLoading: false,
            payload: {
              ...responseArr[0].data,
              ...responseArr[1].data,
            },
          });
        })
        .catch(error =>
          dispatch({
            type: Constants.APPLICATION_ERROR,
            payload: getValidationErrors(error),
          }),
        );
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const updateApplicationSecret = (applicationDetails) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = getUpdateData(applicationDetails);
    const url = `${portal.hostname}/admin/Portal.svc/Applications('${applicationDetails.uuid}')`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.put(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_SECRET_UPDATE_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const deleteApplication = (applicationDetails) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = getDeleteData(applicationDetails);
    dispatch({ type: Constants.APPLICATION_DELETE_RESET });

    const url = `${portal.hostname}/admin/Portal.svc/Applications('${applicationDetails.uuid}')`;
    await axios.delete(url, data, AXIOS_DEFAULT_OPTIONS)
      .then(() => {
        dispatch({
          type: Constants.APPLICATION_DELETE_SUCCESS,
          payload: null,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_DELETE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchOrganizations = (pageNumber = 0, size = 10) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/internal/organizations?status=ENABLED&size=${size}&page=${pageNumber}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_ORGANIZATIONS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAllOrganizations = () => fetchOrganizations(1000);

export const getApplicationNameUniqueUrl = (applicationName, currentId, organizationUuid) => {
  const name = encodeURIComponent(applicationName);
  if (currentId && organizationUuid) {
    return `Name='${name}'&CurrentId='${currentId}'&OrganizationUuid='${organizationUuid}'`;
  } else if (currentId) {
    return `Name='${name}'&CurrentId='${currentId}'`;
  } else if (organizationUuid) {
    return `Name='${name}'&OrganizationUuid='${organizationUuid}'`;
  }
  return `Name='${name}'`;
};

export const fetchApplicationNameUnique = (applicationName, currentId, organizationUuid) =>
  (dispatch, getState) => {
    // Run Unique check when applicationName is non-null.
    if (!applicationName) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const applicationNameUniqueUrl =
      getApplicationNameUniqueUrl(applicationName, currentId, organizationUuid);
    const url =
      `${portal.hostname}/admin/Portal.svc/ApplicationNameUnique()?${applicationNameUniqueUrl}`;

    debouncedAxiosGet.cancel();
    debouncedAxiosGet(
      url,
      AXIOS_DEFAULT_OPTIONS,
      (response) => dispatch({
        type: Constants.APPLICATION_NAME_UNIQUE_SUCCESS,
        payload: response.data,
      }),
      (error) => dispatch({
        type: Constants.APPLICATION_ERROR,
        payload: getValidationErrors(error),
      }),
    );
  };

export const fetchCustomFields = () =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/Portal.svc/CustomFields?%24inlinecount=allpages&%24filter=EntityType+eq+%27APPLICATION%27+and+Status+eq+%27ENABLED%27`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_CUSTOM_FIELDS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchSelectedApis = (applicationUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/Portal.svc/ApplicationApiXrefs?%24inlinecount=allpages&%24filter=ApplicationUuid+eq+%27${applicationUuid}%27`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_SELECTED_APIS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          isLoading: false,
          payload: getValidationErrors(error),
        }),
      );
  };

const fetchQueryString = (params) => {
  let queryString = qs.stringify(params, true);
  queryString = queryString ? `?${queryString}` : '';
  return queryString;
};

export const fetchAvailableApis = (params) =>
  async (dispatch, getState) => {
    const defaultParams = {
      size: 10,
      page: 0,
      portalStatus: 'ENABLED',
    };
    const { tags: tagsParam, searchText: searchParam, ...otherParams } = params;
    const updatedParams = {
      ...defaultParams,
      ...otherParams,
      ...(isArray(tagsParam) && (tagsParam.length > 0) && ({ tags: tagsParam.join(',') })),
      ...(searchParam && ({ name: searchParam })),
    };
    const queryString = fetchQueryString(updatedParams);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis${queryString}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_AVAILABLE_APIS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
          page: updatedParams.page,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAvailableApps = (params) =>
  async (dispatch, getState) => {
    const defaultParams = {
      size: 10,
      page: 0,
      portalStatus: 'ENABLED',
    };
    const { tags: tagsParam, searchText: searchParam, ...otherParams } = params;
    const updatedParams = {
      ...defaultParams,
      ...otherParams,
      ...(isArray(tagsParam) && (tagsParam.length > 0) && ({ tags: tagsParam.join(',') })),
      ...(searchParam && ({ name: searchParam })),
    };
    const queryString = fetchQueryString(updatedParams);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications${queryString}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_AVAILABLE_APPS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
          page: updatedParams.page,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApplicationsLegacy = () =>
  async (dispatch, getState) => {
    const DC_UUID_EXCLUDE_FILTER = 'Uuid ne \'2c824a55-cb6d-471e-bd3c-08b46309d0ee\'';
    const params = {
      filter: DC_UUID_EXCLUDE_FILTER,
    };
    const queryString = fetchQueryString(params);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/Applications${queryString}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.API_LIST_APPLICATIONS_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAvailableOrgs = (params) =>
  async (dispatch, getState) => {
    const defaultParams = {
      size: 10,
      page: 0,
      portalStatus: 'ENABLED',
    };
    const { tags: tagsParam, searchText: searchParam, ...otherParams } = params;
    const updatedParams = {
      ...defaultParams,
      ...otherParams,
      ...(isArray(tagsParam) && (tagsParam.length > 0) && ({ tags: tagsParam.join(',') })),
      ...(searchParam && ({ name: searchParam })),
    };
    const queryString = fetchQueryString(updatedParams);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/internal/organizations${queryString}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_AVAILABLE_ORGS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
          page: updatedParams.page,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApiEula = (eulaUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/eulas/${eulaUuid}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_API_EULA_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchSelectedApiGroups = (applicationUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/api-management/internal/applications/ApplicationApiGroupXrefs?%24inlinecount=allpages&%24filter=ApplicationUuid+eq+%27${applicationUuid}%27`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_SELECTED_API_GROUPS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAvailableApiGroups = ({ orgUuid, size = 10, page = 0, searchText = '' }) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/api-management/internal/OrganizationApiGroups?orgUuid=${orgUuid}&size=${size}&page=${page}&name=${searchText}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_AVAILABLE_API_GROUPS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
          page,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApiGroupEulas = (apiGroupUuid, organizationUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/api-management/internal/ApiGroups/${apiGroupUuid}/Eulas?%24filter=OrganizationUuid+eq+%27${organizationUuid}%27`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_API_GROUP_EULAS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const generateSecret = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/Portal.svc/GenerateNewSharedSecret`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_GENERATE_SECRET_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const resetSecret = () =>
  (dispatch) => {
    dispatch({
      type: Constants.APPLICATION_GENERATE_SECRET_GET_SUCCESS,
      isLoading: false,
      payload: { d: { result: null } },
    });
  };

export const fetchAvailableApiPlans = (orgUuid, apiUuid) =>
  async (dispatch, getState) => {
    if (!(orgUuid && apiUuid)) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/api-management/internal/api-plans?orgUuid=${orgUuid}&apiUuid=${apiUuid}&size=10&page=0`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(({ data }) => {
        dispatch({
          type: Constants.APPLICATION_AVAILABLE_API_PLANS_GET_SUCCESS,
          isLoading: false,
          payload: {
            [apiUuid]: data,
          },
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchTenantInfos = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/Portal.svc/TenantInfos('${portal.tenantPrefix}')`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.TENANT_INFO_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const getKeyNameUniqueUrl = (appUuid, keyName, apiKey) => {
  const name = encodeURIComponent(keyName);
  if (apiKey) {
    return `${appUuid}/api-keys/isNameUnique?name=${name}&apiKey=${apiKey}`;
  }
  return `${appUuid}/api-keys/isNameUnique?name=${name}`;
};

export const fetchKeyNameUnique = (keyName, apiKey, appUuid) =>
  async (dispatch, getState) => {
    // Run Unique check when applicationName is non-null.
    if (!keyName) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const keyNameUniqueUrl =
      getKeyNameUniqueUrl(appUuid, keyName, apiKey);
    const url =
      `${portal.hostname}/admin/api-management/internal/applications/${keyNameUniqueUrl}`;

    debouncedAxiosGet.cancel();
    debouncedAxiosGet(
      url,
      AXIOS_DEFAULT_OPTIONS,
      (response) => dispatch({
        type: Constants.KEY_NAME_UNIQUE_SUCCESS,
        payload: get(response, 'data.isUnique'),
      }),
      (error) => dispatch({
        type: Constants.KEY_NAME_UNIQUE_ERROR,
        payload: getValidationErrors(error),
      }),
    );
  };

export const createKey = (keyDetails) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.KEY_STATUS_RESET,
      payload: '',
    });

    const config = getConfig(getState());
    const { portal } = config;
    const appUuid = get(keyDetails, 'appUuid');
    const data = {
      name: get(keyDetails, 'name'),
      applicationUuid: appUuid,
      oauthCallbackUrl: get(keyDetails, 'oauthCallbackUrl', ''),
      oauthScope: get(keyDetails, 'oauthScope', ''),
      oauthType: get(keyDetails, 'oauthType', null),
      keySecretHashed: get(keyDetails, 'keySecretHashed', false),
      defaultKey: get(keyDetails, 'defaultKey', false),
    };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/api-keys`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.post(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.KEY_CREATE_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.KEY_CREATE_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const updateKey = (keyDetails) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.KEY_STATUS_RESET,
      payload: '',
    });

    const config = getConfig(getState());
    const { portal } = config;
    const appUuid = get(keyDetails, 'appUuid');
    const apiKey = get(keyDetails, 'apiKey');
    const data = {
      name: get(keyDetails, 'name'),
      applicationUuid: appUuid,
      oauthCallbackUrl: get(keyDetails, 'oauthCallbackUrl', ''),
      oauthScope: get(keyDetails, 'oauthScope', ''),
      oauthType: get(keyDetails, 'oauthType', null),
      status: get(keyDetails, 'status'),
      keySecretHashed: get(keyDetails, 'keySecretHashed', false),
      keySecret: get(keyDetails, 'keySecret', undefined),
      defaultKey: get(keyDetails, 'defaultKey', false),
    };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/api-keys/${apiKey}`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.put(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.KEY_UPDATE_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.KEY_UPDATE_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const deleteKey = (keyDetails) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const appUuid = get(keyDetails, 'appUuid');
    const apiKey = get(keyDetails, 'apiKey');
    dispatch({
      type: Constants.KEY_STATUS_RESET,
      payload: '',
    });

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/api-keys/${apiKey}`;
    await axios.delete(url, AXIOS_DEFAULT_OPTIONS)
      .then(() => {
        dispatch({
          type: Constants.KEY_DELETE_SUCCESS,
          payload: null,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.KEY_DELETE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

