import React from 'react';
import { useIntl } from 'react-intl';
import copyToClipboard from 'copy-to-clipboard';
import { func, shape, string } from 'prop-types';
import {
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';

import styles from './styles';
import { ALERT_SUCCESS } from "../../constants";
import { getI18n } from "../../utils/intl";
import { DetailsPanelContainer } from "../index";

export const copy = ({ proxyUrl, notifyMessages, copyMessage }) => {
  notifyMessages(ALERT_SUCCESS, copyMessage);
  copyToClipboard(proxyUrl);
  setTimeout(() => notifyMessages('', ''), 3000);
};

export const UrlContainerContent = ({
  classes = {},
  proxyUrl,
  notifyMessages,
  copyMessage,
}) => {
  const intl = getI18n(useIntl());
  return (
    <IconButton
      className={classes.copyUrl}
      onClick={() => copy({ proxyUrl, notifyMessages, copyMessage })}
    >
      <Typography variant="body1">{intl.getI18nMessage(
        'label.copy.button')}</Typography>
    </IconButton>
  );
};

UrlContainerContent.propTypes = {
  classes: shape({}),
  notifyMessages: func,
  proxyUrl: string,
  copyMessage: string,
};
UrlContainerContent.displayName = 'UrlContainerContent';

export const UrlContainer = ({
  classes = {},
  id,
  proxyUrl,
  title,
  notifyMessages,
  copyMessage,
}) => {
  const intl = getI18n(useIntl());
  return (
    <DetailsPanelContainer
      id={id}
      title={title ? title : intl.getI18nMessage(
        'label.proxy.details.page.overview.proxy.url')}
      subTitle={proxyUrl}
      smallFont
      content={
        <UrlContainerContent
          classes={classes}
          proxyUrl={proxyUrl}
          notifyMessages={notifyMessages}
          copyMessage={copyMessage}
        />
      }
    />
  );
}

export default (
  withStyles(styles)
)(UrlContainer);

UrlContainer.propTypes = {
  classes: shape({}),
  id: string,
  proxyUrl: string,
  notifyMessages: func,
  title: string,
  copyMessage: string,
};
UrlContainer.displayName = 'UrlContainer';
