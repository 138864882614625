import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import EllipsisIcon from './ellipsis.svg';
import theme from './styles';

export default function GridPanelCard(props) {
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  const { cardEntity, cardEntityUUID, url, count, chartEntity } = props;
  const handleClick = (event) => {
    event.stopPropagation();
    window.location.href = url;
  };
  const handleEnterKey = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      event.stopPropagation();
      window.location.href = url;
    }
  };
  const cardEntityLocal = cardEntity || cardEntityUUID;
  const isNonClickable = !cardEntityLocal || (props.isApiOwnerOrOrgPub && (chartEntity === 'proxy' || chartEntity === 'org'));
  return (
    <div>
      {!isNonClickable ?
        <div
          tabIndex="0"
          className={count ? classes.cardAnalytics : classes.card}
          onClick={(e) => handleClick(e)}
          onKeyDown={e => handleEnterKey(e)}
        >
          <div className={classes.cardInner}>
            {cardEntityLocal.length > 20 ?
              <div>
                <span className={classes.cardNameEllipsis}>{cardEntityLocal}</span>
                <div className={classes.tooltip}>
                  <img
                    src={EllipsisIcon}
                    className={classes.ellipsis}
                    alt="Ellipsis"
                  />
                  <span className={classes.tooltiptext}>{cardEntityLocal}</span>
                </div>
                {count ? <span className={classes.count}>{count}</span> : null}
              </div>
              :
              <div>
                <span className={classes.cardName}>{cardEntityLocal}</span>
                {count ? <span className={classes.count}>{count}</span> : null}
              </div>
            }
          </div>
        </div>
        :
        <div
          className={count ? classes.cardUnauthorizedAnalytics : classes.cardUnauthorized}
        >
          <div className={classes.cardInner}>
            <div>
              <span className={classes.cardNameUnAuthorized}>{cardEntityLocal || 'Unauthorized'}</span>
              {count ? <span className={classes.count}>{count}</span> : null}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

GridPanelCard.propTypes = {
  cardEntity: PropTypes.string,
  cardEntityUUID: PropTypes.string,
  url: PropTypes.string,
  count: PropTypes.number,
  isApiOwnerOrOrgPub: PropTypes.bool,
  chartEntity: PropTypes.string,
};
