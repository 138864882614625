import React, { Fragment, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { get, some, values, each, isEmpty, isUndefined } from 'lodash';

import {
  FormActionButtons,
  ErrorContainer,
  FormTextField,
  FormSelect,
} from '../../../../components';
import {
  NEXT_TEXT,
  APPLICATION_CUSTOM_FIELDS_ASSIST_TEXT,
} from '../labels';

const MAX_LENGTH = 5000;

export const getCustomField = (
  applicationDetails,
  { type, uuid, name, description, optionsList, required },
  applicationCustomFieldErrors,
  handleChange,
) => {
  const customFieldValue = applicationDetails.customFieldValues[uuid];
  const helperText = description ? `${description}. Maximum field length is 5000 characters.`
   : 'Maximum field length is 5000 characters.';
  switch (type) {
    case 'TEXT':
      return (
        <FormTextField
          key={uuid}
          id={uuid}
          name={name}
          value={customFieldValue}
          error={applicationCustomFieldErrors[uuid]}
          handleChange={(value) => handleChange(uuid, value, required)}
          helperText={helperText}
          {...(!required && { optional: true })}
        />
      );
    case 'SINGLE_SELECT': {
      const data = optionsList.map(({ value }) => ({ uuid: value, name: value }));
      const handleSingleSelectChange = (e) => {
        const selectedValue = (get(e, 'target.value') === 0 || get(e, 'target.value') === '0')
          ? '' : get(e, 'target.value');
          handleChange(uuid, selectedValue, required);
      };
      return (
        <FormSelect
          key={uuid}
          id={uuid}
          name={name}
          value={customFieldValue}
          defaultText={'Select an option.'}
          data={data}
          handleChange={handleSingleSelectChange}
          helperText={description}
          error={applicationCustomFieldErrors[uuid]}
          {...(!required && { optional: true })}
        />
      );
    }
    default:
      return null;
  }
};

export default function CustomFields(props) {
  const {
    classes,
    headerRef,
    errors = [],
    setCurrentTab,
    applicationDetails = {},
    setApplicationDetails,
    customFields = [],
    onCancel,
  } = props;

  const [applicationCustomFieldErrors, setApplicationCustomFieldErrors] = useState({});

  const handleChange = (fieldName, value, required) => {
    localStorage.setItem('isAppUnSavedChanges', true);
    const { customFieldValues } = applicationDetails;
    if (value.length > MAX_LENGTH) {
      setApplicationCustomFieldErrors(
        { ...applicationCustomFieldErrors, [fieldName]: true },
      );
    } else {
      setApplicationDetails({
        ...applicationDetails,
        customFieldValues: { ...customFieldValues, [fieldName]: value },
      });
      setApplicationCustomFieldErrors(
        { ...applicationCustomFieldErrors, [fieldName]: (required && !value) },
      );
    }
  };

  const onNext = () => {
    const { customFieldValues } = applicationDetails;
    const customFieldErrors = customFields
      .filter(({ required }) => required)
      .reduce((acc, { uuid }) => ({ ...acc, [uuid]: !customFieldValues[uuid] }), {});

    if (some(values(customFieldErrors)) || some(values(applicationCustomFieldErrors))) {
      if (!isEmpty(applicationCustomFieldErrors)) {
        each(applicationCustomFieldErrors, (val, key) => {
          if (customFieldErrors[key]) {
            applicationCustomFieldErrors[key] = customFieldErrors[key];
          }
        });
        setApplicationCustomFieldErrors(
          { ...applicationCustomFieldErrors },
        );
      } else {
        setApplicationCustomFieldErrors(
          { ...applicationCustomFieldErrors, ...customFieldErrors },
        );
      }
    } else {
      setApplicationDetails({
        ...applicationDetails,
        customFieldValues: customFields.reduce(
          (acc, { uuid }) => {
            if (isUndefined(acc[uuid])) { acc[uuid] = ''; }
            return acc;
          },
          applicationDetails.customFieldValues,
        ),
      });
      setCurrentTab('api-management-tab');
      window.scrollTo(0, headerRef.offsetTop);
    }
  };

  return (
    <Fragment>
      <Typography variant="h1" className={classes.pageTitle}>
        Custom Fields
      </Typography>
      {errors.length > 0 &&
        <ErrorContainer errors={errors} />
      }
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid md={12} item>
          {customFields.map(customField => getCustomField(
              applicationDetails, customField, applicationCustomFieldErrors, handleChange,
          ))}
        </Grid>
      </Grid>
      <FormActionButtons
        onNextClick={onNext}
        assistText={APPLICATION_CUSTOM_FIELDS_ASSIST_TEXT}
        nextText={NEXT_TEXT}
        onCancelClick={onCancel}
        id="visibility-form-buttons"
      />
    </Fragment>
  );
}

CustomFields.propTypes = {
  classes: PropTypes.object,
  headerRef: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  setCurrentTab: PropTypes.func,
  applicationDetails: PropTypes.object,
  setApplicationDetails: PropTypes.func,
  customFields: PropTypes.arrayOf(PropTypes.object),
  onCancel: PropTypes.func,
};
