import modifyColour from '../../../utils/modifyColour';
import { get } from 'lodash';

export default ({ color, misc, fontSize, typography }) => ({
  HeaderMenuButton: {
    border: 'none',
    paddingRight: '50px',
    position: 'absolute',
    left: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',

    '&:focus': {
      outline: 'none',
    },
  },

  HeaderMenuButtonIcon: {
    height: 18,
    width: 25,
    marginTop: -9,
    top: '50%',
    left: 0,
    display: 'block',
    position: 'absolute',
    padding: 0,

    '&:before': {
      top: 0,
    },

    '&:after': {
      bottom: 0,
    },

    '&:before, &:after': {
      left: 0,
      right: 0,
      position: 'absolute',
      height: 2,
      content: '" "',
      display: 'block',
      backgroundColor: get(color, 'title'),
    },
  },

  HeaderMenuButtonIconMiddleBar: {
    display: 'block',
    height: 2,
    marginTop: -1,
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    backgroundColor: get(color, 'title'),
  },
  list: {
    width: '300px',
    marginTop: '10px',
    '& .MuiListItem-root': {
      paddingTop: '0px',

    },

    '& .MuiListItem-button:hover': {
      backgroundColor: 'transparent',
    },
  },
  SidemenuNavLink: {
    display: 'block',
    padding: '7px 15px 7px 0',
    marginLeft: '20px',
    fontSize: get(fontSize, 'bodyText'),
    '& a': {
      color: get(color, 'link'),
      display: 'inline-block',
      fontSize: get(fontSize, 'bodyText'),
      fontFamily: get(typography, 'bodyText'),
      textDecoration: 'none',
      transition: get(misc, 'colorTransition'),
      textTransform: 'capitalize',
      margin: 0,
      padding: 0,
      '&.active': {
        color: get(color, 'grayDark'),
      },
      '&:hover': {
        color: get(color, 'linkHover'),
      },
    },
  },
  logOutButton: {
    color: get(color, 'link'),
    display: 'inline-block',
    fontSize: get(fontSize, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    textDecoration: 'none',
    transition: get(misc, 'colorTransition'),
    textTransform: 'capitalize',
    margin: 0,
    padding: 0,
    minWidth: 0,
    '&.active': {
      color: get(color, 'grayDark'),
    },
    '&:hover': {
      color: get(color, 'linkHover'),
    },
  },
  userMenuContent: {
    marginLeft: '40px',
  },
  HeaderServices: {
    fontFamily: get(typography, 'bodyText'),
    zIndex: 100,
  },
  HeaderServicesPanel: {
    alignItems: 'baseline',
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: get(typography, 'bodyText'),
    padding: '20px 15px',
    width: '290px',
  },

  HeaderService: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    width: `${100 / 3}%`,

    color: get(color, 'grayDark'),
    fontSize: get(fontSize, 'smallText'),
    textAlign: 'center',
    textDecoration: 'none',
  },

  HeaderServiceImage: {
    marginBottom: '8px',
    maxHeight: 28,
    maxWidth: 28,
  },

  HeaderServiceLink: {
    boxSizing: 'border-box',
    color: get(color, 'link'),
    fontSize: get(fontSize, 'smallText'),
    padding: '10px',
    width: `${100}%`,
    textAlign: 'center',
  },

  HeaderServicesButton: {
    display: 'none',
    '& button': {
      color: get(color, 'link'),
    },
  },

  ServiceLink: {
    textDecoration: 'none',
    transition: get(misc, 'colorTransition'),

    '&:hover': {
      color: modifyColour(get(color,'link'), 'lighten', 0.4),
    },
  },

  ServiceLinkImageContainer: {
    margin: '4px',
    width: 30,
    height: 30,
    float: 'left',
    backgroundColor: get(color, 'link'),
  },

  ServiceLinkImage: {
    maxHeight: 30,
    maxWidth: 30,
  },
  serviceText: {
    float: 'left',
    paddingTop: '10px',
    margin: '4px !important',
    fontSize: '14px',
  },
  subHeader: {
    marginBottom: 15,
    color: get(color, 'gray'),
    fontSize: get(fontSize, 'smallText'),
    textTransform: 'uppercase',
    margin: '20px 25px',
    padding: '0',
  },

  UserMenuContent__Item: {
    '& button, & a': {
      color: get(color, 'link'),
      display: 'inline-block',
      transition: get(misc, 'colorTransition'),

      '& span': {
        fontSize: get(fontSize, 'bodyText'),
        fontFamily: get(typography, 'bodyText'),
        fontWeight: 'normal',
        marginLeft: 0,
        textDecoration: 'none',
      },

      '&:hover': {
        color: modifyColour(get(color, 'link'), 'lighten', 0.4),
        backgroundColor: `${get(color, 'white')} !important`,
      },

      '&:focus': {
        boxShadow: 'none',
        outline: 'none',
      },
    },
  },

  UserMenuContent__AccessibleOrgWrapper: {
    display: 'flex',
    margin: '2px 0',
    width: '100%',
  },

  UserMenuContent__AccessibleOrgs: {
    backgroundColor: 'transparent !important',
    border: 'none',
    color: get(color, 'link'),
    fontSize: get(fontSize, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    paddingLeft: 0,
    textAlign: 'left',
    cursor: 'pointer',
    outline: 0,
    textTransform: 'none',
  },

  UserMenuContent__Check: {
    color: `${get(color, 'link')} !important`,
    margin: '0.5em',
    fontSize: get(fontSize, 'bodyText'),
    marginLeft: '10px',
    paddingTop: '2px',
  },

  UserMenuContent__ErrorMessage: {
    padding: '10px 0',
    width: '100%',
    backgroundColor: get(color, 'error'),
    color: get(color, 'white'),
    fontFamily: get(typography, 'bodyText'),
    margin: '8px 0',
    textAlign: 'center',
  },

  UserMenuContent_OrgsWrapper: {
    overflowY: 'auto',
    maxHeight: 200,
    margin: 8,
  },
  SubHeader: {
    marginBottom: 10,
    color: get(color, 'gray'),
    fontSize: get(fontSize, 'smallText'),
  },

  SubHeaderUppercase: {
    textTransform: 'uppercase',
  },
  sideMenuPadding: {
    paddingTop: '0px',
    paddingBottom: ' 0px',
  },
  [`@media (min-width: ${get(misc, 'breakpointMedium')}px)`]: {
    HeaderMenuButton: {
      display: 'none',
    },

    SubHeader: {
      marginBottom: 10,
      color: get(color, 'gray'),
      fontSize: get(fontSize, 'smallText'),
    },

    SubHeaderUppercase: {
      textTransform: 'uppercase',
    },
  },
});
