import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import SuccessRateIcon from '../../icons/iconSuccessRate.svg';
import AppCountIcon from '../../icons/iconApps.svg';
import LatencyIcon from '../../icons/iconLatency.svg';
import moment from 'moment';

import { getStatusColor, getStatusLabel } from '../utils';
import { useIntl } from 'react-intl';
import { getI18n } from '../../../../utils/intl';
import ReactTooltip from 'rc-tooltip';
import {
  TagsGroup,
} from '../../../../components';

import {
  withStyles,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const GridItem = (item) => {
  const { name, description, uuid, classes, tags, onTagClick } = item;
  const intl = getI18n(useIntl());

  const renderSubTitle = ({ portalStatus = '', version = '', modifyTs = '' }) => {
    // red for DEPRECATED and PENDING_BUNDLE (api doesn't return api with this state)
    const statusColor = getStatusColor(portalStatus);
    const status = getStatusLabel(portalStatus);
    const statusStyle = {
      color: statusColor,
    };
    const statusIconStyle = {
      fill: statusColor,
    };
    // if we get invalid time or future time, don't show it.
    const timeStampValid = !!(modifyTs && moment(modifyTs).isValid()
      && (moment(modifyTs).valueOf() < moment().valueOf()));
    const modifyStatus = intl.getI18nMessage('label.api.grid.status.updated', { value: moment(modifyTs).fromNow() } );
    return (
      <ul className={classes.APIStatusBar}>
        <li>
          <FiberManualRecordIcon size="0.82em" style={statusIconStyle} className={classes.statusIcon} />
          <span style={statusStyle} className={classes.APIInfoText}>{status}</span>
        </li>
        <li><div className={classes.verticalDivider} /></li>
        <li><span className={classes.APIInfoText}>Version {version}</span></li>
        {timeStampValid && <li><div className={classes.verticalDivider} /></li>}
        {timeStampValid && <li><span className={classes.APIInfoText}>
          {modifyStatus}</span></li>}
      </ul>
    );
  }

  const renderFooter = ({ applicationUsage, metricsData,
    isAnalyticsEnabled, isErrorMetricsDisabled }) => {
    const appCount = Number.isInteger(applicationUsage) ? applicationUsage : 0;
    const appString = intl.getI18nMessage('label.api.grid.status.metrics.appusage', { appCount });
    
    const latencyDataError = metricsData && metricsData.latency && metricsData.latency.error;
    const latency = (metricsData && metricsData.latency
    && !isNaN(metricsData.latency) && Math.round(metricsData.latency)) || '-';
    const latencyString = intl.getI18nMessage('label.api.grid.status.metrics.latency', { value: latency })
    const latencyTooltip = intl.getI18nMessage('label.api.grid.status.metrics.tooltip.latency');
    const appUsateTooltip = intl.getI18nMessage('label.api.grid.status.metrics.tooltip.appusage', { appCount });
    const transitionObject = {
      enter: classes.TooltipEnter,
      enterActive: classes.TooltipEnterActive,
      leave: classes.TooltipLeave,
      leaveActive: classes.TooltipLeaveActive,
    };
    return (
      <ul className={classes.metricsList}>
        <li>
          <ReactTooltip
            className={classes.Tooltip}
            destroyTooltipOnHide={false}
            overlay={appUsateTooltip}
            placement="bottom"
            transitionName={transitionObject}
            overlayClassName={classes.Tooltip__wrapper}
          >
            <div>
              <img
                src={AppCountIcon}
                className={classes.Icon}
                alt="App Count"
                />
              <span className={classes.iconText}>{appString}</span>
            </div>
          </ReactTooltip>
        </li>
        {isAnalyticsEnabled && !latencyDataError && <li>
          <ReactTooltip
            className={classes.Tooltip}
            destroyTooltipOnHide={false}
            overlay={latencyTooltip}
            placement="bottom"
            transitionName={transitionObject}
            overlayClassName={classes.Tooltip__wrapper}
          >
            <div>
              <img
                src={LatencyIcon}
                className={classes.Icon}
                alt="Latency"
                />
              <span className={classes.iconText}>{latencyString}</span>
            </div>
          </ReactTooltip>
        </li>}
        {isAnalyticsEnabled && !isErrorMetricsDisabled && <li>
          <img
            src={SuccessRateIcon}
            className={classes.Icon}
            alt="Error Rate"
            />
          <span className={classes.iconText}>100 %</span>
        </li>}
      </ul>
    );
  };
  return (<Card key={uuid} className={classes.customCard}>
      <CardActionArea href={`/publish/apis/details/${uuid}`}>
        <CardContent className={classes.customCardContent}>
            <div className={classes.cardHeader}>
                <Typography
                    variant="h1"
                    className={classes.cardTitle}
                >
                    {name}
                </Typography>
                {renderSubTitle(item)}
            </div>
            <Divider />
            <div>
                <p className={classes.cardDescription}>{description || ''}</p>
            </div>
            <div className={classes.tagsGroup}>
              <TagsGroup
                tags={tags}
                onTagClick={(tag) => onTagClick(tag, tags)}
              />
            </div>
            <div className={classes.cardFooter}>
                {renderFooter(item)}
            </div>
        </CardContent>
      </CardActionArea>
    </Card>);
}

GridItem.propTypes = {
  entity: PropTypes.object,
  rank: PropTypes.number,
  chartEntity: PropTypes.string,
  clickHandler: PropTypes.func,
  hoverHandler: PropTypes.func,
  isTop3EntitiesSelected: PropTypes.bool,
  lineColor: PropTypes.string,
  isApiOwnerOrOrgPub: PropTypes.bool,
};

export default withStyles(styles)(GridItem);
