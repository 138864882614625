// @flow
import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Link from './Link';
import Menu from './Menu';
import theme from './index.theme';

const Nav = ({
  classes = {},
  navItems = [],
  renderCollapsed = false,
}) => {
  const renderExpandedNav = () => navItems.map(item =>
    ((item.navigations && (item.navigations.length > 0)) ?
      <Menu
        {...item}
        key={item.titleKey}
      />
      :
      <Link
        link={item}
        key={item.titleKey || item.title}
      />),
    );
  return (<div data-apim-test={'header-nav'} className={clsx(classes.HeaderNav, classes.HeaderDesktopNav)}>
    <div data-apim-test={'header-nav-links'} className={classes.HeaderNavLinks}>
      {renderCollapsed ?
        <Menu navigations={navItems} isRootMenu titleKey={''} /> :
        renderExpandedNav()
      }
    </div>
  </div>);
};

Nav.propTypes = {
  classes: PropTypes.shape(),
  navItems: PropTypes.arrayOf(PropTypes.shape()),
  renderCollapsed: PropTypes.bool,
};

export default withStyles(theme)(Nav);
