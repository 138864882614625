import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { AlertDialog, LoadingDialog } from '../../../components';
import styles from './styles';
import { getI18n } from '../../../utils/intl';

import { processErrors } from '../../../utils';
import { FAIL, SUCCESS } from '../../../constants';

import {
  Button,
  makeStyles,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';

export const ActionsRaw = ({
  bundles,
  fetchCustomPagesBundles,
  deleteCustomPagesBundle,
  deleteBundleStatus,
  uploadBundleStatus,
  setNotificationStatus,
  setNotificationMessage,
  errors,
}) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const intl = getI18n(useIntl());

  // Delete dialog
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const [showDeleteBundlesDialog, setShowDeleteBundlesDialog] = useState(false);
  const [bundlesToDelete, setBundlesToDelete] = useState([]);

  // Handle API Delete Status
  const getSuccessNotificationMessage = (
    uploadBundleStatus,
    deleteBundleStatus,
  ) => {
    if (uploadBundleStatus === SUCCESS)
      return intl.getI18nMessage('label.custom.pages.upload.success');
    if (deleteBundleStatus === SUCCESS)
      return intl.getI18nMessage('label.custom.pages.delete.success');
  };
  useEffect(() => {
    if (uploadBundleStatus === SUCCESS || deleteBundleStatus === SUCCESS) {
      setNotificationStatus('success');
      setNotificationMessage(
        getSuccessNotificationMessage(uploadBundleStatus, deleteBundleStatus),
      );
      fetchCustomPagesBundles();
    } else if (uploadBundleStatus === FAIL || deleteBundleStatus === FAIL) {
      const defaultErrorMessage = () => {
        if (uploadBundleStatus === FAIL)
          return intl.getI18nMessage('label.custom.pages.upload.fail');
        if (deleteBundleStatus === FAIL)
          return intl.getI18nMessage('label.custom.pages.delete.fail');
      };
      let errorMessage = processErrors(errors, defaultErrorMessage);
      if (uploadBundleStatus === FAIL) {
        errorMessage =
          errors && errors.devMessage ? errors.devMessage : errorMessage;
      }
      setNotificationStatus('error');
      setNotificationMessage(errorMessage);
      fetchCustomPagesBundles();
    }
    setTimeout(() => setNotificationMessage(''), 3000);
  }, [uploadBundleStatus, deleteBundleStatus]);

  return (
    <div
      id="custom-pages-details-actions-container"
      data-apim-test="custom-pages-details-actions-container"
      className={classes.actionsContainer}
    >
      <AlertDialog
        isOpen={showDeleteBundlesDialog}
        title={intl.getI18nMessage('label.custom.pages.delete.popup.title')}
        description={intl.getI18nMessage(
          'label.custom.pages.delete.popup.description',
        )}
        submitText={intl.getI18nMessage('label.delete.button')}
        submitButtonClass={classes.deleteButtonPopup}
        cancelText={intl.getI18nMessage('label.cancel.button')}
        onClose={() => setShowDeleteBundlesDialog(false)}
        onSubmit={() => {
          setShowDeleteBundlesDialog(false);
          setShowLoadingDialog(true);
          bundlesToDelete.forEach(async (uuid) => {
            await deleteCustomPagesBundle(uuid);
            setBundlesToDelete((bundles) =>
              bundles.filter((item) => item !== uuid),
            );
          });
          setShowLoadingDialog(false);
        }}
        onCancel={() => {
          setBundlesToDelete([]);
          setShowDeleteBundlesDialog(false);
        }}
        dialogId="custom-pages-delete-dialog"
        component={
          <FormGroup>
            {bundles &&
              bundles.length > 0 &&
              bundles.map((bundle) => (
                <FormControlLabel
                  key={bundle.uuid}
                  control={
                    <Checkbox
                      value={bundle.uuid}
                      disabled={bundle.status === 'published'}
                      onChange={(e) => {
                        const uuid = e.target.value;
                        e.target.checked
                          ? setBundlesToDelete((bundles) => [...bundles, uuid])
                          : setBundlesToDelete((bundles) =>
                              bundles.filter((item) => item !== uuid),
                            );
                      }}
                    />
                  }
                  label={bundle.name}
                />
              ))}
          </FormGroup>
        }
      />
      <LoadingDialog
        isOpen={showLoadingDialog}
        title={intl.getI18nMessage('label.custom.pages.deleting')}
        dialogId="deleting-custom-pages-dialog"
      />
      <Button
        variant="contained"
        style={{
          backgroundColor: 'white',
          border: '2px solid red',
          color: 'white',
          outline: 'none',
        }}
        className={classes.deleteButton}
        onClick={() => setShowDeleteBundlesDialog(true)}
        id={`custom-pages-delete-button`}
        data-apim-test={`custom-pages-delete-button`}
        data-layer7-test={`custom-pages-delete-button`}
        disabled={bundles.length === 0}
      >
        {intl.getI18nMessage('label.custom.pages.delete.button.text')}
      </Button>
    </div>
  );
};

ActionsRaw.propTypes = {
  bundles: PropTypes.arrayOf(PropTypes.object),
  fetchCustomPagesBundles: PropTypes.func,
  deleteCustomPagesBundle: PropTypes.func,
  deleteBundleStatus: PropTypes.string,
  uploadBundleStatus: PropTypes.string,
  setNotificationStatus: PropTypes.func,
  setNotificationMessage: PropTypes.func,
  errors: PropTypes.array,
};

ActionsRaw.displayName = 'Actions';

export default ActionsRaw;
