const generateFontSource = (url, format) => (
  !url ? '' : `url("${url}") format("${format}"),`
);

const generateFontSources = (customTypography) =>
  (
    generateFontSource(customTypography.woffUri, 'woff') +
    generateFontSource(customTypography.woff2Uri, 'woff2') +
    generateFontSource(customTypography.ttfUri, 'truetype') +
    generateFontSource(customTypography.otfUri, 'opentype')
  ).slice(0, -1); // Remove trailing comma

const getCustomFontFaces = (customTypographies = []) =>
  customTypographies.map(
    customTypography =>
      ({
        fontFamily: customTypography.name,
        src: generateFontSources(customTypography),
        fontWeight: 'normal',
        fontStyle: 'normal',
      }),
  );

export default getCustomFontFaces;
