import React from 'react';
import { Route, Switch } from 'react-router';
import {
  ApiEditPage,
  ApiListPage,
  ApiDetailsPage,
  ApplicationEditPage,
  ApplicationDetailsPage,
  ApiProxyEditPage,
  GatewayBundleListPage,
  OrganizationListPage,
  OrganizationEditPage,
  OrganizationDetailsPage,
  GatewayBundleDetailsPage,
  GatewayBundleEditPage,
  ProxyDetailsPage,
  UserListPage,
  EulaListPage,
  EulaEditPage,
  EulaDetailsPage,
  CustomPages,
  RateQuotaListPage,
  RateQuotaEditPage,
  RateQuotaDetailsPage,
  PageNotFoundPage,
  UserProfile,
  RequestSettings,
} from '../pages';
import Dashboard from '../pages/Dashboard';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/admin/" component={Dashboard} />
      <Route exact path="/admin/app/dashboard/" component={Dashboard} />
      <Route exact path="/admin/app/guest/dashboard/" component={Dashboard} />
      <Route exact path="/admin/console/users" component={UserListPage} />
      <Route exact path="/admin/console/organizations" component={OrganizationListPage} />
      <Route exact path="/admin/console/organizations/add" component={OrganizationEditPage} />
      <Route exact path="/admin/console/organizations/details/:orgUuid" component={OrganizationDetailsPage} />
      <Route exact path="/admin/console/request-settings" component={RequestSettings} />
      <Route exact path="/admin/user-profile" component={UserProfile} />
      <Route exact path="/publish/apis/" component={ApiListPage} />
      <Route exact path="/publish/apis/add/" component={ApiEditPage} />
      <Route exact path="/publish/apis/edit/:apiUuid/" component={ApiEditPage} />
      <Route exact path="/publish/apis/edit/:apiUuid/:tabName" component={ApiEditPage} />
      <Route exact path="/publish/apis/details/:apiUuid" component={ApiDetailsPage} />
      <Route exact path="/publish/apis/details/:apiUuid/:tabName" component={ApiDetailsPage} />
      <Route exact path="/publish/apis/permissions/:apiUuid" component={ApiEditPage} />
      <Route exact path="/publish/apis/permissions/:apiUuid/:tabName" component={ApiEditPage} />
      <Route exact path="/publish/applications/add/" component={ApplicationEditPage} />
      <Route exact path="/publish/applications/edit/:applicationUuid/" component={ApplicationEditPage} />
      <Route exact path="/publish/applications/details/:applicationUuid/" component={ApplicationDetailsPage} />
      <Route exact path="/dev/applications/details/:applicationUuid/" component={ApplicationDetailsPage} />
      <Route exact path="/publish/applications/edit/:applicationUuid/:tabName" component={ApplicationEditPage} />
      <Route exact path="/publish/api-proxy/add/" component={ApiProxyEditPage} />
      <Route exact path="/publish/api-proxy/edit/:apiProxyUuid/" component={ApiProxyEditPage} />
      <Route exact path="/publish/gateway-bundles" component={GatewayBundleListPage} />
      <Route exact path="/publish/gateway-bundles/add" component={GatewayBundleEditPage} />
      <Route exact path="/publish/gateway-bundles/details/:bundleUuid" component={GatewayBundleDetailsPage} />
      <Route exact path="/publish/gateway-bundles/details/:bundleUuid/:tabName" component={GatewayBundleDetailsPage} />
      <Route exact path="/publish/api-proxy/details/:apiProxyUuid/" component={ProxyDetailsPage} />
      <Route exact path="/publish/api-proxy/details/:apiProxyUuid/:tabName" component={ProxyDetailsPage} />
      <Route exact path="/publish/eulas/" component={EulaListPage} />
      <Route exact path="/publish/eulas/details/:eulaUuid" component={EulaDetailsPage} />
      <Route exact path="/publish/eulas/add/" component={EulaEditPage} />
      <Route exact path="/publish/eulas/edit/:eulaUuid/" component={EulaEditPage} />
      <Route exact path="/admin/rate-quotas" component={RateQuotaListPage} />
      <Route exact path="/admin/rate-quotas/details/:rqUuid" component={RateQuotaDetailsPage} />
      <Route exact path="/admin/rate-quotas/add/" component={RateQuotaEditPage} />
      <Route exact path="/admin/rate-quotas/edit/:rqUuid/" component={RateQuotaEditPage} />
      <Route exact path="/admin/settings/custom-pages/" component={CustomPages} />
      <Route exact path="/404" component={PageNotFoundPage} />
      <Route path="*" component={PageNotFoundPage} />
    </Switch>
  );
}
