import get from 'lodash/get';

const styles = theme => ({
  errorContainer: {
    borderRadius: 8,
    border: '1px solid #DB4332',
    maxWidth: '90%',
    padding: 24,
    marginTop: 10,
    marginBottom: 10,
  },
  errorIcon: {
    color: get(theme, 'color.error'),
    display: 'inline-block',
    height: 36,
  },
  errorPadding: {
    marginBottom: 20,
    maxWidth: '95%',
  },
  errorSubtitle: {
    color: get(theme, 'color.bodyText'),
    fontFamily: 'clear-sans-regular',
    display: 'list-item',
    marginLeft: 50,
  },
  errorSubtitleText: {
    color: get(theme, 'color.bodyText'),
    fontFamily: 'clear-sans-regular',
  },
  errorTitle: {
    color: get(theme, 'color.bodyText'),
    display: 'inline-block',
    fontFamily: 'ca-sans-light',
    fontSize: 24,
    marginLeft: 14,
    marginTop: 0,
  },
});

export default styles;
