import React, { useState, useMemo } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import slugify from 'slugify';

import { MarkdownInput, FormTextField } from '../../../../components';
import {
    hasError,
    hasRequiredError,
    checkSpecialCharacters,
    checkUnicity,
} from '../../../../utils';
import { func , arrayOf, object, string } from 'prop-types';

export const DocumentCreateForm = ({
    document = {},
    allDocuments = [],
    onSave = () => {},
    onCancel = () => {},
    id,
    ...rest
}) => {
    const classes = useStyles(rest);
    const [title, setTitle] = useState(document.title);
    const [uri, setUri] = useState(document.navtitle);
    const [content, setContent] = useState(document.markdown);
    const [uriError, setUriError] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [contentError, setContentError] = useState(false);
    const [uriModified, setUriModified] = useState(false);

    const navtitles = useMemo(
        () => Object.values(allDocuments).map(item => item.navtitle),
        [allDocuments],
    );
    const handleChange = (value, name) => {
        if( name === 'title') {
            const titleUiError = hasError(value, true);
            setTitle(value);
            setTitleError(titleUiError);
            !uriModified ? setUri(slugify(value)) : '';
        }
        if (name === 'uri') {
            setUriModified(true);
            let uriUiError = hasError(value, true);
            if(!uriUiError) {
                uriUiError = checkSpecialCharacters(value);
            }
            setUri(value);
            setUriError(uriUiError);
        }
        if( name === 'content') {
            const contentUiError = hasRequiredError(value, true);
            setContent(value);
            setContentError(contentUiError);
        }
    };

    const handleBlur = (value, name) => {
        if(name === 'uri') {
           let uriUiError = checkSpecialCharacters(value);
           if(!uriUiError) {
            uriUiError = checkUnicity(navtitles, value);
           }
           setUriError(uriUiError);
        }
    };

    const onPublish = () => {
        const titleUiError = hasError(title, true);
        let uriUiError = hasError(uri, true);
        const contentUiError = hasRequiredError(content, true);
        if(!uriUiError) {
            uriUiError = checkSpecialCharacters(uri);
        }
        setUriError(uriUiError);
        setTitleError(titleUiError);
        setContentError(contentUiError);
        if(!titleUiError && !contentUiError && !uriUiError) {
            const newDoc = {
                ...document,
                title: title,
                navtitle: uri,
                markdown: content,
            };
            onSave(newDoc);
        }
    };

    return (
        <div className={classes.formContainer} id={id} data-apim-test={id}>
            <div className={classes.fieldContainer}>
                <FormTextField
                    name={'Title'}
                    id={'api-details-document-edit-title'}
                    data-apim-test={'api-details-document-edit-title'}
                    className={classes.title}
                    variant="standard"
                    value={title}
                    handleChange={(value) => handleChange(value, 'title')}
                    handleBlur={(value) => handleBlur(value, 'title')}
                    error={titleError}
                    helperText={'Title is required'}
                />
            </div>
            <div className={classes.uriContainer}>
                <FormTextField
                    name={'URI'}
                    id={'api-details-document-edit-navtitle'}
                    data-apim-test={'api-details-document-edit-navtitle'}
                    className={classes.navtitle}
                    variant="standard"
                    value={uri}
                    handleChange={(value) => handleChange(value, 'uri')}
                    handleBlur={(value) => handleBlur(value, 'uri')}
                    error={uriError}
                    helperText={'URI must be unique. Supports letters from a to z, numbers and the separators - and _.'}
                />
            </div>
            <MarkdownInput
                source="markdown"
                formClassName={classes.markdown}
                isRequired
                fullWidth
                helperText={'Content is required'}
                name={'Content'}
                value={content}
                error={contentError}
                onChange={(value) => handleChange(value, 'content')}
            />
            <div
                className={classes.buttonContainer}
                id={'api-details-document-add-button-container'}
                data-apim-test={'api-details-document-add-button-container'}
            >
                <Button className={classes.submitButton} color="secondary" variant="contained" onClick={onPublish}>
                    {'PUBLISH'}
                </Button>
                <Button className={classes.cancelButton} color="primary" variant="outlined" onClick={onCancel}>
                    {'CANCEL'}
                </Button>
            </div>
        </div>
    );
};

const useStyles = makeStyles(
    theme => ({
        formContainer: {
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
        },
        fieldContainer: {
            display: 'inline-block',
            width: '42%',
            marginRight: theme.spacing(2),
        },
        uriContainer: {
            display: 'inline-block',
            width: '56%',
        },
        labelContainer: {
            width: '100%',
        },
        buttonContainer: {
            width: '90%',
            float: 'right',
            marginTop: theme.spacing(2),
        },
        cancelButton: {
            float: 'right',
            marginRight: theme.spacing(2),
        },
        submitButton: {
            float: 'right',
            marginRight: theme.spacing(2),
        },
    }),
);

DocumentCreateForm.propTypes = {
    document: object,
    allDocuments: arrayOf(object),
    onSave: func,
    onCancel: func,
    id: string,
};