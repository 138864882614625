import React from 'react';
import { get } from 'lodash';
import { VictoryTooltip } from 'victory';

function wordWrap(str) {
  const contentLength = str.length;
  const charLimit = 40;
  if (contentLength > charLimit && (contentLength - charLimit) > 5) {
    const numOfSubstr = Math.ceil(contentLength / charLimit);
    const newContent = Array(numOfSubstr).fill(0).map((ele, i) => {
      let index = i;
      index *= charLimit;
      if (str.charAt(index + charLimit)) {
        return str.substring(index, index + charLimit);
      }
      return str.substring(index);
    });
    return newContent;
  }
  return [str];
}

const ChartTooltipRaw = (customLabelComponent, labelKey, active, theme) => {
  const style = {
    fontSize: 10, // please test tooltips, as changing font sizes affect layout
    fontFamily: get(theme, 'typography.bodyText'),
    textAnchor: 'start',
    padding: 10,
  };
  return (
    <VictoryTooltip
      flyoutStyle={{
        fill: '#313233',
        stroke: 'none',
      }}
      labelComponent={customLabelComponent}
      text={({ datum }) => {
        let labelText = (datum && (labelKey ? datum[labelKey] : datum.label)) || '';
        if (!active) {
          labelText = '';
        }
        labelText = (labelText && labelText.split('\n')) || [''];
        const newContent = wordWrap(labelText[0]);
        labelText.shift();
        return [...newContent, ...labelText];
      }
      }
      style={style}
      pointerLength={5}
      pointerWidth={5}
      cornerRadius={2}
    />
  );
};

export default ChartTooltipRaw;
