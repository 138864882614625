import get from 'lodash/get';
import { convertHexToRGB } from '../../../../utils';

const styles = ({ color, fontSize, margin, typography, padding, lineHeight }) => ({
  topPanelContainer: {
    padding: '0 36px',
    backgroundColor: `${convertHexToRGB(get(color, 'borderColor'), 0.1)}`,
  },
  bottomPanelContainer: {
    marginTop: get(margin, 'm28'),
    padding: '0 36px',
  },
  detailsTitle: {
    backgroundColor: 'transparent !important',
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'title'),
    fontWeight: 'bold !important',
    outline: 'transparent !important',
    '-webkit-font-smoothing': 'antialiased',
    lineHeight: `${get(lineHeight, 'lh28')} !important`,
    wordWrap: 'break-word',
  },
  detailsSubTitle: {
    backgroundColor: 'transparent !important',
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    outline: 'transparent !important',
    '-webkit-font-smoothing': 'antialiased',
    color: `${get(color, 'bodyText')} !important`,
    lineHeight: get(lineHeight, 'lh21'),
    wordWrap: 'break-word',
    paddingRight: get(padding, 'p20'),
  },
  fileName: {
    fontWeight: 'bold !important',
    margin: '20px 0px',
  },
  linkContainer: {
    '& a': {
      cursor: 'pointer',
      border: 'none !important',
      margin: `${get(margin, 'm0')} !important`,
      padding: `${get(padding, 'p0')} !important`,
      height: '0px !important',
      fontWeight: '300 !important',
      color: `${get(color, 'link')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: `${get(color, 'linkHover')} !important`,
      textDecoration: 'none',
    },
    '& a:focus': {
      boxShadow: 'none !important',
      outline: '0px !important',
      textDecoration: 'none',
    },
  },
  divider: {
    backgroundColor: get(color, 'borderColor'),
  },
});

export default styles;
