import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import {
  fetchApplication,
  fetchSingleApplication,
  fetchApplicationKeys,
  fetchSelectedApiGroups,
} from '../../../actions/application';
import {
  getApplicationDetails,
  getSingleApplication,
  getKeysResults,
  getKeysTotalPages,
  getKeysTotalElements,
} from '../../../reducers/application';
import {
  getUserDetails,
  getFeatureFlags,
} from '../../../reducers/portalConfig';
import {
  hasPublisherRole,
  isEditApplicationDisabled,
  isEditApplicationKeysDisabled,
 } from '../../../utils';

import {
  AlertMessages,
  TabsContainer,
  TabPanel,
  Footer,
} from '../../../components';

import Header from './Header';
import Actions from './Actions';
import Configuration from './Configuration';
import Deployments from './Deployments';
import Apis from './Apis';

import styles from './styles';

export const getApplicationUuid = (props) => get(props, 'match.path')
  && get(props, 'match.path').includes('/details/')
  && get(props, 'match.params.applicationUuid');

const getIsDevUrl = (props) => get(props, 'match.path')
  && get(props, 'match.path').includes('/dev/');

const getTabItems = (user) => [
  {
    id: 0,
    tabId: 'app-config-tab',
    label: 'Configuration',
    visible: true,
  },
  {
    id: 1,
    tabId: 'app-apis-tab',
    label: 'APIs',
    visible: true,
  },
  {
    id: 2,
    tabId: 'app-deployments-tab',
    label: 'Deployments',
    visible: hasPublisherRole(user),
  },
];

export const ApplicationDetails = (props) => {
  const {
    classes,
    user,
    history,
    featureFlags,
    applicationDetails,
    singleApplication,
    appKeys = [],
    appKeysTotalPages,
    appKeysTotalElements,
    hideDeployments,
  } = props;
  const isEditAppDisabled = isEditApplicationDisabled(user, applicationDetails);
  const isEditAppKeysDisabled = isEditApplicationKeysDisabled(user, applicationDetails);
  const featureFlagApiPlan = JSON.parse(get(featureFlags, 'apiPlans.Value'));
  const featureFlagMultiApiKeys = JSON.parse(get(featureFlags, 'useMultiApiKeys.Value'));
  const [notificationMessage, setNotificationMessage] = useState(localStorage.getItem('notificationMessage'));
  const [notificationStatus, setNotificationStatus] = useState(localStorage.getItem('notificationStatus'));

  const isMultiKeySupport = featureFlagMultiApiKeys && appKeys.length > 0;

  const notifyMessages = (message, status) => {
    setNotificationStatus(status);
    localStorage.setItem('notificationStatus', status);
    setNotificationMessage(message);
    localStorage.setItem('notificationMessage', message);
  };

  const appUuid = getApplicationUuid(props);
  useEffect(() => {
    if (appUuid) {
      props.fetchApplication(appUuid);
      props.fetchSingleApplication(appUuid);
      props.fetchApplicationKeys(appUuid);
      props.fetchSelectedApiGroups(appUuid);
    }
    setTimeout(() => {
      notifyMessages('', '');
    }, 2500);
  }, []);

  const [currentTab, setCurrentTab] = useState(0);
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const tabItems = getTabItems(user);
  return (
    <div
      className={classes.content}
      id="application-details-page" data-layer7-test="application-details-page"
    >
      {notificationMessage &&
        <AlertMessages
          id="app-details-notification"
          variant={notificationStatus}
          message={notificationMessage}
          containerClass={classes.notificationWidthClass}
          onClose={() => { notifyMessages('', ''); }}
        />
      }
      <Header
        appUuid={appUuid}
        applicationDetails={applicationDetails}
        isEditAppDisabled={isEditAppDisabled}
        history={history}
      />
      <Actions
        applicationDetails={applicationDetails}
        isEditAppDisabled={isEditAppDisabled}
        isEditAppKeysDisabled={isEditAppKeysDisabled}
        setNotificationMessage={setNotificationMessage}
        setNotificationStatus={setNotificationStatus}
        isMultiKeySupport={isMultiKeySupport}
        referrerUrl={document.referrer}
        history={history}
        user={user}
      />
      <Grid container className={classes.gridContainer}>
        <TabsContainer
          id="app-details-tab-container"
          tabValue={currentTab}
          orientation="horizontal"
          tabItems={tabItems.filter(({ visible }) => visible)}
          handleTabChange={handleChange}
          tabActiveClass={classes.tabActive}
          tabIndicatorClass={classes.tabIndicator}
        />
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.bodyContent}>
        <TabPanel visible={currentTab === 0} id="app-config-tab-panel">
          <Configuration
            applicationUuid={appUuid}
            applicationDetails={applicationDetails}
            fetchApplicationKeys={props.fetchApplicationKeys}
            appKeys={appKeys}
            appKeysTotalPages={appKeysTotalPages}
            appKeysTotalElements={appKeysTotalElements}
            isMultiKeySupport={isMultiKeySupport}
          />
        </TabPanel>
        <TabPanel visible={currentTab === 1} id="app-apis-tab-panel">
          <Apis
            applicationDetails={applicationDetails}
            singleApplication={singleApplication}
            isEditAppDisabled={isEditAppDisabled}
            appUuid={appUuid}
            user={user}
            isDevUrl={getIsDevUrl(props)}
            history={history}
            featureFlagApiPlan={featureFlagApiPlan}
          />
        </TabPanel>
        <TabPanel visible={currentTab === 2} id="app-deployments-tab-panel">
          {!hideDeployments && <Deployments
            applicationUuid={appUuid}
            applicationDetails={applicationDetails}
            appKeys={appKeys}
            appKeysTotalPages={appKeysTotalPages}
            appKeysTotalElements={appKeysTotalElements}
            isMultiKeySupport={isMultiKeySupport}
            history={history}
            fetchApplicationKeys={props.fetchApplicationKeys}
          />}
        </TabPanel>
      </Grid>
      <Footer />
    </div>
  );
};
const mapStateToProps = state => ({
  featureFlags: getFeatureFlags(state),
  applicationDetails: getApplicationDetails(state),
  singleApplication: getSingleApplication(state),
  appKeys: getKeysResults(state),
  appKeysTotalPages: getKeysTotalPages(state),
  appKeysTotalElements: getKeysTotalElements(state),
  user: getUserDetails(state),
});

const mapDispatchToProps = {
  fetchApplication,
  fetchSingleApplication,
  fetchApplicationKeys,
  fetchSelectedApiGroups,
};

ApplicationDetails.propTypes = {
  featureFlags: PropTypes.object,
  applicationDetails: PropTypes.object,
  singleApplication: PropTypes.object,
  fetchApplicationKeys: PropTypes.func,
  appKeys: PropTypes.arrayOf(PropTypes.object),
  appKeysTotalPages: PropTypes.number,
  appKeysTotalElements: PropTypes.number,
  user: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object,
  hideDeployments: PropTypes.func,
  fetchApplication: PropTypes.func,
  fetchSingleApplication: PropTypes.func,
  fetchSelectedApiGroups: PropTypes.func,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ApplicationDetails);
