export default ({ misc }) => ({
  HeaderNav: {
    display: 'inline-block',
    height: '100%',
    '.skipPrint &': {
      '@media print': {
        display: 'none',
      },
    },
  },
  HeaderDesktopNav: {
    flex: 1,
  },
  HeaderNavLinks: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    [`@media (max-width: ${misc && misc.breakpointLarge}px)`]: {
      justifyContent: 'flex-end',
    },
  },
});
