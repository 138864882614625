// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  updateUserContexts,
  fetchRequests,
} from '../../actions/portalConfig';
import {
  getIsPortalAdmin,
  getIsOrgPublisher,
  getIsAnonymousRole,
  getThemes,
  getThemeBrandedLabels,
  getPublisherNavigation,
  getDeveloperNavigations,
  getServices,
  getSettingsNavigation,
  getUserAccessibleOrgs,
  getUserActiveOrgUuid,
  getUserDetails,
  getRequests,
} from '../../reducers/portalConfig';
import Branding from './Branding';
import HamburgerMenu from './HamburgerMenu';
import Nav from './Nav';
import Requests from './Requests';
import Services from './Services';
import Settings from './Settings';
import User from './User';
import styles from './index.theme';

export const getNavLabels = (navItems, themeBrandedLabels) =>
  navItems.map(navItem => {
    const { link } = navItem;
    if (themeBrandedLabels && link.titleKey && themeBrandedLabels[link.titleKey]) {
      return {
        ...navItem,
        link: {
          ...link,
          title: themeBrandedLabels[link.titleKey],
        },
      };
    }
    return navItem;
  });

function componentDidMount() {
  const { isPortalAdmin, isOrgPublisher } = this.props;

  if (isPortalAdmin || isOrgPublisher) { // Publisher Role
    this.props.fetchRequests();
  }
}

const Header = (props) => {
  const {
    logo,
    siteTitle,
    homeHref,
    classes,
    themeBrandedLabels,
    requestItems,
    serviceItems,
    settingItems,
    userAccessibleOrgs,
    userActiveOrgUuid,
    userDetails,
  } = props;
  const navItems = getNavLabels(props.navItems, themeBrandedLabels);

  return (
    <div className={classes.Header}>
      <div className={classes.HeaderInner}>
        <HamburgerMenu
          navItems={navItems}
          serviceItems={serviceItems}
          userAccessibleOrgs={userAccessibleOrgs}
          userActiveOrgUuid={userActiveOrgUuid}
          userDetails={userDetails}
          updateUserContexts={props.updateUserContexts}
        />
        <Branding
          logo={logo}
          siteTitle={siteTitle}
          homeHref={homeHref}
        />
        <Nav navItems={navItems} />
        <Requests requestItems={requestItems} />
        <Services serviceItems={serviceItems} />
        <Settings settingItems={settingItems} />
        <User
          userAccessibleOrgs={userAccessibleOrgs}
          userActiveOrgUuid={userActiveOrgUuid}
          userDetails={userDetails}
          updateUserContexts={props.updateUserContexts}
        />
      </div>
    </div>
  );
};

const getHomePageRef = (state) => {
  const hostName = window.location.hostname;
  if (getIsPortalAdmin(state)) {
    return `//${hostName}/admin`;
  } else if (getIsAnonymousRole(state)) {
    return `//${hostName}/admin/app/guest/dashboard`;
  }
  return `//${hostName}/admin/app/dashboard`;
};

const getNavItems = (state) => {
  if (window.location.href.includes('/dev')) {
    return getDeveloperNavigations(state).service_links;
  } else if (window.location.href.includes('/admin')) {
    return [];
  }
  return getPublisherNavigation(state).service_links;
};

export const mapStateToProps = (state) => {
  const { logo, siteTitle } = getThemes(state);
  return {
    logo,
    siteTitle,
    isPortalAdmin: getIsPortalAdmin(state),
    isOrgPublisher: getIsOrgPublisher(state),
    homeHref: getHomePageRef(state),
    navItems: getNavItems(state),
    themeBrandedLabels: getThemeBrandedLabels(state),
    serviceItems: getServices(state).services,
    settingItems: getSettingsNavigation(state).service_links,
    userAccessibleOrgs: getUserAccessibleOrgs(state),
    userActiveOrgUuid: getUserActiveOrgUuid(state),
    userDetails: getUserDetails(state),
    requestItems: getRequests(state),
  };
};

export const mapDispatchToProps = {
  updateUserContexts,
  fetchRequests,
};

Header.propTypes = {
  logo: PropTypes.string,
  siteTitle: PropTypes.string,
  homeHref: PropTypes.string,
  classes: PropTypes.object,
  navItems: PropTypes.arrayOf(PropTypes.object),
  themeBrandedLabels: PropTypes.object,
  serviceItems: PropTypes.arrayOf(PropTypes.object),
  settingItems: PropTypes.arrayOf(PropTypes.object),
  userAccessibleOrgs: PropTypes.arrayOf(PropTypes.object),
  userActiveOrgUuid: PropTypes.string,
  userDetails: PropTypes.object,
  requestItems: PropTypes.arrayOf(PropTypes.object),
  updateUserContexts: PropTypes.func,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({ componentDidMount }),
)(Header);
