import React, { useState } from 'react';
import { connect } from 'react-redux';
import { array, arrayOf, bool, func, object, string } from 'prop-types';
import {
  getBundles,
  getCustomPages,
  getDeleteBundleStatus,
  getUploadBundleStatus,
  getErrors,
  getIsLoading,
  getSaveCustomPagesStatus,
  getBundlePagesList,
} from '../../reducers/customPages';
import { getFeatureFlagForCustomPages, getUserDetails } from '../../reducers/portalConfig';
import {
  fetchCustomPages,
  fetchCustomPagesBundles,
  fetchCustomPagesListInBundle,
  resetUpdateCustomPagesStatus,
  updateCustomPages,
  uploadCustomPagesBundle,
} from '../../actions/customPages';
import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { FileDropzone, Footer, LoadingDialog } from '../../components';
import DetailsContainer from '../details';
import { FILE_HELPER_TEXT, FILE_TYPE, MAX_FILE_SIZE } from './utils';
import { isArray } from 'lodash';
import { getI18n } from '../../utils/intl';
import { useIntl } from 'react-intl';
import { Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import { compose } from 'recompose';
import { deleteCustomPagesBundle } from '../../actions/customPages';
import Actions from './Actions';
import AssignPages from './AssignPages';
import { hasAdminRole } from '../../utils';

export const CustomPages = (props) => {
  const {
    bundles,
    customPages,
    bundlePagesList,
    classes,
    fetchCustomPages,
    fetchCustomPagesBundles,
    fetchCustomPagesListInBundle,
    updateCustomPages,
    uploadCustomPagesBundle,
    deleteCustomPagesBundle,
    deleteBundleStatus,
    uploadBundleStatus,
    saveCustomPagesStatus,
    resetUpdateCustomPagesStatus,
    featureFlagForCustomPages,
    userContext,
    // isLoading,
    errors,
  } = props;

  const intl = getI18n(useIntl());
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState('');
  const [notificationMessage, setNotificationMessage] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [invalidFileMessage, setInvalidFileMessage] = useState('');
  const [publishedBundle, setPublishedBundle] = useState(null);
  const [bundleFile, setBundleFile] = useState({
    files: [],
    acceptFileTypes: FILE_TYPE,
    maxFiles: 1,
    error: false,
    helperText: FILE_HELPER_TEXT,
    maxSize: MAX_FILE_SIZE,
  });

  useEffect(() => {
    if (!featureFlagForCustomPages) {
      props.push('/404');
    }
  }, [featureFlagForCustomPages]);

  useEffect(() => {
    if(!hasAdminRole(userContext)) {
      props.push('/404');
    }
  }, [userContext]);

  const fileLoad = (file) => {
    const reader = new FileReader();
    reader.onabort = () => {
      bundleFile.error = true;
      setBundleFile({ ...bundleFile });
    };
    reader.onerror = () => {
      bundleFile.error = true;
      setBundleFile({ ...bundleFile });
    };
    reader.onload = async () => {
      bundleFile.error = false;
      setInvalidFile(false);
      setInvalidFileMessage('');
      setBundleFile({ ...bundleFile });

      if (bundles.length < 2) {
        setShowLoadingDialog(true);
        await uploadCustomPagesBundle(file);
        fetchCustomPagesBundles();
        fetchCustomPages();
        setShowLoadingDialog(false);
      } else {
        setInvalidFile(true);
        setInvalidFileMessage(
          intl.getI18nFormattedMessage(
            'error.settings.custom.pages.upload.file.limit',
          ),
        );
        bundleFile.error = true;
        setBundleFile({ ...bundleFile });
      }
    };

    reader.readAsText(file);
  };

  const onRemoveFile = async (file) => {
    const remainBundleFiles = bundleFile.files.filter(
      (obj) => obj.name !== file.name,
    );
    await setBundleFile({ ...bundleFile, files: remainBundleFiles });
  };

  const handleFiles = async (files) => {
    isArray(files)
      ? files.forEach(async (file) => {
          await fileLoad(file);
        })
      : await fileLoad(files);
  };

  useEffect(() => {
    fetchCustomPagesBundles();
    fetchCustomPages();
  }, []);

  useEffect(() => {
    setPublishedBundle(bundles.find((bundle) => bundle.status === 'published'));
  }, [bundles]);

  return (
    <DetailsContainer
      classes={classes}
      detailsPageId="custom-pages-details-page"
      isLoading={false}
      divider={false}
      notificationId="custom-pages-details-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationStatus}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      errors={errors}
      header={
        <Typography
          variant="h1"
          className={classes.pageTitle}
          id={'custom-pages-title'}
          data-apim-test={'custom-pages-title'}
          data-layer7-test={'custom-pages-title'}
        >
          {intl.getI18nMessage('label.custom.pages.title')}
        </Typography>
      }
      actions={
        <Actions
          {...props}
          bundles={bundles}
          setNotificationMessage={setNotificationMessage}
          setNotificationStatus={setNotificationStatus}
          deleteCustomPagesBundle={deleteCustomPagesBundle}
          fetchCustomPagesBundles={fetchCustomPagesBundles}
          deleteBundleStatus={deleteBundleStatus}
          uploadBundleStatus={uploadBundleStatus}
          errors={errors}
        />
      }
      page={
        <div className={classes.pageBody}>
          <LoadingDialog
            isOpen={showLoadingDialog}
            title={intl.getI18nMessage('label.custom.pages.uploading')}
            dialogId="uploading-custom-pages-dialog"
          />
          <div className={classes.uploadBundleContainer}>
            <div className={classes.uploadRequirementsContainer}>
              <Typography
                variant="h3"
                className={classes.uploadBundleTitle}
                id={'upload-bundle-title'}
                data-apim-test={'upload-bundle-title'}
                data-layer7-test={'upload-bundle-title'}
              >
                {intl.getI18nMessage('label.custom.pages.upload.title')}
              </Typography>
              <Typography
                variant="body2"
                className={classes.uploadBundleDescription}
                id={'upload-bundle-description'}
                data-apim-test={'upload-bundle-description'}
                data-layer7-test={'upload-bundle-description'}
              >
                {intl.getI18nMessage('label.custom.pages.upload.requirements')}
                <ul>
                  <li>
                    {intl.getI18nMessage(
                      'label.custom.pages.upload.requirements.bullet1',
                    )}
                  </li>
                  <li>
                    {intl.getI18nMessage(
                      'label.custom.pages.upload.requirements.bullet2',
                    )}
                  </li>
                  <li>
                    {intl.getI18nMessage(
                      'label.custom.pages.upload.requirements.bullet3',
                    )}
                  </li>
                  <li>
                    To get started,{' '}
                    <a href={`/admin/customjs/SampleTemplates.zip`}>
                      download example.zip bundle.
                    </a>
                  </li>
                </ul>
              </Typography>
            </div>
            <div className={classes.uploadBundleFileDropzoneContainer}>
              <FileDropzone
                id={'bundle-file-upload'}
                handleFiles={handleFiles}
                showSelectedFiles={false}
                selectedFiles={bundleFile}
                allowedFileExtentions={['.zip']}
                onRemoveFile={onRemoveFile}
                isValidation={invalidFile}
                validationMessage={invalidFileMessage}
                defaultUploaderText={intl.getI18nMessage(
                  'label.custom.pages.upload',
                )}
              />
            </div>
          </div>
          <AssignPages
            bundles={bundles}
            publishedBundle={publishedBundle}
            customPages={customPages}
            bundlePagesList={bundlePagesList}
            setNotificationMessage={setNotificationMessage}
            setNotificationStatus={setNotificationStatus}
            saveCustomPagesStatus={saveCustomPagesStatus}
            fetchCustomPages={fetchCustomPages}
            fetchCustomPagesBundles={fetchCustomPagesBundles}
            fetchCustomPagesListInBundle={fetchCustomPagesListInBundle}
            resetUpdateCustomPagesStatus={resetUpdateCustomPagesStatus}
            updateCustomPages={updateCustomPages}
            errors={errors}
          />
        </div>
      }
      footer={<Footer />}
    />
  );
};

CustomPages.propTypes = {
  classes: object,
  customPages: arrayOf(object),
  bundlePagesList: arrayOf(object),
  bundles: arrayOf(object),
  deleteBundleStatus: string,
  uploadBundleStatus: string,
  saveCustomPagesStatus: string,
  fetchCustomPages: func,
  fetchCustomPagesBundles: func,
  fetchCustomPagesListInBundle: func,
  updateCustomPages: func,
  uploadCustomPagesBundle: func,
  deleteCustomPagesBundle: func,
  resetUpdateCustomPagesStatus: func,
  push: func,
  isLoading: bool,
  featureFlagForCustomPages: bool,
  userContext: object,
  errors: array,
};

const mapStateToProps = (state) => ({
  userContext: getUserDetails(state),
  customPages: getCustomPages(state),
  featureFlagForCustomPages: getFeatureFlagForCustomPages(state),
  bundlePagesList: getBundlePagesList(state),
  bundles: getBundles(state),
  saveCustomPagesStatus: getSaveCustomPagesStatus(state),
  deleteBundleStatus: getDeleteBundleStatus(state),
  uploadBundleStatus: getUploadBundleStatus(state),
  isLoading: getIsLoading(state),
  errors: getErrors(state),
});

const mapDispatchToProps = {
  push,
  fetchCustomPages,
  fetchCustomPagesBundles,
  fetchCustomPagesListInBundle,
  updateCustomPages,
  resetUpdateCustomPagesStatus,
  uploadCustomPagesBundle,
  deleteCustomPagesBundle,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(CustomPages);

connect(mapStateToProps, mapDispatchToProps)(CustomPages);
