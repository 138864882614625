import React, { useState, useEffect } from 'react';
import {
  Grid,
  FormLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { map, findIndex, forEach, isObject, get, lowerCase, isEmpty } from 'lodash';

import {
  FormActionButtons,
  ErrorContainer,
  FormComboCheckBox,
  ExpandPanelContainer,
} from '../../../../components';
import {
  RATE_LIMIT_QUOTA_LIST_FIELD,
} from '../../../../constants';
import {
  selectedRateLimitQuota,
  getRateLimitQuotaEnity,
  getRateLimitQuotaArguments,
  getRateLimitQuotaPolicyTemplates,
  getApiPolicyTemplateArguments,
} from '../../../../utils';
import {
  SAVE_AND_NEXT_TEXT,
} from '../labels';

export default function PolicyTemplates(props) {
  const {
    classes,
    errors,
    tabValue,
    apiUuid,
    onCancel,
   } = props;
  
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [selectedPolicyTemplates, setSelectedPolicyTemplates] = useState([]);
  const [policyFormArguments, setPolicyFormArguments] = useState([]);
  const [isSelectedRateQuotaTemplate, setIsSelectedRateQuotaTemplate] = useState(false);

  useEffect(() => {
    if(tabValue === 'policy-templates-tab') {
      props.fetchRateLimitQuotas();
      props.fetchPolicyTemplates();
    }
  }, [tabValue]);

  useEffect(() => {
    const selectedTemplates = [];
    //const defaultPolicyTemplates = [...props.policyTemplates];
    let hasSelectedRateQuotaTemplate = false;
    if (props.rateLimitQuotas.length > 0) {
      //To add ratelimitQuota form fields
      props.rateLimitQuotas.map(item => {
        const limit = get(item, 'rateLimit.maxRequestsPerSecond') ? `${get(item, 'rateLimit.maxRequestsPerSecond')} / sec` : 'None';
        const quota = get(item, 'quota.quota') ? `${get(item, 'quota.quota')} / ${lowerCase(get(item, 'quota.interval'))}`
        : 'None';
        item.secondaryText = `Rate: ${limit}; Quota: ${quota}`;
      });
    }
    if (props.policyTemplates.length > 0 && props.rateLimitQuotas.length > 0) {
      forEach(props.policyTemplates, (policy) => {
        const rateLimitPolicyEntity = getRateLimitQuotaPolicyTemplates(policy.arguments);
        if(rateLimitPolicyEntity) {
          policy.arguments = getRateLimitQuotaArguments(props.rateLimitQuotas);
        }
      });
    }
    if (props.policyTemplates.length > 0 && props.apiDetails.policyEntities
      && props.apiDetails.policyEntities.length > 0) {
      const policyTemplates = props.policyTemplates || [];
      const policyEntities = [];
      forEach(props.apiDetails.policyEntities, ({ policyEntityUuid, policyTemplateArguments }) => {
        const entity = {};
        const rateLimitPolicyEntity = getRateLimitQuotaPolicyTemplates(policyTemplateArguments);
        if(rateLimitPolicyEntity) {
          const rateLimitQuota = selectedRateLimitQuota(props.rateLimitQuotas,
            rateLimitPolicyEntity.value);
          policyTemplateArguments = getRateLimitQuotaEnity(rateLimitQuota);
        }
        entity[policyEntityUuid] = getApiPolicyTemplateArguments(policyTemplateArguments);
        policyEntities.push(entity);
      });
      forEach(policyEntities, (policyEntity) => {
        const selectedEntities = policyTemplates
          .filter(({ uuid }) => policyEntity[uuid])
          .map(policyTemplate => ({
            ...policyTemplate,
            arguments: get(policyTemplate, 'arguments', [])
              .map(argument => ({
                ...argument,
                value: policyEntity[policyTemplate.uuid][argument.name],
              })),
          }));
        // Adding to the rendered list of Policy Templates
        // only when the reference implementation exists
        if (selectedEntities.length > 0) { selectedTemplates.push(selectedEntities[0]); }
      });
      
      map(selectedTemplates, (policyTemplate, index) => {
        policyFormArguments[index] = policyFormArguments[index] || [];
        // To update rateLimitquota arguments
        if(getRateLimitQuotaPolicyTemplates(policyTemplate.arguments)) {
          hasSelectedRateQuotaTemplate = true;
          policyFormArguments[index] = policyTemplate.arguments;
        }
        map(get(policyTemplate, 'arguments', []), (arg) => {
          if (arg.value && !getRateLimitQuotaPolicyTemplates(policyTemplate.arguments)) {
            const argObj = policyFormArguments[index].find(obj => obj.name === arg.name);
            if (!isObject(argObj)) {
              policyFormArguments[index].push({ name: arg.name, value: arg.value });
            }
          }
        });
      });
      if(hasSelectedRateQuotaTemplate) {
        setIsSelectedRateQuotaTemplate(true);
      } else {
        setIsSelectedRateQuotaTemplate(false);
      }
      setSelectedPolicyTemplates([...selectedTemplates]);
      setPolicyFormArguments([...policyFormArguments]);
    }
  }, [props.apiDetails, props.policyTemplates, props.rateLimitQuotas]);

  const onSelectedPolicyTemplates = (obj) => {
    const rateLimitPolicyEntity = getRateLimitQuotaPolicyTemplates(obj.arguments);
    if(rateLimitPolicyEntity) {
      obj.arguments = getRateLimitQuotaArguments(props.rateLimitQuotas);
      setIsSelectedRateQuotaTemplate(true);
    }
    selectedPolicyTemplates.push(obj);
    setSelectedPolicyTemplates([...selectedPolicyTemplates]);
  };

  const onDeletePolicyTemplate = (template, i) => {
    if( getRateLimitQuotaPolicyTemplates(selectedPolicyTemplates[i].arguments)) {
      setIsSelectedRateQuotaTemplate(false);
    }
    selectedPolicyTemplates.splice(i, 1);
    policyFormArguments.splice(i, 1);
    setSelectedPolicyTemplates([...selectedPolicyTemplates]);
    setPolicyFormArguments([...policyFormArguments]);
  };

  const onMoveUpPolicyTemplate = (template, i) => {
    if (i > 0) {
      const moveUpPolicyTemplates = selectedPolicyTemplates.splice(i, 1);
      selectedPolicyTemplates.splice(i - 1, 0, moveUpPolicyTemplates[0]);
      const moveUpPolicyFormArguments = policyFormArguments.splice(i, 1);
      policyFormArguments.splice(i - 1, 0, moveUpPolicyFormArguments[0]);
    }
    setSelectedPolicyTemplates([...selectedPolicyTemplates]);
    setPolicyFormArguments([...policyFormArguments]);
    localStorage.setItem('isUnSavedChanges', true);
  };

  const onMoveDownPolicyTemplate = (template, i) => {
    if (i < selectedPolicyTemplates.length - 1) {
      const moveDownPolicyTemplates = selectedPolicyTemplates.splice(i, 1);
      selectedPolicyTemplates.splice(i + 1, 0, moveDownPolicyTemplates[0]);
    }
    if (i < policyFormArguments.length - 1) {
      const moveDownPolicyFormArguments = policyFormArguments.splice(i, 1);
      policyFormArguments.splice(i + 1, 0, moveDownPolicyFormArguments[0]);
    }
    setSelectedPolicyTemplates([...selectedPolicyTemplates]);
    setPolicyFormArguments([...policyFormArguments]);
    localStorage.setItem('isUnSavedChanges', true);
  };

  const onChangePolicyTemplateForm = (val, name, index) => {
    policyFormArguments[index] = policyFormArguments[index] || [];
    const objectIndex = findIndex(policyFormArguments[index], { name });
    if (name === RATE_LIMIT_QUOTA_LIST_FIELD) {
        policyFormArguments[index] = getRateLimitQuotaEnity(val);
        const args = getRateLimitQuotaArguments(props.rateLimitQuotas, val);
        selectedPolicyTemplates[index] = { ...selectedPolicyTemplates[index], arguments: args }
    } else {
      if (objectIndex > -1) {
        policyFormArguments[index][objectIndex].value = val.toString();
      } else {
        policyFormArguments[index].push({ name, value: val.toString() });
      }

      const policyObjectIndex = findIndex(selectedPolicyTemplates[index].arguments, { name });
      if (policyObjectIndex > -1) {
        selectedPolicyTemplates[index].arguments[policyObjectIndex].value = val.toString();
      } else {
        selectedPolicyTemplates[index].arguments[policyObjectIndex].push(
          { name, value: val.toString() });
      }
    }
    setSelectedPolicyTemplates([...selectedPolicyTemplates]);
    setPolicyFormArguments([...policyFormArguments]);
  };

  const onNextPolicyTemplates = async () => {
    const data = [];
    const args = [...policyFormArguments];
    selectedPolicyTemplates.map(({ uuid }, i) => {
      const rateLimitPolicyEntity = getRateLimitQuotaPolicyTemplates(args[i]);
      if (rateLimitPolicyEntity) {
        args[i] = [args[i][6], args[i][7]];
      }
      args[i] && args[i].map(({ value }) => {
        if(isEmpty(value)){
         args[i] = [];
        }
      });
      data.push({
        policyEntityUuid: uuid,
        policyTemplateArguments: args[i] || [],
      });
    });
    await props.saveApiPolicyEntities(apiUuid, data);
  };

  const onNext = () => {
    localStorage.setItem('isUnSavedChanges', false);
    setIsUnsavedChanges(false);
    setIsSelectedRateQuotaTemplate(false);
    onNextPolicyTemplates();
  };

  const onSelect = (template) => {
    localStorage.setItem('isUnSavedChanges', true);
    setIsUnsavedChanges(true);
    onSelectedPolicyTemplates(template);
  };

  const onPolicyFormChange = (val, name, index) => {
    localStorage.setItem('isUnSavedChanges', true);
    setIsUnsavedChanges(true);
    onChangePolicyTemplateForm(val, name, index);
  };

  const getPanelItemName = (item, classes) => {
    const rateLimitPolicyEntity = getRateLimitQuotaPolicyTemplates(item.arguments);
    return (
      <span>{item.name}
        {rateLimitPolicyEntity &&
          <span className={classes.tooltipContainer}>
            System
          </span>
        }
      </span>
    );
  };

  const getOptionDisabled = (option) => {
    let isDisabled = false;
    if(isSelectedRateQuotaTemplate) {
      if(getRateLimitQuotaPolicyTemplates(option.arguments)) {
        isDisabled = true;
      }
    }
    return isDisabled;
  };

  return (
    <div id="api-policy-container">
      <h1 className={classes.pageTitle}>Policy Templates</h1>
      {errors.length > 0 &&
        <ErrorContainer errors={errors} />
      }
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid md={12} item>
          <div className={classes.expandContainer} id="selected-policy-templates-container">
            <ExpandPanelContainer
              id={"selected-policy-templates-panel-container"}
              panelTitle={'Policy Template Name'}
              hasPanelTitle={selectedPolicyTemplates.length > 0}
              panelItemName={getPanelItemName}
              defaultList={selectedPolicyTemplates}
              isDeleteIcon
              onDeleteItem={onDeletePolicyTemplate}
              handleChangeDynamicForm={(val, name, index) => onPolicyFormChange(val, name, index)}
              isMoveUpIcon
              onMoveUpItem={onMoveUpPolicyTemplate}
              isMoveDownIcon
              onMoveDownItem={onMoveDownPolicyTemplate}
              detailContainerClass={classes.detailContainer}
            />
          </div>
          {selectedPolicyTemplates.length > 0 && <hr className={classes.line}/> }
          <div className={classes.comboBoxContainer} id="policy-templates-container">
            <FormLabel className={classes.policyLabel}>{'Add Policy Template'}</FormLabel>
            <FormComboCheckBox
              id="api-policy-templates"
              data={props.policyTemplates}
              onSelect={onSelect}
              selectedvalues={selectedPolicyTemplates}
              getOptionDisabled={getOptionDisabled}
              inputLabel={'Select Policy Template'}
            />
          </div>
        </Grid>
      </Grid>
      <FormActionButtons
        onNextClick={onNext}
        nextText={SAVE_AND_NEXT_TEXT}
        onCancelClick={() => onCancel(isUnsavedChanges)}
        id="visibility-form-buttons"
      />
    </div>
  );
}

PolicyTemplates.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  apiUuid: PropTypes.string,
  policyTemplates: PropTypes.arrayOf(PropTypes.object),
  apiDetails: PropTypes.object,
  rateLimitQuotas: PropTypes.arrayOf(PropTypes.object),
  onCancel: PropTypes.func,
  saveApiPolicyEntities: PropTypes.func,
  tabValue: PropTypes.string,
  fetchRateLimitQuotas: PropTypes.func,
  fetchPolicyTemplates: PropTypes.func,
};
