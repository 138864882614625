import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import ChartLegend from '../ChartLegend';
import HorizontalScrollBar from '../../../components/HorizontalScrollBar';

export default function ChartLegendPanel(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    lineColors,
    chartData,
    chartEntity,
    clickHandler,
    hoverHandler,
  } = props;
  return (
    <HorizontalScrollBar scrollEntity={`${chartEntity}LegendPanel`}>
      <div className={classes.dashboardChartLegends}>
        {chartData.slice(0, 3).map((item, index) => (
          // taking only top 3 elements for the dashboard chart.
          <ChartLegend
            tabIndex="0"
            entity={item}
            rank={index + 1}
            lineColor={lineColors[index]}
            chartEntity={chartEntity}
            key={item.id}
            clickHandler={clickHandler}
            hoverHandler={hoverHandler}
            isTop3EntitiesSelected={props.isTop3EntitiesSelected}
            isApiOwnerOrOrgPub={props.isApiOwnerOrOrgPub}
            isOrgPublisher={props.isOrgPublisher}
          />
        ))}
      </div>
    </HorizontalScrollBar>
  );
}

ChartLegendPanel.propTypes = {
  lineColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.arrayOf(PropTypes.shape({})),
  chartEntity: PropTypes.string,
  clickHandler: PropTypes.func,
  hoverHandler: PropTypes.func,
  isApiOwnerOrOrgPub: PropTypes.bool,
  isTop3EntitiesSelected: PropTypes.object,
  isOrgPublisher: PropTypes.bool,
};
