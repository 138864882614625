import React, { useState } from 'react';
import { arrayOf, bool, func, number, oneOfType, object, string } from 'prop-types';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import compose from 'recompose/compose';
import clsx from 'clsx';

import {
  withStyles,
  Grid,
  Button,
  Tooltip,
  Typography,
  IconButton,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';

import {
  AlertMessages,
  Footer,
  Loading,
  Table,
  TablePagination,
} from '../components';
import styles from './list.styles';
import { GRID_ROWS_PER_PAGE_OPTIONS } from '../constants';
import { isFunction } from 'lodash';

const getRows = ({
  rows,
  expandedRows,
  setExpandedRows,
  selectedRows,
  setSelectedRows,
  selectedRowsObject,
  setSelectedRowsObject,
  disabledRows,
}) => (
  rows.map(
    row => ({
      ...row,
      expanded: expandedRows.includes(row.uuid),
      onClick: (uuid) => {
        if (expandedRows.includes(uuid)) {
          setExpandedRows(
            expandedRows.filter(e => (e !== uuid)),
          );
        } else {
          setExpandedRows(
            [...expandedRows, uuid],
          );
        }
      },
      selected: selectedRows.includes(row.uuid),
      disabled: disabledRows.includes(row.uuid),
      onSelectToggle: (e, row) => {
        if (e.target.checked) {
          setSelectedRows(
            [...selectedRows, row.uuid],
          );
          if(isFunction(setSelectedRowsObject)) {
            selectedRowsObject.push(row)
            setSelectedRowsObject(selectedRowsObject);
          }
        } else {
          setSelectedRows(
            selectedRows.filter(e => (e !== row.uuid)),
          );
          if(isFunction(setSelectedRowsObject)) {
            setSelectedRowsObject(selectedRowsObject.filter(e => (e !== row.uuid)));
          }
        }
      },
    }),
  )
);

export const List = (props) => {
  const {
    classes,
    userContext,
    listPageId,
    isLoading,
    notificationId,
    notificationStatus,
    setNotificationStatus,
    notificationMessage,
    setNotificationMessage,
    onCloseNotification,
    pageHeaderTitle,
    pageHeaderTooltipTitle,
    addButtonLabel,
    onAdd,
    disableAdd,
    filterAndSortContent,
    bulkActionsContent,
    columns,
    noResultsMessage,
    page,
    totalElements,
    totalPages,
    rowsPerPage: rowsPerPageParam,
    rowsPerPageOptions = GRID_ROWS_PER_PAGE_OPTIONS,
    onChangeRowsPerPage,
    onChangePage,
    onChangePreviousPage,
    onChangeNextPage,
    selectedRows = [],
    disabledRows = [],
    setSelectedRows,
    selectedRowsObject = [],
    setSelectedRowsObject,
    showListHeader = true,
    pageClass = null,
    pageBodyClass,
    pageFilterAndSortClass,
    uuidName = null,
    notifyMessages,
    hasPaginationDisabled = false,
    paginationTooltipMessage = '',
  } = props;
  let rowsPerPage;
  if (rowsPerPageOptions) {
    if (findIndex(rowsPerPageOptions, item => item === rowsPerPageParam) === -1) {
      rowsPerPage = rowsPerPageOptions[0];
    } else {
      rowsPerPage = rowsPerPageParam
    }
  }

  const [expandedRows, setExpandedRows] = useState([]);
  const rows = getRows({
    rows: props.rows,
    expandedRows,
    setExpandedRows,
    selectedRows,
    setSelectedRows,
    selectedRowsObject,
    setSelectedRowsObject,
    disabledRows,
  });

  const isSelectedAll = rows.every(item => item.selected);
  const isDisabledAll = rows.every(item => item.disabled);

  const onSelectAllRowsToggle = (e) => {
    const toggleRows = rows.map(row => row.uuid).filter(uuid => !disabledRows.includes(uuid));
    if(get(e, 'target.checked')) {
      setSelectedRows([...toggleRows, ...selectedRows]);
      if(isFunction(setSelectedRowsObject)) {
        setSelectedRowsObject(rows);
      }
    } else {
      setSelectedRows(
        selectedRows.filter(e => !toggleRows.includes(e)),
      );
      isFunction(setSelectedRowsObject) ? setSelectedRowsObject([]) : '';
    }
  }
  const onCloseButton = () => {
    setSelectedRows([]);
    isFunction(setSelectedRowsObject) ? setSelectedRowsObject([]) : '';
  }
  const notificationMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  return (
    <Grid
      className={clsx(pageClass, classes.page)}
      id={listPageId} data-apim-test={listPageId} data-layer7-test={listPageId}
    >
      {notificationMessage &&
        <AlertMessages
          className={classes.alertMessages}
          id={notificationId}
          variant={notificationStatus}
          message={notificationMessage}
          onClose={() => {
            notificationMessages('', '');
            if (onCloseNotification) { onCloseNotification(); }
          }}
        />
      }
      {isLoading ?
        <Loading pageLoader />
        :
        <div className={clsx(pageBodyClass, classes.pageBody)}>
          {showListHeader &&
            <Grid container justify="space-between" className={pageHeaderTitle ? classes.pageHeader: classes.pageHeaderHidden}>
              <Grid item md={10} sm={9} xs={12}>
                <Grid container>
                  <Grid item>
                    <Typography
                      variant="h1"
                      className={classes.pageTitle}
                      id={`${listPageId}-title`} data-apim-test={`${listPageId}-title`} data-layer7-test={`${listPageId}-title`}
                    >
                      {pageHeaderTitle}
                    </Typography>
                  </Grid>
                  {pageHeaderTooltipTitle &&
                    <Tooltip
                      arrow disableFocusListener disableTouchListener placement="top"
                      title={pageHeaderTooltipTitle}
                    >
                      <IconButton
                        size="small" edge="start" color="secondary"
                        className={classes.helpIcon}
                        id={`${listPageId}-help`} data-apim-test={`${listPageId}-help`} data-layer7-test={`${listPageId}-help`}
                        aria-label="gateway-bundle-help"
                      >
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Grid>
              </Grid>
              <Grid
                item md={2} sm={3} xs={12}
                className={classes.addButton}
              >
                {onAdd &&
                  <Button
                    variant="contained" color="secondary"
                    onClick={onAdd || (() => {})}
                    id={`${listPageId}-add`} data-apim-test={`${listPageId}-add`} data-layer7-test={`${listPageId}-add`}
                    disabled={disableAdd}
                  >
                    {addButtonLabel}
                  </Button>
                }
              </Grid>
            </Grid>
          }
          
          {(selectedRows.length > 0) ?
            <Grid
              container justify="space-between"
              className={
                clsx(pageFilterAndSortClass, classes.pageFilterAndSort, classes.pageBulkActions)
              }
            >
              <Grid item md={11} sm={11} xs={11}>
                <Grid
                  container
                  id="bulk-actions" data-apim-test="bulk-actions" data-layer7-test="bulk-actions"
                >
                  {bulkActionsContent}
                </Grid>
              </Grid>
              <Grid item md={1} sm={1} xs={1}>
                <IconButton
                  size="small" edge="start" color="secondary"
                  className={classes.pageBulkActionsClose}
                  id={`${listPageId}-bulk-actions-close`} data-apim-test={`${listPageId}-bulk-actions-close`} data-layer7-test={`${listPageId}-bulk-actions-close`}
                  onClick={onCloseButton}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            :
            <Grid
              container justify="space-between"
              className={clsx(pageFilterAndSortClass, classes.pageFilterAndSort)}
            >
              {filterAndSortContent}
            </Grid>
          }
          <Table
            userContext={userContext}
            id={`${listPageId}-list`}
            columns={columns}
            rows={rows}
            noResultsMessage={noResultsMessage}
            uuidName={uuidName}
            notifyMessages={notifyMessages}
            hasBulkActions={!!bulkActionsContent}
            selectedRows={selectedRows}
            onSelectAllRowsToggle={onSelectAllRowsToggle}
            isSelectedAll={isSelectedAll}
            isDisabledAll={isDisabledAll}
          />
          <TablePagination
            id={`${listPageId}-pagination`}
            page={page}
            count={totalElements}
            pageCount={totalPages}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            onChangePreviousPage={onChangePreviousPage}
            onChangeNextPage={onChangeNextPage}
            hasPaginationDisabled={hasPaginationDisabled}
            paginationTooltipMessage={paginationTooltipMessage}
          />
        </div>
      }
      <Footer />
    </Grid>
  );
};

List.propTypes = {
  classes: object,
  userContext: object,
  listPageId: string,
  isLoading: bool,
  notificationId: string,
  notificationStatus: string,
  setNotificationStatus: func,
  notificationMessage: string,
  setNotificationMessage: func,
  onCloseNotification: func,
  pageHeaderTitle: string,
  pageHeaderTooltipTitle: string,
  addButtonLabel: string,
  onAdd: oneOfType([func, bool]),
  disableAdd: bool,
  filterAndSortContent: object,
  tagsContent: object,
  bulkActionsContent: oneOfType([bool, object]),
  columns: arrayOf(object),
  rows: arrayOf(object),
  noResultsMessage: string,
  page: number,
  rowsPerPageOptions: arrayOf(number),
  totalPages: number,
  totalElements: number,
  rowsPerPage: number,
  onChangeRowsPerPage: func,
  onChangePage: func,
  onChangePreviousPage: func,
  onChangeNextPage: func,
  selectedRows: arrayOf(string),
  disabledRows: arrayOf(string),
  setSelectedRows: func,
  selectedRowsObject: arrayOf(object),
  setSelectedRowsObject: func,
  showListHeader: bool,
  pageClass: string,
  pageBodyClass: string,
  pageFilterAndSortClass: string,
  uuidName: string,
  notifyMessages: func,
  hasPaginationDisabled: bool,
  paginationTooltipMessage: string,
};

export default compose(
  withStyles(styles),
)(List);
