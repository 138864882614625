import get from 'lodash/get';

const styles = theme => ({
  content: {
    margin: get(theme, 'margin.m36'),
    minHeight: 'calc(100vh - 125px)',
    backgroundColor: get(theme, 'color.uiBackground'),
    marginTop: get(theme, 'margin.m0'),
    padding: '24px 0px 72px 0px',
  },
  gridContainer: {
    display: 'inline-flex',
    width: 'auto',
    padding: '0 36px',
  },
  divider: {
    backgroundColor: get(theme, 'color.bodyText'),
  },
  notificationWidthClass: {
    width: 'calc(100% - 100px)',
    padding: '0 15px',
    marginLeft: get(theme, 'margin.m0'),
  },
  actionsContainer: {
    marginTop: get(theme, 'margin.m6'),
  },
  errorContainer: {
    marginLeft: '30px',
  },
});

export default styles;
