import React from 'react';
import { FormattedMessage } from 'react-intl';

export default (dict) => (label) => (
  <FormattedMessage id={label} defaultMessage={dict[label]} />
);

export const SUCCESS_TEXT = 'success';

export const CANCEL_TEXT = 'Cancel';
export const SAVE_TEXT = 'Save';
export const NEXT_TEXT = 'Next';

export const SAVE_AND_NEXT_TEXT = 'Save & Next';
export const CREATE_TEXT = 'Create';
export const DONE_TEXT = 'Done';

export const LABEL_BUTTON_NEXT = 'label.button.next';

export const APPLICATION_ORGANIZATION_ASSIST_TEXT = 'Set an organization and proceed to next step.';
export const APPLICATION_CUSTOM_FIELDS_ASSIST_TEXT = 'Set Custom Fields and proceed to next step.';
export const APPLICATION_API_MANAGEMENT_ASSIST_TEXT = 'Add APIs and proceed to next step.';
export const APPLICATION_AUTH_ASSIST_TEXT = 'You will receive a key right away.';

export const APPLICATION_DETAILS_CREATE_SUCCESS = 'Application is created.';
export const APPLICATION_DETAILS_UPDATE_SUCCESS = 'Application is updated.';
export const APPLICATION_DETAILS_SAVE_ERROR = 'Unable to carry out the operation. No updates have been made. See details below.';

export const APPLICATION_API_SELECTED_APIS_TITLE = 'Selected APIs';
export const APPLICATION_API_SELECTED_APIS_AND_API_GROUPS_TITLE = 'Selected APIs and API Groups';
export const APPLICATION_API_AVAILABLE_APIS_TITLE = 'Available APIs';
export const APPLICATION_API_SEARCH_AVAILABLE_APIS_PLACEHOLDER = 'Search an Available API';
export const APPLICATION_API_AVAILABLE_API_GROUPS_TITLE = 'Available API Groups';
export const APPLICATION_API_SEARCH_AVAILABLE_API_GROUPS_PLACEHOLDER = 'Search an Available API Group';
export const APPLICATION_API_EULA_DIALOG_TITLE = 'API Terms & Conditions';
export const APPLICATION_API_EULA_DIALOG_SUBMIT_TEXT = 'I Accept the Terms & Conditions';

export const APPLICATION_API_TAG_SELECT_DIALOG_TITLE = 'label.api.tags.title';
export const APPLICATION_API_TAG_SELECT_DIALOG_FILTER_TEXT = 'label.application.api.tag.filter.text';
export const APPLICATION_API_TAG_NO_OPTIONS_TEXT = 'label.application.api.tag.nooptions.text';
export const APPLICATION_API_TAG_PLACEHOLDER_TEXT = 'label.application.api.tag.placeholder.text';

export const APPLICATION_AUTH_SELECT_HASHING_DIALOG_TITLE = 'Generate New Secret';
export const APPLICATION_AUTH_SELECT_HASHING_DIALOG_SUBTITLE = 'Portal settings allow for plaintext secrets. This is not recommended for security purposes.';
export const APPLICATION_AUTH_SELECT_HASHING_DIALOG_CHECKBOX_LABEL = 'I want to use a non-secure plaintext key.';
export const APPLICATION_AUTH_SELECT_HASHING_DIALOG_SUBMIT_TEXT = 'Create & Get Key';

export const APPLICATION_APP_KEY_COPY_BUTTON_LABEL = 'Copy';
export const APPLICATION_APP_KEY_GENERATE_SECRET_BUTTON_LABEL = 'Request New Secret';
export const APPLICATION_APP_KEY_GENERATE_SECRET_DIALOG_SUBMIT_TEXT = 'Generate';
export const APPLICATION_APP_KEY_GENERATE_SECRET_DIALOG_SUBTITLE =
  'The old secret will no longer be valid. The new secret must be shared with any developers coding for this ' +
  'application. This action will break access for anyone using the old secret.';
export const APPLICATION_APP_KEY_GENERATE_SECRET_DIALOG_CHECKBOX_LABEL = 'I want to change the key, breaking access for anyone using the old key.';
export const APPLICATION_APP_KEY_SECRET_DEFAULT_HASH_FROM_DB = '********';
export const APPLICATION_SECRET_COPY_MESSAGE1 = 'Secret Generated: <secret-';
export const APPLICATION_SECRET_COPY_MESSAGE2 = '> copied to clipboard.';
export const APPLICATION_API_KEY_MESSAGE1 = 'API Key: <key-';
export const APPLICATION_API_KEY_MESSAGE2 = '> copied to clipboard.';
export const APPLICATION_SECRET_COPY_CLASS = 'success';

export const UNSAVED_DIALOG_TITLE = 'Unsaved Changes';
export const UNSAVED_DIALOG_DESCRIPTIONS = 'Your changes will be lost if you leave this page. Are you sure you want to continue away from this page?';
export const UNSAVED_DIALOG_CANCEL_TEXT = 'Stay';
export const UNSAVED_DIALOG_SUBMIT_TEXT = 'Continue';
export const UNSAVED_DIALOG_EXIT_TEXT = 'Exit';
