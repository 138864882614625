import React from 'react';
import { arrayOf, bool, func, number, oneOfType, object, string } from 'prop-types';
import findIndex from 'lodash/findIndex';
import compose from 'recompose/compose';
import clsx from 'clsx';

import {
  withStyles,
  Grid,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import ReactTooltip from 'rc-tooltip';
import HelpIcon from '@material-ui/icons/Help';

import {
  AlertMessages,
  Footer,
  Loading,
  TablePagination,
} from '../components';
import styles from './grid.styles';
import { GRID_ROWS_PER_PAGE_OPTIONS } from '../constants';

export const List = (props) => {
  const {
    classes,
    listPageId,
    isLoading,
    notificationId,
    notificationStatus,
    setNotificationStatus,
    notificationMessage,
    setNotificationMessage,
    onCloseNotification,
    pageHeaderTitle,
    pageHeaderTooltipTitle,
    addButtonLabel,
    onAdd,
    disableAdd,
    filterAndSortContent,
    // noResultsMessage,
    page,
    rows,
    totalElements,
    totalPages,
    rowsPerPage: rowsPerPageParam,
    rowsPerPageOptions = GRID_ROWS_PER_PAGE_OPTIONS,
    onChangeRowsPerPage,
    onChangePage,
    onChangePreviousPage,
    onChangeNextPage,
    showListHeader = true,
    pageClass = null,
    pageBodyClass,
    pageFilterAndSortClass,
    renderGridItem,
  } = props;

  let rowsPerPage;
  if (rowsPerPageOptions) {
    if (findIndex(rowsPerPageOptions, item => item === rowsPerPageParam) === -1) {
      rowsPerPage = rowsPerPageOptions[0];
    } else {
      rowsPerPage = rowsPerPageParam
    }
  }

  const notificationMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  return (
    <Grid
      className={clsx(pageClass, classes.page)}
      id={listPageId} data-apim-test={listPageId} data-layer7-test={listPageId}
    >
      {notificationMessage &&
        <AlertMessages
          className={classes.alertMessages}
          id={notificationId}
          variant={notificationStatus}
          message={notificationMessage}
          onClose={() => {
            notificationMessages('', '');
            if (onCloseNotification) { onCloseNotification(); }
          }}
        />
      }
      {isLoading ?
        <Loading pageLoader />
        :
        <div className={clsx(pageBodyClass, classes.pageBody)}>
          {showListHeader &&
            <Grid container justify="space-between" className={classes.pageHeader}>
              <Grid item md={10} sm={9} xs={12}>
                <Grid container>
                  <Grid item>
                    <Typography
                      variant="h1"
                      className={classes.pageTitle}
                      id={`${listPageId}-title`} data-apim-test={`${listPageId}-title`} data-layer7-test={`${listPageId}-title`}
                    >
                      {pageHeaderTitle}
                    </Typography>
                  </Grid>
                  {pageHeaderTooltipTitle &&
                    <ReactTooltip
                      arrow disableFocusListener disableTouchListener placement="top"
                      title={pageHeaderTooltipTitle}
                    >
                      <IconButton
                        size="small" edge="start" color="secondary"
                        className={classes.helpIcon}
                        id={`${listPageId}-help`} data-apim-test={`${listPageId}-help`} data-layer7-test={`${listPageId}-help`}
                        aria-label="gateway-bundle-help"
                      >
                        <HelpIcon />
                      </IconButton>
                    </ReactTooltip>
                  }
                </Grid>
              </Grid>
              <Grid
                item md={2} sm={3} xs={12}
                className={classes.addButton}
              >
                {onAdd &&
                  <Button
                    variant="contained" color="secondary"
                    onClick={onAdd || (() => {})}
                    id={`${listPageId}-add`} data-apim-test={`${listPageId}-add`} data-layer7-test={`${listPageId}-add`}
                    disabled={disableAdd}
                  >
                    {addButtonLabel}
                  </Button>
                }
              </Grid>
            </Grid>
          }
        <Grid
            container justify="space-between"
            className={clsx(pageFilterAndSortClass, classes.pageFilterAndSort)}
        >
            {filterAndSortContent}
        </Grid>
        <Grid container className={classes.cardsContainer} >
            {rows.map(renderGridItem)}
        </Grid>
          <TablePagination
            id={`${listPageId}-pagination`}
            page={page}
            count={totalElements}
            pageCount={totalPages}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            onChangePreviousPage={onChangePreviousPage}
            onChangeNextPage={onChangeNextPage}
          />
        </div>
      }
      <Footer />
    </Grid>
  );
};

List.propTypes = {
  classes: object,
  listPageId: string,
  isLoading: bool,
  notificationId: string,
  notificationStatus: string,
  setNotificationStatus: func,
  notificationMessage: string,
  setNotificationMessage: func,
  onCloseNotification: func,
  pageHeaderTitle: string,
  pageHeaderTooltipTitle: string,
  addButtonLabel: string,
  onAdd: oneOfType([func, bool]),
  disableAdd: bool,
  filterAndSortContent: object,
  rows: arrayOf(object),
  noResultsMessage: string,
  page: number,
  rowsPerPageOptions: arrayOf(number),
  totalPages: number,
  totalElements: number,
  rowsPerPage: number,
  onChangeRowsPerPage: func,
  onChangePage: func,
  onChangePreviousPage: func,
  onChangeNextPage: func,
  showListHeader: bool,
  pageClass: string,
  pageBodyClass: string,
  pageFilterAndSortClass: string,
  uuidName: string,
  notifyMessages: func,
  renderGridItem: func,
};

export default compose(
  withStyles(styles),
)(List);
