import React, { Fragment } from 'react';
import { map, upperCase } from 'lodash';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Check from '@material-ui/icons/Check';
import { Button, Paper, Popover, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from './index.theme';
import { hasOrgBoundRole } from '../../../utils';

const renderAccessibleOrgs = ({ classes, userActiveOrgUuid,
  updateUserContexts }) => accessibleOrg => (
    <div key={accessibleOrg.uuid} className={classes.UserMenuContent__AccessibleOrgWrapper}>
      <Button
        color="secondary"
        className={classes.UserMenuContent__AccessibleOrgs}
        onClick={() => updateUserContexts({ orgUuid: accessibleOrg.uuid })}
      >
        {accessibleOrg.name}
      </Button>
      {userActiveOrgUuid === accessibleOrg.uuid && (
        <Check className={classes.UserMenuContent__Check} />
      )}
    </div>
);

const renderUser = ({
  classes,
  userAccessibleOrgs,
  userActiveOrgUuid,
  userDetails,
  updateUserContexts,
}) => (
  <Fragment>
    <div className={classes.UserMenuContent_Padded}>
      <div className={classes.UserMenuContent__Item}>
        <Button
          color="secondary"
          className={classes.button}
          href={`https://${window.location.hostname}/admin/my-profile`}
        >
          My Profile
        </Button>
      </div>
    </div>

    {hasOrgBoundRole(userDetails) && <div className={classes.UserMenuContent_Padded}>
      <div className={classes.UserMenuContent_OrgsWrapper}>
        <div className={clsx(classes.SubHeader, classes.SubHeaderUppercase)}>
          {upperCase('organizations')}
        </div>
        {map(
          userAccessibleOrgs,
          renderAccessibleOrgs({ classes, userActiveOrgUuid, updateUserContexts }),
        )}
      </div>
    </div>}

    <div className={classes.UserMenuContent_Padded}>
      <div className={classes.UserMenuContent__Item}>
        <Button
          color="secondary"
          className={classes.button}
          onClick={() => {
            localStorage.clear();
            window.location.href = `//${window.location.hostname}/admin/logout`;
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  </Fragment>
);

function User({
  classes = {},
  userAccessibleOrgs,
  userActiveOrgUuid,
  userDetails,
  updateUserContexts,
}) {
  const [anchorEl = false, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.UserMenuButton}>
      <IconButton color="secondary" onClick={handleClick}>
        <AccountCircle fontSize="small" />
      </IconButton>
      <span className={classes.user} onClick={handleClick}>{userDetails.username}</span>
      <IconButton color="secondary" onClick={handleClick}>
        <ArrowDropDownIcon fontSize="default" />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Paper>
          <div className={classes.Menu}>
            {renderUser({
              classes,
              userAccessibleOrgs,
              userActiveOrgUuid,
              userDetails,
              updateUserContexts,
            })}
          </div>
        </Paper>
      </Popover>
    </div>
  );
}

User.propTypes = {
  classes: PropTypes.object,
  userAccessibleOrgs: PropTypes.arrayOf(PropTypes.object),
  userActiveOrgUuid: PropTypes.string,
  userDetails: PropTypes.object,
  updateUserContexts: PropTypes.func,
};

renderUser.propTypes = {
  classes: PropTypes.object,
  userAccessibleOrgs: PropTypes.arrayOf(PropTypes.object),
  userActiveOrgUuid: PropTypes.string,
  userDetails: PropTypes.object,
  updateUserContexts: PropTypes.func,
};

export default withStyles(theme)(User);
