export const HELPITEMS = {
  orgVisibilityHelp: {
    title: 'Help',
    descriptions: 'Control API access by organization for private APIs. Public API can be accessed to all organizations(this is set in API details).',
    subItems: [
      {
        id: 0,
        title: '',
        descriptions: 'Add Rate Limit & Quotas specific to each organization for this API. Requires l7.apim.system - Rate Limit & Quota Policy Template 2.0 or higher.',
      },
    ],
  },
  visibilityHelp: {
    title: 'Visibility Help',
    descriptions: 'Set up which users can view this API.',
    subItems: [
      {
        id: 0,
        title: 'Public',
        descriptions: 'If you set the visibility to Public, any user can view or consume this API.',
      },
      {
        id: 1,
        title: 'Private',
        descriptions: 'If you set the visibility to Private, all API Owners and Org Users of the managing org (if selected) can view or consume this API.',
      },
      {
        id: 2,
        title: 'Restricted',
        descriptions: 'If you set the visibility to Restricted, all API Owners and Org Users of selected organizations can view or consume this API.',
      },
    ],
  },
  permissionsHelp: {
    title: 'Management Permissions',
    descriptions: 'You must specify at least one Managing Organization OR API Owner.',
    subItems: [
      {
        id: 0,
        title: 'Managing Org',
        descriptions: 'Selecting a managing org allows all users in that org that have permission to edit apis (e.g. org publishers) to edit this API.',
      },
      {
        id: 1,
        title: 'API Owner',
        descriptions: 'By default all APIs are open to be edited by all API Owners, but it can be restricted on to select individuals.',
      },
      {
        id: 2,
        title: '',
        descriptions: 'For API owners that are always assigned to edit individual APIs, consider adding them to the appropriate organizations in an org publisher role instead.',
      },
    ],
  },
  detailsHelp: {
    title: 'How do I publish an API?',
    descriptions: '',
    subItems: [
      {
        id: 1,
        title: '',
        descriptions: 'Select the type of API you would like to publish (REST or SOAP).',
      },
      {
        id: 2,
        title: '',
        descriptions: 'Upload the corresponding definition files. For REST, Swagger or WADL files are supported. For SOAP, a WSDL file is required and XSD files are optional. Once uploaded, this will pre-fill the API details for you.',
      },
      {
        id: 3,
        title: '',
        descriptions: 'If you do not have any definition files, provide API details manually in the next section, and any mandatory fields that are not filled.',
      },
      {
        id: 4,
        title: '',
        descriptions: 'Add your proxy URI to the Proxy Details field. The proxy URL is the public URL of the API on the API proxy. Developers use this URL in their applications to send requests to the API.',
      },
      {
        id: 5,
        title: '',
        descriptions: 'For Gateway published APIs, select an API EULA, complete the remaining mandatory fields and save. This will enable the API.',
      },
    ],
  },
  customFieldsHelp: {
    title: '',
    descriptions: '',
    subItems: [
      {
        id: 1,
        title: '',
        descriptions: 'Custom fields are defined by your Administrator to provide additional metadata for your API.',
      },
    ],
  },
  policyTemplatesHelp: {
    title: '',
    descriptions: '',
    subItems: [
      {
        id: 1,
        title: '',
        descriptions: 'Policy Templates are defined by your Administrator and enable API Publishers to apply runtime protection. Policy templates support consistent enforcement of customized policy or policy fragments on the API proxy that processes calls to an API.',
      },
      {
        id: 2,
        title: '',
        descriptions: 'Policy templates with the group namespace l7.apim.system are system templates included in API Portal as Gateway bundles having encapsulated assertions. The l7.apim.system - Rate & Quota Policy Template allows you to leverage preconfigured Rate Limit & Quotas, apply the limits to this policy template to protect your API backend.The system template can only be added once to an API.',
      },
      {
        id: 3,
        title: '',
        descriptions: 'You can add one or more policy templates to your API, specify the input values to the selected templates. The policies are applied in the specified order when requests to your API are processed.',
      },
    ],
  },
  specAuthenticationHelp: {
    title: '',
    descriptions: '',
    subItems: [
      {
        id: 1,
        title: '',
        descriptions: 'For REST APIs, the Spec Authentication tab will allow you to configure the authentication settings to test your API via the Spec tab. Note that the Spec Authentication tab and testing via the Spec tab is not available for SOAP APIs.',
      },
    ],
  },
  tagsHelp: {
    title: '',
    descriptions: 'Adding tags is a way to group categories of relevant APIs. Tags surface on the APIs’ list view and card view. Consumers can then search and discover APIs according to their visibility permissions using these tags.',
    subItems: [
      {
        id: 1,
        title: '',
        descriptions: 'You can add up to 25 tags to an API. Maximum tag length is 60 characters.',
      },
      {
        id: 2,
        title: '',
        descriptions: 'Only global and API level tags are added. Endpoint level tags will not be shown.',
      },
    ],
  },
  publishStateHelp: {
    title: '',
    descriptions: 'Select the state of your API. States surface on the APIs’ list view and card view and can be filtered accordingly.',
    subItems: [],
  },
};

export const UNSAVED_DIALOG_TITLE = 'Unsaved Changes';
export const UNSAVED_DIALOG_DESCRIPTIONS = 'Your changes will be lost if you leave this page. Are you sure you want to continue away from this page?';
export const UNSAVED_DIALOG_CANCEL_TEXT = 'Stay';
export const UNSAVED_DIALOG_SUBMIT_TEXT = 'Continue';
export const UNSAVED_DIALOG_EXIT_TEXT = 'Exit';
export const API_DETAILS_UPDATE_SUCCESS = 'Api updated.';
export const API_CUSTOM_FIELDS_UPDATE_SUCCESS = 'Custom Fields updated.';
export const POLICY_TEMPLATE_SAVE_SUCCESS = 'Policy Templates updated.';
export const PUBLISH_STATE_SAVE_SUCCESS = 'Publish state updated.';
export const SPEC_AUTHENTICATION_SAVE_SUCCESS = 'Spec Authentication updated.';
export const VISIBILITY_SAVE_SUCCESS = 'Visibility permissions updated.';
export const MANAGEMENT_SAVE_SUCCESS = 'Management permissions updated.';
export const TAGS_ASSOCIATION_SAVE_SUCCESS = 'Tags association updated.';

export const ID_PRIVATE = '0';
export const ID_PUBLIC = '1';
export const ID_RESTRICTED = '2';
export const API_ACCESS_STATUS_PUBLIC = 'PUBLIC';
export const API_ACCESS_STATUS_PRIVATE = 'PRIVATE';

// VISIBILiTY PAGE
export const VISIBILTY_PERMISSIONS_DATA = [
  {
    id: ID_PUBLIC,
    name: 'Public',
    description: 'The API is visible to users in all organizations.',
  },
  {
    id: ID_PRIVATE,
    name: 'Private',
    description: 'The API is visible to my organization users only (if applicable).',
  },
  {
    id: ID_RESTRICTED,
    name: 'Restricted',
    description: 'Specify which organization\'s users can see this API.',
  },
];

export const VISIBILITY_ORG_TITLE = 'Organizations';
export const VISIBILITY_ORG_SUB_TITLE = 'All available organizations.';
export const VISIBILITY_ORG_PLACE_HOLDER_TEXT = 'Filter by organization name';
export const VISIBILITY_SELECTED_ORG_TITLE = 'Selected';
export const VISIBILITY_SELECTED_ORG_SUB_TITLE = 'Selected organization\'s users can see the API.';
export const VISIBILITY_SELECTED_ORG_PLACE_HOLDER_TEXT = 'Search selected organizations';
export const VISIBILITY_NEXT_TEXT = 'Save & Next';
export const VISIBILITY_ASSIST_TEXT = 'Set permissions and proceed to next step.';
export const VISIBILITY_SELECTED_ITEM_ERROR_MESSAGE = 'Select at least one organization.';

// Management page
export const MANAGEMENT_PERMISSIONS_DATA = [
  {
    id: ID_PUBLIC,
    name: 'Open',
    description: 'Global Publishers with API management permissions can edit this API.',
  },
  {
    id: ID_PRIVATE,
    name: 'Private',
    description: 'Only I can edit this API.',
  },
  {
    id: ID_RESTRICTED,
    name: 'Restricted',
    description: 'Specify users with API management permissions to edit this API.',
  },
];

export const MANAGEMENT_USER_TITLE = 'Users';
export const MANAGEMENT_USER_SUB_TITLE = 'All users that can edit this API.';
export const MANAGEMENT_USER_PLACE_HOLDER_TEXT = 'Filter by user name';
export const MANAGEMENT_SELECTED_USER_TITLE = 'Selected';
export const MANAGEMENT_SELECTED_USER_SUB_TITLE = 'Selected users can edit this API.';
export const MANAGEMENT_SELECTED_USER_PLACE_HOLDER_TEXT = 'Search selected users';
export const MANAGEMENT_SELECTED_ITEM_ERROR_MESSAGE = 'Select at least one user.';
export const ERROR_TITLE_TEXT = 'Unable to carry out the operation. No updates have been made. See details below.';

export const TAGS_TITLE = 'label.api.tags.title';
export const TAGS_SUB_TITLE = 'label.api.tags.subtitle';
export const TAGS_PLACE_HOLDER_TEXT = 'label.api.tags.placeholder';
export const TAGS_SELECTED_TAG_TITLE = 'label.api.tags.title.selected';
export const TAGS_SELECTED_TAG_SUB_TITLE = 'label.api.tags.subtitle.selected';
export const TAGS_ADD_NEW_TAG = 'label.api.tags.add.newtag.text';
export const TAGS_SELECT_SWAGGER_TAGS = 'label.api.tags.select.swagger.tags';
export const TAGS_UNSELECT_SWAGGER_TAGS = 'label.api.tags.clear.swagger.tags';
export const TAGS_SOURCE_SWAGGER_TEXT = 'label.api.tags.source.swagger.swagger';
export const TAGS_SOURCE_PORTAL_TEXT = 'label.api.tags.source.swagger.portal';
export const TAGS_IMPORT_SWAGGER_TAGS_ERROR = 'error.api.tags.import.swagger.tags';
export const TAGS_ASSOCIATION_LIMIT_MSG = 'error.api.tags.max.assoication.limit';
export const TAGS_INVALID_ERROR = 'error.api.tags.validation.new.tag';

export const API_DETAILS_SAVE_SUCCESS = 'Api is created.';
export const ID_REST = '0';
export const ID_SOAP = '1';
export const REST_TYPE = 'REST';
export const SOAP_TYPE = 'SOAP';
export const API_DETAILS_TYPE = [
  {
    id: ID_REST,
    name: 'REST',
    description: '',
  },
  {
    id: ID_SOAP,
    name: 'SOAP',
    description: 'SOAP APIs will not have an interactive spec.',
  },
];
export const REST_FILE_HELPER_TEXT = 'Upload a Swagger or WADL file. Maximum file size is 10MB';
export const SOAP_FILE_HELPER_TEXT = 'Upload a WSDL file (required) and XSD files (optional). Maximum file size is 10MB.';
export const VALIDATION_FILE_NAME_UNIQUE_MESSAGE = 'Unable to upload the file. API asset files with the same name specified.';
export const VALIDATION_ONE_WSDL_MESSAGE = 'Unable to upload the file. Only one wsdl file allowed to upload.';

export const API_SPEC_AUTHENTICATION_TYPE_LABEL = 'Authentication Type';
export const API_SPEC_AUTHENTICATION_TYPE_NONE = 'NONE';
export const API_SPEC_AUTHENTICATION_TYPE_API_KEY = 'APIKEY';
export const API_SPEC_AUTHENTICATION_TYPE_API_KEY_TYPE_QUERY = 'query';
export const API_SPEC_AUTHENTICATION_TYPE_OAUTH2 = 'OAUTH2';
export const API_SPEC_AUTHENTICATION_TYPE_OAUTH2_GRANT_TYPE_CODE = 'CODE';
export const API_SPEC_AUTHENTICATION_TYPES = [
  {
    id: API_SPEC_AUTHENTICATION_TYPE_NONE,
    name: 'None',
  },
  {
    id: API_SPEC_AUTHENTICATION_TYPE_API_KEY,
    name: 'API Key',
  },
  {
    id: API_SPEC_AUTHENTICATION_TYPE_OAUTH2,
    name: 'OAuth 2',
  },
];

export const API_PUBLISH_STATE_LABEL = 'Publish State';
export const API_PUBLISH_STATE_INCOMPLETE = 'INCOMPLETE';
export const API_PUBLISH_STATE_ENABLED = 'ENABLED';
export const API_PUBLISH_STATE_DISABLED = 'DISABLED';
export const API_PUBLISH_STATE_DEPRECATED = 'DEPRECATED';
export const API_PUBLISH_STATE_NEW = 'NEW';

export const API_PUBLISH_STATE_TYPES = [
  {
    id: API_PUBLISH_STATE_ENABLED,
    name: 'Enable',
    description: 'The API can be added to applications and applications can consume them.',
  },
  {
    id: API_PUBLISH_STATE_DISABLED,
    name: 'Disable',
    description: 'The API cannot be added to applications. If an API is already added to an application and the state changes from Enable to Disable, the application cannot continue to consume the API.',
  },
  {
    id: API_PUBLISH_STATE_DEPRECATED,
    name: 'Deprecate',
    description: 'The API cannot be added to applications. If an API is already added to an application and the state of the API changes from Enable to Deprecate, the application can continue to consume the API.',
  },
];

export const SUCCESS_TEXT = 'success';
export const ERROR_TEXT = 'error';

export const NEXT_TEXT = 'Next';
export const CANCEL_TEXT = 'Cancel';
export const SAVE_AND_NEXT_TEXT = 'Save & Next';
export const SAVE_TEXT = 'Save';

export const API_CUSTOM_FIELDS_ASSIST_TEXT = 'Set Custom Fields and proceed to next step.';

export const API_MANAGING_ORG_POPUP_TITLE = 'label.api.managing.org.retain.visibility.title';
export const API_MANAGING_ORG_POPUP_MESSAGE = 'label.api.managing.org.retain.visibility.message';
export const API_MANAGING_ORG_POPUP_SUBMIT_TEXT = 'label.api.managing.org.retain.visibility.submit.text';
export const API_MANAGING_ORG_POPUP_CANCEL_TEXT = 'label.api.managing.org.retain.visibility.cancel.text';

