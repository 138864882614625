import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { get, map, isEmpty } from 'lodash';
import clsx from 'clsx';
import { bool, object, arrayOf, func } from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Collapse,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  AlertDialog,
} from '../../../../components';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import styles from './styles';
import { ALERT_ERROR, SYSTEM_BUNDLE_GROUP_NAME } from '../../../../constants';

export const processErrors = (errors, defaultMessage) => {
  let errorMessage;
  if (errors.length > 0) {
    errorMessage = map(errors, (validationError) => validationError.error).join('\n');
  }
  return errorMessage || defaultMessage;
};

export const ActionsRaw = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    gatewayBundleDetails,
    notifyMessages,
    deleteBundleErrors,
    isBundleDeleteSuccess,
  } = props;

  const intl = getI18n(useIntl());
  const [open, setOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const bundleUuid = get(gatewayBundleDetails, 'uuid');
  const bundleName = get(gatewayBundleDetails, 'name');
  const deletable = get(gatewayBundleDetails, 'deletable', true);
  const isSystemBundle = get(gatewayBundleDetails, 'groupName') === SYSTEM_BUNDLE_GROUP_NAME;

  const handleClick = () => { setOpen(!open); };
  const handleClickAway = () => { setOpen(false); };
  const openDeleteDialog = () => setShowDeleteDialog(true);
  const closeDeleteDialog = () => setShowDeleteDialog(false);

  const onDelete = () => {
    props.deleteGatewayBundle(bundleUuid);
    closeDeleteDialog();
  };

  const actions = [
    {
      key: 'delete',
      value: getI18nFormattedMessage('label.gateway.bundle.delete.button'),
      onClick: () => {
        if (deletable) { openDeleteDialog(); }
      },
      visible: true,
      disabled: !deletable,
      disabledTooltip: !deletable ? isSystemBundle ? getI18nFormattedMessage('label.system.bundle.actions.delete.disabled.tooltip'):
      getI18nFormattedMessage('label.gateway.bundle.actions.delete.disabled.tooltip') : '',
    },
  ];

  useEffect(() => {
    if (isBundleDeleteSuccess) { props.push('/publish/gateway-bundles'); }
  }, [isBundleDeleteSuccess]);

  useEffect(() => {
    if (!isEmpty(deleteBundleErrors)) {
      notifyMessages(ALERT_ERROR, processErrors(deleteBundleErrors, intl.getI18nMessage('error.gateway.bundle.delete')));
    }
  }, [deleteBundleErrors]);

  return (
    <div
      id="gateway-bundle-details-actions-container"
      data-apim-test={'gateway-bundle-details-actions-container'}
      className={classes.actionsContainer}
    >
      {showDeleteDialog &&
        <AlertDialog
          id="gateway-bundle-details-delete-dialog"
          isOpen={showDeleteDialog}
          title={intl.getI18nMessage('label.delete.confirmation.title')}
          description={intl.getI18nMessage('label.gateway.bundle.delete.confirmation.text', { bundleName })}
          submitText={intl.getI18nMessage('label.delete.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={closeDeleteDialog}
          onSubmit={onDelete}
          onCancel={closeDeleteDialog}
          submitButtonClass={classes.submitButton}
        />
      }
      <ClickAwayListener onClickAway={handleClickAway}>
        <List component="nav">
          <ListItem id="gateway-bundle-details-actions-button" data-apim-test="gateway-bundle-details-actions-button" classes={{ root: classes.listButton }} button onClick={handleClick}>
            <ListItemText primary={getI18nFormattedMessage('label.actions.button')} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse className={classes.collapseContainer} in={open}>
            <List className={classes.list} component="div" disablePadding>
              {actions
                .filter(({ visible }) => visible)
                .map(({ key, value, disabled, disabledTooltip, onClick }) => (
                  <Tooltip
                    arrow disableFocusListener disableTouchListener placement="top"
                    title={disabledTooltip}
                    key={key}
                  >
                    <ListItem
                      id={key}
                      data-apim-test={key}
                      button
                      className={clsx('action', classes.listItem)}
                      disabled={disabled}
                      onClick={onClick}
                    >
                      <ListItemText primary={value} />
                    </ListItem>
                  </Tooltip>
              ))}
            </List>
          </Collapse>
        </List>
      </ClickAwayListener>
    </div>
  );
};

ActionsRaw.propTypes = {
  gatewayBundleDetails: object,
  notifyMessages: func,
  deleteBundleErrors: arrayOf(object),
  isBundleDeleteSuccess: bool,
  deleteGatewayBundle: func,
  push: func,
};

ActionsRaw.displayName = 'Actions';

export default ActionsRaw;
