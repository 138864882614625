import get from 'lodash/get';
import { modifyAlpha } from '../../../../utils/modifyColour';

const styles = ({ breakpoint, color, margin, padding, theme }) => ({
  textFieldContainer: {
    margin: `${get(margin, 'm0')} !important`,
    marginRight: `${get(margin, 'm8')} !important`,
  },
  fieldContainer: {
    marginTop: 24,
    marginRight: `${get(margin, 'm8')} !important`,
  },
  actionsFieldContainer: {
    marginTop: `${get(margin, 'm24')} !important`,
  },
  pageFilterAndSort: {
    justifyContent: 'space-between',
    paddingTop: 0,
    marginTop: 0,
    paddingBottom: 0,
  },
  apiStatusField: {
    display: 'flex',
  },
  pageClass: {
    margin: 0,
    marginBottom: 84,
    minHeight: 'calc(100vh - 340px)',
  },
  statusIcon: {
    height: 18,
    marginRight: 4,
    verticalAlign: 'middle',
    width: 18,
  },
  pageBodyClass: {
    padding: get(padding, 'p0'),
  },
  helpIcon: {
    color: color.primaryButtonBackground,
    marginLeft: 6,
    marginTop: 3,
    height: 16,
    width: 16,
  },
  tagsContainer: {
    marginTop: `${get(margin, 'm31')} !important`,
    marginRight: `${get(margin, 'm8')} !important`,
    flexBasis: '33%',
  },
  greyTick: {
    color: `${modifyAlpha(get(color,'bodyText'), 0.5)}`,
  },
  blueTick: {
    color: color.primaryButtonBackground,
  },
  managedContainer: {
    backgroundColor: get(color, 'gray'),
    padding: get(padding, 'p5'),
    borderRadius: get(padding, 'p10'),
    marginLeft: get(margin, 'm2'),
  },
  textFiltersContainer: {
    display: 'flex',
    width: '80%',
    marginTop: 24,
  },
  helpIconContainer: {
    display: 'flex',
  },
  tagButtons: {
    marginTop: get(margin, 'm31'),
  },
  addTagsButton: {
    margin: `0 ${get(margin, 'm2')}`,
  },
  removeTagsButton: {
    backgroundColor: get(color, 'error'),
    borderColor: get(color, 'error'),
    margin: `0 ${get(margin, 'm2')}`,
    '&:hover': {
      backgroundColor: get(color, 'errorHover'),
    },
  },
  headerButtons: {
    marginTop: get(margin, 'm15'),
    marginBottom: get(margin, 'm24'),
  },
  actionsLabel: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontWeight: 700,
    paddingLeft: 0,
    marginTop: get(margin, 'm24'),
  },
  addButton: {
    margin: `0 ${get(margin, 'm2')}`,
  },
  removeButton: {
    backgroundColor: get(color, 'error'),
    borderColor: get(color, 'error'),
    margin: `0 ${get(margin, 'm2')}`,
    '&:hover': {
      backgroundColor: get(color, 'errorHover'),
    },
  },
  defaultButton: {
    padding: 0,
    justifyContent: 'flex-start',
    height: '10px',
  },
  switchAccess: {
    height: '20px',
    '& .MuiSwitch-root': {
      height: '23px',
    },
  },
  pageBulkActionsClose: {
    marginTop: 10,
  },
  tooltipContainer: {
    backgroundColor: get(color, 'gray'),
    padding: get(padding, 'p5'),
    borderRadius: get(padding, 'p10'),
    marginLeft: get(margin, 'm4'),
  },
  submitButton: {
    marginLeft: '80px !important',
  },
  dialogContainer: {
    height: 640,
  },
  dialogActionClass: {
    justifyContent: 'flex-start',
  },
  [`@media (max-width: ${get(breakpoint, 'sm')})`]: {
    textFiltersContainer: {
      display: 'block',
    },
  },
  [`@media (max-width: ${get(breakpoint, 'md')})`]: {
    textFiltersContainer: {
      width: '100%',
    },
  },
});

export default styles;
