import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import BlockIcon from '@material-ui/icons/Block';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import theme from './index.theme';

function Requests({
  classes = {},
  requestItems = [],
}) {
  const [anchorEl = false, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.HeaderRequestsButton}>
      <IconButton className={classes.button} onClick={handleClick}>
        <NotificationsIcon fontSize="small" />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {isEmpty(requestItems) &&
          <Paper>
            <div className={classes.Menu}>
              <div className={classes.HeaderRequestsNoRequestsIcon}>
                <BlockIcon />
              </div>
              <div className={classes.HeaderRequestsNoRequestsText}>
                No Requests
              </div>
              <div className={classes.HeaderRequestsNoRequestsMessage}>
                All active requests have been resolved
              </div>
            </div>
          </Paper>
        }
        {!isEmpty(requestItems) &&
          <Paper>
            <div className={classes.Menu}>
              <div className={classes.HeaderRequestsMenuWrapper}>
                <div className={clsx(classes.SubHeader, classes.SubHeaderUppercase)}>
                  Requests
                </div>
                {requestItems.map(requestItem => (
                  <a
                    className={classes.HeaderRequestsMenuItem}
                    href={`//${window.location.hostname}/admin/requests#${requestItem.RequestType === 'APPLICATION'
                      ? 'application-details' : 'registration-details'}/${requestItem.Uuid}`}
                    key={requestItem.Uuid}
                  >
                    <span>
                      {requestItem.RequestType.charAt(0) +
                        requestItem.RequestType.slice(1).toLowerCase()
                      }
                    </span>
                  </a>
                ))}
              </div>
            </div>
            <div className={classes.HeaderRequestsViewMore}>
              <a href={`//${window.location.hostname}/admin/requests`}>
                <span>View all Requests</span>
              </a>
            </div>
          </Paper>
        }
      </Popover>
    </div>
  );
}


Requests.propTypes = {
  classes: PropTypes.shape(),
  requestItems: PropTypes.arrayOf(PropTypes.shape()),
};

export default withStyles(theme)(Requests);
