import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ChartContainer from '../ChartContainer';
import styles from './styles';

import { readCookie, createCookie } from '../../../utils/cookie';

export default function Charts(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { fetchDashboardChartData, isOrgPublisher, isPortalAdmin, isApiOwner } = props;
  const defaultPanels = isOrgPublisher || isPortalAdmin ? ['api', 'org', 'proxy', 'app'] : ['api', 'app'];
  const [panels, setPanels] = useState(defaultPanels);
  const defaultPanelObject = { isCollapsed: false, isChart: true };
  const defaultPanelData = { api: defaultPanelObject, app: defaultPanelObject };
  if (isOrgPublisher || isPortalAdmin) {
    defaultPanelData.org = defaultPanelObject;
    defaultPanelData.proxy = defaultPanelObject;
  }
  const [panelData, setPanelData] = useState(defaultPanelData);
  useEffect(() => {
    const cookieStr = readCookie('panels');
    if (cookieStr) {
      const cookie = JSON.parse(cookieStr);
      if (cookie.cookiePanels) {
        setPanels(cookie.cookiePanels);
      }
      if (cookie.cookiePanelData) {
        setPanelData(cookie.cookiePanelData);
      }
    }
  }, []);

  const updateCookie = (cookiePanels, cookiePanelData) => {
    const cookieObj = {
      cookiePanels, cookiePanelData,
    };
    createCookie('panels', JSON.stringify(cookieObj), 365, '/admin');
  };
  const movePanel = (panelName, up) => {
    const index = panels.indexOf(panelName);
    const moveTo = up ? index - 1 : index + 1;
    if (!(up && index === 0) && !(!up && index === panels.length - 1)) {
      const updatePanels = JSON.parse(JSON.stringify(panels));
      updatePanels.splice(moveTo, 0, updatePanels.splice(index, 1)[0]);
      setPanels(updatePanels);
      updateCookie(updatePanels, panelData);
    }
  };
  const collapsePanel = panelName => {
    const tempPanelData = JSON.parse(JSON.stringify(panelData));
    tempPanelData[panelName].isCollapsed = !tempPanelData[panelName].isCollapsed;
    setPanelData(tempPanelData);
    updateCookie(panels, tempPanelData);
  };
  const chartToggled = panelName => {
    const tempPanelData = JSON.parse(JSON.stringify(panelData));
    tempPanelData[panelName].isChart = !tempPanelData[panelName].isChart;
    setPanelData(tempPanelData);
    updateCookie(panels, tempPanelData);
  };
  return (
    <div className={classes.charts}>
      {panels.map((name, index) => (
        <ChartContainer
          chartEntity={name}
          chartObject={panels[name]}
          onMovePanel={movePanel}
          onCollapse={collapsePanel}
          onChartToggle={chartToggled}
          panelData={panelData[name] || { isCollapsed: false, isChart: true }}
          chartIndex={index}
          key={name}
          chartsCount={panels.length}
          fetchDashboardChartData={fetchDashboardChartData}
          chartData={props.chartData[name]}
          isAnalyticsEnabled={props.isAnalyticsEnabled}
          isApiOwnerOrOrgPub={isApiOwner || isOrgPublisher}
          isOrgPublisher={isOrgPublisher}
        />
      ))}
    </div>
  );
}

Charts.propTypes = {
  fetchDashboardChartData: PropTypes.func,
  isOrgPublisher: PropTypes.bool,
  isPortalAdmin: PropTypes.bool,
  isApiOwner: PropTypes.bool,
  isAnalyticsEnabled: PropTypes.bool,
  chartData: PropTypes.object,
};

