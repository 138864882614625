import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  withStyles,
  Grid,
} from '@material-ui/core';
import { shape, bool, arrayOf, func } from 'prop-types';

import {
  AlertMessages,
  FormTextField,
  FormActionButtons,
  Loading,
  ErrorContainer,
  RichTextEditor,
} from '../../../components';
import {
  fetchEula,
  saveEula,
  updateEula,
  checkEulaNameUnique,
} from '../../../actions/eula';
import { getUserDetails } from '../../../reducers/portalConfig';
import {
  getIsLoading,
  getIsError,
  getErrors,
  getIsEulaSaveSuccess,
  getEulaDetails,
  getIsEulaNameUnique,
} from '../../../reducers/eula';
import { hasError, checkEmptyHtmlTags } from '../../../utils';
import { getI18n } from '../../../utils/intl';
import {
  ALERT_ERROR,
} from '../../../constants';
import styles from './styles';

const MAX_CONTENT_LENGTH = 200000;
const getEulaUuid = (props) => get(props, 'match.path')
  && get(props, 'match.path').includes('/edit/')
  && get(props, 'match.params.eulaUuid');

export const EulaEdit = (props) => {
  const {
    classes,
    userContext,
    isLoading,
    isEulaNameUnique,
    eulaErrors,
    isError,
    isEulaSaveSuccess,
    eulaDetails,
   } = props;

  const eulaUuid = getEulaUuid(props) || '';

  const getHeaderTitle = () => {
    if (eulaUuid && eulaDetails) {
      return `Edit EULA: ${eulaDetails.name}`;
    }
    return 'Add EULA';
  };

  const intl = getI18n(useIntl());
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [isNameError, setIsNameError] = useState(false);
  const [isNameUniqueError, setIsNameUniqueError] = useState(false);
  const [isContentError, setIsContentError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [headerTitle, setHeaderTitle] = useState(getHeaderTitle());

  const notifyMessages = (message, status) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  const clearErrorMessages = () => {
    notifyMessages('', '');
    setErrors([]);
  };

  useEffect(() => {
    if (!eulaUuid && !get(userContext, 'permissions.API_EULA', []).includes('CREATE')) {
      props.push('/404');
    } else if (eulaUuid && !get(userContext, 'permissions.API_EULA', []).includes('UPDATE')) {
      props.push('/404');
    }
  }, [userContext,eulaUuid]);

  useEffect(() => {
    if (eulaUuid) {
      props.fetchEula(eulaUuid);
    }
  }, [eulaUuid]);

  useEffect(() => {
    if(eulaDetails && eulaDetails.uuid){
      setName(eulaDetails.name);
      setContent(eulaDetails.content);
      setHeaderTitle(getHeaderTitle());
    }
  }, [eulaDetails]);
  
  useEffect(() => {
    setIsNameUniqueError(!isEulaNameUnique);
  }, [isEulaNameUnique]);

  useEffect(() => {
    if (isError) {
      notifyMessages(intl.getI18nMessage('error.title'), ALERT_ERROR);
      setErrors(eulaErrors);
    } else if(!isNameError && !isNameUniqueError && !isContentError) {
      clearErrorMessages();
    }
  }, [isError, eulaErrors]);

  useEffect(() => {
    if (isEulaSaveSuccess) {
      clearErrorMessages();
      props.push(`/publish/eulas/details/${eulaDetails.uuid}`);
    }
  }, [isEulaSaveSuccess, eulaDetails]);

  const onCheckEulaNameUnique = async (name, currentId) => {
    await props.checkEulaNameUnique(name, currentId);
  };

  const handleChange = (value) => {
    setName(value);
    setIsNameError(hasError(value, true));
  };

  const handleBlur = (value) => {
    if (value) {
      onCheckEulaNameUnique(value, eulaUuid);
    } else {
      setIsNameError(true);
    }
  };

  const handleContent = (value) => {
    setContent(value);
    if(checkEmptyHtmlTags(value)) {
      setIsContentError(true);
    } else {
      setIsContentError(hasError(value, true, MAX_CONTENT_LENGTH));
    }
  };

  const onCancel = () => {
    clearErrorMessages();
    window.location.href = '/publish/eulas/';
  };
  const hasValidSaveButton = () => {
    const nameUiError = hasError(name, true);
    const contentUiError = checkEmptyHtmlTags(content) ?
      true : hasError(content, true, MAX_CONTENT_LENGTH);
    setIsNameError(nameUiError);
    setIsContentError(contentUiError);
    if(nameUiError || contentUiError) {
      notifyMessages(intl.getI18nMessage('error.required.fields.missing'), ALERT_ERROR);
    }
    if(isNameUniqueError) {
      notifyMessages(intl.getI18nMessage('error.eula.field.unique.name'), ALERT_ERROR);
    }
    return nameUiError || contentUiError || isNameUniqueError;
  };

  const onNext = async () => {
    clearErrorMessages();
    if (!hasValidSaveButton()) {
      if(eulaUuid) {
        await props.updateEula(name, content, eulaUuid);
      } else {
        await props.saveEula(name, content);
      }
    }
  };

  return (
    <div
      className={classes.content}
      id="eula-edit-page"
      data-apim-test="eula-edit-page"
    >
      {isLoading && <Loading pageLoader /> }
      <Grid container className={classes.gridContainer}>
        <Grid
          md={2}
          item
          className={classes.leftSideBar}
          id="eula-edit-left-sidebar"
          data-apim-test="eula-edit-left-sidebar"
        />
        <Grid
          md={10}
          item
          className={classes.mainContent}
          id="eula-edit-main-content"
          data-apim-test="eula-edit-main-content"
        >
          {notificationMessage &&
            <AlertMessages
              id="eula-edit-notification"
              data-apim-test="eula-edit-notification"
              message={notificationMessage}
              variant={notificationStatus}
              onClose={() => {
                notifyMessages('', '');
              }}
            />
          }
          {errors.length > 0 &&
            <ErrorContainer errors={errors} />
          }
          <h1 className={classes.pageTitle}>{headerTitle}</h1>
            <FormTextField
              id={'eula-edit-page-name'}
              name={'EULA Name'}
              value={name}
              error={isNameError || isNameUniqueError}
              handleChange={handleChange}
              handleBlur={handleBlur}
              helperText={intl.getI18nMessage('label.eula.name.help')}
            />
            <RichTextEditor
              id={'eula-edit-page-content'}
              content={content}
              handleContent={handleContent}
              error={isContentError}
              helperText={intl.getI18nMessage('label.eula.editor.help')}
            />
        </Grid>
      </Grid>
      <FormActionButtons
        onNextClick={onNext}
        onCancelClick={onCancel}
        nextText={intl.getI18nMessage('label.save.button')}
        cancelText={intl.getI18nMessage('label.cancel.button')}
        id="eula-form-buttons"
      />
    </div>
  );
};

const mapStateToProps = state => ({
  isError: getIsError(state),
  isLoading: getIsLoading(state),
  eulaErrors: getErrors(state),
  isEulaNameUnique: getIsEulaNameUnique(state),
  isEulaSaveSuccess: getIsEulaSaveSuccess(state),
  eulaDetails: getEulaDetails(state),
  userContext: getUserDetails(state),
});

const mapDispatchToProps = {
  fetchEula,
  saveEula,
  updateEula,
  checkEulaNameUnique,
  push,
};

EulaEdit.propTypes = {
  classes: shape({}),
  assets: shape({}),
  isLoading: bool,
  isError: bool,
  isEulaSaveSuccess: bool,
  isEulaNameUnique: bool,
  eulaErrors: arrayOf(shape({})),
  fetchEula: func,
  saveEula: func,
  updateEula: func,
  checkEulaNameUnique: func,
  push: func,
  userContext: shape({}),
  eulaDetails: shape({}),
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(EulaEdit);
