import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styles from './styles';
import Header from '../Header';
import ScrollBar from '../EntityScrollBar';
import Charts from '../Charts';
import {
  getIsPortalAdmin,
  getIsOrgPublisher,
  getIsApiOwner,
  getIsOnlyPortalAdmin,
} from '../../../reducers/portalConfig';
import {
  Footer,
} from '../../../components';

const Dashboard = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    fetchDashboardEntityCount,
    entities,
    fetchDashboardChartData,
    isOrgPublisher,
    isPortalAdmin,
    isApiOwner,
  } = props;
  const [isLoading, setLoading] = useState(true);
  const isAnalyticsEnabled = window.analyticsEnabled;
  useEffect(() => {
    fetchDashboardEntityCount().then(() => { setLoading(false); });
  }, [fetchDashboardEntityCount]);

  return (
    <div>
      <div
        className={classes.dashboard}
        id="dashboard-main"
        data-apim-test="dashboard-main"
      >
        <Header {...props} />
        {isLoading ? (
          <Skeleton variant="rect" animation="wave" height={116} width="100%" />
        ) : (
          <ScrollBar entities={entities} isPortalAdmin={isPortalAdmin} />
        )}
        <Charts
          chartData={props.chartData}
          fetchDashboardChartData={fetchDashboardChartData}
          isAnalyticsEnabled={isAnalyticsEnabled}
          isOrgPublisher={isOrgPublisher}
          isApiOwner={isApiOwner}
          isPortalAdmin={isPortalAdmin}
        />
      </div>
      <Footer />
    </div>
  );
};
export const mapStateToProps = state => ({
  isPortalAdmin: getIsPortalAdmin(state),
  isOrgPublisher: getIsOrgPublisher(state),
  isApiOwner: getIsApiOwner(state),
  isOnlyPortalAdmin: getIsOnlyPortalAdmin(state),
});

Dashboard.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.shape({})),
  fetchDashboardEntityCount: PropTypes.func,
  fetchDashboardChartData: PropTypes.func,
  isOrgPublisher: PropTypes.bool,
  isPortalAdmin: PropTypes.bool,
  isApiOwner: PropTypes.bool,
  chartData: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default compose(
  connect(mapStateToProps),
)(Dashboard);
