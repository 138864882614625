import get from 'lodash/get';

const styles = ({ margin, padding, color }) => ({
  fieldContainer: {
    margin: `${get(margin, 'm0')} !important`,
    marginRight: `${get(margin, 'm8')} !important`,
  },
  pageContainer: {
    display: 'flex',
    height: '100%',
    minHeight: '550px',
  },
  treeContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: get(margin, 'm0'),
    marginRight: get(margin, 'm0'),
    marginBottom: get(margin, 'm0'),
    padding: get(padding, 'p0'),
    paddingRight: get(padding, 'p0'),
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '20%',
    borderStyle: 'solid',
    borderColor: get(color, 'gray'),
    borderWidth: '0px 1px 0px 0px',
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: `rgba(17, 69, 153, 0.08) !important`,
      padding: 2,
    },
  },
  tree: {},
  treeToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  documentation: {
    marginTop: get(margin, 'm0'),
    marginBottom: get(margin, 'm0'),
    marginLeft: get(margin, 'm0'),
    padding: get(padding, 'p0'),
    flexGrow: 1,
  },
  localeButton: {
    width: '100%',
    justifyContent: 'space-between',
  },
  addRootDocumentationButton: {
    color: `${get(color, 'primary')} !important`,
  },
  leftIcon: {
    marginRight: get(margin, 'm0'),
  },
  button: {
    '& .MuiButton-root': {
      backgroundColor: '#ffffff !important',
      color: `${get(color, 'gray')} !important`,
    },
  },
  submitButton: {
    backgroundColor: `${get(color, 'red')} !important`,
    borderColor: `${get(color, 'red')} !important`,
  },
});

export default styles;
