import React from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import { bool, shape } from 'prop-types';
import cx from 'classnames';

import styles from './styles';

const getContainerClass = ({
  classes = {},
  pageLoader = false,
  sectionLoader = false,
}) => {
  if (pageLoader) {
    return cx(classes.root, classes.pageLoader);
  } else if (sectionLoader) {
    return cx(classes.root, classes.sectionLoader);
  } else {
    return cx(classes.root, classes.progress);
  }
}

export const Loading = (props) => (
  <div
    className={getContainerClass(props)}
    id="apim-loading"
    data-apim-test="apim-loading"
    data-layer7-test="apim-loading"
  >
    <CircularProgress disableShrink />
  </div>
);

Loading.propTypes = {
  classes: shape({}),
  pageLoader: bool,
  sectionLoader: bool,
};
Loading.displayName = 'Loading';

export default (
  withStyles(styles)
)(Loading);
