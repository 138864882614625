import { get } from 'lodash';

const styles = (theme) => ({
  dialogContainer: {
    minWidth: 400,
    minHeight: 200,
    backgroundColor: get(theme, 'color.uiBackground'),
  },
  title: {
    margin: 0,
  },
  dialogTitle: {
    display: 'inline-flex',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  progress: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: get(theme, 'color.primaryButtonBackground'),
  },
});

export default styles;
