import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import {
  withStyles,
} from '@material-ui/core';

import { DetailsHeader } from '../../../../components';
import { getI18nFormattedMessage } from '../../../../utils/intl';
import styles from './styles';

export const HeaderRaw = (props) => {
  const {
    gatewayBundleDetails,
  } = props;
  const { name, version } = gatewayBundleDetails;

  return (
    <DetailsHeader
      headerId="gateway-bundle-details-header-container"
      title={getI18nFormattedMessage('label.gateway.bundle.details.page.title')}
      name={`${name}-${version}`}
    />
  );
};

HeaderRaw.propTypes = {
  gatewayBundleDetails: PropTypes.object,
};
HeaderRaw.displayName = 'Header';

export default compose(
  withStyles(styles),
)(HeaderRaw);
