import get from 'lodash/get';
import { convertHexToRGB } from '../../utils';

const styles = (theme) => ({
  fieldContainer: {
    marginTop: 20,
    marginBottom: 28,
  },
  root: {
    width: '100%',
    '& .MuiInputLabel-outlined': {
      fontWeight: get(theme, 'fontWeight.normal'),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transform: 'translate(10px, 18px) scale(1)',
      whiteSpace: get(theme, 'whiteSpace.nowrap'),
      width: `calc(100% - ${get(theme, 'margin.m60')})`,
    },
    '& .MuiInputBase-root': {
      padding: '0 10px',
      '& input': {
        height: get(theme, 'height.h40'),
        padding: '0 10px !important',
      },
      '& fieldset': {
        top: 0,
        '& legend': {
          width: 'auto !important',
          display: 'none',
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: convertHexToRGB(get(theme, 'color.primaryButtonBackground'), 0.4),
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.primaryButtonHover'),
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.primaryButtonBackground'),
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.error'),
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      backgroundColor: convertHexToRGB(get(theme, 'color.bodyText'), 0.1),
      borderColor: convertHexToRGB(get(theme, 'color.bodyText'), 0.5),
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      display: 'none',
    },
  },
  error: {
    '& fieldset': {
      borderColor: `${get(theme, 'color.error')} !important`,
    },
    color: get(theme, 'color.error'),
  },
  formLabelContainer: {
    width: '98%',
  },
  hideFormLabel: {
    visibility: 'hidden',
  },
  textField: {
    padding: `${get(theme, 'padding.p10')} 0`,
  },
  secondaryText: {
    color: `${get(theme, 'color.secondaryText')} !important`,
    fontFamily: `${get(theme, 'typography.smallText')} !important`,
    fontSize: `${get(theme, 'fontSize.smallText')} !important`,
  },
  helperText: {
    color: `${get(theme, 'color.smallText')} !important`,
    fontFamily: `${get(theme, 'typography.smallText')} !important`,
    fontSize: `${get(theme, 'fontSize.smallText')} !important`,
  },
  tooltipContainer: {
    backgroundColor: get(theme, 'color.gray'),
    padding: get(theme, 'padding.p5'),
    borderRadius: get(theme, 'padding.p10'),
    marginLeft: get(theme, 'margin.m4'),
  },
});

export default styles;
