import { get } from 'lodash';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: get(theme, 'margin.m10'),
    marginBottom: get(theme, 'margin.m21'),
    color: get(theme, 'color.bodyText'),
  },
  tableTitle: {
    color: get(theme, 'color.pageTitle'),
    fontFamily: get(theme, 'typography.pageTitle'),
    fontSize: get(theme, 'typography.title'),
    marginBottom: get(theme, 'margin.m2'),
  },
  tableSubtitle: {
    color: get(theme, 'color.pageTitle'),
    marginBottom: get(theme, 'margin.m10'),
  },
  table: {
    '& tbody': {
      cursor: 'pointer',
    },
    '& th': {
      padding: get(theme, 'padding.p10'),
    },
    '& .MuiTableRow-root.Mui-selected': {
      '& td': {
        fontWeight: get(theme, 'fontWeight.semiBold'),
      },
    },
  },
  radioRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export default styles;
