import get from 'lodash/get';

const styles = ({ breakpoint, color, margin, typography, padding, lineHeight }) => ({
  listContainer: {
    paddingTop: '0 !important',
  },
  fieldContainer: {
    margin: `${get(margin, 'm0')} !important`,
    marginRight: `${get(margin, 'm8')} !important`,
  },
  sortContainerClass: {
    padding: '10px 0',
    width: '100%',
    '& button': {
      lineHeight: get(lineHeight, 'lh20'),
    },
    '& .MuiButton-outlinedPrimary:hover': {
      backgroundColor: `${get(color, 'primaryButtonBackground')} !important`,
      color: get(color, 'primaryButtonText'),
    },
  },
  buttonContainer: {
    paddingLeft: get(padding, 'p16'),
  },
  headerButtons: {
    marginTop: get(margin, 'm5'),
    marginBottom: get(margin, 'm10'),
  },
  addButton: {
    margin: `0 ${get(margin, 'm2')}`,
  },
  removeButton: {
    backgroundColor: get(color, 'error'),
    borderColor: get(color, 'error'),
    margin: `0 ${get(margin, 'm2')}`,
    '&:hover': {
      backgroundColor: get(color, 'errorHover'),
    },
  },
  tooltipContainer: {
    backgroundColor: get(color, 'gray'),
    padding: get(padding, 'p5'),
    borderRadius: get(padding, 'p10'),
    marginLeft: get(margin, 'm4'),
  },
  activeButton: {
    textTransform: 'none',
    color: get(color, 'primaryButtonText'),
    backgroundColor: get(color, 'primaryButtonBackground'),
    borderColor: get(color, 'primaryButtonBackground'),
    lineHeight: get(lineHeight, 'lh20'),
  },
  submitButton: {
    marginLeft: '80px !important',
  },
  dialogContainer: {
    height: 640,
  },
  dialogActionClass: {
    justifyContent: 'flex-start',
  },
  actionsLabel: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontWeight: 700,
    paddingLeft: 0,
  },
  defaultButton: {
    padding: 0,
    justifyContent: 'flex-start',
    height: '10px',
  },
  switchAccess: {
    height: '20px',
    '& .MuiSwitch-root': {
      height: '23px',
    },
  },
  [`@media (max-width: ${get(breakpoint, 'sm')})`]: {
    tagButtons: {
      marginTop: 0,
    },
  },
});

export default styles;
