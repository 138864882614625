import axios from 'axios';
import { debounce } from 'lodash';
import { stringify } from 'query-string';

import { DEBOUNCE_DELAY } from '../config/constants';

export const debouncedAxiosGet = debounce(
  (url, options, _then, _catch) => axios.get(url, options)
    .then(_then)
    .catch(_catch),
  DEBOUNCE_DELAY,
);

export const stringifyUrl = ({ url, query, options } = {}) => {
  if (query) {
    const params = stringify(query, options);
    return `${url}?${params}`;
  }
  return url;
};

export default {};
