import { get } from 'lodash';
import { convertHexToRGB } from '../../../../utils';

const styles = ({ borderRadius, color }) => ({
  topPanelContainer: {
    backgroundColor: `${convertHexToRGB(get(color, 'borderColor'), 0.1)}`,
    minHeight: '120px',
    padding: '0 36px',
  },
  marginPanelContainer: {
    minHeight: '350px',
  },
  icon: {
    padding: 0,
    marginRight: 8,
    marginTop: '-3px',
  },
  portalCompatibility: {
    marginTop: 14,
  },
  compatible: {
    color: get(color, 'success'),
  },
  incompatible: {
    color: get(color, 'danger'),
  },
  countMismatch: {
    color: get(color, 'danger'),
    borderColor: 'black',
  },
  divider: {
    backgroundColor: get(color, 'borderColor'),
  },
  paper: {
    border: `1px solid ${get(color, 'borderColor')}`,
  },
  selectOrganization: {
    margin: '0 !important',
    '& label + div': {
      borderRadius: `${get(borderRadius, 'br4')} 0 0 ${get(borderRadius, 'br4')}`,
    },
  },
  selectOrganizationGo: {
    borderRadius: `0 ${get(borderRadius, 'br3')} ${get(borderRadius, 'br3')} 0`,
    margin: '31px 0',
  },
});

export default styles;
