import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { arrayOf, bool, func, shape } from 'prop-types';
import { get } from 'lodash';
import { push } from 'connected-react-router';

import {
  Footer, TabPanel, TabsContainer,
} from '../../../components';
import { getTabIndex, hasPublisherRole, hasOrgPublisherRole } from '../../../utils';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import {
  fetchEula,
  fetchEulaApis,
  deleteEula,
} from '../../../actions/eula';
import {
  getEulaDetails,
  getIsEulaDeleteSuccess,
  getIsError,
  getErrors,
  getIsLoading,
  getDeleteEulaErrors,
  getEulaApisListResults,
} from '../../../reducers/eula';
import {
  getConfig,
  getUserDetails,
} from '../../../reducers/portalConfig';
import { DetailsHeader } from '../../../components';
import DetailsContainer from '../../details';
import Overview from './Overview';
import Actions from './Actions';

export const getEulaUuid = (props) => get(props, 'match.path')
  && get(props, 'match.path').includes('/details/')
  && get(props, 'match.params.eulaUuid');

const getTabItems = () => [
  {
    id: 'overview',
    tabId: 'eula-overview-tab',
    label: getI18nFormattedMessage('label.eula.details.page.overview.title'),
    visible: true,
  },
];
export const EulaDetails = (props) => {
  const {
    isLoading,
    userContext,
    eulaDetails,
    pageErrors,
    isError,
  } = props;

  const eulaUuid = get(props, 'match.params.eulaUuid');
  const tabItems = getTabItems();

  const intl = getI18n(useIntl());
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [errors, setErrors] = useState([]);
  const [currentTab, setCurrentTab] = useState(
    getTabIndex(tabItems, props.match),
  );

  useEffect(() => {
    if (!hasPublisherRole(userContext) && !hasOrgPublisherRole(userContext)) { props.push('/404'); }
  }, [userContext]);

  useEffect(() => {
    props.fetchEula(eulaUuid);
    props.fetchEulaApis(eulaUuid);
  }, []);

  useEffect(() => {
    setCurrentTab(getTabIndex(tabItems, props.match));
  }, [props.match]);

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  useEffect(() => {
    setErrors(isError ? pageErrors : []);
  }, [isError, pageErrors]);

  const handleTabChange = (e, newValue) => {
    setCurrentTab(newValue);
  };
 
  const hasActionButton = get(userContext, 'permissions.API_EULA',[])
  && (userContext.permissions.API_EULA.includes('DELETE') || userContext.permissions.API_EULA.includes('UPDATE'))

  return (
    <DetailsContainer
      detailsPageId="eula-details-page"
      isLoading={isLoading}
      notificationId="eula-details-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationMessage}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      errors={errors}
      header={
        <DetailsHeader
          headerId="eula-details-header-container"
          title={intl.getI18nMessage('label.eula.details.page.title')}
          name={get(eulaDetails, 'name')}
        />
      }
      actions={ hasActionButton &&
        <Actions
          {...props}
          eulaUuid={eulaUuid}
          notifyMessages={notifyMessages}
        />
      }
      tabs={
        <TabsContainer
          id={'eula-details-tab-container'}
          tabValue={currentTab}
          orientation="horizontal"
          tabItems={tabItems.filter(({ visible }) => visible)}
          handleTabChange={handleTabChange}
        />
      }
      page={
        <Fragment>
          <TabPanel
            visible={currentTab === 0}
            id="eula-overview-tab-panel"
          >
            <Overview
              {...props}
              isCurrentTab={currentTab === 0}
              eulaUuid={eulaUuid}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
        </Fragment>
      }
      footer={
        <Footer />
      }
    />
  );
};

const mapStateToProps = state => ({
  config: getConfig(state),
  isLoading: getIsLoading(state),
  eulaDetails: getEulaDetails(state),
  isEulaDeleteSuccess: getIsEulaDeleteSuccess(state),
  isError: getIsError(state),
  pageErrors: getErrors(state),
  userContext: getUserDetails(state),
  deleteEulaErrors: getDeleteEulaErrors(state),
  eulaApisList: getEulaApisListResults(state),
});

const mapDispatchToProps = {
  push,
  fetchEula,
  fetchEulaApis,
  deleteEula,
};

EulaDetails.propTypes = {
  config: shape({}),
  userContext: shape({}),
  history: shape({}),
  eulaDetails: shape({}),
  isLoading: bool,
  isError: bool,
  pageErrors: arrayOf(shape({})),
  match: shape({}),
  fetchEula: func,
  fetchEulaApis: func,
  deleteEula: func,
  push: func,
  deleteEulaErrors: arrayOf(shape({})),
  eulaApisList: arrayOf(shape({})),
};

EulaDetails.displayName = 'EulaDetails';

export default (
  connect(mapStateToProps, mapDispatchToProps)
)(EulaDetails);
