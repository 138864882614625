import { get } from 'lodash';
import { convertHexToRGB } from '../../utils';

const styles = (theme) => ({
  fieldContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formLabelContainer: {
    width: '98%',
  },
  hideFormLabel: {
    visibility: 'hidden',
  },
  optional: {
    float: 'right',
    opacity: 0.5,
  },
  textField: {
    padding: '10px 0',
    width: '100%',
    '& .MuiInputBase-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '99%',
      '& input': {
        height: get(theme, 'height.h40'),
        padding: '0 10px',
      },
      '& textarea': {
        padding: '0 10px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: convertHexToRGB(get(theme, 'color.primaryButtonBackground'), 0.4),
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.primaryButtonHover'),
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.primaryButtonBackground'),
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.error'),
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      backgroundColor: convertHexToRGB(get(theme, 'color.bodyText'), 0.1),
      borderColor: convertHexToRGB(get(theme, 'color.bodyText'), 0.5),
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 0',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 1,
    },
  },
  errorHelperText: {
    '& + .MuiFormHelperText-contained': {
      color: get(theme, 'color.error'),
    },
  },
  resetSecretButton: {
    '& + .Connect(ApplicationEdit)': {
      color: get(theme, 'color.error'),
    },
  },

  /* Todo - Material ui override style
  textField: {
    color: '#000000',
    fontFamily: theme.typography.bodyText,
    fontSize: theme.fontSize.bodyText,
    width: '99%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&$outlined:hover $outlined$notchedOutline': {
      borderColor: '#cccccc',
    },
    '&$notchedOutline$focused $notchedOutline': {
      borderColor: '#cccccc',
    },
    '&$error$notchedOutline': {
      borderColor: '##f44336',
    },
    '&$contained': {
      marginLeft: 1,
    },
    paddingTop: 10,
    paddingBottom: 10,
  },
  notchedOutline: {},
  outlined: {},
  focused: {},
  error: {},
  contained: {},
  */
  helperText: {
    color: `${get(theme, 'color.smallText')} !important`,
    fontFamily: `${get(theme, 'typography.smallText')} !important`,
    fontSize: `${get(theme, 'fontSize.smallText')} !important`,
  },

  [`@media (max-width: ${get(theme, 'breakpoint.xs')})`]: {
    fieldContainer: {
      marginTop: 0,
      marginBottom: 0,
    },
    hideFormLabel: {
      display: 'none',
    },
  },
});

export default styles;
