import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { get, isNaN } from 'lodash';
import {
  IconButton,
  TableContainer,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody,
  TableRow as MuiTableRow,
  TableCell,
  Typography,
  withStyles,
} from '@material-ui/core';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import StopOutlinedIcon from '@material-ui/icons/StopOutlined';
import {
  arrayOf, bool, func, number, oneOfType, shape, string,
} from 'prop-types';

import { getI18n } from '../../../../../utils/intl';
import { KEYS } from '../../../../../constants';
import styles from './styles';

export const getNotDeployedCount = ({ published, pending, deployed, error }) => {
  const notDeployedCount = published - (
    pending + deployed + error
  );
  return (notDeployedCount < 0) ? 0 : notDeployedCount;
};

export const TableHead = ({
  classes = {},
  id,
  rowName,
  cells = [],
}) => (
  <MuiTableHead>
    <MuiTableRow className={classes.tableRow}>
      {(id !== KEYS) &&
        <TableCell scope="row">{rowName}</TableCell>
      }
      {
        cells.map(({ key, value, cellClass }) =>
          <TableCell
            align="right"
            key={key}
            data-layer7-test={`${id}.${key}`}
            classes={{ root: cellClass }}
          >
            {value}
          </TableCell>,
        )
      }
    </MuiTableRow>
  </MuiTableHead>
);

TableHead.propTypes = {
  classes: shape({}),
  id: string,
  rowName: string,
  cells: arrayOf(shape({})),
};

export const TableRow = ({
  classes = {},
  id,
  rowName,
  cells = [],
}) => (
  <MuiTableRow>
    {(id !== KEYS) &&
      <TableCell scope="row">{rowName}</TableCell>
    }
    {
      cells.map(({ key, value, onClick, cellClass }) => (
        <TableCell
          align="right"
          key={key}
          data-layer7-test={`${id}.${key}`}
          className={cellClass}
        >
          {onClick ?
            <IconButton className={classes.clickableCell} onClick={onClick}>
              <Typography variant="body1">{value}</Typography>
            </IconButton> :
            <Typography variant="body1">{value}</Typography>
          }
        </TableCell>
      ))
    }
  </MuiTableRow>
);

TableRow.propTypes = {
  classes: shape({}),
  id: string,
  rowName: string,
  cells: arrayOf(shape({})),
};

export const Table = (props) => {
  const {
    classes = {},
    id,
    isProxyDisconnected,
    publishedCount = 0,
    pendingCount = 0,
    deployedCount = 0,
    errorCount = 0,
    portalPublishedCount = 0,
    portalPendingCount = 0,
    portalDeployedCount = 0,
    portalErrorCount = 0,
    gatewayPublishedCount = 0,
    gatewayPendingCount = 0,
    gatewayDeployedCount = 0,
    gatewayErrorCount = 0,
    otkCount = 0,
    showBreakdown,
    hideSubtotal,
    linkUrl,
    queryParams,
    push,
  } = props;

  const intl = getI18n(useIntl());
  const onClickLink = (param) => {
    if(linkUrl) {
      const qParam = get(queryParams, param);
      const link = qParam ? `${linkUrl}/${qParam}` : linkUrl;
      push(link);
    }
  };

  const portalNotDeployedCount = getNotDeployedCount({
    published: portalPublishedCount,
    pending: portalPendingCount,
    deployed: portalDeployedCount,
    error: portalErrorCount,
  });
  const gatewayNotDeployedCount = getNotDeployedCount({
    published: gatewayPublishedCount,
    pending: gatewayPendingCount,
    deployed: gatewayDeployedCount,
    error: gatewayErrorCount,
  });
  const notDeployedCount = getNotDeployedCount({
    published: publishedCount,
    pending: pendingCount,
    deployed: deployedCount,
    error: errorCount,
  });

  const gatewayNotDeployedCountNaN = isNaN(gatewayNotDeployedCount);

  const labelPublished = (id === KEYS) ?
    intl.getI18nMessage('label.proxy.details.page.status.all.keys') :
    intl.getI18nMessage('label.proxy.details.page.status.published');

  const tableHeadCellsDisconnected = [
    {
      key: 'published',
      value: (
        <Fragment>
          <IconButton className={cx(classes.icon, classes.publishedDisconnected)}>
            <StopOutlinedIcon />
          </IconButton>
          {intl.getI18nMessage('label.proxy.details.page.status.published')}
        </Fragment>
      ),
    },
  ];

  const tableHeadCells = [
    {
      key: 'published',
      value: (
        <Fragment>
          <IconButton className={cx(classes.icon, classes.published)}>
            <StopOutlinedIcon />
          </IconButton>
          {labelPublished}
        </Fragment>
      ),
    },
    {
      key: 'notDeployed',
      value: (
        <Fragment>
          <IconButton className={cx(classes.icon, classes.notDeployed)}>
            <StopRoundedIcon />
          </IconButton>
          {intl.getI18nMessage('label.proxy.details.page.status.not.deployed')}
        </Fragment>
      ),
    },
    {
      key: 'pending',
      value: (
        <Fragment>
          <IconButton className={cx(classes.icon, classes.pending)}>
            <StopRoundedIcon />
          </IconButton>
          {intl.getI18nMessage('label.proxy.details.page.status.pending')}
        </Fragment>
      ),
    },
    {
      key: 'deployed',
      value: (
        <Fragment>
          <IconButton className={cx(classes.icon, classes.deployed)}>
            <StopRoundedIcon />
          </IconButton>
          {intl.getI18nMessage('label.proxy.details.page.status.deployed')}
        </Fragment>
      ),
    },
    {
      key: 'error',
      value: (
        <Fragment>
          <IconButton className={cx(classes.icon, classes.error)}>
            <StopRoundedIcon />
          </IconButton>
          {intl.getI18nMessage('label.proxy.details.page.status.error')}
        </Fragment>
      ),
    },
  ];

  const otkTableHeadCell = {
    key: 'otk',
    value: (
      <Fragment>
        {intl.getI18nMessage('label.proxy.details.page.status.api.key.store')}
      </Fragment>
    ),
    cellClass: classes.otkCell,
  };

  const firstRowCells = [
    {
      key: 'portal.published',
      value: portalPublishedCount,
    },
    {
      key: 'portal.not.deployed',
      value: portalNotDeployedCount,
    },
    {
      key: 'portal.pending',
      value: portalPendingCount,
      onClick: () => onClickLink('portalPending'),
    },
    {
      key: 'portal.deployed',
      value: portalDeployedCount,
      onClick: () => onClickLink('portalDeployed'),
    },
    {
      key: 'portal.error',
      value: portalErrorCount,
      onClick: () => onClickLink('portalError'),
    },
  ];

  const otkFirstRowCell = {
    key: 'portal.otk',
    value: otkCount,
    cellClass: classes.otkCell,
  };

  const totalRowCells = [
    {
      key: 'total.published',
      value: publishedCount,
    },
    {
      key: 'total.not.deployed',
      value: notDeployedCount,
    },
    {
      key: 'total.pending',
      value: pendingCount,
      onClick: () => onClickLink('totalPending'),
    },
    {
      key: 'total.deployed',
      value: deployedCount,
      onClick: () => onClickLink('totalDeployed'),
    },
    {
      key: 'total.error',
      value: errorCount,
      onClick: () => onClickLink('totalError'),
    },
  ];

  const otkTotalRowCell = {
    key: 'total.otk',
    value: otkCount,
    cellClass: classes.otkCell,
  };


  if (id === KEYS) {
    tableHeadCells.push(otkTableHeadCell);
    firstRowCells.push(otkFirstRowCell);
    totalRowCells.push(otkTotalRowCell)
  }

  return (
    <TableContainer>
      <MuiTable size="small">
        {isProxyDisconnected ?
          <TableHead
            classes={classes}
            id={id}
            rowName={intl.getI18nMessage('label.proxy.details.page.source')}
            cells={tableHeadCellsDisconnected}
          /> :
          <TableHead
            classes={classes}
            id={id}
            rowName={intl.getI18nMessage('label.proxy.details.page.source')}
            cells={tableHeadCells}
          />
        }
        <TableBody>
          {showBreakdown && !hideSubtotal &&
            <Fragment>
              <TableRow
                classes={classes}
                id={id}
                rowName={intl.getI18nMessage('label.proxy.details.api.portal.published')}
                cells={isProxyDisconnected ?
                  [
                    {
                      key: 'portal.published',
                      value: portalPublishedCount,
                    },
                  ] :
                  firstRowCells
                }
              />
              <TableRow
                classes={classes}
                id={id}
                rowName={intl.getI18nMessage('label.proxy.details.api.gateway.published')}
                cells={isProxyDisconnected ?
                  [
                    {
                      key: 'gateway.published',
                      value: gatewayPublishedCount,
                    },
                  ] :
                  [
                    {
                      key: 'gateway.published',
                      value: gatewayPublishedCount,
                    },
                    {
                      key: 'gateway.not.deployed',
                      value: gatewayNotDeployedCountNaN ?
                        gatewayPublishedCount :
                        gatewayNotDeployedCount,
                    },
                    {
                      key: 'gateway.pending',
                      value: gatewayPendingCount,
                      onClick: !gatewayNotDeployedCountNaN &&
                        (() => onClickLink('gatewayPending')),
                    },
                    {
                      key: 'gateway.deployed',
                      value: gatewayDeployedCount,
                      onClick: !gatewayNotDeployedCountNaN &&
                        (() => onClickLink('gatewayDeployed')),
                    },
                    {
                      key: 'gateway.error',
                      value: gatewayErrorCount,
                      onClick: !gatewayNotDeployedCountNaN &&
                        (() => onClickLink('gatewayError')),
                    },
                  ]
                }
              />
            </Fragment>
          }
          <TableRow
            classes={classes}
            id={id}
            rowName={intl.getI18nMessage('label.proxy.details.api.total')}
            cells={isProxyDisconnected ?
              [
                {
                  key: 'total.published',
                  value: publishedCount,
                },
              ] :
              totalRowCells
            }
          />
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

export default (
  withStyles(styles)
)(Table);

Table.propTypes = {
  classes: shape({}),
  id: string,
  isProxyDisconnected: bool,
  publishedCount: number,
  pendingCount: number,
  deployedCount: number,
  errorCount: number,
  otkCount: number,
  portalPublishedCount: number,
  portalPendingCount: number,
  portalDeployedCount: number,
  portalErrorCount: number,
  gatewayPublishedCount: number,
  gatewayPendingCount: oneOfType([number, string]),
  gatewayDeployedCount: oneOfType([number, string]),
  gatewayErrorCount: oneOfType([number, string]),
  showBreakdown: bool,
  hideSubtotal: bool,
  onClickLink: func,
  linkUrl: string,
  queryParams: shape({}),
  push: func,
};
