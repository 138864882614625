import get from 'lodash/get';

const styles = ({ color, margin, padding }) => ({
  statusBar: {
    margin: `${get(margin, 'm5')} 0 ${get(margin, 'm21')} 0`,
    '& span': {
      position: 'relative',
      display: 'inline-block',
      padding: `0 ${get(padding, 'p16')}`,
      verticalAlign: 'middle',
    },
  },
  inline: {
    display: 'inline-block',
  },
  status: {
    '&:before': {
      display: 'inline-block',
      position: 'absolute',
      content: '""',
      left: 0,
      top: '50%',
      marginTop: '-6px',
      height: '10px',
      width: '10px',
      borderRadius: '10px',
    },
  },
  success: {
    color: get(color, 'success'),
    '&:before': {
      backgroundColor: get(color, 'success'),
    },
  },
  error: {
    color: get(color, 'error'),
    '&:before': {
      backgroundColor: get(color, 'error'),
    },
  },
  deprecated: {
    color: '#f7922f',
    '&:before': {
      backgroundColor: '#f7922f',
    },
  },
  disabled: {
    color: '#b3bdc2',
    '&:before': {
      backgroundColor: '#b3bdc2',
    },
  },
  new: {
    color: '#20465f',
    '&:before': {
      backgroundColor: '#20465f',
    },
  },
  disabledGraphic: {
    color: get(color, 'disabledGraphic'),
    '&:before': {
      backgroundColor: get(color, 'disabledGraphic'),
    },
  },
});

export default styles;
