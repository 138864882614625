import { get } from 'lodash';

const styles = ({ borderRadius, color, fontSize, margin, padding, typography }) => ({
  pageTitle: {
    color: get(color, 'pageTitle'),
    fontFamily: get(typography, 'pageTitle'),
    fontSize: get(fontSize, 'pageTitle'),
    marginTop: 6,
  },
  statusField: {
    '& legend': {
      color: `${get(color, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontWeight: 'bold',
      marginBottom: 10,
    },
  },
  labelText: {
    color: `${get(color, 'bodyText')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
  },
  helperText: {
    color: `${get(color, 'smallText')} !important`,
    fontFamily: `${get(typography, 'smallText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
  },
  resetSecretButton: {
    color: get(color, 'red'),
    border: `1px solid ${get(color, 'borderColor')}`,
    '&:hover': {
      color: get(color, 'red'),
    },
  },
  copyKeyButton: {
    color: get(color, 'primaryButtonBackground'),
    marginTop: 51,
    width: '70%',
  },
  divider: {
    backgroundColor: get(color, 'borderColor'),
  },
  selectWrapper: {
    minHeight: 370,
    width: 500,
  },
  selectApiPlanPlaceholder: {
    color: get(color, 'error'),
    cursor: 'pointer',
    border: `1px solid ${get(color, 'borderColor')}`,
    borderRadius: get(borderRadius, 'br4'),
    margin: `${get(margin, 'm0')} ${get(margin, 'm10')}`,
    padding: `${get(padding, 'p0')} ${get(padding, 'p10')}`,
  },
  selectedApiPlan: {
    margin: `${get(margin, 'm0')} ${get(margin, 'm10')}`,
  },
  tagGroupContainer: {
    marginTop: 8,
    width: '300px',
  },
  apiGroupPanel: {
    color: get(color, 'pageTitle'),
  },
  panelContent: {
    color: `${get(color, 'bodyText')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  iconRoot: {
    '& svg': {
      width: 16,
      height: 16,
    },
  },
});

export default styles;
