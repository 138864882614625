import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/CancelRounded';

import styles from '../styles';

export default function ListFilesRaw(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    title,
    defaultText,
    files,
    onRemoveFile,
    id,
  } = props;

  return (
    <div id={id} className={classes.selectedFileContainer} data-apim-test={id}>
      <h4 className={classes.formLabel}>{title}</h4>
      {files.length === 0 &&
        <Box className={classes.uploadFileSection} p={1}>
          <Typography>{defaultText}</Typography>
        </Box>
      }
      {files.map((file) =>
        <Box key={file.name} className={classes.uploadFileSection} p={1}>
          <Typography>{file.name}</Typography>
          <Typography className={classes.fileSizeText}>{file.size && `${file.size} bytes`}</Typography>
          <DeleteIcon
            color="primary"
            onClick={() => onRemoveFile(file)}
          />
        </Box>,
      )}
    </div>
  );
}

ListFilesRaw.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  onRemoveFile: PropTypes.func,
  defaultText: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
};
