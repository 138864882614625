import get from 'lodash/get';

const styles = theme => ({
  pendingEnrollmentContainer: {
    padding: `0 ${get(theme, 'padding.p36')}`,
  },
  formLabel: {
    width: '100%',
  },
  enrollmentUrl: {
    '& > div': {
      borderRadius: `${get(theme, 'borderRadius.br4')} 0 0 ${get(theme, 'borderRadius.br4')}`,
    },
    '& input': {
      height: get(theme, 'height.h40'),
      padding: `0 ${get(theme, 'padding.p10')}`,
      width: '500px',
    },
    '& p': {
      color: `${get(theme, 'color.bodyText')} !important`,
      margin: `${get(theme, 'margin.m2')} 0`,
    },
  },
  copyEnrollmentUrl: {
    borderRadius: `0 ${get(theme, 'borderRadius.br3')} ${get(theme, 'borderRadius.br3')} 0`,
  },
  checkStatus: {
    padding: `${get(theme, 'padding.p16')} 0`,
    '& > div': {
      marginTop: 0,
    },
    '& > button': {
      padding: 0,
    },
    '& button > span > p': {
      color: get(theme, 'color.link'),
    },
  },
  checkingStatus: {
    color: get(theme, 'color.disabledText'),
    paddingLeft: 0,
  },
});

export default styles;
