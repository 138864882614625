import { get } from 'lodash';
import { convertHexToRGB } from '../../../../utils';

const styles = ({ color }) => ({
  topPanelContainer: {
    backgroundColor: `${convertHexToRGB(get(color, 'borderColor'), 0.1)}`,
    minHeight: '120px',
    padding: '0 36px',
  },
  marginPanelContainer: {
    minHeight: '350px',
  },
  detailsContainer: {
    padding: '20px 36px',
  },

  editTagsLink: {
    float: 'left',
    padding: 4,
    cursor: 'pointer',
    color: `${get(color, 'link')} !important`,
    textDecoration: 'none',
    '& ~ span': {
      borderRadius: '10px',
      color: `${get(color,'bodyText')} !important`,
    },
  },
});

export default styles;
