import React, { useState } from 'react';
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Radio,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { toLower } from 'lodash';

import styles from './styles';

const getQuota = (quota, quotaInterval) =>
  (quota ? `${quota} / ${toLower(quotaInterval)}` : 'Unlimited');
const getRateLimit = (rateLimit) =>
  (rateLimit ? `${rateLimit} / sec` : 'Unlimited');

export default function TableWithSelectableRow(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    title,
    subtitle,
    items,
    onSelectRow,
  } = props;

  const [selectedRow, setSelectedRow] = useState(props.selectedRow);

  return (
    <div className={classes.root}>
      <div className={classes.tableTitle}>{title}</div>
      <div className={classes.tableSubtitle}>{subtitle}</div>
      <Table size="small" padding="none" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" />
            <TableCell align="center">Api Plan</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Quota</TableCell>
            <TableCell align="center">Rate Limit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            items.map(({
              uuid, name, description, quota, quotaInterval, rateLimit,
            }) => (
              <TableRow
                variant="body1"
                key={uuid}
                selected={uuid === selectedRow}
                onClick={() => {
                  setSelectedRow(uuid);
                  onSelectRow(uuid);
                }}
              >
                <TableCell align="center">
                  <Radio
                    classes={{ root: classes.radioRoot }}
                    checked={uuid === selectedRow}
                    name={uuid}
                  />
                </TableCell>
                <TableCell align="center">{name}</TableCell>
                <TableCell align="center">{description}</TableCell>
                <TableCell align="center">{getQuota(quota, quotaInterval)}</TableCell>
                <TableCell align="center">{getRateLimit(rateLimit)}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
}

TableWithSelectableRow.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  selectedRow: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectRow: PropTypes.func,
};
