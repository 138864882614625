import React from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { map } from 'lodash';
import { arrayOf, func, object, oneOfType } from 'prop-types';

import { getI18n } from '../../../../utils/intl';
import { FormAutoCompleteCombo } from '../../../../components';

export const FilterByTag = (props) => {
  const {
    defaultOptions = [],
  } = props;
  const intl = getI18n(useIntl());

  return (
    <Grid item md={3} sm={3} xs={12}>
      <FormAutoCompleteCombo
        {...props}
        id={'apis-filter-by-tags'}
        inputLabel={intl.getI18nMessage('label.search.tag.placeholder')}
        options={defaultOptions}
        value={map(props.value, (name) => ({ name }))}
        onChange={(e, value) => props.handleChange(value)}
        noMargin
        hideHelper
        multiple
      />
    </Grid>
  );
}

FilterByTag.propTypes = {
  handleChange: func,
  value: oneOfType([object, arrayOf(object)]),
  defaultOptions: arrayOf(object),
};

export default (props) => (
  <FilterByTag
    {...props}
  />
);
