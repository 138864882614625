import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes, { func, shape } from 'prop-types';
import { get } from 'lodash';
import { push } from 'connected-react-router';

import {
  Footer, TabPanel, TabsContainer,
} from '../../../components';
import { getTabIndex, hasOrgPublisherRole, hasPublisherRole } from '../../../utils';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';

import {
  getConfig,
  getUserDetails,
} from '../../../reducers/portalConfig';
import { DetailsHeader } from '../../../components';
import DetailsContainer from '../../details';
import Overview from './Overview';
import Deployments from './Deployments';
import Spec from './Spec';
import OrganizationAccess from './OrganizationAccess';
import Documentation from './Documentations';
import Actions from './Actions';
import { withStyles } from "@material-ui/core";
import styles from "../Edit/styles";
import {
  fetchApi,
  fetchAPITags,
  fetchAssets,
  fetchApiPermitted,
  deleteApi,
} from "../../../actions/api";
import { fetchApplicationsLegacy } from "../../../actions/application";
import {
  getApiCanEditPermitted,
  getApiCanDeletePermitted,
  getApiDetails,
  getApplications,
  getAPITags,
  getAssets,
  getDeleteApiStatus,
  getErrors,
} from "../../../reducers/api";

const getTabItems = ({ apiServiceType }, userContext) => [
  {
    id: 'overview',
    tabId: 'api-details-overview-tab',
    label: getI18nFormattedMessage('label.overview'),
    visible: true,
  },
  {
    id: 'organizations',
    tabId: 'api-details-org-access-tab',
    label: getI18nFormattedMessage('label.organizations'),
    visible: hasOrgPublisherRole(userContext) || hasPublisherRole(userContext),
  },
  {
    id: 'deployments',
    tabId: 'api-details-deployments-tab',
    label: getI18nFormattedMessage('label.deployments'),
    visible: hasOrgPublisherRole(userContext) || hasPublisherRole(userContext),
  },
  {
    id: 'spec',
    tabId: 'api-details-spec-tab',
    label: getI18nFormattedMessage('label.spec'),
    visible: apiServiceType === 'REST',
  },
  {
    id: 'documentation',
    tabId: 'api-details-documentation-tab',
    label: getI18nFormattedMessage('label.documentation'),
    visible: true,
  },
];

const getTabs = (tabItems) => tabItems.filter(({ visible }) => visible);

export const ApiDetails = (props) => {
  const {
    userContext,
    canEdit,
    canDelete,
    apiDetails,
    assets,
    apiTags,
    history,
    errors,
  } = props;

  const intl = getI18n(useIntl());

  const apiUuid = get(props, 'match.params.apiUuid');
  const tabItems = getTabItems(apiDetails, userContext);
  const tabs = getTabs(tabItems);
  const defaultIndex = getTabIndex(tabs, props.match);
  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const [currentTab, setCurrentTab] = useState(tabs[defaultIndex].tabId);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');

  const fetchApiData = async () => {
    await props.fetchApi(apiUuid);
    await props.fetchApiPermitted(apiUuid);
    await props.fetchAPITags(apiUuid);
    await props.fetchAssets(apiUuid);
    await props.fetchApplicationsLegacy();
  };

  useEffect(() => {
    if (apiUuid) {
      fetchApiData();
    }
  }, [apiUuid]);

  useEffect(() => {
    if(apiDetails) {
      const defaultIndex = getTabIndex(tabs, props.match);
      setTabIndex(defaultIndex);
      setCurrentTab(tabs[defaultIndex].tabId);
    }
  }, [props.match, apiDetails]);

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  const handleTabChange = (e, newValue) => {
    setTabIndex(newValue);
    setCurrentTab(tabs[newValue].tabId);
      history.push(`/publish/apis/details/${apiUuid}/${tabs[newValue].id}`)
  };

  const { name, portalStatus, version, accessStatus, modifyTs } = apiDetails;

  return (
    <DetailsContainer
      detailsPageId="api-details-page"
      isLoading={false}
      notificationId="api-details-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationMessage}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      errors={errors}
      header={
        <DetailsHeader
          headerId="api-details-header-container"
          title={intl.getI18nMessage('label.overview')}
          name={name}
          status={portalStatus}
          version={version}
          access={accessStatus}
          modifyTs={modifyTs}
        />
      }
      actions={
        <Actions
          {...props}
          apiUuid={apiUuid}
          setNotificationMessage={setNotificationMessage}
          setNotificationStatus={setNotificationMessage}
          errors={errors}
        />
      }
      tabs={
        <TabsContainer
          id={'api-details-tab-container'}
          tabValue={tabIndex}
          orientation="horizontal"
          tabItems={tabs}
          handleTabChange={handleTabChange}
        />
      }
      page={
        <Fragment>
          <TabPanel
            visible={currentTab === 'api-details-overview-tab'}
            id="api-overview-tab-panel"
          >
            <Overview
              {...props}
              isCurrentTab={currentTab === 'api-details-overview-tab'}
              userContext={userContext}
              apiUuid={apiUuid}
              canEdit={canEdit}
              apiDetails={apiDetails}
              assets={assets}
              apiTags={apiTags}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 'api-details-deployments-tab'}
            id="api-deployments-tab-panel"
          >
            <Deployments
              {...props}
              notifyMessages={notifyMessages}
              tabValue={currentTab}
              apiUuid={apiUuid}
              userContext={userContext}
              canEdit={canEdit}
              apiDetails={apiDetails}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 'api-details-org-access-tab'}
            id="api-org-access-tab-panel"
          >
            <OrganizationAccess
              {...props}
              tabValue={currentTab}
              apiUuid={apiUuid}
              canEdit={canEdit}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 'api-details-spec-tab'}
            id="api-spec-tab-panel"
          >
            <Spec
              {...props}
              apiUuid={apiUuid}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 'api-details-documentation-tab'}
            id="api-documentation-tab-panel"
          >
            <Documentation
              {...props}
              apiUuid={apiUuid}
              tabValue={currentTab}
              userCanEdit={canEdit}
              userCanDelete={canDelete}
            />
          </TabPanel>
        </Fragment>
      }
      footer={
        <Footer />
      }
    />
  );
};

const mapStateToProps = state => ({
  config: getConfig(state),
  userContext: getUserDetails(state),
  apiDetails: getApiDetails(state),
  applications: getApplications(state),
  canEdit: getApiCanEditPermitted(state),
  canDelete: getApiCanDeletePermitted(state),
  assets: getAssets(state),
  apiTags: getAPITags(state),
  deleteApiStatus: getDeleteApiStatus(state),
  errors: getErrors(state),
});

const mapDispatchToProps = {
  push,
  fetchApi,
  fetchAPITags,
  fetchAssets,
  fetchApiPermitted,
  fetchApplicationsLegacy,
  deleteApi,
};

ApiDetails.propTypes = {
  config: shape({}),
  userContext: shape({}),
  history: shape({}),
  match: shape({}),
  push: func,
  assets: PropTypes.arrayOf(PropTypes.object),
  apiTags: PropTypes.arrayOf(PropTypes.object),
  fetchApi: PropTypes.func,
  fetchAPITags: PropTypes.func,
  fetchAssets: PropTypes.func,
  fetchApiPermitted: PropTypes.func,
  fetchApplicationsLegacy: PropTypes.func,
  deleteApi: PropTypes.func,
  apiDetails: PropTypes.object,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  deleteApiStatus: PropTypes.string,
  errors: PropTypes.array,
};

ApiDetails.displayName = 'ApiDetails';

export default (
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ApiDetails);
