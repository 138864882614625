import React from 'react';
import { number, shape, string } from 'prop-types';
import {
  Typography,
  withStyles,
} from '@material-ui/core';

import styles from './styles';
import StatusIndicator from '../../StatusIndicator';

export const DetailsHeader = (props) => {
  const {
    classes,
    headerId,
    title,
    subTitle='',
    marker='',
    name,
    modifyTs,
    status,
    version,
    access,
  } = props;

  return (
    <div
      className={classes.headerContainer}
      id={headerId} data-apim-test={headerId} data-layer7-test={headerId}
    >
      <Typography variant="h6" className={classes.pageTitle}>
        {title}
      </Typography>
      <Typography variant="h1" className={classes.pageTitle}>{name}</Typography>
      {subTitle &&
        <div className={classes.subTitle}>
          {subTitle}
          {marker && <span className={classes.markerContainer}>{marker}</span>}
        </div>
      }
      {status &&
        <StatusIndicator
          status={status}
          modifyTs={modifyTs}
          version={version}
          access={access}
        />
      }
    </div>
  );
};

DetailsHeader.propTypes = {
  classes: shape({}),
  headerId: string,
  title: string,
  name: string,
  modifyTs: number,
  status: string,
  subTitle: string,
  version: string,
  access: string,
  marker: string,
};
DetailsHeader.displayName = 'DetailsHeader';

export default withStyles(styles)(DetailsHeader);
