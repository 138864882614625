import { get } from 'lodash';

const styles = ({ color, fontSize, typography }) => ({
  appHeaderContainer: {
    padding: '0 36px',
  },
  pageTitle: {
    color: `${get(color, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'pageTitle')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontWeight: 'bold',
    wordWrap: 'break-word',
    lineHeight: '48px',
    margin: '5px 0',
  },
  statusBar: {
    margin: '10px 0px',
    '& span': {
      position: 'relative',
      display: 'inline-block',
      marginBottom: '5px',
      paddingLeft: '15px',
      verticalAlign: 'middle',
      fontSize: get(fontSize, 'smallText'),
      letterSpacing: 0,
    },
  },
  statusIcon: {
    fontSize: get(fontSize, 'smallText'),
  },
  status: {
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    '&:before': {
      display: 'inline-block',
      position: 'absolute',
      content: '""',
      left: 0,
      top: '50%',
      marginTop: '-6px',
      height: '10px',
      width: '10px',
      borderRadius: '10px',
    },
  },
  available: {
    color: get(color, 'success'),
    '&:before': {
      backgroundColor: get(color, 'success'),
    },
  },
  pending: {
    color: get(color, 'pending'),
    '&:before': {
      backgroundColor: get(color, 'pending'),
    },
  },
  unavailable: {
    color: get(color, 'error'),
    '&:before': {
      backgroundColor: get(color, 'error'),
    },
  },
  grayText: {
    color: get(color, 'smallText'),
    '&:before': {
      content: '"Client ID: "',
      textTransform: 'uppercase',
    },
  },
  linkWrapper: {
    paddingLeft: '5px !important',
    '& a': {
      cursor: 'pointer',
      border: 'none !important',
      margin: '0px !important',
      padding: '0px !important',
      height: '0px !important',
      fontWeight: '300 !important',
      color: `${get(color, 'link')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: `${get(color, 'linkHover')} !important`,
      textDecoration: 'none',
    },
    '& a:focus': {
      boxShadow: 'none !important',
      outline: '0px !important',
      textDecoration: 'none',
    },
  },
  disabledClass: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
    '& a': {
      color: `${get(color, 'gray')} !important`,
    },
  },
});

export default styles;
