import { get } from 'lodash';

const styles = (theme) => ({
  root: {
    backgroundColor: get(theme, 'color.white'),
    color: get(theme, 'color.bodyText'),
    '& fieldset': {
      borderColor: `${get(theme, 'color.borderColor')} !important`,
      top: 0,
      '& legend': {
        width: 'auto !important',
        display: 'none',
      },
    },
    '& .MuiIconButton-root': {
      color: get(theme, 'color.primaryButtonBackground'),
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      display: 'none',
    },
  },
  buttonContainer: {
    color: get(theme, 'color.primaryButtonBackground'),
    marginRight: 8,
    width: '4%',
    float: 'left',
    paddingTop: 6,
    paddingBottom: 6,
  },
  optionName: {
    float: 'left',
    paddingTop: 7,
    paddingBottom: 6,
  },
  tag: {
    display: 'none',
  },
  input: {
    color: get(theme, 'color.bodyText'),
  },
  inputRoot: {
    color: get(theme, 'color.bodyText'),
  },
  listbox: {
    color: get(theme, 'color.bodyText'),
  },
  clearIndicator: {
    display: 'none',
  },
  optionContainer: {
    width: '100%',
    height: '100%',
  },
  optionListContainer: {
    marginTop: 6,
    marginBottom: 6,
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export default styles;
