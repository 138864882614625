import colorMod from 'color';
import { get } from 'lodash';

export default ({ color, fontSize, typography }) => ({
  UserMenuButton: {
    display: 'inline-block',
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    '& > button': {
      border: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0)',
      borderRadius: 3,
      color: get(color, 'headerLink'),
      padding: 8,
      '&:hover': {
        color: colorMod(get(color, 'headerLink')).alpha(0.7).string(),
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      '&:focus': {
        borderColor: colorMod(get(color, 'headerLink')).alpha(0.7).string(),
      },
      '&:active': {
        borderColor: get(color, 'headerLink'),
      },
    },
    '.skipPrint &': {
      '@media print': {
        display: 'none',
      },
    },
  },

  PersonIcon: {
    width: 24,
    height: 24,
  },

  Popper: {
    marginTop: 4,
  },

  MenuPaper: {
    borderRadius: 3,
    boxShadow: 'none',
    filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))',
  },

  Menu: {
    fontFamily: get(typography, 'bodyText'),
    margin: '0 0 0 0 !important',
    maxHeight: '500px',
    padding: '4px 0 12px 0 !important',
    overflowY: 'auto',
    width: 250,
  },

  UserMenuContent__Item: {
    borderLeft: '3px solid !important',
    borderColor: 'rgba(0, 0, 0, 0) !important',
    height: 36,
    lineHeight: '36px !important',
    padding: '0 12px 0 12px !important',
    '&:hover': {
      backgroundColor: `${colorMod(get(color, 'headerLink')).alpha(0.08).string()} !important`,
    },
    '&:focus': {
      backgroundColor: `${colorMod(get(color, 'headerLink')).alpha(0.08).string()} !important`,
      borderColor: `${get(color, 'headerLink')} !important`,
      boxShadow: 'none !important',
      outline: 'none !important',
    },
    '& button, & a': {
      color: `${get(color, 'bodyText')} !important`,
      display: 'inline-block',
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontStyle: 'normal !important',
      fontWeight: 'normal !important',
      padding: 0,
      textTransform: 'capitalize',
      verticalAlign: 'middle',
      textAlign: 'left',
    },
  },

  UserMenuContent__Name: {
    color: get(color, 'bodyText'),
    cursor: 'default',
    fontSize: get(fontSize, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontWeight: 'bold',
    lineHeight: '36px',
    whiteSpace: 'nowrap',
    opacity: '1.0 !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0) !important',
    },
  },

  UserMenuContent__AccessibleOrgWrapper: {
    borderLeft: '3px solid !important',
    borderColor: 'rgba(0, 0, 0, 0) !important',
    display: 'flex',
    justifyContent: 'space-between',
    width: '250px !important',
    padding: '0 12px 0 12px !important',
    '& button': {
      border: '0px solid',
      color: get(color, 'bodyText'),
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontStyle: 'normal !important',
      fontWeight: 'normal !important',
      justifyContent: 'left',
      lineHeight: '36px',
    },
    '&:hover': {
      backgroundColor: `${colorMod(get(color, 'headerLink')).alpha(0.08).string()} !important`,
    },
    '&:focus': {
      backgroundColor: `${colorMod(get(color, 'headerLink')).alpha(0.08).string()} !important`,
      borderColor: `${get(color, 'headerLink')} !important`,
    },
  },

  UserMenuContent__AccessibleOrgs: {
    backgroundColor: 'transparent !important',
    border: 'none',
    color: get(color, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    padding: 0,
    textAlign: 'left',
    cursor: 'pointer',
    outline: 0,
    textTransform: 'none',
  },

  UserMenuContent__AccessibleOrgs_label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  UserMenuContent__Check: {
    color: `${get(color, 'headerLink')} !important`,
  },

  SubHeader: {
    color: get(color, 'bodyText'),
    cursor: 'default',
    fontSize: get(fontSize, 'smallText'),
    fontWeight: 'bold',
    lineHeight: '36px !important',
    padding: '0 0 0 27px !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0) !important',
    },
  },

  SubHeaderUppercase: {
    textTransform: 'uppercase',
  },
});
