import { get } from 'lodash';

const styles = ({ padding, margin, color }) => ({
  container: {
    margin: `${get(margin, 'm36')} !important`,
    '& a:hover': {
      color: `${get(color, 'linkHover')} !important`,
      textDecoration: 'none',
    },
    '& a:focus': {
      boxShadow: 'none !important',
      outline: '0px !important',
      textDecoration: 'none',
    },
  },
  summaryContainer: {
    marginTop: get(margin, 'm36'),
  },
  showContainer: {
    marginRight: get(padding, 'p15'),
  },
  button: {
    color: get(color, 'link'),
    marginRight: get(margin, 'm5'),
  },
  outlinedButton: {
    color: get(color, 'link'),
    borderColor: get(color, 'link'),
  },
});

export default styles;
