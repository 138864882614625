import React from 'react';
import { useIntl } from 'react-intl';
import { Grid, Button, ButtonGroup, FormLabel, Tooltip } from '@material-ui/core';
import { func, object, string, bool } from 'prop-types';

import { FormTextField } from '../../../../../components';
import { getI18n, getI18nFormattedMessage } from '../../../../../utils/intl';
import {
  ACCESS_TYPE_GRANTED,
  ACCESS_TYPE_MANAGED,
  ACCESS_TYPE_NONE,
} from '../../../../../constants';

export const FilterByName = (props) => (
    <Grid item md={5} sm={5} xs={12}>
        <FormTextField
        {...props}
        id="api-org-access-filter-by-name"
        data-apim-test="api-org-access-filter-by-name"
        />
    </Grid>
);

export const AccessFilterButtons = (props) => (
  <Grid
    item md={2} sm={6} xs={12}
    id="api-org-show-container" data-apim-test="api-org-show-container" data-layer7-test="api-org-show-container"
  >
    <FormLabel>{getI18nFormattedMessage('label.show')}</FormLabel>
    <ButtonGroup className={props.fieldContainerClass}>
      <Button
        id="api-org-show-all" data-apim-test="api-org-show-all" data-layer7-test="api-org-show-all"
        color="primary"
        variant="outlined"
        className={props.access === '' ? props.activeButton: ''}
        onClick={(e) => props.onSortButtonClick(e, 'all')}
      >
        {getI18nFormattedMessage('label.all')}
      </Button>
      <Button
        id="api-org-show-access" data-apim-test="api-org-show-access" data-layer7-test="api-org-show-access"
        color="primary"
        variant="outlined"
        className={props.access === `${ACCESS_TYPE_GRANTED},${ACCESS_TYPE_MANAGED}`
          ? props.activeButton: ''}
        onClick={(e) => props.onSortButtonClick(e, 'access')}
      >
        {getI18nFormattedMessage('label.access')}
      </Button>
      <Button
        id="api-org-show-noaccess" data-apim-test="api-org-show-noaccess" data-layer7-test="api-org-show-noaccess"
        color="primary"
        variant="outlined"
        className={props.access === ACCESS_TYPE_NONE ? props.activeButton: ''}
        onClick={(e) => props.onSortButtonClick(e, 'noaccess')}
      >
        {getI18nFormattedMessage('label.no.access')}
      </Button>
    </ButtonGroup>
  </Grid>
);

AccessFilterButtons.propTypes = {
  access: string,
  fieldContainerClass: object,
  activeButton: func,
  onSortButtonClick: func,
};

export const FilterAndSortSeparator = () => (
  <Grid item md={5} />
);

export const TypeHeaderTooltip = ({ tooltipLabel } ) => {
  return (
    <div>
      <div>
        { tooltipLabel }
      </div>
    </div>
  );
}
TypeHeaderTooltip.propTypes = {
  tooltipLabel: string,
};
export const TooltipIcon = ({ classes, label, tooltipLabel, content }) => (
  <div className={classes.helpIconContainer}>
    <div>
      {label}
    </div>
    <Tooltip title={<TypeHeaderTooltip tooltipLabel={tooltipLabel}/>} placement="bottom">
      {content}
    </Tooltip>
  </div>
);
TooltipIcon.propTypes = {
  tooltipLabel: string,
  classes: object,
  label: string,
  content: object,
};

export const HeaderActionButtons = (props) => {
  const {
    classes = {},
    onAddVisible,
    onRemoveVisible,
    onAddRateLimit,
    onRemoveRateLimit,
    hasVisibleActions,
    hasRateQuotaActions,
    bulkActionsItemsCount,
    disableAddAccess,
    disableRemoveAccess,
    disableAddRateQuota,
    disableDefaultAddRateQuota,
  } = props;

  const id = 'api-org-access'
  const intl = getI18n(useIntl());
  
  return (
    <>
      {(hasVisibleActions || hasRateQuotaActions) &&
        <>
          <FormLabel className={ classes.actionsLabel }>
              {intl.getI18nMessage('label.organization.apiaccess.actions.label', { count: bulkActionsItemsCount })}
            </FormLabel>
            <Grid
              item md={12} sm={12} xs={12}
              className={classes.headerButtons}
            >
              {hasVisibleActions &&
                <span>
                  <Button
                    variant="contained" color="secondary"
                    className={classes.addButton}
                    id={`${id}-add-visibility`}
                    data-apim-test={`${id}-add-visibility`}
                    data-layer7-test={`${id}-add-visibility`}
                    onClick={onAddVisible}
                    disabled={disableAddAccess}
                  >
                    {intl.getI18nMessage('label.add.access')}
                  </Button>
                  <Button
                    variant="contained" color="secondary"
                    className={classes.removeButton}
                    id={`${id}-remove-visibility`}
                    data-apim-test={`${id}-remove-visibility`}
                    data-layer7-test={`${id}-remove-visibility`}
                    onClick={onRemoveVisible}
                    disabled={disableRemoveAccess}
                  >
                    {intl.getI18nMessage('label.remove.access')}
                  </Button>
                </span>
              }
              {hasRateQuotaActions &&
                <span className={classes.buttonContainer}>
                    <Button
                      variant="contained" color="secondary"
                      className={classes.addButton}
                      id={`${id}-add-ratelimit`}
                      data-apim-test={`${id}-add-ratelimit`}
                      data-layer7-test={`${id}-add-ratelimit`}
                      onClick={onAddRateLimit}
                      disabled={disableAddRateQuota}
                    >
                        {intl.getI18nMessage('label.rate.quota.add.button')}
                    </Button>
                    <Button
                      variant="contained" color="secondary"
                      className={classes.addButton}
                      id={`${id}-remove-ratelimit`}
                      data-apim-test={`${id}-remove`}
                      data-layer7-test={`${id}-remove`}
                      onClick={onRemoveRateLimit}
                      disabled={disableDefaultAddRateQuota}
                    >
                        {intl.getI18nMessage('label.rate.quota.reset.button')}
                    </Button>
                </span>
              }
            </Grid>
        </>
      }
    </>
  );
}

HeaderActionButtons.propTypes = {
  classes: object,
  onAddVisible: func,
  onRemoveVisible: func,
  onAddRateLimit: func,
  onRemoveRateLimit: func,
  hasVisibleActions: bool,
  hasRateQuotaActions: bool,
  bulkActionsItemsCount: string,
  disableAddAccess: bool,
  disableRemoveAccess: bool,
  disableAddRateQuota: bool,
  disableDefaultAddRateQuota: bool,
};