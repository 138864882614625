import { getI18nFormattedMessage } from '../../../utils/intl';

export const HELP_ITEMS = {
  title: '',
  descriptions: '',
  subItems: [
    {
      id: 0,
      title: '',
      descriptions: getI18nFormattedMessage('label.organization.form.page.side.help.1'),
      list: {
        type: 'circle',
        items: [
          getI18nFormattedMessage('label.organization.form.page.side.help.1.0'),
          getI18nFormattedMessage('label.organization.form.page.side.help.1.1'),
        ],
      },
    },
    {
      id: 1,
      title: '',
      descriptions: getI18nFormattedMessage('label.organization.form.page.side.help.2'),
      list: {
        type: 'circle',
        items: [
          getI18nFormattedMessage('label.organization.form.page.side.help.2.0'),
          getI18nFormattedMessage('label.organization.form.page.side.help.2.1'),
          getI18nFormattedMessage('label.organization.form.page.side.help.2.2'),
        ],
      },
    },
    {
      id: 2,
      title: '',
      descriptions: getI18nFormattedMessage('label.organization.form.page.side.help.3'),
    },
    {
      id: 3,
      title: '',
      descriptions: getI18nFormattedMessage('label.organization.form.page.side.help.4'),
    },
  ],
};

export default HELP_ITEMS;
