import React from 'react';
import { bool, number } from 'prop-types';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryStack,
} from 'victory';
import get from 'lodash/get';
import { useTheme } from '@material-ui/core/styles';

export const Visualization = (props) => {
  const {
    isProxyDisconnected,
    publishedCount = 0,
    pendingCount = 0,
    deployedCount = 0,
    errorCount = 0,
  } = props;

  const theme = useTheme();
  const {
    bodyText,
    disabledBackground,
    disabledGraphic,
    success,
    error,
    uiBackground,
  } = get(theme, 'color', {});

  const borderColor = isProxyDisconnected ? disabledBackground : bodyText;
  const colorScale = [ disabledGraphic, disabledBackground, success, error ];
  const visualizationData =
    isProxyDisconnected ?
      [0, publishedCount, 0, 0] :
      [
        publishedCount - (
          pendingCount + deployedCount + errorCount
        ),
        pendingCount,
        deployedCount,
        errorCount,
      ];
  const data = visualizationData.map(
    i => [{ x: 1, y: i }],
  );

  const axisStyles = {
    axis: { stroke: 'transparent' },
    ticks: { stroke: 'transparent' },
    tickLabels: { fill: 'transparent' },
  };

  return (
    <VictoryChart
      horizontal
      height={30}
      padding={{ bottom: 0, left: 2, right: 2, top: 0 }}
      containerComponent={
        <VictoryContainer
          height={20}
          style={{
            border: `2px solid ${borderColor}`,
            borderRadius: 4,
            marginBottom: 20,
            marginTop: 10,
          }}
        />
      }
    >
      <VictoryStack
        colorScale={colorScale}
        style={{
          data: {
            fillOpacity: 1.0,
            stroke: uiBackground,
            strokeWidth: 3,
          },
        }}
      >
        {
          data.map((d, key) => (
            <VictoryBar
              alignment="middle"
              barWidth={25}
              data={d}
              key={key}
            />
          ))
        }
      </VictoryStack>
      <VictoryAxis style={axisStyles} />
      <VictoryAxis style={axisStyles} />
    </VictoryChart>
  );
};

export default (Visualization);

Visualization.propTypes = {
  isProxyDisconnected: bool,
  publishedCount: number,
  pendingCount: number,
  deployedCount: number,
  errorCount: number,
};
