import React from 'react';
import { withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import Measure from 'react-measure';
import { injectIntl, intlShape } from 'react-intl';

import theme from './index.theme';
import { getI18n } from '../../utils/intl';

type Props = {
  tags: Array<Object>,
  classes: Object,
  onTagClick: Function,
  intl: intlShape,
};

const sortFn = (item1, item2) =>
  (item1 && item1.toLowerCase().localeCompare(item2 && item2.toLowerCase()));


const tagsLabel = 'label.react.components.tagsgroup.tags';

class TagsGroupRaw extends React.Component {
  state = {
    collapsed: false,
  };

  clickHandler = (event, tag, onTagClick) => {
    event.stopPropagation();
    event.preventDefault();
    if (onTagClick) {
      onTagClick(tag);
    }
  };

  handleContainerResize = (contentRect) => {
    const { width: containerWidth } = contentRect.bounds;
    const { width: scrollWidth } = contentRect.scroll;
    // added +1, as scrollWidth value is slightly(<1)
    // more than containerWidth, even when there is no overflow
    if (scrollWidth > containerWidth + 1) {
      this.setState({ collapsed: true });
    }
  };

  props: Props;

  renderTag = (tag) => {
    const { classes, onTagClick } = this.props;
    return (
      <Measure
        scroll
        bounds
        key={tag}
      >
        {({ measureRef }) => (
          <span
            ref={measureRef}
            key={tag}
            onClick={(event) => this.clickHandler(event, tag, onTagClick)}
            className={classes.Tag}
          >
            {tag}
          </span>
        )}
      </Measure>
    );
  }

  render = () => {
    const { tags = [], classes, onTagClick, intl } = this.props;
    const { getI18nMessage } = getI18n(intl);
    const { collapsed } = this.state;
    if (tags.length === 0) {
      return (<div className={classes.TagGroup} />);
    }
    const sortedTags = tags.sort(sortFn);
    return (
      <Measure
        scroll
        bounds
        onResize={this.handleContainerResize}
      >
        {({ measureRef }) => (
          <div ref={measureRef} className={classes.TagGroup}>
            {!collapsed && sortedTags.map(this.renderTag)}
            {collapsed &&
              <span
                className={classes.Tag}
                onClick={(event) => this.clickHandler(event, '', onTagClick)}
              >
                {getI18nMessage(tagsLabel, { num: sortedTags.length })}
              </span>}
          </div>)
        }
      </Measure>
    );
  };
}

TagsGroupRaw.displayName = 'TagsGroup';

export default compose(
  withStyles(theme),
  injectIntl,
)(TagsGroupRaw);
