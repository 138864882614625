import React, { useState } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import GraphIcon from './graph_icon';
import { APPLICATION, API, ORGANIZATION, PROXY, TRAFFIC, ADD_TO_CHART,
  REMOVE_FROM_CHART, UNAUTHORIZED } from '../labels';
import styles from './styles';

export default function ChartLegend(props) {
  const [showOnChart, setShowOnChart] = useState(true);
  const useStyles = makeStyles(styles);
  const { isTop3EntitiesSelected } = props;
  const {
    entity,
    rank,
    lineColor,
    chartEntity,
    clickHandler,
    hoverHandler,
    isOrgPublisher,
  } = props;
  const mouseHover = (id, flag) => {
    if (!flag) {
      document.activeElement.blur();
    }
    hoverHandler(id, flag);
  };
  const detailLinks = {
    api: '/publish/apis/details/',
    app: '/publish/applications/details/',
    proxy: '/publish/api-proxy/details/',
    org: '/admin/console/organizations/details/',
  };
  const entityRankTitles = {
    api: API,
    app: APPLICATION,
    org: ORGANIZATION,
    proxy: PROXY,
  };
  const isNonClickable = entity.id === UNAUTHORIZED || (props.isApiOwnerOrOrgPub && (chartEntity === 'proxy'))
  || (isOrgPublisher && (chartEntity === 'org'));
  const navigateToEntity = (event, entityId) => {
    event.stopPropagation();
    if (!isNonClickable) {
      const url = `https://${window.location.hostname}${detailLinks[chartEntity]}${entityId}`;
      window.location.href = url;
    }
  };
  const navigateToEntityByKey = (event, entityId) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      navigateToEntity(event, entityId);
    }
  };
  const hideOrShowOnChart = (event, id) => {
    event.stopPropagation();
    setShowOnChart(!showOnChart);
    clickHandler(id, true);
  };
  const classes = useStyles({ lineColor, showOnChart, isNonClickable });
  return (
    <div
      className={classes.legend}
      onMouseEnter={() => mouseHover(entity.id, true)}
      onMouseLeave={() => mouseHover(entity.id, false)}
      onFocus={() => mouseHover(entity.id, true)}
      onBlur={() => mouseHover(entity.id, false)}
      onClick={(e) => navigateToEntity(e, entity.id)}
      onKeyDown={e => navigateToEntityByKey(e, entity.id)}
      tabIndex="0"
      role="button"
    >
      <div>
        {isTop3EntitiesSelected && (
          <div className={classes.relationship}>
            {`#${rank} ${TRAFFIC} ${entityRankTitles[chartEntity].toUpperCase()}`}
          </div>
        )}
        <div className={classes.title}>{entity.names[0]}</div>
        <div className={classes.count} >
          {entity.tooltipData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </div>
      </div>
      <div className={classes.graphIconContainer}>
        <div className={classes.tooltip}>
          <IconButton
            onClick={(e) => hideOrShowOnChart(e, entity.id)}
            className={classes.graphIconHolder}
          >
            <GraphIcon className={classes.graphIcon} />
          </IconButton>

          <span className={classes.tooltiptext}>
            {showOnChart ? REMOVE_FROM_CHART : ADD_TO_CHART}
          </span>
        </div>
      </div>
    </div>
  );
}

ChartLegend.propTypes = {
  entity: PropTypes.object,
  rank: PropTypes.number,
  chartEntity: PropTypes.string,
  clickHandler: PropTypes.func,
  hoverHandler: PropTypes.func,
  isTop3EntitiesSelected: PropTypes.bool,
  lineColor: PropTypes.string,
  isApiOwnerOrOrgPub: PropTypes.bool,
  isOrgPublisher: PropTypes.bool,
};
