import each from 'lodash/each';
import keys from 'lodash/keys';

const aggregationValueMap = { hits: 'count', latency: 'avg' };

const buildMetricsQueryForChartData = (data) => ({
  type: data.metrics.type,
  aggregation: data.metrics.aggregation,
});

const buildFilterQueryForChartData = data => {
  const uriField = data.dimensions.filter.fields.find(
    field => field.dimension === 'uri',
  );
  if (uriField) {
    const groupByPresent = uriField.isGrouped === false;
    const fields = [];
    const patterns = [];
    uriField.values.forEach(entry => {
      fields.push({
        type: 'regex',
        dimension: 'uri',
        pattern: {
          type: 'regex',
          value: entry.value,
          name: entry.name,
        },
      });
      if (groupByPresent) {
        patterns.push({
          type: 'regex',
          value: entry.value,
          name: entry.name,
        });
      }
    });
    const index = data.dimensions.filter.fields.findIndex(
      field => field.dimension === 'uri',
    );
    data.dimensions.filter.fields.splice(index, 1, {
      type: 'or',
      fields,
    });
    if (groupByPresent) {
      data.dimensions.groupBy.push({
        type: 'pattern',
        dimension: 'uri',
        patterns,
      });
    }
  }
  return data.dimensions.filter;
};

const buildGroupByQueryForChartData = (data) => data.dimensions.groupBy;

export const buildQuery = (data) => {
  const query = {};
  query.metrics = buildMetricsQueryForChartData(data);
  query.timeRange = data.timeRange;
  if (data.dimensions && data.dimensions.filter &&
    data.dimensions.filter.fields &&
    data.dimensions.filter.fields.length) {
    query.filter = buildFilterQueryForChartData(data);
  }
  if (data.dimensions && data.dimensions.groupBy.length) {
    query.groupBy = buildGroupByQueryForChartData(data);
  }
  return query;
};

export default buildQuery;

const buildMetricsQuery = (data) => ({
  type: data.selectedMetric.value,
  aggregation: aggregationValueMap[data.selectedMetric.value],
});

const buildTimeRangeQuery = data =>
  data.timeRange || {
    type: 'period',
    period: '7D',
    aggregation: 'day',
  };

const buildDimensionsQuery = (data) => {
  const { selectedDataSource } = data;
  const filterGroupByData = [];
  let filterFieldsData = {};
  const filterFields = [];
  each(selectedDataSource, dataSource => {
    if (dataSource.type === 'in') {
      const updatedDataSource = { ...dataSource, values: keys(dataSource.values) };
      filterFields.push(updatedDataSource);
    } else if (dataSource.type === 'top' ||
      dataSource.type === 'range' ||
      dataSource.type === 'default') {
      filterGroupByData.push(dataSource);
    } else if (dataSource.dimension === 'uri') {
      filterFields.push(dataSource);
    } else {
      filterFields.push(dataSource);
    }
  });
  if (filterFields.length > 0) {
    filterFieldsData = { type: 'and', fields: filterFields };
  }
  return ({
    filter: filterFieldsData,
    groupBy: filterGroupByData,
    dimensionSelectionOrder: data.dimensionOrder,
  });
};

export const buildSaveQuery = (data) => {
  const saveQuery = {};
  saveQuery.name = data.title;
  saveQuery.description = data.description;
  saveQuery.chartType = 'trend';
  saveQuery.metrics = buildMetricsQuery(data);
  saveQuery.timeRange = buildTimeRangeQuery(data);
  saveQuery.dimensions = buildDimensionsQuery(data);
  return saveQuery;
};
