// @flow
import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Link from './Link';
import theme from './index.theme';

const Nav = ({
  classes = {},
  navItems = [],
  useExternalNavLinks = true,
}) => (
  <div className={clsx(classes.HeaderNav, classes.HeaderDesktopNav)}>
    <div className={classes.VerticalAlign}>
      <div className={classes.VerticalAlignInner}>
        <ul className={classes.HeaderNavLinks}>
          {navItems.map(item =>
            <Link
              {...item}
              key={item.id}
              useExternalNavLinks={useExternalNavLinks}
            />)
          }
        </ul>
      </div>
    </div>
  </div>
);

Nav.propTypes = {
  classes: PropTypes.shape(),
  navItems: PropTypes.arrayOf(PropTypes.shape()),
  useExternalNavLinks: PropTypes.bool,
};

export default withStyles(theme)(Nav);
