import React, { Fragment, useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { push } from 'connected-react-router';
import {
  withStyles,
 } from '@material-ui/core';
import { bool, object, arrayOf, number, func } from 'prop-types';

import Header from './Header';
import Actions from './Actions';
import Metadata from './Metadata';
import Apis from './Apis';
import Deployments from './Deployments';
import {
  TabsContainer,
  TabPanel,
} from '../../../components';
import DetailsContainer from '../../details';
import { fetchGatewayBundleDetails, fetchGatewayBundleUsage, deleteGatewayBundle } from '../../../actions/gatewayBundle';
import { getConfig, getUserDetails } from '../../../reducers/portalConfig';
import {
  getGatewayBundleDetails,
  getGatewayBundleApiUsage,
  getIsLoading,
  getErrors,
  getDeleteBundleErrors,
  getIsBundleDeleteSuccess,
} from '../../../reducers/gatewayBundle';
import styles from './styles';
import { getI18nFormattedMessage } from '../../../utils/intl';

const getTabItems = () => [
  {
    id: 0,
    tabId: 'gateway-bundle-metadata-tab',
    label: getI18nFormattedMessage('label.gateway.bundle.details.page.metadata.title'),
    visible: true,
  },
  {
    id: 1,
    tabId: 'gateway-bundle-deployment-tab',
    label: getI18nFormattedMessage('label.gateway.bundle.details.page.deployments.title'),
    visible: true,
  },
  {
    id: 2,
    tabId: 'gateway-bundle-api-tab',
    label: getI18nFormattedMessage('label.gateway.bundle.details.page.api.title'),
    visible: true,
  },
];

export const GatewayBundleDetails = (props) => {
  const {
    gatewayBundleDetails,
    isLoading,
    config,
    userContext,
    bundleErrors,
    apiUsage,
  } = props;

  const bundleUuid = get(props, 'match.params.bundleUuid');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [currentTab, setCurrentTab] = useState(0);
  const tabItems = getTabItems();

  useEffect(() => {
    if (bundleUuid) {
      props.fetchGatewayBundleDetails(bundleUuid);
      props.fetchGatewayBundleUsage(bundleUuid);
    }
  }, []);

  useEffect(() => {
    if (!userContext.portalAdmin) { props.push('/404'); }
  }, [userContext]);

  useEffect(() => {
    if (!isEmpty(bundleErrors)) {
      props.push('/404');
    }
  }, [bundleErrors]);

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  const handleTabChange = (e, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <DetailsContainer
      detailsPageId="gateway-bundle-details-page"
      isLoading={isLoading}
      notificationId="gateway-bundle-details-notification"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationMessage}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      header={
        <Header
          gatewayBundleDetails={gatewayBundleDetails}
        />
      }
      actions={
        <Actions
          {...props}
          notifyMessages={notifyMessages}
        />
      }
      tabs={
        <TabsContainer
          id={'gateway-bundle-details-tab-container'}
          tabValue={currentTab}
          orientation="horizontal"
          tabItems={tabItems.filter(({ visible }) => visible)}
          handleTabChange={handleTabChange}
        />
      }
      page={
        <Fragment>
          <TabPanel
            visible={currentTab === 0}
            id="gateway-bundle-metadata-tab-panel"
          >
            <Metadata
              gatewayBundleDetails={gatewayBundleDetails}
              apiUsage={apiUsage}
              config={config}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 1}
            id="gateway-bundle-deployments-tab-panel"
          >
            <Deployments
              gatewayBundleDetails={gatewayBundleDetails}
              bundleUuid={bundleUuid}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 2}
            id="gateway-bundle-api-tab-panel"
          >
            <Apis
              gatewayBundleDetails={gatewayBundleDetails}
              bundleUuid={bundleUuid}
            />
          </TabPanel>
        </Fragment>
      }
    />
  );
};

const mapStateToProps = state => ({
  config: getConfig(state),
  gatewayBundleDetails: getGatewayBundleDetails(state),
  apiUsage: getGatewayBundleApiUsage(state),
  isLoading: getIsLoading(state),
  userContext: getUserDetails(state),
  bundleErrors: getErrors(state),
  deleteBundleErrors: getDeleteBundleErrors(state),
  isBundleDeleteSuccess: getIsBundleDeleteSuccess(state),
});

const mapDispatchToProps = {
  fetchGatewayBundleDetails,
  fetchGatewayBundleUsage,
  deleteGatewayBundle,
  push,
};

GatewayBundleDetails.propTypes = {
  gatewayBundleDetails: object,
  apiUsage: number,
  isLoading: bool,
  config: object,
  userContext: object,
  bundleErrors: arrayOf(object),
  fetchGatewayBundleDetails: func,
  fetchGatewayBundleUsage: func,
  push: func,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(GatewayBundleDetails);
