import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import {
  withStyles,
  Grid,
  Divider,
 } from '@material-ui/core';
import { bool, shape, arrayOf, string, func } from 'prop-types';

import {
  AlertMessages,
  Loading,
  ErrorContainer,
} from '../components';
import styles from './details.styles';

export const Details = (props) => {
  const {
    classes,
    detailsPageId,
    isLoading,
    divider=true,
    notificationId,
    notificationStatus,
    setNotificationStatus,
    notificationMessage,
    setNotificationMessage,
    errors=[],
    header,
    actions,
    pending,
    tabs,
    page,
    footer,
  } = props;

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  return (
    <div
      className={classes.content}
      id={detailsPageId} data-apim-test={detailsPageId} data-layer7-test={detailsPageId}
    >
      {isLoading && <Loading pageLoader /> }
      {notificationMessage &&
        <AlertMessages
          id={notificationId}
          variant={notificationStatus}
          message={notificationMessage}
          containerClass={classes.notificationWidthClass}
          onClose={() => notifyMessages('', '')}
        />
      }
      {errors.length > 0 &&
        <div className={classes.errorContainer}>
          <ErrorContainer errors={errors} />
        </div>
      }
      <Fragment>
        <Grid container justify="space-between">
          <Grid item md={10} sm={9} xs={12}>
            {header}
          </Grid>
          <Grid item md={2} sm={3} xs={12} className={classes.actionsContainer}>
            {actions}
          </Grid>
        </Grid>
        {pending ?
          <Fragment>
            {pending}
          </Fragment>
          :
          <Fragment>
            <Grid container className={classes.gridContainer}>
              {tabs}
            </Grid>
            {divider && <Divider className={classes.divider} />}
            <Grid>
              {page}
            </Grid>
          </Fragment>
        }
        {footer}
      </Fragment>
    </div>
  );
};

Details.propTypes = {
  classes: shape({}),
  detailsPageId: string,
  isLoading: bool,
  divider: bool,
  notificationId: string,
  notificationStatus: string,
  setNotificationStatus: func,
  notificationMessage: string,
  setNotificationMessage: func,
  infoId: string,
  errors: arrayOf(shape({})),
  header: shape({}),
  actions: shape({}),
  pending: shape({}),
  tabs: shape({}),
  page: shape({}),
  footer: shape({}),
};
Details.displayName = 'Details';

export default compose(
  withStyles(styles),
)(Details);
