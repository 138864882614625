import React from 'react';
import isEmpty from 'lodash/isEmpty';

import Details from './Details';
import CustomFields from './CustomFields';
import PolicyTemplates from './PolicyTemplates';
import SpecAuthentication from './SpecAuthentication';
import ManagementPermission from './ManagementPermissions';
import Tags from './Tags';
import {
  hasPublisherRole,
} from '../../../utils';
import { ID_SOAP, API_PUBLISH_STATE_INCOMPLETE, API_PUBLISH_STATE_NEW } from './labels';

const isApiIncomplete = (apiDetails) => apiDetails.portalStatus === API_PUBLISH_STATE_INCOMPLETE;

const isAPINew = (apiDetails) => apiDetails.portalStatus === API_PUBLISH_STATE_NEW;

const isPublishedByGateway = (apiDetails) =>
  (apiDetails.publishedByPortal === undefined ? false : !apiDetails.publishedByPortal);

export default [
  {
    id: 'details',
    tabId: 'details-tab',
    label: 'Details',
    tabPanel: (props) => (<Details {...props} />),
    onCancel: () => { window.location.href = '/publish/apis'; },
    isHidden: ({ useLegacyApiPages }) => (useLegacyApiPages),
  },
  {
    id: 'custom-fields',
    tabId: 'custom-fields-tab',
    label: 'Custom Fields',
    tabPanel: (props) => (<CustomFields {...props} />),
    isDisabled: ({ apiUuid }) => (!apiUuid),
    isHidden: ({ customFields, useLegacyApiPages, apiDetails }) => (
      isEmpty(customFields) || useLegacyApiPages || isPublishedByGateway(apiDetails)
    ),
  },
  {
    id: 'policy-templates',
    tabId: 'policy-templates-tab',
    label: 'Policy Templates',
    tabPanel: props => (<PolicyTemplates {...props} />),
    isHidden: ({ apiDetails, useLegacyApiPages }) => (
      isPublishedByGateway(apiDetails) || useLegacyApiPages
    ),
    isDisabled: ({ apiUuid }) => (!apiUuid),
  },
  {
    id: 'spec-authentication',
    tabId: 'spec-authentication-tab',
    label: 'Spec Authentication',
    tabPanel: props => (<SpecAuthentication {...props} />),
    isHidden: ({ apiType, apiDetails, useLegacyApiPages }) => (
      apiType === ID_SOAP || isPublishedByGateway(apiDetails) || useLegacyApiPages
    ),
    isDisabled: ({ apiUuid, apiDetails }) => (!apiUuid || isApiIncomplete(apiDetails)),
  },
  {
    id: 'management',
    tabId: 'management-tab',
    label: 'Management Permissions',
    tabPanel: props => (<ManagementPermission {...props} />),
    isHidden: ({ userContext }) => (!hasPublisherRole(userContext)),
    isDisabled: ({ apiUuid, apiDetails }) => (
      !apiUuid || isApiIncomplete(apiDetails) || isAPINew(apiDetails)
    ),
  },
  {
    id: 'tags',
    tabId: 'tags-tab',
    label: 'Tags',
    tabPanel: props => (<Tags {...props} />),
    isHidden: false,
    isDisabled: ({ apiUuid, apiDetails }) => (
      !apiUuid || isApiIncomplete(apiDetails) || isAPINew(apiDetails)
    ),
  },
];
