import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { arrayOf, bool, func, shape } from 'prop-types';
import { get, isUndefined } from 'lodash';
import { push } from 'connected-react-router';

import {
  Footer, TabPanel, TabsContainer,
} from '../../../components';
import { getTabIndex } from '../../../utils';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import { isCompatibleBundleVersion } from '../../../utils/datetime';
import {
  fetchProxy,
  fetchProxyDetails,
  fetchProxyGateway,
  deleteProxy,
  fetchApiKeysCount,
  fetchProxyApiKeys,
  fetchApis,
  fetchProxyApis,
  fetchProxyApiPlans,
  fetchProxyOrganizations,
  fetchRateLimitQuota,
  fetchProxyDeploymentStatus,
  updateNewProxySync,
} from '../../../actions/proxy';
import {
  getProxyDetails,
  getProxy,
  getProxyGatewayDetailsLoading,
  getParsedProxyGatewayDetails,
  getProxyGateways,
  getIsProxyDeleteSuccess,
  getHasError,
  getPageErrors,
  getIsLoading,
} from '../../../reducers/proxy';
import {
  fetchDeploymentRefreshTime,
} from '../../../actions/portalConfig';
import {
  getConfig,
  getDeploymentRefreshTime,
  getFeatureFlags,
} from '../../../reducers/portalConfig';
import { DetailsHeader } from '../../../components';
import DetailsContainer from '../../details';
import Overview from './Overview';
import Apis from './Apis';
import Keys from './Keys';
import Certificates from './Certificates';
import EntitiesSummary from './EntitiesSummary';
import Actions from './Actions';
import PendingEnrollment from './PendingEnrollment';
import {
  PROXY_ENROLMENT_STATUS_PENDING,
  PROXY_CONNECTED,
  PROXY_DISCONNECTED,
} from '../../../constants';

export const getProxyUuid = (props) => get(props, 'match.path')
  && get(props, 'match.path').includes('/details/')
  && get(props, 'match.params.apiProxyUuid');

export const getProxyStatus = (proxyDetails) => {
  const mqConnected = get(proxyDetails, 'messageQueueDetails.connected');
  if(isUndefined(mqConnected)) { return null; }
  return mqConnected ? PROXY_CONNECTED : PROXY_DISCONNECTED;
};

const getTabItems = (featureFlagL7Manager) => [
  {
    id: 'overview',
    tabId: 'proxy-overview-tab',
    label: getI18nFormattedMessage('label.proxy.details.page.overview.title'),
    visible: true,
  },
  {
    id: 'apis',
    tabId: 'proxy-apis-tab',
    label: getI18nFormattedMessage('label.proxy.details.page.apis.deployments.title'),
    visible: true,
  },
  {
    id: 'keys',
    tabId: 'proxy-keys-tab',
    label: getI18nFormattedMessage('label.proxy.details.page.application.api.keys.deployments.title'),
    visible: true,
  },
  {
    id: 'certificates',
    tabId: 'proxy-certificates-tab',
    label: getI18nFormattedMessage('label.certificates'),
    visible: featureFlagL7Manager,
  },
  {
    id: 'entities',
    tabId: 'proxy-entities-summary-tab',
    label: getI18nFormattedMessage('label.entities.summary'),
    visible: featureFlagL7Manager,
  },
  // {
  //   id: 'organizations',
  //   tabId: 'proxy-organizations-tab',
  //   label: getI18nFormattedMessage('label.organizations'),
  //   visible: true,
  // },
];

export const getIsCompatible = ({
  gatewayDetailsLoading,
  logs,
}) => {
  if(gatewayDetailsLoading) { return; }
  const bundleVersion = get(logs, 'bundle_version');
  return isCompatibleBundleVersion(bundleVersion);
};

export const ProxyDetails = (props) => {
  const {
    isLoading,
    featureFlags,
    proxyDetails,
    gatewayDetailsLoading,
    parsedGatewayDetails = {},
    hasError,
    pageErrors,
  } = props;

  const proxyUuid = get(props, 'match.params.apiProxyUuid');
  const featureFlagL7Manager = JSON.parse(get(featureFlags, 'l7Manager.Value'));
  const tabItems = getTabItems(featureFlagL7Manager);

  const intl = getI18n(useIntl());
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [errors, setErrors] = useState([]);
  const [currentTab, setCurrentTab] = useState(
    getTabIndex(tabItems, props.match),
  );

  const { enrollmentStatus, logs } = parsedGatewayDetails;
  const isCompatible = getIsCompatible({
    gatewayDetailsLoading,
    logs,
  });
  const isPendingEnrollment =
    (enrollmentStatus === PROXY_ENROLMENT_STATUS_PENDING);
  const proxyStatus = getProxyStatus(proxyDetails);

  useEffect(() => {
    props.fetchProxy(proxyUuid);
    props.fetchProxyGateway(proxyUuid);
    props.fetchRateLimitQuota(proxyUuid);
    props.fetchProxyDeploymentStatus(proxyUuid);
    props.fetchDeploymentRefreshTime();
  }, []);

  useEffect(() => {
    setCurrentTab(getTabIndex(tabItems, props.match));
  }, [props.match]);

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  useEffect(() => {
    setErrors(hasError ? pageErrors : []);
  }, [hasError, pageErrors]);

  const handleTabChange = (e, newValue) => {
    setCurrentTab(newValue);
  };

  const onSwitchNewSync = () =>{
    props.updateNewProxySync(proxyUuid);
  };

  return (
    <DetailsContainer
      detailsPageId="proxy-details-page"
      isLoading={isLoading}
      notificationId="proxy-details-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationMessage}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      infoMessage={intl.getI18nMessage('label.proxy.details.info.switch.newSync')}
      infoLinkText={intl.getI18nMessage('label.proxy.details.info.switch.linkText')}
      handleInfoClick={onSwitchNewSync}
      infoSeverity="info"
      infoId="proxy-details-info"
      errors={errors}
      header={
        <DetailsHeader
          headerId="proxy-details-header-container"
          title={intl.getI18nMessage('label.proxy.details.page.title')}
          name={get(proxyDetails, 'name')}
          status={isPendingEnrollment ? enrollmentStatus : proxyStatus}
        />
      }
      actions={
        <Actions
          {...props}
          proxyUuid={proxyUuid}
          enrollmentStatus={enrollmentStatus}
          notifyMessages={notifyMessages}
        />
      }
      pending={(enrollmentStatus && isPendingEnrollment) && (
        <PendingEnrollment
          {...props}
          proxyUuid={proxyUuid}
          notifyMessages={notifyMessages}
        />
      )}
      tabs={
        <TabsContainer
          id={'proxy-details-tab-container'}
          tabValue={currentTab}
          orientation="horizontal"
          tabItems={tabItems.filter(({ visible }) => visible)}
          handleTabChange={handleTabChange}
        />
      }
      page={
        <Fragment>
          <TabPanel
            visible={currentTab === 0}
            id="proxy-overview-tab-panel"
          >
            <Overview
              {...props}
              isCurrentTab={currentTab === 0}
              proxyUuid={proxyUuid}
              proxyStatus={proxyStatus}
              parsedGatewayDetails={parsedGatewayDetails}
              isCompatible={isCompatible}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 1}
            id="proxy-apis-tab-panel"
          >
           <Apis
              {...props}
              isCurrentTab={currentTab === 1}
              gatewayBundleDetails={parsedGatewayDetails}
              proxyUuid={proxyUuid}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 2}
            id="proxy-keys-tab-panel"
          >
            <Keys
              {...props}
              isCurrentTab={currentTab === 2}
              gatewayBundleDetails={parsedGatewayDetails}
              proxyUuid={proxyUuid}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 3}
            id="proxy-certificates-tab-panel"
          >
            <Certificates
              {...props}
              isCurrentTab={currentTab === 3}
              proxyUuid={proxyUuid}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 4}
            id="proxy-entities-summary-tab-panel"
          >
            <EntitiesSummary
              {...props}
              isCurrentTab={currentTab === 4}
              proxyUuid={proxyUuid}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
        </Fragment>
      }
      footer={
        <Footer />
      }
    />
  );
};

const mapStateToProps = state => ({
  config: getConfig(state),
  featureFlags: getFeatureFlags(state),
  isLoading: getIsLoading(state),
  gatewayDetailsLoading: getProxyGatewayDetailsLoading(state),
  proxyDetails: getProxyDetails(state),
  proxy: getProxy(state),
  gateways: getProxyGateways(state),
  parsedGatewayDetails: getParsedProxyGatewayDetails(state),
  isProxyDeleteSuccess: getIsProxyDeleteSuccess(state),
  hasError: getHasError(state),
  pageErrors: getPageErrors(state),
  deploymentRefreshTime: getDeploymentRefreshTime(state),
});

const mapDispatchToProps = {
  push,
  fetchProxy,
  fetchProxyDetails,
  fetchProxyGateway,
  deleteProxy,
  fetchApiKeysCount,
  fetchProxyApiKeys,
  fetchApis,
  fetchProxyApis,
  fetchProxyApiPlans,
  fetchProxyOrganizations,
  updateNewProxySync,
  fetchDeploymentRefreshTime,
  fetchRateLimitQuota,
  fetchProxyDeploymentStatus,
};

ProxyDetails.propTypes = {
  config: shape({}),
  history: shape({}),
  proxyDetails: shape({}),
  parsedGatewayDetails: shape({}),
  gatewayDetailsLoading: bool,
  gatewayDetails: shape({}),
  featureFlags: shape({}),
  gateways: arrayOf(shape({})),
  isLoading: bool,
  hasError: bool,
  pageErrors: arrayOf(shape({})),
  match: shape({}),
  fetchProxy: func,
  fetchProxyGateway: func,
  fetchDeploymentRefreshTime: func,
  updateNewProxySync: func,
  fetchRateLimitQuota: func,
  fetchProxyDeploymentStatus: func,
};
ProxyDetails.displayName = 'ProxyDetails';

export default (
  connect(mapStateToProps, mapDispatchToProps)
)(ProxyDetails);
