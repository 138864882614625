import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles,
 } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles';

export default function AlertDialog(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    id,
    isOpen,
    cancelText,
    submitText,
    defaultText,
    title,
    description,
    component,
    dialogId = 'alert-dialog',
    handleClose,
    containerClass = '',
    submitButtonClass = '',
    defaultButtonClass = '',
    cancelButtonClass = '',
    dialogActionClass = '',
    showDefault = false,
    showSubmit = true,
    onSubmit,
    showCancel = true,
    onCancel,
    onDefault,
    onClose,
    showCloseButton,
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id={id || dialogId} data-apim-test={id || dialogId} data-layer7-test={id || dialogId}
      classes={{
        paper: clsx(classes.dialogContainer, containerClass),
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        data-apim-test="alert-dialog-title"
        className={classes.dialogTitle}
      >
        <Typography variant="h3" className={classes.title}>{title}</Typography>
        {showCloseButton ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" data-apim-test="alert-dialog-description">
          {description}
        </DialogContentText>
        {component && component}
      </DialogContent>
      <DialogActions id={`${dialogId}-actions`} classes={{ root:dialogActionClass }}>
      {showDefault &&
          <Button
            className={clsx(classes.defaultButton, defaultButtonClass)}
            data-apim-test="alert-dialog-default"
            id={`${dialogId}-action-default`} data-layer7-test={`${dialogId}-action-default`}
            onClick={onDefault}
            variant="text"
            color="secondary"
          >
            {defaultText}
          </Button>
        }
        {showSubmit &&
          <Button
            className={clsx(classes.submitButton, submitButtonClass)}
            data-apim-test="alert-dialog-submit"
            id={`${dialogId}-action-submit`} data-layer7-test={`${dialogId}-action-submit`}
            onClick={onSubmit}
            variant="contained"
            color="secondary"
          >
            {submitText}
          </Button>
        }
        {showCancel &&
          <Button
            className={clsx(classes.cancelButton, cancelButtonClass)}
            data-apim-test="alert-dialog-cancel"
            id={`${dialogId}-action-cancel`} data-layer7-test={`${dialogId}-action-cancel`}
            onClick={onCancel}
            variant="outlined"
          >
            {cancelText}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  dialogId: PropTypes.string,
  handleClose: PropTypes.func,
  showSubmit: PropTypes.bool,
  onSubmit: PropTypes.func,
  component: PropTypes.object,
  showCancel: PropTypes.bool,
  onCancel: PropTypes.func,
  submitButtonClass: PropTypes.string,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  cancelButtonClass: PropTypes.string,
  dialogActionClass: PropTypes.string,
  containerClass: PropTypes.string,
  defaultButtonClass: PropTypes.string,
  showDefault: PropTypes.bool,
  defaultText: PropTypes.string,
  onDefault: PropTypes.func,
};
