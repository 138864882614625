import { get } from 'lodash';

const styles = ({ padding, margin, color }) => ({
  listContent: {
    margin: get(margin, 'm0'),
  },
  pageBodyClass: {
    paddingTop: get(padding, 'p0'),
  },
  pageFilterAndSortClass: {
    marginTop: get(margin, 'm24'),
  },
  fieldContainer: {
    margin: `${get(margin, 'm0')} !important`,
    marginRight: '5px !important',
  },
  selectField: {
    padding: '2px 10px !important',
  },
  tooltipContainer: {
    backgroundColor: get(color, 'gray'),
    padding: get(padding, 'p5'),
    borderRadius: get(padding, 'p10'),
    marginLeft: get(margin, 'm4'),
  },
});

export default styles;
