import color from 'color';

const specialColorValues = ['transparent', 'inherit'];

const isSpecialColor = (col: string) =>
  specialColorValues.indexOf(col) !== -1;

const callColorMethodUnlessSpecialColor =
  (method: string, col: string, amount?: number) => (
    isSpecialColor(col) ? col : color(col)[method](amount).hexString()
  );

export const lighten = callColorMethodUnlessSpecialColor.bind(null, 'lighten');
export const darken = callColorMethodUnlessSpecialColor.bind(null, 'darken');
export const greyscale = callColorMethodUnlessSpecialColor.bind(null, 'greyscale');

export const modifyAlpha =
  (colorValue, alpha) => color(colorValue).alpha(alpha).string();

export default (col: string, method: string, amount: number) =>
  color(col)[method](amount).rgb().string();
