import { get } from 'lodash';

const styles = theme => ({
  tableContainer: {
    '& .MuiCheckbox-colorSecondary.Mui-disabled': {
      '& .MuiSvgIcon-root': {
        color: get(theme, 'color.gray'),
        fill: get(theme, 'color.gray'),
        backgroundColor: get(theme, 'color.gray'),
        width: '0.75em',
        height: '0.75em',
        marginLeft: get(theme, 'margin.m4'),
      },
    },
    '& tr th:first-child, tr td:first-child': {
      paddingLeft: get(theme, 'padding.p36'),
    },
    '& tr th:last-child, tr td:last-child': {
      paddingRight: get(theme, 'padding.p36'),
    },
    '& caption': {
      color: get(theme, 'color.bodyText'),
      paddingLeft: get(theme, 'padding.p36'),
      paddingRight: get(theme, 'padding.p36'),
      whiteSpace: get(theme, 'whiteSpace.breakSpaces'),
    },
  },
  rowsSelected: {
    '&:hover': {
      backgroundColor: 'unset !important',
    },
    '& > td, & > th': {
      backgroundColor: get(theme, 'color.lightHover'),
    },
  },
  [`@media (max-width: ${get(theme, 'breakpoint.xs')})`]: {
    tableContainer: {
      '& tr th:first-child, tr td:first-child': {
        paddingLeft: get(theme, 'padding.p16'),
      },
      '& tr th:last-child, tr td:last-child': {
        paddingRight: get(theme, 'padding.p16'),
      },
    },
  },
  cellOverflow: {
    float: 'left',
    width: '97%',
    display: 'block',
    overflow: 'hidden',
    background: `linear-gradient(to right, #000 55%, rgb(0 0 0 / 0%) 100%);`,
    height: '20px',
    textOverflow: 'ellipsis',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
});

export default styles;
