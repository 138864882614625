import { get } from 'lodash';
import * as Constants from '../../constants';

const resetState = {
  isError: false,
  errors: [],
  isUpdateSuccessful: false,
  isUpdateError: false,
  updateErrorMsg: '',
};

const initialState = {
  ...resetState,
  isLoading: false,
  isFormLoadingError: false,
  isUpdateError: false,
  updateErrorMsg: '',
};

const getValidationErrorMessage = (payload) => get(payload, 'error.detail.devErrorMessage');

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.APP_RESET:
      return {
        ...state,
        ...resetState,
      };
    case Constants.APP_LOADING:
      return {
        ...state,
        ...resetState,
        isLoading: action.isLoading,
      };
    case Constants.REQUEST_SETTINGS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        data: action.payload,
      };
    case Constants.REQUEST_SETTINGS_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        isFormLoadingError: true,
        isError: true,
        errors: action.payload,
      };
    case Constants.REQUEST_SETTINGS_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.REQUEST_SETTINGS_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdateSuccessful: true,
        requestSettings: action.payload,
      };
    case Constants.REQUEST_SETTINGS_UPDATE_ERROR: {
      const updateErrorMsg = getValidationErrorMessage(action.payload);
      return {
        ...state,
        isUpdateError: true,
        isLoading: false,
        ...(updateErrorMsg ? { updateErrorMsg } : {}),
      };
    }
    default:
      return state;
  }
}

export const getIsLoading = (state) => get(state, 'requestSettings.isLoading');

export const getIsError = (state) => get(state, 'requestSettings.isError');
export const getErrors = state => get(state, 'requestSettings.errors');

export const getIsFormLoadingError = (state) => get(state, 'requestSettings.isFormLoadingError');

export const getRequestSettings = (state) => get(state, 'requestSettings.data');
export const getIsFormUpdateError = (state) => get(state, 'requestSettings.isUpdateError');
export const getIsUpdateSuccessful = (state) => get(state, 'requestSettings.isUpdateSuccessful');
export const getUpdateErrorMsg = (state) => get(state, 'requestSettings.updateErrorMsg');

