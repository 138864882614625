import { get } from 'lodash';

const styles = ({ color, fontSize, typography, borderRadius, padding }) => ({
  formContainer: {
    border: get(color, 'gray') + ' 1px solid',
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    borderRadius: get(borderRadius, 'br4'),
    padding: `0 ${get(padding, 'p36')}`,
    color: get(color, 'primaryButtonText'),
    '& nav': {
      padding: get(padding, 'p0'),
      '& > div > div > span': {
        color: get(color, 'primaryButtonText'),
      },
    },
    margin: '0 auto',
  },
  pageDescription: {
    color: get(color, 'black'),
  },
  submitButton: {
    backgroundColor: `${get(color, 'red')} !important`,
  },
});

export default styles;
