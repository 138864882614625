import get from 'lodash/get';

export default ({ color, misc, typography }) => ({
  Header: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: get(color, 'headerBackground'),
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.12)',
    fontFamily: get(typography, 'bodyText'),
    zIndex: 900,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 72,
    paddingRight: 72,

    [`@media screen and (max-width: ${get(misc, 'breakpointSmall')}px)`]: {
      paddingLeft: 16,
      paddingRight: 16,
    },

    [`@media screen and (min-width: ${get(misc, 'breakpointSmall')}px) and (max-width: ${get(misc, 'breakpointLarge')}px)`]: {
      paddingLeft: 36,
      paddingRight: 36,
    },
  },

  HeaderInner: {
    height: 64,
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
