import get from 'lodash/get';

const styles = ({ color }) => ({
    helpIcon: {
        color: get(color, 'primaryButtonBackground'),
        marginLeft: 6,
        marginTop: 3,
        height: 16,
        width: 16,
      },
      helpIconContainer: {
        display: 'flex',
      },
});

export default styles;
