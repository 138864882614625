import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    Button,
} from '@material-ui/core';
import { get } from 'lodash';

import DocumentToolbar from './DocumentToolbar';
import { MarkdownInput, FormTextField } from '../../../../components';
import { hasError, hasRequiredError } from '../../../../utils';
import { formatDateString } from "../../../../utils/datetime";
import { bool, func, object, string } from 'prop-types';


const DocumentEdit = ({
    document,
    entityType,
    entityUuid,
    userCanDelete,
    userCanEdit,
    hasChildren,
    onAddNewDocument,
    onDeleteDocument,
    selectedViewDocument,
    onSave,
    onCancel,
    id,
}) => {

    const classes = useStyles();
    const [title, setTitle] = useState(get(selectedViewDocument, 'title'));
    const [uri, setUri] = useState(get(selectedViewDocument, 'navtitle'));
    const [content, setContent] = useState(get(selectedViewDocument, 'markdown'));
    const modifyTs = get(selectedViewDocument, 'modifyTs') ?
        formatDateString(new Date(get(selectedViewDocument, 'modifyTs')).toString()): '';
    const [lastModified, setLastmodified] = useState(modifyTs);
    const [titleError, setTitleError] = useState(false);
    const [contentError, setContentError] = useState(false);

    useEffect(() => {
        if(selectedViewDocument) {
            setTitle(selectedViewDocument.title);
            setUri(selectedViewDocument.navtitle);
            setContent(selectedViewDocument.markdown);
            setLastmodified(formatDateString(new Date(selectedViewDocument.modifyTs).toString()));
        }
    }, [selectedViewDocument]);

    const handleChange = (value, name) => {
        if( name === 'title') {
            const titleUiError = hasError(value, true);
            setTitle(value);
            setTitleError(titleUiError);
        }
        if( name === 'content') {
            const contentUiError = hasRequiredError(value, true);
            setContent(value);
            setContentError(contentUiError);
        }
    };

    const onPublish = () => {
        const titleUiError = hasError(title, true);
        const contentUiError = hasRequiredError(content, true);
        setTitleError(titleUiError);
        setContentError(contentUiError);
        if(!titleUiError && !contentUiError) {
            const newDoc = {
                ...selectedViewDocument,
                title: title,
                markdown: content,
            };
            onSave(newDoc);
        }
    };

    return (
        <div id={id} data-apim-test={id}>
            <DocumentToolbar
                document={document}
                entityType={entityType}
                entityUuid={entityUuid}
                userCanEdit={userCanEdit}
                userCanAdd={userCanEdit}
                userCanDelete={userCanDelete}
                hasChildren={hasChildren}
                onAddNewDocument={onAddNewDocument}
                onDeleteDocument={onDeleteDocument}
                disabled
                id={`${id}-toolbar`}
                data-apim-test={`${id}-toolbar`}
            />
            <div className={classes.formContainer}>
                <div className={classes.fieldContainer}>
                    <FormTextField
                        name={'Title'}
                        id={'api-details-document-edit-title'}
                        data-apim-test={'api-details-document-edit-title'}
                        fieldContainerClass={classes.textFieldContainer}
                        variant="standard"
                        value={title}
                        handleChange={(value) => handleChange(value, 'title')}
                        error={titleError}
                        helperText={'Title is required'}
                    />
                </div>
                <div className={classes.uriContainer}>
                    <FormTextField
                        name={'URI'}
                        id={'api-details-document-edit-navtitle'}
                        data-apim-test={'api-details-document-edit-navtitle'}
                        fieldContainerClass={classes.textFieldContainer}
                        variant="standard"
                        value={uri}
                        disabled={true}
                    />
                </div>
                <div className={classes.dateContainer}>
                    <FormTextField
                        name={'Last Modified'}
                        id={'api-details-document-edit-lastmodified'}
                        data-apim-test={'api-details-document-edit-lastmodified'}
                        fieldContainerClass={classes.textFieldContainer}
                        variant="standard"
                        value={lastModified}
                        disabled={true}
                    />
               
                </div>
                <MarkdownInput
                    source="markdown"
                    formClassName={classes.markdown}
                    isRequired
                    fullWidth
                    helperText={'Content is required'}
                    name={'Content'}
                    value={content}
                    error={contentError}
                    onChange={(value) => handleChange(value, 'content')}
                />
                <div
                    className={classes.buttonContainer}
                    id={'api-details-document-edit-button-container'}
                    data-apim-test={'api-details-document-edit-button-container'}
                >
                    <Button className={classes.submitButton} color="secondary" variant="contained" onClick={onPublish}>
                        {'PUBLISH'}
                    </Button>
                    <Button className={classes.cancelButton} color="primary" variant="outlined" onClick={onCancel}>
                        {'CANCEL'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles(
    theme => ({
        formContainer: {
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
        },
        textFieldContainer: {
            marginBottom: `${theme.spacing(1)}px !important`,
        },
        fieldContainer: {
            display: 'inline-block',
            width: '26%',
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        uriContainer: {
            display: 'inline-block',
            width: '46%',
        },
        dateContainer: {
            display: 'inline-block',
            width: '26%',
            marginLeft: theme.spacing(1),
        },
        labelContainer: {
            width: '100%',
        },
        buttonContainer: {
            width: '90%',
            float: 'right',
            marginTop: theme.spacing(1),
        },
        cancelButton: {
            float: 'right',
            marginRight: theme.spacing(2),
        },
        submitButton: {
            float: 'right',
            marginRight: theme.spacing(2),
        },
    }),
);

DocumentEdit.propTypes = {
    document: object,
    entityType: string,
    entityUuid: string,
    userCanDelete: bool,
    userCanEdit: bool,
    hasChildren: object,
    onAddNewDocument: func,
    onDeleteDocument: func,
    selectedViewDocument: object,
    onSave: func,
    onCancel: func,
    id: string,
};

export default DocumentEdit;
