// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { getI18n } from '../../../../utils/intl';
import theme from './index.theme';

export default function Link({
  link,
}: Object) {
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  const intl = getI18n(useIntl());
  return (
    <a
      href={`//${window.location.hostname}${link.href}`}
      className={classes.HeaderNavigationLink}
      target={link.externalLink ? '_blank' : '_self'}
      rel={
        link.externalLink ? `noopener${link.titleKey !== 'label.appnav.portalapi'
        ? ' noreferrer' : ''}`
        : ''
      }
      data-apim-test={link.titleKey || link.menuTitle}
    >
      {link.titleKey ? intl.getI18nMessage(link.titleKey) : link.menuTitle}
    </a>);
}
