import { get } from 'lodash';

const styles = ({ color, typography, fontSize }) => ({
  appApisContainer: {
    padding: '0 36px',
  },
  apiCard: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  card: {
    margin: 5,
    minHeight: 160,
    backgroundColor: get(color, 'uiBackground'),
  },
  cardTitle: {
    fontSize: get(fontSize, 'smallTitle'),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: '5px 0px',
  },
  cardStatus: {
    color: get(color, 'bodyText'),
    marginTop: 5,
    marginBottom: 5,
  },
  cardStatusIcon: {
    fontSize: 12,
  },
  available: {
    color: get(color, 'success'),
    '&:before': {
      backgroundColor: get(color, 'success'),
    },
  },
  pending: {
    color: get(color, 'pending'),
    '&:before': {
      backgroundColor: get(color, 'pending'),
    },
  },
  unavailable: {
    color: get(color, 'error'),
    '&:before': {
      backgroundColor: get(color, 'error'),
    },
  },
  cardDescription: {
    paddingTop: 5,
    color: get(color, 'smallText'),
  },
  versionText: {
    color: get(color, 'bodyText'),
  },
  boldText: {
    fontWeight: 'bold',
  },
  linkWrapper: {
    '& a': {
      cursor: 'pointer',
      border: 'none !important',
      margin: '0px !important',
      padding: '0px !important',
      height: '0px !important',
      fontWeight: '300 !important',
      color: `${get(color, 'link')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: `${get(color, 'linkHover')} !important`,
      textDecoration: 'none',
    },
    '& a:focus': {
      boxShadow: 'none !important',
      outline: '0px !important',
      textDecoration: 'none',
    },
  },
  leftMargin: {
    '& a': {
      marginLeft: '5px !important',
    },
  },
  disabledClass: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
    '& a': {
      color: `${get(color, 'gray')} !important`,
    },
  },
});

export default styles;
