/* eslint-disable */
import React from 'react';
import {
  FormLabel,
  TextField,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { bool, func, number, string } from 'prop-types';

import styles from './styles';

export default function FormTextField(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    fieldContainerClass,
    id,
    name,
    value,
    helperText,
    errorHelperText,
    disabled = false,
    handleChange,
    handleBlur,
    rows = 1,
    error = false,
    type = 'string',
    optional,
    multiline = false,
    maxLength,
    onKeyPress,
    placeholder,
    textFieldClass = null,
    hideLabel,
  } = props;

  const textClass = textFieldClass ?
    clsx(textFieldClass, classes.textField) : classes.textField;

  return (
    <div
      className={clsx(fieldContainerClass, classes.fieldContainer)}
      id={id} data-apim-test={id} data-layer7-test={id}
    >
      <div className={classes.formLabelContainer}>
        <FormLabel className={hideLabel && classes.hideFormLabel}>{name}</FormLabel>
        {optional &&
          <FormLabel className={classes.optional}>Optional</FormLabel>
        }
      </div>
      <TextField
        classes={{
          root: textClass,
        }}
        variant="outlined"
        disabled={disabled}
        error={error}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={(e) => handleBlur && handleBlur(e.target.value)}
        InputProps={{
          className: (errorHelperText && classes.errorHelperText),
        }}
        inputProps={{
          ...(maxLength ? { maxLength } : {}),
        }}
        value={value || ''}
        helperText={helperText}
        FormHelperTextProps={{
          className: errorHelperText ? classes.errorHelperText : classes.helperText,
        }}
        type={type}
        multiline={multiline}
        rows={rows}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
      />
    </div>
  );
}

FormTextField.propTypes = {
  fieldContainerClass: string,
  id: string,
  name: string,
  value: string,
  helperText: string,
  errorHelperText: bool,
  disabled: bool,
  handleChange: func,
  handleBlur: func,
  rows: number,
  error: bool,
  type: string,
  optional: bool,
  multiline: bool,
  maxLength: string,
  onKeyPress: func,
  placeholder: string,
  textFieldClass: string,
  hideLabel: bool,
};
