import React from 'react';
import { FormattedMessage } from 'react-intl';

/* please use getI18n:getI18nMessage instead of this function.
  will remove this function and replace usages with getI18nMessage in next refactoring
*/
export const getIntlMessage = (messageObj, dict) => {
  const { id, defaultMessage } = messageObj;
  const message = dict[id];
  return ({
    id,
    description: message || defaultMessage,
    defaultMessage: message || `+++ ${defaultMessage}`,
  });
};

export default {
  getIntlMessage,
};

export const getI18n = (intl) => {
  const { messages = {}, formatMessage = () => {} } = intl;
  return ({
    /**
     * Function to resolve i18n message using an 'intl' instance
     * @param labelKey: message key in the bundle
     * @param values: object with values for tokens
     * @param defaultMessage: when id / tokens are not resolved in the bundle
     * @returns Resolved i18n plain-string message from react-intl
     */
    getI18nMessage: (labelKey, values, defaultMessage) => {
      const message = messages[labelKey];
      return formatMessage(
        {
          id: labelKey,
          description: message || defaultMessage,
          defaultMessage: message || `+++ ${defaultMessage}`,
        },
        values,
      );
    },
    /**
     * Function to resolve i18n message using an 'intl' instance
     * @param id: message key in the bundle
     * @param values: object with values for tokens
     * @param defaultMessage: when id / tokens are not resolved in the bundle
     * @returns Resolved i18n FormattedMessage from react-intl
     */
    getI18nFormattedMessage: (id, values, defaultMessage) => (
      <FormattedMessage
        id={id}
        defaultMessage={messages[id] || defaultMessage}
        values={values}
      />
    ),
  });
};

/**
 * Static function to resolve i18n message if an 'intl' instance is not available
 * @param id: message key in the bundle
 * @param values: object with values for tokens
 * @param defaultMessage: when id / tokens are not resolved in the bundle
 * @returns Formatted i18n message from react-intl
 */
export const getI18nFormattedMessage = (id, values, defaultMessage) => (
  <FormattedMessage
    id={id}
    values={values}
    defaultMessage={defaultMessage}
  />
);
