import get from 'lodash/get';

const styles = ({
  breakpoint, color, fontSize, height, padding, margin, lineHeight,
} = {}) => ({
  page: {
    backgroundColor: get(color, 'uiBackground'),
    margin: `0 ${get(margin, 'm36')}`,
    minHeight: 'calc(100vh - 125px)',
  },
  pageBody: {
    paddingTop: get(padding, 'p38'),
    paddingBottom: get(height, 'h72'),
  },
  pageHeader: {
    padding: `0 ${get(padding, 'p36')}`,
  },
  pageTitle: {
    margin: 0,
  },
  helpIcon: {
    fontSize: get(fontSize, 'pageTitle'),
    marginTop: get(margin, 'm4'),
    paddingLeft: get(padding, 'p12'),
  },
  addButton: {
    marginTop: get(margin, 'm4'),
    '& button': {
      float: 'right',
      lineHeight: get(lineHeight, 'lh20'),
      marginRight: get(margin, 'm4'),
    },
  },
  pageFilterAndSort: {
    marginTop: get(margin, 'm12'),
    padding: `${get(padding, 'p12')} ${get(padding, 'p36')} 0 ${get(padding, 'p36')}`,
  },
  cardsContainer: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  alertMessages: {
    position: 'fixed',
    left: get(margin, 'm36'),
    right: get(margin, 'm36'),
    margin: 0,
    minHeight: get(height, 'h40'),
    width: 'auto',
  },
  [`@media (max-width: ${get(breakpoint, 'md')})`]: {
    page: {
      margin: 0,
    },
    alertMessages: {
      left: 0,
      right: 0,
    },
  },

  [`@media (max-width: ${get(breakpoint, 'xs')})`]: {
    pageHeader: {
      padding: `0 ${get(padding, 'p16')}`,
    },
    helpIcon: {
      display: 'none',
    },
    addBundleButton: {
      '& button': {
        float: 'left',
      },
    },
    pageFilterAndSort: {
      marginTop: get(margin, 'm24'),
      padding: `0 ${get(padding, 'p16')}`,
    },
  },
});

export default styles;
