// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Header, CookiePolicyBanner, GlobalHeader } from '../../components';
import theme from './index.theme';
import {
  getNewLandingPage,
} from '../../reducers/portalConfig';

const Main = ({ classes, children, newLandingPage }) => (
  <div data-apim-test="main-container">
    {newLandingPage ? <GlobalHeader /> : <Header />}
    <div data-apim-test="main-page-content" className={classes.MainPageContent}>{children}</div>
    <CookiePolicyBanner />
  </div>
);
export const mapStateToProps = (state) => ({
  newLandingPage: getNewLandingPage(state),
});
Main.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.object,
  newLandingPage: PropTypes.bool,
};

export default compose(withStyles(theme), connect(mapStateToProps))(Main);
