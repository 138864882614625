import get from 'lodash/get';

const styles = ({ margin, padding, color } = {}) => ({
  fieldContainer: {
    margin: `${get(margin, 'm2')} ${get(margin, 'm4')} ${get(margin, 'm2')} ${get(margin, 'm0')} !important`,
  },
  selectField: {
    padding: `${get(padding, 'p2')} ${get(padding, 'p10')} !important`,
  },
  tooltipContainer: {
    backgroundColor: get(color, 'gray'),
    padding: get(padding, 'p5'),
    borderRadius: get(padding, 'p10'),
    marginLeft: get(margin, 'm4'),
  },
});

export default styles;
