import { get } from 'lodash';
import { convertHexToRGB } from '../../../../utils';

const styles = ({ color, fontSize, typography }) => ({
  appConfigurationContainer: {
    padding: '0 36px',
    '& table': {
      backgroundColor: get(color, 'uiBackground'),
    },
    '& tr': {
      borderTop: `1px solid ${get(color, 'borderColor')}`,
    },
    '& th': {
      color: get(color, 'primaryButtonBackground'),
      padding: 12,
      cursor: 'pointer',
      border: 'none',
    },
    '& td': {
      padding: 12,
      border: 'none',
    },
  },
  expandCollapseButton: {
    color: get(color, 'primaryButtonBackground'),
    float: 'right',
  },
  tableHead: {
    '& th': {
      color: `${get(color, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontWeight: 'bold !important',
      cursor: 'auto',
    },
  },
  paginationContainer: {
    margin: '0 !important',
    '& .MuiTablePagination-toolbar': {
      margin: '0 !important',
    },
  },
  defaultChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'bodyText'), 0.05)} !important`,
    color: `${get(color, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  enabledChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'success'))} !important`,
    color: `${get(color, 'white')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  disabledChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'error'))} !important`,
    color: `${get(color, 'white')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  leftPanel: {
    backgroundColor: `${get(color, 'uiBackground')} !important`,
    width: '80%',
    padding: 20,
    '& a': {
      color: get(color, 'link'),
    },
    wordBreak: 'break-all',
  },
  leftPanelItemContainer: {
    marginTop: '10px !important',
    marginBottom: '10px !important',
  },
  leftPanelTitle: {
    backgroundColor: 'transparent !important',
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    fontWeight: 'bold !important',
    outline: 'transparent !important',
    '-webkit-font-smoothing': 'antialiased',
    lineHeight: '21px !important',
  },
  leftPanelDesc: {
    backgroundColor: 'transparent !important',
    lineHeight: '21px !important',
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    outline: 'transparent !important',
    '-webkit-font-smoothing': 'antialiased',
  },
});

export default styles;
