import get from 'lodash/get';

const styles = (theme) => ({
  runningIndicatorIcon: {
    color: get(theme, 'color.pageTitle'),
    padding: '7px',
    '& + p': {
      display: 'inline-block',
    },
  },
});

export default styles;
