import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { bool, shape } from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import {
  Grid,
  IconButton,
  withStyles,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { IconDanger } from 'mineral-ui-icons';

import styles from './styles';
import { getI18n } from '../../../../utils/intl';
import {
  DetailsPanelContainer,
} from '../../../../components';

export const getPortalCompatibility = ({ classes = {}, isCompatible }) => {
  const intl = getI18n(useIntl());
  if(isUndefined(isCompatible)) { return null; }
  return (
    isCompatible
    ?
    <Grid container component="span" className={classes.portalCompatibility}>
      <Grid item component="span">
        <IconButton data-compatible className={cx(classes.icon, classes.compatible)}>
          <CheckCircleIcon />
        </IconButton>
      </Grid>
      <Grid item component="span">
        {intl.getI18nMessage('label.proxy.details.page.overview.portal.compatible')}
      </Grid>
    </Grid>
    :
    <Grid container component="span" className={classes.portalCompatibility}>
      <Grid item component="span">
        <IconButton data-incompatible className={cx(classes.icon, classes.incompatible)}>
          <IconDanger />
        </IconButton>
      </Grid>
      <Grid item component="span">
        {intl.getI18nMessage('label.proxy.details.page.overview.portal.incompatible')}
      </Grid>
    </Grid>
  );
}

export const getPortalCompatibilityTooltip = (isCompatible) => {
  const intl = getI18n(useIntl());
  return (
    <Fragment>
      <div>
        {isCompatible ?
          intl.getI18nMessage('label.proxy.details.page.overview.portal.compatibility.tooltip')
        :
          intl.getI18nMessage('label.proxy.details.page.overview.portal.incompatibility.tooltip')
        }
      </div>
    </Fragment>
  );
}

export const PortalCompatibility = ({ classes = {}, isCompatible }) => {
  const intl = getI18n(useIntl());
  const portalCompatibility = getPortalCompatibility({ classes, isCompatible });
  const PortalCompatibilityTooltip = getPortalCompatibilityTooltip(isCompatible);
  return (
    <DetailsPanelContainer
      id="overview-portal-compatibility"
      title={intl.getI18nMessage('label.proxy.details.page.overview.portal.compatibility')}
      subTitle={portalCompatibility}
      tooltip={PortalCompatibilityTooltip}
      smallFont
    />
  );
}

export default (
  withStyles(styles)
)(PortalCompatibility);

PortalCompatibility.propTypes = {
  classes: shape({}),
  isCompatible: bool,
};
PortalCompatibility.displayName = 'PortalCompatibility';
