import { useState } from 'react';

const useNotification = ({ status: initialStatus, message: initialMessage }) => {
  const [status, setStatus] = useState(initialStatus);
  const [message, setMessage] = useState(initialMessage);

  const setNotification = ({ status = '', message = '' } = {}) => {
    setStatus(status);
    setMessage(message);
  };

  return [
    { status, message },
    setNotification,
  ];
}

export default useNotification;
