import { get } from 'lodash';

const styles = ({ color, fontSize, typography }) => ({
  pageTitle: {
    color: get(color, 'pageTitle'),
    fontFamily: get(typography, 'pageTitle'),
    fontSize: get(fontSize, 'pageTitle'),
    marginTop: 6,
  },
});

export default styles;
