import { isString } from 'lodash';

export const getParsedApiDetails = (apiDetails) => ({
  ...apiDetails,
  authenticationParameters: isString(apiDetails.authenticationParameters) ?
    JSON.parse(apiDetails.authenticationParameters) : apiDetails.authenticationParameters,
});

export const getParsedCustomFields = (customFields) =>
  customFields.map(customField => {
    const {
      Status: status,
      Description: description,
      Name: name,
      EntityType: entityType,
      Type: type,
      Uuid: uuid,
      Required: required,
      OptionsList: { results: optionsList },
    } = customField;

    return {
      status,
      description,
      name,
      entityType,
      type,
      uuid,
      required,
      optionsList: optionsList.map(({ Ordinal: ordinal, Value: value }) => ({ ordinal, value })),
    };
  });

export default () => {};
