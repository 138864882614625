import { get, filter } from 'lodash';

import * as constants from '../../constants';
import { getParsedGatewayDetails } from './responseParser';

const initialState = {
  hasError: false,
  isLoading: false,
  pageErrors: [],
  proxyDetails: {},
  gatewayDetails: {},
  gateways: [],
  proxyApis: {
    apis: 0,
    apisPendingDeployment: 0,
    apisDeployed: 0,
    apisError: 0,
    apisPublishedByPortal: 0,
    apisPublishedByPortalPendingDeployment: 0,
    apisPublishedByPortalDeployed: 0,
    apisPublishedByPortalError: 0,
    apisPublishedByGateway: 0,
    apisPublishedByGatewayPendingDeployment: 0,
    apisPublishedByGatewayDeployed: 0,
    apisPublishedByGatewayError: 0,
    apisPendingUndeployment: 0,
    apisPublishedByPortalPendingUndeployment: 0,
    apisPublishedByGatewayPendingUndeployment: 0,
    apisErrorUndeploy: 0,
    apisPublishedByPortalErrorUndeploy: 0,
    apisPublishedByGatewayErrorUndeploy: 0,
  },
  apis: {
    apis: {},
    apisPublishedByGateway: {},
    apisPublishedByPortal: {},
  },
  apps: [],
  organizations: {},
  proxyApiPlans: {},
  apiPlans: {},
  accountPlans: {},
  apisList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  keysList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  apiKeys: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  certificates: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  entitiesSummary: {},
  proxyDeploymentStatus: {
    results: [],
  },
  rateLimitQuota: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.API_PROXY_LOADING:
      return {
        ...state,
        hasError: false,
        pageErrors: [],
        isLoading: action.payload,
      };
    case constants.API_PROXY_RESET:
      return {
        ...initialState,
        isProxyDeleteSuccess: false,
      };
    case constants.PROXY_GET_SUCCESS:
      return {
        ...state,
        proxyDetails: get(action.payload, 'proxyDetails'),
        proxy: get(action.payload, 'proxy'),
        fetchProxyStatus: constants.SUCCESS,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_GET_ERROR:
      return {
        ...state,
        proxyUuid: '',
        proxyDetails: {},
        fetchProxyStatus: constants.FAIL,
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.PROXY_DELETE_SUCCESS:
      return {
        ...state,
        hasError: false,
        pageErrors: [],
        isProxyDeleteSuccess: true,
      };
    case constants.PROXY_DELETE_ERROR:
      return {
        ...state,
        hasError: true,
        pageErrors: action.payload,
        isProxyDeleteSuccess: false,
      };
    case constants.PROXY_DETAILS_GET_SUCCESS:
      return {
        ...state,
        proxyDetails: action.payload,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_DETAILS_GET_ERROR:
      return {
        ...state,
        proxyDetails: {},
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.PROXY_GATEWAY_GET_REQUEST:
      return {
        ...state,
        gatewayDetailsLoading: true,
        isLoading: true,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_GATEWAY_GET_SUCCESS:
      return {
        ...state,
        gatewayDetailsLoading: false,
        isLoading: false,
        gatewayDetails: action.payload.gatewayDetails,
        gateways: action.payload.gateways,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_GATEWAY_GET_ERROR:
      return {
        ...state,
        gatewayDetailsLoading: false,
        isLoading: false,
        gatewayDetails: {},
        gateways: [],
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.PROXY_CONFIG_UPDATE_NEW_SYNC_SUCCESS:
      return {
        ...state,
        proxyDetails: {
          ...state.proxyDetails, config: { useNewAutoSync: true },
        },
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_CONFIG_UPDATE_NEW_SYNC_ERROR:
      return {
        ...state,
        hasError: true,
        proxyDetails: {
          ...state.proxyDetails, config: { useNewAutoSync: false },
        },
        pageErrors: action.payload,
      };
    case constants.PROXY_APIS_GET_REQUEST:
      return {
        ...state,
        proxyApisLoading: true,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_APIS_GET_SUCCESS:
      return {
        ...state,
        proxyApis: {
          apis: get(action.payload, 'apis'),
          apisPublishedByPortal: get(action.payload, 'apisPublishedByPortal'),
          apisPublishedByGateway: get(action.payload, 'apisPublishedByGateway'),
        },
        proxyApisLoading: false,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_APIS_GET_ERROR:
      return {
        ...state,
        proxyApis: {
          apis: 0,
          apisPendingDeployment: 0,
          apisDeployed: 0,
          apisError: 0,
          apisPublishedByPortal: 0,
          apisPublishedByPortalPendingDeployment: 0,
          apisPublishedByPortalPendingUndeployment: 0,
          apisPublishedByPortalDeployed: 0,
          apisPublishedByPortalError: 0,
          apisPublishedByGateway: 0,
          apisPublishedByGatewayPendingDeployment: 0,
          apisPublishedByGatewayPendingUndeployment: 0,
          apisPublishedByGatewayDeployed: 0,
          apisPublishedByGatewayError: 0,
        },
        proxyApisLoading: false,
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.API_KEYS_COUNT_GET_REQUEST:
      return {
        ...state,
        apiKeysLoading: true,
        hasError: false,
        pageErrors: [],
      };
    case constants.API_KEYS_COUNT_GET_SUCCESS:
      return {
        ...state,
        apiKeysCount: action.payload.count,
        apiKeysLoading: false,
        hasError: false,
        pageErrors: [],
      };
    case constants.API_KEYS_COUNT_GET_ERROR:
      return {
        ...state,
        apiKeysCount: {},
        apiKeysLoading: false,
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.APIS_GET_SUCCESS:
      return {
        ...state,
        apis: {
          apis: get(action.payload, 'apis'),
          apisPublishedByPortal: get(action.payload, 'apisPublishedByPortal'),
          apisPublishedByGateway: get(action.payload, 'apisPublishedByGateway'),
        },
        proxyApisLoading: false,
        hasError: false,
        pageErrors: [],
      };
    case constants.APIS_GET_ERROR:
      return {
        ...state,
        apis: {
          apis: {},
          apisPublishedByPortal: {},
          apisPublishedByGateway: {},
        },
        proxyApisLoading: false,
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.ACCOUNT_PLANS_GET_SUCCESS:
      return {
        ...state,
        accountPlans: action.payload,
        hasError: false,
        pageErrors: [],
      };
    case constants.ACCOUNT_PLANS_GET_ERROR:
      return {
        ...state,
        accountPlans: {},
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.PROXY_API_PLANS_GET_REQUEST:
      return {
        ...state,
        proxyApiPlans: [],
        proxyApiPlansLoading: true,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_API_PLANS_GET_SUCCESS:
      return {
        ...state,
        proxyApiPlans: action.payload,
        proxyApiPlansLoading: false,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_API_PLANS_GET_ERROR:
      return {
        ...state,
        proxyApiPlans: {},
        proxyApiPlansLoading: false,
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.API_PLANS_GET_SUCCESS:
      return {
        ...state,
        apiPlans: action.payload,
        hasError: false,
        pageErrors: [],
      };
    case constants.API_PLANS_GET_ERROR:
      return {
        ...state,
        apiPlans: {},
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.PROXY_ORGANIZATIONS_GET_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_ORGANIZATIONS_GET_ERROR:
      return {
        ...state,
        organizations: [],
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.PROXY_APIS_LIST_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        pageErrors: [],
        apisList: action.payload,
      };
    case constants.PROXY_APIS_LIST_GET_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        pageErrors: action.payload,
        apisList:  {
          totalPages: 1,
          totalElements: 0,
          results: [],
        },
      };
    case constants.PROXY_APIS_KEYS_LIST_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        pageErrors: [],
        keysList: action.payload,
      };
    case constants.PROXY_APIS_KEYS_LIST_GET_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        pageErrors: action.payload,
        keysList: {
          totalPages: 1,
          totalElements: 0,
          results: [],
        },
      };
    case constants.PROXY_DEPLOYMENT_STATUS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        pageErrors: [],
        proxyDeploymentStatus: action.payload,
      };
    case constants.PROXY_DEPLOYMENT_STATUS_GET_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        pageErrors: action.payload,
        proxyDeploymentStatus: {
          results: [],
        },
      };
    case constants.PROXY_APIS_KEYS_COUNT_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        pageErrors: [],
        apiKeys: action.payload,
      };
    case constants.PROXY_APIS_KEYS_COUNT_GET_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        pageErrors: action.payload,
        apiKeys: {},
      };
    case constants.PROXY_RATE_LIMIT_QUOTA_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        pageErrors: [],
        rateLimitQuota: action.payload,
      };
    case constants.PROXY_RATE_LIMIT_QUOTA_GET_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        pageErrors: action.payload,
        rateLimitQuota: {},
      };
    case constants.PROXY_CERTIFICATES_LIST_GET_SUCCESS:
      return {
        ...state,
        certificates: action.payload,
        isLoading: false,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_CERTIFICATES_LIST_GET_ERROR:
      return {
        ...state,
        certificates: {
          totalPages: 1,
          totalElements: 0,
          results: [],
        },
        isLoading: false,
        hasError: true,
        pageErrors: action.payload,
      };
    case constants.PROXY_ENTITIES_SUMMARY_GET_SUCCESS:
      return {
        ...state,
        entitiesSummary: action.payload,
        isLoading: false,
        hasError: false,
        pageErrors: [],
      };
    case constants.PROXY_ENTITIES_SUMMARY_GET_ERROR:
      return {
        ...state,
        entitiesSummary: {},
        isLoading: false,
        hasError: true,
        pageErrors: action.payload,
      };
    default:
      return state;
  }
}

export const getUpdateProxyOrgsStatus = (state) => get(state, 'apiProxy.updateProxyOrgsStatus');
export const getIsLoading = (state) => get(state, 'proxy.isLoading');

export const getProxy = (state) => get(state, 'proxy.proxy');
export const getProxyDetails = (state) => get(state, 'proxy.proxyDetails');
export const getProxyDetailsBulkDeploymentStatus = (state) => get(state, 'proxy.proxyDetails.bulkDeploymentStatus');
export const getProxyDetailsBulkApiKeyDeploymentStatus = (state) => get(state, 'proxy.proxyDetails.apiKeyBulkDeploymentStatus');

export const getProxyGatewayDetailsLoading = (state) => get(state, 'proxy.gatewayDetailsLoading');
export const getProxyGatewayDetails = (state) => get(state, 'proxy.gatewayDetails');
export const getParsedProxyGatewayDetails = (state) => {
  const proxyGatewayDetails = getProxyGatewayDetails(state);
  return getParsedGatewayDetails(proxyGatewayDetails);
}
export const getProxyGateways = (state) => get(state, 'proxy.gateways');

export const getIsProxyDeleteSuccess = state => get(state, 'proxy.isProxyDeleteSuccess');
export const getDeleteProxyErrors = state => get(state, 'proxy.deleteProxyErrors');

export const getIsProxyApisLoading = (state) => get(state, 'proxy.proxyApisLoading');

export const getProxyOrganizations = (state) => get(state, 'proxy.organizations');

export const getApis = (state) => get(state, 'proxy.apis');

export const getApiKeysCount = (state) => get(state, 'proxy.apiKeysCount');
export const getIsApiKeysLoading = (state) => get(state, 'proxy.apiKeysLoading');

export const getAccountPlans = (state) => get(state, 'proxy.accountPlans');

export const getProxyApiPlans = (state) => get(state, 'proxy.proxyApiPlans');
export const getIsProxyApiPlansLoading = (state) => get(state, 'proxy.proxyApiPlansLoading');
export const getApiPlans = (state) => get(state, 'proxy.apiPlans');

export const getHasError = (state) => get(state, 'proxy.hasError');
export const getPageErrors = (state) => get(state, 'proxy.pageErrors');

export const getApisListTotalPages = (state) => get(state, 'proxy.apisList.totalPages');
export const getApisListTotalElements = (state) => get(state, 'proxy.apisList.totalElements');
export const getApisListResults = (state) => get(state, 'proxy.apisList.results');

export const getKeysListTotalPages = (state) => get(state, 'proxy.keysList.totalPages');
export const getKeysListTotalElements = (state) => get(state, 'proxy.keysList.totalElements');
export const getKeysListResults = (state) => get(state, 'proxy.keysList.results');

export const getProxyDeploymentStatus = (state) => get(state, 'proxy.proxyDeploymentStatus.results');

export const getApiKeys = (state) => {
  const deploymentStatus = getProxyDeploymentStatus(state);
  const keys = get(state, 'proxy.apiKeys.results');
  const keysStatus = filter(deploymentStatus, obj =>
    obj.type === 'ApiKeyDeployment');
  const keysDeployed = filter(keysStatus, obj =>
    obj.deploymentStatus === 'DEPLOYED');
  const keysPendingDeployment = filter(keysStatus, obj =>
    obj.deploymentStatus === 'PENDING_DEPLOYMENT');
  const keysPendingUnDeployment = filter(keysStatus, obj =>
    obj.deploymentStatus === 'PENDING_UNDEPLOYMENT');
  const keysError = filter(keysStatus, obj =>
    obj.deploymentStatus === 'ERROR');
    
  return { keysDeployed, keysPendingDeployment, keysPendingUnDeployment, keysError, keys };
};

export const getProxyApis = (state) => {
  const apisStatus = get(state, 'proxy.proxyApis') || {};
  const deploymentStatus = getProxyDeploymentStatus(state);
  apisStatus.apisPublishedByPortalDeployed = filter(deploymentStatus, obj =>
    obj.type === 'PortalApiDeployment' && obj.deploymentStatus === 'DEPLOYED');
  apisStatus.apisPublishedByPortalPendingDeployment = filter(deploymentStatus, obj =>
    obj.type === 'PortalApiDeployment' && obj.deploymentStatus === 'PENDING_DEPLOYMENT');
  apisStatus.apisPublishedByPortalPendingUndeployment = filter(deploymentStatus, obj =>
    obj.type === 'PortalApiDeployment' && obj.deploymentStatus === 'PENDING_UNDEPLOYMENT');
  apisStatus.apisPublishedByPortalError = filter(deploymentStatus, obj =>
    obj.type === 'PortalApiDeployment' && obj.deploymentStatus === 'ERROR');
  apisStatus.apisPublishedByGatewayDeployed = filter(deploymentStatus, obj =>
    obj.type === 'GWPublishedApiDeployment' && obj.deploymentStatus === 'DEPLOYED');
  apisStatus.apisPublishedByGatewayPendingDeployment = filter(deploymentStatus, obj =>
    obj.type === 'GWPublishedApiDeployment' && obj.deploymentStatus === 'PENDING_DEPLOYMENT');
  apisStatus.apisPublishedByGatewayPendingUndeployment = filter(deploymentStatus, obj =>
    obj.type === 'GWPublishedApiDeployment' && obj.deploymentStatus === 'PENDING_UNDEPLOYMENT');
  apisStatus.apisPublishedByGatewayError = filter(deploymentStatus, obj =>
    obj.type === 'GWPublishedApiDeployment' && obj.deploymentStatus === 'ERROR');

  return apisStatus;
};

export const getRateLimitQuota = (state) => get(state, 'proxy.rateLimitQuota');

export const getCertificatesListTotalPages = (state) => get(state, 'proxy.certificates.totalPages');
export const getCertificatesListTotalElements = (state) => get(state, 'proxy.certificates.totalElements');
export const getCertificatesListResults = (state) => get(state, 'proxy.certificates.results');

export const getEntitiesSummary = (state) => get(state, 'proxy.entitiesSummary');