import React, { Fragment, useEffect, useState, createContext } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { withStyles, Tooltip, Link } from '@material-ui/core';
import { arrayOf, bool, func, number, shape, string, object } from 'prop-types';
import { map, uniq, get } from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import HelpIcon from '@material-ui/icons/Help';

import { fetchAllTags } from '../../../../actions/api';
import { getAllPortalTags } from '../../../../reducers/api';
import { fetchApisOrganizations } from "../../../../actions/rateQuota";
import {
  getApisOrgsListResults,
  getApisOrgsListTotalElements,
  getApisOrgsListTotalPages,
} from "../../../../reducers/rateQuota";
import ListContainer from "../../../list";
import {
  QUOTA_ASSIGNMENT_API_ORG,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
  GRID_ROWS_PER_PAGE_OPTIONS,
} from "../../../../constants";
import {
  getI18n,
  getI18nFormattedMessage,
} from "../../../../utils/intl";
import {
  FilterByName,
  FilterSeparator,
  Tags,
  SortButtons,
} from './controls';
import FilterByTag from './filterByTag';
import styles from './styles';

export const TypeHeaderTooltip = () => {
  const intl = getI18n(useIntl());
  return (
    <div>
        {`${intl.getI18nMessage('label.rate.quota.apis.orgs.assigned.limit')}`}
    </div>
  );
}

export const TypeHeader = ({ classes, label }) => (
  <div className={classes.helpIconContainer}>
    <div>
      {label}
    </div>
    <Tooltip title={<TypeHeaderTooltip />} arrow placement="right">
      <HelpIcon className={classes.helpIcon} />
    </Tooltip>
  </div>
);

TypeHeader.propTypes = {
  classes: object,
  label: object,
};

export const getOrgListColumns = (classes) => [{
  id: 'name',
  label: getI18nFormattedMessage('label.api.name'),
  minWidth: 100,
  link: '/publish/apis/details',
}, {
  id: 'tags',
  label: getI18nFormattedMessage('label.api.tags'),
  minWidth: 50,
  value: Tags,
}, {
  id: 'apiOrgRateQuotaCount',
  label: (
    <TypeHeader classes={classes} label={getI18nFormattedMessage('label.apis.organizations.assigned.limit')} />
  ),
  minWidth: 50,
  value: (item) => {
    return (
      <div className={classes.count}>{item.apiOrgRateQuotaCount}</div>
    )
  },
}, {
  id: '',
  label: '',
  minWidth: 50,
  value: (item) => {
    const { uuid } = item;
    const url = `/publish/apis/details/${uuid}/organizations`
    return (
      <Link href={url}>
        <EditIcon size="0.82em" className={classes.statusIcon} />
      </Link>);
  },
}];

export const ApisOrgsListContext = createContext();

export const ApisOrganizations = (props) => {
  const {
    classes,
    rqUuid,
    isLoading,
    allTags = [],
    rateQuotaDetails,
    results = [],
    totalElements,
    totalPages,
  } = props;

  const intl = getI18n(useIntl());
  const [filterByName, setFilterByName] = useState('');
  const [filterByTags, setFilterByTags] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [filterByAnyApplied, setFilterByAnyApplied] = useState(false);
  const [filterByShowApplied, setFilterByShowApplied] = useState(false);
  const [assigned, setAssigned] = useState('');

  useEffect(() => {
    if (rqUuid && get(rateQuotaDetails, 'assignmentLevel') === QUOTA_ASSIGNMENT_API_ORG) {
      props.fetchApisOrganizations({
        rqUuid,
        name: filterByName,
        tags: filterByTags,
        hasApiOrgRateQuota: assigned,
        page: 0,
        size: rowsPerPage,
      });
      props.fetchAllTags({ size: 2000 });
    }
  }, [rqUuid, rateQuotaDetails]);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilterByAnyApplied(true);
      setFilterByShowApplied(false);
      setPage(0);
      props.fetchApisOrganizations({
        rqUuid,
        name: filterByName,
        tags: filterByTags,
        hasApiOrgRateQuota: assigned,
        page: 0,
        size: rowsPerPage,
      });
    }
  };

  const onTagsSelectionChange = (items) => {
    const tags = uniq(map(items, item => item.name));
    setFilterByTags(tags);
    setFilterByAnyApplied(true);
    setFilterByShowApplied(false);
    setPage(0);
    props.fetchApisOrganizations({
      rqUuid,
      name: filterByName,
      tags: tags,
      hasApiOrgRateQuota: assigned,
      page: 0,
      size: rowsPerPage,
    });
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchApisOrganizations({
      rqUuid,
      name: filterByName,
      tags: filterByTags,
      hasApiOrgRateQuota: assigned,
      page: newPage,
      size: rowsPerPage,
    });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchApisOrganizations({
      rqUuid,
      name: filterByName,
      tags: filterByTags,
      hasApiOrgRateQuota: assigned,
      page: 0,
      size: newRowsPerPage,
    });
  };

  const onClickTag = (tag) => {
    const tags = uniq([...filterByTags, tag]);
    setFilterByTags(tags);
    setFilterByAnyApplied(true);
    setFilterByShowApplied(false);
    props.fetchApisOrganizations({
      rqUuid,
      name: filterByName,
      tags: tags,
      hasApiOrgRateQuota: assigned,
      page: page,
      size: rowsPerPage,
    });
  };

  const onSortButtonClick = (e, assignedType) => {
    setAssigned(assignedType);
    setFilterByAnyApplied(false);
    setFilterByShowApplied(true);
    props.fetchApisOrganizations({
      rqUuid,
      name: filterByName,
      tags: filterByTags,
      hasApiOrgRateQuota: assignedType,
      page: page,
      size: rowsPerPage,
    });
  };

  const noResultsMessage = filterByAnyApplied ?
    `${intl.getI18nMessage('label.apis.orgs.filter.no.results')}` :
    filterByShowApplied ? `${intl.getI18nMessage('label.apis.orgs.show.filter.no.results')}` :
    intl.getI18nMessage('label.rate.quota.apis.orgs.no.results');

  return (
    <ApisOrgsListContext.Provider value={{ onClickTag }}>
      <ListContainer
        listPageId="rate-quota-apis-orgs-list"
        isLoading={isLoading}
        showListHeader={false}
        pageClass={classes.listContent}
        pageBodyClass={classes.pageBodyClass}
        pageFilterAndSortClass={classes.pageFilterAndSortClass}
        filterAndSortContent={(
          <Fragment>
            <FilterByName
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter.by')}
              value={filterByName}
              placeholder={intl.getI18nMessage('label.enter.api.name')}
              handleChange={onFilterByNameChange}
              onKeyPress={onFilterByNameKeyPress}
            />
            <FilterByTag
              {...props}
              defaultOptions={allTags}
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter')}
              value={filterByTags}
              placeholder={intl.getI18nMessage('label.gateway.bundle.list.page.filter.by.group.placeholder')}
              handleChange={onTagsSelectionChange}
              hideLabel
            />
            <FilterSeparator />
            <SortButtons
              onSortButtonClick={onSortButtonClick}
              fieldContainerClass={classes.sortContainerClass}
              assigned={assigned}
              activeButton={classes.activeButton}
            />
          </Fragment>
        )}
        columns={getOrgListColumns(classes) || []}
        rows={results}
        noResultsMessage={noResultsMessage}
        page={page}
        totalElements={totalElements}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        />
      </ApisOrgsListContext.Provider>
  );
};

ApisOrganizations.propTypes = {
  classes: shape({}),
  isLoading: bool,
  totalPages: number,
  totalElements: number,
  results: arrayOf(shape({})),
  allTags: arrayOf(shape({})),
  rateQuotaDetails: shape({}),
  fetchAllTags: func,
  fetchApisOrganizations: func,
  rqUuid: string,
};

const mapStateToProps = (state) => ({
  isLoading: false,
  results: getApisOrgsListResults(state),
  totalPages: getApisOrgsListTotalPages(state),
  totalElements: getApisOrgsListTotalElements(state),
  allTags: getAllPortalTags(state),
});

const mapDispatchToProps = {
  fetchApisOrganizations,
  fetchAllTags,
};
ApisOrganizations.displayName = 'ApisOrganizations';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ApisOrganizations);
