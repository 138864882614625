import get from 'lodash/get';

const styles = ({ breakpoint, color, padding } = {}) => ({
  multiValueCellContainer: {
    cursor: 'pointer',
  },
  multiValueCellHeading: {
    padding: 0,
    '& > span > p': {
      color: get(color, 'link'),
      paddingRight: get(padding, 'p20'),
    },
    '& ~ div': {
      padding: `${get(padding, 'p10')} 0 0 0`,
    },
    '& ~ div ul > li:not(:last-child)': {
      padding: `${get(padding, 'p10')} 0`,
    },
    '& ~ div ul > li:last-child': {
      padding: `${get(padding, 'p10')} 0 0 0`,
    },
  },

  [`@media (max-width: ${get(breakpoint, 'md')})`]: {
    multiValueCellHeading: {
      '& > span > p': {
        paddingRight: get(padding, 'p5'),
      },
    },
  },
});

export default styles;
