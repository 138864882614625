import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { get } from 'lodash';
import { number, bool, func, shape, arrayOf } from 'prop-types';

import { fetchEulasList, resetApp } from '../../../actions/eula';
import { getUserDetails } from '../../../reducers/portalConfig';
import {
  getEulasListTotalPages,
  getEulasListTotalElements,
  getEulasListResults,
  getIsEulaDeleteSuccess,
} from '../../../reducers/eula';
import styles from './styles';
import ListContainer from '../../list';
import {
  GRID_ROWS_PER_PAGE_OPTIONS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
  ALERT_SUCCESS,
 } from '../../../constants';
import {
  FilterByName,
} from './controls';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';

const LIST_COLUMNS = [{
  id: 'name',
  label: getI18nFormattedMessage('label.eula.name'),
  minWidth: 100,
  link: '/publish/eulas/details',
  },
];

export const ListRaw = (props) => {
  const {
    classes,
    isLoading,
    userContext,
    totalPages,
    totalElements = 0,
    results = [],
    isEulaDeleteSuccess,
  } = props;

  const intl = getI18n(useIntl());
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [filterByName, setFilterByName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);

  const emptyFunction = () => {};

  useEffect(() => {
    if (!get(userContext, 'permissions.API_EULA', []).includes('READ')) {
      props.push('/404');
    }
  }, [userContext]);

  useEffect(() => {
    props.fetchEulasList({ filterByName, page, rowsPerPage });
  }, []);

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  useEffect(() => {
    if (isEulaDeleteSuccess) {
      notifyMessages(
        ALERT_SUCCESS,
        intl.getI18nMessage('label.eula.deleted'),
      );
    }
  }, [isEulaDeleteSuccess]);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPage(0);
      props.fetchEulasList({ filterByName, page: 0, rowsPerPage });
    }
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchEulasList({ filterByName, page: newPage, rowsPerPage });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchEulasList({ filterByName, page: 0, rowsPerPage: newRowsPerPage });
  };

  const onAddEula = () => {
    props.resetApp();
    props.push('/publish/eulas/add');
  };

  const hasAddButton = get(userContext, 'permissions.API_EULA', []).includes('CREATE') ?
    onAddEula : null;

  return (
    <ListContainer
      listPageId="eulas-list"
      isLoading={isLoading}
      notificationId="eulas-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationStatus}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      pageHeaderTitle={intl.getI18nMessage('label.eula.list.page.title')}
      pageHeaderTooltipTitle={''}
      addButtonLabel={intl.getI18nMessage('label.eula.add.button')}
      onAdd={hasAddButton}
      showListHeader={true}
      filterAndSortContent={(
        <Fragment>
          <FilterByName
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            value={filterByName}
            placeholder={intl.getI18nMessage('label.eula.name')}
            handleChange={onFilterByNameChange}
            onKeyPress={onFilterByNameKeyPress}
          />
        </Fragment>
     )}
      columns={LIST_COLUMNS}
      rows={results}
      noResultsMessage={intl.getI18nMessage('label.eula.list.no.results')}
      page={page}
      totalElements={totalElements}
      totalPages={totalPages}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onChangePreviousPage={onChangePreviousPage}
      onChangeNextPage={onChangeNextPage}
      notifyMessages={emptyFunction}
    />
  );
};

ListRaw.propTypes = {
  classes: shape({}),
  userContext: shape({}),
  isLoading: bool,
  totalPages: number,
  totalElements: number,
  results: arrayOf(shape({})),
  fetchEulasList: func,
  resetApp: func,
  notifyMessages: func,
  push: func,
  isEulaDeleteSuccess: bool,
};

const mapStateToProps = (state) => ({
  isLoading: false,
  totalPages: getEulasListTotalPages(state),
  totalElements: getEulasListTotalElements(state),
  results: getEulasListResults(state),
  userContext: getUserDetails(state),
  isEulaDeleteSuccess: getIsEulaDeleteSuccess(state),
});

const mapDispatchToProps = {
  fetchEulasList,
  resetApp,
  push,
};
ListRaw.displayName = 'Eulas';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ListRaw);
