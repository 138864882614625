import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { shape, string } from 'prop-types';

import getDateTime from '../../../../../utils/datetime';
import { getI18n } from '../../../../../utils/intl';
import styles from './styles';

export const Footer = ({
  classes = {},
  id,
  deploymentType,
  tooltip,
  lastUpdated,
}) => {
  const intl = getI18n(useIntl());
  return (
    <Grid container className={classes.type}>
      {deploymentType &&
        <Grid
          item md={6} sm={6} xs={12}
          id={`${id}-status-type`} data-layer7-test={`${id}-status-type`}
        >
          <Grid container>
            <Grid item>
              <Typography variant="h5" className={classes.footerLabel}>
                {intl.getI18nMessage('label.proxy.details.page.overview.deployment.type')}
              </Typography>
            </Grid>
            {tooltip &&
              <Tooltip arrow placement="right" title={tooltip} >
                <IconButton
                  size="small" edge="start" color="secondary"
                  className={classes.helpIcon}
                  aria-label={`${id}-help`}
                >
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            }
          </Grid>
          <Typography variant="body1">
            {deploymentType}
          </Typography>
        </Grid>
      }
      <Grid
        item md={6} sm={6} xs={12}
        id={`${id}-status-updated`} data-layer7-test={`${id}-status-updated`}
      >
        {lastUpdated &&
          <Fragment>
            <Typography variant="h5" className={classes.footerLabel}>
              {intl.getI18nMessage('label.last.updated')}
            </Typography>
            <Typography variant="body1">
              {getDateTime(lastUpdated)}
            </Typography>
          </Fragment>
        }
      </Grid>
    </Grid>
  );
}

Footer.propTypes = {
  classes: shape({}),
  id: string,
  deploymentType: string,
  lastUpdated: string,
  tooltip: string,
};
Footer.displayName = 'Footer';

export default (
  withStyles(styles)
)(Footer);
