import { get } from 'lodash';

export default ({ color, typography, misc }) => ({
  HeaderBrandingWrapper: {
    color: get(color, 'title'),
    textDecoration: 'none',

    '&:link, &:visited, &:focus': {
      color: get(color, 'title'),
    },

    '&:hover': {
      color: get(color, 'title'),
    },

    '&:active': {
      color: get(color, 'title'),
    },

    '&:focus, &:active': {
      transition: 'none',
      outline: 'none',
    },
  },

  HeaderBrandingLogo: {
    alignItems: 'center',
    display: 'flex',
  },

  HeaderBrandingLogoImage: {
    display: 'inline-block',
    float: 'left',
    position: 'relative',
    top: 1,
    height: 'auto',
    maxHeight: 33,
    maxWidth: 50,
    marginRight: 15,
  },

  HeaderBrandingServiceTitle: {
    display: 'inherit',
    height: '100%',
    marginBottom: 4,
    color: get(color, 'siteTitle'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: 18,
  },

  HeaderBrandingSiteTitle: {
    color: get(color, 'smallText'),
    opacity: 0.6,
    display: 'inherit',
    fontFamily: get(typography, 'bodyText'),
    fontSize: 13,
    height: '100%',
  },

  [`@media (min-width: ${get(misc, 'breakpointMedium')}px)`]: {
    HeaderBrandingWrapper: {
      marginLeft: 20,
      marginRight: 40,
    },
  },

  [`@media (min-width: ${get(misc, 'breakpointMedium')}px)`]: {
    HeaderBranding__ServiceTitle: {
      display: 'inherit',
    },
    HeaderBranding__SiteTitle: {
      display: 'inherit',
    },
  },
});
