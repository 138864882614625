import React, { Fragment, useState, useEffect } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormLabel,
  Typography,
  makeStyles,
  TextField,
  ListItem,
  Grid,
  Button,
  Divider,
  Badge,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/CancelRounded';
import AddIcon from '@material-ui/icons/AddCircleRounded';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { isEmpty, isFunction } from 'lodash';
import clsx from 'clsx';

import { DynamicForm, TableWithSelectableRow } from '../../components';
import styles from './styles';
import {
  TYPE_API_GROUP,
} from '../../constants';

export default function ExpandPanelContainer(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    id,
    error,
    isDeleteIcon,
    isAddIcon,
    isMoveUpIcon,
    isMoveDownIcon,
    isAdded = (() => false),
    onDeleteItem,
    onAddItem,
    onMoveUpItem,
    onMoveDownItem,
    title,
    subtitle,
    panelTitle = '',
    hasPanelTitle = false,
    placeHolderText,
    defaultList = [],
    onLoadMore,
    isLoadMore,
    hasSearch,
    onSearchTextChange,
    loadMoreText,
    handleChangeDynamicForm,
    getSelectedItem,
    onSelectItem,
    SecondaryLabelComponent,
    ExternalFilter,
    panelSubtitle,
    emptyListMessage,
    isApiDetails = false,
    isApiGroup = false,
    detailContainerClass,
    panelItemName,
  } = props;

  const [searchText, setSearchText] = useState('');
  const [filteredList, setFilterdList] = useState(defaultList);

  useEffect(() => {
    setFilterdList(defaultList);
  }, [defaultList]);

  const onTextChange = (e) => {
    const searchTerm = e.target.value;
    setSearchText(searchTerm);
    if (!onSearchTextChange) {
      const filtered = defaultList.filter((item) => (
        item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      ));
      setFilterdList(filtered);
    }
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSearchTextChange(searchText);
    }
  };

  const handleSearch = () => {
    onSearchTextChange(searchText);
  };

  const loadMoreClick = () => {
    setSearchText('');
    onLoadMore();
  };

  const onDelete = (e, item, i) => {
    e.stopPropagation();
    onDeleteItem(item, i);
  };

  const onAdd = (e, item, i) => {
    e.stopPropagation();
    onAddItem(item, i);
  };

  const onMoveUp = (e, item, i) => {
    e.stopPropagation();
    onMoveUpItem(item, i);
  };

  const onMoveDown = (e, item, i) => {
    e.stopPropagation();
    onMoveDownItem(item, i);
  };

  let searchFieldClasses = clsx('expand-panel-search', classes.textField);
  if (hasSearch && ExternalFilter) {
    searchFieldClasses = clsx(searchFieldClasses, classes.smallSearchField);
  }

  const classRoot = error ? clsx(classes.root, classes.rootError) : classes.root;
  return (
    <div className={classRoot} id={id} data-apim-test={id}>
      <div data-apim-test={`${id}-title`} className={classes.listTitle}>{title}</div>
      <div data-apim-test={`${id}-sub-title`} className={clsx('listSubtitle', classes.listSubtitle)}>
        {subtitle}
      </div>
      <div data-apim-test={`${id}-search-container`} className={classes.searchContainer}>
        {hasSearch &&
          <TextField
            className={searchFieldClasses}
            id="expand-search"
            data-apim-test="expand-search"
            margin="normal"
            variant="outlined"
            onKeyPress={handleSearchKeyPress}
            placeholder={placeHolderText}
            onChange={(e) => onTextChange(e)}
            value={searchText}
            InputProps={{
              className: classes.textField,
              type: 'search',
            }}
          />
        }
        {hasSearch && onSearchTextChange &&
          <div data-apim-test="expand-search-icon-container">
            <IconButton
              className={classes.searchIcon}
              onClick={() => handleSearch()}
              onFocus={e => e.stopPropagation()}
            >
              <PageviewRoundedIcon
                className={classes.searchRoundedIcon}
                size="large"
              />
            </IconButton>
          </div>
        }
        {ExternalFilter &&
          <div
            data-apim-test="expand-search-filter-container"
            className={clsx(classes.smallSearchField, classes.externalFilterContainer)}
          >
            <ExternalFilter />
          </div>
        }
      </div>
      {isEmpty(filteredList) && emptyListMessage &&
        <FormLabel
          data-apim-test={`${id}-empty-message`}
          className={classes.emptyListMessage}
        >
          {emptyListMessage}
        </FormLabel>
      }
      {panelTitle && hasPanelTitle &&
        <div className={classes.panelTitle}>{panelTitle}</div>
      }
      {filteredList.map((item = {}, i) =>
        <ExpansionPanel
          className="expand-panel-list-item"
          key={item.uuid + i.toString()}
          classes={{
            root: classes.summaryRoot,
          }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            {isDeleteIcon &&
              <Fragment>
                <IconButton
                  className={classes.iconRoot}
                  size="small"
                  onClick={e => onDelete(e, item, i)}
                  onFocus={e => e.stopPropagation()}
                >
                  <DeleteIcon />
                </IconButton>
              </Fragment>
            }
            {isAddIcon && !isAdded(item) &&
              <Fragment>
                <IconButton
                  className={classes.iconRoot}
                  size="small"
                  onClick={e => onAdd(e, item, i)}
                  onFocus={e => e.stopPropagation()}
                >
                  <AddIcon />
                </IconButton>
              </Fragment>
            }
            {isMoveUpIcon &&
              <Fragment>
                <IconButton
                  className={classes.iconRoot}
                  size="small"
                  onClick={e => onMoveUp(e, item, i)}
                  onFocus={e => e.stopPropagation()}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              </Fragment>
            }
            {isMoveDownIcon &&
              <Fragment>
                <IconButton
                  className={classes.iconRoot}
                  size="small"
                  onClick={e => onMoveDown(e, item, i)}
                  onFocus={e => e.stopPropagation()}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </Fragment>
            }
            <Grid container className={classes.labelContainer}>
              <Grid item {...(panelSubtitle && { md: 6 })}>
                <FormLabel classes={{ root: classes.labelRoot }}>
                  {isFunction(panelItemName) ? panelItemName(item, classes) : item.name}
                </FormLabel>
                {SecondaryLabelComponent &&
                  <SecondaryLabelComponent item={item} />
                }
              </Grid>
              {panelSubtitle &&
                <Grid item md={6}>
                  {isFunction(panelSubtitle) ? panelSubtitle(item) : panelSubtitle}
                </Grid>
              }
            </Grid>
            {(item.type === TYPE_API_GROUP || (isApiGroup && !isApiDetails)) &&
              <Fragment>
                <Badge
                  classes={{ badge: classes.badge }}
                  overlap="rectangle"
                  badgeContent="API Group"
                />
              </Fragment>
            }
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: clsx(detailContainerClass, classes.expansionPanelDetails) }}
          >
            <Typography variant="body1">
              {item.description}
              {!isEmpty(item.arguments) &&
                <DynamicForm
                  fields={item.arguments}
                  handleChange={handleChangeDynamicForm}
                  index={i}
                />
              }
            </Typography>
            {isApiDetails && item.portalStatus &&
              <div className={classes.detailsContainer}>
                <div className={classes.detailsCol}>{'API State'}</div>
                <div className={classes.detailsCol}>{item.portalStatus}</div>
              </div>
            }
            {isApiGroup && item.apis && item.apis.length > 0
              && item.apis.map((api = {}) => (
                <div key={api.uuid} className={classes.detailsContainer}>
                  <div className={classes.detailsCol}>{api.name}</div>
                  <div className={classes.detailsCol}>{api.status}</div>
                </div>
              ))
            }
            {(isApiDetails || isApiGroup) &&
              <Divider className={classes.detailDivider} />
            }
            {!isEmpty(item.availableItems) &&
              <TableWithSelectableRow
                items={item.availableItems}
                selectedRow={getSelectedItem(item)}
                onSelectRow={(selectedRow) => onSelectItem(item.uuid, selectedRow)}
              />
            }
          </ExpansionPanelDetails>
        </ExpansionPanel>,
      )}
      {isLoadMore &&
        <ListItem key="button-loadmore">
          <Grid container justify="center">
            <Button
              variant="contained"
              color="secondary"
              className={classes.loadMore}
              onClick={loadMoreClick}
            >
              {loadMoreText}
            </Button>
          </Grid>
        </ListItem>
      }
    </div>
  );
}

ExpandPanelContainer.propTypes = {
  id: PropTypes.string,
  error: PropTypes.bool,
  isDeleteIcon: PropTypes.bool,
  onDeleteItem: PropTypes.func,
  isAddIcon: PropTypes.bool,
  isAdded: PropTypes.func,
  onAddItem: PropTypes.func,
  isMoveUpIcon: PropTypes.bool,
  onMoveUpItem: PropTypes.func,
  isMoveDownIcon: PropTypes.bool,
  onMoveDownItem: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  panelTitle: PropTypes.string,
  hasPanelTitle: PropTypes.bool,
  placeHolderText: PropTypes.string,
  defaultList: PropTypes.arrayOf(PropTypes.shape({})),
  onLoadMore: PropTypes.func,
  isLoadMore: PropTypes.bool,
  hasSearch: PropTypes.func,
  onSearchTextChange: PropTypes.func,
  loadMoreText: PropTypes.string,
  handleChangeDynamicForm: PropTypes.func,
  getSelectedItem: PropTypes.func,
  onSelectItem: PropTypes.func,
  SecondaryLabelComponent: PropTypes.func,
  ExternalFilter: PropTypes.func,
  panelSubtitle: PropTypes.func,
  emptyListMessage: PropTypes.string,
  isApiDetails: PropTypes.bool,
  isApiGroup: PropTypes.bool,
  detailContainerClass: PropTypes.shape({}),
  panelItemName: PropTypes.func,
};
