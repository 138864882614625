import axios from 'axios';
import { flatten, forEach, map, isEmpty, isArray, get } from 'lodash';
import { stringify } from 'query-string';

import * as constants from '../../constants';
import { AXIOS_DEFAULT_OPTIONS } from '../';
import { getConfig } from '../../reducers/portalConfig';
import { getApiDetails, getUserContext } from '../../reducers/api';
import {
  getCreateData,
  getUpdateData,
  getUpdateDetails,
  buildDocumentId,
} from './requestParser';
import { getValidationErrors } from '../../utils';
import { stringifyUrl } from '../../utils/actions';

export const showLoading = (isLoading) => dispatch =>
  dispatch({
    type: constants.APP_LOADING,
    isLoading,
  });

const initApi = (dispatch, isLoading = true) =>
  dispatch({
    type: constants.APP_LOADING,
    isLoading,
  });

export const fetchAvailableUsers = (apiUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/permissions/apis/${apiUuid}/available-users`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_PERMISSION_AVAILABLE_USER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchSelectedUsers = (apiUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/api-management/internal/permissions/apis/${apiUuid}/selected-users`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_PERMISSION_SELECTED_USER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchRecentAPISearches = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/v2/users/activities/searches?type=API&top=5`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_RECENT_SEARCH_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAPIs = (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: constants.API_LIST_REQUEST,
    });
    const defaultParams = {
      size: 10,
      page: 0,
    };
    const { tags: tagsParam, searchText: searchParam, ...otherParams } = params;
    const updatedParams = {
      ...defaultParams,
      ...otherParams,
      ...(isArray(tagsParam) && (tagsParam.length > 0) && ({ tags: tagsParam.join(',') })),
      ...(searchParam && ({ name: searchParam })),
    };
    const queryString = fetchQueryString(updatedParams);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis${queryString}`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_LIST_SUCCESS,
          isLoading: false,
          payload: response.data,
          page: updatedParams.page,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

const fetchQueryString = (params) => {
  let queryString = stringify(params, true);
  queryString = queryString ? `?${queryString}` : '';
  return queryString;
  };

export const fetchAutoSuggestions = (params) =>
  async (dispatch, getState) => {
    const defaultParams = {
      size: 10,
      page: 0,
    };
    const { tags: tagsParam, searchText: searchParam, ...otherParams } = params;
    const updatedParams = {
      ...defaultParams,
      ...otherParams,
      ...(isArray(tagsParam) && (tagsParam.length > 0) && ({ tags: tagsParam.join(',') })),
      ...(searchParam && ({ name: searchParam })),
    };
    const queryString = fetchQueryString(updatedParams);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis${queryString}`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_AUTO_SUGGESTION_SUCCESS,
          isLoading: false,
          payload: response.data,
          page: updatedParams.page,
        });
      })
      .catch(() =>
        dispatch({
          type: constants.API_AUTO_SUGGESTION_ERROR,
        }),
      );
  };

export const fetchLatencyData = (apiIds = []) =>
  async (dispatch, getState) => {
    dispatch({
      type: constants.API_LIST_ANALYTICS_LATENCY_REQUEST,
    });
    const config = getConfig(getState());
    const { portal } = config;
    const paramsObj = {
      buckets: 'apis',
      sortorder: 'desc',
      sortby: 'avg',
      apiIds: apiIds.join(','),
    };
    const paramsString = stringify(paramsObj, { encode: true });
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/analytics/metrics/v1/latency/apis?${paramsString}`;
    await axios.get(url, { credentials: 'include' })
    .then((response) => {
      dispatch({
        type: constants.API_LIST_ANALYTICS_LATENCY_SUCCESS,
        payload: response.data,
      });
    })
    .catch(() =>
      dispatch({
        type: constants.API_LIST_ANALYTICS_LATENCY_ERROR,
        payload: {},
      }),
    );
};

export const fetchOrganizations = (size, pageNumber) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/internal/organizations?status=ENABLED&size=${size}&page=${pageNumber}`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_PERMISSION_ORGANIZATIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAllOrganizations = (size = 1000, pageNumber = 0) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/internal/organizations?status=ENABLED&size=${size}&page=${pageNumber}`;
    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_PERMISSION_ALL_ORGANIZATIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchSelectedOrganizations = (apiUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/api-management/internal/apis/${apiUuid}/selected-organizations`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_PERMISSION_SELECTED_ORGANIZATIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const setRedirectApiTab = () => dispatch =>
  dispatch({
    type: constants.API_PERMISSION_REDIRECT_API_TAB,
  });

export const fetchApi = (apiUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAllTags = ({ size = 100 } = {}) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const urlPrefix = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/tags`;

    const getCascadingRequests = (totalPages) => {
      const cascadingRequests = [];
      for (let page = 1; page < totalPages; page++) {
        const url = stringifyUrl({
          url: urlPrefix,
          query: { page, size },
        });
        cascadingRequests.push(
          axios.get(url, AXIOS_DEFAULT_OPTIONS),
        );
      }
      return cascadingRequests;
    };

    const url = stringifyUrl({
      url: urlPrefix,
      query: { page: 0, size },
    });

    dispatch({
      type: constants.API_TAGS_ALL_TAGS_REQUEST,
      isLoading: false,
    });

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(async response => {
        const { totalPages } = response.data;
        if (totalPages > 1) {
          const cascadingResponses = await Promise.all(
            getCascadingRequests(totalPages),
          );
          const cascadingResponsesResults = cascadingResponses
            .map(res => res.data.results);
          return {
            ...response.data,
            results: [
              ...response.data.results,
              ...flatten(cascadingResponsesResults),
            ],
          };
        }
        return response.data;
      })
      .then(response =>
        dispatch({
          type: constants.API_TAGS_ALL_TAGS_SUCCESS,
          payload: response,
        }),
      )
      .catch(error =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAPITags = (apiUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/tags`;
    dispatch({
      type: constants.API_TAGS_API_TAGS_REQUEST,
    });
    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_TAGS_API_TAGS_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => // todo: check errors
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchSwaggerFile = (uuid: string) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/2.0/Apis('${uuid}')/SpecContent`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_SWAGGER_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => // todo: check errors
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const createTags = (data) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/tags`;
    await axios.post(url, data, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_TAGS_CREATE_TAGS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApiPermitted = (apiUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/api-management/internal/permissions/apis/${apiUuid}/permitted`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_PERMISSION_PERMITTED_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_PERMISSION_PERMITTED_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApiEulas = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/eulas?page=0&size=1000`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_EULAS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const checkApiNameUnique = (apiName, currentId) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/Portal.svc/ApiNameUnique()`;
    await axios.get(url,
      {
        params: {
          Name: `'${apiName}'`,
          CurrentId: `'${currentId}'`,
        },
      },
      {
        credentials: 'include',
      })
      .then((response) => {
        dispatch({
          type: constants.API_NAME_UNIQUE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchPolicyTemplates = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/policyTemplates`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.POLICY_TEMPLATES_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const checkProxyUrlUnique = (name) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/Portal.svc/ProxyUrlUnique()?ProxyUrl='${name}'`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_PROXY_URL_UNIQUE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchSelectedManageOrg = (Uuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/1.0/organizations/${Uuid}`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_PERMISSION_SELECTED_MANAGE_ORGANIZATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchCustomFields = (entityName = 'API') =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/Portal.svc/CustomFields?%24inlinecount=allpages&%24filter=EntityType+eq+%27${entityName}%27+and+Status+eq+%27ENABLED%27`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.CUSTOM_FIELDS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAssets = (apiUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/assets`;
    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_ASSETS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchUsage = (apiUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/usage`;
    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_USAGE_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const saveAssets = async (apiDetails, uploadedAssets, portal, dispatch,
  isUploadFile, filesToDelete) => {
  const formData = new FormData();
  if (isUploadFile) {
    forEach(uploadedAssets, ({ blob, name }) => {
      if (blob) { formData.append('files', blob, name); }
    });
  }

  if (filesToDelete.length > 0) {
    forEach(filesToDelete, (uuid) => {
      formData.append('filesToDelete', uuid);
    });
  }
  const url = `${portal.hostname}/admin/api-management/internal/apis/${apiDetails.uuid}/assets`;
  await axios.post(url, formData, { credentials: 'include' })
    .then((response) => {
      const data = apiDetails;
      if (isUploadFile) {
        data.files = response.data;
      }
      dispatch({
        type: constants.API_SAVE_SUCCESS,
        payload: data,
      });
    })
    .catch((error) =>
      dispatch({
        type: constants.API_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};

export const saveApi = (details, uploadedAssets) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const userContext = getUserContext(getState());
    const data = getCreateData(userContext, details);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/`;
    await axios.post(url, data, { credentials: 'include' })
      .then((response) => {
        if (uploadedAssets && !isEmpty(uploadedAssets.files)) {
          const apiDetails = response.data;
          saveAssets(apiDetails, uploadedAssets.files, portal, dispatch, true, []);
        } else {
          dispatch({
            type: constants.API_SAVE_SUCCESS,
            payload: response.data,
          });
        }
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateApi = (apiUuid, details) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = getUpdateData(apiUuid, details);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}`;
    await axios.put(url, data, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_UPDATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateApiDetails = (apiUuid, details) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const formData = getUpdateDetails(apiUuid, details);
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}`;

    await axios.put(url, formData, { credentials: 'include' })
      .then(() => {
        dispatch({
          type: constants.API_SAVE_SUCCESS,
          payload: details,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateApiDetailsWithAssets = (apiUuid, details, initialAssets, uploadedAssets,
                                 isUploadFile, filesToDelete) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const formData = getUpdateDetails(apiUuid, details);
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}`;

    await axios.put(url, formData, { credentials: 'include' })
      .then(() => {
        const apiDetails = details;
        if (isUploadFile || filesToDelete.length > 0) {
          saveAssets(apiDetails, uploadedAssets.files, portal, dispatch,
            isUploadFile, filesToDelete);
        } else {
          dispatch({
            type: constants.API_SAVE_SUCCESS,
            payload: apiDetails,
          });
        }
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const publishApi = async (apiUuid, portal, dispatch) => {
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/publish`;
  await axios.put(url, '', { credentials: 'include' })
    .then((response) =>
      dispatch({
        type: constants.API_PUBLISH_SAVE_SUCCESS,
        isLoading: false,
        payload: response,
      }),
    )
    .catch((error) =>
      dispatch({
        type: constants.API_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};

export const fetchApiCustomFields = (apiUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/custom-fields`;
    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_CUSTOM_FIELDS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const saveApiCustomFields = (apiUuid, customFieldValues) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = map(customFieldValues, (value, key) => ({
      customFieldUuid: key,
      value,
    }));
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/custom-fields`;
    await axios.put(url, data, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_CUSTOM_FIELDS_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApiPolicyEntities = (apiUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/policy-entities`;
    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_POLICY_TEMPLATES_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const saveApiPolicyEntities = (apiUuid, data) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/policy-entities`;
    await axios.put(url, data, { credentials: 'include' })
      .then(() => {
        publishApi(apiUuid, portal, dispatch);
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

const callSaveOrganizationsAPI = (data, apiUuid, portal, dispatch) => {
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/organizations`;
  axios.put(url, data, { credentials: 'include' })
    .then(() => {
      dispatch({
        type: constants.API_PERMISSION_SAVE_ORGANIZATIONS_SUCCESS,
      });
    })
    .catch((error) =>
      dispatch({
        type: constants.API_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};

const saveManageOrgApi = (
  selectedManageOrg,
  apiDetails,
  apiUuid,
  portal,
  dispatch,
  retainManagingOrgVisibility,
) => {
  const data = getUpdateData(apiUuid, apiDetails);
  data.managingOrgUuid = selectedManageOrg ? selectedManageOrg.uuid : null;
  let url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}`;
  if (retainManagingOrgVisibility === true || retainManagingOrgVisibility === false) {
    url = `${url}?retainManagingOrgVisibility=${retainManagingOrgVisibility}`;
  }
  axios
    .put(url, data, { credentials: 'include' })
    .then(() => {
      dispatch({
        type: constants.API_PERMISSION_SAVE_SUCCESS,
        payload: data,
      });
    })
    .catch((error) =>
      dispatch({
        type: constants.API_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};

export const savePermissions = (data, apiUuid, selectedManageOrg, isSavePermissionApi,
  isSaveManageOrgApi, retainManagingOrgVisibility) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const apiDetails = getApiDetails(getState());
    if (!isSavePermissionApi && isSaveManageOrgApi) {
      saveManageOrgApi(selectedManageOrg, apiDetails, apiUuid, portal, dispatch,
          retainManagingOrgVisibility);
    } else {
      const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/permissions/apis/${apiUuid}`;
      await axios.put(url, data, { credentials: 'include' })
        .then(() => {
          if (selectedManageOrg === null || apiDetails.managingOrgUuid !== selectedManageOrg.uuid) {
            saveManageOrgApi(selectedManageOrg, apiDetails, apiUuid, portal, dispatch);
          } else {
            dispatch({
              type: constants.API_PERMISSION_SAVE_SUCCESS,
              payload: apiDetails,
            });
          }
        })
        .catch((error) =>
          dispatch({
            type: constants.API_ERROR,
            payload: getValidationErrors(error),
          }),
        );
    }
  };

export const saveApiAccessStatus = (accessStatus, apiUuid, canSaveOrgadminDev,
  dataToSave) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const apiDetails = getApiDetails(getState());
    const data = getUpdateData(apiUuid, apiDetails);
    data.uuid = apiUuid;
    data.accessStatus = accessStatus;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}`;

    await axios.put(url, data, { credentials: 'include' })
      .then((response) => {
        if (accessStatus === constants.API_ACCESS_STATUS_PRIVATE && !canSaveOrgadminDev) {
          callSaveOrganizationsAPI(dataToSave, apiUuid, portal, dispatch);
        } else {
          dispatch({
            type: constants.API_PERMISSION_ACCESS_STATUS_SAVE_SUCCESS,
            payload: response.data,
          });
        }
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const saveTagsAssociation = (data, apiUuid) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/tags`;
    dispatch({
      type: constants.API_TAGS_ASSOCIATE_REQUEST,
      isLoading: false,
    });
    await axios.put(url, data, { credentials: 'include' })
      .then(() => {
        dispatch({
          type: constants.API_TAGS_ASSOCIATE_SUCCESS,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchRateLimitQuotas = (size = 2000, page = 0, assignmentLevel = 'API') =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      assignmentLevel,
      page,
      size,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/rate-quotas?${params}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: constants.API_RATE_QUOTA_GET_LIST_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApiRateLimitQuota = (uuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${uuid}/rate-quotas`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: constants.API_RATE_QUOTA_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApiOrgAccess = ({ apiUuid, filterByName, access, page, rowsPerPage }) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      orgName: filterByName,
      apiOrgAccessStatus: access,
      page,
      size: rowsPerPage,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/internal/access/organizations-api/${apiUuid}?${params}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: constants.API_ORGANIZATION_ACCESS_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const addApiRateLimitQuota = (apiUuid, data) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/org-rate-quotas`;
    await axios.patch(url, data, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_ORG_ACCESS_BUTTONS_ADD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const removeApiRateLimitQuota = (apiUuid, data) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/org-rate-quotas?action=remove`;
    await axios.patch(url, data, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_ORG_ACCESS_BUTTONS_REMOVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

  export const addApiVisibility = (apiUuid, data) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/organizations`;
    await axios.patch(url, data, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_ORG_ACCESS_BUTTONS_ADD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const removeApiVisibility = (apiUuid, data) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}/organizations?action=remove`;
    await axios.patch(url, data, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: constants.API_ORG_ACCESS_BUTTONS_REMOVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchDeploymentProxyList = () =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies?status=ACTIVE`;
    await axios.get(url, { credentials: 'include' })
      .then(response =>
        dispatch({
          type: constants.API_DETAILS_GET_PROXY_LIST_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: constants.API_DETAILS_GET_PROXY_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchDeploymentByApi = (apiUuid) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/apis/${apiUuid}/proxies`;
    await axios.get(url, { credentials: 'include' })
      .then(response => {
        dispatch({
          type: constants.API_DETAILS_GET_DEPLOYMENT_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch(error =>
        dispatch({
          type: constants.API_DETAILS_GET_DEPLOYMENT_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

const getDeploymentValidationErrors = (error) => get(error, 'validationErrors');
const processDeploymentErrors = (validationErrors, defaultMessage) => {
  let errorMessage;
  const keys = validationErrors ? Object.keys(validationErrors) : [];
  if (keys.length > 0) {
    errorMessage = map(keys, (key) => validationErrors[key].devMessage).join('\n');
  }
  return errorMessage || defaultMessage;
};

export const deployApi = (apiUuid, proxyUuid) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = { proxyUuid };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/apis/${apiUuid}/proxies`;
    await axios.post(url, data, { credentials: 'include' })
      .then(response =>
        dispatch({
          type: constants.API_DETAILS_DEPLOY_PROXY_SUCCESS,
          payload: response.data,
        }),
      )
      .catch((error) => {
        const statusCode = get(error, 'response.status');
        if (statusCode === constants.HTTP_STATUS_UNAUTHORIZED) {
          window.location.href =
            `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
        }

        const responseError = get(error, 'response.data');
        const strErrorMessage = (statusCode === constants.HTTP_STATUS_BAD_REQUEST)
          ? processDeploymentErrors(getDeploymentValidationErrors(responseError), '')
          : get(error, 'message');
        dispatch({
          type: constants.API_DETAILS_DEPLOY_PROXY_ERROR,
          payload: {
            proxyUuid,
            errorMessage: strErrorMessage,
          },
        });
      },
    );
  };

export const undeployApi = (apiUuid, proxyUuid) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/apis/${apiUuid}/proxies/${proxyUuid}`;
    await axios.delete(url, { credentials: 'include' })
      .then(() =>
        dispatch({
          type: constants.API_DETAILS_UNDEPLOY_PROXY_SUCCESS,
          payload: {
            proxyUuid,
            errorMessage: '',
          },
        }),
      )
      .catch((error) => {
        const statusCode = get(error, 'response.status');
        if (statusCode === constants.HTTP_STATUS_UNAUTHORIZED) {
          window.location.href =
            `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
        }

        const responseError = get(error, 'response.data');
        const strErrorMessage = (statusCode === constants.HTTP_STATUS_BAD_REQUEST)
          ? processDeploymentErrors(getDeploymentValidationErrors(responseError), '')
          : get(error, 'message');
        dispatch({
          type: constants.API_DETAILS_UNDEPLOY_PROXY_ERROR,
          payload: {
            proxyUuid,
            errorMessage: strErrorMessage,
          },
        });
      },
    );
  };

export const redeployApi = (apiUuid, proxyUuid) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = { status: constants.PENDING_DEPLOYMENT };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/apis/${apiUuid}/proxies/${proxyUuid}`;
    await axios.put(url, data, { credentials: 'include' })
      .then(response =>
        dispatch({
          type: constants.GATEWAY_BUNDLES_REDEPLOY_PROXY_SUCCESS,
          payload: response.data,
        }),
      )
      .catch((error) => {
        const statusCode = get(error, 'response.status');
        if (statusCode === constants.HTTP_STATUS_UNAUTHORIZED) {
          window.location.href =
            `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
        }

        const responseError = get(error, 'response.data');
        const strErrorMessage = (statusCode === constants.HTTP_STATUS_BAD_REQUEST)
          ? processDeploymentErrors(getDeploymentValidationErrors(responseError), '')
          : get(error, 'message');
        dispatch({
          type: constants.GATEWAY_BUNDLES_REDEPLOY_PROXY_ERROR,
          payload: {
            proxyUuid,
            errorMessage: strErrorMessage,
          },
        });
      },
    );
  };

export const fetchDocumentTree = ({ entityUuid, entityType, locale }) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/document-service/1.0/docs/api/${entityUuid}?locale=${locale}`;
    await axios.get(url, { credentials: 'include' })
      .then(response => {
        const itemData = response.data.map(item => ({
          ...item,
          id: buildDocumentId(
            entityType,
            entityUuid,
            item.navtitle,
            locale,
          ),
        }));
        const data = { itemData, total: itemData.length };
        dispatch({
          type: constants.API_DETAILS_GET_DOCUMENT_LIST_SUCCESS,
          payload: data,
        });
      })
      .catch(error =>
        dispatch({
          type: constants.API_DETAILS_GET_DOCUMENT_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const addNewDocument = (document) =>
  async (dispatch) => {
    dispatch({
      type: constants.API_DETAILS_ADD_DOCUMENT,
      payload: document,
    });
  };

  export const removeNewDocument = () =>
  async (dispatch) => {
    dispatch({
      type: constants.API_DETAILS_ADD_DOCUMENT,
      payload: null,
    });
  };

export const fetchDocument = ({ entityUuid, entityType, locale, navtitle }) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/document-service/1.0/docs/api/${entityUuid}/${encodeURIComponent(
      navtitle)}?locale=${locale}`;
    await axios.get(url, { credentials: 'include' })
      .then(response => {
        const data = {
          ...response.data,
          id: buildDocumentId(
            entityType,
            entityUuid,
            response.data.navtitle,
            locale,
          ),
        };
        dispatch({
          type: constants.API_DETAILS_GET_DOCUMENT_SUCCESS,
          payload: data,
        });
      })
      .catch(error =>
        dispatch({
          type: constants.API_DETAILS_GET_DOCUMENT_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const saveDcoument = (doc, uuid) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    dispatch({ type: constants.API_DELETE_RESET });

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/document-service/1.0/docs/api/${uuid}/${encodeURIComponent(
      doc.navtitle)}`;
    await axios.post(url, doc, { credentials: 'include' })
      .then(() => {
          dispatch({
            type: constants.API_DETAILS_DOCUMENT_SAVE_SUCCESS,
            payload: doc,
          });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_DETAILS_DOCUMENT_SAVE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateDcoument = (doc, uuid) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/document-service/1.0/docs/api/${uuid}/${encodeURIComponent(
      doc.navtitle)}`;
    await axios.put(url, doc, { credentials: 'include' })
      .then((response) => {
          dispatch({
            type: constants.API_DETAILS_DOCUMENT_SAVE_SUCCESS,
            payload: response.data,
          });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_DETAILS_DOCUMENT_SAVE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const deleteDocument = (doc, uuid) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const { navtitle, status, locale } = doc;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/document-service/1.0/docs/api/${uuid}/${encodeURIComponent(
      navtitle)}?forceDelete=true&status=${status}&locale=${locale}`;
    await axios.delete(url, { credentials: 'include' })
      .then((response) => {
          dispatch({
            type: constants.API_DETAILS_DOCUMENT_DELETE_SUCCESS,
            payload: response.data,
          });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_DETAILS_DOCUMENT_DELETE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateDocumentTree = (uuid, locale, data) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/document-service/1.0/docs/api/${uuid}?locale=${locale}`;
    await axios.put(url, data, { credentials: 'include' })
      .then((response) => {
          dispatch({
            type: constants.API_DETAILS_DOCUMENT_UPDATE_TREE_SUCCESS,
            payload: response.data,
          });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_DETAILS_DOCUMENT_UPDATE_TREE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const resetDocumentNotification = () => dispatch =>
  dispatch({ type: constants.API_DETAILS_DOCUMENT_RESET_NOTIFICATION });

export const deleteApi = (apiUuid) =>
  async (dispatch, getState) => {
    initApi(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis/${apiUuid}`;

    await axios
      .delete(url, { credentials: "include" })
      .then(() => {
        dispatch({
          type: constants.API_DELETE_SUCCESS,
          payload: {
            apiUuid,
          },
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.API_DELETE_ERROR,
          payload: getValidationErrors(error) || error,
        }),
      );
  };
