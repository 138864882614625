import axios from 'axios';
import { get, map, filter, extend, isEmpty } from 'lodash';
import { stringify } from 'query-string';

import * as constants from '../../constants';
import { getValidationErrors } from '../../utils';
import { getConfig } from '../../reducers/portalConfig';
import { stringifyUrl } from '../../utils/actions';
import { AXIOS_DEFAULT_OPTIONS } from '../';

export const showLoading = ({ isLoading, dispatch }) => {
  dispatch({
    type: constants.API_PROXY_LOADING,
    payload: isLoading,
  });
};

export const reinitializeState = ({ dispatch }) => {
  dispatch({
    type: constants.API_PROXY_RESET,
    payload: null,
  });
};

export const fetchProxy = (proxyUuid, includeMq = true) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    await Promise.all([
      axios.get(
        `${portal.hostname}/api/${portal.tenantPrefix}/deployments/0.1/proxies/${proxyUuid}/details?includeMq=${includeMq}`,
        AXIOS_DEFAULT_OPTIONS,
      ),
      axios.get(
        `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}`,
        AXIOS_DEFAULT_OPTIONS,
      ),
    ])
      .then((response = []) => {
        dispatch({
          type: constants.PROXY_GET_SUCCESS,
          payload: {
            proxyDetails: get(response[0], 'data', {}),
            proxy: get(response[1], 'data', {}),
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.PROXY_GET_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchProxyDetails = (proxyUuid, includeMq=true) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    axios.get(
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/0.1/proxies/${proxyUuid}/details?includeMq=${includeMq}`,
      AXIOS_DEFAULT_OPTIONS,
    )
      .then((response) => {
        dispatch({
          type: constants.PROXY_DETAILS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.PROXY_DETAILS_GET_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchProxyGateway = (proxyUuid) =>
  async (dispatch, getState) => {
    dispatch({
      type: constants.PROXY_GATEWAY_GET_REQUEST,
    });

    const config = getConfig(getState());
    const { portal } = config;
    await Promise.all([
      axios.get(
        `${portal.hostname}/admin/Portal.svc/Gateways('${proxyUuid}')`,
        AXIOS_DEFAULT_OPTIONS,
      ),
      axios.get(
        `${portal.hostname}/admin/Portal.svc/Gateways?inlinecount=allpages&filter=(EnrollmentStatus=ACTIVE or EnrollmentStatus=PENDING)`,
        AXIOS_DEFAULT_OPTIONS,
      ),
    ])
      .then((response = []) => {
        // 0.5 sec delay to display the Loading icon for proxy status clearly
        setTimeout(() =>
          dispatch({
            type: constants.PROXY_GATEWAY_GET_SUCCESS,
            payload: {
              gatewayDetails: get(response[0], 'data.d', {}),
              gateways: get(response[1], 'data.d.results', []),
            },
          }),
          500,
        );
      })
      .catch((error) => {
        dispatch({
          type: constants.PROXY_GATEWAY_GET_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchProxyApis = (proxyUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    dispatch({ type: constants.PROXY_APIS_GET_REQUEST });
    await Promise.all([
      axios.get(
        `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/apis?sort=lastTimeDeployed,desc&size=1&page=0`,
        AXIOS_DEFAULT_OPTIONS,
      ),
      axios.get(
        `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/apis?publishedByPortal=true`,
        AXIOS_DEFAULT_OPTIONS,
      ),
      axios.get(
        `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/apis?publishedByPortal=false`,
        AXIOS_DEFAULT_OPTIONS,
      ),
    ])
    .then((response = []) => {
      dispatch({
        type: constants.PROXY_APIS_GET_SUCCESS,
        payload: {
          apis: response[0].data,
          apisPublishedByPortal: response[1].data,
          apisPublishedByGateway: response[2].data,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.PROXY_APIS_GET_ERROR,
        payload: getValidationErrors(error),
      });
    });
  };

export const fetchApis = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    const urlPrefix = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis`;
    const url = ({ publishedByPortal }) => stringifyUrl({
      url: urlPrefix,
      query: {
        portalStatus: [
          constants.API_STATUS_NEW,
          constants.API_STATUS_ENABLED,
          constants.API_STATUS_DISABLED,
          constants.API_STATUS_DEPRECATED,
        ],
        publishedByPortal,
      },
    });

    await Promise.all([
      axios.get(url({}), AXIOS_DEFAULT_OPTIONS),
      axios.get(url({ publishedByPortal: true }), AXIOS_DEFAULT_OPTIONS),
      axios.get(url({ publishedByPortal: false }), AXIOS_DEFAULT_OPTIONS),
    ])
      .then((response) => {
        dispatch({
          type: constants.APIS_GET_SUCCESS,
          payload: {
            apis: response[0].data,
            apisPublishedByPortal: response[1].data,
            apisPublishedByGateway: response[2].data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.APIS_GET_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchProxyDeploymentStatus = (proxyUuid) =>
  async (dispatch, getState) => {
    showLoading({ dispatch, isLoading: true });
    const config = getConfig(getState());
    const { portal } = config;

    axios.get(
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/status?proxyUuid=${proxyUuid}`,
      AXIOS_DEFAULT_OPTIONS,
    )
    .then((response) => {
      dispatch({
        type: constants.PROXY_DEPLOYMENT_STATUS_GET_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.PROXY_DEPLOYMENT_STATUS_GET_ERROR,
        payload: getValidationErrors(error),
      });
    });
  };

export const fetchProxyApiKeys = (proxyUuid) =>
  async (dispatch, getState) => {
    showLoading({ dispatch, isLoading: true });
    const config = getConfig(getState());
    const { portal } = config;
    axios.get(
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/api-keys?sort=lastTimeDeployed,desc&size=1&page=0`,
      AXIOS_DEFAULT_OPTIONS,
    )
    .then((response) => {
      dispatch({
        type: constants.PROXY_APIS_KEYS_COUNT_GET_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.PROXY_APIS_KEYS_COUNT_GET_ERROR,
        payload: getValidationErrors(error),
      });
    });
  };

export const fetchApiKeysCount = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    dispatch({ type: constants.API_KEYS_COUNT_GET_REQUEST });
    axios.get(
      `${portal.hostname}/admin/api-management/internal/applications/api-keys/count`,
      AXIOS_DEFAULT_OPTIONS,
    )
      .then((response) => {
        dispatch({
          type: constants.API_KEYS_COUNT_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.API_KEYS_COUNT_GET_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchProxyApiPlans = (proxyUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    dispatch({ type: constants.PROXY_API_PLANS_GET_REQUEST });
    axios.get(
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/api-plans`,
      AXIOS_DEFAULT_OPTIONS,
    )
      .then((response = []) => {
        dispatch({
          type: constants.PROXY_API_PLANS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.PROXY_API_PLANS_GET_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const deleteProxy = (proxyUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}`;

    axios.delete(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: constants.PROXY_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.PROXY_DELETE_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchAccountPlans = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    axios.get(
      `${portal.hostname}/admin/Portal.svc/AccountPlans`,
      AXIOS_DEFAULT_OPTIONS,
    )
      .then((response) => {
        dispatch({
          type: constants.ACCOUNT_PLANS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.ACCOUNT_PLANS_GET_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchApiPlans = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    axios.get(
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/api-plans`,
      AXIOS_DEFAULT_OPTIONS,
    )
      .then((response = []) => {
        dispatch({
          type: constants.API_PLANS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.API_PLANS_GET_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchProxyOrganizations = (proxyUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    axios.get(
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/organizations`,
      AXIOS_DEFAULT_OPTIONS,
    )
      .then((response) => {
        const orgIds = get(response, 'data', []);
        if(isEmpty(orgIds)) {
          return Promise.resolve({});
        }
        const query = stringify({
          page: 0,
          size: 100,
          status: 'ENABLED',
          orgId: orgIds.map(({ uuid }) => uuid),
        });
        return axios.get(
          `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/1.0/organizations?${query}`,
          AXIOS_DEFAULT_OPTIONS,
        );
      })
      .then((response) => {
        dispatch({
          type: constants.PROXY_ORGANIZATIONS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.PROXY_ORGANIZATIONS_GET_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const updateNewProxySync = (proxyUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    axios.put(
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/0.1/proxies/${proxyUuid}/config`,
      { useNewAutoSync: true },
    )
      .then(() => {
        dispatch({
          type: constants.PROXY_CONFIG_UPDATE_NEW_SYNC_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.PROXY_CONFIG_UPDATE_NEW_SYNC_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

const fetchApisList = (proxyApis, dispatch, config, size) => {
  const { portal } = config;
  const ids = map(proxyApis.results, 'apiUuid').join(',');
  axios.get(
    `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis?id=${ids}&size=${size}`,
    AXIOS_DEFAULT_OPTIONS,
  )
    .then((response) => {
      const mergeResults = [];
      map(proxyApis.results, (proxyObj) => {
        const apiData = filter(response.data.results, (apiObj) => {
          return proxyObj.apiUuid === apiObj.uuid
        })[0];
        mergeResults.push(extend(proxyObj, apiData))
      });
      dispatch({
        type: constants.PROXY_APIS_LIST_GET_SUCCESS,
        payload: { ...proxyApis, results: mergeResults },
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.PROXY_APIS_LIST_GET_ERROR,
        payload: getValidationErrors(error),
      });
    });
};

export const fetchProxyApisList = ({
  proxyUuid,
  publishSource = constants.ALL,
  status = constants.ALL,
  state = constants.ALL,
  sortBy = 'lastTimeDeployed,DESC',
  page = 0,
  rowsPerPage = 12,
}) =>
  async (dispatch, getState) => {
    showLoading({ dispatch, isLoading: true });
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      publishedByPortal: (publishSource !== constants.ALL) ? publishSource : undefined,
      status: (status !== constants.ALL) ? status : undefined,
      page,
      size: rowsPerPage,
      sort: sortBy,
      portalStatus: (state !== constants.ALL) ? state : undefined,
    }, true);

    await axios.get(
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/apis?${params}`,
      AXIOS_DEFAULT_OPTIONS,
    )
      .then(response => {
        if(response.data.results.length === 0 ) {
          dispatch({
            type: constants.PROXY_APIS_LIST_GET_SUCCESS,
            payload: response.data,
          });
        } else {
          fetchApisList(response.data, dispatch, config, rowsPerPage);
        }
      })
      .catch(error =>
        dispatch({
          type: constants.PROXY_APIS_LIST_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

const fetchKeysList = (keys, dispatch, config, size) => {
  const { portal } = config;
  const ids = map(keys.results, 'apiKey').join(',');
  axios.get(
    `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/api-keys?apiKeys=${ids}&size=${size}`,
    AXIOS_DEFAULT_OPTIONS,
  )
    .then((response) => {
      const mergeResults = [];
      map(keys.results, (proxyObj) => {
        const apiData = filter(response.data.results, (apiObj) => {
          if (proxyObj.apiKey === apiObj.apiKey) {
            apiObj.keyStatus = apiObj.status;
            delete apiObj.status;
          }
          return proxyObj.apiKey === apiObj.apiKey
        })[0];
        mergeResults.push(extend(proxyObj, apiData))
      });
      dispatch({
        type: constants.PROXY_APIS_KEYS_LIST_GET_SUCCESS,
        payload: { ...keys, results: mergeResults },
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.PROXY_APIS_KEYS_LIST_GET_ERROR,
        payload: getValidationErrors(error),
      });
    });
};

export const fetchProxyApiKeysList = ({
  proxyUuid,
  keyName,
  keyStatus = constants.ALL,
  apiKey,
  status = constants.ALL,
  page = 0,
  rowsPerPage = 12,
  sortBy = 'lastTimeDeployed,DESC',
}) =>
  async (dispatch, getState) => {
    showLoading({ dispatch, isLoading: true });
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      apiKeyName: keyName ? keyName: undefined,
      apiKey: apiKey ? apiKey: undefined,
      apiKeyStatus: (keyStatus !== constants.ALL) ? keyStatus : undefined,
      status: (status !== constants.ALL) ? status : undefined,
      page,
      size: rowsPerPage,
      sort: sortBy,
    }, true);

    await axios.get(
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${proxyUuid}/api-keys?${params}`,
      AXIOS_DEFAULT_OPTIONS,
    )
      .then(response => {
        if(response.data.results.length === 0 ) {
          dispatch({
            type: constants.PROXY_APIS_KEYS_LIST_GET_SUCCESS,
            payload: response.data,
          });
        } else {
          fetchKeysList(response.data, dispatch, config, rowsPerPage);
        }
      })
      .catch(error =>
        dispatch({
          type: constants.PROXY_APIS_KEYS_LIST_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchRateLimitQuota = (uuid) =>
  async (dispatch, getState) => {
    showLoading({ dispatch, isLoading: true });
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies/${uuid}/org-rate-quotas`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: constants.PROXY_RATE_LIMIT_QUOTA_GET_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: constants.PROXY_RATE_LIMIT_QUOTA_GET_SUCCESS,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchProxyCertificatesList = ({
  proxyUuid,
  name,
  subjectDn,
  page = 0,
  rowsPerPage = 12,
  sortBy,
}) =>
  async (dispatch, getState) => {
    showLoading({ dispatch, isLoading: true });
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      name: name ? name : undefined,
      subjectDn:  subjectDn ? subjectDn : undefined,
      page,
      size: rowsPerPage,
      sort: sortBy,
    }, true);

    axios.get(
      `${portal.hostname}/api/${portal.tenantPrefix}/gateway-management/0.1/${proxyUuid}/trusted-certs?${params}`,
      AXIOS_DEFAULT_OPTIONS,
    )
    .then((response) => {
      dispatch({
        type: constants.PROXY_CERTIFICATES_LIST_GET_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.PROXY_CERTIFICATES_LIST_GET_ERROR,
        payload: getValidationErrors(error),
      });
    });
  };

export const fetchEntitiesSummary = (proxyUuid) =>
  async (dispatch, getState) => {
    showLoading({ dispatch, isLoading: true });
    const config = getConfig(getState());
    const { portal } = config;

    axios.get(
      `${portal.hostname}/api/${portal.tenantPrefix}/gateway-management/0.1/${proxyUuid}/summary`,
      AXIOS_DEFAULT_OPTIONS,
    )
    .then((response) => {
      dispatch({
        type: constants.PROXY_ENTITIES_SUMMARY_GET_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.PROXY_ENTITIES_SUMMARY_GET_ERROR,
        payload: getValidationErrors(error),
      });
    });
  };
