import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import theme from './index.theme';

function Settings({
 classes = {},
 settingItems = [],
}) {
  const [anchorEl = false, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.HeaderSettingsButton}>
      <IconButton onClick={handleClick}>
        <SettingsIcon fontSize="small" />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {!isEmpty(settingItems) &&
        <Paper>
          <div className={classes.Menu}>
            <div className={classes.HeaderSettingsMenuWrapper}>
              <div className={clsx(classes.SubHeader, classes.SubHeaderUppercase)}>
                Administration
              </div>
              {settingItems.map(settingItem => (
                <a
                  className={classes.HeaderSettingsMenuItem}
                  href={`//${window.location.hostname}${settingItem.link.href}`}
                  key={settingItem.id}
                >
                  <span>{settingItem.link.title}</span>
                </a>
              ))}
            </div>
          </div>
        </Paper>
        }
      </Popover>
    </div>
  );
}

Settings.propTypes = {
  classes: PropTypes.shape({}),
  settingItems: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withStyles(theme)(Settings);
