import React, { useState } from 'react';
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Button,
  Drawer,
 } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import { upperCase, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styles from './index.theme';
import { hasOrgBoundRole } from '../../../utils';

export default function HamburgerMenu(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const {
    navItems,
    serviceItems,
    userAccessibleOrgs,
    userActiveOrgUuid,
    userDetails,
    updateUserContexts,
  } = props;
  const hostname = `https://${window.location.hostname}`;
  const profileLink = `${hostname}/admin/my-profile`;

  const getImageSource = (serviceItem) =>
    hostname + serviceItem.link.imagePath.replace('.png', '.svg');

  const toggleDrawer = (flag) => {
    setIsOpen(flag);
  };

  return (
    <div>
      <button className={classes.HeaderMenuButton} onClick={() => toggleDrawer(true)}>
        <div className={classes.HeaderMenuButtonIcon}>
          <span className={classes.HeaderMenuButtonIconMiddleBar} />
        </div>
      </button>
      <Drawer open={isOpen} onClose={() => toggleDrawer(false)}>
        <div
          className={classes.list}
          role="presentation"
          onClick={() => toggleDrawer(false)}
          onKeyDown={() => toggleDrawer(false)}
        >
          <List>
            {navItems.map((item) => (
              <ListItem button key={item.id} className={classes.SidemenuNavLink}>
                <a href={item.link.href}>
                  <ListItemText primary={item.link.title} />
                </a>
              </ListItem>
            ))}
            {!isEmpty(serviceItems) &&
              <div>
                <div className={classes.subHeader}>
                  <span>Services</span>
                </div>
                {serviceItems.map(serviceItem => (
                  <ListItem button key={serviceItem.id}>
                    <a
                      key={serviceItem.link.title}
                      className={clsx(classes.ServiceLink, classes.HeaderServiceLink)}
                      href={serviceItem.link.href}
                      target={serviceItem.link.externalLink ? '_blank' : '_self'}
                    >
                      <div className={classes.ServiceLinkImageContainer}>
                        <img
                          alt={`${serviceItem.link.title} Icon`}
                          className={classes.ServiceLinkImage}
                          src={getImageSource(serviceItem)}
                        />
                      </div>
                      <div className={classes.serviceText}>
                        {serviceItem.link.title}
                      </div>
                    </a>
                  </ListItem>
                ))}
              </div>
            }
            <div className={classes.userMenuContent}>
              <ListItem button key="myProfile" className={clsx(classes.SidemenuNavLink, classes.sideMenuPadding)}>
                <Button
                  color="secondary"
                  className={classes.button}
                  href={profileLink}
                >
                  My Profile
                    {hasOrgBoundRole(userDetails) &&
                    <div className={clsx(classes.SubHeader, classes.SubHeaderUppercase)}>
                      {upperCase('organizations')}
                    </div>
                  }
                </Button>
              </ListItem>
              {hasOrgBoundRole(userDetails) &&
                <div className={classes.UserMenuContent_Padded}>
                  <ListItem className={clsx(classes.SidemenuNavLink, classes.sideMenuPadding)}>
                    <div>
                      {userAccessibleOrgs.map(accessibleOrg => (
                        <div
                          key={accessibleOrg.uuid}
                          className={classes.UserMenuContent__AccessibleOrgWrapper}
                        >
                          <Button
                            color="secondary"
                            className={classes.UserMenuContent__AccessibleOrgs}
                            onClick={() => updateUserContexts({ orgUuid: accessibleOrg.uuid })}
                          >
                            {accessibleOrg.name}
                          </Button>
                          {userActiveOrgUuid === accessibleOrg.uuid && (
                            <Check className={classes.UserMenuContent__Check} />
                          )}
                        </div>
                      ))}
                    </div>
                  </ListItem>
                </div>
              }
              <div className={classes.UserMenuContent_Padded}>
                <ListItem button key="logout" className={classes.SidemenuNavLink}>
                  <Button
                    color="secondary"
                    className={classes.logOutButton}
                    onClick={() => {
                      localStorage.clear();
                      window.location.href = `//${window.location.hostname}/admin/logout`;
                    }}
                  >
                    Logout
                      </Button>
                </ListItem>
              </div>
            </div>
          </List>
        </div>
      </Drawer>
    </div>
  );
}


HamburgerMenu.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({})),
  serviceItems: PropTypes.arrayOf(PropTypes.shape({})),
  userAccessibleOrgs: PropTypes.arrayOf(PropTypes.shape({})),
  userActiveOrgUuid: PropTypes.string,
  userDetails: PropTypes.shape({}),
  updateUserContexts: PropTypes.func,
};
