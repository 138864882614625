const styles = ({ color, fontSize, misc, typography }) => ({
  dashboard: {
    background: `${color.uiBackground} 0% 0% no-repeat padding-box`,
    margin: `-${misc.headerHeight}px 38px 0 38px`,
  },
  title: {
    color: color.pageTitle,
    fontFamily: typography.pageTitle,
    fontSize: fontSize.pageTitle,
    lineHeight: '48px',
    marginTop: 102,
    paddingLeft: 36,
  },
  subTitle: {
    color: color.pageTitle,
    fontFamily: typography.pageTitle,
    fontSize: fontSize.smallTitle,
    lineHeight: '28px',
    marginTop: 36,
    paddingLeft: 36,
  },
});

export default styles;
