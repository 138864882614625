import { get } from 'lodash';
import * as constants from '../../constants';

const initialState = {
  isLoading: false,
  isError: false,
  errors: [],
  customPages: [],
  bundles: [],
  deleteBundleStatus: null,
  uploadBundleStatus: null,
  saveCustomPagesStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.APP_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        isError: false,
        uploadBundleStatus: null,
        deleteBundleStatus: null,
      };
    case constants.FETCH_CUSTOM_PAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customPages: action.payload,
        errors: [],
        isError: false,
      };
    case constants.FETCH_CUSTOM_PAGES_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
      };
    case constants.FETCH_CUSTOM_PAGES_BUNDLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bundles: action.payload,
        errors: [],
        isError: false,
      };
    case constants.FETCH_CUSTOM_PAGES_BUNDLES_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
      };
    case constants.FETCH_CUSTOM_PAGES_LIST_IN_BUNDLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bundlePagesList: action.payload,
        errors: [],
        isError: false,
      };
    case constants.FETCH_CUSTOM_PAGES_LIST_IN_BUNDLE_ERROR:
      return {
        ...state,
        isLoading: false,
        bundlePagesList: [],
        isError: true,
        errors: action.payload,
      };
    case constants.UPLOAD_CUSTOM_PAGES_BUNDLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        uploadBundleStatus: constants.SUCCESS,
      };
    case constants.UPLOAD_CUSTOM_PAGES_BUNDLE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
        uploadBundleStatus: constants.FAIL,
      };
    case constants.DELETE_CUSTOM_PAGES_BUNDLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        deleteBundleStatus: constants.SUCCESS,
      };
    case constants.DELETE_CUSTOM_PAGES_BUNDLE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
        deleteBundleStatus: constants.FAIL,
      };
    case constants.SAVE_CUSTOM_PAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        saveCustomPagesStatus: action.payload // isReset
          ? constants.SAVE_CUSTOM_PAGES_RESET_SUCCESS
          : constants.SUCCESS,
      };
    case constants.SAVE_CUSTOM_PAGES_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
        saveCustomPagesStatus: constants.FAIL,
      };
    case constants.RESET_CUSTOM_PAGES_SUCCESS_MESSAGE:
      return {
        ...state,
        isLoading: false,
        saveCustomPagesStatus: '',
      };
    // NOTE: Keeping old code below for errorCodes
    // case constants.UPLOAD_CUSTOM_UI_ASSETS_BUNDLE_ERROR:
    //   let errorCode =
    //     action.payload.response &&
    //     action.payload.response.validationErrors &&
    //     action.payload.response.validationErrors['ui-assets_bundle'] &&
    //     action.payload.response.validationErrors['ui-assets_bundle'].code
    //       ? action.payload.response.validationErrors['ui-assets_bundle'].code
    //       : '';
    //   if (action.status === 413) {
    //     errorCode = 4016;
    //   }
    //   switch (errorCode) {
    //     case 4016:
    //       return constants.UPLOAD_CUSTOM_UI_ASSETS_BUNDLE_ERROR_SIZE;
    //     case 571:
    //       return constants.UPLOAD_CUSTOM_UI_ASSETS_BUNDLE_ERROR_INVALID;
    //     case 568:
    //       return constants.UPLOAD_CUSTOM_UI_ASSETS_BUNDLE_ERROR_UNSUPPORTED_CONTENT;
    //     case 590:
    //       return constants.UPLOAD_CUSTOM_UI_ASSETS_BUNDLE_ERROR_INVALID_FILE_NAME;
    //     case 4010:
    //       return constants.UPLOAD_CUSTOM_UI_ASSETS_BUNDLE_ERROR_LIMIT_REACHED;
    //     default:
    //       return constants.UPLOAD_CUSTOM_UI_ASSETS_BUNDLE_ERROR_UNKNOWN;
    //   }
    default:
      return state;
  }
};

export const getIsLoading = (state) => get(state, 'customPages.isLoading');

export const getCustomPages = (state) => get(state, 'customPages.customPages');

export const getErrors = (state) => get(state, 'customPages.errors');

export const getBundles = (state) => get(state, 'customPages.bundles');

export const getDeleteBundleStatus = (state) =>
  get(state, 'customPages.deleteBundleStatus');

export const getUploadBundleStatus = (state) =>
  get(state, 'customPages.uploadBundleStatus');

export const getSaveCustomPagesStatus = (state) =>
  get(state, 'customPages.saveCustomPagesStatus');

export const getBundlePagesList = (state) =>
  get(state, 'customPages.bundlePagesList');

// export const getUploadingErrorCode = (state) =>
//   get(state, 'customPages.customPagesUploadingErrorInfo');
