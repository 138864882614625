import { get } from 'lodash';

const styles = theme => ({
  container: {
    border: `1px solid ${get(theme, 'color.borderColor')}`,
    height: 200,
    display: 'flex',
    margin: 10,
    marginLeft: 0,
    width: '98%',
  },
  fileLabel: {
    color: get(theme, 'color.primaryButtonBackground'),
    margin: 'auto',
    textAlign: 'center',
    fontFamily: get(theme, 'typography.bodyText'),
    fontWeight: 700,
    '& > div': {
      color: get(theme, 'color.bodyText'),
      fontFamily: get(theme, 'typography.bodyText'),
      fontSize: get(theme, 'fontSize.title'),
    },
  },
  root: {
    '&.Mui-error': {
      color: get(theme, 'color.error'),
      fontSize: 14,
    },
  },
  selectedFileContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formLabel: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.smallTitle'),
    fontWeight: 700,
    marginBottom: 10,
    paddingLeft: 0,
  },
  uploadFileSection: {
    backgroundColor: get(theme, 'color.disabledBackground'),
    color: get(theme, 'color.disabledText'),
    marginBottom: get(theme, 'margin.4px'),
    '& p': {
      display: 'inline-block',
    },
    '& svg': {
      float: 'right',
    },
  },
  error: {
    color: get(theme, 'color.error'),
    paddingTop: 10,
  },
  fileSizeText: {
    paddingLeft: 10,
    fontStyle: 'italic',
  },
  helperText: {
    color: get(theme, 'color.smallText'),
    fontFamily: get(theme, 'typography.smallText'),
    fontSize: get(theme, 'fontSize.smallText'),
  },
});

export default styles;
