import { get } from 'lodash';
import { convertHexToRGB } from '../../utils';

const styles = (theme) => ({
  fieldContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formLabel: {
    float: 'unset',
  },
  defaultTextField: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.bodyText'),
    maxWidth: '20%',
    width: '20%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 10,
    paddingBottom: 6,
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 7,
    float: 'left',
    marginRight: 2,
    border: `1px solid ${get(theme, 'color.borderColor')}`,
    borderRadius: 4,
    background: 'rgba(220, 220, 220, 0.80)',
  },
  error: {
    borderColor: get(theme, 'color.error'),
  },
  textField: {
    borderRadius: get(theme, 'borderRadius.br3'),
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.bodyText'),
    marginTop: 10,
    paddingTop: 10,
    paddingBottom: 10,
    width: '73.2%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& input': {
      padding: '0 10px',
    },
    '& .MuiInputBase-root': {
      backgroundColor: get(theme, 'color.uiBackground'),
      position: 'unset',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: convertHexToRGB(get(theme, 'color.primaryButtonBackground'), 0.4),
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.primaryButtonHover'),
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.primaryButtonBackground'),
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.error'),
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      backgroundColor: convertHexToRGB(get(theme, 'color.bodyText'), 0.1),
      borderColor: convertHexToRGB(get(theme, 'color.bodyText'), 0.5),
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 1,
    },
  },
  /* Todo - Material ui override style
  textField: {
    color: '#000000',
    fontFamily: theme.typography.bodyText,
    fontSize: theme.fontSize.bodyText,
    width: '99%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&$outlined:hover $outlined$notchedOutline': {
      borderColor: '#cccccc',
    },
    '&$notchedOutline$focused $notchedOutline': {
      borderColor: '#cccccc',
    },
    '&$error$notchedOutline': {
      borderColor: '##f44336',
    },
    '&$contained': {
      marginLeft: 1,
    },
    paddingTop: 10,
    paddingBottom: 10,
  },
  notchedOutline: {},
  outlined: {},
  focused: {},
  error: {},
  contained: {},
  */
  helperText: {
    color: `${get(theme, 'color.smallText')} !important`,
    fontFamily: `${get(theme, 'typography.smallText')} !important`,
    fontSize: `${get(theme, 'fontSize.smallText')} !important`,
  },

});

export default styles;
