import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import HorizontalScrollBar from '../../../components/HorizontalScrollBar';
import EntityCard from '../EntityCard';

const entityOrder = [
  'APIs',
  'APIPlans',
  'APIGroups',
  'Proxies',
  'Applications',
  'Organizations',
  'Users',
  'RateAndQuotaPlans',
];
export default function EntityScrollBar(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const getEntityObj = entityName =>
    props.entities.find(entityObj => entityObj.entity === entityName);
  return (
    <React.Fragment>
      <HorizontalScrollBar scrollEntity="referenceCard">
        {props.entities && props.entities.length > 0 ? (
          <div className={classes.leftBorder} />
        ) : null}
        {entityOrder.map(
          (entityName, id) =>
            getEntityObj(entityName) && (
              <EntityCard
                isPortalAdmin={props.isPortalAdmin}
                entity={getEntityObj(entityName)}
                id={id}
                key={entityName}
              />
            ),
        )}
        {props.entities && props.entities.length > 0 ? (
          <div className={classes.rightBorder} />
        ) : null}
      </HorizontalScrollBar>
    </React.Fragment>
  );
}
EntityScrollBar.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
  isPortalAdmin: PropTypes.bool,
};
