import isEmpty from 'lodash/isEmpty';
import split from 'lodash/split';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import set from 'lodash/set';
import isUndefined from 'lodash/isUndefined';
import { SwaggerUIBundle, SwaggerUIStandalonePreset } from 'swagger-ui-dist';

export const SWAGGER_UI_EL = 'apim-swagger-ui';
const PORTAL_AUTH_API_KEY = 'portal_api_key';
const AUTH_TYPE_API_KEY = 'APIKEY';
const AUTH_TYPE_OAUTH2 = 'OAUTH2';
const DUMMY_KEYSECRET = '********';

const initializeSwaggerUIAuth = ({ api, apiKey, swaggerUIBundle }) => {
  const { authenticationType } = api;
  const { apiKey: apiKeyValue, keySecret } = apiKey;

  switch (authenticationType) {
    case AUTH_TYPE_OAUTH2:
      if (keySecret !== DUMMY_KEYSECRET) {
        swaggerUIBundle.initOAuth({
          clientId: apiKeyValue,
          clientSecret: keySecret,
        });
      } else {
        swaggerUIBundle.initOAuth({
          clientId: apiKeyValue,
          clientSecret: '',
        });
      }
      break;
    case AUTH_TYPE_API_KEY:
      swaggerUIBundle.preauthorizeApiKey(PORTAL_AUTH_API_KEY, apiKeyValue);
      break;
    default:
      break;
  }
};

const injectOauthScopes = ({ oauthScope, apiSwagger }) => {
  const oauthScopesToInject = {};

  try {
    if (!isUndefined(oauthScope)) {
      const definedScopes = split(oauthScope, /\s/);
      forEach(definedScopes, (scope) => {
        oauthScopesToInject[scope] = 'scope defined in the application';
      });

      if (get(apiSwagger, 'swagger')) {
        // inject scopes into our portal_oauth2 config for swagger spec
        if (!isUndefined(get(apiSwagger, 'securityDefinitions.portal_oauth2'))) {
          set(apiSwagger, 'securityDefinitions.portal_oauth2.scopes', oauthScopesToInject);
        }
      } else if (get(apiSwagger, 'openapi')) {
        // inject scopes into our portal_oauth2 config for openapi spec
        if (!isUndefined(get(apiSwagger, 'components.securitySchemes.portal_oauth2.flows'))) {
          const { flows } = apiSwagger.components.securitySchemes.portal_oauth2;

          /*
          iterate over flows which may be authorizationCode,
          implicit, password, or clientCredentials
           */
          forEach(flows, (oauthFlow) => {
            set(oauthFlow, 'scopes', oauthScopesToInject);
          });
        }
      }
    }
  } catch (e) {
    // continue if scope injection failed
  }
};

export default ({ api, apiKey, apiSwagger }) => {
  if (isEmpty(apiSwagger)) { return; }
  const { oauthCallbackUrl, oauthScope } = apiKey;

  if (!isEmpty(apiKey)) {
    injectOauthScopes({ oauthScope, apiSwagger });
  }

  const swaggerUIBundle = SwaggerUIBundle({
    spec: apiSwagger,
    dom_id: `#${SWAGGER_UI_EL}`,
    oauth2RedirectUrl: oauthCallbackUrl,
    validatorUrl: null,
    deepLinking: true,
    presets: [
      SwaggerUIBundle.presets.apis,
      SwaggerUIStandalonePreset,
    ],
  });

  initializeSwaggerUIAuth({ api, apiKey, swaggerUIBundle });
};