import { get } from 'lodash';
import * as Constants from '../../constants';

const resetState = {
  isError: false,
  errors: [],
};
const initialState = {
  ...resetState,
  isLoading: false,
  eulaNameUnique: { d: { result: true } },
  isEulaSaveSuccess: false,
  isEulaDeleteSuccess: false,
  eulaApisList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  eulasList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  eulaDetails: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.APP_RESET:
      return {
        ...state,
        ...resetState,
        eulaDetails: {},
        isEulaSaveSuccess: false,
        isEulaDeleteSuccess: false,
      };
    case Constants.EULA_DELETE_RESET:
      return {
        ...state,
        ...resetState,
        isEulaSaveSuccess: false,
        isEulaDeleteSuccess: false,
      };
    case Constants.APP_LOADING:
      return {
        ...state,
        ...resetState,
        isEulaSaveSuccess: false,
        isEulaDeleteSuccess: false,
        isLoading: action.isLoading,
      };
    case Constants.EULAS_GET_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        eulasList: action.payload,
      };
    case Constants.EULAS_GET_LIST_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
      };
    case Constants.EULAS_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        eulaDetails: action.payload,
      };
    case Constants.EULAS_DETAILS_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
      };
    case Constants.EULAS_APIS_LIST_SUCCESS:
      return {
        ...state,
        eulaApisList: action.payload,
      };
    case Constants.EULAS_APIS_LIST_ERROR:
      return {
        ...state,
        eulaApisList:{
          totalPages: 1,
          totalElements: 0,
          results: [],
        },
      };
    case Constants.EULA_ADD_REQUEST:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isEulaSaveSuccess: false,
      };
    case Constants.EULA_NAME_UNIQUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        eulaNameUnique: action.payload,
        isEulaSaveSuccess: false,
        isError: false,
        errors: [],
      };
    case Constants.EULA_NAME_UNIQUE_ERROR:
      return {
        ...state,
        isLoading: false,
        isEulaSaveSuccess: false,
        isError: true,
        errors: action.payload,
      };
    case Constants.EULA_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        eulaDetails: action.payload,
        isEulaSaveSuccess: true,
      };
    case Constants.EULA_SAVE_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
        isEulaSaveSuccess: false,
      };
    case Constants.EULA_DELETE_SUCCESS:
      return {
        ...state,
        ...resetState,
        deleteEulaErrors: [],
        isEulaDeleteSuccess: true,
      };
    case Constants.EULA_DELETE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        deleteEulaErrors: action.payload,
        isEulaDeleteSuccess: false,
      };
    default:
      return state;
  }
}

export const getIsLoading = (state) => get(state, 'eula.isLoading');

export const getIsError = (state) => get(state, 'eula.isError');

export const getErrors = state => get(state, 'eula.errors');

export const getIsEulaSaveSuccess = state => get(state, 'eula.isEulaSaveSuccess');

export const getIsEulaDeleteSuccess = state => get(state, 'eula.isEulaDeleteSuccess');
export const getDeleteEulaErrors = state => get(state, 'eula.deleteEulaErrors');

export const getEulaDetails = state => get(state, 'eula.eulaDetails');

export const getIsEulaNameUnique = (state) => get(state, 'eula.eulaNameUnique.d.result');

export const getEulasListTotalPages = (state) => get(state, 'eula.eulasList.totalPages');
export const getEulasListTotalElements = (state) => get(state, 'eula.eulasList.totalElements');
export const getEulasListResults = (state) => get(state, 'eula.eulasList.results');

export const getEulaApisListResults = (state) => get(state, 'eula.eulaApisList.results');
