import React from 'react';
import { makeStyles } from '@material-ui/core';
import { shape, string } from 'prop-types';

import ListHelpRaw from './ListHelp';
import styles from './styles';

export default function HelpContainer(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { id, helpItems } = props;

  return (
    <div
      className={classes.helpContainer}
      id={id} data-layer7-test={id}
    >
      <h2 className={classes.helpTitle}>{helpItems.title}</h2>
      <div className={classes.helpDescription}>{helpItems.descriptions}</div>
      {helpItems.subItems.map(
        item =>
          <div key={item.id} className={classes.helpSubContainer}>
            <div className={classes.helpTextHead}>{item.title}</div>
            <div className={classes.helpTextBody}>
              {item.descriptions}
              {item.list && item.list.items.length > 0 &&
                <ListHelpRaw lists={item.list} />
              }
            </div>
          </div>,
      )}
    </div>
  );
}

HelpContainer.propTypes = {
  id: string,
  helpItems: shape({}),
};
