import { isString } from 'lodash';

export const getParsedApplicationDetails = (payload) => {
  if (!payload) { return null; }
  const {
    Uuid: uuid,
    OrganizationName: organizationName,
    Description: description,
    ApiKey: apiKey,
    MagMasterKey: magMasterKey,
    Reason: reason,
    Name: name,
    OauthScope: oauthScope,
    KeySecret: keySecret,
    MagScope: magScope,
    OauthCallbackUrl: oauthCallbackUrl,
    Status: status,
    DisabledByType: disabledByType,
    OauthType: oauthType,
    OrganizationUuid: organizationUuid,
    CustomFieldValues: { results: customFieldValues },
    ApiIds: { results: apiIds },
    ApiApiPlanIds: { results: apiApiPlanIds },
    ApiGroupIds: { results: apiGroupIds },
  } = payload;

  return {
    uuid,
    organizationName,
    description,
    apiKey,
    magMasterKey,
    reason,
    name,
    oauthScope,
    keySecret,
    magScope,
    oauthCallbackUrl,
    status,
    disabledByType,
    oauthType,
    organizationUuid,
    customFieldValues: customFieldValues.reduce((acc, { CustomFieldUuid, Value }) => {
      acc[CustomFieldUuid] = Value;
      return acc;
    }, {}),
    apiIds,
    apiApiPlanIds: apiApiPlanIds.reduce((acc, { ApiUuid, ApiPlanUuid }) => {
      acc[ApiUuid] = ApiPlanUuid;
      return acc;
    }, {}),
    apiGroupIds,
  };
};

export const getParsedApiEula = (payload) => {
  const {
    ApiUsage: apiUsage,
    ApplicationUsage: applicationUsage,
    Uuid: uuid,
    Content: content,
    Name: name,
    PossibleActions: possibleActions,
  } = payload;

  return { apiUsage, applicationUsage, uuid, content, name, possibleActions };
};

export const getParsedCustomFields = (customFields) =>
  customFields.map(customField => {
    const {
      Status: status,
      Description: description,
      Name: name,
      EntityType: entityType,
      Type: type,
      Uuid: uuid,
      Required: required,
      OptionsList: { results: optionsList },
    } = customField;

    return {
      status,
      description,
      name,
      entityType,
      type,
      uuid,
      required,
      optionsList: optionsList.map(({ Ordinal: ordinal, Value: value }) => ({ ordinal, value })),
    };
  });

export const getHashingMetadata = (payload) => {
  const {
    AppSecretHashingMetadata: appSecretHashingMetadata,
  } = payload;

  return {
    appSecretHashingMetadata:
      isString(appSecretHashingMetadata) ?
        JSON.parse(appSecretHashingMetadata) : appSecretHashingMetadata,
  };
};
