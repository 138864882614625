import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LensIcon from '@material-ui/icons/Lens';

import { getApiStatusLabel, getApiStatusColor } from '../../../../../utils/portalStatusMapper';
import {
  VERSION_TEXT,
  CHANGE_ACTION_TEXT,
  ADD_APIPLAN_ACTION_TEXT,
} from '../../labels';

import styles from '../styles';

export const renderTitle = (name = '', uuid, classes) => (
  <span className={classes.linkWrapper}>
    <Link href={`/publish/apis/details/${uuid}`}>
      <h4 className={classes.cardTitle}>{name}</h4>
    </Link>
  </span>
);

const redirectToEditApplication = (appUuid, history) => {
  history.push(`/publish/applications/edit/${appUuid}/api-management`);
};

const ApiCard = ({
  appUuid,
  details: {
    uuid,
    name,
    status,
    version,
    description,
  },
  isEditAppDisabled,
  featureFlagApiPlan,
  apiPlan,
  history,
  classes,
}) => {
  const statusLabel = getApiStatusLabel(status);
  const statusColor = getApiStatusColor(status);
  const statusStyle = {
    color: statusColor,
  };

  return (
    <Fragment>
      <Card className={classes.card} id={uuid}>
        <CardContent>
          <Typography>
            {renderTitle(name, uuid, classes)}
          </Typography>
          <Typography style={statusStyle} className={cx(classes.cardStatus)}>
            <LensIcon className={classes.cardStatusIcon} /> {statusLabel}
            &nbsp; &nbsp;
            <span className={classes.versionText}>
              {VERSION_TEXT}: {version}
            </span>
          </Typography>
          <Divider />
          {featureFlagApiPlan &&
            <Typography className={classes.cardDescription}>
              {apiPlan ?
                <div>
                  <span>{get(apiPlan, 'name')}</span>
                  <span
                    className={cx(
                      classes.linkWrapper,
                      classes.leftMargin,
                      isEditAppDisabled ? classes.disabledClass : '',
                    )}
                  >
                    <Link onClick={() => redirectToEditApplication(appUuid, history)}>
                      {CHANGE_ACTION_TEXT}
                    </Link>
                  </span>
                </div>
                :
                <span
                  className={cx(
                    classes.linkWrapper,
                    isEditAppDisabled ? classes.disabledClass : '',
                  )}
                >
                  <Link onClick={() => redirectToEditApplication(appUuid, history)}>
                    {ADD_APIPLAN_ACTION_TEXT}
                  </Link>
                </span>
              }
            </Typography>
          }
          <Typography className={classes.cardDescription}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Fragment>
  );
};

ApiCard.propTypes = {
  appUuid: PropTypes.string,
  details: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    version: PropTypes.string,
    description: PropTypes.string,
  }),
  isEditAppDisabled: PropTypes.bool,
  featureFlagApiPlan: PropTypes.string,
  apiPlan: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(ApiCard);
