import perdido from 'perdido';
import get from 'lodash/get';

const styles = ({
  color, fontSize, typography, misc,
} = {}) => ({
  cardsContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  verticalDivider: {
    borderLeft: '1px solid #c8d1e0',
    height: 10,
    marginTop: 2,
  },
  iconText: {
    marginLeft: 5,
    display: 'inline-block',
  },
  metricsList: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    margin: '0px',
    fontWeight: '300',
    color: color.bodyText,
    fontFamily: typography.bodyText,
    fontSize: fontSize.smallText,
    '& li': {
      marginRight: '12px',
    },
  },
  customCard: {
    transition: 'opacity 250ms ease-in-out',
    borderBottom: 'none',
    border: '1px solid #ebeff5',
    boxShadow: 'rgb(235 235 235) 0px 2px 8px 0px',
    boxSizing: 'border-box',
    marginBottom: '20px',
    '&:hover': {
      boxShadow: '0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)',
      transition: 'all 0.3s cubic-bezier(.25, .8, .25, 1)',
    },
    '&:focus': {
      boxShadow: `${color.primaryButtonBackground} 0px 0px 0px 2px, rgba(0, 0, 0, 0.2) 0px 1px 2px 0px, rgba(0, 0, 0, 0.2) 0px 2px 4px 0px !important`,
    },
  },
  [`@media (min-width: ${misc.breakpointSmall}px)`]: {
    customCard: {
      ...perdido.column('1/2', { gutter: '20px' }),
    },
  },

  [`@media (min-width: ${misc.breakpointMedium}px)`]: {
    customCard: {
      ...perdido.column('1/3', { gutter: '20px' }),
    },
  },
  '@media (min-width: 1280px)': {
    customCard: {
      ...perdido.column('1/4', { gutter: '20px' }),
    },
  },
  tagsGroup: {
    height: 28,
    paddingLeft: 16,
    paddingRight: 16,
  },
  APIStatusBar: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    paddingBottom: 4,
    paddingTop: 4,
    marginBottom: 10,
    marginTop: 10,
    fontWeight: '300',
    fontSize: '10px',
    '& li': {
      marginRight: '6px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  APIInfoText: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'smallText'),
  },
  statusIcon: {
    height: 15,
    marginRight: 3,
    verticalAlign: 'top',
    width: 15,
  },
  customCardContent : {
    padding: 0,
  },
  cardHeader: {
    padding: 16,
  },
  cardTitle: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontWeight: 'bold',
    fontSize: get(fontSize, 'bodyText'),
    lineHeight: get(fontSize, 'bodyText', 14),
    margin: '5px 0',
    minHeight: get(fontSize, 'bodyText', 14),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-font-smoothing': 'antialiased',
    whiteSpace: 'nowrap',
  },
  cardDescription: {
    color: get(color, 'bodyText'),
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    fontSize: 12,
    fontFamily: get(typography, 'bodyText'),
    height: 70,
    overflow: 'hidden',
    paddingLeft: 16,
    paddingRight: 16,
  },
  cardFooter: {
    padding: '16px !important',
  },
  Icon: {
    display: 'inline block',
    verticalAlign: 'middle',
  },
  Tooltip__wrapper: {
  '& .rc-tooltip-inner': {
      borderRadius: '3px',
      boxShadow: '0 1px 2px 0 rgba(0,0,0,0.4)',
      color: get(color, 'primaryButtonText'),
      fontFamily: get(typography, 'pageTitle'),
      fontSize: '14px',
      lineHeight: '1.28em',
      minHeight: 'auto',
    },
  },
  TooltipEnter: {
    opacity: 0,
  },
  TooltipEnterActive: {
    opacity: 1,
    transition: 'opacity 100ms',
    transitionTimingFunction: 'ease-in',
  },
  TooltipLeave: {
    opacity: 1,
  },
  TooltipLeaveActive: {
    opacity: 0,
    transition: 'opacity 100ms',
    transitionTimingFunction: 'ease-out',
  },
});

export default styles;
