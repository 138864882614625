export const readCookie = (name) => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(cookieName) === 0) return cookie.substring(cookieName.length, cookie.length);
  }
  return null;
};

export const createCookie = (name, value, days, path) => {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=  ${date.toGMTString()}`;
  } else expires = '';
  document.cookie = `${name}=${value}${expires}; path=${path}`;
};

