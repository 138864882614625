import { get } from 'lodash';
import * as Constants from '../../constants';

const resetState = {
  isError: false,
  errors: [],
  listProxyErrors: [],
  listApiErrors: [],
  deleteBundleErrors: [],
};
const initialState = {
  ...resetState,
  gatewayBundleDetails: {},
  gatewayBundleUsage: 0,
  isLoading: false,
  proxyErrors: [],
  gatewayBundles: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  gatewayBundleProxyList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  gatewayBundlesApisList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
};

const updateProxyErrors = (arr = [], obj) => {
  const tempArr = arr;
  const index = arr.findIndex((e) => e.proxyUuid === obj.proxyUuid);
  if (index === -1) {
    tempArr.push(obj);
  } else {
    tempArr[index] = obj;
  }
  return tempArr;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.APP_RESET:
      return {
        ...state,
        ...resetState,
        gatewayBundleDetails: {},
        isBundleSaveSuccess: false,
        isBundleDeleteSuccess: false,
      };
    case Constants.GATEWAY_BUNDLES_DELETE_RESET:
      return {
        ...state,
        ...resetState,
        isBundleSaveSuccess: false,
        isBundleDeleteSuccess: false,
      };
    case Constants.APP_LOADING:
      return {
        ...state,
        ...resetState,
        isLoading: action.isLoading,
      };
    case Constants.GATEWAY_BUNDLES_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        gatewayBundles: action.payload,
      };
    case Constants.GATEWAY_BUNDLES_GET_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
      };
    case Constants.GATEWAY_BUNDLES_GET_USAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        gatewayBundleUsage: action.payload,
      };
    case Constants.GATEWAY_BUNDLES_GET_USAGE_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
      };
    case Constants.GATEWAY_BUNDLES_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        gatewayBundleDetails: action.payload,
      };
    case Constants.GATEWAY_BUNDLES_DETAILS_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
      };
    case Constants.GATEWAY_BUNDLES_ADD_REQUEST:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isBundleSaveSuccess: false,
      };
    case Constants.GATEWAY_BUNDLES_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        gatewayBundleDetails: action.payload,
        isBundleSaveSuccess: true,
      };
    case Constants.GATEWAY_BUNDLES_SAVE_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
        isBundleSaveSuccess: false,
      };
    case Constants.GATEWAY_BUNDLES_DELETE_SUCCESS:
      return {
        ...state,
        ...resetState,
        deleteBundleErrors: [],
        isBundleDeleteSuccess: true,
      };
    case Constants.GATEWAY_BUNDLES_DELETE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        deleteBundleErrors: action.payload,
        isBundleDeleteSuccess: false,
      };
    case Constants.GATEWAY_BUNDLES_GET_PROXY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        listProxyErrors: [],
        gatewayBundleProxyList: action.payload,
        gatewayBundleDetails: {
          ...state.gatewayBundleDetails,
          deletable: action.payload.deletable,
        },
      };
    case Constants.GATEWAY_BUNDLES_GET_PROXY_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        listProxyErrors: action.payload,
      };
    case Constants.GATEWAY_BUNDLES_GET_PROXY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        listProxyErrors: [],
        gatewayBundleProxyList: action.payload,
        gatewayBundleDetails: {
          ...state.gatewayBundleDetails,
        },
      };
    case Constants.GATEWAY_BUNDLES_GET_PROXY_LIST_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        listProxyErrors: action.payload,
      };
    case Constants.GATEWAY_BUNDLES_DEPLOY_PROXY_SUCCESS:
    case Constants.GATEWAY_BUNDLES_REDEPLOY_PROXY_SUCCESS:
    case Constants.GATEWAY_BUNDLES_UNDEPLOY_PROXY_SUCCESS: {
      const obj = {
        proxyUuid: get(action.payload, 'proxyUuid'),
        errorMessage: '',
        validation: Constants.SUCCESS,
      };
      return {
        ...state,
        isLoading: false,
        proxyErrors: updateProxyErrors(get(state, 'proxyErrors'), obj),
      };
    }

    case Constants.GATEWAY_BUNDLES_DEPLOY_PROXY_ERROR:
    case Constants.GATEWAY_BUNDLES_UNDEPLOY_PROXY_ERROR:
    case Constants.GATEWAY_BUNDLES_REDEPLOY_PROXY_ERROR: {
      const obj = {
        proxyUuid: get(action.payload, 'proxyUuid'),
        errorMessage: get(action.payload, 'errorMessage'),
        validation: Constants.FAIL,
      };
      return {
        ...state,
        isLoading: false,
        proxyErrors: updateProxyErrors(get(state, 'proxyErrors'), obj),
      };
    }
    case Constants.GATEWAY_BUNDLES_GET_APIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        listApiErrors: [],
        gatewayBundlesApisList: action.payload,
      };
    case Constants.GATEWAY_BUNDLES_GET_APIS_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        listApiErrors: action.payload,
        gatewayBundlesApisList: [],
      };
    default:
      return state;
  }
}

export const getIsLoading = (state) => get(state, 'gatewayBundle.isLoading');

export const getIsError = (state) => get(state, 'gatewayBundle.isError');

export const getErrors = state => get(state, 'gatewayBundle.errors');

export const getIsBundleSaveSuccess = state => get(state, 'gatewayBundle.isBundleSaveSuccess');

export const getIsBundleDeleteSuccess = state => get(state, 'gatewayBundle.isBundleDeleteSuccess');
export const getDeleteBundleErrors = state => get(state, 'gatewayBundle.deleteBundleErrors');

export const getGatewayBundleDetails = state => get(state, 'gatewayBundle.gatewayBundleDetails');

export const getGatewayBundleApiUsage = state => get(state, 'gatewayBundle.gatewayBundleUsage');

export const getGatewayBundlesListTotalPages = (state) => get(state, 'gatewayBundle.gatewayBundles.totalPages');
export const getGatewayBundlesListTotalElements = (state) => get(state, 'gatewayBundle.gatewayBundles.totalElements');
export const getGatewayBundlesListResults = (state) => get(state, 'gatewayBundle.gatewayBundles.results');

export const getGatewayBundlesProxyListTotalPages = (state) => get(state, 'gatewayBundle.gatewayBundleProxyList.totalPages');
export const getGatewayBundlesProxyListTotalElements = (state) => get(state, 'gatewayBundle.gatewayBundleProxyList.totalElements');
export const getGatewayBundleProxyListResults = (state) => get(state, 'gatewayBundle.gatewayBundleProxyList.results');
export const getGatewayBundleProxyListErrors = (state) => get(state, 'gatewayBundle.listProxyErrors');
export const getProxyErrors = (state) => get(state, 'gatewayBundle.proxyErrors');

export const getGatewayBundlesApisListTotalPages = (state) => get(state, 'gatewayBundle.gatewayBundlesApisList.totalPages');
export const getGatewayBundlesApisListTotalElements = (state) => get(state, 'gatewayBundle.gatewayBundlesApisList.totalElements');
export const getGatewayBundlesApisListResults = (state) => get(state, 'gatewayBundle.gatewayBundlesApisList.results');
export const getGatewayBundleApisListErrors = (state) => get(state, 'gatewayBundle.listApiErrors');
