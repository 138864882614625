import { get } from 'lodash';
import { green } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    marginTop: '-23px',
    marginBottom: '24px',
    height: '44px',
    padding: '4px 30px 0px 30px',
    color: get(theme, 'palette.primary.light'),
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: get(theme, 'palette.error.dark'),
  },
  info: {
    backgroundColor: 'rgba(247, 205, 59, 0.15)',
  },
  action: {
    '& .MuiLink-underlineHover:hover': {
      textDecoration: 'none',
    },
    padding: 0,
    marginRight: 0,
    marginTop: '-10px',
    color: get(theme, 'link'),
    cursor: 'pointer',
  },
});

export default styles;
