import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { pascalCase } from 'change-case';

import styles from './styles';
import { fetchAPIs } from "../../../../actions/api";
import { connect } from "react-redux";
import {
  getApisListResults,
  getApisListTotalElements,
  getApisListTotalPages,
} from "../../../../reducers/rateQuota";
import ListContainer from "../../../list";
import {
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
  GRID_ROWS_PER_PAGE_OPTIONS,
} from "../../../../constants";
import { getI18n, getI18nFormattedMessage } from "../../../../utils/intl";
import { getStatusColor, getStatusLabel } from "../../../Api/List/utils";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FormTextField from "../../../../components/FormTextField";

export const FilterByApiName = (props) => (
  <Grid item md={3}>
    <FormTextField
      {...props}
      id="rate-quotas-apis-filter-by-name"
      data-apim-test="rate-quotas-apis-filter-by-name"
    />
  </Grid>
);

export const getAPIListColumns = (classes) => [{
  id: 'name',
  label: getI18nFormattedMessage('label.api.name'),
  minWidth: 100,
  link: '/publish/apis/details',
}, {
  id: 'version',
  label: getI18nFormattedMessage('label.api.list.column.version'),
  minWidth: 50,
}, {
  id: 'ssgServiceType',
  label: getI18nFormattedMessage('label.api.list.column.type'),
  minWidth: 50,
}, {
  id: 'accessStatus',
  label: getI18nFormattedMessage('label.api.list.column.visibility'),
  minWidth: 50,
  value: (item) => pascalCase(item.accessStatus),
}, {
  id: 'portalStatus',
  label: getI18nFormattedMessage('label.state'),
  minWidth: 50,
  value: (item) => {
    const { portalStatus } = item;
    const status = getStatusLabel(portalStatus);
    const statusIconStyle = {
      fill: getStatusColor(portalStatus),
    };
    return (
      <span>
          <FiberManualRecordIcon size="0.82em" style={statusIconStyle} className={classes.statusIcon} />{status}
      </span>);
  },
}];


export const Apis = (props) => {
  const {
    classes,
    rqUuid,
    isLoading,
    results = [],
    totalElements,
    totalPages,
  } = props;

  const [filterByName, setFilterByName] = useState('');
  const [filterByNameApplied, setFilterByNameApplied] = useState(false);
  const intl = getI18n(useIntl());
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [page, setPage] = useState(0);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilterByNameApplied(true);
      setPage(0);
      props.fetchAPIs({ rateQuotaUuid: rqUuid, page: 0, name: filterByName, size: rowsPerPage });
    }
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchAPIs({ rateQuotaUuid: rqUuid, page: newPage, size: rowsPerPage });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchAPIs({ rateQuotaUuid: rqUuid, page: 0, size: newRowsPerPage });
  };

  useEffect(() => {
    if (rqUuid) {
      props.fetchAPIs({ rateQuotaUuid: rqUuid, size: rowsPerPage });
    }
  }, [rqUuid]);

  const noResultsMessage = filterByNameApplied ?
    `${intl.getI18nMessage('label.apis.filter.no.results')}` :
    intl.getI18nMessage('label.rate.quota.apis.no.results');

  return (
    <ListContainer
      listPageId="rate-quota-apis-list"
      isLoading={isLoading}
      showListHeader={false}
      pageClass={classes.listContent}
      pageBodyClass={classes.pageBodyClass}
      pageFilterAndSortClass={classes.pageFilterAndSortClass}
      filterAndSortContent={(
        <Fragment>
          <FilterByApiName
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            value={filterByName}
            placeholder={intl.getI18nMessage('label.gateway.bundle.apis.filter.by.name.placeholder')}
            handleChange={onFilterByNameChange}
            onKeyPress={onFilterByNameKeyPress}
          />
        </Fragment>
      )}
      columns={getAPIListColumns(classes) || []}
      rows={results}
      noResultsMessage={noResultsMessage}
      page={page}
      totalElements={totalElements}
      totalPages={totalPages}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onChangePreviousPage={onChangePreviousPage}
      onChangeNextPage={onChangeNextPage}
      />
  );
};

Apis.propTypes = {
  classes: shape({}),
  rateQuotaDetails: shape({}),
  isLoading: bool,
  totalPages: number,
  totalElements: number,
  results: arrayOf(shape({})),
  fetchAPIs: func,
  rqUuid: string,
};

const mapStateToProps = (state) => ({
  isLoading: false,
  results: getApisListResults(state),
  totalPages: getApisListTotalPages(state),
  totalElements: getApisListTotalElements(state),
});

const mapDispatchToProps = {
  fetchAPIs,
};
Apis.displayName = 'Apis';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Apis);
