import { has, get, find } from 'lodash';
import * as constants from '../../constants';
import { getUserDetails } from '../portalConfig';
import { getApiOwnerUser } from '../../utils';
import { getParsedApiDetails, getParsedCustomFields } from './responseParser';

const initialState = {
  isLoading: false,
  isError: false,
  selectedUsers: [],
  defaultSelectedUsers: [],
  availableUsers: [],
  errors: [],
  organizations: { results: [] },
  selectedOrganizations: [],
  allOrganizations: { results: [] },
  userContext: {},
  apiPermitted: { canEdit: true, canDelete: true },
  allPortalTags: [],
  apiTags: [],
  newTags: [],
  swaggerData: {},
  apiDetails: { accessStatus: 'PRIVATE' },
  eulas: {},
  apiNameUnique: { d: { result: true } },
  assets: [],
  usage: {},
  customFields: [],
  policyTemplate: { managedGateways: [], policyTemplates: [] },
  proxyUrlUnique: { d: { result: true } },
  isApiSaveSuccess: false,
  deleteApiStatus: constants.NONE,
  selectedManageOrg: { name: '' },
  rateLimitQuotas: { results: [] },
  recentSearches: [],
  autoSuggestions: [],
  apis: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  applications: [],
  metricsData: {
    analyticsDisabled: false,
    latencyData: [],
    shouldRefreshMetricsData: false,
  },
  orgsAccessList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  isAPIListLoading: false,
  isOrgAccessSaveSuccess:false,
  proxyList: [],
  proxyListErrors: [],
  proxyErrors: [],
  apiDeploymentList: [],
  documentationTree: [],
  newDocument: null,
  selectedViewDocument: {},
  isDocSaveSuccess: false,
  isDocDeleteSuccess: false,
  isUpdateTreeSuccess: false,
  documentErrors:[],
};

const updateProxyErrors = (arr = [], obj) => {
  const tempArr = arr;
  const index = arr.findIndex((e) => e.proxyUuid === obj.proxyUuid);
  if (index === -1) {
    tempArr.push(obj);
  } else {
    tempArr[index] = obj;
  }
  return tempArr;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.APP_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        isApiSaveSuccess: false,
        errors: [],
        isError: false,
      };
    case constants.API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        isError: false,
        errors: [],
      };
    case constants.API_PERMISSION_AVAILABLE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableUsers: action.payload,
        isApiSaveSuccess: false,
        isError: false,
        errors: [],
      };
    case constants.API_PERMISSION_SELECTED_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        defaultSelectedUsers: [...action.payload],
        selectedUsers: action.payload,
        isError: false,
        isApiSaveSuccess: false,
        errors: [],
      };
    case constants.API_RECENT_SEARCH_SUCCESS:
      {
        const recentSearches = get(action, 'payload.searchActivites', []);
        return {
          ...state,
          isLoading: false,
          recentSearches,
          isError: false,
          isApiSaveSuccess: false,
          errors: [],
        };
      }
    case constants.API_AUTO_SUGGESTION_SUCCESS:
      {
        const autoSuggestions = get(action, 'payload.results', []);
        return {
          ...state,
          isLoading: false,
          autoSuggestions,
          isError: false,
          isApiSaveSuccess: false,
          errors: [],
        };
      }
    case constants.API_AUTO_SUGGESTION_ERROR:
      {
        return {
          ...state,
          isLoading: false,
          autoSuggestions: [],
          isError: false,
          isApiSaveSuccess: false,
          errors: [],
        };
      }
    case constants.API_LIST_REQUEST: {
      return {
        ...state,
        isAPIListLoading: true,
      };
    }
    case constants.API_LIST_SUCCESS: {
      const apis = get(action, 'payload', {});
      return {
        ...state,
        apis,
        errors: [],
        isAPIListLoading: false,
        isApiSaveSuccess: false,
        isError: false,
        isLoading: false,
        metricsData: {
          analyticsDisabled: false,
          latencyData: getMetricsLatencyData(state),
          shouldRefreshMetricsData: true,
        },
      };
    }
    case constants.API_LIST_ERROR: {
      return {
        ...state,
        apis: { results: [] },
        errors: [],
        isAPIListLoading: false,
        isApiSaveSuccess: false,
        isError: true,
        isLoading: false,
      };
    }
    case constants.API_LIST_APPLICATIONS_SUCCESS: {
      const applications = get(action, 'payload', {});
      return {
        ...state,
        applications,
        errors: [],
        isAPIListLoading: false,
        isApiSaveSuccess: false,
        isError: false,
        isLoading: false,
      };
    }
    case constants.API_LIST_ANALYTICS_LATENCY_REQUEST: {
      return {
        ...state,
        metricsData: {
          analyticsDisabled: false,
          latencyData: [],
          shouldRefreshMetricsData: false,
        },
      };
    }
    case constants.API_LIST_ANALYTICS_LATENCY_SUCCESS: {
      const RESPONSE_CODE_ANALYTICS_DISABLED = 15201;
      const responseCode = get(action, 'payload.responseCode');
      return {
        ...state,
        metricsData: {
          analyticsDisabled: responseCode === RESPONSE_CODE_ANALYTICS_DISABLED,
          latencyData: get(action, 'payload.data.buckets', []),
          shouldRefreshMetricsData: false,
        },
      };
    }
    case constants.API_LIST_ANALYTICS_LATENCY_ERROR: {
      return {
        ...state,
        metricsData: {
          analyticsDisabled: false,
          latencyData: [],
          shouldRefreshMetricsData: false,
        },
      };
    }
    case constants.API_PERMISSION_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isApiSaveSuccess: true,
        apiDetails: getParsedApiDetails(action.payload),
        errors: [],
      };
    case constants.API_PERMISSION_ORGANIZATIONS_SUCCESS:
      {
        const orgs = state.organizations;
        const payload = action.payload;
        if (has(payload, 'results') && has(orgs, 'results')) {
          payload.results = [...orgs.results, ...action.payload.results];
        }
        return {
          ...state,
          isLoading: false,
          organizations: payload,
          isError: false,
          isApiSaveSuccess: false,
          errors: [],
        };
      }
    case constants.API_PERMISSION_ALL_ORGANIZATIONS_SUCCESS:
      {
        const payload = action.payload;
        return {
          ...state,
          isLoading: false,
          allOrganizations: payload,
          isError: false,
          isApiSaveSuccess: false,
          errors: [],
        };
      }
    case constants.API_PERMISSION_SELECTED_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedOrganizations: action.payload,
        isError: false,
        errors: [],
        isApiSaveSuccess: false,
      };
    case constants.API_PERMISSION_SELECTED_MANAGE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedManageOrg: action.payload,
        isError: false,
        errors: [],
        isApiSaveSuccess: false,
      };
    case constants.API_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
        isApiSaveSuccess: false,
        isOrgAccessSaveSuccess:false,
      };
    case constants.API_PERMISSION_SAVE_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.message,
        isApiSaveSuccess: true,
        errors: [],
        isError: false,
      };
    case constants.API_PERMISSION_REDIRECT_API_TAB:
      return {
        ...state,
        isApiVisibilitySaveSuccess: false,
        isError: false,
        errors: [],
      };
    case constants.API_GET_SUCCESS:
      return {
        ...state,
        apiDetails: getParsedApiDetails(action.payload),
        errors: [],
        isApiSaveSuccess: false,
        isError: false,
      };
    case constants.API_PERMISSION_ACCESS_STATUS_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isApiSaveSuccess: true,
        errors: [],
      };
    case constants.API_PERMISSION_PERMITTED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        apiPermitted: action.payload,
        errors: [],
        isApiSaveSuccess: false,
      };
    case constants.API_PERMISSION_PERMITTED_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        apiPermitted: { canEdit: false, canDelete: false },
        errors: action.payload,
        isApiSaveSuccess: false,
      };
    case constants.API_ORGANIZATION_ACCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        orgsAccessList: action.payload,
        errors: [],
        isApiSaveSuccess: false,
      };
    case constants.API_TAGS_ALL_TAGS_REQUEST:
      return {
        ...state,
        isLoading: false,
        isError: false,
        allPortalTags: [],
        errors: [],
        isApiSaveSuccess: false,
      };
    case constants.API_TAGS_ALL_TAGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        allPortalTags: get(action.payload, 'results'),
        errors: [],
        isApiSaveSuccess: false,
      };
    case constants.API_TAGS_API_TAGS_REQUEST:
      return {
        ...state,
        isLoading: false,
        isError: false,
        apiTags: [],
        errors: [],
        isApiSaveSuccess: false,
      };
    case constants.API_TAGS_API_TAGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        apiTags: action.payload.data,
        errors: [],
        isApiSaveSuccess: false,
      };
    case constants.API_SWAGGER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        swaggerData: action.payload.data,
        errors: [],
        isApiSaveSuccess: false,
      };
    case constants.API_TAGS_CREATE_TAGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        newTags: action.payload,
        errors: [],
        isApiSaveSuccess: false,
      };
    case constants.API_TAGS_ASSOCIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isApiSaveSuccess: false,
        errors: [],
      };
    case constants.API_TAGS_ASSOCIATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isApiSaveSuccess: true,
        errors: [],
      };
    case constants.API_USER_CONTEXTS:
      return {
        ...state,
        isLoading: false,
        userContext: action.payload,
      };
    case constants.API_EULAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        eulas: action.payload,
        isApiSaveSuccess: false,
        isError: false,
        errors: [],
      };
    case constants.API_NAME_UNIQUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        apiNameUnique: action.payload,
        isApiSaveSuccess: false,
        isError: false,
        errors: [],
      };
    case constants.API_ASSETS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assets: action.payload.map(({ name, type, uuid, links }) => ({
          name, uuid, links, ext: type && type.toLowerCase(),
        })),
        isApiSaveSuccess: false,
        isError: false,
        errors: [],
      };
    case constants.API_USAGE_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        usage: action.payload,
        isApiSaveSuccess: false,
        isError: false,
        errors: [],
      };
    case constants.CUSTOM_FIELDS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customFields: getParsedCustomFields(get(action, 'payload.d.results')),
        isApiSaveSuccess: false,
        isError: false,
        errors: [],
      };
    case constants.API_CUSTOM_FIELDS_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isApiSaveSuccess: true,
        isError: false,
        errors: [],
      };
    case constants.POLICY_TEMPLATES_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        policyTemplate: action.payload,
        isApiSaveSuccess: false,
        isError: false,
        errors: [],
      };
    case constants.API_POLICY_TEMPLATES_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isApiSaveSuccess: true,
        isError: false,
        errors: [],
      };
    case constants.API_PROXY_URL_UNIQUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        proxyUrlUnique: action.payload,
        isApiSaveSuccess: false,
        isError: false,
        errors: [],
      };
    case constants.API_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        apiDetails: getParsedApiDetails(action.payload),
        isError: false,
        errors: [],
        isApiSaveSuccess: true,
      };
    case constants.API_PUBLISH_SAVE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isApiSaveSuccess: true,
      };
    }
    case constants.API_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isApiSaveSuccess: true,
      };
    case constants.API_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isApiSaveSuccess: false,
        deleteApiStatus: constants.SUCCESS,
      };
    case constants.API_DELETE_ERROR: {
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        deleteApiStatus: constants.FAIL,
      };
    }
    case constants.API_DELETE_RESET: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        deleteApiStatus: constants.NONE,
      };
    }
    case constants.API_RATE_QUOTA_GET_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        rateLimitQuotas: action.payload,
        isApiSaveSuccess: false,
      };
    case constants.API_RATE_QUOTA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isApiSaveSuccess: false,
        apiRateLimitQuota: action.payload,
      };
    case constants.API_ORG_ACCESS_BUTTONS_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isApiSaveSuccess: false,
        isOrgAccessSaveSuccess: true,
      };
    }
    case constants.API_ORG_ACCESS_BUTTONS_REMOVE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isApiSaveSuccess: false,
        isOrgAccessSaveSuccess: true,
      };
    }
    case constants.API_DETAILS_GET_PROXY_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        proxyList: action.payload,
      };
    }
    case constants.API_DETAILS_GET_PROXY_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        proxyListErrors: action.payload,
        proxyList: [],
        isApiSaveSuccess: false,
      };
    }
    case constants.API_DETAILS_GET_DEPLOYMENT_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        apiDeploymentList: action.payload,
      };
    }
    case constants.API_DETAILS_GET_DEPLOYMENT_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        proxyListErrors: action.payload,
        apiDeploymentList: [],
        isApiSaveSuccess: false,
      };
    }
    case constants.API_DETAILS_DEPLOY_PROXY_SUCCESS:
    case constants.API_DETAILS_REDEPLOY_PROXY_SUCCESS:
    case constants.API_DETAILS_UNDEPLOY_PROXY_SUCCESS: {
      const obj = {
        proxyUuid: get(action.payload, 'proxyUuid'),
        errorMessage: '',
        validation: constants.SUCCESS,
      };
      return {
        ...state,
        isLoading: false,
        proxyErrors: updateProxyErrors(get(state, 'proxyErrors'), obj),
      };
    }

    case constants.API_DETAILS_DEPLOY_PROXY_ERROR:
    case constants.API_DETAILS_UNDEPLOY_PROXY_ERROR:
    case constants.API_DETAILS_REDEPLOY_PROXY_ERROR: {
      const obj = {
        proxyUuid: get(action.payload, 'proxyUuid'),
        errorMessage: get(action.payload, 'errorMessage'),
        validation: constants.FAIL,
      };
      return {
        ...state,
        isLoading: false,
        proxyErrors: updateProxyErrors(get(state, 'proxyErrors'), obj),
      };
    }
    case constants.API_DETAILS_GET_DOCUMENT_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        documentationTree: action.payload,
        isDocSaveSuccess: false,
        documentErrors: [],
        isDocDeleteSuccess: false,
        isUpdateTreeSuccess: false,
      };
    }
    case constants.API_DETAILS_GET_DOCUMENT_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        documentationTree: {},
        documentErrors: action.payload,
        isDocSaveSuccess: false,
        isDocDeleteSuccess: false,
        isUpdateTreeSuccess: false,
      };
    }
    case constants.API_DETAILS_ADD_DOCUMENT: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        newDocument: action.payload,
        documentErrors: [],
        isDocSaveSuccess: false,
        isDocDeleteSuccess: false,
        isUpdateTreeSuccess: false,
      };
    }
    case constants.API_DETAILS_GET_DOCUMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        selectedViewDocument: action.payload,
        documentErrors: [],
        isDocSaveSuccess: false,
        isDocDeleteSuccess: false,
        isUpdateTreeSuccess: false,
      };
    }
    case constants.API_DETAILS_GET_DOCUMENT_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        selectedViewDocument: {},
        documentErrors: action.payload,
        isDocSaveSuccess: false,
        isDocDeleteSuccess: false,
        isUpdateTreeSuccess: false,
      };
    }
    case constants.API_DETAILS_DOCUMENT_SAVE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        documentErrors: [],
        isDocSaveSuccess: true,
        isDocDeleteSuccess: false,
        isUpdateTreeSuccess: false,
        selectedViewDocument: action.payload,
      };
    }
    case constants.API_DETAILS_DOCUMENT_SAVE_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        documentErrors: action.payload,
        isDocSaveSuccess: false,
        isDocDeleteSuccess: false,
        isUpdateTreeSuccess: false,
      };
    }
    case constants.API_DETAILS_DOCUMENT_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        documentErrors: [],
        isDocDeleteSuccess: true,
      };
    }
    case constants.API_DETAILS_DOCUMENT_DELETE_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        documentErrors: action.payload,
        isDocDeleteSuccess: false,
        isUpdateTreeSuccess: false,
      };
    }
    case constants.API_DETAILS_DOCUMENT_UPDATE_TREE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        documentErrors: [],
        isUpdateTreeSuccess: true,
      };
    }
    case constants.API_DETAILS_DOCUMENT_UPDATE_TREE_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        documentErrors: action.payload,
        isUpdateTreeSuccess: false,
      };
    }
    case constants.API_DETAILS_DOCUMENT_RESET_NOTIFICATION: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isDocSaveSuccess: false,
        isDocDeleteSuccess: false,
        isUpdateTreeSuccess: false,
      };
    }
    default:
      return state;
  }
}

export const getIsLoading = (state) => get(state, 'permissions.isLoading');

export const getPermissionData = (state) => get(state, 'permissions.data');

export const getAvailableUsers = (state) => get(state, 'permissions.availableUsers');

export const getRecentSearches = (state) => get(state, 'permissions.recentSearches');

export const getAutoSuggestions = (state) => get(state, 'permissions.autoSuggestions');

export const getAPIs = (state) => get(state, "permissions.apis.results");
export const getAPIListTotalElements = (state) => get(state, "permissions.apis.totalElements");
export const getAPIListTotalPages = (state) => get(state, 'permissions.apis.totalPages');


export const getIsAPIsListLoading = (state) => get(state, "permissions.isAPIListLoading");

export const getRedirectToDetailsPage = (state) => get(state, 'permissions.redirectToDetailsPage');

export const getOrganizations = (state) => get(state, 'permissions.organizations.results');

export const getAllOrganizations = (state) => get(state, 'permissions.allOrganizations.results');

export const getOrganizationsPage = (state) => get(state, 'permissions.organizations');

export const getSelectedOrganizations = (state) => get(state, 'permissions.selectedOrganizations');

export const getIsApiVisibilitySaveSuccess = state => get(state, 'permissions.isApiVisibilitySaveSuccess');

export const getUserContext = (state) => get(state, 'permissions.userContext');

export const getIsError = (state) => get(state, 'permissions.isError');

export const getErrors = state => get(state, 'permissions.errors');

export const getApiAccessStatus = (state) => get(state, 'permissions.apiDetails.accessStatus');

export const getApiPortalStatus = (state) => get(state, 'permissions.apiDetails.portalStatus');

export const getApiCanEditPermitted = (state) => get(state, 'permissions.apiPermitted.canEdit');

export const getApiCanDeletePermitted = (state) => get(state, 'permissions.apiPermitted.canDelete');

export const getApiDetails = (state) => get(state, 'permissions.apiDetails');

export const getDeleteApiStatus = (state) => get(state, 'permissions.deleteApiStatus');

export const getApplications = (state) => get(state, 'permissions.applications');

export const getAllPortalTags = (state) => get(state, 'permissions.allPortalTags');

export const getAPITags = (state) => get(state, 'permissions.apiTags');

export const getNewTags = (state) => get(state, 'permissions.newTags');

export const getAPISwaggerData = (state) => get(state, 'permissions.swaggerData');

export const getIsApiNameUnique = (state) => get(state, 'permissions.apiNameUnique.d.result');

export const getManagedGateways = (state) => get(state, 'permissions.policyTemplate.managedGateways');

export const getIsProxyUrlUnique = (state) => get(state, 'permissions.proxyUrlUnique.d.result');

export const getIsSaveApiSuccess = state => get(state, 'permissions.isApiSaveSuccess');

export const getIsApiTagAssocSaveSuccess = (state) => get(state, 'permissions.isApiTagAssocSaveSuccess');

export const getAssets = (state) => get(state, 'permissions.assets');

export const getUsage = (state) => get(state, 'permissions.usage');

export const getCustomFields = (state) => get(state, 'permissions.customFields');

export const getPolicyTemplates = (state) => get(state, 'permissions.policyTemplate.policyTemplates');

export const getIsApiManagementSaveSuccess = (state) => get(state, 'permissions.isApiManagementSaveSuccess');

export const getShouldRefreshMetricsData = (state) => get(state, 'permissions.metricsData.shouldRefreshMetricsData', false);

export const getMetricsLatencyData = (state) => get(state, 'permissions.metricsData.latencyData', []);

export const getIsAnalyticsEnabled = (state) => !get(state, 'permissions.metricsData.analyticsDisabled', false);

export const getPublisherOrganizations = (state) =>
  get(state, 'permissions.organizations.results', []).filter(org => org.type === 'PUBLISHER');

export const getSelectedManageOrg = (state) => get(state, 'permissions.selectedManageOrg');

export const getSelectedUsers = (state) => {
  const userDetails = getUserDetails(state);
  const selectedUsers = get(state, 'permissions.selectedUsers');
  if (userDetails && userDetails.apiOwner) {
    const isApiOwner = find(selectedUsers, { uuid: userDetails.uuid });
    // Add the apiowner by default when logged in user as apiOwner
    if (!isApiOwner) {
      selectedUsers.push(getApiOwnerUser(userDetails));
    }
  }
  return selectedUsers;
};

export const getDefaultSelectedUsers = (state) => get(state, 'permissions.defaultSelectedUsers');

export const getEulas = (state) => get(state, 'permissions.eulas.results') || [];

export const getRateLimitQuotas = (state) => get(state, 'permissions.rateLimitQuotas.results');

export const getRateLimitQuotasPage = (state) => get(state, 'permissions.rateLimitQuotas');

export const getOrgsAccessListTotalPages = (state) => get(state, 'permissions.orgsAccessList.totalPages');
export const getOrgsAccessListTotalElements = (state) => get(state, 'permissions.orgsAccessList.totalElements');
export const getOrgsAccessListResults = (state) => get(state, 'permissions.orgsAccessList.results');
export const getIsOrgAccessSaveSuccess = (state) => get(state,'permissions.isOrgAccessSaveSuccess');

export const getProxyListResults = (state) => get(state, 'permissions.proxyList');
export const getProxyListErrors = (state) => get(state, 'permission.proxyListErrors');
export const getProxyErrors = (state) => get(state, 'permissions.proxyErrors');
export const getApiDeploymentList = (state) => get(state, 'permissions.apiDeploymentList');

export const getDocumentationTree = (state) => get(state, 'permissions.documentationTree.itemData', []);
export const getNewDocument = state => state.permissions.newDocument;
export const getSelectedViewDocument = state => state.permissions.selectedViewDocument;
export const getIsDocSaveSuccess = state => state.permissions.isDocSaveSuccess;
export const getIsDocDeleteSuccess = state => state.permissions.isDocDeleteSuccess;
export const getIsUpdateTreeSuccess = state => state.permissions.isUpdateTreeSuccess;
export const getDocumentErrors = state => state.permissions.documentErrors;
