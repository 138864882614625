/**
 * Refer to:
 * 1. https://www.figma.com/file/EuhA9sV3JzlTdZuVvbz2L7/Portal-Components?node-id=118%3A1002
 */
export default {
  color: {
    attention: '#F7CD3B',
    attentionBackground: 'rgba(247, 205, 59, 0.15)',
    danger: '#DE1B1B',
    error: '#B30303',
    errorHover: '#833400',
    errorBackground: 'rgba(179, 3, 3, 0.1)',
    success: '#306204',
    progress: '#97B081',
    warning: '#FF944D',
    warningBackground: 'rgba(255, 148, 77, 0.1)',
    tooltipBackground: 'rgba(0, 0, 0, 0.9)',
    tooltipText: '#FFFFFF',
    // color with names
    black: '#000000',
    white: '#FFFFFF',
    gray: '#D8D8D8',
    red: '#FF0000',
    darkGreyBackground: '#38364D',
  },
  borderRadius: {
    br3: '3px',
    br4: '4px',
    br10: '10px',
  },
  boxShadow: {
    none: 'none',
  },
  breakpoint: {
    xl: '1920px',
    lg: '1440px',
    md: '1280px',
    sm: '600px',
    xs: '320px',
  },
  fontStyle: {
    normal: 'normal',
  },
  fontWeight: {
    bold: 'bold',
    normal: 'normal',
    semiBold: 600,
  },
  letterSpacing: {
    ls01: '0.1px',
  },
  lineHeight: {
    lh14: '14px',
    lh21: '21px',
    lh20: '20px',
    lh28: '28px',
    lh32: '32px',
    lh36: '36px',
    lh48: '48px',
  },
  height: {
    h40: '40px',
    h72: '72px',
  },
  padding: {
    p0: '0px',
    p2: '2px',
    p4: '4px',
    p5: '5px',
    p6: '6px',
    p8: '8px',
    p10: '10px',
    p12: '12px',
    p16: '16px',
    p20: '20px',
    p21: '21px',
    p24: '24px',
    p36: '36px',
    p38: '38px',
    p50: '50px',
  },
  margin: {
    m0: '0px',
    m2: '2px',
    m4: '4px',
    m5: '5px',
    m6: '6px',
    m8: '8px',
    m10: '10px',
    m12: '12px',
    m15: '15px',
    m21: '21px',
    m24: '24px',
    m28: '28px',
    m31: '31px',
    m36: '36px',
    m60: '60px',
  },
  position: {
    p3: '3px',
  },
  textTransform: {
    capitalize: 'capitalize',
    lowercase: 'lowercase',
    none: 'none',
    uppercase: 'uppercase',
  },
  whiteSpace: {
    breakSpaces: 'break-spaces',
    nowrap: 'nowrap',
  },
  zIndex: {
    z800: 800,
  },
};
