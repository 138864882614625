import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
} from '@material-ui/core';
import {
  SearchTextListContainer,
} from '../../../../components';

import {
  ORGANIZATIONS_TITLE,
  AVAILABLE_ORGANIZATIONS_SUBTITLE,
  SEARCH_AVAILABLE_ORGANIZATIONS_SUBTITLE,
  SELECTED_ORGANIZATIONS_TITLE,
  ALL_SELECTED_ORGANIZATIONS_SUBTITLE,
  SEARCH_SELECTED_ORGANIZATIONS_SUBTITLE, ORG_ASSIGNMENT_TAB_TITLE, ORG_ASSIGNMENT_PAGE_SUB_TITLE,
} from '../labels';

export default function OrganizationAssignment(props) {
  const {
    classes,
    orgs,
    selectedOrgs,
    setSelectedOrgs,
  } = props;
  return (
    <Fragment>
      <h1 className={classes.pageTitle}>{ORG_ASSIGNMENT_TAB_TITLE}</h1>
      <h2 className={classes.sectionTitle}>{ORG_ASSIGNMENT_PAGE_SUB_TITLE}</h2>
      <Divider />
      <Grid container className={classes.fieldContainer}>
        <Grid md={6} item>
          <SearchTextListContainer
            listId={'orgs-list'}
            title={ORGANIZATIONS_TITLE}
            subtitle={AVAILABLE_ORGANIZATIONS_SUBTITLE}
            placeHolderText={SEARCH_AVAILABLE_ORGANIZATIONS_SUBTITLE}
            defaultList={orgs}
            defaultSelectedList={selectedOrgs}
            onSearchListChange={(selectedList) => setSelectedOrgs([...selectedList])}
            isCheckBoxVisible
          />
        </Grid>
        <Grid md={6} item>
          <SearchTextListContainer
            listId={'selected-orgs-list'}
            title={SELECTED_ORGANIZATIONS_TITLE}
            subtitle={ALL_SELECTED_ORGANIZATIONS_SUBTITLE}
            placeHolderText={SEARCH_SELECTED_ORGANIZATIONS_SUBTITLE}
            defaultList={selectedOrgs}
            defaultSelectedList={selectedOrgs}
            onSearchListChange={(selectedList) => setSelectedOrgs([...selectedList])}
            isDeleteIconVisible
            isSelectedNumberVisible
            isMandatory={false}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

OrganizationAssignment.propTypes = {
  classes: PropTypes.object,
  orgs: PropTypes.arrayOf(PropTypes.object),
  selectedOrgs: PropTypes.arrayOf(PropTypes.object),
  setSelectedOrgs: PropTypes.func,
};
