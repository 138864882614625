import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { arrayOf, bool, func, object, oneOfType, string } from 'prop-types';
import { find, get, isEmpty } from 'lodash';
import clsx from 'clsx';
import {
  Chip, CircularProgress, FormLabel, Grid, Link, TextField, makeStyles,
} from '@material-ui/core';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import styles from './styles';

const filter = createFilterOptions();

export default function FormAutoCompleteCombo(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    fieldContainerClass,
    id,
    name,
    error,
    hideLabel,
    hideHelper,
    helperText,
    withCreate,
  } = props;

  return (
    <div
      className={clsx(fieldContainerClass, classes.fieldContainer)}
      id={id} data-apim-test={id} data-layer7-test={id}
    >
      <div className={classes.formLabelContainer}>
        <FormLabel className={hideLabel && classes.hideFormLabel}>{name}</FormLabel>
      </div>
      {withCreate ?
        <FormAutoCompleteWithCreate {...props} />
        :
        <FormAutoCompleteWithoutCreate {...props} />
      }
      {!hideHelper &&
        <p className={clsx('MuiFormHelperText-root', classes.helperText, (error && classes.error))}>
          {helperText}
        </p>
      }
    </div>
  );
}

export const FormAutoCompleteWithoutCreate = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const getOptionLabelDefaultFn = (option) => option ? get(option, 'name') : '';
  const getOptionSelectedDefaultFn = (option, value) => value ? value.name === option.name : false;
  
  const {
    id,
    data = [],
    options = [],
    selectedValues,
    value = { name: '' },
    onChange,
    isMultiple,
    multiple,
    isLoading,
    error,
    inputLabel = '',
    getOptionLabel = getOptionLabelDefaultFn,
    getOptionSelected = getOptionSelectedDefaultFn,
    searchText,
    onSearchTextChange,
    isSecondaryText = false,
  } = props;

  return (
    <Autocomplete
      id={`${id}-auto-complete-combo`}
      data-apim-test={`${id}-auto-complete-combo`}
      data-layer7-test={`${id}-auto-complete-combo`}
      classes={{
        root: classes.root,
      }}
      {...(error && { className: classes.error })}
      loading={isLoading}
      loadingText={
        <>
          <span>Loading...</span>
          <CircularProgress color="inherit" size={20} />
        </>
      }
      options={isEmpty(data) ? options : data}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      value={selectedValues || value}
      multiple={isMultiple || multiple}
      filterSelectedOptions
      onChange={onChange}
      renderOption = {(option) => {
        return( <div>
          <div>{option.name}
            {option.marker &&
              <span className={classes.tooltipContainer}>
                {option.marker}
              </span>
            }
          </div>
           {isSecondaryText &&
             <div className={classes.secondaryText}>
               {option.secondaryText}
             </div>
            }
          </div>
      )}}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined" size="small"
            key={option}
            label={option.name}
            deleteIcon={<ClearOutlinedIcon />}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          className={classes.textField}
          variant="outlined"
          fullWidth
          label={inputLabel}
          inputProps={{
            ...params.inputProps,
            value: params.inputProps.value || searchText || getOptionLabel(value) || '',
          }}
          onChange={onSearchTextChange}
        />
      )}
    />
  );
};

export const FormAutoCompleteWithCreate = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  // Defaults from https://material-ui.com/components/autocomplete/
  const getOptionLabelDefaultFn = (option = {}) =>
    (option.inputValue || option.name);
  const getOptionSelectedDefaultFn = (option, value) =>
    (value.name === option.name);
  const getFilterOptionsDefaultFn = (options, params) => {
    const filtered = filter(options, params);
    const isInputValueExisting = find(filtered, option => (option.name === params.inputValue));
    if (!(isEmpty(params.inputValue) || isInputValueExisting)) {
      // Add option for new value
      return [{ inputValue: params.inputValue }, ...filtered];
    }
    return filtered;
  };
  const renderOptionDefaultFn = (option) => {
    if (option.inputValue) {
      return (
        <Grid container>
          <Grid item md={10} sm={10} xs={10}>{option.inputValue}</Grid>
          <Grid item md={2} sm={2} xs={2}>
            <Link color="secondary" underline="none">Create</Link>
          </Grid>
        </Grid>
      );
    }
    return option.name;
  };

  const {
    id,
    options = [],
    value,
    onChange,
    multiple,
    isLoading,
    error,
    inputLabel = '',
    getOptionLabel = getOptionLabelDefaultFn,
    getOptionSelected = getOptionSelectedDefaultFn,
    getFilterOptions = getFilterOptionsDefaultFn,
    renderOption = renderOptionDefaultFn,
    searchText,
    onSearchTextChange,
  } = props;

  return (
    <Autocomplete
      id={`${id}-auto-complete-combo`}
      data-apim-test={`${id}-auto-complete-combo`}
      data-layer7-test={`${id}-auto-complete-combo`}
      {...(error && { className: classes.error })}
      classes={{
        root: classes.root,
      }}
      loading={isLoading}
      loadingText={
        <>
          <span>Loading...</span>
          <CircularProgress color="inherit" size={20} />
        </>
      }
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      value={value}
      multiple={multiple}
      filterSelectedOptions
      onChange={onChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined" size="small"
            key={option}
            label={option.name}
            deleteIcon={<ClearOutlinedIcon />}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          className={classes.textField}
          variant="outlined"
          fullWidth
          label={inputLabel}
          inputProps={{
            ...params.inputProps,
            value: searchText || params.inputProps.value || getOptionLabel(value) || '',
          }}
          onChange={onSearchTextChange}
        />
      )}
      renderOption={renderOption}
      filterOptions={getFilterOptions}
    />
  );
};

FormAutoCompleteCombo.propTypes = {
  fieldContainerClass: string,
  id: string,
  name: string,
  data: arrayOf(object),
  onChange: func,
  isLoading: bool,
  isMultiple: bool,
  selectedValues: object,
  hideHelper: bool,
  helperText: string,
  withCreate: bool,
  error: bool,
  inputLabel: string,
  hideLabel: bool,
  getOptionLabel: func,
  getOptionSelected: func,
  optional: bool,
};

FormAutoCompleteWithoutCreate.propTypes = {
  id: string,
  data: arrayOf(object),
  options: arrayOf(object),
  selectedValues: object,
  value: oneOfType([object, arrayOf(object)]),
  onChange: func,
  isMultiple: bool,
  multiple: bool,
  isLoading: bool,
  error: bool,
  inputLabel: string,
  getOptionLabel: func,
  getOptionSelected: func,
  searchText: string,
  onSearchTextChange: func,
  isSecondaryText: bool,
};

FormAutoCompleteWithCreate.propTypes = {
  id: string,
  options: arrayOf(object),
  value: object,
  onChange: func,
  multiple: bool,
  isLoading: bool,
  error: bool,
  inputLabel: string,
  getOptionLabel: func,
  getOptionSelected: func,
  getFilterOptions: func,
  renderOption: func,
  searchText: string,
  onSearchTextChange: func,
};
