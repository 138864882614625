import { get } from 'lodash';

const styles = ({ color, fontSize, typography }) => ({
  pageTitle: {
    color: get(color, 'pageTitle'),
    fontFamily: get(typography, 'pageTitle'),
    fontSize: get(fontSize, 'pageTitle'),
    marginTop: 6,
  },
  sectionTitle: {
    color: get(color, 'pageTitle'),
    fontFamily: get(typography, 'pageTitle'),
    fontSize: get(fontSize, 'sectionTitle'),
  },
  apiTypeContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  expandContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  expandManualContainer: {
    cursor: 'pointer',
  },
  manualContainer: {
    width: '90%',
    float: 'left',
    fontSize: get(fontSize, 'sectionTitle'),
    margin: 0,
  },
  expandIconContainer: {
    color: get(color, 'primaryButtonBackground'),
    paddingTop: 6,
  },
  comboBoxContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  fileConatiner: {
    marginTop: 10,
    marginBottom: 10,
  },
  selectedFileContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formLabel: {
    display: 'inline',
    fontSize: get(fontSize, 'smallTitle'),
    float: 'unset',
  },
  apiServiceType: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    padding: '10px 0',
  },
  uploadFileSection: {
    backgroundColor: get(color, 'grayLight'),
    color: get(color, 'bodyText'),
    marginBottom: 5,
  },
  deleteIcon: {
    float: 'right',
    fontSize: '1.3rem',
  },
  swaggerSelectButton: {
    color: get(color, 'link'),
    height: 'auto',
    lineHeight: 1,
    padding: 0,
    margin: 0,
  },
  error: {
    color: get(color, 'error'),
    paddingTop: 10,
  },
  detailDivider: {
    width: '98%',
    backgroundColor: get(color, 'borderColor'),
  },
  statusContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  detailContainer: {
    display: 'block',
  },
  policyLabel: {
    padding: '10px 0',
  },
  line: {
    margin: '36px 0',
    borderColor: get(color, 'grayLight'),
  },
});

export default styles;
