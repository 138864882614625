// @flow
import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from './index.theme';
import HeaderDropdown from '../HeaderDropdown';
import SearchBar from '../SearchBar';
import {
  APPLICATION,
  API,
  ORGANIZATION,
  HELLO,
  GO,
} from '../labels';

export default function DashboardHeader(props) {
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  const [dropdownValue, setDropdownValue] = React.useState(API);
  const [selectedEntity, setSelectedEntity] = React.useState();
  const [disableButton, setDisableButton] = React.useState(true);

  let autoSuggestEntities = [];
  if (dropdownValue === API) {
    autoSuggestEntities = props.autoSuggestionsApis;
  } else if (dropdownValue === APPLICATION) {
    autoSuggestEntities = props.autoSuggestionsApps;
  } else if (dropdownValue === ORGANIZATION) {
    autoSuggestEntities = props.autoSuggestionsOrgs;
  }
  const autoSuggestSuggestions =
    autoSuggestEntities.map(entity => ({ key: entity.uuid, value: entity.name }));

  const handleDropdownUpdate = (value) => {
    setSelectedEntity();
    setDisableButton(true);
    setDropdownValue(value);
  };

  const handleSelection = (entity: Object) => {
    if (entity) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    setSelectedEntity(entity);
  };
  const handleGo = () => {
    const selectedUUID = selectedEntity.key;
    if (dropdownValue === API) {
      window.location.href = `https://${window.location.hostname}/publish/apis/details/${selectedUUID}`;
    }
    if (dropdownValue === APPLICATION) {
      window.location.href = `https://${window.location.hostname}/publish/applications/details/${selectedUUID}`;
    }
    if (dropdownValue === ORGANIZATION) {
      window.location.href = `https://${window.location.hostname}/admin/console/organizations/details/${selectedUUID}`;
    }
  };

  return (
    <div
      className={classes.Header}
      id="dashboard-header"
      data-apim-test="dashboard-header"
    >
      <div className={classes.HeaderInner}>
        <div className={classes.WelcomeHeader}>
          <span className={classes.Title}>{HELLO} {props.userContexts.firstName}</span>
        </div>
        <div className={classes.HeaderSearch} key={dropdownValue}>
          <HeaderDropdown
            handleDropdownUpdate={handleDropdownUpdate}
            selectedDropdownValue={dropdownValue}
            isOnlyPortalAdmin={props.isOnlyPortalAdmin}
          />
          <SearchBar
            fetchAvailableApis={props.fetchAvailableApis}
            fetchAvailableApps={props.fetchAvailableApps}
            fetchAvailableOrgs={props.fetchAvailableOrgs}
            autoSuggestSuggestions={autoSuggestSuggestions}
            selectedDropdownValue={dropdownValue}
            selectedOption={selectedEntity}
            handleSelection={handleSelection}
          />
          <div className={classes.GoButton}>
            <Button
              disabled={disableButton}
              variant="outlined"
              color="secondary"
              className={classes.HeaderGoButton}
              onClick={() => handleGo()}
            >
              {GO}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardHeader.propTypes = {
  fetchAvailableApis: PropTypes.func,
  autoSuggestionsApis: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
  fetchAvailableApps: PropTypes.func,
  autoSuggestionsApps: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
  fetchAvailableOrgs: PropTypes.func,
  autoSuggestionsOrgs: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
  userContexts: PropTypes.shape({
    firstName: PropTypes.string,
  }),
  isOnlyPortalAdmin: PropTypes.bool,
};
