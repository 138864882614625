import get from 'lodash/get';

export default ({
  borderRadius, color, fontSize, misc, typography,
} = {}) => ({
  Footer: {
    alignItems: 'center',
    backgroundColor: get(color, 'footerBackground'),
    bottom: 0,
    boxSizing: 'border-box',
    color: get(color, 'footerText'),
    display: 'flex',
    fontFamily: get(typography, 'footerText'),
    fontSize: get(fontSize, 'footerText'),
    height: get(misc, 'footerHeight'),
    justifyContent: 'space-between',
    left: 0,
    padding: '0 40px 0 40px',
    position: 'fixed',
    width: '100%',
    zIndex: 50,
  },

  FooterHelpButton: {
    backgroundColor: `${get(color, 'footerBackground')} !important`,
    border: `1px solid ${get(color, 'footerLink')}`,
    borderRadius: get(borderRadius, 'br3'),
    boxShadow: 'none',
    color: `${get(color, 'footerLink')} !important`,
    cursor: 'pointer',
    fontFamily: get(typography, 'footerText'),
    fontSize: get(fontSize, 'footerText'),
    fontWeight: 'bold',
    textTransform: 'capitalize',

    '&:hover, &:active': {
      backgroundColor: get(color, 'footerBackground'),
      boxShadow: 'none',
      color: `${get(color, 'footerLink')} !important`,
    },

    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineColor: `${get(color, 'footerLink')} !important`,
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
});
