import colorMod from 'color';

const styles = ({ color, fontSize, misc, typography }) => ({
  rootPanel: {
    fontSize: 20,
  },
  HeaderSettings: {
    zIndex: 100,
  },
  DropDownIcon: {
    marginLeft: 18,
    background: color.primaryButtonBackground,
    borderRadius: 3,
    color: color.primaryButtonText,
    opacity: 1,
    padding: 1,
    height: 18,
    width: 18,
    '&:hover': {
      background: color.primaryButtonHover,
    },
    '&:focus': {
      background: color.primaryButtonHover,
      border: '1px solid #FFFFFF',
      outline: '2px solid',
      outlineColor: color.primaryButtonBackground,
      borderRadius: 3,
    },
  },
  Popper: {
    marginTop: 4,
  },
  DropDownLabel: {
    color: '#ffffff',
    width: 18,
    height: 18,
  },
  CheckIcon: {
    width: 16,
    height: 16,
    color: color.link,
    float: 'right',
  },
  MoveArrowButtons: {
    width: 18,
    height: 18,
    color: color.link,
    border: '1px solid',
    borderColor: color.link,
    borderRadius: 3,
    float: 'right',
    marginLeft: 6,
    marginRight: 2,
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
      color: color.linkHover,
      borderColor: color.linkHover,
    },
    '&:focus-within': {
      color: color.linkHover,
      borderColor: color.linkHover,
      outline: '2px solid',
      outlineColor: color.linkHover,
    },
    '&:disabled': {
      border: '0.4000000059604645px solid #4B5060',
      opacity: '0.5',
      pointer: 'none',
    },
  },
  MoveArrows: {
    height: 14,
    width: 14,
  },
  MenuItem: {
    width: 192,
    display: 'block',
    color: color.bodyText,
    fontSize: fontSize.bodyText,
    fontFamily: typography.bodyText,
    lineHeight: '1.2em',
    paddingBottom: 8,
    paddingTop: 10,
    paddingLeft: 16,
    paddingRight: 10,
    textDecoration: 'none',
    transition: misc.colorTransition,
    cursor: 'pointer',
    '&:hover': {
      background: `${colorMod(color.primaryButtonBackground).alpha(0.08).string()} !important`,
    },
    '&:focus': {
      background: `${colorMod(color.primaryButtonBackground).alpha(0.08).string()} !important`,
      outline: 'none',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

export default styles;
