import Color from 'color';

const styles = ({ color, fontSize, typography }) => ({
  legend: (props) => ({
    display: 'flex',
    alignItems: 'center',
    paddingRight: 20,
    background: props.showOnChart
      ? Color(props.lineColor).alpha(0.05).string()
      : 'transparent',
    borderRadius: 4,
    paddingLeft: 16,
    paddingTop: 16,
    paddingBottom: 16,
    border: '1px solid',
    borderLeftColor: props.showOnChart ? props.lineColor : color.borderColor,
    borderLeft: props.showOnChart ? '4px solid' : '1px solid',
    marginLeft: props.showOnChart ? 16 : 19,
    marginTop: 16,
    borderColor: props.showOnChart ? color.background : color.borderColor,
    cursor: props.isNonClickable ? 'arrow' : 'pointer',
    '&:hover': !props.isNonClickable
      ? {
        marginLeft: 16,
        border: '1px solid',
        borderLeft: '4px solid',
        borderColor: props.lineColor,
        background: Color(props.lineColor).alpha(0.1).string(),
        '& button': {
          visibility: 'visible',
        },
      }
    : {
      '& button': {
        visibility: 'visible',
      },
    },
    '&:focus-within': !props.isNonClickable
      ? {
        outline: 'none',
        paddingLeft: 16,
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 19,
        border: '2px solid',
        borderLeft: '4px solid',
        borderColor: props.lineColor,
        background: Color(props.lineColor).alpha(0.1).string(),
        '& button': {
          visibility: 'visible',
        },
      }
    : {
      outline: 'none',
      '& button': {
        visibility: 'visible',
      },
    },
  }),
  relationship: {
    fontSize: fontSize.smallLabel,
    lineHeight: 2,
    fontFamily: typography.pageTitle,
    letterSpacing: 0,
    color: color.pageTitle,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  title: (props) => ({
    fontFamily: typography.bodyText,
    fontSize: fontSize.bodyText,
    fontWeight: 'bold',
    paddingRight: 5,
    lineHeight: 2,
    color: props.isNonClickable ? color.bodyText : color.link,
    display: 'flex',
    whiteSpace: 'nowrap',
  }),
  count: {
    fontFamily: typography.bodyText,
    fontSize: fontSize.bodyText,
    fontWeight: 'normal',
    color: color.bodyText,
  },
  graphIconContainer: {
    display: 'flex',
  },
  graphIconHolder: {
    visibility: 'hidden',
    display: 'contents',
    '& span': {
      display: 'block',
      lineHeight: '1.2 !important',
    },
  },
  icon: {
    fill: color.primaryButtonText,
  },
  graphIcon: {
    marginRight: 6,
    fill: color.primaryButtonBackground,
    position: 'relative',
    left: 12,
    right: 12,
    '&:hover': {
      fill: color.primaryButtonHover,
    },
    '&:focus': {
      outline: 'none',
      fill: color.primaryButtonHover,
      border: '1px solid transparent',
      borderRadius: 9,
      boxShadow: `0 0 0 2px ${color.primaryButtonHover}`,
    },
  },
  tooltiptext: {
    fontSize: fontSize.bodyText,
    fontFamily: typography.bodyText,
    width: 120,
    opacity: 0.9,
    visibility: 'hidden',
    backgroundColor: '#5a5f6d',
    color: '#fff',
    textAlign: 'center',
    borderRadius: 3,
    padding: '5px 0',
    position: 'absolute',
    zIndex: 1,
    bottom: '100%',
    left: '41%',
    '&:after': {
      content: 'close-quote',
      position: 'absolute',
      top: '100%',
      left: '10%',
      marginLeft: -5,
      borderWidth: 4,
      borderStyle: 'solid',
      borderColor: '#5a5f6d transparent transparent transparent',
    },
  },
  tooltip: {
    position: 'relative',
    '&:hover': {
      '& span': {
        visibility: 'visible',
      },
    },
  },
});

export default styles;
