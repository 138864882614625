import { get } from 'lodash';

const styles = ({ color }) => ({
  scrollBar: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  scrollBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 30,
    outline: 'none',
    cursor: 'pointer',
    color: get(color, 'link'),
    border: 0,
    height: 130,
    background: 'transparent linear-gradient(89deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box',
  },
  scrollBtn__hide: {
    display: 'none',
  },
  container: {
    position: 'relative',
  },
  leftscrollBtn: {
    position: 'absolute',
    top: 0,
    height: 130,
    fontSize: 30,
    color: get(color, 'link'),
    outline: 'none',
    cursor: 'pointer',
    left: 0,
    border: '0',
    background: 'transparent linear-gradient(89deg, #FFFFFF 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box',
  },
});

export default styles;
