import React, { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { get, map, isNull } from 'lodash';
import { sentenceCase } from 'change-case';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  Grid,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  withStyles,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/Close';

import {
  UNSAVED_DIALOG_TITLE,
  UNSAVED_DIALOG_DESCRIPTIONS,
  UNSAVED_DIALOG_SUBMIT_TEXT,
  UNSAVED_DIALOG_CANCEL_TEXT,
  DONE_TEXT,
  APPLICATION_STATUS_EDIT_PENDING_APPROVAL,
  APPLICATION_STATUS_DISABLED,
  ALERT_SUCCESS,
} from '../../../../constants';
import {
  AlertDialog,
  ErrorContainer,
  FormActionButtons,
  LoadingDialog,
  TablePagination,
} from '../../../../components';
import Key from './Key';
import styles from './styles';
import { getI18n } from '../../../../utils/intl';
import { hasPublisherRole } from '../../../../utils';

const GRID_ROWS_PER_PAGE_OPTIONS = [10, 20];
const GRID_ROWS_PER_PAGE_DEFAULT_OPTION = 10;

const blankKeyObject = {
  isNew: true,
  applicationUuid: '',
  name: '',
  apiKey: '',
  keySecret: '',
  defaultKey: false,
  oauthCallbackUrl: null,
  oauthScope: null,
  oauthType: null,
  status: 'ENABLED',
  keySecretHashed: false,
  disabledByType: null,
};

const processErrors = (arrErrors, defaultMessage) => {
  let errorMessage = defaultMessage;
  if (arrErrors.length > 0) {
    errorMessage = map(arrErrors, (validationError) => validationError.error).join('\n');
  }
  return errorMessage;
};

const KeyManagement = (props) => {
  const {
    applicationUuid,
    applicationDetails,
    appKeys,
    appKeysTotalPages,
    appKeysTotalElements,
    fetchKeyNameUnique,
    isUniqueKey,
    createKey,
    createKeyStatus,
    updateKey,
    updateKeyStatus,
    deleteKey,
    deleteKeyStatus,
    isSaveApplicationSuccess,
    appSecretHashingMetadata,
    userContext = {},
    errors = [],
    classes,
    resetApp,
    createdKey,
    notifyMessages,
  } = props;
  const intl = getI18n(useIntl());

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [keys, setKeys] = useState(appKeys);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [lastCreatedKey, setLastCreatedKey] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchApplicationKeys(applicationUuid, newPage, rowsPerPage);
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchApplicationKeys(applicationUuid, 0, newRowsPerPage);
  };

  const openDeleteDialog = (e) => {
    e.stopPropagation();
    setShowDeleteDialog(true);
  };
  const closeDeleteDialog = () => setShowDeleteDialog(false);

  const openLoadingDialog = () => setShowLoadingDialog(true);
  const closeLoadingDialog = () => setShowLoadingDialog(false);

  const onDeleteKey = async () => {
    const key = keys[activeIndex];
    closeDeleteDialog();
    openLoadingDialog();
    await deleteKey({ ...key, appUuid: applicationUuid });
    closeLoadingDialog();
  };

  const onRowClick = (idx) => {
    const isNew = get(keys[idx], 'isNew');
    if (hasUnsavedChanges) {
      setIsDialogOpen(true);
    } else if (activeIndex === idx && !isNew) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(idx);
    }
  };

  useEffect(() => {
    if (isSaveApplicationSuccess) {
      setHasUnsavedChanges(false);
      notifyMessages(intl.getI18nMessage('label.application.update.success'), ALERT_SUCCESS);
    }
    if (createKeyStatus === 'SUCCESS') {
      setHasUnsavedChanges(false);
      notifyMessages(intl.getI18nMessage('label.application.key.create.success'), ALERT_SUCCESS);
      props.fetchApplicationKeys(applicationUuid);
    }
    if (updateKeyStatus === 'SUCCESS') {
      setHasUnsavedChanges(false);
      notifyMessages(intl.getI18nMessage('label.application.key.update.success'), ALERT_SUCCESS);
    }
    if (deleteKeyStatus === 'SUCCESS') {
      setHasUnsavedChanges(false);
      notifyMessages(intl.getI18nMessage('label.application.key.delete.success'), ALERT_SUCCESS);
      setActiveIndex(-1);
    }
    if (createKeyStatus === 'FAIL' || updateKeyStatus === 'FAIL' || deleteKeyStatus === 'FAIL') {
      notifyMessages(processErrors(errors, 'Save error. Check form for details.'), 'error');
    }
  }, [createKeyStatus, updateKeyStatus, deleteKeyStatus, isSaveApplicationSuccess]);

  useEffect(() => {
    setKeys(appKeys);
    if (lastCreatedKey) {
      setActiveIndex(appKeys.findIndex(appKey => (appKey.name === lastCreatedKey.name)));
    }
  }, [appKeys.length]);

  useEffect(() => {
    const newKey = keys.find(key => get(key, 'isNew'));
    if (!newKey && isNull(createdKey)) {
      setKeys(appKeys);
    }
  }, [appKeys[activeIndex]]);

  useEffect(() => {
    const newKey = keys.find(key => get(key, 'isNew'));
    if (newKey) {
      setPage(0);
      setActiveIndex(0);
    }
  }, [keys.length]);

  const addNewKey = () => {
    const newKey = keys.find(key => get(key, 'isNew'));
    if (!newKey) {
      const arr = [].concat(keys);
      arr.unshift(blankKeyObject);
      setKeys(arr);
    }
    resetApp();
  };

  const removeNewKey = () => {
    const newKey = keys.find(key => get(key, 'isNew'));
    if (newKey) {
      const arr = [].concat(keys);
      arr.shift();
      setKeys(arr);
      setActiveIndex(-1);
    }
  };

  const onNext = () => {
    localStorage.setItem('notificationMessage', '');
    localStorage.setItem('notificationStatus', '')
    setTimeout(() => { window.location.href = `/publish/applications/details/${applicationUuid}`; }, 1000);
  };

  const onDialogSubmit = () => {
    setActiveIndex(-1);
    setHasUnsavedChanges(false);
    setIsDialogOpen(false);
  };

  const onDialogClose = () => {
    setIsDialogOpen(false);
  };

  const isDisabledApp = get(applicationDetails, 'status') === APPLICATION_STATUS_DISABLED;
  const getIsEditable = (key) => {
    // Edit Pending Approval Application
    if (hasPublisherRole(userContext) &&
        get(applicationDetails, 'status') === APPLICATION_STATUS_EDIT_PENDING_APPROVAL &&
        get(key, 'defaultKey')) {
      return false;
    }
    // Disabled Application
    if (isDisabledApp) {
      return false;
    }
    return true;
  };

  const isNonDefaultKey = !get(keys[activeIndex], 'defaultKey');

  return (
    <Fragment>
      <AlertDialog
        id="gateway-bundle-details-delete-dialog"
        isOpen={showDeleteDialog}
        title={intl.getI18nMessage('label.delete.confirmation.title')}
        description={intl.getI18nMessage('label.api.key.delete.confirmation.text')}
        submitText={intl.getI18nMessage('label.api.key.delete.button')}
        cancelText={intl.getI18nMessage('label.cancel.button')}
        onClose={closeDeleteDialog}
        onSubmit={onDeleteKey}
        onCancel={closeDeleteDialog}
        submitButtonClass={classes.submitButton}
      />
      <AlertDialog
        isOpen={isDialogOpen}
        title={UNSAVED_DIALOG_TITLE}
        description={UNSAVED_DIALOG_DESCRIPTIONS}
        submitText={UNSAVED_DIALOG_SUBMIT_TEXT}
        cancelText={UNSAVED_DIALOG_CANCEL_TEXT}
        onClose={onDialogClose}
        onSubmit={onDialogSubmit}
        onCancel={onDialogClose}
        dialogId="application-unsaved-dialog"
      />
      <LoadingDialog
        isOpen={showLoadingDialog}
        title={intl.getI18nMessage('label.api.key.delete.loading.title')}
        description={intl.getI18nMessage('label.api.key.delete.loading.text')}
        dialogId="deleting-key-dialog"
      />
      <Typography variant="h1" className={classes.pageTitle}>Authentication & Keys</Typography>
      <Button
        variant="contained"
        color="secondary"
        className={classes.addButton}
        onClick={() => addNewKey()}
        disabled={isDisabledApp || hasUnsavedChanges}
      >
        {'Add Key'}
      </Button>
      {(errors.length > 0) &&
        <ErrorContainer errors={errors} />
      }
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid md={12} item>
          <div className={classes.keysContainer}>
            <TableContainer className={classes.tableContainer} component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell>{'Key Name'}</TableCell>
                    <TableCell align={'center'}>{'Status'}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keys.map((row, idx) => {
                    const isNew = get(row, 'isNew');
                    const isOpen = (activeIndex === idx);
                    const isDisabledRow = (hasUnsavedChanges && activeIndex !== idx);
                    let rowClass = '';
                    if (isOpen) { rowClass = classes.openRow; }
                    if (isDisabledRow) { rowClass = classes.isDisabledRow; }
                    return (
                      <Fragment key={row.name}>
                        <TableRow
                          className={rowClass}
                          onClick={() => onRowClick(idx)}
                        >
                          {!isOpen &&
                            <Fragment>
                              <TableCell component="th" scope="row">
                                <IconButton
                                  className={classes.expandCollapseButton}
                                  size="small"
                                  onClick={() => onRowClick(idx)}
                                >
                                  <KeyboardArrowDownIcon />
                                </IconButton>
                                {get(row, 'isNew') ? '[NEW KEY]' : row.name}
                                {get(row, 'defaultKey') &&
                                  <Chip
                                    className={classes.defaultChip}
                                    size="small"
                                    label={'Default'}
                                  />
                                }
                              </TableCell>
                              <TableCell component="th" scope="row" align={'center'}>
                                {!get(row, 'isNew') && <Chip
                                  className={get(row, 'status') === 'ENABLED'
                                    ? classes.enabledChip
                                    : classes.disabledChip
                                  }
                                  size="small"
                                  label={sentenceCase(get(row, 'status'))}
                                />}
                              </TableCell>
                            </Fragment>
                          }
                          {isOpen &&
                            <Fragment>
                              <TableCell className={classes.openRowTitle} component="th" scope="row">
                                <IconButton
                                  className={classes.expandCollapseButton}
                                  size="small"
                                  onClick={() => onRowClick(idx)}
                                >
                                  <KeyboardArrowUpIcon />
                                </IconButton>
                                {isNew &&
                                  <IconButton size="small" onClick={() => removeNewKey()}>
                                    <CloseIcon />
                                  </IconButton>
                                }
                                {isNew ? 'Add Key' : 'Key Details'}
                              </TableCell>
                              <TableCell component="th" scope="row" align={'center'}>
                                {isNonDefaultKey &&
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    id="delete-api-key-button"
                                    data-apim-test="delete-api-key-button"
                                    className={classes.deleteButton}
                                    onClick={openDeleteDialog}
                                  >
                                    {'Delete Key'}
                                  </Button>
                                }
                              </TableCell>
                            </Fragment>
                          }
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                              <Key
                                idx={idx}
                                applicationUuid={applicationUuid}
                                editable={getIsEditable(row)}
                                details={{
                                  name: get(row, 'name'),
                                  status: get(row, 'status'),
                                  isDefault: get(row, 'defaultKey'),
                                  callbackUrl: get(row, 'oauthCallbackUrl'),
                                  scope: get(row, 'oauthScope'),
                                  type: get(row, 'oauthType'),
                                  clientId: get(row, 'apiKey'),
                                  clientSecret: get(createdKey, 'keySecret') || get(row, 'keySecret'),
                                  isHashed: get(row, 'keySecretHashed'),
                                  isCreatedKey: get(createdKey, 'name') === get(row, 'name'),
                                }}
                                setHasUnsavedChanges={setHasUnsavedChanges}
                                notifyMessages={notifyMessages}
                                appSecretHashingMetadata={appSecretHashingMetadata}
                                fetchKeyNameUnique={fetchKeyNameUnique}
                                isUniqueKey={isUniqueKey}
                                createKey={(data) => {
                                  setLastCreatedKey(data);
                                  createKey(data);
                                }}
                                updateKey={updateKey}
                                closeAction={onRowClick}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <div className={classes.paginationContainer}>
              <TablePagination
                id="app-keys-pagination"
                data-apim-test="app-keys-pagination"
                page={page}
                count={appKeysTotalElements}
                pageCount={appKeysTotalPages}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                onChangePreviousPage={onChangePreviousPage}
                onChangeNextPage={onChangeNextPage}
                labelRowsPerPage={intl.getI18nMessage('label.pagination.rows.per.page')}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <FormActionButtons
        onNextClick={onNext}
        nextText={DONE_TEXT}
        showCancel={false}
        id={'manage-keys-form-buttons'}
      />
    </Fragment>
  );
};

export default withStyles(styles)(KeyManagement);

KeyManagement.propTypes = {
  applicationUuid: PropTypes.string,
  applicationDetails: PropTypes.object,
  appKeys: PropTypes.arrayOf(PropTypes.object),
  appKeysTotalPages: PropTypes.number,
  appKeysTotalElements: PropTypes.number,
  fetchKeyNameUnique: PropTypes.func,
  isUniqueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  createKey: PropTypes.func,
  updateKey: PropTypes.func,
  deleteKey: PropTypes.func,
  createKeyStatus: PropTypes.string,
  updateKeyStatus: PropTypes.string,
  deleteKeyStatus: PropTypes.string,
  isSaveApplicationSuccess: PropTypes.bool,
  appSecretHashingMetadata: PropTypes.object,
  userContext: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  resetApp: PropTypes.func,
  createdKey: PropTypes.object,
  notifyMessages: PropTypes.func,
  fetchApplicationKeys: PropTypes.func,
};

