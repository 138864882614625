import React, { useContext } from 'react';
import { Grid, Button, ButtonGroup, FormLabel, Chip } from '@material-ui/core';
import { func, object, string } from 'prop-types';
import { map } from 'lodash';

import { ApisOrgsListContext } from './';
import { FormTextField } from '../../../../components';
import { getI18nFormattedMessage } from '../../../../utils/intl';

export const FilterByName = (props) => (
  <Grid item md={3} sm={3} xs={12}>
    <FormTextField
      {...props}
      id="rate-quota-apis-orgs-filter-by-name"
      data-apim-test="rate-quota-apis-orgs-filter-by-name"
    />
  </Grid>
);


export const SortButtons = (props) => (
  <Grid
    item md={3} sm={6} xs={12}
    id="apis-orgs-show-container" data-apim-test="apis-orgs-show-container" data-layer7-test="apis-orgs-show-container"
  >
    <FormLabel>{getI18nFormattedMessage('label.show')}</FormLabel>
    <ButtonGroup className={props.fieldContainerClass}>
      <Button
        id="apis-orgs-show-all" data-apim-test="apis-orgs-show-all" data-layer7-test="apis-orgs-show-all"
        color="primary"
        variant="outlined"
        className={props.assigned === '' ? props.activeButton: ''}
        onClick={(e) => props.onSortButtonClick(e, '')}
      >
        {getI18nFormattedMessage('label.all')}
      </Button>
      <Button
        id="apis-orgs-show-asssigned" data-apim-test="apis-orgs-show-access" data-layer7-test="apis-orgs-show-access"
        color="primary"
        variant="outlined"
        className={props.assigned
          ? props.activeButton: ''}
        onClick={(e) => props.onSortButtonClick(e, true)}
      >
        {getI18nFormattedMessage('label.assigned')}
      </Button>
      <Button
        id="apis-orgs-show-noaccess" data-apim-test="apis-orgs-show-noaccess" data-layer7-test="apis-orgs-show-noaccess"
        color="primary"
        variant="outlined"
        className={props.assigned === false ? props.activeButton: ''}
        onClick={(e) => props.onSortButtonClick(e, false)}
      >
        {getI18nFormattedMessage('label.not.assigned')}
      </Button>
    </ButtonGroup>
  </Grid>
);

export const Tags = ({ tags, numSelectedRows }) => {
  const apisOrgsListContext = useContext(ApisOrgsListContext);
  const clickable = (numSelectedRows === 0);
  return map(
    tags,
    tag => (
      <Chip
        variant="outlined" size="small"
        clickable={true}
        style={{ marginRight: '2px' }}
        key={tag} label={tag}
        onClick={() => { if(clickable) { apisOrgsListContext.onClickTag(tag)}}}
      />
    ),
  );
}

SortButtons.propTypes = {
  assigned: string,
  fieldContainerClass: object,
  activeButton: func,
  onSortButtonClick: func,
};
export const FilterSeparator = () => (
  <Grid item md={1} />
);

