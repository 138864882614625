/**
 * Refer to:
 * 1. https://www.figma.com/file/EuhA9sV3JzlTdZuVvbz2L7/Portal-Components?node-id=118%3A1002
 */
import { get, merge } from 'lodash';

import constants from './constants';
import { modifyAlpha } from '../utils/modifyColour';

const {
  color,
} = constants;

export default ({ themes }) =>
  merge(
    {},
    constants,
    {
      color: {
        // Hardcoded
        ...color,
        confirmation: color.success,
        enabled: color.success,

        // Procedural
        disabledBackground: modifyAlpha(get(themes, 'color.bodyText'), 0.1),
        disabledGraphic: modifyAlpha(get(themes, 'color.bodyText'), 0.5),
        disabledText: modifyAlpha(get(themes, 'color.bodyText'), 0.7),
        disabledTitle: modifyAlpha(get(themes, 'color.bodyText'), 0.4),
        formBorder: modifyAlpha(get(themes, 'color.primaryButtonBackground'), 0.4),
        lightBackground: modifyAlpha(get(themes, 'color.borderColor'), 0.1),
        lightGrayBackground: modifyAlpha(get(themes, 'color.borderColor'), 0.3),
        lightHover: modifyAlpha(get(themes, 'color.primaryButtonBackground'), 0.08),
        overlayBackground: modifyAlpha(get(themes, 'color.bodyText'), 0.6),

        // Secondary Button (& Unselected Button in Group)
        secondaryButtonHover: modifyAlpha(get(themes, 'color.primaryButtonBackground'), 0.08),
        primaryButtonDisabledBackground: modifyAlpha(get(themes, 'color.bodyText'), 0.05),

        // TODO:
        // The below properties need to be re-evaluated and deprecated
        accent: get(themes, 'color.primaryButtonBackground'),
        accentLight: '#62C6E5',
        accentDark: '#677B87',

        title: '#20465F',
        titleLight: '#2E4448',

        grayLight: '#F7F7F7',
        grayDark: '#48575F',
        grayColumnBackground: '#2C353A', // swagge
        grayDarkBackground: '#222A2E',
        pending: '#F79232',
        orange: '#F79232',

        // swagger colours
        swaggerGreen: '#00A645',
        swaggerRed: '#DB4332',
        swaggerBlue: '#0168B7',
        swaggerOrange: '#FCA130',

        // custom icons
        iconsMain: '#09AEEF',
        iconsLight: '#62C6E5',
        iconsGray: '#677B87',
        iconsDark: '#408492',
        iconsDarker: '#20465F',

        // implementation color variables
        overlay: 'rgba(255, 255, 255, 0.85)',

        // default branding variables
        background: '#F7F7F7',
        borderColor: '#D8D8D8',

        headerBackground: '#FFFFFF',
        siteTitle: '#48575F',
        headerLink: '#09AEEF',

        pageTitle: '#48575F',

        bodyText: '#48575F',
        link: get(themes, 'color.link'),
        linkHover: 'rgba(9, 174, 239, .5)',
        smallText: 'rgba(72, 87, 95, 0.5)',
        secondaryText: '#464646',

        primaryButtonBackground: get(themes, 'color.primaryButtonBackground'),
        primaryButtonText: get(themes, 'color.primaryButtonText'),
        primaryButtonHover: 'rgba(9, 174, 239, .5)',

        dropdownOptionHover: '#F5FAFF',

        footerBackground: '#48575F',
        footerText: '#FFFFFF',
        footerLink: '#09AEEF',
      },
      misc: {
        // header
        headerHeight: 64,

        // footer
        footerHeight: 72,

        // TODO:
        // The below properties need to be re-evaluated and deprecated

        // borders
        borderRadiusLarge: 10,
        borderRadiusMedium: 6,
        borderRadiusSmall: 4,

        // shadows
        boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.1)',

        // buttons
        buttonFontVerticalOffsetLg: 1,
        buttonFontVerticalOffset: 1,
        buttonFontVerticalOffsetSm: 1,
        buttonFontVerticalOffsetXs: 0,

        // responsive
        breakpointLarge: 1280,
        breakpointMedium: 992,
        breakpointSmall: 600,
        breakpointXSmall: 480,

        // layout / grid
        maxWidthLarge: 1177,
        maxWidthMedium: 1154,
        maxWidthSmall: 1154,

        gutter: 20,
        gridWidth: 1160,

        // menu
        menuHorizontalPadding: 20,

        // modal
        overlayZIndex: '80',

        // transitions
        colorTransition: 'color 250ms ease-in-out',
        backgroundColorTransition: 'background-color 250ms ease-in-out',
        borderColorTransition: 'border-color 250ms ease-in-out',
        opacityTransition: 'opacity 250ms ease-in-out',
      },
    },
  );
