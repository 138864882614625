import { get, trim, find, capitalize, isUndefined, includes , findIndex, map, split } from 'lodash';
import { isEmpty, isLength } from 'validator';
import slugify from 'slugify';

import { getI18nFormattedMessage } from '../utils/intl';
import {
  ROLE_PORTAL_ADMIN,
  ROLE_API_OWNER,
  ROLE_ORG_ADMIN,
  ROLE_DEVELOPER,
  ROLE_ORG_PUBLISHER,
  PORTAL_STATUS_NEW,
  API_OWNER_TEXT,
  ORGANIZATION_ADMIN_TEXT,
  DEVELOPER_TEXT,
  APPLICATION_DISABLED_BY_TYPE_EXTERNAL,
  APPLICATION_STATUS_ENABLED,
  APPLICATION_STATUS_PENDING_APPROVAL,
  APPLICATION_STATUS_EDIT_PENDING_APPROVAL,
  RATE_LIMIT_QUOTA_DEFAULT_FIELD,
  APPLICATION_STATUS_DELETE_PENDING_APPROVAL,
} from '../constants';

const MAX_LENGTH = 255;
const URI_ALLOWED_STRING = /^[a-zA-Z0-9_-]*$/;
const URI_NOT_ALLOWED_CHARACTERS = /[^a-zA-Z0-9_-\s]/g;

const getLastExtension = (fileName, search) => {
  if (!fileName) return null;
  return fileName.substr(fileName.lastIndexOf(search) + 1).toLowerCase();
};

export const getErrorMessage = error => (
  get(error, 'message') && [get(error, 'message')]
);

export const getValidationErrors = (error) => (
  get(error, 'response.data.error.detail.validationErrors') || [error]
);

export const getUserRole = ({
  portalAdmin,
  apiOwner,
  orgAdmin,
  developer,
  orgPublisher,
} = {}) => {
  let roleName;
  if (portalAdmin) {
    roleName = ROLE_PORTAL_ADMIN;
  }
  if (apiOwner) {
    roleName = ROLE_API_OWNER;
  }
  if (orgAdmin) {
    roleName = ROLE_ORG_ADMIN;
  }
  if (developer) {
    roleName = ROLE_DEVELOPER;
  }
  if (orgPublisher) {
    roleName = ROLE_ORG_PUBLISHER;
  }
  return roleName;
};

export const getSingularRoleName = (role) => {
  const ROLE_ROLENAME = {
    apiowners: API_OWNER_TEXT,
    devorgadministrators: ORGANIZATION_ADMIN_TEXT,
    developers: DEVELOPER_TEXT,
  };
  const roleName = ROLE_ROLENAME[role.roleName] || role.roleName;
  return role.organization ? `${role.organization} (${roleName})` : roleName;
};

export const hasPublisherRole = (user) => (
  getUserRole(user) === ROLE_PORTAL_ADMIN || getUserRole(user) === ROLE_API_OWNER
);

export const hasOrgBoundRole = (user) => (
  [ROLE_ORG_ADMIN, ROLE_ORG_PUBLISHER, ROLE_DEVELOPER].includes(getUserRole(user))
);

export const hasDeveloperRole = (user) => (
  getUserRole(user) === ROLE_ORG_ADMIN || getUserRole(user) === ROLE_DEVELOPER
);

export const hasAdminRole = (user) => getUserRole(user) === ROLE_PORTAL_ADMIN;

export const hasOrgPublisherRole = (user) => getUserRole(user) === ROLE_ORG_PUBLISHER;

export const hasOrgDeveloperRole = (user) => getUserRole(user) === ROLE_DEVELOPER;

export const hasAccessPermissionsPage = (portalStatus) => portalStatus === PORTAL_STATUS_NEW;

export const getApiOwnerUser = (userDetails) => {
  const apiOwnerName = `${userDetails.firstName} ${userDetails.lastName}`;
  const apiOwner = { uuid: userDetails.uuid, name: apiOwnerName, roleOrgs: [{ roleName: 'apiOwner' }] };
  return apiOwner;
};

export const hasAPICRUDPermissions = (user, types) => {
  const apiPermissions = get(user, 'permissions.API');
  if (apiPermissions && apiPermissions.length > 0) {
    if (types.every((type) => apiPermissions.includes(type))) {
      return true;
    }
  }
  return false;
};

export const hasAPIDeploymentCRUDPermissions = (user, types) => {
  const apiDeploymentPermissions = get(user, 'permissions.API_DEPLOYMENT');
  if (apiDeploymentPermissions && apiDeploymentPermissions.length > 0) {
    if (types.every((type) => apiDeploymentPermissions.includes(type))) {
      return true;
    }
  }
  return false;
};

export const hasProxyCRUDPermissions = (user, types) => {
  const proxyPermissions = get(user, 'permissions.PROXY');
  if (proxyPermissions && proxyPermissions.length > 0) {
    if (types.every((type) => proxyPermissions.includes(type))) {
      return true;
    }
  }
  return false;
};

export const hasManagePermissions = (user, api, apiPermitted) => {
  const userOrgUuid = get(user, 'organizationUuid');
  const apiManageOrgUuid = get(api, 'managingOrgUuid');
  return (apiPermitted || apiManageOrgUuid === userOrgUuid);
};

export const hasAPIPortalStatus = (api) => (api.portalStatus !== 'NEW' && api.portalStatus !== 'INCOMPLETE');

export const canAPIPerformAction = (user, api, apiPermitted, action) =>
  (hasManagePermissions(user, api, apiPermitted) &&
  hasAPICRUDPermissions(user, ['READ']) &&
  hasProxyCRUDPermissions(user, ['READ']) &&
  hasAPIDeploymentCRUDPermissions(user, ['READ', action]));

export const hasError = (value, isRequired, max = MAX_LENGTH) =>
  (isRequired && isEmpty(trim(value))) || !isLength(value, { max });

export const hasRequiredError = (value, isRequired) =>
  (isRequired && isEmpty(trim(value)));

export const isNumericVersion = (value) => {
  const numeric = /^\d+([.|_|-]*\d+)*$/;
  return !numeric.test(value);
};

export const getFileExtension = (filename) => getLastExtension(filename, '.');

export const listToObject = (entities = [], type) => entities.reduce(
  (acc, props) => ({ ...acc, [props.uuid]: { ...props, type } }),
  {},
);

export const isEditApplicationDisabled = (userContext, { status, disabledByType }) => {
  if (hasPublisherRole(userContext)) {
    // Internal applications
    return [APPLICATION_STATUS_EDIT_PENDING_APPROVAL,APPLICATION_STATUS_DELETE_PENDING_APPROVAL,
      APPLICATION_STATUS_PENDING_APPROVAL].includes(status);
  } else if (hasOrgBoundRole(userContext)) {
    // External applications
    return !isUndefined(status) && !isUndefined(disabledByType) &&
    (
      !(disabledByType === null || disabledByType === APPLICATION_DISABLED_BY_TYPE_EXTERNAL) ||
      [APPLICATION_STATUS_PENDING_APPROVAL,
        APPLICATION_STATUS_EDIT_PENDING_APPROVAL,
        APPLICATION_STATUS_DELETE_PENDING_APPROVAL].includes(status)
    );
  }
  return false;
};

export const isEditApplicationKeysDisabled = (userContext, { status }) => {
  if (hasPublisherRole(userContext)) {
    // Internal applications
    return !([APPLICATION_STATUS_ENABLED,
      APPLICATION_STATUS_EDIT_PENDING_APPROVAL,
      APPLICATION_STATUS_DELETE_PENDING_APPROVAL].includes(status));
  } else if (hasOrgBoundRole(userContext)) {
    // External applications
    return true;
  }
  return false;
};

export const getBundlesExtension = (filename, ALLOWED_FILE_EXTENSIONS) => {
  const last2Index = filename.lastIndexOf('.', filename.lastIndexOf('.') - 1) + 1;
  const extension = filename.substr(last2Index).toLocaleLowerCase();
  if (includes(ALLOWED_FILE_EXTENSIONS, extension)) {
    return extension;
  }
  return getLastExtension(filename, '.');
};

const entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;',
};

const escapeHtml = (str) => str.replace(/[&<>"'`=/]/g, (s) => entityMap[s]);

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const formatStatusMessage = (message) => {
  let modalBody = '';
  let jsonObj = null;

  if (message) {
    if (isJsonString(message)) {
      jsonObj = JSON.parse(message);
    }
    if (jsonObj && jsonObj.length) {
      for (let i = 0; i < jsonObj.length; i++) {
        modalBody += jsonObj[i].message;
      }
    } else if (jsonObj) {
      modalBody = JSON.stringify(jsonObj, null, 2);
    } else {
      modalBody = escapeHtml(message);
    }
  }
  return modalBody;
};

export const getVersion = (version, char = '.') => {
  const len = version ? version.indexOf(char, version.indexOf(char) + 1) : 0;
  return len > 0 ? version.substr(0, len) : version;
};

export const getRateLimitQuotaArguments = (data, rateLimitQuota, hasApiOrgEnabled) => {
  const args = [
    {
      label: getI18nFormattedMessage('label.api.rate.limits.quotas.title'),
      name: "rateLimitQuota",
      type: "autocomplete",
      inputLabel: getI18nFormattedMessage('label.none'),
      isSecondaryText: true,
      data: data,
      required: true,
      value: rateLimitQuota,
    },
    {
      name: "rateLimit",
      type: "string",
      label: getI18nFormattedMessage('label.rate.quota.rate.limit'),
      disabled: true,
      value: get(rateLimitQuota, 'rateLimit.maxRequestsPerSecond'),
    },
    {
      name: "windowSizeInSeconds",
      type: "string",
      label: getI18nFormattedMessage('label.rate.quota.spread.limit.window'),
      disabled: true,
      value: get(rateLimitQuota, 'rateLimit.windowSizeInSeconds'),
    },
    {
      name: "maxConcurrency",
      type: "string",
      label: getI18nFormattedMessage('label.rate.quota.maximum.concurrency'),
      disabled: true,
      value: get(rateLimitQuota, 'rateLimit.maxConcurrency'),
    },
    {
      name: "quota",
      type: "string",
      label: getI18nFormattedMessage('label.rate.quota.limit.quota'),
      disabled: true,
      value: get(rateLimitQuota, 'quota.quota'),
    },
    {
      name: "quotaInterval",
      type: "string",
      label: getI18nFormattedMessage('label.rate.quota.limit.quota.interval'),
      disabled: true,
      value: capitalize(get(rateLimitQuota, 'quota.interval')),
    },
    {
      name: "l7.rateQuotaUuid",
      type: "hidden",
      label: "l7.rateQuotaUuid",
      disabled: true,
      value: get(rateLimitQuota, 'uuid'),
    },
    {
      name: "l7.rateQuotaJson",
      type: "hidden",
      label: "l7.rateQuotaJson",
      disabled: true,
      value: rateLimitQuota ? JSON.stringify(rateLimitQuota) : null,
    },
  ];
  if(hasApiOrgEnabled){
    args.push({ name: "l7.rq.apiOrg.enabled", type:"boolean", label: null });
  }
  return args;
};

export const getRateLimitQuotaEnity = (rateLimitQuota, hasApiOrgEnabled) => {
  const args = [
    {
      name: "rateLimitQuota",
      value: rateLimitQuota,
    },
    {
      name: "rateLimit",
      value: get(rateLimitQuota, 'rateLimit.maxRequestsPerSecond'),
    },
    {
      name: "windowSizeInSeconds",
      value: get(rateLimitQuota, 'rateLimit.windowSizeInSeconds'),
    },
    {
      name: "maxConcurrency",
      value: get(rateLimitQuota, 'rateLimit.maxConcurrency'),
    },
    {
      name: "quota",
      value: get(rateLimitQuota, 'quota.quota'),
    },
    {
      name: "quotaInterval",
      value: capitalize(get(rateLimitQuota, 'quota.interval')),
    },
    {
      name: "l7.rateQuotaUuid",
      value: get(rateLimitQuota, 'uuid'),
    },
    {
      name: "l7.rateQuotaJson",
      value: rateLimitQuota ? JSON.stringify(rateLimitQuota) : null,
    },
  ];
  if(hasApiOrgEnabled){
    args.push({ name: "l7.rq.apiOrg.enabled", value: true });
  }
  return args;
};

export const selectedRateLimitQuota = (rateLimitQuotas, selectedUuid) => {
  return find(rateLimitQuotas, ({ uuid }) => (uuid === selectedUuid));
};

export const processErrorsMessage = (errors, selectedRowsObject) => {
  const orgErrors = [];
  const selectedObjects = JSON.parse(localStorage.getItem(selectedRowsObject)) || [];
  errors.map((error) => {
    const field = get(error, 'field') ? split(error.field, ".") : '';
    let name = field;
    if(field.length === 2) {
      const selectedObj = selectedObjects.filter(obj => obj.uuid === field[1]);
      name = selectedObj.length > 0 ? `Organization ${get(selectedObj[0], 'name')}` : field;
    }
    const msg = error.error || error.message
    orgErrors.push({ 'field': name, 'message': msg });
  });
  return orgErrors;
};

export const getRateLimitQuotaPolicyTemplates = (policyTemplateArguments) => {
  return find(policyTemplateArguments, ({ name }) =>
    (name === RATE_LIMIT_QUOTA_DEFAULT_FIELD));
};

export const hasEnabledFieldName = (fields, fieldName) => {
  return find(fields, ({ name }) =>
    (name === fieldName));
};

export const getApiPolicyTemplateArguments = (policyTemplateArguments) =>
  policyTemplateArguments.reduce((acc, { name, value }) => {
  acc[name] = value;
  return acc;
}, {});

export const isNumberOnly = (value) => {
  return value.match(/[^0-9]/g, '') ? true: false;
};

export const getTitleCaseText = (value) => {
  const str = value ? value.replace('_', ' ') : value;
  return str ? str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()) : str;
};

export const getTabIndex = (tabs, match) => {
  const tabName = get(match, 'params.tabName');
  const index = findIndex(tabs, tab => (tab.id === tabName));
  // if none found default to 0
  return (index > 0 ? index : 0);
};

export const checkEmptyHtmlTags = (content) => {
  return content.replace(/<(.|\n)*?>/g, '').trim().length === 0;
};

export const processErrors = (arrErrors, defaultMessage) => {
  let errorMessage = defaultMessage;
  if (arrErrors.length > 0) {
    errorMessage = map(arrErrors, (validationError) =>
      unescape(validationError.error || validationError.message)).join('\n');
  }
  return errorMessage;
};

export const getOrgStatusColor = (status) => {
  let statusColor = '#d84332';
  if (status === 'ENABLED' ) {
    statusColor = '#59bb52';
  } else if (status === 'DISABLED') {
    statusColor = '#b3bdc2';
  } else if (status === 'REGISTRATION_INIT') {
    statusColor = '#FF944D';
  }
  return statusColor;
};

export const getCerStatus = (value) => {
  let statusColor = '#306204';
  let status = value;
  if (value < 1 ) {
    statusColor = '#B30303';
    status = value < 0 ? "Expired" : value;
  } else if (value > 0 && value < 61) {
    statusColor = '#FF944D';
  } else if (value > 60 ) {
    statusColor = '#306204';
  }
  return { status, statusColor };
};

export const formatNumber = (value) => {
  const digits = new Number(value).toString().length;
  if(digits>6) {
      value = (value / 1000000).toFixed(1);
      return value + ' M';

  } else {
    return parseInt(value).toLocaleString();
  }
};

export const checkSpecialCharacters = (value) => {
    if (URI_ALLOWED_STRING.test(value)) {
        return false;
    }
    return true;
};

export const checkUnicity = (navtitles, value) => {
    return navtitles.map(navtitle => navtitle.toLowerCase()).includes(value.toLowerCase());
};

const replaceNotAllowedCharacters = (text, replacement = '_') => {
    return text.replace(URI_NOT_ALLOWED_CHARACTERS, replacement);
};

export const slugifyURI = (uri) => {
    const cleanedURI = replaceNotAllowedCharacters(uri, '_');
    return slugify(cleanedURI);
};


/* converHexToRGB */
export const convertHexToRGB = (hex = '', a = 1) => {
  const hexValue = hex.replace('#', '');
  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${a})`;
};

/* eslint-disable */
/*
found from: https://stackoverflow.com/a/2117523
 */
export const uuidv4 = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
};
/* eslint-enable */
