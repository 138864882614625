import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { number, bool, func, shape, arrayOf, string } from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { replace } from 'lodash';
import queryString from 'query-string';

import { fetchProxyApisList } from '../../../../actions/proxy';
import {
  getApisListTotalPages,
  getApisListTotalElements,
  getApisListResults,
} from '../../../../reducers/proxy';
import styles from './styles';
import ListContainer from '../../../list';
import {
  GRID_ROWS_PER_PAGE_OPTIONS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
 } from '../../../../constants';
import {
  FilterByPublishSources,
  FilterByDeploymentStatus,
  FilterByStates,
  SortBy,
  FilterAndSortSeparator,
} from './controls';
import getDateTime, { getTimeZoneAbbr } from '../../../../utils/datetime';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';

const LIST_COLUMNS = [{
  id: 'name',
  label: getI18nFormattedMessage('label.api.name'),
  minWidth: 100,
  link: '/publish/apis/details',
}, {
  id: 'portalStatus',
  label: getI18nFormattedMessage('label.api.state'),
  format: value => replace(value, 'NEW', 'UnPublished'),
  minWidth: 50,
  isStatusIcon: true,
},{
  id: 'publishedByPortal',
  label: getI18nFormattedMessage('label.api.publish.source'),
  format: value => value ? 'Portal' : 'Gateway',
  minWidth: 50,
}, {
  id: 'lastTimeDeployed',
  label: getI18nFormattedMessage('label.api.date.last.deployed', { zone: getTimeZoneAbbr() }),
  format: value => getDateTime(value),
  minWidth: 50,
}, {
  id: 'status',
  label: getI18nFormattedMessage('label.api.deployed.status'),
  minWidth: 50,
  isStatusIcon: true,
},
];

export const ApiListRaw = (props) => {
  const {
    proxyUuid,
    classes,
    isLoading,
    totalPages,
    totalElements = 0,
    results = [],
    notifyMessages,
    isCurrentTab,
  } = props;

  const parsed = queryString.parse(props.location.search);
  const deploymentStatus = parsed.status || 'all';
  const source = parsed.source || 'all';
  const filterSearch = parsed.status || parsed.source ? true : false;

  const intl = getI18n(useIntl());
  const [state, setState] = useState('all');
  const [publishSource, setPublishSource] = useState(source);
  const [status, setStatus] = useState(deploymentStatus);
  const [sortBy, setSortBy] = useState('lastTimeDeployed,DESC');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [filterByAnyApplied, setFilterByAnyApplied] = useState(filterSearch);

  const emptyFunction = () => {};

  useEffect(() => {
    if (proxyUuid) {
     props.fetchProxyApisList({
       proxyUuid, publishSource, status, state, sortBy, page, rowsPerPage,
     });
    }
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    const deploymentStatus = parsed.status || 'all';
    const source = parsed.source || 'all';
    const filterSearch = parsed.status || parsed.source ? true : false;
    setPublishSource(source);
    setStatus(deploymentStatus);
    setFilterByAnyApplied(filterSearch);
    if (proxyUuid && isCurrentTab) {
     props.fetchProxyApisList({
       proxyUuid,
       publishSource: source,
       status: deploymentStatus,
       state, sortBy, page, rowsPerPage,
     });
    }
  }, [props.location, isCurrentTab]);


  const onFilterByPublishSourcesChange = (newSource) => {
    setPublishSource(newSource);
    setFilterByAnyApplied(true);
    setPage(0);
    props.fetchProxyApisList({ proxyUuid, publishSource: newSource, status,
      state, sortBy, page: 0, rowsPerPage });
  };

  const onFilterByDeploymentStatus = (newStatus) => {
    setStatus(newStatus);
    setFilterByAnyApplied(true);
    setPage(0);
    props.fetchProxyApisList({ proxyUuid, publishSource, status: newStatus,
      state, sortBy, page: 0, rowsPerPage });
  };

  const onFilterByStateChange = (newStateBy) => {
    setState(newStateBy);
    setFilterByAnyApplied(true);
    setPage(0);
    props.fetchProxyApisList({ proxyUuid, publishSource, status,
      state: newStateBy, sortBy, page: 0, rowsPerPage });
  };

  const onSortByChange = (newSortBy) => {
    setSortBy(newSortBy);
    setPage(0);
    props.fetchProxyApisList({ proxyUuid, publishSource, status,
      state, sortBy: newSortBy, page: 0, rowsPerPage });
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchProxyApisList({ proxyUuid, publishSource, status,
      state, sortBy, page: newPage, rowsPerPage });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchProxyApisList({ proxyUuid, publishSource, status,
      state, sortBy, page: 0, rowsPerPage: newRowsPerPage });
  };

  const noResultsMessage = filterByAnyApplied ?
  `${intl.getI18nMessage('label.proxy.details.apis.search.results')}` :
    intl.getI18nMessage('label.proxy.details.apis.no.results');

  return (
    <ListContainer
      listPageId="proxy-details-apis-list"
      isLoading={isLoading}
      notificationId="proxy-details-notifications"
      notificationStatus={''}
      setNotificationStatus={emptyFunction}
      notificationMessage={''}
      setNotificationMessage={emptyFunction}
      pageHeaderTitle={''}
      pageHeaderTooltipTitle={''}
      addButtonLabel={''}
      onAddBundle={emptyFunction}
      showListHeader={false}
      pageClass={classes.listContent}
      pageBodyClass={classes.pageBodyClass}
      pageFilterAndSortClass={classes.pageFilterAndSortClass}
      filterAndSortContent={(
        <Fragment>
          <FilterByStates
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            selectFieldClass={classes.selectField}
            value={state}
            handleChange={onFilterByStateChange}
          />
          <FilterByPublishSources
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            selectFieldClass={classes.selectField}
            value={publishSource}
            handleChange={onFilterByPublishSourcesChange}
            hideLabel
          />
          <FilterByDeploymentStatus
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            selectFieldClass={classes.selectField}
            value={status}
            handleChange={onFilterByDeploymentStatus}
            hideLabel
          />
          <FilterAndSortSeparator />
          <SortBy
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.sort.by')}
            value={sortBy}
            handleChange={onSortByChange}
          />
        </Fragment>
     )}
      columns={LIST_COLUMNS}
      rows={results}
      noResultsMessage={noResultsMessage}
      page={page}
      totalElements={totalElements}
      totalPages={totalPages}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onChangePreviousPage={onChangePreviousPage}
      onChangeNextPage={onChangeNextPage}
      notifyMessages={notifyMessages}
    />
  );
};

ApiListRaw.propTypes = {
  classes: shape({}),
  location: shape({}),
  isLoading: bool,
  totalPages: number,
  totalElements: number,
  results: arrayOf(shape({})),
  fetchProxyApis: func,
  fetchProxyApisList: func,
  proxyUuid: string,
  notifyMessages: func,
  isCurrentTab: bool,
};

const mapStateToProps = (state) => ({
  isLoading: false,
  totalPages: getApisListTotalPages(state),
  totalElements: getApisListTotalElements(state),
  results: getApisListResults(state),
});

const mapDispatchToProps = {
  fetchProxyApisList,
};
ApiListRaw.displayName = 'Apis';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ApiListRaw);
