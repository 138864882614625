import React from 'react';
import { withStyles, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { object } from 'prop-types';

import styles from './styles';

const ToolTip = ({ classes, label, info }) => (
    <div className={classes.helpIconContainer}>
      <div>
        {label}
      </div>
      <Tooltip title={info} arrow placement="right">
        <HelpIcon className={classes.helpIcon} />
      </Tooltip>
    </div>
  );
  
ToolTip.propTypes = {
    classes: object,
    label: object,
    info: object,
};


export default (
    withStyles(styles)
  )(ToolTip);