import React from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles,
 } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './styles';

export default function LoadingDialog(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    id,
    isOpen,
    title,
    description,
    dialogId = 'loading-dialog',
  } = props;

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="loading-dialog-title"
      aria-describedby="loading-dialog-description"
      id={id || dialogId} data-apim-test={id || dialogId} data-layer7-test={id || dialogId}
      classes={{
        paper: classes.dialogContainer,
      }}
    >
      <DialogTitle
        id="loading-dialog-title"
        data-apim-test="loading-dialog-title"
        className={classes.dialogTitle}
      >
        <Typography variant="h3" className={classes.title}>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="loading-dialog-description" data-apim-test="loading-dialog-description" className={classes.content}>
          <Typography variant="body1">{description}</Typography>
          <CircularProgress color="primary" className={classes.progress} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

LoadingDialog.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  dialogId: PropTypes.string,
};
