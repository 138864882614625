import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Chip, Grid, Button, Tooltip, FormLabel } from '@material-ui/core';
import { number, obj, string, func, bool } from 'prop-types';
import { map } from 'lodash';
import { FormSelect, FormTextField } from '../../../../../components';
import { OrgAPIAccessListContext } from '../';
import {
  API_ACCESS_STATUS_FILTER_BY,
} from '../../../../../constants';
import { getI18n } from '../../../../../utils/intl';
export const FilterByName = (props) => (
  <FormTextField
    {...props}
    id="apis-org-filter-by-name"
    data-apim-test="apis-org-filter-by-name"
    type="text"
  />
);
export const FilterByAccessStatus = (props) => (
  <FormSelect
    {...props}
    id="apis-filter-by-status"
    data-apim-test="apis-filter-by-status"
    data={API_ACCESS_STATUS_FILTER_BY}
    noNoneOption
    noNativeSelect
  />
);
export const Tags = ({ apiTags, numSelectedRows }) => {
  const orgAPIAccessListContext = useContext(OrgAPIAccessListContext);
  const clickable = (numSelectedRows === 0);
  return map(
    apiTags,
    tag => (
      <Chip
        variant="outlined" size="small"
        clickable={clickable}
        style={{ marginRight: '2px' }}
        key={tag} label={tag}
        onClick={() => {
          if(clickable) { orgAPIAccessListContext.onClickTag(tag) }
        }}
      />
    ),
  );
}

export const TypeHeaderTooltip = ({ tooltipLabel } ) => {
  return (
    <div>
      <div>
        { tooltipLabel }
      </div>
    </div>
  );
}
TypeHeaderTooltip.propTypes = {
  tooltipLabel: string,
};
export const TooltipIcon = ({ classes, label, tooltipLabel, content }) => (
  <div className={classes.helpIconContainer}>
    <div>
      {label}
    </div>
    <Tooltip title={<TypeHeaderTooltip tooltipLabel={tooltipLabel}/>} arrow placement="right">
      {content}
    </Tooltip>
  </div>
);
TooltipIcon.propTypes = {
  tooltipLabel: string,
  classes: obj,
  label: string,
  content: obj,
};
export const HeaderActionButtons = (props) => {
  const {
    classes = {},
    bulkActionsItemsCount,
    addAccess,
    removeAccess,
    disableAddAccess,
    disableRemoveAccess,
    disableAddRateQuota,
    disableDefaultAddRateQuota,
    onAddRateLimit,
    onRemoveRateLimit,
  } = props;

  const id = 'api-org-access'
  const intl = getI18n(useIntl());

  const onClickAdd = () => {
    addAccess();
  };
  const onClickRemove = () => {
    removeAccess();
  };

  return (
    <>
      <FormLabel className={classes.actionsLabel}>
        {intl.getI18nMessage('label.organization.apiaccess.actions.label', { count: bulkActionsItemsCount })}
      </FormLabel>
      <Grid
        item md={12} sm={12} xs={12}
        className={classes.headerButtons}
      >
        <span className={classes.buttonContainer}>
          <Button
            variant="contained" color="secondary"
            className={classes.addButton}
            id={`${id}-add`} data-apim-test={`${id}-add`} data-layer7-test={`${id}-add`}
            onClick={onClickAdd}
            disabled={disableAddAccess}
          >
            {intl.getI18nMessage('label.organization.apiaccess.add.access.button')}
          </Button>
          <Button
            variant="contained" color="secondary"
            className={classes.removeButton}
            id={`${id}-remove`} data-apim-test={`${id}-remove`} data-layer7-test={`${id}-remove`}
            onClick={onClickRemove}
            disabled={disableRemoveAccess}
          >
            {intl.getI18nMessage('label.organization.apiaccess.remove.access.button')}
          </Button>
          <Button
            variant="contained" color="secondary"
            className={classes.addButton}
            id={`${id}-add-ratelimit`}
            data-apim-test={`${id}-add-ratelimit`}
            data-layer7-test={`${id}-add-ratelimit`}
            onClick={onAddRateLimit}
            disabled={disableAddRateQuota}
          >
              {intl.getI18nMessage('label.rate.quota.add.button')}
          </Button>
          <Button
            variant="contained" color="secondary"
            className={classes.addButton}
            id={`${id}-remove-ratelimit`}
            data-apim-test={`${id}-remove`}
            data-layer7-test={`${id}-remove`}
            onClick={onRemoveRateLimit}
            disabled={disableDefaultAddRateQuota}
          >
              {intl.getI18nMessage('label.rate.quota.reset.button')}
          </Button>
        </span>
      </Grid>
    </>
  );
}
HeaderActionButtons.propTypes = {
  classes: obj,
  bulkActionsItemsCount: number,
  addAccess: func,
  removeAccess: func,
  disableAddAccess: bool,
  disableRemoveAccess: bool,
  disableAddRateQuota: bool,
  disableDefaultAddRateQuota: bool,
  onAddRateLimit: func,
  onRemoveRateLimit: func,
};