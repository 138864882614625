import axios from 'axios';
import { get, forEach, isEmpty, map } from 'lodash';
import { stringify } from 'query-string';

import * as Constants from '../../constants';
import { getConfig } from '../../reducers/portalConfig';
import { getValidationErrors } from '../../utils';

const initBundle = (dispatch, isLoading = true) =>
  dispatch({
    type: Constants.APP_LOADING,
    isLoading,
  });

export const resetApp = () => dispatch => dispatch({ type: Constants.APP_RESET });
export const resetDeleteNotification = () => dispatch =>
  dispatch({ type: Constants.GATEWAY_BUNDLES_DELETE_RESET });
export const showLoading = isLoading => dispatch => initBundle(dispatch, isLoading);

export const fetchGatewayBundles = (name = null, groupName = null, sort = 'createTs,DESC',
  type = Constants.ANY, l7template = Constants.ANY, page = 0, size = 12) =>
  async (dispatch, getState) => {
    initBundle(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      name: !isEmpty(name) ? name : undefined,
      groupName: !isEmpty(groupName) ? groupName : undefined,
      page,
      size,
      sort,
      type: (type !== Constants.ANY) ? type : undefined,
      l7template: (l7template !== Constants.ANY) ? l7template : undefined,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/policy-management/0.1/gateway-bundles?${params}`;
    await axios.get(url, { credentials: 'include' })
      .then(response =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_GET_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchGatewayBundleDetails = (bundleUuid) =>
  async (dispatch, getState) => {
    initBundle(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const urlDetails = `${portal.hostname}/api/${portal.tenantPrefix}/policy-management/0.1/gateway-bundles/${bundleUuid}`;
    const urlActions = `${portal.hostname}/admin/policy-management/internal/gateway-bundles/${bundleUuid}/actions`;
    await Promise.all([
      axios.get(urlDetails, { credentials: 'include' }),
      axios.get(urlActions, { credentials: 'include' }),
    ])
      .then(responseArr => {
        dispatch({
          type: Constants.GATEWAY_BUNDLES_DETAILS_SUCCESS,
          payload: {
            ...responseArr[0].data,
            ...responseArr[1].data,
          },
        });
      })
      .catch(error =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_DETAILS_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchGatewayBundleUsage = (bundleUuid) =>
  async (dispatch, getState) => {
    initBundle(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/admin/policy-management/internal/gateway-bundles/${bundleUuid}/usage`;

    await axios.get(url, { credentials: 'include' })
      .then(response => {
        dispatch({
          type: Constants.GATEWAY_BUNDLES_GET_USAGE_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_GET_USAGE_ERROR,
          payload: null,
        }),
      );
  };

export const fetchDeploymentProxyList = (bundleUuid, name = null,
  status = Constants.ALL, page = 0, size = 12) =>
  async (dispatch, getState) => {
    initBundle(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      proxyName: !isEmpty(name) ? name : undefined,
      page,
      size,
      status: (status !== Constants.ALL) ? status : undefined,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/internal/gateway-bundles/${bundleUuid}/proxies?${params}`;
    await axios.get(url, { credentials: 'include' })
      .then(response =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_GET_PROXY_LIST_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_GET_PROXY_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchDeploymentStatus = (bundleUuid, name = null,
  status = Constants.ALL, page = 0, size = 12) =>
  async (dispatch, getState) => {
    initBundle(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const params = stringify({
      proxyName: !isEmpty(name) ? name : undefined,
      page,
      size,
      status: (status !== Constants.ALL) ? status : undefined,
    }, true);

    const urlDeployments = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/internal/gateway-bundles/${bundleUuid}/proxies?${params}`;
    const urlActions = `${portal.hostname}/admin/policy-management/internal/gateway-bundles/${bundleUuid}/actions`;
    await Promise.all([
      axios.get(urlDeployments, { credentials: 'include' }),
      axios.get(urlActions, { credentials: 'include' }),
    ])
      .then(responseArr => {
        dispatch({
          type: Constants.GATEWAY_BUNDLES_GET_PROXY_SUCCESS,
          payload: {
            ...responseArr[0].data,
            ...responseArr[1].data,
          },
        });
      })
      .catch(error =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_GET_PROXY_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchGatewayBundlesApis = (bundleUuid, name = null, proxyName = null,
  apiStatus = Constants.ALL, sort = 'apiModifyTs,DESC', page = 0, size = 12) =>
  async (dispatch, getState) => {
    initBundle(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      apiName: !isEmpty(name) ? name : undefined,
      proxyName: !isEmpty(proxyName) ? proxyName : undefined,
      page,
      size,
      sort,
      apiStatus: (apiStatus !== Constants.ALL) ? apiStatus : undefined,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/policy-management/0.1/gateway-bundles/${bundleUuid}/apis?${params}`;
    await axios.get(url, { credentials: 'include' })
      .then(response =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_GET_APIS_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_GET_APIS_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };


export const saveBundleAssets = (uploadedAssets) =>
  async (dispatch, getState) => {
    initBundle(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const formData = new FormData();
    forEach(uploadedAssets, ({ blob, name }) => {
      if (blob) { formData.append('files', blob, name); }
    });

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/policy-management/0.1/gateway-bundles`;
    await axios.post(url, formData, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: Constants.GATEWAY_BUNDLES_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_SAVE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const deleteGatewayBundle = (bundleUuid) =>
  async (dispatch, getState) => {
    initBundle(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/policy-management/0.1/gateway-bundles/${bundleUuid}`;
    await axios.delete(url, { credentials: 'include' })
      .then(response =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_DELETE_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_DELETE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

const getDeploymentValidationErrors = (error) => get(error, 'validationErrors');
const processDeploymentErrors = (validationErrors, defaultMessage) => {
  let errorMessage;
  const keys = validationErrors ? Object.keys(validationErrors) : [];
  if (keys.length > 0) {
    errorMessage = map(keys, (key) => validationErrors[key].devMessage).join('\n');
  }
  return errorMessage || defaultMessage;
};

export const deployGatewayBundle = (bundleUuid, proxyUuid) =>
  async (dispatch, getState) => {
    initBundle(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = { proxyUuid };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/0.1/gateway-bundles/${bundleUuid}/proxies`;
    await axios.post(url, data, { credentials: 'include' })
      .then(response =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_DEPLOY_PROXY_SUCCESS,
          payload: response.data,
        }),
      )
      .catch((error) => {
        const statusCode = get(error, 'response.status');
        if (statusCode === Constants.HTTP_STATUS_UNAUTHORIZED) {
          window.location.href =
            `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
        }

        const responseError = get(error, 'response.data');
        const strErrorMessage = (statusCode === Constants.HTTP_STATUS_BAD_REQUEST)
          ? processDeploymentErrors(getDeploymentValidationErrors(responseError), '')
          : get(error, 'message');
        dispatch({
          type: Constants.GATEWAY_BUNDLES_DEPLOY_PROXY_ERROR,
          payload: {
            proxyUuid,
            errorMessage: strErrorMessage,
          },
        });
      },
    );
  };

export const undeployGatewayBundle = (bundleUuid, proxyUuid) =>
  async (dispatch, getState) => {
    initBundle(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/0.1/gateway-bundles/${bundleUuid}/proxies/${proxyUuid}`;
    await axios.delete(url, { credentials: 'include' })
      .then(() =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_UNDEPLOY_PROXY_SUCCESS,
          payload: {
            proxyUuid,
            errorMessage: '',
          },
        }),
      )
      .catch((error) => {
        const statusCode = get(error, 'response.status');
        if (statusCode === Constants.HTTP_STATUS_UNAUTHORIZED) {
          window.location.href =
            `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
        }

        const responseError = get(error, 'response.data');
        const strErrorMessage = (statusCode === Constants.HTTP_STATUS_BAD_REQUEST)
          ? processDeploymentErrors(getDeploymentValidationErrors(responseError), '')
          : get(error, 'message');
        dispatch({
          type: Constants.GATEWAY_BUNDLES_UNDEPLOY_PROXY_ERROR,
          payload: {
            proxyUuid,
            errorMessage: strErrorMessage,
          },
        });
      },
    );
  };

export const redeployGatewayBundle = (bundleUuid, proxyUuid) =>
  async (dispatch, getState) => {
    initBundle(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = { status: Constants.PENDING_DEPLOYMENT };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/deployments/0.1/gateway-bundles/${bundleUuid}/proxies/${proxyUuid}`;
    await axios.put(url, data, { credentials: 'include' })
      .then(response =>
        dispatch({
          type: Constants.GATEWAY_BUNDLES_REDEPLOY_PROXY_SUCCESS,
          payload: response.data,
        }),
      )
      .catch((error) => {
        const statusCode = get(error, 'response.status');
        if (statusCode === Constants.HTTP_STATUS_UNAUTHORIZED) {
          window.location.href =
            `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
        }

        const responseError = get(error, 'response.data');
        const strErrorMessage = (statusCode === Constants.HTTP_STATUS_BAD_REQUEST)
          ? processDeploymentErrors(getDeploymentValidationErrors(responseError), '')
          : get(error, 'message');
        dispatch({
          type: Constants.GATEWAY_BUNDLES_REDEPLOY_PROXY_ERROR,
          payload: {
            proxyUuid,
            errorMessage: strErrorMessage,
          },
        });
      },
    );
  };
