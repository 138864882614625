export const DETAILS_TAB_TITLE = 'Details';
export const ORG_ASSIGNMENT_TAB_TITLE = 'Organization Assignment';
export const ORG_ASSIGNMENT_PAGE_SUB_TITLE = 'Optional: Add organizations to see the proxy.';

export const UNIQUE_PROXY_NAME_WARNING_MSG = 'Name already exists. Enter a unique proxy name.';
export const MAXLENGTH_PROXY_NAME_WARNING_MSG = 'Maximum name length is 255 characters. Name must be unique.';
export const UNIQUE_PROXY_NAME_ERROR_MSG = 'Name already exists. Enter a unique proxy name.';
export const EMPTY_PROXY_NAME_ERROR_MSG = 'Enter a valid proxy name.';
export const MAXLENGTH_PROXY_NAME_ERROR_MSG = 'Enter a proxy name with less than 255 characters.';

export const PROXY_CREATE_SUCCESS_MSG = 'Proxy was successfully created.';
export const PROXY_CREATE_FAIL_MSG = 'There was an error while creating a proxy.';
export const PROXY_UPDATE_SUCCESS_MSG = 'Proxy was successfully updated.';
export const PROXY_UPDATE_FAIL_MSG = 'There was an error while updating a proxy.';
export const PROXY_ORGS_UPDATE_FAIL_MSG = 'There was an error while updating orgs to a proxy.';

export const ADD_PROXY_LABEL = 'Add Proxy';
export const EDIT_PROXY_LABEL = 'Edit Proxy';

export const PROXY_NAME_TITLE = 'Proxy Name';
export const API_DEPLOYMENT_TYPE_TITLE = 'API Deployment Type';
export const API_DEPLOYMENT_TYPE_TOOLTIP_TEXT = 'API Deployment Type determines how APIs will be deployed to this proxy.';
export const KEY_DEPLOYMENT_TYPE_TITLE = 'Key Deployment Type';
export const KEY_DEPLOYMENT_TYPE_TOOLTIP_TEXT = 'Key Deployment Type determines how API Keys will be deployed to this proxy.';

export const ORGANIZATIONS_TITLE = 'Organizations';
export const AVAILABLE_ORGANIZATIONS_SUBTITLE = 'All available organizations.';
export const SEARCH_AVAILABLE_ORGANIZATIONS_SUBTITLE = 'Search available organizations';
export const SELECTED_ORGANIZATIONS_TITLE = 'Selected';
export const ALL_SELECTED_ORGANIZATIONS_SUBTITLE = 'All the selected organizations.';
export const SEARCH_SELECTED_ORGANIZATIONS_SUBTITLE = 'Search selected organizations';

export const HELP_ITEMS = [
  {
    screen: 0,
    details: {
      title: 'Proxy Details Help',
      descriptions: '',
      subItems: [
        {
          id: 1,
          title: '',
          descriptions: 'Select the API deployment type to control how API Portal deploys newly Portal-published APIs to this proxy. Select the key deployment type to control how API Portal deploys newly created applications to this proxy. API Portal automatically deploys the changes that you make to existing APIs and applications to the proxies regardless of the deployment type.',
        },
        {
          id: 2,
          title: 'Automatic',
          descriptions: 'API Portal automatically deploys newly added APIs and applications.',
        },
        {
          id: 3,
          title: 'On Demand',
          descriptions: 'The Portal Admin and users with deployment permissions manage API and application deployments as needed.',
        },
        {
          id: 4,
          title: 'Scripted (API only)',
          descriptions: 'API deployment can be integrated into your existing CICD workflow by leveraging the deployment APIs and invoking them from your deployment script.',
        },
      ],
    },
  },
  {
    screen: 1,
    details: {
      title: 'Org Assignment Help',
      descriptions: '',
      subItems: [
        {
          id: 1,
          title: '',
          descriptions: 'Select the organization to assign it to the proxy. When no organization is assigned, only global publishers can see the proxy.',
        },
        {
          id: 2,
          title: '',
          descriptions: 'Users with the right permissions within the organization will be able to see this proxy in the Deployments tab of their APIs.',
        },
        {
          id: 3,
          title: '',
          descriptions: 'The users will be able to redeploy, undeploy and deploy their APIs to the proxy.',
        },
      ],
    },
  },
];

export const UNSAVED_DIALOG_TITLE = 'Unsaved Changes';
export const UNSAVED_DIALOG_DESCRIPTION = 'Your changes will be lost if you leave this page. Are you sure you want to continue away from this page?';
export const UNSAVED_DIALOG_CANCEL_TEXT = 'Stay';
export const UNSAVED_DIALOG_SUBMIT_TEXT = 'Continue';
export const UNSAVED_DIALOG_EXIT_TEXT = 'Exit';
export const SAVE_TEXT = 'Save';
export const SAVE_AND_NEXT_TEXT = 'Save & Next';
