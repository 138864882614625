import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import {
  APIS_LABEL,
  API,
  APPLICATIONS_LABEL,
  APPLICATION,
  RATE_AND_QUOTA_PLANS_LABEL,
  RATE_AND_QUOTA_PLAN,
  API_GROUPS_LABEL,
  API_GROUP,
  API_PLANS_LABEL,
  API_PLAN,
  ORGANIZATIONS_LABEL,
  ORGANIZATION,
  PROXIES_LABEL,
  PROXY,
  USERS_LABEL,
  USER,
} from '../labels';

export default function EntityCard({ entity, isPortalAdmin }) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const entityTitles = {
    APIs: APIS_LABEL,
    APIPlans: API_PLANS_LABEL,
    APIGroups: API_GROUPS_LABEL,
    Organizations: ORGANIZATIONS_LABEL,
    Users: USERS_LABEL,
    Applications: APPLICATIONS_LABEL,
    Proxies: PROXIES_LABEL,
    RateAndQuotaPlans: RATE_AND_QUOTA_PLANS_LABEL,
  };

  const entityDetailsPageUrlMap = {
    APIs: `https://${window.location.hostname}/publish/apis`,
    APIPlans: `https://${window.location.hostname}/admin/apis/api-plans`,
    Applications: isPortalAdmin ? `https://${window.location.hostname}/admin/applications` : `https://${window.location.hostname}/admin/app/applications`,
    Organizations: `https://${window.location.hostname}/admin/console/organizations`,
    Users: `https://${window.location.hostname}/admin/console/users`,
    APIGroups: `https://${window.location.hostname}/admin/api-groups`,
    Proxies: `https://${window.location.hostname}/admin/api-proxy`,
    RateAndQuotaPlans: `https://${window.location.hostname}/admin/rate-quotas`,
  };

  const entityAddPageUrlMap = {
    APIs: `https://${window.location.hostname}/publish/apis/add`,
    APIPlans: `https://${window.location.hostname}/admin/apis/api-plans#add`,
    Applications: `https://${window.location.hostname}/publish/applications/add`,
    Organizations: `https://${window.location.hostname}/admin/console/organizations/add`,
    Users: `https://${window.location.hostname}/admin/users#add`,
    APIGroups: `https://${window.location.hostname}/admin/api-groups#add`,
    Proxies: `https://${window.location.hostname}/publish/api-proxy/add`,
    RateAndQuotaPlans: `https://${window.location.hostname}/admin/rate-quotas/add`,
  };

  const entityAddHelp = {
    APIs: API,
    Applications: APPLICATION,
    Organizations: ORGANIZATION,
    Users: USER,
    APIGroups: API_GROUP,
    APIPlans: API_PLAN,
    Proxies: PROXY,
    RateAndQuotaPlans: RATE_AND_QUOTA_PLAN,
  };

  const handleCardClick = (event, entityName) => {
    event.stopPropagation();
    const href = entityDetailsPageUrlMap[entityName];
    window.location.href = href;
  };

  const handleAddButtonClick = (event, entityName) => {
    event.stopPropagation();
    const href = entityAddPageUrlMap[entityName];
    window.location.href = href;
  };
  const handleEnterKey = (event, entityName, isCard) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      if (isCard) {
        handleCardClick(event, entityName);
      } else {
        handleAddButtonClick(event, entityName);
      }
    }
  };
  return (
    <div
      tabIndex="0"
      className={classes.card}
      onClick={e => handleCardClick(e, entity.entity)}
      onKeyDown={e => handleEnterKey(e, entity.entity, true)}
    >
      <div className={classes.entityTitle}>{entityTitles[entity.entity]}</div>
      <div className={classes.entityCount}>{entity.count}</div>
      <div className={classes.addButtonContainer}>
        {entity.hasAddPermission ? (
          <div className={classes.tooltip}>
            <div
              onClick={e => handleAddButtonClick(e, entity.entity)}
              onKeyDown={e => handleEnterKey(e, entity.entity, false)}
              className={classes.addButton}
              tabIndex="0"
            >
              +
            </div>
            <span className={classes.tooltiptext}>Add {entityAddHelp[entity.entity]}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

EntityCard.propTypes = {
  entity: PropTypes.object,
  isPortalAdmin: PropTypes.bool,
};
