import { connect } from 'react-redux';
import {
  fetchAvailableApis,
  fetchOrganizations,
  fetchAvailableApps,
  fetchProxies,
} from '../../../actions/application';
import {
  fetchDashboardChartData,
} from '../../../actions/dashboard';
import {
  getAvailableApis,
  getOrganizations,
  getAvailableApps,
  getProxies,
} from '../../../reducers/application';
import {
  getDashboardChartData,
} from '../../../reducers/dashboard';
import GridPanel from '../GridPanel';

const mapStateToProps = (state) => ({
  apis: getAvailableApis(state),
  allOrgs: getOrganizations(state),
  apps: getAvailableApps(state),
  allProxies: getProxies(state),
  chartData: getDashboardChartData(state),
});

const mapDispatchToProps = (dispatch: Function) => ({
  fetchAvailableApis: () =>
    dispatch(fetchAvailableApis({ tags: '', size: 24 })),
  fetchAllOrgs: () =>
    dispatch(fetchOrganizations(0, 24)),
  fetchAvailableApps: () =>
    dispatch(fetchAvailableApps({ size: 24, portalStatus: '' })),
  fetchAllProxies: () =>
    dispatch(fetchProxies()),
  fetchDashboardChartData: (query, entity) =>
    dispatch(fetchDashboardChartData(query, entity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridPanel);
