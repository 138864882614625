import React from 'react';
import compose from 'recompose/compose';
import { shape } from 'prop-types';
import {
  Grid,
  withStyles,
} from '@material-ui/core';

import styles from './styles';
import {
  Footer,
} from '../../../../components';


export const Overview = (props) => {
  const {
    classes,
    eulaDetails,
  } = props;

  return (
    <div
      id="eula-details-overview-container"
      data-apim-test="eula-details-overview-container"
      data-layer7-test="eula-details-overview-container"
    >
      <Grid container className={classes.marginPanelContainer}>
        <Grid item md={12} sm={12} xs={12} className={classes.paper}>
          {eulaDetails &&
            <div
              className={classes.detailsContainer}
              dangerouslySetInnerHTML={{ __html: (eulaDetails.content) }}
            />
          }
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

Overview.propTypes = {
  classes: shape({}),
  eulaDetails: shape({}),
};
Overview.displayName = 'Overview';

export default compose(
  withStyles(styles),
)(Overview);
