import React, { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import cx from 'classnames';
import { get, toNumber } from 'lodash';
import {
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  Typography,
  withStyles,
} from '@material-ui/core';
import { IconDanger } from 'mineral-ui-icons';
import { bool, func, object, string } from 'prop-types';

import styles from './styles';
import { getI18n } from '../../../../utils/intl';
import {
  getProxyApiPlans,
  getIsProxyApiPlansLoading,
} from '../../../../reducers/proxy';
import {
  OverviewPaper,
  OverviewPaperWithStatusTableHead,
  OverviewPaperWithStatusTableRow,
} from './Paper';
import { Loading } from '../../../../components';

export const ApiPlans = (props) => {
  const {
    classes,
    proxyUuid,
    proxyStatus,
    proxyApiPlans,
    isProxyApiPlansLoading,
  } = props;

  const intl = getI18n(useIntl());
  const portalPlanCounts = get(proxyApiPlans, 'definedEntityCount', 0);
  const gatewayPlanCounts = get(proxyApiPlans, 'deployedEntityCount', 0);
  const lastUpdated = get(proxyApiPlans, 'lastTimeDeployed', 0);
  const arePlanCountsEqual = (
    toNumber(portalPlanCounts) === toNumber(gatewayPlanCounts)
  );

  useEffect(() => {
    props.fetchProxyApiPlans(proxyUuid);
  }, []);

  return (
    <>
      {isProxyApiPlansLoading && <Loading sectionLoader />}
      {arePlanCountsEqual ?
        <OverviewPaper
          id="api-plans"
          title={intl.getI18nMessage('label.api.plans')}
          proxyStatus={proxyStatus}
          content={portalPlanCounts}
          lastUpdated={lastUpdated}
        /> :
        <OverviewPaper
          id="api-plans"
          title={intl.getI18nMessage('label.api.plans')}
          proxyStatus={proxyStatus}
          contentMore={
            <Fragment>
              <TableContainer>
                <Table size="small">
                  <OverviewPaperWithStatusTableHead
                    rowName={intl.getI18nMessage('label.proxy.details.page.source')}
                    cells={[
                      {
                        key: 'published',
                        value: intl.getI18nMessage('label.proxy.details.page.status.published'),
                      },
                    ]}
                  />
                  <TableBody>
                    <OverviewPaperWithStatusTableRow
                      rowName={intl.getI18nMessage('label.proxy.details.api.portal')}
                      cells={[
                        { key: 'portal.plan.counts.api-plans', value: portalPlanCounts },
                      ]}
                    />
                    <OverviewPaperWithStatusTableRow
                      rowName={intl.getI18nMessage('label.proxy.details.api.gateway')}
                      cells={[
                        { key: 'gateway.plan.counts.api-plans', value: gatewayPlanCounts },
                      ]}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container style={{ marginTop: 25, marginBottom: 25 }}>
                <Grid item>
                  <IconButton className={cx(classes.icon, classes.countMismatch)}>
                    <IconDanger />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {intl.getI18nMessage('label.proxy.details.portal.gateway.counts.different')}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          }
          lastUpdated={lastUpdated}
        />
      }
    </>
  );
};

const mapStateToProps = state => ({
  proxyApiPlans: getProxyApiPlans(state),
  isProxyApiPlansLoading: getIsProxyApiPlansLoading(state),
});

ApiPlans.propTypes = {
  classes: object,
  proxyUuid: string,
  proxyStatus: string,
  proxyApiPlans: object,
  isProxyApiPlansLoading: bool,
  fetchApiPlans: func,
  fetchProxyApiPlans: func,
};
ApiPlans.displayName = 'ApiPlans';

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(ApiPlans);
