import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { withStyles, Tooltip } from '@material-ui/core';
import { arrayOf, bool, func, number, shape, string, object } from 'prop-types';
import { lowerCase, upperFirst, get } from 'lodash';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import HelpIcon from '@material-ui/icons/Help';

import { fetchOrganizations } from "../../../../actions/rateQuota";
import {
  getOrgsListResults,
  getOrgsListTotalElements,
  getOrgsListTotalPages,
} from "../../../../reducers/rateQuota";
import ListContainer from "../../../list";
import {
  ANY,
  ALL,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
  GRID_ROWS_PER_PAGE_OPTIONS,
  QUOTA_ASSIGNMENT_ORG,
} from "../../../../constants";
import {
  getI18n,
  getI18nFormattedMessage,
} from "../../../../utils/intl";
import {
  getOrgStatusColor,
} from "../../../../utils";
import {
  FilterByName,
  FilterByType,
  FilterByStatus,
  FilterSeparator,
} from './controls';
import styles from './styles';

export const TypeHeaderTooltip = () => {
  const intl = getI18n(useIntl());
  return (
    <div>
      <div>
        {`${intl.getI18nMessage('label.organization.list.page.filter.by.publisher.types')}:
          ${intl.getI18nMessage('label.organization.list.page.help.text.publisher')}`}
      </div>
      <div>
        {`${intl.getI18nMessage('label.organization.list.page.filter.by.consumer.types')}:
        ${intl.getI18nMessage('label.organization.list.page.help.text.consumer')}`}
      </div>
    </div>
  );
}

export const TypeHeader = ({ classes, label }) => (
  <div className={classes.helpIconContainer}>
    <div>
      {label}
    </div>
    <Tooltip title={<TypeHeaderTooltip />} arrow placement="right">
      <HelpIcon className={classes.helpIcon} />
    </Tooltip>
  </div>
);

TypeHeader.propTypes = {
  classes: object,
  label: object,
};

export const getOrgListColumns = (classes) => [{
  id: 'name',
  label: getI18nFormattedMessage('label.organization.name'),
  minWidth: 100,
  link: '/admin/console/organizations/details',
}, {
  id: 'type',
  label: (
    <TypeHeader classes={classes} label={getI18nFormattedMessage('label.organization.type')} />
  ),
  minWidth: 50,
  format: value => upperFirst(lowerCase(value)),
}, {
  id: 'status',
  label: getI18nFormattedMessage('label.state'),
  minWidth: 50,
  value: (item) => {
    const { status } = item;
    const statusLabel = upperFirst(lowerCase(status));
    const statusIconStyle = {
      fill: getOrgStatusColor(status),
    };
    return (
      <span>
          <FiberManualRecordIcon size="0.82em" style={statusIconStyle} className={classes.statusIcon} />{statusLabel}
      </span>);
  },
}];

export const Organizations = (props) => {
  const {
    classes,
    rqUuid,
    isLoading,
    results = [],
    totalElements,
    totalPages,
    rateQuotaDetails,
  } = props;

  const intl = getI18n(useIntl());
  const [filterByName, setFilterByName] = useState('');
  const [filterByType, setFilterByType] = useState(ANY);
  const [filterByStatus, setFilterByStatus] = useState(ALL);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [filterByAnyApplied, setFilterByAnyApplied] = useState(false);

  useEffect(() => {
    if (rqUuid && get(rateQuotaDetails, 'assignmentLevel') === QUOTA_ASSIGNMENT_ORG) {
      props.fetchOrganizations({
        name: filterByName,
        accountPlanUuid: rqUuid,
        type: filterByType,
        status: filterByStatus,
        page: 0,
        size: rowsPerPage,
      });
    }
  }, [rqUuid, rateQuotaDetails]);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilterByAnyApplied(true);
      setPage(0);
      props.fetchOrganizations({
        name: filterByName,
        accountPlanUuid: rqUuid,
        type: filterByType,
        status: filterByStatus,
        page: 0,
        size: rowsPerPage,
      });
    }
  };

  const onFilterByTypeChange = (value) => {
    setFilterByAnyApplied(true);
    setFilterByType(value);
    setPage(0);
    props.fetchOrganizations({
      name: filterByName,
      accountPlanUuid: rqUuid,
      type: value,
      status: filterByStatus,
      page: 0,
      size: rowsPerPage,
    });
  };

  const onFilterByStatusChange = (value) => {
    setFilterByAnyApplied(true);
    setFilterByStatus(value);
    setPage(0);
    props.fetchOrganizations({
      name: filterByName,
      accountPlanUuid: rqUuid,
      type: filterByType,
      status: value,
      page: 0,
      size: rowsPerPage,
    });
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchOrganizations({
      name: filterByName,
      accountPlanUuid: rqUuid,
      type: filterByType,
      status: filterByStatus,
      page: newPage,
      size: rowsPerPage,
    });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchOrganizations({
      name: filterByName,
      accountPlanUuid: rqUuid,
      type: filterByType,
      status: filterByStatus,
      page: 0,
      size: newRowsPerPage,
    });
  };

  const noResultsMessage = filterByAnyApplied ?
    `${intl.getI18nMessage('label.organization.filter.no.results')}` :
    intl.getI18nMessage('label.rate.quota.orgs.no.results');

  return (
    <ListContainer
      listPageId="rate-quota-orgs-list"
      isLoading={isLoading}
      showListHeader={false}
      pageClass={classes.listContent}
      pageBodyClass={classes.pageBodyClass}
      pageFilterAndSortClass={classes.pageFilterAndSortClass}
      filterAndSortContent={(
        <Fragment>
          <FilterByName
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            value={filterByName}
            placeholder={intl.getI18nMessage('label.organization.list.page.filter.by.name.placeholder')}
            handleChange={onFilterByNameChange}
            onKeyPress={onFilterByNameKeyPress}
          />
            <FilterByType
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter')}
              value={filterByType}
              handleChange={onFilterByTypeChange}
              hideLabel
            />
            <FilterByStatus
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter')}
              value={filterByStatus}
              handleChange={onFilterByStatusChange}
              hideLabel
            />
            <FilterSeparator />
        </Fragment>
      )}
      columns={getOrgListColumns(classes) || []}
      rows={results}
      noResultsMessage={noResultsMessage}
      page={page}
      totalElements={totalElements}
      totalPages={totalPages}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onChangePreviousPage={onChangePreviousPage}
      onChangeNextPage={onChangeNextPage}
      />
  );
};

Organizations.propTypes = {
  classes: shape({}),
  rateQuotaDetails: shape({}),
  isLoading: bool,
  totalPages: number,
  totalElements: number,
  results: arrayOf(shape({})),
  fetchOrganizations: func,
  rqUuid: string,
};

const mapStateToProps = (state) => ({
  isLoading: false,
  results: getOrgsListResults(state),
  totalPages: getOrgsListTotalPages(state),
  totalElements: getOrgsListTotalElements(state),
});

const mapDispatchToProps = {
  fetchOrganizations,
};
Organizations.displayName = 'Organizations';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Organizations);
