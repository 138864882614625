import colorMod from 'color';

const styles = ({ color }) => ({
  leftBorder: {
    width: 36,
    borderTop: `1px solid ${color.borderColor}`,
    borderBottom: `1px solid ${color.borderColor}`,
    backgroundColor: `${colorMod(color.borderColor).alpha(0.1).string()} !important`,
  },
  rightBorder: {
    width: 36,
    borderLeft: `1px solid ${color.borderColor}`,
    borderTop: `1px solid ${color.borderColor}`,
    borderBottom: `1px solid ${color.borderColor}`,
    backgroundColor: `${colorMod(color.borderColor).alpha(0.1).string()} !important`,
  },
});

export default styles;
