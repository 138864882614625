import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Button } from '@material-ui/core';
import { get } from 'lodash';
import { bool, func, object, number, arrayOf } from 'prop-types';

import { fetchTags } from '../../../../actions/organization';
import { getI18n } from '../../../../utils/intl';
import { FormAutoCompleteCombo } from '../../../../components';

export const UpdateTags = (props) => {
  const {
    config,
    classes = {},
    isUpdatingOrganizationTags,
    bulkActionsItemsCount,
    onAddNewTag,
    onAddTag,
    onRemoveTag,
    fetchTags,
    defaultOptions = [],
  } = props;
  const id = 'organization-tags'
  const intl = getI18n(useIntl());
  const [options, setOptions] = useState(defaultOptions);
  const [name, setName] = useState('');
  const [value, setValue] = useState();
  const setResponse = props.setResponse ||
    ((response) => setOptions(get(response, 'results')));

  const onChange = (e, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(!isUpdatingOrganizationTags) {
      fetchTags({ config, name, setResponse });
    }
  }, [isUpdatingOrganizationTags, name]);

  const onClickAdd = () => {
    setName('');
    if (value.inputValue) {
      onAddNewTag(value.inputValue)
    } else {
      onAddTag(value)
    }
  };
  const onClickRemove = () => {
    setName('');
    onRemoveTag(value);
  };

  return (
    <>
      <Grid item md={3} sm={12} xs={12}>
        <FormAutoCompleteCombo
          {...props}
          id={id}
          inputLabel={intl.getI18nMessage('label.search.tag.placeholder')}
          name={`${intl.getI18nMessage('label.actions')} (${bulkActionsItemsCount} ${intl.getI18nMessage('label.items.selected')})`}
          options={options}
          onChange={onChange}
          onSearchTextChange={(e) => {setName(e.target.value)}}
          noMargin
          hideHelper
          withCreate
        />
      </Grid>
      <Grid
        item md={4} sm={12} xs={12}
        className={classes.tagButtons}
      >
        <Button
          variant="contained" color="secondary"
          className={classes.addTagsButton}
          id={`${id}-add`} data-apim-test={`${id}-add`} data-layer7-test={`${id}-add`}
          onClick={onClickAdd}
          disabled={!value}
        >
          {intl.getI18nMessage('label.add.tag')}
        </Button>
        <Button
          variant="contained" color="secondary"
          className={classes.removeTagsButton}
          id={`${id}-remove`} data-apim-test={`${id}-remove`} data-layer7-test={`${id}-remove`}
          onClick={onClickRemove}
          disabled={!value || !!value.inputValue}
        >
          {intl.getI18nMessage('label.remove.tag')}
        </Button>
      </Grid>
    </>
  );
}

UpdateTags.propTypes = {
  config: object,
  classes: object,
  isUpdatingOrganizationTags: bool,
  bulkActionsItemsCount: number,
  onAddNewTag: func,
  onAddTag: func,
  onRemoveTag: func,
  fetchTags: func,
  setResponse: func,
  defaultOptions: arrayOf(object),
};

export default (props) => (
  <UpdateTags
    {...props}
    fetchTags={fetchTags}
  />
);
