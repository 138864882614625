import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

const getEntityIdForEntityType = entity => (
  entity.includes('Id') ? entity : `${entity.replace('s', '')}Id`
);
const getEntityMapNameForEntityType = entity => `${entity.replace('s', '')}Map`;

export const getBucketPermuteIdAndNames = (
  bucket,
  entitiesStr,
  entityIdMap,
) => {
  const idSeparator = '__';
  const ids = [];
  const names = [];
  let entityId;
  let entityName = '';
  if (!entitiesStr) {
    entityId = '1';
    entityName = 'All Data';
    ids.push(entityId);
    names.push(entityName);
  } else {
    const entities = entitiesStr.split(',');
    each(entities, (entity) => {
      entityId = bucket[getEntityIdForEntityType(entity)];
      if (!entityId) entityId = '333';
      if (entityId) {
        ids.push(entityId);
      }
      const entityTypeMap = entityIdMap[getEntityMapNameForEntityType(entity)];
      entityName = (entityTypeMap && entityTypeMap[entityId]) || entityId;
      entityName =
        isEmpty(entityName) || isUndefined(entityName)
          ? 'Unauthorized'
          : entityName;
      names.push(entityName);
    });
  }
  const permuteId = ids.join(idSeparator);
  return {
    permuteId,
    names,
    ids,
  };
};

export const getEntityPermuteMap = (dataBuckets, entitiesStr, entityIdMap) => {
  const entityPermuteMap = {};
  let permuteObject = {};
  let permuteId;
  if (!entitiesStr) {
    permuteObject = getBucketPermuteIdAndNames({}, entitiesStr, {});
    permuteId = permuteObject.permuteId;
    entityPermuteMap[permuteId] = {};
    entityPermuteMap[permuteId].ids = permuteObject.ids;
    entityPermuteMap[permuteId].names = permuteObject.names;
  } else {
    each(dataBuckets, (bucket) => {
      permuteObject = getBucketPermuteIdAndNames(
        bucket,
        entitiesStr,
        entityIdMap,
      );
      permuteId = permuteObject.permuteId;
      entityPermuteMap[permuteId] = {};
      entityPermuteMap[permuteId].ids = permuteObject.ids;
      entityPermuteMap[permuteId].names = permuteObject.names;
    });
  }
  return entityPermuteMap;
};

const getEntityIdForEntityTypeForCustomReport = (entity) => entity;

export const getSourcesIdsFromResponse = (response = {}, type, entitiesMap) => {
  const responseData = response || {};
  const data = responseData.data || {};
  const buckets = (data && data[type] && data[type].buckets) || [];
  const sourceIds = [];
  const entitiesStr = response && response.query && response.query.buckets;
  if (entitiesStr) {
    each(buckets, (bucket) => {
      const permuteObject = getBucketPermuteIdAndNames(
        bucket,
        entitiesStr,
        entitiesMap,
      );
      sourceIds.push(permuteObject.permuteId);
    });
  } else {
    sourceIds.push('1');
  }
  return sourceIds;
};

export const getBucketPermuteIdAndNamesForCustomReport = (
  bucket,
  entitiesStr,
) => {
  const idSeparator = '__';
  const ids = [];
  const names = [];
  const tooltipLabel = [];
  let entityId;
  let entityName = '';
  if (!entitiesStr) {
    entityId = '1';
    entityName = 'ALL_DATA';
    ids.push(entityId);
    names.push(entityName);
  } else {
    const entities = entitiesStr.split(',');
    each(entities, (entity) => {
      entityId =
        bucket[getEntityIdForEntityTypeForCustomReport(entity)] ||
        'UNAUTHORIZED';
      ids.push(entityId);
      if (entity.includes('Id')) {
        entityName = bucket[entity.replace('Id', 'Name')] || entityId;
        entityName =
          isEmpty(entityName) ||
          isUndefined(entityName) ||
          entityName === 'UNAUTHORIZED'
            ? 'Unauthorized'
            : entityName;
        tooltipLabel.push(entityName);
      } else {
        entityName = entityId;
        tooltipLabel.push(entityName);
      }
      entityName =
        isEmpty(entityName) || isUndefined(entityName)
          ? 'Unauthorized'
          : entityName;
      names.push(entityName);
    });
  }
  const permuteId = ids.join(idSeparator);
  return {
    permuteId,
    names,
    ids,
    tooltipLabel,
  };
};

export const getSourcesIdsFromResponseForCustomReport = (
  response = {},
  selectedDataSource,
) => {
  const responseData = response || {};
  const sourceIds = [];
  const entitiesArray = [];
  selectedDataSource.forEach((dataSource) => {
    entitiesArray.push(dataSource.dimension);
  });
  const entitiesStr = entitiesArray.join(',');
  if (entitiesStr) {
    each(responseData, (data) => {
      const permuteObject = getBucketPermuteIdAndNamesForCustomReport(
        data,
        entitiesStr,
      );
      if (permuteObject.permuteId) {
        sourceIds.push(permuteObject.permuteId);
      }
    });
  } else {
    sourceIds.push('1');
  }
  return sourceIds;
};

export const getOrgMapFromResponse = (data = {}) => {
  const orgMap = {};
  if (data && data.usageTs) {
    each(data.usageTs, (bucket) => {
      if (bucket.usage && bucket.usage.length) {
        each(bucket.usage, (item) => {
          if (!orgMap[item.orgId]) {
            orgMap[item.orgId] = item.orgName;
          }
        });
      }
    });
  }
  return orgMap;
};

export const getDefaultSharedMutations = (sourceIds, keyMetric) => {
  const sharedMutations = {};
  each(sourceIds, (permuteId) => {
    const defaultMetricVal = {
      show: true,
      hover: false,
    };
    sharedMutations[permuteId] = {
      show: true,
      metrics: {
        [keyMetric]: defaultMetricVal,
      },
    };
  });
  return sharedMutations;
};

const SOURCE_METRIC_SEPARATOR = '___';
export const getMetricKey = (id, metric) =>
  `${id}${SOURCE_METRIC_SEPARATOR}${metric}`;
export const getSourceFromMetricKey = (metricKey) =>
  metricKey.split(SOURCE_METRIC_SEPARATOR)[0];
export const getMetricFromMetricKey = (metricKey) =>
  (metricKey.includes(SOURCE_METRIC_SEPARATOR) &&
    metricKey.split(SOURCE_METRIC_SEPARATOR)[1]) ||
  '';
