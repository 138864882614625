// @flow
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { useTheme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { isArray, get } from 'lodash';
import PropTypes from 'prop-types';
import {
  updateUserContexts,
  fetchRequests,
  fetchProxyStatus,
} from '../../actions/portalConfig';
import {
  getIsPortalAdmin,
  getIsOnlyPortalAdmin,
  getIsOrgPublisher,
  getIsAnonymousRole,
  getThemes,
  getGlobalNavigation,
  getCQNavigation,
  getUserAccessibleOrgs,
  getUserActiveOrgUuid,
  getUserDetails,
  getRequests,
  getNewLandingPage,
  getProxyStatus,
  getErrors,
} from '../../reducers/portalConfig';
import Branding from './Branding';
import Nav from './Nav';
import Requests from './Requests';
import ProxyStatus from './ProxyStatus';
import Settings from './Settings';
import User from './User';
import styles from './index.theme';

function componentDidMount() {
  const { isPortalAdmin, isOrgPublisher } = this.props;
  if (isPortalAdmin || isOrgPublisher) { // Publisher Role
    this.props.fetchRequests();
  }
}

export const Header = (props) => {
  const {
    logo,
    siteTitle,
    homeHref,
    classes,
    navItems,
    cqNavItems,
    requestItems,
    userAccessibleOrgs,
    userActiveOrgUuid,
    userDetails,
    isEnabledGlobalHeader,
  } = props;
  const settingsNode = navItems && navItems.find(item => item.titleKey === 'label.appnav.administration');
  const settingItems = (settingsNode && isArray(settingsNode.navigations)
    && settingsNode.navigations) || [];
  const devCQItems =
    cqNavItems &&
    cqNavItems.developer &&
    cqNavItems.developer.filter(
      (item) => item.titleKey !== 'label.appnav.home',
    );
  const devCQMenu = [];
  if (devCQItems) {
    devCQItems.forEach((item) => {
      devCQMenu.push({
        menuTitle: item.title,
        href: item.url,
        externalLink: true,
      });
      if (item.subNav) {
        item.subNav.forEach((subnavItem) => {
          devCQMenu.push({
            menuTitle: subnavItem.title,
            href: subnavItem.url,
            externalLink: true,
          });
        });
      }
    });
  }
  const filteredNavItems = navItems.filter(item => item.titleKey !== 'label.appnav.administration');
  const theme = useTheme();
  const [isLargeScreen, setIsLargeScreen] =
    useState(window.innerWidth > get(theme, 'misc.breakpointLarge', 1280));
  if (devCQMenu && devCQMenu.length > 0) {
    const devCQTopMenu = {
      titleKey: 'label.appnav.custompages.menu',
      navigations: devCQMenu,
      externalLink: false,
    };
    filteredNavItems.splice(filteredNavItems.length - 1, 0, devCQTopMenu);
  }
  const updateMedia = () => {
    setIsLargeScreen(window.innerWidth > get(theme, 'misc.breakpointLarge'));
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  // Changes to hide all header elements except Branding while printing
  // analytics reports
  // To keep DOM structure intact, added styling in individual elements
  // to skip elements while printing,
  // which depends on classname skipPrint, so don't remove or rename it
  const shouldSkipHeaderInPrint = () => window.location.href.includes('analytics-dashboard');
  const skipPrint = shouldSkipHeaderInPrint();
  return (
    isEnabledGlobalHeader ?
      <div className={clsx(classes.Header, skipPrint && 'skipPrint')}>
        <div className={classes.HeaderInner}>
          <Branding
            logo={logo}
            siteTitle={siteTitle}
            homeHref={homeHref}
          />
          {isLargeScreen ?
            <Nav navItems={filteredNavItems} /> :
            <Nav navItems={filteredNavItems} renderCollapsed />
          }
          {(props.isOnlyPortalAdmin) &&
            <ProxyStatus
              {...props}
            />
          }
          {(settingItems.length > 0) && <Settings settingItems={settingItems} />}
          {(props.isPortalAdmin || props.isOrgPublisher) &&
            <Requests fetchRequests={props.fetchRequests} requestItems={requestItems} />
          }
          <User
            userAccessibleOrgs={userAccessibleOrgs}
            userActiveOrgUuid={userActiveOrgUuid}
            userDetails={userDetails}
            updateUserContexts={props.updateUserContexts}
          />
        </div>
      </div>
    :
      <div />
  );
};

const getHomePageRef = (state) => {
  const hostName = window.location.hostname;
  if (getIsPortalAdmin(state)) {
    return `//${hostName}/admin`;
  } else if (getIsAnonymousRole(state)) {
    return `//${hostName}/admin/app/guest/dashboard`;
  }
  return `//${hostName}/admin/app/dashboard`;
};

const getNavItems = (state) => getGlobalNavigation(state);
const getCqItems = (state) => getCQNavigation(state);
export const mapStateToProps = (state) => {
  const { logo, siteTitle } = getThemes(state);
  return {
    logo,
    siteTitle,
    isPortalAdmin: getIsPortalAdmin(state),
    isOrgPublisher: getIsOrgPublisher(state),
    homeHref: getHomePageRef(state),
    navItems: getNavItems(state),
    cqNavItems: getCqItems(state),
    userAccessibleOrgs: getUserAccessibleOrgs(state),
    userActiveOrgUuid: getUserActiveOrgUuid(state),
    userDetails: getUserDetails(state),
    requestItems: getRequests(state),
    isEnabledGlobalHeader: getNewLandingPage(state),
    proxyStatus: getProxyStatus(state),
    errors: getErrors(state),
    isOnlyPortalAdmin: getIsOnlyPortalAdmin(state),
  };
};

export const mapDispatchToProps = {
  updateUserContexts,
  fetchRequests,
  fetchProxyStatus,
};

Header.propTypes = {
  logo: PropTypes.string,
  siteTitle: PropTypes.string,
  homeHref: PropTypes.string,
  classes: PropTypes.object,
  fetchRequests: PropTypes.func,
  navItems: PropTypes.arrayOf(PropTypes.shape()),
  cqNavItems: PropTypes.shape(),
  userAccessibleOrgs: PropTypes.arrayOf(PropTypes.shape()),
  userActiveOrgUuid: PropTypes.string,
  userDetails: PropTypes.shape({}),
  requestItems: PropTypes.arrayOf(PropTypes.shape()),
  updateUserContexts: PropTypes.func,
  isEnabledGlobalHeader: PropTypes.bool,
  isPortalAdmin: PropTypes.bool,
  isOnlyPortalAdmin: PropTypes.bool,
  isOrgPublisher: PropTypes.bool,
  proxyStatus: PropTypes.arrayOf(PropTypes.shape()),
  errors: PropTypes.arrayOf(PropTypes.shape()),
  fetchProxyStatus: PropTypes.func,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({ componentDidMount }),
)(Header);
