import axios from 'axios';
import { get } from 'lodash';

import * as Constants from '../../constants';
import { AXIOS_DEFAULT_OPTIONS } from '..';
import { getConfig } from '../../reducers/portalConfig';

const initApplication = (dispatch, isLoading = true) =>
  dispatch({
    type: Constants.APP_LOADING,
    isLoading,
  });

export const fetchRequestSettings = () =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/workflowConfigurations`;
    dispatch({
      type: Constants.REQUEST_SETTINGS_GET_REQUEST,
    });
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.REQUEST_SETTINGS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() =>
        dispatch({
          type: Constants.REQUEST_SETTINGS_GET_ERROR,
          payload: [''],
        }),
      );
  };

export const updateRequestSettings = (requestSettings) =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    dispatch({
      type: Constants.REQUEST_SETTINGS_UPDATE_REQUEST,
    });
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const url = `${portal.hostname}/admin/workflowConfigurations`;
    await axios.put(url, requestSettings, { headers }, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.REQUEST_SETTINGS_UPDATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.REQUEST_SETTINGS_UPDATE_ERROR,
          payload: get(error, 'response.data'),
        });
      });
  };

