import { get } from 'lodash';

const styles = theme => ({
  container: {
    display:'block',
    width:'100%',
    height:'400px',
    paddingBottom: '42px',
  },
  containerError: {
    border: `1px solid ${get(theme, 'color.error')}`,
  },
  error: {
    color: get(theme, 'color.error'),
    paddingTop: 10,
  },
  helperText: {
    color: get(theme, 'color.smallText'),
    fontFamily: get(theme, 'typography.smallText'),
    fontSize: get(theme, 'fontSize.smallText'),
  },
});

export default styles;