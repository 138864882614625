import React from 'react';
import ReactQuill from 'react-quill';
import { withStyles, FormHelperText } from '@material-ui/core';
import { string, func, shape, bool } from 'prop-types';

import styles from './styles';

export const RichTextEditor = (props) => {
  const {
    classes = {},
    content,
    handleContent,
    helperText,
    error = false,
    id = 'form-editor',
  } = props;

  return (
    <div id={id}>
        <ReactQuill
          className={error ? [classes.container, classes.containerError ]: classes.container}
          value={content}
          onChange={handleContent}
        />
        <FormHelperText
          className={classes.helperText}
        >
            {helperText}
        </FormHelperText>
    </div>
 );
};

export default (
  withStyles(styles)
)(RichTextEditor);


RichTextEditor.propTypes = {
  content: string,
  handleContent: func,
  helperText: string,
  classes: shape({}),
  error: bool,
  id: string,
};