import React from 'react';

type Props = {
  text?: Object,
  x: Number,
  y: Number,
};

const labelComponent = (styles) => ({ text, x, y }: Props) => {
  const capHeight = 1.71;
  const { fontSize, textAnchor, fill, fontFamily, fontFamilyBold } = styles;
  const contentLength = text.length;
  let titleLength = 0;
  for (let i = 0; i < contentLength; i++) {
    if (text[i] !== '') {
      titleLength++;
    } else {
      break;
    }
  }

  const dy = (((capHeight / 2) + ((0.5 - contentLength) / 2) /*  * linedHeight */) * 9);
  const tspanElements = text.map((line, index) => {
    let spanDy;
    switch (true) {
      case (index === 0): spanDy = undefined;
        break;
      case (text[index - 1] === ''): spanDy = 2 * fontSize;
        break;
      default: spanDy = fontSize;
        break;
    }
    const key = `tspan__${index}`;
    return (<tspan
      key={key}
      dy={spanDy}
      dx={0}
      x={x}
      fontSize={fontSize}
      textAnchor={textAnchor}
      fill={fill}
      fontFamily={index <= titleLength ? fontFamilyBold : fontFamily}
    >
      {line}
    </tspan>);
  });
  return (<text textAnchor={textAnchor} x={x} y={y} dy={dy} >
    {tspanElements}
  </text>);
};

export default labelComponent;
