import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { some, isEmpty } from 'lodash';
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import {
  FormActionButtons,
  ErrorContainer,
  FormTextField,
  FormAutoCompleteCombo,
} from '../../../../components';
import {
  APPLICATION_STATUS_PENDING_APPROVAL,
  APPLICATION_STATUS_EDIT_PENDING_APPROVAL,
  APPLICATION_STATUS_REJECTED,
} from '../../../../constants';

import getI18n, {
  LABEL_BUTTON_NEXT,
} from '../labels';
import { hasError, hasOrgBoundRole } from '../../../../utils';

const getSelectedOrganization = (organizations = [], organizationUuid) => (
  organizations.find(organization => (organization.uuid === organizationUuid)) || {}
);

const getSelectedOrganizationForOrgBoundUser = (userAccessibleOrgs = [], userActiveOrgUuid) => (
  userAccessibleOrgs.find(userAccessibleOrg => (userAccessibleOrg.uuid === userActiveOrgUuid)) || {}
);

export default function Details({
    classes,
    headerRef,
    dict = {},
    errors = [],
    userContext = {},
    userActiveOrgUuid,
    userAccessibleOrgs,
    currentTab,
    setCurrentTab,
    isApplicationNameUnique,
    fetchApplicationNameUnique,
    applicationUuid,
    applicationDetails = {},
    setApplicationDetails,
    organizations1 = {},
    customFields = [],
    fetchOrganizations,
    fetchAvailableApis,
    fetchAvailableApiGroups,
    onCancel,
  }) {
  const i18n = getI18n(dict);
  const { name = '', description = '', organizationUuid, organizationName, status = '' } = applicationDetails;
  const selectedOrganization = hasOrgBoundRole(userContext) ?
    getSelectedOrganizationForOrgBoundUser(userAccessibleOrgs, userActiveOrgUuid) :
    getSelectedOrganization(organizations1.results, organizationUuid);

  const [applicationName, setApplicationName] = useState(name);
  const [appStatus, setAppStatus] = useState(status);
  const [applicationDescription, setApplicationDescription] = useState(description);
  const [applicationDetailsErrors, setApplicationDetailsErrors] = useState({});

  useEffect(() => { setApplicationName(name); }, [name]);
  useEffect(() => { setAppStatus(status); }, [status]);
  useEffect(() => { setApplicationDescription(description); }, [description]);

  useEffect(() => {
    if (currentTab !== 'details-tab') { return; }
    if (!hasOrgBoundRole(userContext)) { fetchOrganizations(); }
  }, []);

  useEffect(() => {
    if (currentTab !== 'details-tab') { return; }
    if (selectedOrganization.uuid) {
      fetchAvailableApis({ orgUuid: selectedOrganization.uuid });
      fetchAvailableApiGroups({ orgUuid: selectedOrganization.uuid });
    }
  }, [selectedOrganization.uuid]);

  useEffect(() => {
    // Re-run Unique Name check on direct navigating from Application Key tab
    if (currentTab === 'application-key-tab') {
      if (hasOrgBoundRole(userContext)) {
        fetchApplicationNameUnique(name, applicationUuid);
      } else if (selectedOrganization.uuid) {
        fetchApplicationNameUnique(name, applicationUuid, selectedOrganization.uuid);
      }
    }
  }, [name, applicationUuid, selectedOrganization.uuid]);
  useEffect(() => {
    setApplicationDetailsErrors({
      ...applicationDetailsErrors,
      name: (isApplicationNameUnique === false),
    });
  }, [isApplicationNameUnique]);

  const handleChange = async (fieldName, fieldValue) => {
    localStorage.setItem('isAppUnSavedChanges', true);
    switch (fieldName) {
      case 'organizationUuid': {
        const selectedOrganizationUuid = fieldValue.uuid || organizationUuid;
        // Re-run unique name check and initialize name error with hasError check if name is set
        if (name && selectedOrganizationUuid) {
          await fetchApplicationNameUnique(name, applicationUuid, selectedOrganizationUuid);
        }
        setApplicationDetailsErrors(
          {
            ...applicationDetailsErrors,
            ...(name && { name: hasError(name, true) }),
            organizationUuid: !fieldValue,
          },
        );
        setApplicationDetails({
          ...applicationDetails,
          organizationUuid: fieldValue ? fieldValue.uuid : null,
          organizationName: fieldValue ? fieldValue.name : null,
        });
        break;
      }
      case 'name':
        setApplicationDetailsErrors(
          { ...applicationDetailsErrors, name: hasError(fieldValue, true) },
        );
        setApplicationName(fieldValue);
        if (hasOrgBoundRole(userContext)) {
          await fetchApplicationNameUnique(fieldValue, applicationUuid);
        } else if (selectedOrganization.uuid) {
          await fetchApplicationNameUnique(fieldValue, applicationUuid, selectedOrganization.uuid);
        }
        break;
      case 'status':
        setAppStatus(fieldValue);
        setApplicationDetails({ ...applicationDetails, status: fieldValue });
        break;
      case 'description':
        setApplicationDescription(fieldValue);
        break;
      default:
    }
  };

  const handleBlurNameField = (nameValue) => {
    // Set to Application Details on blur to reduce the render cycles
    setApplicationDetails({ ...applicationDetails, name: nameValue });
  };
  const handleBlurDescriptionField = (descriptionValue) => {
    // Set to Application Details on blur to reduce the render cycles
    setApplicationDetails({ ...applicationDetails, description: descriptionValue });
  };

  useEffect(() => {
    // Recurring fetch call to cache the organizations
    if ((organizations1.currentPage + 1) < (organizations1.totalPages)) {
      fetchOrganizations(organizations1.currentPage + 1);
    }
  }, [organizations1]);

  const onOrgBoundNext = () => {
    const nameError = hasError(name || applicationName, true)
      || (isApplicationNameUnique === false);
    if (nameError) {
      setApplicationDetailsErrors({
        ...applicationDetailsErrors,
        name: nameError,
      });
    } else if (isApplicationNameUnique === true) {
      if (!isEmpty(customFields)) {
        setCurrentTab('custom-fields-tab');
        window.scrollTo(0, headerRef.offsetTop);
      } else {
        setCurrentTab('api-management-tab');
        window.scrollTo(0, headerRef.offsetTop);
      }
    }
  };

  const onNext = () => {
    const organizationUuidError = !organizationUuid;
    const nameError = hasError(name || applicationName, true)
      || (isApplicationNameUnique === false);
    if (some([organizationUuidError, nameError])) {
      setApplicationDetailsErrors({
        ...applicationDetailsErrors,
        organizationUuid: organizationUuidError,
        name: nameError,
      });
    } else if (isApplicationNameUnique === true) {
      if (!isEmpty(customFields)) {
        setCurrentTab('custom-fields-tab');
        window.scrollTo(0, headerRef.offsetTop);
      } else {
        setCurrentTab('api-management-tab');
        window.scrollTo(0, headerRef.offsetTop);
      }
    }
  };

  const appStatusOptions = [
    {
      key: 'ENABLED',
      label: 'Enabled',
    },
    {
      key: 'DISABLED',
      label: 'Disabled',
    },
  ];

  return (
    <Fragment>
      <Typography variant="h1" className={classes.pageTitle}>
        Details
      </Typography>
      {(errors.length > 0) &&
        <ErrorContainer errors={errors} />
      }
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid md={12} item>
          {!hasOrgBoundRole(userContext) &&
            (applicationUuid ?
              <FormTextField
                id={'application-selected-organization'}
                name={'Organization Name'}
                value={organizationName}
                disabled
              /> :
              <FormAutoCompleteCombo
                id="application-selected-organization"
                name="Selected Organization"
                selectedValues={selectedOrganization}
                data={organizations1.results}
                error={applicationDetailsErrors.organizationUuid}
                onChange={(e, value) => { handleChange('organizationUuid', value); }}
                loading={organizations1.currentPage < (organizations1.totalPages - 1)}
                helperText={'Select at least one organization.'}
              />)
          }
          <FormTextField
            id={'application-name'}
            name={'Application Name'}
            value={applicationName}
            error={applicationDetailsErrors.name || (isApplicationNameUnique === false)}
            handleChange={(value) => { handleChange('name', value); }}
            handleBlur={(value) => { handleBlurNameField(value); }}
            helperText={'Maximum name length is 50 characters. Name must be unique.'}
            maxLength="50"
          />
          {applicationUuid && status !== APPLICATION_STATUS_REJECTED &&
            <Fragment>
              <FormControl component="fieldset" className={classes.statusField}>
                <FormLabel component="legend" focused={false}>
                  {'Status'}
                </FormLabel>
                {[APPLICATION_STATUS_PENDING_APPROVAL,
                  APPLICATION_STATUS_EDIT_PENDING_APPROVAL].includes(status) ?
                    <Fragment>
                      <Typography classes={{ root: classes.labelText }} gutterBottom>
                        {'Pending Approval'}
                      </Typography>
                      <FormHelperText classes={{ root: classes.helperText }}>
                        {'Approve or reject the request to enable or disable this app.'}
                      </FormHelperText>
                    </Fragment> :
                    <RadioGroup
                      value={appStatus}
                      onChange={(e) => handleChange('status', e.target.value)}
                    >
                      {appStatusOptions.map((item) => (
                        <Fragment key={item.key}>
                          <FormControlLabel
                            control={<Radio classes={{ root: classes.iconRoot }} />}
                            key={item.key}
                            label={item.label}
                            value={item.key}
                          />
                        </Fragment>
                      ))}
                    </RadioGroup>
                }
              </FormControl>
            </Fragment>
          }
          <FormTextField
            id={'application-description'}
            name={'Public Description'}
            value={applicationDescription}
            handleChange={value => { handleChange('description', value || ''); }}
            handleBlur={value => { handleBlurDescriptionField(value); }}
            helperText={'Maximum description length is 255 characters'}
            multiline
            rows={4}
            optional
            maxLength="255"
          />
        </Grid>
      </Grid>
      <FormActionButtons
        onNextClick={hasOrgBoundRole(userContext) ? onOrgBoundNext : onNext}
        nextText={i18n(LABEL_BUTTON_NEXT)}
        onCancelClick={onCancel}
        id="details-form-buttons"
      />
    </Fragment>
  );
}

Details.propTypes = {
  classes: PropTypes.object,
  headerRef: PropTypes.object,
  userContext: PropTypes.object,
  userActiveOrgUuid: PropTypes.string,
  userAccessibleOrgs: PropTypes.arrayOf(PropTypes.object),
  dict: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  isApplicationNameUnique: PropTypes.bool,
  fetchApplicationNameUnique: PropTypes.func,
  applicationUuid: PropTypes.string,
  applicationDetails: PropTypes.object,
  organizations1: PropTypes.object,
  customFields: PropTypes.arrayOf(PropTypes.object),
  setApplicationDetails: PropTypes.func,
  fetchOrganizations: PropTypes.func,
  fetchAvailableApis: PropTypes.func,
  fetchAvailableApiGroups: PropTypes.func,
  onCancel: PropTypes.func,
};
