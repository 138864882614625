import get from 'lodash/get';

export default ({ color, typography }) => ({
  panel: {
    display: 'flow-root',
    boxSizing: 'border-box',
    position: 'relative',
    backgroundColor: get(color, 'background'),
    fontFamily: get(typography, 'bodyText'),
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 14,
  },
  loader: {
    textAlign: 'center',
    height: 'auto',
    marginTop: 28,
    marginBottom: 20,
    '& .MuiCircularProgress-colorPrimary': {
      color: color.link,
    },
  },
});
