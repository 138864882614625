import moment from 'moment';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import {
  TYPE_BY_MINUTE,
  TYPE_BY_HOUR,
  TYPE_BY_DAY,
  TYPE_BY_WEEK,
  TYPE_BY_MONTH,
  TIME_RANGE_HOUR,
  TIME_RANGE_DAY,
  TIME_RANGE_WEEK,
  TIME_RANGE_MONTH,
  HISTORICAL_METRIC_30DAYS,
  HISTORICAL_METRIC_52WEEKS,
} from '../../../constants/metrics';

export const lineColors = ['#B51629', '#8B802D', '#3C1279', '#FF9966', '#89D3E5', '#159540', '#169BD5', '#FFCC00', '#FF00FF', '#B3716C'];
export const lightShadeLineColors = ['#FFCC0066', '#FF996666', '#15954066', '#169BD566', '#93A7B166', '#B1A0C966', '#3C127966', '#8B802D66', '#B5162966', '#00000066'];

export const getStrokeColor = (lineColorsParam, index) =>
  lineColorsParam[index > lineColorsParam.length ? index % lineColorsParam.length : index];

const getHourlyTickValuesMapForCustomRange = (xAxisTickValues, startDate) => {
  const returnValues = {};
  returnValues.xAxisValuesTickMap = {};
  returnValues.xAxisTickValuesMap = {};
  const startDateString = moment.utc(startDate).format('YYYY-MM-DD');
  forEach(xAxisTickValues, (item) => {
    const timeString = moment.utc(startDateString, 'YYYY-MM-DD').startOf('hour').add(item, 'hour')
      .format();
    returnValues.xAxisTickValuesMap[item] = timeString;
    returnValues.xAxisValuesTickMap[timeString] = item;
  });
  return returnValues;
};

const getDailyTickValuesMapForCustomRange = (xAxisTickValues, startDate, days) => {
  const returnValues = {};
  returnValues.xAxisValuesTickMap = {};
  returnValues.xAxisTickValuesMap = {};
  const startDateString = moment.utc(startDate).format('YYYY-MM-DD');
  // special case if a single day was selected. show it in the middle
  if (days === 1) {
    const currentDayStr = moment.utc(startDateString, 'YYYY-MM-DD').startOf('day').format();
    returnValues.xAxisTickValuesMap[1] = currentDayStr;
    returnValues.xAxisValuesTickMap[currentDayStr] = 1;
  } else {
    forEach(xAxisTickValues, (item) => {
      const timeString = moment.utc(startDateString, 'YYYY-MM-DD').startOf('day').add(item, 'day')
      .format();
      returnValues.xAxisTickValuesMap[item] = timeString;
      returnValues.xAxisValuesTickMap[timeString] = item;
    });
  }
  return returnValues;
};

const getUnitFromAggregation = (timeAgg) => timeAgg && timeAgg.replace('by-', '');

export const getTickValuesMap = (timeAgg, xAxisTickValues, endDate) => {
  const returnValues = {};
  const totalCount = xAxisTickValues.length - 1;
  returnValues.xAxisValuesTickMap = {};
  returnValues.xAxisTickValuesMap = {};
  const unit = getUnitFromAggregation(timeAgg);
  forEach(xAxisTickValues, (item) => {
    const timeString = moment.utc(endDate).startOf(unit)
    .subtract(totalCount - item, unit)
    .format();
    returnValues.xAxisTickValuesMap[item] = timeString;
    returnValues.xAxisValuesTickMap[timeString] = item;
  });
  return returnValues;
};

const getMonthlyTickValuesMapForCustomRange = (
  xAxisTickValues, adjustedStartDate, endDateParam) => {
  const returnValues = {};
  returnValues.xAxisValuesTickMap = {};
  returnValues.xAxisTickValuesMap = {};
  const startDateString = moment.utc(adjustedStartDate).format('YYYY-MM-DD');
  if ((adjustedStartDate.year() === endDateParam.year()) &&
    (adjustedStartDate.month() === endDateParam.month())) {
    const currentDayStr = moment.utc(startDateString, 'YYYY-MM-DD').startOf('day').format();
    returnValues.xAxisTickValuesMap[1] = currentDayStr;
    returnValues.xAxisValuesTickMap[currentDayStr] = 1;
  } else {
    forEach(xAxisTickValues, (item) => {
      const timeString = moment.utc(startDateString, 'YYYY-MM-DD').startOf('month').add(item, 'month')
      .format();
      returnValues.xAxisTickValuesMap[item] = timeString;
      returnValues.xAxisValuesTickMap[timeString] = item;
    });
  }
  return returnValues;
};

const getUsageMonthlyTickValuesMapForCustomRange = (
  xAxisTickValues, adjustedStartDate, endDateParam) => {
  const returnValues = {};
  returnValues.xAxisValuesTickMap = {};
  returnValues.xAxisTickValuesMap = {};
  const startDateString = moment.utc(adjustedStartDate).format('YYYY-MM-DD');
  if ((adjustedStartDate.year() === endDateParam.year()) &&
    (adjustedStartDate.month() === endDateParam.month())) {
    const startDayStr = moment.utc(startDateString, 'YYYY-MM-DD').startOf('day').format();
    returnValues.xAxisTickValuesMap[1] = startDayStr;
    returnValues.xAxisValuesTickMap[startDayStr] = 1;
  } else {
    forEach(xAxisTickValues, (item) => {
      const timeString = moment.utc(startDateString, 'YYYY-MM-DD').add(item * 30, 'days')
      .format();
      returnValues.xAxisTickValuesMap[item] = timeString;
      returnValues.xAxisValuesTickMap[timeString] = item;
    });
  }
  return returnValues;
};

const getWeeklyTickValuesMapForCustomRange = (
    xAxisTickValues, adjustedStartDate) => {
  const returnValues = {};
  returnValues.xAxisValuesTickMap = {};
  returnValues.xAxisTickValuesMap = {};
  const startDateString = moment.utc(adjustedStartDate).format('YYYY-MM-DD');
  forEach(xAxisTickValues, (item) => {
    const timeString = moment.utc(startDateString, 'YYYY-MM-DD').add(item, 'week')
    .format();
    returnValues.xAxisTickValuesMap[item] = timeString;
    returnValues.xAxisValuesTickMap[timeString] = item;
  });
  return returnValues;
};

const getTicksLength = (dateRange, aggregation) => {
  let length;
  let hours;
  if (dateRange === 'day') {
    length = 24;
  } else if (dateRange === 'hour') {
    length = 60;
  } else if (dateRange === 'week') {
    length = 7;
    if (aggregation === 'by-hour') {
      hours = moment().utc().hours() + 1;
      length = (6 * 24) + hours;
    }
  } else if (dateRange === 'month') {
    length = 30;
    if (aggregation === 'by-hour') {
      hours = moment().utc().hours() + 1;
      length = (29 * 24) + hours;
    }
  }
  return length;
};

const getDefaultTimeRangesData = (dateRange, aggregation, endDate) => {
  const key = getTicksLength(dateRange, aggregation);
  const xAxisTickValues = [...Array(key).keys()];
  const returnValues = getTickValuesMap(aggregation, xAxisTickValues, endDate);
  return {
    xAxisTickValues,
    returnValues,
  };
};

const getCustomTimeRangesData = (startDate, endDate, aggregation) => {
  const getHourlyAggData = (startDateParam, endDateParam) => {
    const selectedDays = endDateParam.startOf('day').diff(startDateParam.startOf('day'), 'days') + 1;
    // const totalDays = getRoundedDays(selectedDays);
    const xAxisTickValues = [...Array(selectedDays * 24).keys()];
    const returnValues =
      getHourlyTickValuesMapForCustomRange(xAxisTickValues, startDateParam);
    return {
      xAxisTickValues,
      returnValues,
    };
  };
  const getDailyAggData = (startDateParam, endDateParam) => {
    const selectedDays = endDateParam.startOf('day').diff(startDateParam.startOf('day'), 'days') + 1;
    let xAxisTickValues;
    if (selectedDays === 1) {
      xAxisTickValues = [...Array(3).keys()];
    } else {
      xAxisTickValues = [...Array(selectedDays).keys()];
    }
    const returnValues =
      getDailyTickValuesMapForCustomRange(xAxisTickValues, startDateParam, selectedDays);
    return {
      xAxisTickValues,
      returnValues,
    };
  };
  const getWeeklyAggData = (startDateParam, endDateParam) => {
    const adjustedStartDate = moment.utc(startDateParam).startOf('isoWeek');
    const adjustedEndDate = moment.utc(endDateParam).startOf('isoWeek');
    const ticks = adjustedEndDate.diff(adjustedStartDate, 'weeks') + 1;
    const xAxisTickValues = [...Array(ticks).keys()];
    // show ticks from 1st of starting month to 1st of ending month
    const returnValues = getWeeklyTickValuesMapForCustomRange(
      xAxisTickValues, adjustedStartDate, endDateParam, ticks);
    return {
      xAxisTickValues,
      returnValues,
    };
  };
  const getMonthlyAggData = (startDateParam, endDateParam) => {
    const adjustedStartDate = moment.utc(startDateParam).startOf('month');
    const adjustedEndDate = moment.utc(endDateParam).startOf('month');
    const absoluteMonthsForStartDate = (adjustedStartDate.year() * 12) + adjustedStartDate.month();
    const absoluteMonthsForEndDate = (adjustedEndDate.year() * 12) + adjustedEndDate.month();
    const ticks = (absoluteMonthsForEndDate - absoluteMonthsForStartDate) + 1;
    let xAxisTickValues;
    if ((adjustedStartDate.year() === endDateParam.year()) &&
      (adjustedStartDate.month() === endDateParam.month())) {
      xAxisTickValues = [...Array(3).keys()];
    } else {
      xAxisTickValues = [...Array(ticks).keys()];
    }
    // show ticks from 1st of starting month to 1st of ending month
    const returnValues = getMonthlyTickValuesMapForCustomRange(
      xAxisTickValues, adjustedStartDate, endDateParam, ticks);
    return {
      xAxisTickValues,
      returnValues,
    };
  };
  let data = {};
  switch (aggregation) {
    case 'by-hour':
    case 'hour':
      data = getHourlyAggData(startDate, endDate);
      break;
    case 'by-day':
    case 'day':
      data = getDailyAggData(startDate, endDate);
      break;
    case 'by-week':
    case 'week':
      data = getWeeklyAggData(startDate, endDate);
      break;
    case 'by-month':
    case 'month':
      data = getMonthlyAggData(startDate, endDate);
      break;
    default: break;
  }
  return {
    xAxisTickValues: data.xAxisTickValues,
    returnValues: data.returnValues,
  };
};

export function getDisplayDate(timeString, daterange,
  timeAgg, startDate, endDate, type, nonTimeAggregated = false, chart) {
  let days = 0;
  let timeObj;
  let dateString;
  const start = moment.utc(startDate);
  const end = moment.utc(endDate);
  if (daterange === 'custom') {
    days = end.startOf('day').diff(start.startOf('day'), 'days');
  }
  const getWeekRange = (date: String) => {
    let weekRangeStr = '';
    let startOfWeek = moment.utc(date);
    let endOfWeek = moment.utc(date).add(6, 'days');
    const dateBreak = type === 'tooltip' ? '-' : '\n';

    if (endOfWeek.isAfter(end)) {
      endOfWeek = end;
    } else if (startOfWeek < start) {
      startOfWeek = start;
    }

    if (startOfWeek.isSame(endOfWeek, 'day')) {
      weekRangeStr = startOfWeek.format('DD MMM YYYY');
    } else {
      weekRangeStr = `${startOfWeek.format('DD MMM YYYY')} ${dateBreak} ${endOfWeek.format('DD MMM YYYY')}`;
    }

    return weekRangeStr;
  };
  const getTooltipDate = () => {
    switch (daterange) {
      case 'hour':
      case 'day':
        return `${timeObj.format('MMM DD YYYY')} ${timeObj.format('HH:mm')}`;
      case 'week':
      case 'month':
        return timeObj.format('MMM DD YYYY');
      case 'custom': {
        let string;
        if (timeAgg === 'by-hour' && days <= 2) {
          string = `${timeObj.format('HH:mm')} ${timeObj.format('MMM DD YYYY')}`;
        } else if (timeAgg === 'by-month') {
          if (nonTimeAggregated) {
            string = timeObj.format('DD MMM YYYY');
          } else {
            string = timeObj.format('MMM YYYY');
          }
        } else if (timeAgg === 'by-week') {
          if (nonTimeAggregated) {
            string = timeObj.format('DD MMM YYYY');
          } else {
            string = getWeekRange(timeObj);
          }
        } else {
          string = timeObj.format('MMM DD YYYY');
        }
        return string;
      }
      default: return '';
    }
  };
  const getXaxisLabel = (chartParam) => {
    switch (daterange) {
      case 'hour':
      case 'day':
        return `${timeObj.format('MMM DD')} \n ${timeObj.format('HH:mm')}`;
      case 'week':
      case 'month':
        return timeObj.format('MMM DD');
      case 'custom': {
        let string;
        if (timeAgg === 'by-hour' && days <= 2) {
          string = `${timeObj.format('HH:mm')} \n ${timeObj.format('MMM DD')}`;
        } else if (timeAgg === 'by-month') {
          if (nonTimeAggregated) {
            string = timeObj.format('DD MMM YYYY');
          } else {
            string = timeObj.format('MMM YYYY');
          }
        } else if (timeAgg === 'by-week') {
          if (nonTimeAggregated) {
            string = timeObj.format('DD MMM YYYY');
          } else {
            string = getWeekRange(timeObj);
          }
        } else {
          string = timeObj.format('MMM DD');
          if (chartParam === 'dailyQuota') {
            string = `${timeObj.format('MMM DD')} \n ${timeObj.format('HH:mm')}`;
          }
        }
        return string;
      }
      default: return '';
    }
  };
  const getDateLabelsForDailyQuota = () => {
    const breakStr = type === 'tooltip' ? '' : '\n';
    switch (daterange) {
      case 'day':
        return `${timeObj.format('HH:mm')} ${breakStr} ${timeObj.format('MMM DD')}`;
      case 'week':
        return `${timeObj.format('HH:mm')} ${breakStr} ${timeObj.format('DD MMM YYYY')}`;
      case 'custom':
        return `${timeObj.format('HH:mm')} ${breakStr} ${timeObj.format('DD MMM YYYY')}`;
      default: return '';
    }
  };
  if (timeString) {
    timeObj = moment(timeString, moment.ISO_8601).utc();
    if ((chart === 'dailyQuota') && (type === 'tooltip')) {
      dateString = getDateLabelsForDailyQuota();
    } else if (type === 'tooltip') {
      dateString = getTooltipDate();
    } else {
      dateString = getXaxisLabel(chart);
    }
  }
  return dateString;
}

const getUsageCustomTimeRangesData = (startDate, endDate, aggregation) => {
  const getHourlyAggData = (startDateParam, endDateParam) => {
    const selectedDays = endDateParam.startOf('day').diff(startDateParam.startOf('day'), 'days') + 1;
    // const totalDays = getRoundedDays(selectedDays);
    const xAxisTickValues = [...Array(selectedDays * 24).keys()];
    const returnValues =
      getHourlyTickValuesMapForCustomRange(xAxisTickValues, startDateParam);
    return {
      xAxisTickValues,
      returnValues,
    };
  };
  const getDailyAggData = (startDateParam, endDateParam) => {
    const selectedDays = endDateParam.startOf('day').diff(startDateParam.startOf('day'), 'days') + 1;
    let xAxisTickValues;
    if (selectedDays === 1) {
      xAxisTickValues = [...Array(3).keys()];
    } else {
      xAxisTickValues = [...Array(selectedDays).keys()];
    }
    const returnValues =
      getDailyTickValuesMapForCustomRange(xAxisTickValues, startDateParam, selectedDays);
    return {
      xAxisTickValues,
      returnValues,
    };
  };
  const getWeeklyAggData = (startDateParam, endDateParam) => {
    const adjustedStartDate = moment.utc(startDateParam).startOf('day');
    const adjustedEndDate = moment.utc(endDateParam).startOf('day');
    const ticks = Math.ceil(adjustedEndDate.diff(adjustedStartDate, 'days') / 7) + 1;
    const newStartDate = adjustedEndDate.subtract((ticks - 1) * 7, 'days');
    const xAxisTickValues = [...Array(ticks).keys()];
    // show ticks from 1st of starting month to 1st of ending month
    const returnValues = getWeeklyTickValuesMapForCustomRange(
      xAxisTickValues, newStartDate, endDateParam, ticks);
    return {
      xAxisTickValues,
      returnValues,
    };
  };
  const getMonthlyAggData = (startDateParam, endDateParam) => {
    const differenceInDays = moment.utc(endDateParam).diff(moment.utc(startDateParam), 'days');
    const ticks = Math.ceil(differenceInDays / 30) + 1;
    let xAxisTickValues;
    if ((startDateParam.year() === endDateParam.year()) &&
      (startDateParam.month() === endDateParam.month())) {
      xAxisTickValues = [...Array(3).keys()];
    } else {
      xAxisTickValues = [...Array(ticks).keys()];
    }
    const adjustedStartDate = moment.utc(endDateParam).startOf('day').subtract((ticks - 1) * 30, 'days');
    const returnValues = getUsageMonthlyTickValuesMapForCustomRange(
      xAxisTickValues, adjustedStartDate, endDateParam, ticks);
    return {
      xAxisTickValues,
      returnValues,
    };
  };
  let data = {};
  switch (aggregation) {
    case 'by-hour':
      data = getHourlyAggData(startDate, endDate);
      break;
    case 'by-day':
      data = getDailyAggData(startDate, endDate);
      break;
    case 'by-week':
      data = getWeeklyAggData(startDate, endDate);
      break;
    case 'by-month':
      data = getMonthlyAggData(startDate, endDate);
      break;
    default: break;
  }
  return {
    xAxisTickValues: data.xAxisTickValues,
    returnValues: data.returnValues,
  };
};

export const getDateRangeData = (dateRange, timeAgg, startDate, endDate) => {
  let dateRangeData = {};
  if (dateRange === 'custom') {
    dateRangeData = getCustomTimeRangesData(moment.utc(startDate), moment.utc(endDate), timeAgg);
  } else {
    dateRangeData = getDefaultTimeRangesData(dateRange, timeAgg, endDate);
  }
  return {
    xAxisTickValues: dateRangeData.xAxisTickValues,
    xAxisValuesTickMap: dateRangeData.returnValues.xAxisValuesTickMap,
    xAxisTickValuesMap: dateRangeData.returnValues.xAxisTickValuesMap,
    dateRange,
    timeAgg,
    startDate,
    endDate,
  };
};

const timerangeTypeMap = {
  week: TYPE_BY_DAY,
  day: TYPE_BY_HOUR,
  hour: TYPE_BY_MINUTE,
};

export const getUsageRangeData = (dateRange, timeAgg, startDate, endDate) => {
  let dateRangeData = {};
  if (dateRange === 'custom') {
    dateRangeData =
      getUsageCustomTimeRangesData(moment.utc(startDate), moment.utc(endDate), timeAgg);
  } else {
    dateRangeData = getDefaultTimeRangesData(dateRange, timeAgg, endDate);
  }
  return {
    xAxisTickValues: dateRangeData.xAxisTickValues,
    xAxisValuesTickMap: dateRangeData.returnValues.xAxisValuesTickMap,
    xAxisTickValuesMap: dateRangeData.returnValues.xAxisTickValuesMap,
    dateRange,
    timeAgg,
    startDate,
    endDate,
  };
};

export const getCustomReportAggregation = (days) => {
  switch (true) {
    case (days <= 2):
      return TIME_RANGE_HOUR;
    case (days <= 60):
      return TIME_RANGE_DAY;
    case (days <= 366):
      return TIME_RANGE_WEEK;
    case (days <= 731):
      return TIME_RANGE_MONTH;
    default:
      return TIME_RANGE_DAY;
  }
};

export const getAggregation = (timerange, startDate, endDate) => {
  let aggregation = timerangeTypeMap[timerange] || TYPE_BY_DAY;
  if (timerange === 'custom') {
    const days = moment(endDate).diff(startDate, 'days') + 1;
    switch (true) {
      case (days <= 2):
        aggregation = TYPE_BY_HOUR;
        break;
      case (days <= 60):
        aggregation = TYPE_BY_DAY;
        break;
      case (days <= 366):
        aggregation = TYPE_BY_WEEK;
        break;
      case (days <= 1800):
        aggregation = TYPE_BY_MONTH;
        break;
      default:
        aggregation = TYPE_BY_DAY;
        break;
    }
  }
  return aggregation;
};

export const getMonthlyUsageAggregation = (timerange, startDate, endDate) => {
  let aggregation = TYPE_BY_DAY;
  if (timerange === 'custom') {
    const days = moment(endDate).diff(startDate, 'days') + 1;
    switch (true) {
      case (days <= 60):
        aggregation = TYPE_BY_DAY;
        break;
      case (days <= 366):
        aggregation = TYPE_BY_WEEK;
        break;
      case (days <= 1800):
        aggregation = TYPE_BY_MONTH;
        break;
      default:
        aggregation = TYPE_BY_DAY;
        break;
    }
  }
  return aggregation;
};

export const getMonthlyUsageParamsFromQueryData = (query: Object = {}) => {
  const timerange = query.timerange && query.timerange.replace('(default)', '');
  const startDate = query.startTimeGMT && query.startTimeGMT.replace('(default)', '');
  let endDate = query.endTimeGMT && query.endTimeGMT.replace('(default)', '');

  // todo: remove this hack, once it is fixed in backend
  if (timerange === 'custom') {
    const endDateObj = moment.utc(endDate).subtract(1, 'hour');
    endDate = `${endDateObj.format('YYYY-MM-DD')}T${endDateObj.format('HH:mm:SS')}`;
  }

  return {
    timerange,
    timeAgg: getMonthlyUsageAggregation(timerange, startDate, endDate),
    startDate,
    endDate,
  };
};

export const getParamsFromQueryData = (query: Object = {}) => {
  const timerange = query.timerange && query.timerange.replace('(default)', '');
  const startDate = query.startTimeGMT && query.startTimeGMT.replace('(default)', '');
  let endDate = query.endTimeGMT && query.endTimeGMT.replace('(default)', '');

  // todo: remove this hack, once it is fixed in backend
  if (timerange === 'custom') {
    const endDateObj = moment.utc(endDate).subtract(1, 'hour');
    endDate = `${endDateObj.format('YYYY-MM-DD')}T${endDateObj.format('HH:mm:SS')}`;
  }

  return {
    timerange,
    timeAgg: getAggregation(timerange, startDate, endDate),
    startDate,
    endDate,
  };
};

export const getYAxisTicks = (max) => {
  const yTicks = [];
  let suffix = '';
  let paddingFactor = 1;
  let decimalPoint = 2;
  if (max > 0) {
    let padding = 0;
    if (max > 0 && max <= 1) {
      if (max < 0.01) {
        decimalPoint = 3;
      } else if (max < 0.001) {
        decimalPoint = 4;
      }
      padding = (max / 5).toFixed(decimalPoint);
    } else if (max < 10) {
      padding = 1;
      if (max > 5) {
        padding++;
      }
    } else {
      const length = max.toFixed(0).toString().length;
      padding = Math.ceil(max / 5);
      if (length > 12) {
        paddingFactor = 10 ** 12;
        suffix = 'T';
      } else if (length > 9) {
        paddingFactor = 10 ** 9;
        suffix = 'B';
      } else if (length > 6) {
        paddingFactor = 10 ** 6;
        suffix = 'M';
      } else if (length > 3) {
        paddingFactor = 10 ** 3;
        suffix = 'K';
      } else if (padding >= 100) {
        paddingFactor = 100;
      } else if (padding >= 10) {
        paddingFactor = 10;
      } else if (padding >= 5) {
        paddingFactor = 5;
      }
      padding = Math.ceil(padding / paddingFactor) * paddingFactor;
      paddingFactor = paddingFactor < 1000 ? 1 : paddingFactor;
    }
    let item = 0;
    for (let i = 0; item < max; i++) {
      item = padding > 0 && padding < 1 ?
        parseFloat((padding * i).toFixed(decimalPoint)) : padding * i;
      yTicks.push(item);
    }
  }
  return { yTicks, paddingFactor, suffix };
};

export const getParamsForHistoricalData = (query, timeRange, entityData) => {
  /*
  show no data for unsupported time ranges
  */
  const params = {};
  params.buckets = query.buckets ? query.buckets.split(',') : [];
  params.limit = [];
  const bucketMap = { apis: 'apiIds', apps: 'appIds', orgs: 'orgIds' };
  const entityDataBucketMap = { apis: 'apiId', apps: 'appId', orgs: 'orgId' };
  forEach(params.buckets, bucket => {
    params[bucketMap[bucket]] = [];
    forEach(entityData.buckets, data => {
      params[bucketMap[bucket]].push(data[entityDataBucketMap[bucket]]);
    });
    params[bucketMap[bucket]] = uniq(params[bucketMap[bucket]]);
    const entityIds = params[bucketMap[bucket]];
    params[bucketMap[bucket]] = params[bucketMap[bucket]].join();
    params.limit.push(entityIds.length);
  });
  params.stats = query.stats ? [query.stats] : null;
  params.timerange = 'custom';
  const startDate = query.startTimeGMT && query.startTimeGMT.replace('(default)', '');
  const endDate = query.endTimeGMT && query.endTimeGMT.replace('(default)', '');
  params.aggregation = getAggregation(params.timerange, startDate, endDate);

  if (timeRange === HISTORICAL_METRIC_30DAYS) {
    const noOfDays = 30;
    const pastStartDate = moment.utc(startDate).subtract(noOfDays, 'days');
    params.startDate = pastStartDate.format('YYYY-MM-DD');
    const pastEndDate = moment.utc(endDate).subtract(noOfDays, 'days');
    params.endDate = pastEndDate.format('YYYY-MM-DD');
    params.metricType = HISTORICAL_METRIC_30DAYS;
  } else if (timeRange === HISTORICAL_METRIC_52WEEKS) {
    const noOfDays = 52 * 7;
    const pastStartDate = moment.utc(startDate).subtract(noOfDays, 'days');
    params.startDate = pastStartDate.format('YYYY-MM-DD');
    const pastEndDate = moment.utc(endDate).subtract(noOfDays, 'days');
    params.endDate = pastEndDate.format('YYYY-MM-DD');
    params.metricType = HISTORICAL_METRIC_52WEEKS;
  }
  if (query.timerange === 'day') {
    params.endDate = params.startDate;
  }
  return params;
};

export const getParamsForHistoricalUsageData = (query, timeRange, orgIds, isMonthly) => {
  /*
  show no data for unsupported time ranges
  */
  const params = {};
  params.orgIds = orgIds.join(',');
  params.limit = [orgIds.length];
  params.planType = query.planType;
  params.stats = query.stats ? [query.stats] : null;
  params.timerange = 'custom';

  const startDate = query.startTimeGMT && query.startTimeGMT.replace('(default)', '');
  let endDate = query.endTimeGMT && query.endTimeGMT.replace('(default)', '');
  // not supporting 'last 1 hour' for usage in UI, but handling it
  if ((query.timerange === 'hour') || (query.timerange === 'day')) {
    endDate = startDate;
  } else if (query.timerange === 'week') {
    endDate = moment.utc(startDate).add(6, 'days');
  }

  if (isMonthly) {
    params.aggregation = getMonthlyUsageAggregation(params.timerange, startDate, endDate);
  } else {
    params.aggregation = getAggregation(params.timerange, startDate, endDate);
  }

  if (timeRange === HISTORICAL_METRIC_30DAYS) {
    const noOfDays = 30;
    const pastStartDate = moment.utc(startDate).subtract(noOfDays, 'days');
    params.startDate = pastStartDate.format('YYYY-MM-DD');
    const pastEndDate = moment.utc(endDate).subtract(noOfDays, 'days');
    params.endDate = pastEndDate.format('YYYY-MM-DD');
    params.metricType = HISTORICAL_METRIC_30DAYS;
  } else if (timeRange === HISTORICAL_METRIC_52WEEKS) {
    const noOfDays = 52 * 7;
    const pastStartDate = moment.utc(startDate).subtract(noOfDays, 'days');
    params.startDate = pastStartDate.format('YYYY-MM-DD');
    const pastEndDate = moment.utc(endDate).subtract(noOfDays, 'days');
    params.endDate = pastEndDate.format('YYYY-MM-DD');
    params.metricType = HISTORICAL_METRIC_52WEEKS;
  }
  return params;
};

export const getStartAndEndRangeForLastNDays = (days) => {
  const endDate = moment.utc();
  return {
    endDate: endDate.format('YYYY-MM-DD'),
    startDate: endDate.subtract('days', days - 1).format('YYYY-MM-DD'),
  };
};

export const getStartAndEndRangeWithTimeForLastNDays = (days) => {
  const endDate = moment.utc();
  return {
    endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
    startDate: endDate.subtract('days', days - 1).format('YYYY-MM-DDTHH:mm:ss'),
  };
};

export const convertWeekRangeToCustom = (params, withTime) => {
  const newParams = params;
  if (newParams.timerange === 'week') {
    newParams.timerange = 'custom';
    let customDays;
    if (withTime) {
      customDays = getStartAndEndRangeWithTimeForLastNDays(7);
    } else {
      customDays = getStartAndEndRangeForLastNDays(7);
    }
    newParams.startDate = customDays.startDate;
    newParams.endDate = customDays.endDate;
  }
  return newParams;
};

export const clearHistoricalDataMutations = (sharedMutations) => {
  const updatedSharedMutation = {};
  forEach(sharedMutations, (entityObj, entity) => {
    updatedSharedMutation[entity] = {
      ...entityObj,
      metrics: {
        ...(entityObj && entityObj.metrics),
        customDate: {},
        customWeek: {},
      },
    };
  });
  return updatedSharedMutation;
};

export const clearMetricsHoverState = (sharedMutations) => {
  const updatedSharedMutation = sharedMutations;
  forEach(updatedSharedMutation, (sharedMutationObj, sourceKey) => {
    forEach(sharedMutationObj.metrics, (metricObj, metricKey) => {
      updatedSharedMutation[sourceKey].metrics[metricKey] = {
        ...updatedSharedMutation[sourceKey].metrics[metricKey],
        hover: false,
      };
    });
  });
  return updatedSharedMutation;
};
