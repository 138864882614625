import { get } from 'lodash';
import { convertHexToRGB } from '../../../../utils';

const styles = ({ color }) => ({
  topPanelContainer: {
    backgroundColor: `${convertHexToRGB(get(color, 'borderColor'), 0.1)}`,
    minHeight: '120px',
    padding: '0 36px',
  },
  marginPanelContainer: {
    minHeight: '350px',
  },
  detailsContainer: {
    padding: '20px 36px',
  },
});

export default styles;
