import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { parse, stringify } from 'query-string';
import compose from 'recompose/compose';
import {
  withStyles,
  Divider,
  IconButton,
} from '@material-ui/core';
import IconAdd from '@material-ui/icons/Add';
import { get } from 'lodash';
import { object, arrayOf, bool, func, string } from 'prop-types';

import {
  getIsLoading,
  getErrors,
  getDocumentationTree,
  getNewDocument,
  getSelectedViewDocument,
  getIsDocSaveSuccess,
  getIsDocDeleteSuccess,
  getIsUpdateTreeSuccess,
  getDocumentErrors,
} from '../../../../reducers/api';
import {
  fetchDocumentTree,
  fetchDocument,
  addNewDocument,
  removeNewDocument,
  saveDcoument,
  deleteDocument,
  updateDocumentTree,
  resetDocumentNotification,
} from '../../../../actions/api';
import {
  getFirstDocument,
} from '../../../../components/TreeViewNav/tree';
import { getI18n } from '../../../../utils/intl';
import {
  TreeViewNav,
  AlertDialog,
  Loading,
  ErrorContainer,
  AlertMessages,
} from '../../../../components';
import { ALERT_SUCCESS } from '../../../../constants';
import DocumentView from './DocumentView';
import DocumentEdit from './DocumentEdit';
import DocumentCreate, { createNewDocument } from './DocumentCreate';
import { moveDocument } from './useUpdateDocumentTree';
import styles from './styles';

export const DocumentationRaw = (props) => {
  const {
    classes,
    isLoading,
    apiUuid,
    tabValue,
    documentErrors,
    entityUuid = apiUuid,
    entityType = 'api',
    items,
    userCanEdit,
    userCanAdd = userCanEdit,
    userCanDelete,
    history,
    newDocument,
    selectedViewDocument,
    isDocSaveSuccess,
    isDocDeleteSuccess,
    isUpdateTreeSuccess,
  } = props;

  const locale = 'en-US';
  const title = 'New-document';
  const intl = getI18n(useIntl());
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [expanded, setExpanded] = useState([items.map(obj => obj.id)]);
  const [viewDocument, setViewDocument] = useState(selectedViewDocument);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    if (tabValue === 'api-details-documentation-tab') {
      props.fetchDocumentTree({ entityUuid, entityType, locale });
    }
  }, [apiUuid, tabValue]);

  useEffect(() => {
    const query = useQuery(location);
    const navtitle = get(query, 'uri', null);
    if(items.length > 0 ) {
      const firstDoc = items.filter(doc =>
        !doc.parentUuid).sort((a, b) => a.ordinal - b.ordinal)[0];
      const currentDoc = items.filter(doc => doc.navtitle === navtitle)[0];
      if (!currentDoc && firstDoc) {
        props.fetchDocument({ entityUuid, entityType, locale, navtitle: firstDoc.navtitle });
      } else if (currentDoc) {
        props.fetchDocument({ entityUuid, entityType, locale, navtitle: currentDoc.navtitle });
      }
    }
  }, [items]);

  useEffect(() => {
    setViewDocument(selectedViewDocument);
  }, [selectedViewDocument])

  useEffect(() => {
    setExpanded(items.map(obj => obj.id));
  }, [items]);

  const notifyMessages = (message, status) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  const {
      mode,
      selectedDocument,
      selectedDocumentId,
      openDocumentPage,
      openNewDocumentPage,
      closeDocumentPage,
  } = useDocumentationHistory(items, entityUuid, history, props.location);

  useEffect(() => {
    if (mode === 'add') {
        if (!history.location.state) {
          history.goBack();
          return;
        }
      props.addNewDocument(history.location.state);
    } else if (newDocument) {
      props.removeNewDocument();
    }
}, [history, mode, newDocument]);

useEffect(() => {
  if(isDocSaveSuccess) {
    props.fetchDocumentTree({ entityUuid, entityType, locale });
    const msg = newDocument ? 'Document successfully created' :
    'Document successfully updated';
    notifyMessages(msg, ALERT_SUCCESS);
    setTimeout(() => { props.resetDocumentNotification()}, 2500);
    setTimeout(() => { notifyMessages('', '')}, 2500);
    openDocumentPage(selectedDocument, 'view');
  }
}, [isDocSaveSuccess])

useEffect(() => {
  if(isDocDeleteSuccess || isUpdateTreeSuccess) {
    if(isUpdateTreeSuccess) {
      notifyMessages('Document tree updated successfully', ALERT_SUCCESS);
    }
    if(isDocDeleteSuccess) {
      notifyMessages('Document successfully deleted', ALERT_SUCCESS);
    }
    setTimeout(() => { props.resetDocumentNotification()}, 2500);
    setTimeout(() => { notifyMessages('', '')}, 2500);
    props.fetchDocumentTree({ entityUuid, entityType, locale });
    closeDocumentPage();
  }
}, [isDocDeleteSuccess, isUpdateTreeSuccess])

  const handleDocumentParentChange = ({ documentUuid, newParentUuid, ordinal }) => {

      const newDocuments = moveDocument({
        documentUuid,
        newParentUuid,
        ordinal,
        allDocuments: items,
    });

    const prepareDataForUpdate = items =>
      items.map(({ ...item }) => item);
    const data = prepareDataForUpdate(newDocuments);
    props.updateDocumentTree(apiUuid, locale, data);
  };

  const handleAddNewDocument = (parentDocument) => {
    if (newDocument !== null) {
      return;
    }

    openNewDocumentPage(
      createNewDocument(
          title,
          parentDocument,
          items,
          entityType,
          entityUuid,
          locale,
      ),
    );
  };

  const handleAddNewChildDocument = () => {
    if (newDocument !== null) {
      return;
    }
    openNewDocumentPage(
      createNewDocument(
          title,
          viewDocument,
          items,
          entityType,
          entityUuid,
          locale,
      ),
    );
  };
  
  const handleSaveNewDocument = (newDoc) => {
    props.saveDcoument(newDoc, apiUuid);
  };

  const handleCancelAddNewDocument = () => {
    closeDocumentPage();
  };

  const handleSelectDocument = document => {
    props.fetchDocument({ entityUuid, entityType, locale, navtitle: document.navtitle });
    openDocumentPage(document, 'view');
};

const handleEditDocument = () => {
    openDocumentPage(selectedDocument, 'edit');
};

const handleSaveEditDocument = (newDoc) => {
  props.saveDcoument(newDoc, apiUuid);
};

const handleCancelEditDocument = () => {
    openDocumentPage(selectedDocument, 'view');
};

const handleDeleteDocument = () => {
  setShowDeleteDialog(true);
};

const onDelete = () => {
  setShowDeleteDialog(false);
  props.deleteDocument(selectedViewDocument, apiUuid);
};

const closeDeleteDialog = () => {
  setShowDeleteDialog(false);
}

const hasChildren = () => {
  openDocumentPage(selectedDocument, 'view');
};


  return (
    <div
      id="api-details-doc-container"
      data-apim-test="api-details-doc-container"
      className={classes.pageContainer}
    >
      <AlertDialog
          id="api-details-doc-delete-dialog"
          data-apim-test="api-details-doc-delete-dialog"
          isOpen={showDeleteDialog}
          title={intl.getI18nMessage('label.delete.confirmation.title')}
          description={intl.getI18nMessage('label.api.details.doc.delete.confirmation.text')}
          submitText={intl.getI18nMessage('label.delete.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={closeDeleteDialog}
          onSubmit={onDelete}
          onCancel={closeDeleteDialog}
          submitButtonClass={classes.submitButton}
        />
      <div className={classes.treeContainer}>
        <div className={classes.treeToolbar}>
          {userCanAdd && (
              <IconButton
                  className={classes.addRootDocumentationButton}
                  onClick={() => handleAddNewDocument()}
                  disabled={newDocument !== null}
                  aria-label={'New root document'}
                  title={'New root document'}
                  id="api-details-root-doc-add"
                  data-apim-test="api-details-root-doc-add"
              >
                  <IconAdd />
              </IconButton>
          )}
        </div>
          <Divider />
            <TreeViewNav
                className={classes.tree}
                items={[
                    ...(newDocument !== null ? [newDocument] : []),
                    ...items,
                ]}
                onDocumentSelected={handleSelectDocument}
                selectedDocumentId={
                    newDocument !== null
                        ? newDocument.id
                        : selectedDocumentId
                }
                expanded={expanded}
                onExpandedChange={setExpanded}
                onDocumentParentChange={handleDocumentParentChange}
                canDrag={userCanEdit}
                id="api-details-doc-tree-view"
            />
      </div>
      <div className={classes.documentation}>
          {isLoading ?
            <Loading />
          :
            <div>
              {documentErrors.length > 0 &&
                <ErrorContainer errors={documentErrors} />
              }
              {notificationMessage &&
                <AlertMessages
                  className={classes.alertMessages}
                  id={'api-details-doc-message'}
                  variant={notificationStatus}
                  message={notificationMessage}
                  onClose={() => {
                    notifyMessages('', '');
                  }}
                />
              }
              {selectedDocument && mode === 'view' ? (
                  <DocumentView
                      document={selectedDocument}
                      entityType={entityType}
                      entityUuid={entityUuid}
                      userCanDelete={userCanDelete}
                      userCanEdit={userCanEdit}
                      userCanAdd={userCanAdd}
                      hasChildren={hasChildren}
                      onEdit={handleEditDocument}
                      onAddNewDocument={handleAddNewChildDocument}
                      onDeleteDocument={handleDeleteDocument}
                      allDocuments={items}
                      data={viewDocument}
                      id="api-details-doc-view"
                  />
              ) : null }
              {selectedDocument && mode === 'edit' ? (
                  <DocumentEdit
                      document={selectedDocument}
                      entityType={entityType}
                      entityUuid={entityUuid}
                      userCanDelete={userCanDelete}
                      userCanEdit={userCanEdit}
                      hasChildren={hasChildren}
                      onSave={handleSaveEditDocument}
                      onCancel={handleCancelEditDocument}
                      onAddNewDocument={handleAddNewDocument}
                      onDeleteDocument={handleDeleteDocument}
                      selectedViewDocument={selectedViewDocument}
                      allDocuments={items}
                      id="api-details-doc-edit"
                  />
              ) : null }
              {newDocument && mode === 'add' ? (
                  <DocumentCreate
                      document={newDocument}
                      entityType={entityType}
                      entityUuid={entityUuid}
                      allDocuments={items}
                      onSave={handleSaveNewDocument}
                      onCancel={handleCancelAddNewDocument}
                      id="api-details-doc-create"
                  />
              ) : null}
            </div>
          }

      </div>
    </div>
  );
}

function useQuery(location) {
  return parse(location.search);
}

function useDocumentationHistory(items, entityUuid, history, location) {
  const query = useQuery(location);
  const selectedDocumentNavtitle = get(query, 'uri', null);
  const mode = get(query, 'mode', 'view');

  const selectedDocument =
      items.find(
          documentation => documentation.navtitle === selectedDocumentNavtitle,
      ) || null;
  const selectedDocumentId = selectedDocument ? selectedDocument.id : null;
  const firstDocument = useMemo(() => getFirstDocument(items), [items]);

  const openDocumentPage = useCallback(
        (document = null, mode = 'view', state = null) => {
            const search = history.location.pathname.includes('/documentation') ? stringify({
              ...(document && { uri: document.navtitle }),
              mode }) : '';

            return history.push({
                pathname: `${history.location.pathname}`,
                search,
                ...(state !== null && { state }),
            });
        },
        [history],
    );
  const openNewDocumentPage = state => {
      return openDocumentPage(null, 'add', state);
  };

  const closeDocumentPage = () => {
      return history.push({
          pathname: history.location.pathname,
      });
  };

  useEffect(() => {
      if (
          mode !== 'add' &&
          selectedDocumentNavtitle === null &&
          firstDocument !== null
      ) {
          openDocumentPage(firstDocument, 'view');
      }
  }, [
      firstDocument,
      items,
      mode,
      openDocumentPage,
      selectedDocument,
      selectedDocumentNavtitle,
  ]);

  return {
      // If a document was requested but not found
      error:
          selectedDocumentNavtitle && !selectedDocumentId
              ? 'resources.documents.notifications.not_found'
              : undefined,
      mode,
      selectedDocument,
      selectedDocumentId,
      openDocumentPage,
      openNewDocumentPage,
      closeDocumentPage,
  };
}

DocumentationRaw.propTypes = {
  classes: object,
  apiDetails: object,
  orgsAccessList: arrayOf(object),
  userContext: object,
  isLoading: bool,
}

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  errors: getErrors(state),
  items: getDocumentationTree(state),
  newDocument: getNewDocument(state),
  selectedViewDocument: getSelectedViewDocument(state),
  isDocSaveSuccess: getIsDocSaveSuccess(state),
  isDocDeleteSuccess: getIsDocDeleteSuccess(state),
  isUpdateTreeSuccess: getIsUpdateTreeSuccess(state),
  documentErrors:getDocumentErrors(state),
});
  
const mapDispatchToProps = {
  fetchDocumentTree,
  fetchDocument,
  addNewDocument,
  removeNewDocument,
  saveDcoument,
  deleteDocument,
  updateDocumentTree,
  resetDocumentNotification,
};

DocumentationRaw.displayName = 'Documentation';

DocumentationRaw.propTypes = {
  classes: object,
  isLoading: bool,
  apiUuid: string,
  tabValue: string,
  documentErrors: arrayOf(object),
  entityUuid: string,
  entityType: string,
  items: arrayOf(object),
  userCanEdit: bool,
  userCanDelete: bool,
  userCanAdd: bool,
  history: object,
  newDocument: object,
  selectedViewDocument: object,
  location: object,
  isDocSaveSuccess: bool,
  isDocDeleteSuccess: bool,
  isUpdateTreeSuccess: bool,
  fetchDocumentTree: func,
  fetchDocument: func,
  addNewDocument: func,
  removeNewDocument: func,
  saveDcoument: func,
  deleteDocument: func,
  updateDocumentTree: func,
  resetDocumentNotification: func,
};
  
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentationRaw);
  