import { get } from 'lodash';

const styles = ({ padding, margin }) => ({
  listContent: {
    margin: get(margin, 'm0'),
  },
  pageBodyClass: {
    paddingTop: get(padding, 'p0'),
  },
  pageFilterAndSortClass: {
    marginTop: get(margin, 'm24'),
  },
  fieldContainer: {
    margin: `${get(margin, 'm0')} !important`,
    marginRight: '5px !important',
  },
  selectField: {
    padding: '2px 10px !important',
  },
  statusIcon: {
    height: 15,
    marginRight: 3,
    width: 15,
  },
});

export default styles;
