import React, { useEffect, useState } from 'react';
//import { useIntl } from 'react-intl';
import { bool, func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, Grid, Button, Link } from '@material-ui/core';
import ReactJson from 'react-json-view';
import { get } from 'lodash';

import { fetchEntitiesSummary } from '../../../../actions/proxy';
import { getEntitiesSummary } from '../../../../reducers/proxy';
import { Footer } from '../../../../components';
import { getI18nFormattedMessage } from '../../../../utils/intl';
import styles from './styles';


export const EntitiesSummaryRaw = (props) => {
  const {
    proxyUuid,
    classes,
    entitiesSummary,
    config,
    featureFlags,
  } = props;

  const featureFlagL7Manager = JSON.parse(get(featureFlags, 'l7Manager.Value'));
  const hostname = get(config, 'portal.hostname');
  const tenantPrefix = get(config, 'portal.tenantPrefix');
  const link = `gateway-management/0.1/${proxyUuid}/summary/download/`;
  const url = `${hostname}/api/${tenantPrefix}/${link}`;
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (proxyUuid && featureFlagL7Manager) {
      props.fetchEntitiesSummary(proxyUuid);
    }
  }, []);

  return (
    <div
      id="proxy-details-entities-summary-container"
      data-apim-test="proxy-details-entities-summary-container"
      data-layer7-test="proxy-details-entities-summary-container"
    >
    <Grid container className={classes.container}>
          <>
            <Grid item md={7} sm={7} xs={12} className={classes.summaryContainer}>
              <div
                id="proxy-details-entities-summary-details"
                data-apim-test="proxy-details-entities-summary-details"
                data-layer7-test="proxy-details-entities-summary-details"
              >
                <ReactJson src={get(entitiesSummary, 'data')} collapsed={isCollapsed}/>
              </div>
            </Grid>
            <Grid item md={2} sm={2} xs={1}/>
            <Grid item md={3} sm={3} xs={12} className={classes.showContainer}>
              <div>
                <Button
                  id="proxy-details-entities-summary-expand"
                  data-apim-test="proxy-details-entities-summary-expand"
                  data-layer7-test="proxy-details-entities-summary-expand"
                  variant="text"
                  className={classes.button}
                  onClick={() =>setIsCollapsed(false)}
                >
                  {getI18nFormattedMessage('label.expand.all')}
                </Button>
                <Button
                  id="proxy-details-entities-summary-collapsed"
                  data-apim-test="proxy-details-entities-summary-collpased"
                  data-layer7-test="proxy-details-entities-summary-collpased"
                  variant="text"
                  className={classes.button}
                  onClick={() => setIsCollapsed(true)}
                >
                  {getI18nFormattedMessage('label.collapsed')}
                </Button>
                <Link href={url}>
                  <Button
                    id="proxy-details-entities-summary-doownload"
                    data-apim-test="proxy-details-entities-summary-download"
                    data-layer7-test="proxy-details-entities-summary-download"
                    variant="outlined"
                    className={classes.outlinedButton}
                  >
                    {getI18nFormattedMessage('label.download.json')}
                  </Button>
                </Link>
              </div>
            </Grid>
          </>
    </Grid>
    <Footer />
  </div>
  )
 
};

EntitiesSummaryRaw.propTypes = {
  classes: shape({}),
  config: shape({}),
  entitiesSummary: shape({}),
  featureFlags: shape({}),
  fetchEntitiesSummary: func,
  proxyUuid: string,
  isCurrentTab: bool,
};

const mapStateToProps = (state) => ({
  isLoading: false,
  entitiesSummary: getEntitiesSummary(state),
});

const mapDispatchToProps = {
  fetchEntitiesSummary,
};
EntitiesSummaryRaw.displayName = 'EntitiesSummary';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(EntitiesSummaryRaw);
