import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormLabel, withStyles, makeStyles } from '@material-ui/core';
import { get, find, lowerCase } from 'lodash';
import { push } from 'connected-react-router';

import {
  fetchOrganization,
  fetchRateLimitQuotas,
  fetchOrganizationNameUnique,
  createOrganization,
  updateOrganization,
  resetApp,
} from '../../../../actions/organization';
import {
  getOrganizationDetails,
  getIsFormLoading,
  getIsFormLoadingError,
  getIsOrganizationNameUnique,
  getIsFormValidationError,
  getIsFormCreateError,
  getIsFormUpdateError,
  getUpdateErrorMsg,
  getCreateErrorMsg,
  getIsFormSubmissionSuccessful,
  getRateLimitQuotasPage,
  getRateLimitQuotas,
} from '../../../../reducers/organization';
import { getUserDetails } from '../../../../reducers/portalConfig';
import EditContainer from '../../../edit';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import {
  hasPublisherRole,
} from '../../../../utils';
import styles from './styles';
import {
  FormAutoCompleteCombo,
  FormTextField,
  RadioGroupSection,
  FormActionButtons,
} from '../../../../components';
import {
  ORGANIZATION_TYPE_PUBLISHER,
  ORGANIZATION_TYPE_CONSUMER,
  ALERT_ERROR,
} from '../../../../constants';
import {
  HELP_ITEMS,
} from './../labels';


const getOrgTypes = () => ([
  {
    id: ORGANIZATION_TYPE_PUBLISHER,
    name: getI18nFormattedMessage('label.organization.form.page.radio.group.option.publisher'),
    description: getI18nFormattedMessage('label.organization.form.page.radio.group.option.publisher.description'),
  },
  {
    id: ORGANIZATION_TYPE_CONSUMER,
    name: getI18nFormattedMessage('label.organization.form.page.radio.group.option.consumer'),
    description: getI18nFormattedMessage('label.organization.form.page.radio.group.option.consumer.description'),
  },
]);

const PAGE_SIZE = 10000;

// wrapping renderPageContent with dummy function
// to avoid ES lint bug
const createPageContent = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const renderPageContent = ({
    handleBlur,
    handleChange,
    rateLimitQuotas = [],
    isRateLimitQuotaLoad,
    orgName,
    orgDescription,
    selectedRateLimitQuota = { name: '', uuid: '' },
    selectedOrgType,
    onSave,
    onCancel,
    isSaveDisabled,
    intl,
    formErrors,
  }) =>
    <Grid md={12} item>
      <FormTextField
        id={'organization-name'}
        name={intl.getI18nMessage('label.organization.form.page.organization.name')}
        value={orgName}
        handleChange={value => handleChange('name', value)}
        handleBlur={value => handleBlur('name', value)}
        helperText={intl.getI18nMessage('label.organization.form.page.helper.text.name')}
        maxLength="255"
        error={formErrors.name}
      />
      <div className={classes.typeContainer}>
        <FormLabel className={classes.formLabel} >
          {intl.getI18nMessage('label.organization.form.page.organization.type')}
        </FormLabel>
        <RadioGroupSection
          selected={selectedOrgType}
          onChange={(event) => handleChange('type', event.target.value)}
          data={getOrgTypes()}
          id="type-radio-group"
        />
      </div>
      <FormAutoCompleteCombo
        id="selected-accountplan"
        name={intl.getI18nMessage('label.organization.form.page.organization.rate.limit.quota')}
        selectedValues={selectedRateLimitQuota}
        isSecondaryText={true}
        data={rateLimitQuotas}
        loading={isRateLimitQuotaLoad}
        inputLabel={intl.getI18nMessage('label.organization.form.page.rate.place.holder.text')}
        onChange={(e, value) => handleChange('rateLimitQuota', value)}
        error={formErrors.rateLimitQuota}
      />
      <FormTextField
        id={'organization-description'}
        name={intl.getI18nMessage('label.organization.form.page.organization.public.description')}
        value={orgDescription}
        handleChange={value => handleChange('description', value)}
        helperText={intl.getI18nMessage('label.organization.form.page.helper.text.description')}
        multiline
        rows={4}
        optional
        maxLength="255"
      />
      <FormActionButtons
        onNextClick={onSave}
        nextText={intl.getI18nMessage('label.save.button')}
        onCancelClick={onCancel}
        id="org-edit-form-buttons"
        isDisabled={isSaveDisabled}
      />
    </Grid>
  ;
  renderPageContent.propTypes = {
    accountPlans: PropTypes.arrayOf(PropTypes.shape({})),
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    orgDescription: PropTypes.string,
    orgName: PropTypes.string,
    selectedAccountPlan: PropTypes.shape({}),
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    selectedOrgType: PropTypes.string,
    isSaveDisabled: PropTypes.bool,
    intl: PropTypes.shape({}),
    formErrors: PropTypes.shape({}),
    classes: PropTypes.shape({}),
  };
  return renderPageContent;
};

export const OrganizationOverview = (props) => {
  const {
    history,
    orgUuid,
    isEditMode,
    orgDetails,
    userContext,
    rateLimitQuotas,
    rateLimitQuotasPage,
    isLoading,
    isFormLoadingError,
    isOrgNameUnique,
    isFormValidationError,
    isCreateError,
    createErrorMsg,
    isUpdateError,
    updateErrorMsg,
    isFormSubmissionSuccessful,
    classes,
  } = props;
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const intl = getI18n(useIntl());
  const onDialogSubmit = () => {
    window.history.back();
  };
  const getHelpItems = () => HELP_ITEMS;
  const [orgName, setOrgName] = useState('');
  const [orgDescription, setOrgDescription] = useState('');
  const [initialOrgName, setInitialOrgName] = useState('');
  const [selectedRateLimitQuota, setSelectedRateLimitQuota] = useState({});
  const [isRateLimitQuotaLoad, setIsRateLimitQuotaLoad] = useState(false);
  const [selectedOrgType, setSelectedOrgType] = useState(ORGANIZATION_TYPE_CONSUMER);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const onDialogClose = () => {
    setIsDialogOpen(false);
  };

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  useEffect(() => {
    props.resetApp();
    props.fetchRateLimitQuotas(PAGE_SIZE, 0);
  }, []);

  useEffect(() => {
    if (isFormLoadingError) {
      notifyMessages(ALERT_ERROR, intl.getI18nMessage('error.organization.form.load'));
      setIsSaveDisabled(true);
    }
  }, [isFormLoadingError]);

  useEffect(() => {
    if (isFormValidationError) {
      notifyMessages(ALERT_ERROR, intl.getI18nMessage('error.organization.form.validation'));
      setIsSaveDisabled(true);
    }
  }, [isFormValidationError]);

  useEffect(() => {
    if (isCreateError) {
      notifyMessages(ALERT_ERROR, createErrorMsg || intl.getI18nMessage('error.organization.form.submit.create'));
    }
  }, [isCreateError]);

  useEffect(() => {
    if (isUpdateError) {
      notifyMessages(ALERT_ERROR, updateErrorMsg || intl.getI18nMessage('error.organization.form.submit.update'));
    }
  }, [isUpdateError]);

  useEffect(() => {
    if (!isOrgNameUnique) {
      setIsSaveDisabled(true);
      setFormErrors({
        ...formErrors,
        name: true,
      });
    }
  }, [isOrgNameUnique]);

  useEffect(() => {
    if (userContext) {
      if (!hasPublisherRole(userContext)) {
        props.push('/404');
      }
    }
  }, [userContext]);

  useEffect(() => {
    if (isEditMode && orgDetails && orgDetails.name) {
      setOrgName(orgDetails.name);
      setInitialOrgName(orgDetails.name);
      setOrgDescription(orgDetails.description);
      setSelectedOrgType(orgDetails.type);
      setSelectedRateLimitQuota(
        find(rateLimitQuotas, (rate) => rate.uuid === orgDetails.accountPlanUuid),
      )
    }
    if (rateLimitQuotas.length > 0) {
      rateLimitQuotas.map(item => {
        const limit = get(item, 'rateLimit.maxRequestsPerSecond') ? `${get(item, 'rateLimit.maxRequestsPerSecond')} / sec` : 'None';
        const quota = get(item, 'quota.quota') ? `${get(item, 'quota.quota')} / ${lowerCase(get(item, 'quota.interval'))}`
        : 'None';
        item.marker = item.defaultRateQuota ? getI18nFormattedMessage('label.default') : '';
        item.secondaryText = `Rate: ${limit}; Quota: ${quota}`;
      });
    }
  }, [rateLimitQuotas, orgDetails]);

  useEffect(() => {
    if (isFormSubmissionSuccessful) {
      if (isEditMode) {
        localStorage.setItem('isUpdateOrg', true);
      } else {
        localStorage.setItem('isCreateOrg', true);
      }
      history.push('/admin/console/organizations');
    }
  }, [isFormSubmissionSuccessful]);

  useEffect(() => {
    if (isEditMode && orgUuid) {
      props.fetchOrganization(orgUuid);
    }
  }, [orgUuid]);

  useEffect(() => {
    const page = rateLimitQuotasPage.currentPage < (rateLimitQuotasPage.totalPages - 1);
    setIsRateLimitQuotaLoad(page);
    if (page) {
      const currentPage = rateLimitQuotasPage.currentPage + 1;
      props.fetchRateLimitQuotas(PAGE_SIZE, currentPage);
    }
  }, [rateLimitQuotasPage]);

  const handleChange = (name, value = '') => {
    setIsSaveDisabled(false);
    if (name === 'name') {
      if (value) {
        setFormErrors({
          ...formErrors,
          name: false,
        });
      }
      setOrgName(value);
    } else if (name === 'rateLimitQuota') {
      setFormErrors({
        ...formErrors,
        rateLimitQuota: false,
      });
      setSelectedRateLimitQuota(value);
    } else if (name === 'type') {
      setSelectedOrgType(value);
    } else if (name === 'description') {
      setOrgDescription(value);
    }
    setHasUnsavedChanges(true);
  };

  const handleBlur = async (name, value) => {
    if ((name === 'name') && (initialOrgName !== value)) {
      await props.fetchOrganizationNameUnique(value);
    }
  };

  const validateForm = () => {
    if (!orgName || !get(selectedRateLimitQuota, 'uuid', '')) {
      const newFormErrors = formErrors;
      if (!orgName || !isOrgNameUnique) {
        newFormErrors.name = true;
      }
      if (!get(selectedRateLimitQuota, 'uuid', '')) {
        newFormErrors.rateLimitQuota = true;
      }
      setFormErrors(newFormErrors);
      return false;
    }
    return true;
  };

  const onSave = () => {
    if (!validateForm()) {
      setIsSaveDisabled(true);
      return;
    }
    const payload = {
      accountPlanUuid: selectedRateLimitQuota.uuid,
      description: orgDescription,
      name: orgName,
      type: selectedOrgType,
      ...(isEditMode ? { status: orgDetails.status } : {}),
      ...((orgUuid && { orgUuid }) || { orgUuid: '{{GENERATED_GUID}}' }),
    };
    if (isEditMode) {
      props.updateOrganization(payload);
    } else {
      props.createOrganization(payload);
    }
  };

  const onCancel = () => {
    if (hasUnsavedChanges) {
      setIsDialogOpen(true);
    } else {
      window.history.back();
    }
  };

  return (<EditContainer
    editPageId="organization-overview-page"
    dialogId="organization-unsaved-dialog"
    isDialogOpen={isDialogOpen}
    onDialogClose={onDialogClose}
    dialogSubmitText={intl.getI18nMessage('label.dialog.unsaved.changes.exit')}
    onDialogSubmit={onDialogSubmit}
    isLoading={isLoading}
    leftSidebarId="organization-edit-left-sidebar"
    mainContentId="organization-edit-main-content"
    notificationId="organization-notifications"
    notificationStatus={notificationStatus}
    setNotificationStatus={setNotificationStatus}
    notificationMessage={notificationMessage}
    setNotificationMessage={setNotificationMessage}
    tabsContainer={(<div />)}
    pageContent={createPageContent()({
      rateLimitQuotas,
      rateLimitQuotasPage,
      handleBlur,
      handleChange,
      orgDescription,
      orgName,
      selectedRateLimitQuota,
      isRateLimitQuotaLoad,
      selectedOrgType,
      onSave,
      onCancel,
      isSaveDisabled,
      intl,
      formErrors,
      classes,
    })}
    rightSidebarId={'organization-edit-right-sidebar'}
    helpItems={getHelpItems()}
    classes={classes}
  />);
};

OrganizationOverview.propTypes = {
  classes: PropTypes.object,
  isEditMode: PropTypes.bool,
  orgUuid: PropTypes.string,
  isLoading: PropTypes.bool,
  orgDetails: PropTypes.object,
  accountPlans: PropTypes.arrayOf(PropTypes.object),
  isFormLoadingError: PropTypes.bool,
  isFormValidationError: PropTypes.bool,
  history: PropTypes.object,
  isOrgNameUnique: PropTypes.bool,
  isCreateError: PropTypes.bool,
  isUpdateError: PropTypes.bool,
  isFormSubmissionSuccessful: PropTypes.bool,
  createErrorMsg: PropTypes.string,
  updateErrorMsg: PropTypes.string,
  userContext: PropTypes.object,
  resetApp: PropTypes.func,
  fetchRateLimitQuotas: PropTypes.func,
  push: PropTypes.func,
  fetchOrganization: PropTypes.func,
  fetchOrganizationNameUnique: PropTypes.func,
  updateOrganization: PropTypes.func,
  createOrganization: PropTypes.func,
  rateLimitQuotas:PropTypes.arrayOf(PropTypes.object),
  rateLimitQuotasPage: PropTypes.object,
  isRateLimitQuotaLoad: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  orgDetails: getOrganizationDetails(state),
  isLoading: getIsFormLoading(state, props.isEditMode),
  isFormLoadingError: getIsFormLoadingError(state),
  isFormValidationError: getIsFormValidationError(state),
  isOrgNameUnique: getIsOrganizationNameUnique(state),
  isCreateError: getIsFormCreateError(state),
  isUpdateError: getIsFormUpdateError(state),
  updateErrorMsg: getUpdateErrorMsg(state),
  createErrorMsg: getCreateErrorMsg(state),
  isFormSubmissionSuccessful: getIsFormSubmissionSuccessful(state),
  userContext: getUserDetails(state),
  rateLimitQuotasPage: getRateLimitQuotasPage(state),
  rateLimitQuotas: getRateLimitQuotas(state),
});

const mapDispatchToProps = {
  fetchOrganization,
  fetchRateLimitQuotas,
  fetchOrganizationNameUnique,
  createOrganization,
  updateOrganization,
  resetApp,
  push,
};

const getOrgUuid = (props) => get(props, 'match.params.orgUuid', '');

const addProps = (props) => ({
  orgUuid: getOrgUuid(props),
  isEditMode: !!getOrgUuid(props),
});

export default compose(
  withStyles(styles),
  withProps(addProps),
  connect(mapStateToProps, mapDispatchToProps),
)(OrganizationOverview);
