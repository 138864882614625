import { get } from 'lodash';
import { convertHexToRGB } from '../../../../utils';

const styles = ({ color, typography, fontSize }) => ({
  appDeploymentsContainer: {
    padding: '0 36px',
    '& table': {
      backgroundColor: get(color, 'uiBackground'),
    },
    '& tr': {
      borderTop: `1px solid ${get(color, 'borderColor')}`,
    },
    '& th': {
      color: get(color, 'primaryButtonBackground'),
      padding: 12,
      cursor: 'pointer',
      border: 'none',
    },
    '& td': {
      padding: 12,
      border: 'none',
    },
  },
  expandCollapseButton: {
    color: get(color, 'primaryButtonBackground'),
    float: 'right',
  },
  deploymentCard: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  card: {
    margin: 5,
    marginLeft: 0,
    minHeight: 160,
    backgroundColor: get(color, 'uiBackground'),
  },
  cardTitle: {
    fontSize: get(fontSize, 'smallTitle'),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: '5px 0px',
  },
  cardType: {
    color: get(color, 'bodyText'),
    marginTop: 5,
    marginBottom: 5,
    verticalAlign: 'text-bottom',
    '& span': {
      verticalAlign: 'bottom',
      color: get(color, 'accentDark'),
      fontFamily: get(typography, 'bodyText'),
      margin: 'auto 0',
    },
  },
  cardStatus: {
    color: get(color, 'bodyText'),
    marginTop: 5,
    marginBottom: 5,
  },
  cardStatusIcon: {
    fontSize: 12,
  },
  cardLastUpdated: {
    color: get(color, 'smallText'),
    marginBottom: 10,
  },
  helpIcon: {
    fontSize: 12,
    margin: 4,
    verticalAlign: 'inherit',
    cursor: 'pointer',
    color: `${get(color, 'link')} !important`,
  },
  helpTextContainer: {
    '& ui': {
      padding: 0,
      listStyleType: 'none',
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
  linkWrapper: {
    '& a': {
      cursor: 'pointer',
      border: 'none !important',
      margin: '0px !important',
      padding: '0px !important',
      height: '0px !important',
      fontWeight: '300 !important',
      color: `${get(color, 'link')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: `${get(color, 'linkHover')} !important`,
      textDecoration: 'none',
    },
    '& a:focus': {
      boxShadow: 'none !important',
      outline: '0px !important',
      textDecoration: 'none',
    },
  },
  actionLink: {
    margin: 10,
  },
  deploymentButtonsContainer: {
    margin: 5,
    '& button:first-of-type': {
      paddingLeft: '10px !important',
    },
    '& button': {
      border: 'none !important',
      margin: '0px !important',
      padding: '0px !important',
      paddingRight: '10px !important',
      height: '0px !important',
      lineHeight: '1 !important',
      fontWeight: '300 !important',
      color: `${get(color, 'link')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      verticalAlign: 'initial',
      textTransform: 'none',
      textDecoration: 'none',
    },
    '& button:hover': {
      color: `${get(color, 'linkHover')} !important`,
      textTransform: 'none',
      textDecoration: 'none',
    },
    '& button:focus': {
      boxShadow: 'none !important',
      outline: '0px !important',
      textTransform: 'none',
      textDecoration: 'none',
    },
  },
  checkStatusContainer: {
    margin: -5,
  },
  checkStatusButtonContainer: {
    display: 'block',
  },
  emptyResults: {
    marginLeft: 10,
    marginRight: 10,
  },
  tableHead: {
    '& th': {
      color: `${get(color, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontWeight: 'bold !important',
      cursor: 'auto',
    },
  },
  paginationContainer: {
    margin: '0 !important',
    '& .MuiTablePagination-toolbar': {
      margin: '0 !important',
    },
  },
  defaultChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'bodyText'), 0.05)} !important`,
    color: `${get(color, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  enabledChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'success'))} !important`,
    color: `${get(color, 'white')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  disabledChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'error'))} !important`,
    color: `${get(color, 'white')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  editKeyButton: {
    color: `${get(color, 'grayDark')} !important`,
    textTransform: 'none',
    float: 'right',
  },
});

export default styles;
