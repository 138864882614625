export const jsonParseAsync = (jsonString) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(JSON.parse(jsonString))
    })
  })
};

const jsonParse = (jsonString, exceptionValue) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return exceptionValue;
  }
};

export default jsonParse;
