import React, { useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/AddCircleRounded';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import styles from './styles';

export default function FormComboCheckBox(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { id, data, onSelect, selectedvalues, inputLabel = '', getOptionDisabled = '' } = props;
  const getOptionLabel = (option) => get(option, 'name');
  const getOptionSelected = (option, value) => (value.name === option.name);

  const [highlightedOption, setHighlightedOption] = useState(null);
  const onChange = (e) => {
    if (e.key === 'Enter') { onSelect(highlightedOption); }
  };

  return (
    <div id={id} data-apim-test={id}>
      <Autocomplete
        multiple
        options={data}
        id={`${id}-combo-checkbox`}
        data-apim-test={`${id}-combo-checkbox`}
        classes={{
          root: classes.root,
          tag: classes.tag,
          input: classes.input,
          focused: classes.focused,
          inputRoot: classes.inputRoot,
          listbox: classes.listbox,
          clearIndicator: classes.clearIndicator,
          option: classes.optionListContainer,
        }}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        getOptionDisabled={getOptionDisabled}
        value={selectedvalues}
        onHighlightChange={(e, option) => setHighlightedOption(option)}
        onChange={onChange}
        renderOption={(option) => (
          <div
            onClick={() => onSelect(option)}
            className={classes.optionContainer}
          >
            <AddIcon
              fontSize="small"
              className={classes.buttonContainer}
            />
            <div className={classes.optionName}>{option.name}</div>
          </div>
        )}
        className={classes.container}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={inputLabel}
            placeholder={inputLabel}
            fullWidth
          />
        )}
      />
    </div>
  );
}

FormComboCheckBox.propTypes = {
  id: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func,
  selectedvalues: PropTypes.arrayOf(PropTypes.shape({})),
  inputLabel: PropTypes.string,
  getOptionDisabled: PropTypes.func,
};
