import React from 'react';
import PropTypes, { shape } from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import cx from 'classnames';
import styles from './styles';

import { getConfig } from "../../../../reducers/portalConfig";
import { getI18nFormattedMessage } from "../../../../utils/intl";

const handleDownloadAssets = ({ apiUuid, tenantId }) => {
  // custom function as downloadjs doesn't work properly for urls
  const link = document.createElement('a');
  link.href = `/api/${tenantId}/api-management/1.0/apis/${apiUuid}/assets/archive`;
  // Add the element to the DOM
  link.setAttribute('type', 'hidden');
  document.body.appendChild(link);
  link.click();
}

export const ApiAssets = (props) => {
  const { api, apiAssets, config, classes } = props;
  const downloadButtonText = api.apiServiceType === 'REST'
    ? getI18nFormattedMessage('label.api.asset.swagger.download')
    : getI18nFormattedMessage('label.api.asset.download');
  const isGatewayPublishedSoapApi = (api && api.apiServiceType === 'SOAP'
    && !api.publishedByPortal);
  let assetsSection;
  const apiUuid = api.uuid;
  const tenantId = config.portal.tenantPrefix;

  function getAssetLinks(apiAsset) {
    return _.map(apiAsset.links, (assetLink) => (
      <>
      <div className={classes.assetLinkStyle}>
          <Typography
            className={cx('body1', classes.truncateText)}>
            {(isGatewayPublishedSoapApi ? (`${assetLink.href}`)
              : (`${window.location.origin}${assetLink.href}`))}
          </Typography>
          <a
            className={cx(classes.noTextUnderline, classes.assetLinkStyle,
              classes.assetLinkText)}
            href={(isGatewayPublishedSoapApi ? (`${assetLink.href}`)
              : (`/api/${tenantId}${assetLink.href}`))}
          >
            <span>Download</span>
          </a>
      </div>
      </>
    ));
  }

  if (_.isEmpty(apiAssets)) {
    assetsSection = (
      <Typography classes={cx(classes.assetText, classes.truncateText)}>
        {getI18nFormattedMessage('label.api.no.assets')}
      </Typography>
    );
  } else {
    assetsSection = [
      _.map(apiAssets,
        (apiAsset) => (
          <li key={apiAsset.uuid}>
            <Typography variant={'h5'}>
              {apiAsset.name}
            </Typography>
            {getAssetLinks(apiAsset)}
          </li>),
      ),
      _.size(apiAssets) > 1 ?
        <Button key="apiAssetsButton" className={classes.downloadButton}
                onClick={() => handleDownloadAssets({ apiUuid, tenantId })}>
          {downloadButtonText}
        </Button> : null];
  }
  return (
    <>
      <Typography variant="h4">{getI18nFormattedMessage(
        'label.assets')}</Typography>
      <ul>
      {assetsSection}
      </ul>
    </>
  );
}

const mapStateToProps = state => ({
  config: getConfig(state),
});

ApiAssets.propTypes = {
  config: PropTypes.object,
  api: PropTypes.object,
  apiAssets: PropTypes.arrayOf(PropTypes.object),
  tenantId: String,
  classes: shape({}),
};
ApiAssets.displayName = 'ApiAssets';

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(ApiAssets);
