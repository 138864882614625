import { get, merge } from 'lodash';

import constants from './constants';
import mapping from './mapping';

const {
  borderRadius,
  boxShadow,
  color,
  fontStyle,
  fontWeight,
  height,
  letterSpacing,
  lineHeight,
  margin,
  padding,
  position,
  textTransform,
} = constants;

// Material UI Components
export default ({ themes }) => {
  const themeMapping = mapping({ themes });
  return merge(
    {},
    themeMapping,
    {
      palette: {
        primary: {
          light: '#000000',
          main: '#ffffff',
          contrastText: '#000000',
        },
        secondary: {
          light: '#ffffff',
          main: get(themes, 'color.primaryButtonBackground'),
          contrastText: '#ffffff',
        },
        text: {
          primary: get(themes, 'color.primaryButtonText'),
        },
      },
      typography: {
        ...(themes.typography),
        fontFamily: get(themes, 'typography.bodyText'),
        fontSize: parseInt(get(themes, 'fontSize.bodyText'), 10),
        body1: {
          fontFamily: get(themes, 'typography.bodyText'),
          fontSize: parseInt(get(themes, 'fontSize.bodyText'), 10),
        },
      },
      props: {
        MuiButtonBase: {
          disableRipple: true, /* Disable Ripple Effect */
        },
      },
      overrides: {
        MuiAutocomplete: {
          input: {
            color: get(themes, 'color.bodyText'),
            fontSize: get(themes, 'fontSize.bodyText'),
          },
          listbox: {
            color: get(themes, 'color.bodyText'),
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: themeMapping.color.overlayBackground,
          },
        },
        MuiButton: {
          root: {
            borderRadius: borderRadius.br3,
            boxShadow: `${boxShadow.none} !important`,
            fontFamily: get(themes, 'typography.buttonText'),
            fontSize: get(themes, 'fontSize.buttonText'),
            height: height.h40,
            lineHeight: lineHeight.lh32,
            padding: `${padding.p8} ${padding.p12}`,
            textTransform: textTransform.none,
            '&$disabled': {
              backgroundColor: `${themeMapping.color.disabledBackground} !important`,
              border: `1px solid ${themeMapping.color.disabledBackground} !important`,
              color: `${themeMapping.color.disabledText} !important`,
            },
          },
          // Secondary Button
          outlinedPrimary: {
            backgroundColor: get(themes, 'color.primaryButtonText'),
            border: `1px solid ${get(themes, 'color.primaryButtonBackground')}`,
            color: get(themes, 'color.primaryButtonBackground'),
            '&:hover': {
              backgroundColor: `${themeMapping.color.secondaryButtonHover} !important`,
              borderColor: get(themes, 'color.primaryButtonBackground'),
              color: get(themes, 'color.primaryButtonBackground'),
            },
          },
          // Primary Button
          containedSecondary: {
            backgroundColor: get(themes, 'color.primaryButtonBackground'),
            border: `1px solid ${get(themes, 'color.primaryButtonBackground')}`,
            color: get(themes, 'color.primaryButtonText'),
            '&:hover': {
              backgroundColor: get(themes, 'color.primaryButtonHover'),
              borderColor: get(themes, 'color.primaryButtonHover'),
              color: get(themes, 'color.primaryButtonText'),
            },
          },
          textPrimary: {
            color: get(themes, 'color.primaryButtonBackground'),
            '&:hover': {
              color: get(themes, 'color.primaryButtonBackground'),
            },
          },
        },
        MuiChip: {
          clickable: {
            border: `1px solid ${get(themes, 'color.link')} !important`,
            borderRadius: borderRadius.br10,
            color: `${get(themes, 'color.link')} !important`,
          },
          deleteIcon: {
            color: get(themes, 'color.link'),
            '&:hover': {
              color: get(themes, 'color.link'),
            },
          },
          outlined: {
            border: `1px solid ${get(themes, 'color.bodyText')}`,
            borderRadius: borderRadius.br10,
            color: get(themes, 'color.bodyText'),
          },
        },
        MuiDialogActions: {
          root: {
            padding: `${padding.p16} ${padding.p24}`,
          },
        },
        MuiFormLabel: {
          root: {
            color: get(themes, 'color.bodyText'),
            fontFamily: get(themes, 'typography.bodyText'),
            fontSize: get(themes, 'fontSize.bodyText'),
            fontWeight: fontWeight.semiBold,
            lineHeight: lineHeight.lh21,
            paddingLeft: 0,
            float: 'left',
          },
        },
        MuiIconButton: {
          root: {
            color: get(themes, 'color.primaryButtonBackground'),
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          colorPrimary: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          colorSecondary: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        MuiInputBase: {
          root: {
            backgroundColor: get(themes, 'color.uiBackground'),
            borderRadius: borderRadius.br3,
            color: get(themes, 'color.bodyText'),
            fontFamily: get(themes, 'typography.bodyText'),
            fontSize: get(themes, 'fontSize.bodyText'),
            '&:focus': {
              outline: `1px solid ${get(themes, 'color.primaryButtonBackground')}`,
              borderColor: get(themes, 'color.primaryButtonBackground'),
            },
            '&:hover': {
              borderColor: get(themes, 'color.primaryButtonHover'),
            },
          },
        },
        MuiLink: {
          root: {
            color: get(themes, 'color.link'),
            fontWeight: fontWeight.bold,
          },
        },
        MuiListItem: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          },
          button: {
            '&:hover': {
              backgroundColor: `${themeMapping.color.lightHover} !important`,
            },
          },
        },
        MuiListItemText: {
          dense: {
            '& > span': {
              fontSize: get(themes, 'fontSize.bodyText'),
            },
          },
        },
        MuiMenuItem: {
          root: {
            color: get(themes, 'color.bodyText'),
            fontFamily: get(themes, 'typography.bodyText'),
            fontSize: get(themes, 'fontSize.bodyText'),
          },
        },
        MuiPaginationItem: {
          root: {
            backgroundColor: get(themes, 'color.uiBackground'),
            color: get(themes, 'color.primaryButtonBackground'),
            fontFamily: get(themes, 'typography.bodyText'),
            fontSize: get(themes, 'fontSize.bodyText'),
          },
        },
        MuiSelect: {
          icon: {
            color: get(themes, 'color.primaryButtonBackground'),
            right: margin.m5,
          },
        },
        MuiSvgIcon: {
          colorPrimary: {
            cursor: 'pointer',
            color: get(themes, 'color.primaryButtonBackground'),
            '&:hover': {
              color: get(themes, 'color.primaryButtonHover'),
            },
          },
        },
        MuiTableCell: {
          body: {
            color: get(themes, 'color.bodyText'),
          },
          head: {
            color: get(themes, 'color.bodyText'),
            fontWeight: fontWeight.bold,
            lineHeight: lineHeight.lh21,
          },
          root: {
            backgroundColor: get(themes, 'color.uiBackground'),
            color: get(themes, 'color.bodyText'),
            fontFamily: get(themes, 'typography.bodyText'),
            fontSize: get(themes, 'fontSize.bodyText'),
            fontWeight: fontWeight.normal,
            lineHeight: lineHeight.lh21,
            padding: `${padding.p10} ${padding.p16}`,
            minWidth: '120px',
            verticalAlign: 'unset',
            wordBreak: 'break-word',
          },
          sizeSmall: {
            minWidth: 0,
            padding: `${padding.p6} ${padding.p12}`,
          },
          stickyHeader: {
            backgroundColor: get(themes, 'color.uiBackground'),
          },
          paddingCheckbox: {
            minWidth: '48px',
          },
        },
        MuiTooltip: {
          arrow: {
            color: color.tooltipBackground,
          },
          tooltip: {
            backgroundColor: color.tooltipBackground,
            borderRadius: borderRadius.br3,
            color: color.tooltipText,
            fontSize: get(themes, 'fontSize.bodyText'),
            fontFamily: get(themes, 'typography.bodyText'),
            lineHeight: lineHeight.lh21,
            padding: `${padding.p8} ${padding.p12}`,
            margin: `${position.p3} !important`,
          },
        },
        MuiTypography: {
          root: {
            color: get(themes, 'color.bodyText'),
            fontFamily: get(themes, 'typography.bodyText'),
            fontSize: get(themes, 'fontSize.bodyText'),
            fontStyle: fontStyle.normal,
            fontWeight: fontWeight.normal,
            lineHeight: lineHeight.lh21,
          },
          body1: {
            color: get(themes, 'color.bodyText'),
            fontFamily: get(themes, 'typography.bodyText'),
            fontSize: get(themes, 'fontSize.bodyText'),
            fontStyle: fontStyle.normal,
            fontWeight: fontWeight.normal,
            lineHeight: lineHeight.lh21,
            minHeight: lineHeight.lh21,
          },
          body2: {
            color: get(themes, 'color.smallText'),
            fontFamily: get(themes, 'typography.smallText'),
            fontSize: get(themes, 'fontSize.smallText'),
            fontStyle: fontStyle.normal,
            fontWeight: fontWeight.normal,
            letterSpacing: letterSpacing.ls01,
            lineHeight: lineHeight.lh14,
            minHeight: lineHeight.lh14,
          },
          colorError: {
            color: get(themes, 'color.error'),
          },
          h1: {
            color: get(themes, 'color.pageTitle'),
            fontFamily: get(themes, 'typography.pageTitle'),
            fontSize: get(themes, 'fontSize.pageTitle'),
            fontStyle: fontStyle.normal,
            fontWeight: fontWeight.bold,
            lineHeight: lineHeight.lh48,
            marginBottom: margin.m24,
            marginTop: margin.m36,
            minHeight: lineHeight.lh48,
            textTransform: textTransform.none,
          },
          h2: {
            color: get(themes, 'color.pageTitle'),
            fontFamily: get(themes, 'typography.pageTitle'),
            fontSize: get(themes, 'fontSize.sectionTitle'),
            fontStyle: fontStyle.normal,
            fontWeight: fontWeight.bold,
            lineHeight: lineHeight.lh36,
            marginBottom: margin.m12,
            marginTop: margin.m24,
            minHeight: lineHeight.lh36,
            textTransform: textTransform.none,
          },
          h3: {
            color: get(themes, 'color.pageTitle'),
            fontFamily: get(themes, 'typography.pageTitle'),
            fontSize: get(themes, 'fontSize.title'),
            fontStyle: fontStyle.normal,
            fontWeight: fontWeight.bold,
            lineHeight: lineHeight.lh28,
            marginBottom: margin.m12,
            marginTop: margin.m24,
            minHeight: lineHeight.lh28,
            textTransform: textTransform.none,
          },
          h4: {
            color: get(themes, 'color.pageTitle'),
            fontFamily: get(themes, 'typography.pageTitle'),
            fontSize: get(themes, 'fontSize.smallTitle'),
            fontStyle: fontStyle.normal,
            fontWeight: fontWeight.bold,
            lineHeight: lineHeight.lh28,
            marginBottom: margin.m12,
            marginTop: margin.m24,
            minHeight: lineHeight.lh28,
            textTransform: textTransform.none,
          },
          h5: {
            color: get(themes, 'color.pageTitle'),
            fontFamily: get(themes, 'typography.pageTitle'),
            fontSize: get(themes, 'fontSize.label'),
            fontStyle: fontStyle.normal,
            fontWeight: fontWeight.bold,
            lineHeight: lineHeight.lh14,
            marginBottom: 0,
            marginTop: 0,
            minHeight: lineHeight.lh14,
            textTransform: textTransform.uppercase,
          },
          h6: {
            color: get(themes, 'color.pageTitle'),
            fontFamily: get(themes, 'typography.pageTitle'),
            fontSize: get(themes, 'fontSize.smallLabel'),
            fontStyle: fontStyle.normal,
            fontWeight: fontWeight.bold,
            lineHeight: lineHeight.lh14,
            marginBottom: 0,
            marginTop: 0,
            minHeight: lineHeight.lh14,
            textTransform: textTransform.uppercase,
          },
        },
      },
    },
    themes,
  );
};
