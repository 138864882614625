import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  withStyles,
  Grid,
} from '@material-ui/core';
import { isArray, includes, some } from 'lodash';
import PropTypes from 'prop-types';

import {
  FileDropzone,
  HelpContainer,
  AlertMessages,
  FormActionButtons,
  Loading,
  ErrorContainer,
} from '../../../components';
import { saveBundleAssets } from '../../../actions/gatewayBundle';
import { getUserDetails } from '../../../reducers/portalConfig';
import {
  getIsLoading,
  getIsError,
  getErrors,
  getIsBundleSaveSuccess,
  getGatewayBundleDetails,
} from '../../../reducers/gatewayBundle';
import { getBundlesExtension } from '../../../utils';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import {
  HELP_ITEMS,
  BUNDLE_FILE_TYPE,
  ALLOWED_FILE_EXTENSIONS,
  YML_FILE_EXT,
  BUNDLE_FILE_EXT,
  DELETE_BUNDLE_FILE_EXT,
  MAX_FILE_SIZE,
  MIME_TYPES,
} from './constants';
import {
  ALERT_ERROR,
} from '../../../constants';
import styles from './styles';

export const GatewayBundleEdit = (props) => {
  const {
    classes,
    userContext,
    assets = [],
    isLoading,
    bundleErrors,
    isError,
    isBundleSaveSuccess,
    gatewayBundleDetails,
   } = props;

  const intl = getI18n(useIntl());
  const [bundleFiles, setBundleFiles] = useState({
    files: assets,
    acceptFileTypes: BUNDLE_FILE_TYPE,
    maxFiles: 3,
    error: false,
    helperText: getI18nFormattedMessage('label.gateway.bundle.file.help'),
  });
  const [isFileValidation, setIsFileValidation] = useState(false);
  const [fileValidationMessage, setFileValidationMessage] = useState('');
  const [errors, setErrors] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  localStorage.setItem('isSaveBundleAssets', false);

  const notifyMessages = (message, status) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  const clearErrorMessages = () => {
    setBundleFiles({ ...bundleFiles, error: false });
    setIsFileValidation(false);
    notifyMessages('', '');
    setErrors([]);
  };

  useEffect(() => {
    if (!userContext.portalAdmin) { props.push('/404'); }
  }, [userContext]);

  useEffect(() => {
    if (isError) {
      notifyMessages(intl.getI18nMessage('error.title'), ALERT_ERROR);
      setErrors(bundleErrors);
    } else {
      clearErrorMessages();
    }
  }, [isError, bundleErrors]);

  useEffect(() => {
    if (isBundleSaveSuccess) {
      clearErrorMessages();
      localStorage.setItem('isSaveBundleAssets', true);
      props.push(`/publish/gateway-bundles/details/${gatewayBundleDetails.uuid}`);
    }
  }, [isBundleSaveSuccess, gatewayBundleDetails]);

  const hasValidateFile = (file) => {
    const extension = getBundlesExtension(file.name, ALLOWED_FILE_EXTENSIONS);
    if (!includes(ALLOWED_FILE_EXTENSIONS, extension)) {
      bundleFiles.error = true;
      return false;
    }

    if (file.size > MAX_FILE_SIZE) {
      bundleFiles.error = true;
      return false;
    }
    if (some(bundleFiles.files, { name: file.name })) {
      setIsFileValidation(true);
      setFileValidationMessage(getI18nFormattedMessage('error.gateway.bundle.file.name.unique'));
      return false;
    }
    if (bundleFiles.files.length === 3) {
      setIsFileValidation(true);
      setFileValidationMessage(getI18nFormattedMessage('error.gateway.bundle.file.count'));
      return false;
    }
    const filterYmlFiles = bundleFiles.files.filter((obj) =>
      obj.ext === YML_FILE_EXT);
    if (filterYmlFiles.length === 1 && extension === YML_FILE_EXT) {
      setIsFileValidation(true);
      setFileValidationMessage(getI18nFormattedMessage('error.gateway.bundle.file.metadata.yml.count'));
      return false;
    }
    const filterBundleFiles = bundleFiles.files.filter((obj) =>
      obj.ext === BUNDLE_FILE_EXT);
    if (filterBundleFiles.length === 1 && extension === BUNDLE_FILE_EXT) {
      setIsFileValidation(true);
      setFileValidationMessage(getI18nFormattedMessage('error.gateway.bundle.file.bundle.count'));
      return false;
    }

    const filterDeleteBundleFiles = bundleFiles.files.filter((obj) =>
      obj.ext === DELETE_BUNDLE_FILE_EXT);
    if (filterDeleteBundleFiles.length === 1 && extension === DELETE_BUNDLE_FILE_EXT) {
      setIsFileValidation(true);
      setFileValidationMessage(getI18nFormattedMessage('error.gateway.bundle.file.delete.bundle.count'));
      return false;
    }
    setIsFileValidation(false);
    return true;
  };

  const fileLoad = (file) => {
    const reader = new FileReader();
    reader.onabort = () => {
      setBundleFiles({ ...bundleFiles, error: true });
    };
    reader.onerror = () => {
      setBundleFiles({ ...bundleFiles, error: true });
    };
    reader.onload = () => {
      if (hasValidateFile(file)) {
        bundleFiles.error = false;
        const fileContent = reader.result;
        const fileExtenstion = getBundlesExtension(file.name, ALLOWED_FILE_EXTENSIONS);
        const fileData = file;
        fileData.ext = fileExtenstion;
        fileData.blob = new Blob(
          [fileContent],
          { type: MIME_TYPES[fileExtenstion] },
        );
        bundleFiles.files.push(fileData);
        setBundleFiles({ ...bundleFiles });
      } else {
        setBundleFiles({ ...bundleFiles });
      }
    };
    reader.readAsText(file);
  };

  const handleFiles = (files) => {
    clearErrorMessages();
    if (isArray(files)) {
      files.forEach((file) => {
        fileLoad(file);
      });
    } else {
      fileLoad(files);
    }
  };

  const onRemoveFile = (file) => {
    clearErrorMessages();
    const remainBundleFiles = bundleFiles.files.filter((obj) => obj.name !== file.name);
    setBundleFiles({ ...bundleFiles, files: remainBundleFiles });
  };

  const onCancel = () => {
    clearErrorMessages();
    window.location.href = '/publish/gateway-bundles/';
  };

  const hasValidSaveButton = (selectedFiles) => {
    if (selectedFiles.files.length !== 3) {
      return true;
    }
    const filterYmlFiles = selectedFiles.files.filter((obj) =>
      obj.ext === YML_FILE_EXT);
    const filterBundleFiles = selectedFiles.files.filter((obj) =>
      obj.ext === BUNDLE_FILE_EXT);
    const filterDeleteBundleFiles = bundleFiles.files.filter((obj) =>
      obj.ext === DELETE_BUNDLE_FILE_EXT);
    if (filterYmlFiles.length === 1 && filterBundleFiles.length === 1
      && filterDeleteBundleFiles.length === 1) {
      return false;
    }
    return true;
  };

  const onNext = async () => {
    clearErrorMessages();
    if (hasValidSaveButton(bundleFiles)) {
      setBundleFiles({ ...bundleFiles, error: true });
      setNotificationStatus('error');
      setNotificationMessage(getI18nFormattedMessage('error.gateway.bundle.file.size'));
    } else {
      await props.saveBundleAssets(bundleFiles.files);
    }
  };

  return (
    <div
      className={classes.content}
      id="gateway-bundle-edit-page"
      data-apim-test="gateway-bundle-edit-page"
    >
      {isLoading && <Loading pageLoader /> }
      <Grid container className={classes.gridContainer}>
        <Grid
          md={2}
          item
          className={classes.leftSideBar}
          id="gateway-bundle-edit-left-sidebar"
          data-apim-test="gateway-bundle-edit-left-sidebar"
        />
        <Grid
          md={7}
          item
          className={classes.mainContent}
          id="gateway-bundle-edit-main-content"
          data-apim-test="gateway-bundle-edit-main-content"
        >
          {notificationMessage &&
            <AlertMessages
              id="gateway-bundle-edit-notification"
              data-apim-test="gateway-bundle-edit-notification"
              message={notificationMessage}
              variant={notificationStatus}
              onClose={() => {
                setNotificationMessage('');
                setNotificationStatus('');
              }}
            />
          }
          {errors.length > 0 &&
            <ErrorContainer errors={errors} />
          }
          <h1 className={classes.pageTitle}> Add Gateway Bundles</h1>
          <FileDropzone
            handleFiles={handleFiles}
            selectedFiles={bundleFiles}
            helperText={''}
            title={''}
            id={'gateway-bundle-edit-file-upload'}
            allowedFileExtentions={ALLOWED_FILE_EXTENSIONS}
            listFileTitles={[
              intl.getI18nMessage('label.gateway.bundle.selected.file.metadata.yml.title'),
              intl.getI18nMessage('label.gateway.bundle.selected.file.bundle.title'),
              intl.getI18nMessage('label.gateway.bundle.selected.file.delete.bundle.title'),
            ]}
            onRemoveFile={onRemoveFile}
            isValidation={isFileValidation}
            validationMessage={fileValidationMessage}
            defaultUploaderText={intl.getI18nMessage('label.gateway.bundle.file.uploader')}
            isMultipleTitle
          />
        </Grid>
        <Grid
          md={3}
          item
          className={classes.rightSideBar}
          id="gateway-bundle-edit-right-sidebar"
          data-apim-test="gateway-bundle-edit-right-sidebar"
        >
          <HelpContainer
            helpItems={HELP_ITEMS.bundleHelp}
          />
        </Grid>
      </Grid>
      <FormActionButtons
        onNextClick={onNext}
        onCancelClick={onCancel}
        nextText={intl.getI18nMessage('label.save.button')}
        cancelText={intl.getI18nMessage('label.exit.button')}
        id="bundle-form-buttons"
      />
    </div>
  );
};

const mapStateToProps = state => ({
  isError: getIsError(state),
  isLoading: getIsLoading(state),
  bundleErrors: getErrors(state),
  isBundleSaveSuccess: getIsBundleSaveSuccess(state),
  gatewayBundleDetails: getGatewayBundleDetails(state),
  userContext: getUserDetails(state),
});

const mapDispatchToProps = {
  saveBundleAssets,
  push,
};

GatewayBundleEdit.propTypes = {
  classes: PropTypes.object,
  assets: PropTypes.object,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isBundleSaveSuccess: PropTypes.bool,
  bundleErrors: PropTypes.arrayOf(PropTypes.object),
  saveBundleAssets: PropTypes.func,
  userContext: PropTypes.object,
  gatewayBundleDetails: PropTypes.object,
  push: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(GatewayBundleEdit);
