export const FILE_TYPE = '.zip';
export const FILE_HELPER_TEXT = 'Click or drag bundle to upload. See requirements for details.';
export const MAX_BUNDLE_SIZE_IN_MB = 30;
export const MAX_FILE_SIZE = MAX_BUNDLE_SIZE_IN_MB * 1024 * 1024;
export const PORTAL_DEFAULT_TEXT = 'Portal Default';
export const PageTypes = {
  HOME: 'HOME',
  LOGIN: 'LOGIN',
  REGISTRATION: 'REGISTRATION',
  ACCOUNT_SETUP: 'ACCOUNT_SETUP',
  REQUEST_RESET_PASSWORD: 'REQUEST_RESET_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
};

export const getFileExtension = (fileName) => {
  return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
};

export const getBundleName = (bundle) => {
  return bundle
    ? `${bundle.name}${
        bundle.status === 'published' ? ' - Published' : ''
      } - Uploaded: ${new Date(bundle.createTs).toString()}`
    : PORTAL_DEFAULT_TEXT;
};

export const getCustomPagePayload = (pageType, name) => {
  return {
    name: name,
    pageType: pageType,
    uri: `/pages/${name}`,
  };
};
