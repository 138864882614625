import { getI18nFormattedMessage } from '../../../utils/intl';

export const DEPLOYMENT_FILTER_BY_STATE = [
  {
    uuid: 'all',
    text: getI18nFormattedMessage('label.deployments.state.all'),
  },
  {
    uuid: 'DEPLOYED',
    text: getI18nFormattedMessage('label.deployments.state.deployed'),
  },
  {
    uuid: 'NOT_DEPLOYED',
    text: getI18nFormattedMessage('label.deployments.state.not.deployed'),
  },
  {
    uuid: 'PENDING_DEPLOYMENT',
    text: getI18nFormattedMessage('label.deployments.state.pending.deployment'),
  },
  {
    uuid: 'PENDING_UNDEPLOYMENT',
    text: getI18nFormattedMessage('label.deployments.state.pending.undeployment'),
  },
  {
    uuid: 'ERROR_UNDEPLOY',
    text: getI18nFormattedMessage('label.deployments.state.error.undeploy'),
  },
  {
    uuid: 'ERROR',
    text: getI18nFormattedMessage('label.deployments.state.error'),
  },
];

export const DEPLOYMENT_STATUS_LABEL_UNDEPLOYED = 'Not deployed';
export const DEPLOYMENT_ACTION_TYPE_DEPLOY = 'DEPLOY';
export const DEPLOYMENT_ACTION_TYPE_REDEPLOY = 'REDEPLOY';
export const DEPLOYMENT_ACTION_TYPE_UNDEPLOY = 'UNDEPLOY';
export const DEPLOYMENT_STATUS_ERROR = 'ERROR';
export const DEPLOYMENT_STATUS_UNDEPLOYED_ERROR = 'ERROR_UNDEPLOY';
export const DEPLOYMENT_STATUS_NOT_DEPLOYED = 'NOT_DEPLOYED';
export const DEPLOYMENT_STATUS_DEPLOYED = 'DEPLOYED';
