import axios from 'axios';
import { get } from 'lodash';
import * as constants from '../../constants';
import { getConfig, getUserContexts } from '../../reducers/portalConfig';
import { getValidationErrors } from '../../utils';

const fetchDashboardEntityCount = () => async (dispatch, getState) => {
  const config = getConfig(getState());
  const { portal } = config;
  const url = `${portal.hostname}/admin/navigation/internal/entity-counts`;

  await axios
    .get(url, { credentials: 'include' })
    .then(response => {
      dispatch({
        type: constants.DASHBOARD_GET_ENTITY_COUNT_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error =>
      dispatch({
        type: constants.DASHBOARD_GET_ENTITY_COUNT_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};

export const fetchDashboardChartData = (
  body: string,
  entity: string,
) => async (dispatch, getState) => {
  const config = getConfig(getState());
  const userContexts = getUserContexts(getState());
  const activeOrgUuid = get(userContexts, 'userContexts[0].activeOrgUuid');
  const { portal } = config;
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/analytics/metrics/v1/_query`;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(activeOrgUuid ? {
      'APIM-OrgUuid': activeOrgUuid,
    } : {}),
  };
  await axios
    .post(url, body, { headers }, { credentials: 'include' })
    .then(response => {
      dispatch({
        type: constants.DASHBOARD_CHART_DATA_SUCCESS,
        payload: response.data,
        entity,
      });
    })
    .catch(error =>
      dispatch({
        type: constants.DASHBOARD_CHART_DATA_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};

export default fetchDashboardEntityCount;
