import get from 'lodash/get';

const styles = ({ color }) => ({
    cardStatus: {
        color: get(color, 'bodyText'),
        marginTop: 5,
        marginBottom: 5,
    },
    cardStatusIcon: {
        fontSize: 12,
        marginRight: 5,
    },
    DEPLOYED: {
        color: '#306204',
    },
    ENABLED: {
        color: '#306204',
    },
    PENDING_DEPLOYMENT: {
        color: '#97B081',
    },
    PENDING_UNDEPLOYMENT: {
        color: '#97B081',
    },
    UNDEPLOYED: {
        color: '#D1D1D1',
    },
    DISABLED: {
        color: '#D1D1D1',
    },
    ERROR: {
        color: '#B30303',
    },
    ERROR_UNDEPLOY: {
        color: '#B30303',
    },
    UnPublished: {
        color: '#D1D1D1',
    },
    errorContent: {
        cursor: 'pointer',
        color: get(color, 'link'),
    },
    dialogAction: {
        display: 'block !important',
    },
    dialogSubmit: {
        color: `${get(color, 'link')} !important`,
        backgroundColor: 'transparent !important',
        float: 'left !important',
    },
    dialogCancel: {
        float: 'right !important',
        backgroundColor: `${get(color, 'link')} !important`,
        color: `${get(color, 'white')} !important`,
    },
});

export default styles;