import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

export default function HorizontalScrollBar(props) {
  const [showRightScrollBtn, setRightScrollBtnVisibility] = useState(false);
  const [showLeftScrollBtn, setLeftScrollBtnVisibility] = useState(false);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  function setScrollButtonVisibility() {
    const container = document.getElementById(`${props.scrollEntity}`);
    if (container.scrollWidth - container.offsetWidth > container.scrollLeft) {
      setRightScrollBtnVisibility(true);
    }
    if (
      container.scrollWidth - container.offsetWidth ===
      container.scrollLeft
    ) {
      setRightScrollBtnVisibility(false);
    }
    setLeftScrollBtnVisibility(container.scrollLeft > 0);
  }

  function sideScroll(elementId, direction, speed, distance, step) {
    const element = document.getElementById(elementId);
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (direction === 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
      setScrollButtonVisibility();
    }, speed);
  }

  const scrollRight = () => {
    sideScroll(`${props.scrollEntity}`, 'right', 25, 100, 10);
  };

  const scrollLeft = () => {
    sideScroll(`${props.scrollEntity}`, 'left', 25, 100, 10);
  };

  window.addEventListener('resize', setScrollButtonVisibility);

  useEffect(() => {
    setScrollButtonVisibility();
  });
  const rightScrollBtnClass = showRightScrollBtn
    ? classes.scrollBtn
    : classes.scrollBtn__hide;
  const leftScrollBtnClass = showLeftScrollBtn
    ? classes.leftscrollBtn
    : classes.scrollBtn__hide;

  return (
    <div className={classes.container} data-apim-test="hscroll-bar-container">
      <button className={leftScrollBtnClass} onClick={scrollLeft}>
        {'<'}
      </button>
      <div
        id={props.scrollEntity}
        data-apim-test="hscroll-bar-entity"
        className={classes.scrollBar}
      >
        {props.children}
      </div>
      <button className={rightScrollBtnClass} onClick={scrollRight}>
        {'>'}
      </button>
    </div>
  );
}

HorizontalScrollBar.propTypes = {
  children: PropTypes.node,
  scrollEntity: PropTypes.string,
};
