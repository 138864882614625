import React from 'react';
import { Grid } from '@material-ui/core';

import {
  ORGANIZATION_LIST_TYPE_FILTER_BY,
  ORGANIZATION_LIST_STATUS_FILTER_BY,
} from '../../../../constants';
import {
    FormSelect,
    FormTextField,
} from '../../../../components';

export const FilterByName = (props) => (
  <Grid item md={3} sm={3} xs={12}>
    <FormTextField
      {...props}
      id="rate-quota-orgs-filter-by-name"
      data-apim-test="rate-quota-orgs-filter-by-name"
    />
  </Grid>
);

export const FilterByType = (props) => (
  <Grid item md={3} sm={3} xs={12}>
    <FormSelect
      {...props}
      id="rate-quota-orgs-filter-by-type"
      data-apim-test="rate-quota-orgs-filter-by-type"
      data={ORGANIZATION_LIST_TYPE_FILTER_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterByStatus = (props) => (
  <Grid item md={3} sm={3} xs={12}>
    <FormSelect
      {...props}
      id="rate-quota-orgs-filter-by-status"
      data-apim-test="rate-quota-orgs-filter-by-status"
      data={ORGANIZATION_LIST_STATUS_FILTER_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterSeparator = () => (
  <Grid item md={3} />
);

