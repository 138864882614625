import React from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import {
  withStyles,
  Grid,
  Divider,
  Link,
  Button,
  Typography,
} from '@material-ui/core';
import { get, isUndefined } from 'lodash';
import { shape, number } from 'prop-types';

import styles from './styles';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import { getTitleCaseText } from '../../../../utils';
import getDateTime, { getTimeZoneAbbr } from '../../../../utils/datetime';
import { DetailsPanelContainer, Footer } from '../../../../components';

export const MetadataRaw = (props) => {
  const { classes, gatewayBundleDetails, config, apiUsage } = props;
  const {
    name,
    version,
    description,
    type,
    groupName,
    l7Template,
    createTs,
  } = gatewayBundleDetails;

  const l7TemplateText = !isUndefined(l7Template) ? l7Template.toString() : '';
  const apiCount = l7Template ? apiUsage : 'N/A';
  const link = get(gatewayBundleDetails, '_links.metadata.href');
  const hostname = get(config, 'portal.hostname');
  const tenantPrefix = get(config, 'portal.tenantPrefix');
  const url = `${hostname}/api/${tenantPrefix}${link}`;
  const fileName = `${name}-${version}.metadata.yml`;
  const dateUploaded = createTs && getDateTime(createTs);
  const intl = getI18n(useIntl());

  return (
    <div
      id="gateway-bundle-details-metadata-container"
      data-apim-test={'gateway-bundle-details-metadata-container'}
    >
      <Grid
        container
        id="metadata-top-panel-container"
        data-apim-test={'metadata-top-panel-container'}
        className={classes.topPanelContainer}
      >
        <Grid item md={5}>
          <DetailsPanelContainer
            id="metadata-type-container"
            title={intl.getI18nMessage('label.type')}
            subTitle={getTitleCaseText(type)}
          />
        </Grid>
        <Grid item md={2}>
          <DetailsPanelContainer
            id="metadata-reusable-container"
            title={intl.getI18nMessage('label.gateway.bundle.details.l7Template')}
            tooltip={intl.getI18nMessage('label.gateway.bundle.details.l7Template.tooltip')}
            subTitle={getTitleCaseText(l7TemplateText)}
          />
        </Grid>
        <Grid item md={2}>
          <DetailsPanelContainer
            id="metadata-apis-container"
            title={intl.getI18nMessage('label.apis')}
            tooltip={intl.getI18nMessage('label.gateway.bundle.details.apis.tooltip')}
            subTitle={apiCount.toString()}
          />
        </Grid>
        <Grid item md={3}>
          <DetailsPanelContainer
            id="metadata-date-uploaded-container"
            title={intl.getI18nMessage('label.gateway.bundle.date.created', { zone: getTimeZoneAbbr() })}
            subTitle={dateUploaded}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid
        container
        id="metadata-bottom-panel-container"
        data-apim-test={'metadata-bottom-panel-container'}
        className={classes.bottomPanelContainer}
      >
        <Grid
          item
          md={7}
          id="metadata-description-container"
          data-apim-test={'metadata-description-container'}
        >
          <Typography variant="h3">{getI18nFormattedMessage('label.group')}</Typography>
          <div className={classes.detailsSubTitle}>{groupName}</div>
          <Typography variant="h3">{getI18nFormattedMessage('label.description')}</Typography>
          <div className={classes.detailsSubTitle}>{description}</div>
        </Grid>
        <Grid item md={1} />
        <Grid item md={4}>
          <div
            className={classes.assestsContainer}
            id="metadata-assets-container"
            data-apim-test={'metadata-assets-container'}
          >
            <Typography variant="h3">{getI18nFormattedMessage('label.assets')}</Typography>
            <div className={classes.detailsSubTitle}>{getI18nFormattedMessage('label.assets.help')}</div>
            <div className={classes.fileName}>{fileName}</div>
            <div
              className={classes.linkContainer}
              id="metadata-assets-link-container"
              data-apim-test={'metadata-assets-link-container'}
            >
              <Link href={url}>
                <Button
                  id="download-button"
                  data-apim-test="metadata-assets-download-button"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                >
                  {getI18nFormattedMessage('label.download')}
                </Button>
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

MetadataRaw.propTypes = {
  classes: shape({}),
  gatewayBundleDetails: shape({}),
  config: shape({}),
  apiUsage: number,
};
MetadataRaw.displayName = 'Metadata';

export default compose(
  withStyles(styles),
)(MetadataRaw);

