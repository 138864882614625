import React from 'react';
import { useIntl } from 'react-intl';
import { bool, number, shape, string } from 'prop-types';
import cx from 'classnames';
import {
  Typography,
  withStyles,
} from '@material-ui/core';

import styles from './styles';
import { getI18n, getI18nFormattedMessage } from '../../utils/intl';
import {
  PROXY_ENROLMENT_STATUS_PENDING,
  PROXY_CONNECTED,
  PROXY_DISCONNECTED,
} from '../../constants';
import { pascalCase } from "pascal-case";
import { getStatusLabel } from "../../pages/Api/List/utils";
import { formatDateString } from "../../utils/datetime";

export const getStatusColor = (classes, status) => {
  switch (status) {
    case PROXY_ENROLMENT_STATUS_PENDING:
      return {
        statusLabel: getI18nFormattedMessage('label.proxy.details.page.status.pending.enrollment'),
        statusColorClass: classes.disabledGraphic,
      }
    case PROXY_CONNECTED:
      return {
        statusLabel: getI18nFormattedMessage('label.proxy.details.page.status.connected'),
        statusColorClass: classes.success,
      }
    case PROXY_DISCONNECTED:
      return {
        statusLabel: getI18nFormattedMessage('label.proxy.details.page.status.disconnected'),
        statusColorClass: classes.error,
      }
    case 'ENABLED':
      return {
        statusLabel: getStatusLabel(status),
        statusColorClass: classes.success,
      }
    case 'DISABLED':
      return {
        statusLabel: getStatusLabel(status),
        statusColorClass: classes.disabled,
      }
    case 'INCOMPLETE':
      return {
        statusLabel: getStatusLabel(status),
        statusColorClass: classes.error,
      }
    case 'DEPRECATED':
      return {
        statusLabel: getStatusLabel(status),
        statusColorClass: classes.deprecated,
      }
    case 'NEW':
      return {
        statusLabel: getStatusLabel(status),
        statusColorClass: classes.new,
      }
    default:
      return {
        statusLabel: '',
        statusColorClass: {},
      }
  }
}

export const StatusIndicator = (props) => {
  const {
    classes,
    inline,
    modifyTs,
    status,
    version,
    access,
  } = props;

  const { statusLabel, statusColorClass } = getStatusColor(classes, status);
  const lastModified = modifyTs && formatDateString(new Date(modifyTs).toString());
  const statusBarClass = inline ? cx(classes.statusBar, classes.inline) : classes.statusBar;

  const intl = getI18n(useIntl());

  return (
    <div
      className={statusBarClass}
      id="status-indicator" data-layer7-test="status-indicator"
    >
      <span className={cx(classes.status, statusColorClass)}>
        <Typography variant="body1" className={statusColorClass}>
          {statusLabel}
        </Typography>
      </span>
      {version &&
        <span>
          <Typography variant="body1" id="status-indicator-version">
            <li>{intl.getI18nMessage('label.api.version')}: {version}</li>
          </Typography>
        </span>
      }
      {access &&
        <span>
          <Typography variant="body1" id="status-indicator-access">
            <li>{pascalCase(access)}</li>
          </Typography>
        </span>
      }
      {lastModified &&
        <span>
          <Typography variant="body1" id="status-indicator-last-modified">
            <li>{intl.getI18nMessage('label.last.updated')}: {lastModified}</li>
          </Typography>
        </span>
      }
    </div>
  );
};

StatusIndicator.propTypes = {
  classes: shape({}),
  inline: bool,
  modifyTs: number,
  status: string,
  version: string,
  access: string,
};
StatusIndicator.displayName = 'StatusIndicator';

export default withStyles(styles)(StatusIndicator);
