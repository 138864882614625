import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { bool, func, shape, string } from 'prop-types';
import {
  Divider,
  Grid,
  withStyles,
} from '@material-ui/core';

import styles from './styles';
import { getI18n } from '../../../../utils/intl';
import {
  getProxyOrganizations,
  getApiPlans,
  getRateLimitQuota,
} from '../../../../reducers/proxy';
import { getFeatureFlagApiPlans } from '../../../../reducers/portalConfig';
import {
  DetailsPanelContainer,
  Footer,
} from '../../../../components';
import PortalCompatibility from './portalCompatibility';
import Apis from './apis';
import ApiKeys from './apiKeys';
import ApiPlans from './apiPlans';
import Plans from './plans';
import Organizations from './organizations';
import UrlContainer from "../../../../components/UrlContainer";

export const Overview = (props) => {
  const {
    classes,
    featureFlagApiPlans,
    rateLimitQuota,
    proxyUuid,
    proxyStatus,
    parsedGatewayDetails,
    isCompatible,
    proxyOrganizations = {},
    push,
    notifyMessages,
  } = props;

  const intl = getI18n(useIntl());
  const { logs } = parsedGatewayDetails;
  const proxyUrl = get(logs, 'cluster_name');

  useEffect(() => {
    props.fetchProxyOrganizations(proxyUuid);
    props.fetchRateLimitQuota(proxyUuid);
  }, []);

  return (
    <div
      id="proxy-details-overview-container"
      data-apim-test="proxy-details-overview-container"
      data-layer7-test="proxy-details-overview-container"
    >
      <Grid
        container
        id="metadata-top-panel-container"
        data-apim-test="metadata-top-panel-container"
        data-layer7-test="metadata-top-panel-container"
        className={classes.topPanelContainer}
      >
        <Grid item md={3} sm={12} xs={12}>
          <UrlContainer
            id="overview-proxy-url"
            title={intl.getI18nMessage('label.proxy.details.page.overview.proxy.url')}
            proxyUrl={proxyUrl}
            notifyMessages={notifyMessages}
            copyMessage={intl.getI18nMessage('label.proxy.details.proxy.url.copied')}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <DetailsPanelContainer
            id="overview-otk-version"
            title={intl.getI18nMessage('label.proxy.details.page.overview.otk.version')}
            subTitle={get(logs, 'otk_version')}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <DetailsPanelContainer
            id="overview-gateway-version"
            title={intl.getI18nMessage('label.proxy.details.page.overview.gateway.version')}
            subTitle={get(logs, 'version')}
          />
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
          <PortalCompatibility
            isCompatible={isCompatible}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.marginPanelContainer}>
        <Grid item md={6} sm={12} xs={12} className={classes.paper}>
          <Apis
            {...props}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12} className={classes.paper}>
          <ApiKeys
            {...props}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.marginPanelContainer}>
        {/*<Grid item md={4} sm={4} xs={12} className={classes.paper}>*/}
        {/*  <DetailsPaper*/}
        {/*    id="gateway-bundles"*/}
        {/*    title={intl.getI18nMessage('label.gateway.bundles')}*/}
        {/*    content={'0'}*/}
        {/*    lastUpdated={Date.now()}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item md={4} sm={4} xs={12} className={classes.paper}>
          <Plans
            id="account-plans"
            title={intl.getI18nMessage('label.rate.limit.quotas')}
            subTitle={intl.getI18nMessage('label.account.plans.previous')}
            proxyStatus={proxyStatus}
            portalPlanCounts={get(rateLimitQuota, 'definedEntityCount')}
            gatewayPlanCounts={get(rateLimitQuota, 'deployedEntityCount')}
            lastUpdated={get(rateLimitQuota, 'lastTimeDeployed')}
          />
        </Grid>
        {featureFlagApiPlans &&
          <Grid item md={4} sm={4} xs={12} className={classes.paper}>
            <ApiPlans
              {...props}
            />
          </Grid>
        }
        {proxyOrganizations.totalElements &&
          <Grid item md={4} sm={4} xs={12} className={classes.paper}>
            <Organizations
              proxyStatus={proxyStatus}
              proxyOrganizations={proxyOrganizations}
              push={push}
            />
          </Grid>
        }
      </Grid>
      <Footer />
    </div>
  );
};

const mapStateToProps = state => ({
  featureFlagApiPlans: getFeatureFlagApiPlans(state),
  proxyOrganizations: getProxyOrganizations(state),
  apiPlans: getApiPlans(state),
  rateLimitQuota: getRateLimitQuota(state),
});

Overview.propTypes = {
  classes: shape({}),
  featureFlagApiPlans: bool,
  proxyUuid: string,
  proxyDetails: shape({}),
  rateLimitQuota: shape({}),
  proxyStatus: string,
  parsedGatewayDetails: shape({}),
  isCompatible: bool,
  proxyOrganizations: shape({}),
  apiPlans: shape({}),
  push: func,
  notifyMessages: func,
  fetchApiPlans: func,
  fetchProxyOrganizations: func,
  fetchRateLimitQuota: func,
};
Overview.displayName = 'Overview';

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(Overview);
