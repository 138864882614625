import get from 'lodash/get';

const styles = ({ breakpoint, color, margin, padding }) => ({
  fieldContainer: {
    margin: `${get(margin, 'm0')} !important`,
    marginRight: `${get(margin, 'm8')} !important`,
  },
  pageFilterAndSort: {
    'justify-content': 'start',
  },
  statusIcon: {
    height: 15,
    marginRight: 3,
    width: 15,
  },
  helpIcon: {
    color: color.primaryButtonBackground,
    marginLeft: 6,
    marginTop: 3,
    height: 16,
    width: 16,
  },
  helpIconContainer: {
    display: 'flex',
  },
  tagButtons: {
    marginTop: get(margin, 'm31'),
  },
  addTagsButton: {
    margin: `0 ${get(margin, 'm2')}`,
  },
  tooltipContainer: {
    backgroundColor: get(color, 'gray'),
    padding: get(padding, 'p5'),
    borderRadius: get(padding, 'p10'),
    marginLeft: get(margin, 'm4'),
  },
  removeTagsButton: {
    backgroundColor: get(color, 'error'),
    borderColor: get(color, 'error'),
    margin: `0 ${get(margin, 'm2')}`,
    '&:hover': {
      backgroundColor: get(color, 'errorHover'),
    },
  },
  [`@media (max-width: ${get(breakpoint, 'sm')})`]: {
    tagButtons: {
      marginTop: 0,
    },
  },
});

export default styles;
