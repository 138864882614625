import React from 'react';
import { arrayOf, bool, number, object, string } from 'prop-types';
import { isEmpty, isFunction, get } from 'lodash';
import cx from 'classnames';
import {
  makeStyles,
  Checkbox,
  Link,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import StatusContent from '../StatusContent';
import styles from './styles';
import { func } from 'prop-types';

export const getColumns = ({
  columns = [], hasBulkActions, isSelectedAll, isDisabledAll,
  onSelectAllRowsToggle, selectedStyles, selectedRows,
}) => (
  <TableRow className={selectedStyles}>
    {hasBulkActions &&
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelectedAll}
          onChange={onSelectAllRowsToggle}
          inputProps={{ 'aria-label': 'select all rows' }}
          indeterminate={selectedRows.length > 0}
          disabled={isDisabledAll}
        />
      </TableCell>
    }
    {
      columns.map(({ id, label, minWidth, isHidden=false }) => {
        if(!isHidden) {
          return (
            <TableCell style={{ minWidth }} key={id}>
            {label}
            </TableCell>
          )
        }
      })
    }
  </TableRow>
);

export const getHyperlinkCellContent = ({ uuid, linkValue, format, value }) => (
    <Link underline="none" href={`${linkValue}/${uuid}`}>
      {format ? format(value) : value}
    </Link>
);

export const getPlainCellContent = ({ format, value, uuid, secondaryValue,
  isStatusIcon, notifyMessages, message, id, classes, isCellOverflow }) => {
   const content = format ? format(value, secondaryValue, uuid) : value;
  return(
    isStatusIcon ?
      <StatusContent
        id={id}
        statusLabel={content}
        notifyMessages={notifyMessages}
        message={message}
      />
    :
    <span className={isCellOverflow ? classes.cellOverflow: ''}>
      {content}
    </span>
  );
};

export const getRows = ({
  userContext, columns = [], rows = [], uuidName,
  notifyMessages, hasBulkActions, selectedStyles, numSelectedRows, classes,
}) => (
  rows.map(row => (
    <TableRow
      className={cx({ [selectedStyles]: row.selected })}
      hover tabIndex={-1} key={row.uuid}
      onClick={() => {
        if (isFunction(row.onClick)) { row.onClick(row.uuid); }
      }}
    >
      {hasBulkActions &&
        <TableCell padding="checkbox">
          <Checkbox
            checked={row.selected}
            onClick={e => e.stopPropagation()}
            onChange={e => row.onSelectToggle(e, row)}
            inputProps={{ 'aria-labelledby': row.uuid }}
            disabled={row.disabled}
          />
        </TableCell>
      }
      {columns.map(column => {
        const {
          id,
          link,
          value,
          isStatusIcon,
          isCellOverflow,
          secondaryId,
          isHidden=false,
        } = column;
        const linkValue = link && isFunction(link) ? link({ ...row, userContext }) : link;
        const content = linkValue
          ? getHyperlinkCellContent({
            ...column,
            uuid: uuidName ? row[uuidName] :row.uuid,
            uuidName,
            value: value && isFunction(value) ? value(row) : row[id],
            linkValue,
          })
          : getPlainCellContent({
            ...column,
            value: value && isFunction(value) ? value({ ...row, numSelectedRows }) : row[id],
            secondaryValue: secondaryId ? row[secondaryId] : null,
            uuid: uuidName ? row[uuidName] :row.uuid,
            isStatusIcon,
            notifyMessages,
            message: get(row, 'message'),
            id,
            classes,
            isCellOverflow,
          });
          if(!isHidden) {
            return (
              <TableCell key={id} align="left">
                {content}
              </TableCell>
            );
          }
      })}
    </TableRow>
  ))
);

function Table(props) {
  const {
    userContext, id, noResultsMessage, hasBulkActions, selectedRows = [], onSelectAllRowsToggle,
  } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const selectedStyles = (selectedRows.length > 0) ? classes.rowsSelected : '';
  const numSelectedRows = selectedRows.length;

  const rows = getRows({
    ...props,
    userContext,
    hasBulkActions,
    selectedStyles,
    numSelectedRows,
    classes,
  });
  const columns = getColumns({
    ...props,
    hasBulkActions,
    numRows: rows.length,
    numSelectedRows,
    onSelectAllRowsToggle,
    selectedStyles,
  });

  return (
    <TableContainer
      className={classes.tableContainer}
      id={id} data-apim-test={id} data-layer7-test={id}
    >
      <MuiTable stickyHeader aria-label="sticky table">
        <TableHead>{columns}</TableHead>
        {isEmpty(rows) ?
          <caption>{noResultsMessage}</caption> :
          <TableBody>{rows}</TableBody>
        }
      </MuiTable>
    </TableContainer>
  );
}

export default Table;

getColumns.propTypes = {
  columns: arrayOf(object),
};

getHyperlinkCellContent.propTypes = {
  uuid: string,
  linkValue: string,
  format: string,
  value: string || number,
};

getPlainCellContent.propTypes = {
  format: string, value: string || number,
};

Table.propTypes = {
  userContext: object,
  id: string,
  noResultsMessage: string,
  hasBulkActions: bool,
  selectedRows: arrayOf(string),
  onSelectAllRowsToggle: func,
};
