import get from 'lodash/get';
import { convertHexToRGB } from '../../../utils';

const styles = ({ color, fontSize, fontWeight, padding, margin, typography }) => ({
  panelContainer: {
    borderLeft: `1px solid ${get(color, 'borderColor')}`,
    borderRight: `1px solid ${get(color, 'borderColor')}`,
    height: `calc(100% - 2 * ${get(padding, 'p20')})`,
    padding: get(padding, 'p20'),
  },
  title: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    '-webkit-font-smoothing': 'antialiased',
  },
  subTitle: {
    color: `${convertHexToRGB(get(color, 'bodyText'), 0.7)} !important`,
    fontFamily: get(typography, 'bodyText'),
    fontWeight: get(fontWeight, 'normal'),
    marginBottom: get(margin, 'm0'),
    marginTop: get(margin, 'm0'),
    '-webkit-font-smoothing': 'antialiased',
  },
  subTitleBody: {
    marginTop: get(margin, 'm4'),
  },
  helpIcon: {
    padding: `0 ${get(padding, 'p4')} 0 ${get(padding, 'p8')}`,
    '& svg': {
      fontSize: get(fontSize, 'smallText'),
    },
  },
});

export default styles;
