import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { get, isUndefined, some, values } from 'lodash';

import {
  FormActionButtons,
  ErrorContainer,
  FormTextField,
  FormSelect,
} from '../../../../components';
import {
  SAVE_AND_NEXT_TEXT,
} from '../labels';

const getCustomField = (
  customFieldValues,
  { type, uuid, name, description, optionsList, required },
  customFieldErrors,
  handleChange,
  ) => {
  const customFieldValue = customFieldValues[uuid];
  switch (type) {
    case 'TEXT':
      return (
        <FormTextField
          key={uuid}
          id={uuid}
          name={name}
          value={customFieldValue}
          error={customFieldErrors[uuid]}
          handleChange={(value) => handleChange(uuid, value, required)}
          helperText={description}
          {...(!required && { optional: true })}
        />
      );
    case 'SINGLE_SELECT':
      return (
        <FormSelect
          key={uuid}
          id={uuid}
          name={name}
          value={customFieldValue}
          defaultText={'Select an option.'}
          data={optionsList.map(({ value }) => ({ uuid: value, name: value }))}
          handleChange={(e) => {
            const value = (get(e, 'target.value') === 0 || get(e, 'target.value') === '0')
              ? '' : get(e, 'target.value');
            handleChange(uuid, value, required)
          }}
          helperText={description}
          error={customFieldErrors[uuid]}
          {...(!required && { optional: true })}
        />
      );
    default:
      return null;
  }
};

const getCustomFieldValues = ({ customFieldValues = [] }) =>
  customFieldValues.reduce((acc, { customFieldUuid, value }) => {
    acc[customFieldUuid] = value;
    return acc;
  }, {});


export default function CustomFields(props) {
  const {
    classes,
    errors = [],
    apiUuid,
    apiDetails,
    customFields = [],
    saveApiCustomFields,
    onCancel,
  } = props;

  const [customFieldErrors, setCustomFieldErrors] = useState({});
  const [customFieldValues, setCustomFieldValues] = useState(getCustomFieldValues(apiDetails));
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);

  useEffect(() => {
    setCustomFieldValues(getCustomFieldValues(apiDetails));
  }, [apiDetails]);

  const handleChange = (fieldName, value, required) => {
    localStorage.setItem('isUnSavedChanges', true);
    setIsUnsavedChanges(true);
    setCustomFieldValues({ ...customFieldValues, [fieldName]: value });
    setCustomFieldErrors(
      { ...customFieldErrors, [fieldName]: (required && !value) },
    );
  };

  const onNext = async () => {
    setIsUnsavedChanges(false);
    localStorage.setItem('isUnSavedChanges', false);
    const fieldErrors = customFields
      .filter(({ required }) => required)
      .reduce((acc, { uuid }) => ({ ...acc, [uuid]: !customFieldValues[uuid] }), {});
    if (some(values(fieldErrors))) {
      setCustomFieldErrors(
        { ...customFieldErrors, ...fieldErrors },
      );
    } else {
      await saveApiCustomFields(
        apiUuid,
        customFields.reduce(
          (acc, { uuid }) => {
            if (isUndefined(acc[uuid])) { acc[uuid] = ''; }
            return acc;
          },
          customFieldValues,
        ),
      );
    }
  };

  return (
    <div id="api-custom-fields-container">
      <h1 className={classes.pageTitle}>Custom Fields</h1>
      {errors.length > 0 &&
        <ErrorContainer errors={errors} />
      }
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid md={12} item>
          {customFields.map(customField => getCustomField(
              customFieldValues, customField, customFieldErrors, handleChange,
          ))}
        </Grid>
      </Grid>
      <FormActionButtons
        onNextClick={onNext}
        nextText={SAVE_AND_NEXT_TEXT}
        onCancelClick={() => onCancel(isUnsavedChanges)}
        id="custom-fields-form-buttons"
      />
    </div>
  );
}

CustomFields.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  apiUuid: PropTypes.string,
  apiDetails: PropTypes.object,
  customFields: PropTypes.arrayOf(PropTypes.object),
  saveApiCustomFields: PropTypes.func,
  onCancel: PropTypes.func,
};
