export const getOrgPayload = (
  {
    accountPlanUuid,
    description,
    name,
    type,
    status,
    orgUuid,
  }) => ({
    AccountPlanUuid: accountPlanUuid,
    Description: description,
    Name: name,
    Type: type,
    Status: status,
    Uuid: orgUuid,
  });

export default {};
