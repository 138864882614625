import get from 'lodash/get';

const styles = ({ color, margin, padding, typography, borderRadius, fontSize } = {}) => ({
  content: {
    paddingBottom: get(padding, 'p36'),
  },
  uploadBundleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: get(margin, 'm36'),
  },
  uploadRequirementsContainer: {
    border: get(color, 'gray') + ' 1px solid',
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    borderRadius: get(borderRadius, 'br4'),
    padding: `0 ${get(padding, 'p36')}`,
    paddingBottom: get(padding, 'p36'),
    width: '46%',
  },
  uploadBundleFileDropzoneContainer: {
    width: '46%',
  },
  container: {
    backgroundColor: get(color, 'uiBackground'),
    margin: `0 ${get(margin, 'm36')}`,
    minHeight: 'calc(100vh - 125px)',
  },
  pageBody: {
    padding: `0 ${get(padding, 'p36')}`,
    paddingBottom: get(padding, 'p36'),
  },
  pageTitle: {
    margin: `0 ${get(padding, 'p36')}`,
  },
  bundlesList: {
  },
});

export default styles;
