import axios from 'axios';
import { get } from 'lodash';

import * as Constants from '../../constants';
import { AXIOS_DEFAULT_OPTIONS } from '..';
import { getConfig } from '../../reducers/portalConfig';

const initApplication = (dispatch, isLoading = true) =>
  dispatch({
    type: Constants.APP_LOADING,
    isLoading,
  });

export const resetApp = () => dispatch => dispatch({ type: Constants.APP_RESET });
export const showLoading = isLoading => dispatch => initApplication(dispatch, isLoading);

export const init = (dispatch, isLoading = true) =>
  dispatch({
    type: Constants.APP_LOADING,
    isLoading,
  });
export const fetchPublicKey = () =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/authenticate/getPublicKey`;
    dispatch({
      type: Constants.PUBLIC_KEY_GET_REQUEST,
    });
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.PUBLIC_KEY_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() =>
        dispatch({
          type: Constants.PUBLIC_KEY_GET_ERROR,
          payload: [''],
        }),
      );
};
export const fetchPasswordPolicy = () =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/public/auth/schemes/passwordpolicy`;
    dispatch({
      type: Constants.PASSWORD_POLICY_GET_REQUEST,
    });
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.PASSWORD_POLICY_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() =>
        dispatch({
          type: Constants.PASSWORD_POLICY_GET_ERROR,
          payload: [''],
        }),
      );
};
export const fetchUserProfile = () =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/userProfile`;
    dispatch({
      type: Constants.USER_PROFILE_GET_REQUEST,
    });
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.USER_PROFILE_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() =>
        dispatch({
          type: Constants.USER_PROFILE_GET_ERROR,
          payload: [''],
        }),
      );
  };

export const updateUserProfile = (userProfile, publicKey) =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    dispatch({
      type: Constants.ORGANIZATION_UPDATE_REQUEST,
    });
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    if(publicKey) {
      headers['Public-Key'] = publicKey;
    }
    const url = `${portal.hostname}/admin/userProfile`;
    await axios.put(url, userProfile, { headers }, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.USER_PROFILE_UPDATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.USER_PROFILE_UPDATE_ERROR,
          payload: get(error, 'response.data'),
        });
      });
  };

