import React from 'react';
import { makeStyles, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PropTypes from 'prop-types';

import styles from '../styles';

export default function ListHelpRaw(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { lists } = props;

  return (
    <List classes={{ root: classes.listRoot }} alignItems="flex-start">
      {lists.items.map((listItem, index) =>
        <ListItem key={`list_${index.toString()}`} classes={{ root: classes.listRoot }} alignItems="flex-start">
          {lists.type &&
            <ListItemIcon classes={{ root: classes.listIconRoot }} >
              {lists.type === 'circle' &&
                <FiberManualRecordIcon className={classes.iconText} />
              }
            </ListItemIcon>
          }
          <ListItemText primary={listItem} />
        </ListItem>,
      )}
    </List>
  );
}

ListHelpRaw.propTypes = {
  lists: PropTypes.object,
};
