import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import union from 'lodash/union';
import map from 'lodash/map';

import { withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import {
  fetchAvailableApis,
  fetchAllApiPlans,
} from '../../../../actions/application';
import {
  getAvailableApis,
  getSelectedApiGroups,
  getAllApiPlans,
} from '../../../../reducers/application';

import ApiCard from './ApiCard';
import styles from './styles';

export const getApiPlan = (apiUuid, apiApiPlanMapping, allApiPlans) => {
  const apiPlanId = apiApiPlanMapping.find(item => item.ApiUuid === apiUuid);
  const apiPlanUuid = get(apiPlanId, 'ApiPlanUuid');
  const apiPlan = allApiPlans.find(item => item.uuid === apiPlanUuid);

  return apiPlan;
};
export const getAppApisList = (app) => get(app, 'ApiIds.results', []);
export const getAppApiGroupsApisList = (list) =>
  uniq(flatMap(list, ({ apis }) => map(apis, api => (api.uuid))));
export const getApiApiPlanMapping = (app) => get(app, 'ApiApiPlanIds.results', []);

export const ApisRaw = (props) => {
  const {
    appUuid,
    singleApplication,
    isEditAppDisabled,
    allApis = [],
    allApiPlans = [],
    selectedApiGroups,
    isDevUrl,
    featureFlagApiPlan,
    user,
    history,
    classes,
  } = props;

  useEffect(() => {
    if (singleApplication && singleApplication.ApiIds) {
      const apiList = getAppApisList(singleApplication);
      const apiGroupsList = getAppApiGroupsApisList(selectedApiGroups);
      const selectedApis = union(apiList, apiGroupsList);
      if (appUuid) {
        props.fetchAvailableApis({ tags: '', id: selectedApis });
      }
      if (featureFlagApiPlan) {
        props.fetchAllApiPlans();
      }
    }
  }, [singleApplication]);

  const apiApiPlanMapping = getApiApiPlanMapping(singleApplication);

  return (
    <div
      className={classes.appApisContainer}
      id="app-apis-container" data-layer7-test="app-apis-container"
    >
      {
        allApis.map((obj, idx) => (
          <Box key={appUuid} width="25%" className={classes.apiCard}>
            <ApiCard
              index={idx}
              appUuid={appUuid}
              details={{
                uuid: get(obj, 'uuid'),
                name: get(obj, 'name'),
                status: get(obj, 'portalStatus'),
                version: get(obj, 'version'),
                description: get(obj, 'description'),
              }}
              isEditAppDisabled={isEditAppDisabled}
              featureFlagApiPlan={featureFlagApiPlan}
              apiPlan={getApiPlan(get(obj, 'uuid'), apiApiPlanMapping, allApiPlans)}
              isDevUrl={isDevUrl}
              user={user}
              history={history}
              classes={classes}
            />
          </Box>
        ))
      }
    </div>
  );
};

const mapStateToProps = state => ({
  allApis: getAvailableApis(state),
  selectedApiGroups: getSelectedApiGroups(state),
  allApiPlans: getAllApiPlans(state),
});

const mapDispatchToProps = {
  fetchAvailableApis,
  fetchAllApiPlans,
};

ApisRaw.propTypes = {
  appUuid: PropTypes.string,
  applicationDetails: PropTypes.object,
  singleApplication: PropTypes.object,
  isEditAppDisabled: PropTypes.bool,
  allApis: PropTypes.arrayOf(PropTypes.object),
  allApiPlans: PropTypes.arrayOf(PropTypes.object),
  selectedApiGroups: PropTypes.arrayOf(PropTypes.object),
  featureFlagApiPlan: PropTypes.string,
  history: PropTypes.object,
  user: PropTypes.object,
  isDevUrl: PropTypes.bool,
  classes: PropTypes.object,
  fetchAvailableApis: PropTypes.func,
  fetchAllApiPlans: PropTypes.func,
};
ApisRaw.displayName = 'Apis';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ApisRaw);
