import React, { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { bool, func, shape, string } from 'prop-types';
import {
  withStyles,
} from '@material-ui/core';

import styles from './styles';
import { getI18n } from '../../../../utils/intl';
import { getDeploymentTypeLabel } from '../../../../utils/deployment';
import {
  getProxyDetailsBulkDeploymentStatus,
  getApis,
  getProxyApis,
  getIsProxyApisLoading,
} from '../../../../reducers/proxy';
import { Loading } from '../../../../components';
import { OverviewPaper, OverviewPaperWithStatus } from './Paper';
import {
  PROXY_DEPLOYMENT_TYPE_AUTOMATIC,
  PROXY_DEPLOYMENT_TYPE_ON_DEMAND,
  PROXY_DEPLOYMENT_TYPE_SCRIPTED,
  PROXY_API_QUERY_PARAMS,
  PROXY_DETAILS_URL,
} from '../../../../constants';

export const ApisTooltip = ({ api }) => {
  const intl = getI18n(useIntl());
  switch(api) {
    case PROXY_DEPLOYMENT_TYPE_AUTOMATIC:
      return (
        <div>
          {intl.getI18nMessage('label.proxy.details.api.deployment.automatic.tooltip')}
        </div>
      )
    case PROXY_DEPLOYMENT_TYPE_ON_DEMAND:
      return (
        <div>
          {intl.getI18nMessage('label.proxy.details.api.deployment.on.demand.tooltip')}
        </div>
      )
    default:
      return null;
  }
}

ApisTooltip.propTypes = {
  api: string,
};

export const getLastUpdated = ({
  api, useNewAutoSync, proxyApis, apiSyncTime,
}) => {
  switch(api) {
    case PROXY_DEPLOYMENT_TYPE_AUTOMATIC:
      return useNewAutoSync ?
        get(proxyApis, 'apis.results[0].lastTimeDeployedDate', null) :
        apiSyncTime;
    case PROXY_DEPLOYMENT_TYPE_ON_DEMAND:
      return get(proxyApis, 'apis.results[0].lastTimeDeployedDate', null);
    case PROXY_DEPLOYMENT_TYPE_SCRIPTED:
      return get(proxyApis, 'apis.results[0].lastTimeDeployedDate', null);
    default:
      return 0;
  }
}

export const Apis = (props) => {
  const {
    proxyUuid,
    proxyDetails = {},
    bulkDeploymentStatus,
    proxyStatus,
    parsedGatewayDetails,
    proxyApis = {},
    apis,
    push,
    deploymentRefreshTime,
    isProxyApisLoading,
  } = props;

  const intl = getI18n(useIntl());

  const { config = {}, deploymentTypes = {} } = proxyDetails;
  const { useNewAutoSync } = config;
  const { api } = deploymentTypes;
  const { apiSyncTime } = parsedGatewayDetails;

  const fetchApis = () => {
    props.fetchApis();
    props.fetchProxyApis(proxyUuid);
  }

  useEffect(() => {
    fetchApis();
  }, []);
  
    const portalPendingCount = get(proxyApis, 'apisPublishedByPortalPendingDeployment[0].count') || 0;
    const portalPendingUndeploymentCount = get(proxyApis, 'apisPublishedByPortalPendingUndeployment[0].count') || 0;
    const portalDeployedCount = get(proxyApis, 'apisPublishedByPortalDeployed[0].count') || 0;
    const portalErrorCount = get(proxyApis, 'apisPublishedByPortalError[0].count') || 0;
    const gatewayPendingCount = get(proxyApis, 'apisPublishedByGatewayPendingDeployment[0].count') || 0;
    const gatewayPendingUndeploymentCount = get(proxyApis, 'apisPublishedByGatewayPendingUndeployment[0].count') || 0;
    const gatewayDeployedCount = get(proxyApis, 'apisPublishedByGatewayDeployed[0].count') || 0;
    const gatewayErrorCount = get(proxyApis, 'apisPublishedByGatewayError[0].count') || 0;
    const totalPortalPendingCount = portalPendingCount + portalPendingUndeploymentCount;
    const totalGatewayPendingCount = gatewayPendingCount + gatewayPendingUndeploymentCount;

  const counts = {
    publishedCount: get(apis, 'apis.totalElements'),
    pendingCount: totalPortalPendingCount + totalGatewayPendingCount,
    deployedCount: portalDeployedCount + gatewayDeployedCount,
    errorCount: portalErrorCount + gatewayErrorCount,
    portalPublishedCount: get(apis, 'apisPublishedByPortal.totalElements'),
    portalPendingCount: totalPortalPendingCount,
    portalDeployedCount: portalDeployedCount,
    portalErrorCount: portalErrorCount,
    gatewayPublishedCount: get(apis, 'apisPublishedByGateway.totalElements'),
    gatewayPendingCount: (api === PROXY_DEPLOYMENT_TYPE_ON_DEMAND) ?
      intl.getI18nMessage('label.not.applicable') :
      totalGatewayPendingCount,
    gatewayDeployedCount: (api === PROXY_DEPLOYMENT_TYPE_ON_DEMAND) ?
      intl.getI18nMessage('label.not.applicable') :
      gatewayDeployedCount,
    gatewayErrorCount: (api === PROXY_DEPLOYMENT_TYPE_ON_DEMAND) ?
      intl.getI18nMessage('label.not.applicable') : gatewayErrorCount,
  };
  
  const isOverviewWithStatus = [
    PROXY_DEPLOYMENT_TYPE_AUTOMATIC, PROXY_DEPLOYMENT_TYPE_ON_DEMAND,
  ].includes(api);
  const isOverviewWithoutStatus = (PROXY_DEPLOYMENT_TYPE_SCRIPTED === api);
  const lastUpdated = getLastUpdated({
    api, useNewAutoSync, proxyApis, apiSyncTime,
  });

  return (
    <Fragment>
      {isProxyApisLoading && <Loading sectionLoader />}
      {isOverviewWithStatus &&
        <OverviewPaperWithStatus
          {...counts}
          id="apis"
          title={intl.getI18nMessage('label.apis')}
          deploymentType={getDeploymentTypeLabel(api)}
          deploymentTypeTooltip={<ApisTooltip api={api} />}
          proxyStatus={proxyStatus}
          bulkDeploymentStatus={bulkDeploymentStatus}
          refreshTime={deploymentRefreshTime}
          checkStatus={() => {
            props.fetchProxyDetails(proxyUuid);
            fetchApis();
          }}
          lastUpdated={lastUpdated}
          linkUrl={`${PROXY_DETAILS_URL}${proxyUuid}/apis`}
          queryParams={PROXY_API_QUERY_PARAMS}
          push={push}
          showBreakdown
        />
      }
      {isOverviewWithoutStatus &&
        <OverviewPaper
          id="apis"
          title={intl.getI18nMessage('label.apis')}
          proxyStatus={proxyStatus}
          content={get(apis, 'apis.totalElements')}
          deploymentType={getDeploymentTypeLabel(api)}
          lastUpdated={lastUpdated}
        />
      }
    </Fragment>
  );
};

const mapStateToProps = state => ({
  bulkDeploymentStatus: getProxyDetailsBulkDeploymentStatus(state),
  apis: getApis(state),
  proxyApis: getProxyApis(state),
  isProxyApisLoading: getIsProxyApisLoading(state),
});

Apis.propTypes = {
  proxyUuid: string,
  proxyDetails: shape({}),
  bulkDeploymentStatus: string,
  proxyStatus: string,
  parsedGatewayDetails: shape({}),
  proxyApis: shape({}),
  apis: shape({}),
  deploymentRefreshTime: string,
  isProxyApisLoading: bool,
  push: func,
  fetchApis: func,
  fetchProxyApis: func,
  fetchProxyDetails: func,
};
Apis.displayName = 'Apis';

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(Apis);
