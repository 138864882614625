import get from 'lodash/get';

const styles = (theme) => ({
  paper: {
    backgroundColor: get(theme, 'color.uiBackground'),
    minHeight: 'calc(100% - 48px)',
    padding: `${get(theme, 'padding.p24')} ${get(theme, 'padding.p36')}`,
  },
  title: {
    marginTop: 0,
  },
  helpIcon: {
    padding: `0 ${get(theme, 'padding.p4')} 0 ${get(theme, 'padding.p8')}`,
    '& svg': {
      fontSize: get(theme, 'fontSize.smallText'),
    },
  },
  disconnectedBackground: {
    backgroundColor: get(theme, 'color.lightBackground'),
  },
  disconnectedIndicatorIcon: {
    color: get(theme, 'color.disabledGraphic'),
    padding: '7px',
  },
  errorIndicatorIcon: {
    color: get(theme, 'color.error'),
    padding: '7px',
  },
  value: {
    fontFamily: get(theme, 'typography.bodyText'),
    fontWeight: get(theme, 'fontWeight.normal'),
    marginTop: get(theme, 'margin.m12'),
    minHeight: 0,
  },
  tableRow: {
    '& th': {
      color: get(theme, 'color.pageTitle'),
      fontWeight: get(theme, 'fontWeight.bold'),
    },
  },
  icon: {
    padding: 0,
    marginTop: '-3px',
  },
  publishedDisconnected: {
    color: get(theme, 'color.disabledBackground'),
  },
  published: {
    color: get(theme, 'color.bodyText'),
  },
  pending: {
    color: get(theme, 'color.disabledBackground'),
  },
  notDeployed: {
    color: get(theme, 'color.disabledGraphic'),
  },
  deployed: {
    color: get(theme, 'color.success'),
  },
  error: {
    color: get(theme, 'color.error'),
  },
  otkCell: {
    marginLeft: '2px',
    backgroundColor: get(theme, 'color.lightGrayBackground'),
  },
  clickableCell: {
    padding: 0,
    '& > span > p': {
      color: get(theme, 'color.link'),
    },
  },
  hideSubtotal: {
    marginTop: get(theme, 'margin.m24'),
    padding: 0,
    '& > span > p': {
      color: get(theme, 'color.link'),
      paddingRight: get(theme, 'padding.p20'),
    },
  },
  type: {
    marginTop: get(theme, 'margin.m24'),
  },
  footerLabel: {
    fontFamily: get(theme, 'typography.bodyText'),
    '-webkit-font-smoothing': 'antialiased',
  },
});

export default styles;
