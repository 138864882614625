// @flow
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from 'moment';
import { withStyles, Button } from '@material-ui/core';
import theme from './index.theme';
import { getDisplayCopyright } from '../../reducers/portalConfig';

const copyrightText = "Broadcom. All Rights Reserved. The term 'Broadcom' refers to Broadcom Inc. and/or its subsidiaries.";
const Footer = ({
  classes = {},
  supportLink,
  displayCopyright = true,
}: Object) => (
  <div className={classes.Footer}>
    <div>
      {displayCopyright &&
        <div
          data-apim-test="copyright"
        >
          {`© ${moment().format('YYYY')} `}
          {copyrightText}
        </div>
      }
    </div>
    <Button
      variant="contained"
      color="secondary"
      className={classes.FooterHelpButton}
      href={supportLink}
    >
      Help
    </Button>
  </div>
);

export const mapStateToProps = (state: Object) => ({
  supportLink: 'https://www.broadcom.com/support/services-support/ca-support',
  displayCopyright: getDisplayCopyright(state),
});

export default compose(
  withStyles(theme),
  connect(mapStateToProps),
)(Footer);
