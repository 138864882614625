import React, { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { bool, func, number, shape, string } from 'prop-types';
import {
  withStyles,
} from '@material-ui/core';

import styles from './styles';
import { getI18n } from '../../../../utils/intl';
import { getDeploymentTypeLabel } from '../../../../utils/deployment';
import {
  getApiKeys,
  getApiKeysCount,
  getIsProxyApisLoading,
  getProxyDetailsBulkApiKeyDeploymentStatus,
} from '../../../../reducers/proxy';
import { OverviewPaper, OverviewPaperWithStatus } from './Paper';
import {
  PROXY_DEPLOYMENT_TYPE_AUTOMATIC,
  PROXY_DEPLOYMENT_TYPE_ON_DEMAND,
  PROXY_DEPLOYMENT_TYPE_SCRIPTED,
  PROXY_DETAILS_URL,
  PROXY_KEYS_QUERY_PARAMS,
} from '../../../../constants';
import { Loading } from '../../../../components';

export const getLastUpdated = ({
  application, useNewAutoSync, apiKeys, appSyncTime,
}) => {
  switch(application) {
    case PROXY_DEPLOYMENT_TYPE_AUTOMATIC:
      return useNewAutoSync ?
        get(apiKeys, 'keys[0].lastTimeDeployedDate', null) :
        appSyncTime;
    case PROXY_DEPLOYMENT_TYPE_ON_DEMAND:
      return get(apiKeys, 'keys[0].lastTimeDeployedDate', null);
    default:
      return 0;
  }
}

export const ApiKeysTooltip = () => {
  const intl = getI18n(useIntl());
  return (
    <div>
      {intl.getI18nMessage('label.proxy.details.api.key.deployment.tooltip')}
    </div>
  );
}

export const ApiKeys = (props) => {
  const {
    proxyUuid,
    proxyDetails,
    bulkDeploymentStatus,
    deploymentRefreshTime,
    proxyStatus,
    parsedGatewayDetails,
    publishedCount,
    apiKeys = [],
    push,
    isApiKeysLoading,
  } = props;

  const intl = getI18n(useIntl());

  const { config = {}, deploymentTypes = {} } = proxyDetails;
  const { application } = deploymentTypes;
  const { useNewAutoSync } = config;
  const { appSyncTime } = parsedGatewayDetails;

  const fetchApiKeys = () => {
    props.fetchApiKeysCount();
    props.fetchProxyApiKeys(proxyUuid);
  };

  useEffect(() => {
    fetchApiKeys();
  }, [proxyUuid]);

  const isOverviewWithStatus = [
    PROXY_DEPLOYMENT_TYPE_AUTOMATIC, PROXY_DEPLOYMENT_TYPE_ON_DEMAND,
  ].includes(application);
  const isOverviewWithoutStatus = (PROXY_DEPLOYMENT_TYPE_SCRIPTED === application);
  const lastUpdated = getLastUpdated({
    application, useNewAutoSync, apiKeys, appSyncTime,
  });
  
  const deployedCount = get(apiKeys, 'keysDeployed[0].count') || 0 ;
  const pendingDeployCount = get(apiKeys, 'keysPendingDeployment[0].count') || 0;
  const pendingUndeployCount = get(apiKeys, 'keysPendingUnDeployment[0].count') || 0;
  const pendingCount = pendingDeployCount + pendingUndeployCount;
  const errorCount = get(apiKeys, 'keysError[0].count') || 0;
  const otkCount = proxyDetails.deployedApiKeyCount || 0 ;
 
  return (
    <Fragment>
      {isApiKeysLoading && <Loading sectionLoader />}
      {isOverviewWithStatus &&
        <OverviewPaperWithStatus
          id="keys"
          title={intl.getI18nMessage('label.application.api.keys')}
          deploymentType={getDeploymentTypeLabel(application)}
          deploymentTypeTooltip={<ApiKeysTooltip />}
          proxyStatus={proxyStatus}
          publishedCount={publishedCount}
          pendingCount={pendingCount}
          pendingUndeployCount={pendingUndeployCount}
          deployedCount={deployedCount}
          bulkDeploymentStatus={bulkDeploymentStatus}
          refreshTime={deploymentRefreshTime}
          checkStatus={() => {
            props.fetchProxyDetails(proxyUuid);
            fetchApiKeys();
          }}
          errorCount={errorCount}
          otkCount={otkCount}
          lastUpdated={lastUpdated}
          linkUrl={`${PROXY_DETAILS_URL}${proxyUuid}/keys`}
          queryParams={PROXY_KEYS_QUERY_PARAMS}
          push={push}
        />
      }
      {isOverviewWithoutStatus &&
        <OverviewPaper
          id="keys"
          title={intl.getI18nMessage('label.application.api.keys')}
          proxyStatus={proxyStatus}
          content={publishedCount}
          deploymentType={getDeploymentTypeLabel(application)}
        />
      }
    </Fragment>
  );
};

const mapStateToProps = state => ({
  bulkDeploymentStatus: getProxyDetailsBulkApiKeyDeploymentStatus(state),
  publishedCount: getApiKeysCount(state),
  apiKeys: getApiKeys(state),
  isApiKeysLoading: getIsProxyApisLoading(state),
});

ApiKeys.propTypes = {
  proxyUuid: string,
  proxyDetails: shape({}),
  bulkDeploymentStatus: string,
  proxyStatus: string,
  parsedGatewayDetails: shape({}),
  publishedCount: number,
  apiKeys: shape({}),
  deploymentRefreshTime: string,
  isApiKeysLoading: bool,
  push: func,
  fetchApiKeysCount: func,
  fetchProxyApiKeys: func,
  fetchProxyDetails: func,
};
ApiKeys.displayName = 'ApiKeys';

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(ApiKeys);
