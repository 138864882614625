import { get } from 'lodash';
import { convertHexToRGB } from '../../utils';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
    color: get(theme, 'color.bodyText'),
    padding: '5px 1px 10px 1px',
  },
  rootError: {
    border: `1px solid ${get(theme, 'color.error')}`,
    borderRadius: 4,
  },
  labelRoot: {
    color: get(theme, 'color.bodyText'),
  },
  iconRoot: {
    marginTop: 4,
    color: get(theme, 'color.primaryButtonBackground'),
  },
  labelContainer: {
    marginTop: 5,
    padding: 5,
  },
  listTitle: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.title'),
    marginBottom: 2,
  },
  emptyListMessage: {
    borderRadius: get(theme, 'borderRadius.br3'),
    backgroundColor: get(theme, 'color.warning'),
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.bodyText'),
    padding: '5px 10px',
    display: 'inline-block',
  },
  textField: {
    backgroundColor: get(theme, 'color.uiBackground'),
    borderRadius: get(theme, 'borderRadius.br3'),
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.bodyText'),
    width: '100%',
    height: '40px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& .MuiInputBase-root': {
      backgroundColor: get(theme, 'color.uiBackground'),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: convertHexToRGB(get(theme, 'color.primaryButtonBackground'), 0.4),
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.primaryButtonHover'),
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.primaryButtonBackground'),
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: get(theme, 'color.error'),
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      backgroundColor: convertHexToRGB(get(theme, 'color.bodyText'), 0.1),
      borderColor: convertHexToRGB(get(theme, 'color.bodyText'), 0.5),
    },
  },
  listContainer: {
    width: '99%',
    height: '98%',
  },
  listSubtitle: {
    color: get(theme, 'color.bodyText'),
    marginBottom: 10,
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 10,
    marginTop: 0,
  },
  smallSearchField: {
    width: '48%',
    '& input': {
      backgroundColor: get(theme, 'color.uiBackground'),
    },
  },
  externalFilterContainer: {
    marginBottom: 8,
  },
  expansionPanelDetails: {
    flexFlow: 'wrap',
  },
  table: {
    '& tbody': {
      cursor: 'pointer',
    },
    '& td': {
      fontSize: get(theme, 'fontSize.bodyText'),
      color: get(theme, 'color.bodyText'),
    },
    '& .MuiTableRow-root.Mui-selected': {
      '& td': {
        fontWeight: 900,
      },
    },
  },
  summaryRoot: {
    backgroundColor: get(theme, 'color.uiBackground'),
    border: `1px solid ${get(theme, 'color.borderColor')}`,
    '& .MuiExpansionPanelSummary-expandIcon': {
      color: get(theme, 'color.link'),
    },
  },
  detailsContainer: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.bodyText'),
    borderTop: `1px solid ${get(theme, 'color.borderColor')}`,
    width: '100%',
    marginTop: 10,
  },
  detailsCol: {
    width: '48%',
    float: 'left',
    padding: 5,
  },
  detailDivider: {
    height: 0,
    width: '100%',
    borderTop: `1px solid ${get(theme, 'color.borderColor')}`,
  },
  badge: {
    backgroundColor: get(theme, 'color.uiBackground'),
    color: get(theme, 'color.bodyText'),
    width: '100px',
    right: '200px',
    top: '45%',
    borderRadius: 2,
  },
  searchIcon: {
    padding: 1,
    marginRight: 10,
    color: get(theme, 'color.primaryButtonBackground'),
  },
  searchRoundedIcon: {
    fontSize: '3.2rem',
  },
  panelTitle: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontWeight: 700,
    padding: 7,
    paddingLeft: '12%',
  },
  tooltipContainer: {
    backgroundColor: get(theme, 'color.gray'),
    padding: get(theme, 'padding.p5'),
    borderRadius: get(theme, 'padding.p10'),
    marginLeft: get(theme, 'margin.m4'),
  },
});

export default styles;
