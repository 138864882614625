import React from 'react';
import { Switch, FormLabel, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';

import { FormTextField, RadioGroupSection, FormAutoCompleteCombo } from '../../components';
import styles from './styles';

export default function DynamicForm(props) {
  const { fields, handleChange, index } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  
  return (
    <div data-apim-test="dynamic-form-container">
      {fields && fields.map((field) =>
        <div key={field.name}>
          {field.type === 'boolean' &&
            <div data-apim-test={field.name} className={classes.fieldContainer}>
              <FormLabel
                className={classes.formLabel}
              >
                {field.label}
              </FormLabel>
              <br/>
              <div>
                No
                <Switch
                  checked={field.value === 'true'}
                  onChange={(e) => handleChange(e.target.checked, field.name, index)}
                />
                Yes
              </div>
            </div>
          }
          {(field.type === 'string' || field.type === 'int') &&
            <FormTextField
              id={field.name}
              name={field.label}
              handleChange={(value) => handleChange(value, field.name, index)}
              value={field.value}
              disabled={field.disabled}
            />
          }
          {field.type === 'radio' &&
            <RadioGroupSection
              id={field.name}
              name={field.label}
              onChange={(e) => handleChange(e.target.value, field.name, index)}
              selected={field.value}
              data={field.data}
            />
          }
          {field.type === 'autocomplete' &&
            <FormAutoCompleteCombo
              data={field.data}
              selectedValues={field.value}
              onChange={(e, value) => handleChange(value, field.name, index)}
              loading={field.isLoad}
              isSecondaryText={field.isSecondaryText}
              id={field.name}
              inputLabel={field.inputLabel}
              hideLabel={field.hideLabel}
              optional={field.optional}
              name={field.label}
              hideHelper={field.hideLabel}
              helperText={field.helperText}
              required={field.required}
            />
          }
        </div>,
      )}
    </div>
  );
}

DynamicForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  handleChange: PropTypes.func,
  index: PropTypes.number,
};
