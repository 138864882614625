import React from 'react';
import compose from 'recompose/compose';
import cx from 'classnames';
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { bool, number, oneOfType, shape, string } from 'prop-types';

import styles from './styles';

export const DetailsPanelContainer = (props) => {
  const {
    classes,
    id,
    title,
    tooltip,
    subTitle,
    content,
    smallFont,
  } = props;

  return (
    <div
      className={classes.panelContainer}
      id={id} data-apim-test={id} data-layer7-test={id}
    >
      <Grid container>
        <Grid item>
          <Typography
            variant="h5"
            className={classes.title}
            id={`${id}-title`} data-apim-test={`${id}-title`} data-layer7-test={`${id}-title`}
          >
            {title}
          </Typography>
        </Grid>
        {tooltip &&
          <Tooltip arrow placement="right" title={tooltip} >
            <IconButton
              size="small" edge="start" color="secondary"
              className={classes.helpIcon}
              aria-label={`${id}-help`}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
        }
      </Grid>
      <Typography
        variant={smallFont ? 'body1' : 'h1'}
        className={smallFont ?
          cx(classes.subTitle, classes.subTitleBody) :
          classes.subTitle
        }
        id={`${id}-sub-title`} data-apim-test={`${id}-sub-title`} data-layer7-test={`${id}-sub-title`}
      >
        {subTitle}
      </Typography>
      {subTitle && content}
    </div>
  );
};

DetailsPanelContainer.propTypes = {
  classes: shape({}),
  id: string,
  title: string,
  tooltip: oneOfType([shape({}), string]),
  subTitle: oneOfType([number, shape({}), string]),
  content: shape({}),
  smallFont: bool,
};
DetailsPanelContainer.displayName = 'DetailsPanelContainer';

export default compose(
  withStyles(styles),
)(DetailsPanelContainer);
