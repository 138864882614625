import React from 'react';
import {
  withStyles,
  Link,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { func, shape, string } from 'prop-types';
import styles from './styles';

const AlertInfo = (props) => {
  const { id, message, classes, severity, onClose, actionText } = props;
  return (
    <Alert
      variant='filled'
      id={id}
      data-apim-test={id}
      severity={severity}
      onClose={onClose}
      classes={{
        root: classes.root,
        filledInfo: classes.info,
        filledSuccess: classes.success,
        icon: classes.icon,
        action: classes.action,
      }}
      action={
        <Link onClick={onClose}>{actionText}</Link>
      }
    >
      {message}
    </Alert>
  );
};

AlertInfo.propTypes = {
  id: string,
  message: string,
  classes: shape({}),
  severity: string,
  onClose: func,
  actionText: string,
};

export default withStyles(styles)(AlertInfo);
