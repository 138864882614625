import map from 'lodash/map';

export const getCreateData = ({
  organizationName,
  description,
  name,
  oauthScope,
  magScope,
  oauthCallbackUrl,
  oauthType,
  organizationUuid,
  apiIds = [],
  customFieldValues = {},
  apiApiPlanIds = [],
  apiGroupIds = [],
  shouldHash,
}) => ({
  Name: name,
  Description: description,
  OrganizationUuid: organizationUuid,
  OrganizationName: organizationName,
  OauthCallbackUrl: oauthCallbackUrl,
  OauthScope: oauthScope,
  OauthType: oauthType,
  MagScope: magScope,
  Uuid: '{{GENERATED_GUID}}',
  ApiIds: { results: apiIds },
  ApiGroupIds: { results: apiGroupIds },
  CustomFieldValues: {
    results: map(customFieldValues, (value, key) => ({
      Uuid: '{{GENERATED_UUID}}',
      EntityUuid: '{{GENERATED_UUID}}',
      CustomFieldUuid: key,
      Value: value,
    })),
  },
  ApiApiPlanIds: {
    results: map(apiApiPlanIds, (value, key) => ({
      ApiUuid: key,
      ApiPlanUuid: value,
    })),
  },
  ShouldHash: shouldHash,
});

export const getUpdateData = ({
  uuid,
  organizationName,
  description,
  apiKey,
  magMasterKey,
  reason,
  name,
  oauthScope,
  keySecret,
  magScope,
  oauthCallbackUrl,
  status,
  disabledByType,
  oauthType,
  organizationUuid,
  apiIds = [],
  customFieldValues = {},
  apiApiPlanIds = [],
  apiGroupIds = [],
  shouldHash,
}) => ({
  Uuid: uuid,
  OrganizationName: organizationName,
  Description: description,
  ApiKey: apiKey,
  MagMasterKey: magMasterKey,
  Reason: reason,
  Name: name,
  OauthScope: oauthScope,
  KeySecret: keySecret,
  MagScope: magScope,
  OauthCallbackUrl: oauthCallbackUrl,
  Status: status,
  DisabledByType: disabledByType,
  OauthType: oauthType,
  OrganizationUuid: organizationUuid,
  ApiIds: { results: apiIds },
  ApiGroupIds: { results: apiGroupIds },
  CustomFieldValues: {
    results: map(customFieldValues, (value, key) => ({
      Uuid: '{{GENERATED_UUID}}',
      EntityUuid: '{{GENERATED_UUID}}',
      CustomFieldUuid: key,
      Value: value,
    })),
  },
  ApiApiPlanIds: {
    results: map(apiApiPlanIds, (value, key) => ({
      ApiUuid: key,
      ApiPlanUuid: value,
    })),
  },
  ShouldHash: shouldHash,
});

export const getDeleteData = ({
  uuid,
  organizationName,
  name,
  status,
  disabledByType,
  organizationUuid,
}) => ({
  uuid,
  organizationName,
  name,
  status,
  disabledByType,
  organizationUuid,
});
