// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import theme from './index.theme';

const Branding = ({
  classes = {},
  homeHref,
  logo,
  siteTitle,
}: Object) => (
  <a
    className={classes.HeaderBrandingWrapper}
    href={homeHref}
    data-apim-test="tenant-home-link"
  >
    <div className={classes.HeaderBrandingLogo}>
      <div className={classes.HeaderBrandingLogoImageWrapper}>
        <img
          className={classes.HeaderBrandingLogoImage}
          alt="Logo"
          src={logo}
        />
      </div>
      <span className={classes.HeaderBrandingSiteTitle}>{siteTitle}</span>
    </div>
  </a>
);

export default withStyles(theme)(Branding);
