import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { compose } from "recompose";
import { JSEncrypt } from 'jsencrypt';
import {
  withStyles,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  Collapse,
} from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  fetchUserProfile,
  updateUserProfile,
  fetchPublicKey,
  fetchPasswordPolicy,
} from "../../actions/userProfile";
import {
  getIsLoading,
  getIsFormLoadingError,
  getIsFormValidationError,
  getUserProfile,
  getUpdateErrorMsg,
  getIsFormUpdateError,
  getIsUpdateSuccessful,
  getPublicKey,
  getPublicKeyError,
  getRegexPolicy,
  getRegexError,
} from "../../reducers/userProfile";
import { getUserDetails } from '../../reducers/portalConfig';
import styles from "./styles";
import { getI18n } from "../../utils/intl";
import { hasAdminRole } from "../../utils";
import { FormTextField, Loading, AlertMessages, FormDialog } from "../../components";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../constants";
export const UserProfile = (props) => {
  const {
    isFormLoadingError,
    isUpdateError,
    updateErrorMsg,
    classes,
    userProfile,
    isLoading,
    isUpdateSuccess,
    userContext,
    publicKey,
    publicKeyError,
    regexPolicy,
    regexError,
  } = props;
  const intl = getI18n(useIntl());

  const nameLabels = {
    firstName: "label.user.profile.firstname",
    lastName: "label.user.profile.lastname",
    email: "label.user.profile.email",
    userName: "label.user.profile.username",
    newPassword: "label.user.profile.new.password",
    newConfirmPassword: "label.user.profile.confirm.new.password",
  };
  const helperTextsInit = {
    firstName: intl.getI18nMessage("label.user.profile.name.length.helpertext"),
    lastName: intl.getI18nMessage("label.user.profile.name.length.helpertext"),
    email: intl.getI18nMessage(hasAdminRole(userContext) ? "label.user.profile.email.length.helpertext" :
        "label.user.profile.email.readonly.helpertext"),
    userName: intl.getI18nMessage("label.user.profile.username.readonly.helpertext"),
    password: intl.getI18nMessage("label.user.profile.password.dialog.helpertext"),
    newPassword: "",
    newConfirmPassword: "",
  };
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [userProfileState, setUserProfileState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    password: "",
    newPassword: "",
    newConfirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    newPassword: false,
    newConfirmPassword: false,
  });
  const [helperTexts, setHelperTexts] = useState(JSON.parse(JSON.stringify(helperTextsInit)));
  const [isPwdPromptOpen, setIsPwdPromptOpen] = useState(false);
  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  useEffect(() => {
    props.fetchPublicKey();
    props.fetchPasswordPolicy();
    props.fetchUserProfile();
  }, []);
  useEffect(() => {
    if (userProfile) {
      setUserProfileState(userProfile);
      setChangingPassword(false);
    }
  }, [userProfile]);
  useEffect(() => {
    if (isFormLoadingError || publicKeyError || regexError) {
      notifyMessages(
        ALERT_ERROR, intl.getI18nMessage("error.user.profile.load"));
    }
  }, [isFormLoadingError, publicKeyError, regexError]);
  useEffect(() => {
    if(isUpdateSuccess) {
      props.fetchUserProfile();
      notifyMessages(
        ALERT_SUCCESS,
        updateErrorMsg ||
          intl.getI18nMessage("label.user.profile.update.success"));
      setIsFormDirty(false);
    }
  }, [isUpdateSuccess]);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const handleUnload = (evt) => {
    const unsaved_changes_warning = intl.getI18nMessage("label.user.profile.unsaved.changes.description");
    evt.returnValue = unsaved_changes_warning;
    return unsaved_changes_warning;
  };
  useEffect(() => {
    if (isFormDirty) {
      window.addEventListener("beforeunload", handleUnload);
      return () => {
        window.removeEventListener("beforeunload", handleUnload);
      };
    }
  }, [isFormDirty]);
  const [isChangingPassword, setChangingPassword] = useState(false);
  useEffect(() => {
    if (isUpdateError) {
      notifyMessages(
        ALERT_ERROR,
        updateErrorMsg ||
          intl.getI18nMessage("error.user.profile.update"));
    }
    setIsPwdPromptOpen(false);
    setUserProfileState({
      ...userProfileState,
      password: '',
    });
  }, [isUpdateError]);

  const togglePasswordMgmt = (checked) => {
    notifyMessages('', '');
    setChangingPassword(checked);
    setUserProfileState({
      ...userProfileState,
      newPassword: "",
      newConfirmPassword: "",
    });
    setFormErrors({
      ...formErrors,
      newPassword: checked,
      newConfirmPassword: checked,
    });
    if(checked) {
      setHelperTexts({
        ...helperTexts,
        newPassword: intl.getI18nMessage(`${nameLabels['newPassword']}.mandatory`),
        newConfirmPassword: intl.getI18nMessage(`${nameLabels['newConfirmPassword']}.mandatory`),
      });
    }
  };

  const emailIsValid = (email) => /\S+@\S+\.\S{2,}/.test(email);
  const isPasswordValid = (password) =>
    new RegExp(regexPolicy.REGEX.value).test(password);
  const containsFormErrors = () =>
    formErrors.firstName ||
    formErrors.lastName ||
    formErrors.email ||
    formErrors.newPassword ||
    formErrors.newConfirmPassword;
  const isFormModified = () =>
    userProfile.firstName !== userProfileState.firstName ||
    userProfile.lastName !== userProfileState.lastName ||
    userProfile.email !== userProfileState.email ||
    (userProfileState.newPassword && userProfileState.newPassword !== "") ||
    (userProfileState.newConfirmPassword && userProfileState.newConfirmPassword !== "");
  const handleChange = (name, value = "") => {
    setIsFormDirty(isFormModified || value !== userProfile[name]);
    notifyMessages('', '');
    let errorText;
    if (value === "" && name !== "password") {
      errorText = intl.getI18nMessage(`${nameLabels[name]}.mandatory`);
    } else {
      switch (name) {
        case "password":
          if(value === "") {
            errorText = intl.getI18nMessage("label.user.profile.password.dialog.helpertext");
          }
          break;
        case "email":
          if (!emailIsValid(value)) {
            errorText = intl.getI18nMessage("label.user.profile.email.format.error");
          }
          break;
        case "newConfirmPassword":
          if(isChangingPassword) {
            if (value !== userProfileState.newPassword) {
              errorText = intl.getI18nMessage("label.user.profile.password.match.error");
            } else if (!isPasswordValid(value)) {
              errorText = intl.getI18nMessage("label.user.profile.password.format.error");
            }
          }
          break;
        case "newPassword":
          if(isChangingPassword && !isPasswordValid(value)) {
            errorText = intl.getI18nMessage("label.user.profile.password.format.error");
          }
          break;
      }
    }
    let helperText;
    let error = false;
    if (errorText) {
      helperText = errorText;
      error = true;
    } else {
      helperText = helperTextsInit[name];
    }
    setUserProfileState({
      ...userProfileState,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: error,
    });
    setHelperTexts({
      ...helperTexts,
      [name]: helperText,
    });
  };
  const handleKeyPress = (e) => {
    if(e.key === 'Enter') {
      onDialogSubmit();
    }
  };
  const isPwdRequired = () => userProfile.email !== userProfileState.email || isChangingPassword;

  const onSave = () => {
    notifyMessages('', '');
    if(isPwdRequired()) {
      setUserProfileState({
        ...userProfileState,
        password: '',
      });
      setIsPwdPromptOpen(true);
    } else {
      updateUserProfile();
    }
  };
  const updateUserProfile = () => {
    const payload = {
      firstName: userProfileState.firstName,
      lastName: userProfileState.lastName,
      email: userProfileState.email,
    };
    if(isPwdRequired()) {
      if(publicKey) {
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        payload.password = encrypt.encrypt(userProfileState.password);
        if(isChangingPassword) {
          payload.newPassword = encrypt.encrypt(userProfileState.newPassword);
        }
      } else {
        payload.password = userProfileState.password;
        if(isChangingPassword) {
          payload.newPassword = userProfileState.newPassword;
        }
      }
    }
    props.updateUserProfile(payload, publicKey);
    setIsPwdPromptOpen(false);
  }
  const onCancel = () => {
    location.reload();
  };
  const onDialogClose = () => {
    notifyMessages('', '');
    setIsPwdPromptOpen(false);
  };
  const onDialogSubmit = () => {
    if(userProfileState.password === "") {
      formErrors.password=true;
    } else {
      updateUserProfile();
    }
  };
  const pwdRequirementsList = ()=> {
    const list = [];
    if(regexPolicy) {
      Object.keys(regexPolicy).map(key => {
      const valObj = regexPolicy[key];
      
      if(key !== "REGEX" && key !== 'SUPPORTED_SYMBOLS' && key !== 'PASSWORD_EXPIRY' && valObj && valObj.enabled) {
        if (key === "SYMBOL") {
          list.push(intl.getI18nMessage(`label.user.profile.password.requirements.${key}`,
          {
            val: valObj.value,
            chars: regexPolicy["SUPPORTED_SYMBOLS"].value,
          }));
        } else {
          list.push(intl.getI18nMessage(`label.user.profile.password.requirements.${key}`,
          {
            val: valObj.value,
          }));
        }
      }
    });
    }
    return list;
  };

  return (
    <div>
      {isLoading && !regexPolicy? (
        <Loading />
      ) : (
        <div className={classes.outerDiv}>
          <div className={classes.details}>
            <Typography variant="h1" className={classes.pageTitle}>
              {intl.getI18nMessage("label.user.profile.title")}
            </Typography>
            <Typography variant="h2" className={classes.smallTitle}>
              {intl.getI18nMessage("label.user.profile.details.section.title")}
            </Typography>
            <FormTextField
              id={"first-name"}
              name={intl.getI18nMessage(nameLabels.firstName)}
              value={userProfileState.firstName}
              maxLength="60"
              helperText={helperTexts.firstName}
              placeholder={intl.getI18nMessage("label.user.profile.firstname.placeholder")}
              handleChange={(value) => handleChange("firstName", value)}
              handleBlur={(value) => handleChange("firstName", value)}
              error={formErrors.firstName}
              errorHelperText={formErrors.firstName}
              disabled={userContext.isIdpUser}
            />
            <FormTextField
              id={"last-name"}
              name={intl.getI18nMessage(nameLabels.lastName)}
              value={userProfileState.lastName}
              maxLength="60"
              helperText={helperTexts.lastName}
              placeholder={intl.getI18nMessage("label.user.profile.lastname.placeholder")}
              handleChange={(value) => handleChange("lastName", value)}
              handleBlur={(value) => handleChange("lastName", value)}
              error={formErrors.lastName}
              errorHelperText={formErrors.lastName}
              disabled={userContext.isIdpUser}
            />
            <FormTextField
              id={"email"}
              name={intl.getI18nMessage(nameLabels.email)}
              value={userProfileState.email}
              disabled={!hasAdminRole(userContext) || userContext.isIdpUser}
              maxLength="254"
              helperText={helperTexts.email}
              placeholder={intl.getI18nMessage("label.user.profile.email.placeholder")}
              handleChange={(value) => handleChange("email", value)}
              handleBlur={(value) => handleChange("email", value)}
              error={formErrors.email}
              errorHelperText={formErrors.email}
            />
            <FormTextField
              id={"user-name"}
              name={intl.getI18nMessage(nameLabels.userName)}
              value={userProfileState.userName}
              disabled
              helperText={helperTexts.userName}
            />
          </div>
          {!userContext.isIdpUser && (
            <div>
              <Typography variant="h2" className={classes.smallTitle}>
                {intl.getI18nMessage("label.user.profile.password.section.title")}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    id="password-management-chkbox"
                    data-apim-test="password-management-chkbox"
                    checked={isChangingPassword}
                    onChange={(e) => togglePasswordMgmt(e.target.checked)}
                  />
                }
                label={intl.getI18nMessage(
                  "label.user.profile.change.password.checkbox",
                )}
              />
              <Collapse in={isChangingPassword}>
                <div className={classes.passwordManagement}>
                  <div className={classes.passwordFields}>
                    <FormTextField
                      id={"newPassword"}
                      name={intl.getI18nMessage(nameLabels.newPassword)}
                      value={userProfileState.newPassword}
                      maxLength="60"
                      type="password"
                      handleChange={(value) => handleChange("newPassword", value)}
                      handleBlur={(value) => handleChange("newPassword", value)}
                      error={formErrors.newPassword}
                      errorHelperText={formErrors.newPassword}
                      helperText={helperTexts.newPassword}
                      placeholder={intl.getI18nMessage("label.user.profile.new.password.placeholder")}
                    />
                    <FormTextField
                      id={"confirm-password"}
                      name={intl.getI18nMessage(nameLabels.newConfirmPassword)}
                      value={userProfileState.newConfirmPassword}
                      maxLength="60"
                      type="password"
                      handleChange={(value) =>
                        handleChange("newConfirmPassword", value)
                      }
                      handleBlur={(value) =>
                        handleChange("newConfirmPassword", value)
                      }
                      error={formErrors.newConfirmPassword}
                      errorHelperText={formErrors.newConfirmPassword}
                      helperText={helperTexts.newConfirmPassword}
                      placeholder={intl.getI18nMessage(
                        "label.user.profile.confirm.new.password.placeholder",
                      )}
                    />
                  </div>
                  <div className={classes.pwdRequirements}>
                    <Typography variant="h2" className={classes.bodyText}>
                      {intl.getI18nMessage(
                        "label.user.profile.password.requirements.text",
                      )}
                    </Typography>
                    <ul className={classes.bullets}>
                      {pwdRequirementsList().map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Collapse>
              <div className={classes.buttonsPanel}>
                <Button
                  id="save-button"
                  data-apim-test={"save-button"}
                  variant="contained"
                  color="secondary"
                  className={classes.saveButton}
                  onClick={() => onSave()}
                  disabled={containsFormErrors()}
                >
                  {intl.getI18nMessage("label.save.button")}
                </Button>
                <Button
                  id="cancel-button"
                  data-apim-test="cancel-button"
                  color="primary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={() => onCancel()}
                >
                  {intl.getI18nMessage("label.cancel.button")}
                </Button>
              </div>
            </div>
          )}
          <FormDialog
            dialogId="application-select-hashing-dialog"
            isOpen={isPwdPromptOpen}
            submitText={intl.getI18nMessage("label.user.profile.prompt.submit")}
            cancelText={intl.getI18nMessage("label.cancel.button")}
            onSubmit={onDialogSubmit}
            onCancel={onDialogClose}
            className={classes.dialog}
            title={intl.getI18nMessage("label.user.profile.password.dialog.title")}
            submitButtonDisabled={userProfileState.password===''}
          >
            <FormTextField
              id={"current-password"}
              name={""}
              value={userProfileState.password}
              maxLength="60"
              type="password"
              error={formErrors.password}
              errorHelperText={formErrors.password}
              helperText={helperTexts.password}
              placeholder={intl.getI18nMessage("label.user.profile.password.dialog.password.placeholder")}
              handleChange={(value) => handleChange("password", value)}
              handleBlur={(value) => handleChange("password", value)}
              onKeyPress={handleKeyPress}
            />
          </FormDialog>
        </div>
      )}
      {notificationMessage && (
        <AlertMessages
          className={classes.notification}
          id="notificationId"
          data-apim-test="notificationId"
          variant={notificationStatus}
          message={notificationMessage}
          onClose={() => {
            setNotificationMessage("");
            setNotificationStatus("");
          }}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  userProfile: getUserProfile(state),
  isLoading: getIsLoading(state),
  isUpdateError: getIsFormUpdateError(state),
  isUpdateSuccess: getIsUpdateSuccessful(state),
  isFormLoadingError: getIsFormLoadingError(state),
  isFormValidationError: getIsFormValidationError(state),
  publicKey: getPublicKey(state),
  publicKeyError: getPublicKeyError(state),
  updateErrorMsg: getUpdateErrorMsg(state),
  userContext: getUserDetails(state),
  regexPolicy: getRegexPolicy(state),
  regexError: getRegexError(state),
});

const mapDispatchToProps = {
  fetchUserProfile,
  updateUserProfile,
  fetchPublicKey,
  fetchPasswordPolicy,
};

UserProfile.propTypes = {
  userProfile: PropTypes.object,
  updateUserProfile: PropTypes.func,
  fetchPublicKey: PropTypes.func,
  isLoading: PropTypes.bool,
  isFormLoadingError: PropTypes.bool,
  getIsFormUpdateError: PropTypes.func,
  isUpdateError: PropTypes.bool,
  updateErrorMsg: PropTypes.string,
  isUpdateSuccess: PropTypes.bool,
  getIsUpdateSuccessful: PropTypes.func,
  handleBlur: PropTypes.func,
  fetchUserProfile: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isSaveDisabled: PropTypes.bool,
  intl: PropTypes.shape({}),
  formErrors: PropTypes.shape({}),
  classes: PropTypes.object,
  userContext: PropTypes.object,
  publicKey: PropTypes.string,
  publicKeyError: PropTypes.object,
  fetchPasswordPolicy: PropTypes.func,
  regexPolicy: PropTypes.object,
  regexError: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps))(UserProfile);
