import { isFinite, toNumber } from 'lodash';
import momentTimeZone from 'moment-timezone';

export const getTimeZone = () => momentTimeZone.tz.guess();

export const getTimeZoneAbbr = () => momentTimeZone.tz([2012, 5], getTimeZone()).zoneAbbr();

export const zone = getTimeZone(); //'America/Los_Angeles';

export const formatDateString = (dateString) => {
  if (dateString) {
    const arrDateFields = dateString.split(' ');
    const month = arrDateFields[1];
    const date = arrDateFields[2];
    const year = arrDateFields[3];

    let formattedTimeString = '';
    if (arrDateFields[4]) { // time string
      const arrTimeFields = arrDateFields[4].split(':');
      const hour = arrTimeFields[0];
      const mins = arrTimeFields[1];
      formattedTimeString = `${hour}:${mins}`;
    }
    return `${month} ${date} ${year} ${formattedTimeString}`;
  }
  return '';
};

export default (value) => {
  const _value = value && toNumber(value);
  const date = isFinite(_value) ? _value : value;
  return date &&
    momentTimeZone
      .tz(new Date(date), zone)
      .format('MMM DD YYYY HH:mm');
}

export const convertBundleVersionDate = (bundleDate) => {
  const arrDateFields = bundleDate.split('.');
  const year = arrDateFields[1].substr(0, 4);
  const month = arrDateFields[1].substr(4, 2);
  const day = arrDateFields[1].substr(6, 2);
  const hour = arrDateFields[2].substr(0, 2);
  const mins = arrDateFields[2].substr(2, 2);
  const seconds = arrDateFields[3];
  return new Date(Date.UTC(year, month, day, hour, mins, seconds)).getTime();
}

export const isCompatibleBundleVersion = (bundleVersion) => {
  const supportedBundleVersion = "UTC.20220303.1200.00";
  if(bundleVersion && bundleVersion.split('.').length === 4) {
    const supportedTime = convertBundleVersionDate(supportedBundleVersion);
    const bundleTime = convertBundleVersionDate(bundleVersion);
    return (supportedTime <= bundleTime);
  }
  return false;
}
