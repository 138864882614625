import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import toNumber from 'lodash/toNumber';
import {
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  Typography,
  withStyles,
} from '@material-ui/core';
import { IconDanger } from 'mineral-ui-icons';
import { number, shape, string } from 'prop-types';

import styles from './styles';
import { getI18n } from '../../../../utils/intl';
import {
  OverviewPaper,
  OverviewPaperWithStatusTableHead,
  OverviewPaperWithStatusTableRow,
} from './Paper';

export const Plans = (props) => {
  const {
    classes,
    id,
    title,
    subTitle,
    proxyStatus,
    portalPlanCounts,
    gatewayPlanCounts,
    lastUpdated,
  } = props;

  const intl = getI18n(useIntl());
  const arePlanCountsEqual = (
    toNumber(portalPlanCounts) === toNumber(gatewayPlanCounts)
  );

  return (arePlanCountsEqual ?
    <OverviewPaper
      id={id}
      title={title}
      subTitle={subTitle}
      proxyStatus={proxyStatus}
      content={portalPlanCounts}
      lastUpdated={lastUpdated}
    /> :
    <OverviewPaper
      id={id}
      title={title}
      subTitle={subTitle}
      proxyStatus={proxyStatus}
      contentMore={
        <Fragment>
          <TableContainer>
            <Table size="small">
              <OverviewPaperWithStatusTableHead
                rowName={intl.getI18nMessage('label.proxy.details.page.source')}
                cells={[
                  {
                    key: 'published',
                    value: intl.getI18nMessage('label.proxy.details.page.status.published'),
                  },
                ]}
              />
              <TableBody>
                <OverviewPaperWithStatusTableRow
                  rowName={intl.getI18nMessage('label.proxy.details.api.portal')}
                  cells={[
                    { key: `portal.plan.counts.${id}`, value: portalPlanCounts },
                  ]}
                />
                <OverviewPaperWithStatusTableRow
                  rowName={intl.getI18nMessage('label.proxy.details.api.gateway')}
                  cells={[
                    { key: `gateway.plan.counts.${id}`, value: gatewayPlanCounts },
                  ]}
                />
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container style={{ marginTop: 25, marginBottom: 25 }}>
            <Grid item>
              <IconButton className={cx(classes.icon, classes.countMismatch)}>
                <IconDanger />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {intl.getI18nMessage('label.proxy.details.portal.gateway.counts.different')}
              </Typography>
            </Grid>
          </Grid>
        </Fragment>
      }
      lastUpdated={lastUpdated}
    />
  );
};

Plans.propTypes = {
  classes: shape({}),
  id: string,
  title: string,
  subTitle: string,
  proxyStatus: string,
  portalPlanCounts: number,
  gatewayPlanCounts: number,
  lastUpdated: string,
};
Plans.displayName = 'Plans';

export default (
  withStyles(styles)
)(Plans);
