import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles, Grid, Box } from '@material-ui/core';

import { TablePagination, Footer } from '../../../../components';
import { FilterByName, FilterByDeploymentState } from './controls';
import {
  fetchDeploymentRefreshTime,
} from '../../../../actions/portalConfig';
import {
  getDeploymentRefreshTime,
} from '../../../../reducers/portalConfig';
import {
  fetchDeploymentProxyList,
  fetchDeploymentStatus,
  deployGatewayBundle,
  redeployGatewayBundle,
  undeployGatewayBundle,
} from '../../../../actions/gatewayBundle';
import {
  getGatewayBundleProxyListResults,
  getGatewayBundlesProxyListTotalElements,
  getGatewayBundlesProxyListTotalPages,
  getGatewayBundleProxyListErrors,
  getProxyErrors,
} from '../../../../reducers/gatewayBundle';
import DeploymentCard from './DeploymentCard';
import styles from './styles';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import {
  ALERT_ERROR,
  GRID_ROWS_PER_PAGE_OPTIONS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
} from '../../../../constants';

export const DeploymentsRaw = (props) => {
  const {
    bundleUuid,
    gatewayBundleDetails,
    deploymentProxyList = [],
    deploymentErrors = [],
    listProxyErrors = [],
    notifyMessages,
    totalPages,
    totalElements = 0,
    classes,
  } = props;

  const intl = getI18n(useIntl());
  const [filterByName, setFilterByName] = useState('');
  const [filterByState, setFilterByState] = useState('all');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);

  useEffect(() => {
    if (bundleUuid) {
      props.fetchDeploymentProxyList(bundleUuid, filterByName, filterByState, page, rowsPerPage);
      props.fetchDeploymentRefreshTime();
    }
  }, [bundleUuid]);

  useEffect(() => {
    if (listProxyErrors.length > 0) {
      notifyMessages(ALERT_ERROR, intl.getI18nMessage('error.gateway.bundle.proxy.list.fetch'));
    } else {
      notifyMessages('', '');
    }
  }, [listProxyErrors]);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      props.fetchDeploymentProxyList(bundleUuid, filterByName, filterByState, page, rowsPerPage);
    }
  };

  const onFilterByStateChange = (newFilterByState) => {
    setFilterByState(newFilterByState);
    props.fetchDeploymentProxyList(bundleUuid, filterByName, newFilterByState, page, rowsPerPage);
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchDeploymentProxyList(bundleUuid, filterByName, filterByState, newPage, rowsPerPage);
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchDeploymentProxyList(bundleUuid, filterByName, filterByState, 0, newRowsPerPage);
  };

  const checkStatus = () => {
    props.fetchDeploymentStatus(bundleUuid, filterByName, filterByState, page, rowsPerPage);
  };

  return (
    <div
      id="gatway-bundle-detailsdeployments-container"
      data-apim-test={'gatway-bundle-detailsdeployments-container'}
      className={classes.deploymentsContainer}
    >
      <Grid container className={classes.bodyContent}>
        <Grid
          container
          className={classes.filterContainer}
          id="deployments-filter-container"
          data-apim-test={'deployments-filter-container'}
        >
          <FilterByName
            name={intl.getI18nMessage('label.filter.by')}
            fieldContainerClass={classes.fieldContainer}
            value={filterByName}
            placeholder={intl.getI18nMessage('label.deployments.filter.by.name.placeholder')}
            handleChange={onFilterByNameChange}
            onKeyPress={onFilterByNameKeyPress}
          />
          <FilterByDeploymentState
            name={intl.getI18nMessage('label.filter.by')}
            fieldContainerClass={classes.fieldContainer}
            value={filterByState}
            handleChange={onFilterByStateChange}
            selectFieldClass={classes.selectField}
            hideLabel
          />
        </Grid>
        {deploymentProxyList.length > 0 ?
          deploymentProxyList.map((obj, idx) => (
            <Box width="25%" key={obj.proxyUuid} className={classes.deploymentCard}>
              <DeploymentCard
                index={idx}
                details={{
                  name: obj.proxyName,
                  status: obj.status,
                  statusMessage: obj.message,
                  modifyTs: obj.lastTimeDeployed,
                  gatewayBundleUuid: obj.gatewayBundleUuid,
                  proxyUuid: obj.proxyUuid,
                  deployedGatewayBundleVersion: obj.deployedGatewayBundleVersion,
                  latestGatewayBundleVersion: gatewayBundleDetails.version,
                }}
                errorObj={deploymentErrors.find(err => (err.proxyUuid === obj.proxyUuid))}
                refreshTime={props.refreshTime}
                deployGatewayBundle={props.deployGatewayBundle}
                redeployGatewayBundle={props.redeployGatewayBundle}
                undeployGatewayBundle={props.undeployGatewayBundle}
                checkStatus={checkStatus}
                classes={classes}
              />
            </Box>
          )) :
          <Box width="100%">{getI18nFormattedMessage('label.no.results')}</Box>
        }
      </Grid>
      <TablePagination
        id="gateway-bundle-deployments-pagination"
        data-apim-test="gateway-bundle-deployments-pagination"
        page={page}
        count={totalElements}
        pageCount={totalPages}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        labelRowsPerPage={intl.getI18nMessage('label.pagination.items.per.page')}
      />
      <Footer />
    </div>
  );
};

const mapStateToProps = state => ({
  deploymentProxyList: getGatewayBundleProxyListResults(state),
  totalPages: getGatewayBundlesProxyListTotalPages(state),
  totalElements: getGatewayBundlesProxyListTotalElements(state),
  refreshTime: getDeploymentRefreshTime(state),
  deploymentErrors: getProxyErrors(state),
  listProxyErrors: getGatewayBundleProxyListErrors(state),
});

const mapDispatchToProps = {
  fetchDeploymentProxyList,
  fetchDeploymentStatus,
  fetchDeploymentRefreshTime,
  deployGatewayBundle,
  undeployGatewayBundle,
  redeployGatewayBundle,
};

DeploymentsRaw.propTypes = {
  bundleUuid: PropTypes.string,
  gatewayBundleDetails: PropTypes.object,
  deploymentProxyList: PropTypes.arrayOf(PropTypes.object),
  deploymentErrors: PropTypes.arrayOf(PropTypes.object),
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  classes: PropTypes.object,
  undeployGatewayBundle: PropTypes.func,
  listProxyErrors: PropTypes.arrayOf(PropTypes.object),
  notifyMessages: PropTypes.func,
  fetchDeploymentProxyList: PropTypes.func,
  fetchDeploymentRefreshTime: PropTypes.func,
  fetchDeploymentStatus: PropTypes.func,
  refreshTime: PropTypes.func,
  deployGatewayBundle: PropTypes.func,
  redeployGatewayBundle: PropTypes.func,
};
DeploymentsRaw.displayName = 'Deployments';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(DeploymentsRaw);
