export default ({ color, fontSize, typography }) => ({
  card: {
    position: 'relative',
    display: 'inline-block',
    width: 190,
    height: 57,
    border: `1px solid ${color.borderColor}`,
    borderRadius: 4,
    opacity: 1,
    marginTop: 14,
    marginLeft: 15,
    marginBottom: 1,
    marginRight: 1,
    float: 'left',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${color.primaryButtonHover}`,
      transition: 'border-color 0.5s ease',
    },
    '&:focus': {
      marginTop: 13,
      marginLeft: 14,
      marginBottom: 0,
      marginRight: 0,
      outline: 'none',
      border: `2px solid ${color.primaryButtonHover}`,
      transition: 'border-color 1s ease',
    },
  },

  cardAnalytics: {
    position: 'relative',
    display: 'inline-block',
    width: 190,
    height: 76,
    border: `1px solid ${color.borderColor}`,
    borderRadius: 4,
    opacity: 1,
    marginTop: 14,
    marginLeft: 15,
    float: 'left',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${color.primaryButtonHover}`,
      transition: 'border-color 0.5s ease',
    },
    '&:focus': {
      outline: 'none',
      border: `2px solid ${color.primaryButtonHover}`,
      transition: 'border-color 1s ease',
    },
  },

  cardUnauthorized: {
    position: 'relative',
    display: 'inline-block',
    width: 190,
    height: 57,
    border: `1px solid ${color.borderColor}`,
    borderRadius: 4,
    opacity: 1,
    marginTop: 14,
    marginLeft: 15,
    float: 'left',
  },

  cardUnauthorizedAnalytics: {
    position: 'relative',
    display: 'inline-block',
    width: 190,
    height: 76,
    border: `1px solid ${color.borderColor}`,
    borderRadius: 4,
    opacity: 1,
    marginTop: 14,
    marginLeft: 15,
    float: 'left',
  },

  cardNameEllipsis: {
    textAlign: 'left',
    float: 'left',
    width: 144,
    fontFamily: typography.bodyText,
    fontSize: fontSize.bodyText,
    fontWeight: 'bold',
    color: color.link,
    paddingTop: 6,
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    background: `linear-gradient(to right, ${color.link} 44%, #FFF 188%)`,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  cardName: {
    textAlign: 'left',
    float: 'left',
    width: 144,
    fontFamily: typography.bodyText,
    fontSize: fontSize.bodyText,
    fontWeight: 'bold',
    color: color.link,
    paddingTop: 6,
    display: 'block',
  },

  cardNameUnAuthorized: {
    textAlign: 'left',
    fontFamily: typography.bodyText,
    fontSize: fontSize.bodyText,
    fontWeight: 'bold',
    color: color.bodyText,
    marginTop: 8,
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  cardInner: {
    height: 33,
    margin: '12px 12px 12px 16px',
  },

  count: {
    fontFamily: typography.bodyText,
    fontSize: fontSize.bodyText,
    fontWeight: 'normal',
    color: color.bodyText,
  },

  tooltip: {
    '&:hover': {
      '& span': {
        visibility: 'visible',
      },
    },
  },

  tooltiptext: {
    fontSize: typography.bodyText,
    fontFamily: typography.bodyText,
    width: 160,
    opacity: 0.9,
    visibility: 'hidden',
    backgroundColor: '#5a5f6d',
    color: '#fff',
    textAlign: 'center',
    borderRadius: 3,
    padding: '5px 0',
    position: 'absolute',
    zIndex: 1,
    bottom: '81%',
    left: '78%',
    '&:after': {
      content: 'close-quote',
      position: 'absolute',
      top: '100%',
      left: '12%',
      marginLeft: -5,
      borderWidth: 7,
      borderStyle: 'solid',
      borderColor: '#5a5f6d transparent transparent transparent',
    },
  },

  ellipsis: {
    float: 'left',
    marginTop: 8,
  },
});
