import { get } from 'lodash';

const styles = theme => ({
  formControl: {
    fontSize: 12,
  },
  radioGroupContainer: {
    marginTop: 10,
    marginLeft: 5,
    '& .MuiRadio-root': {
      padding: '3px',
    },
    '& .MuiSvgIcon-root': {
      height: '20px',
      width: '20px',
    },
  },
  formLabel: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontWeight: 700,
    paddingLeft: 0,
  },
  radioLabel: {
    color: get(theme, 'color.bodyText'),
    fontFamily: 'clear-sans-bold',
    paddingLeft: 0,
  },
  radioSubtitle: {
    color: get(theme, 'color.bodyText'),
    fontFamily: 'clear-sans-regular',
    fontSize: 14,
    paddingLeft: theme.spacing(2),
  },
  disabled: {
    color: get(theme, 'color.gray'),
  },
  helpTip: {
    fontSize: 14,
    marginLeft: 100,
  },
});

export default styles;
