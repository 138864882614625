import React, { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';

import { fetchLockUserCreation } from '../../../actions/portalConfig';
import { fetchAllOrganizations } from '../../../actions/organization';
import { resetApp, fetchUsers, resetDeleteNotification } from '../../../actions/user';
import {
  getConfig, getUserDetails, getLockUserCreation,
} from '../../../reducers/portalConfig';
import {
  getOrganizationListResults,
} from '../../../reducers/organization';
import {
  getUserListTotalElements,
  getUserListResults,
  getIsError,
  getErrors,
  getIsUserDeleteSuccess,
} from '../../../reducers/user';
import styles from './styles';
import ListContainer from '../../list';
import {
  FilterByName,
  FilterByEmail,
  FilterByOrganization,
  FilterByRole,
  FilterByStatus,
  SortBy,
  FilterAndSortSeparator,
  Organization,
  Role,
} from './controls';
import {
  ALL,
  ALERT_SUCCESS,
  ALERT_ERROR,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
  KEY_ENTER,
  USER_STATUS_LABEL,
} from '../../../constants';
import {
  hasPublisherRole, getCanListUsers, getCanManageUser, getCanCreateUser,
} from '../../../utils/rbac';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';

export const getUserEditLink = (user) => (
  getCanManageUser(user) && '/admin/users#edit'
);

export const getFullName = ({ lastName = '', firstName = '' }) => (
  (lastName && firstName) ?
    `${lastName}, ${firstName}` :
    (lastName || firstName || getI18nFormattedMessage('label.user.no.name'))
);

export const USERS_LIST_COLUMNS = [
  {
    id: 'name',
    label: getI18nFormattedMessage('label.name'),
    minWidth: 200,
    link: getUserEditLink,
    value: getFullName,
  }, {
    id: 'email',
    label: getI18nFormattedMessage('label.email'),
    minWidth: 100,
  }, {
    id: 'organization',
    label: getI18nFormattedMessage('label.organization(s)'),
    minWidth: 100,
    value: Organization,
  }, {
    id: 'role',
    label: getI18nFormattedMessage('label.role'),
    minWidth: 100,
    value: Role,
  }, {
    id: 'status',
    label: getI18nFormattedMessage('label.status'),
    minWidth: 100,
    value: ({ status }) => (USER_STATUS_LABEL[status] || status),
  },
];

export const UserList = (props) => {
  const {
    classes,
    userContext,
    isError,
    userErrors = [],
    isLoading,
    totalElements = 0,
    results = [],
    isUserDeleteSuccess,
    lockUserCreation,
    organizations,
  } = props;

  const intl = getI18n(useIntl());
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [filterByName, setFilterByName] = useState('');
  const [filterByEmail, setFilterByEmail] = useState('');
  const [filterByOrganization, setFilterByOrganization] = useState();
  const [filterByRole, setFilterByRole] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState(ALL);
  const [sortBy, setSortBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);

  const isPublisher = hasPublisherRole(userContext);

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  useEffect(() => {
    props.fetchAllOrganizations();
    const [by, order] = sortBy.split(',');
    props.fetchUsers({
      name: filterByName,
      email: filterByEmail,
      orgUuids: filterByOrganization,
      roleUuids: filterByRole,
      status: filterByStatus,
      sortBy: by,
      sortOrder: order,
      page,
      rowsPerPage,
    });
    props.fetchLockUserCreation();
    if (isUserDeleteSuccess) {
      notifyMessages(ALERT_SUCCESS, intl.getI18nMessage('label.user.deleted'));
      setTimeout(props.resetDeleteNotification, 2500);
    }
  }, []);

  useEffect(() => {
    if (!getCanListUsers(userContext)) { props.push('/404'); }
  }, [userContext]);

  useEffect(() => {
    if (isError) {
      notifyMessages(ALERT_ERROR, intl.getI18nMessage('error.user.fetch'));
    } else if (isUserDeleteSuccess) {
      notifyMessages(ALERT_SUCCESS, intl.getI18nMessage('label.user.deleted'));
    } else {
      notifyMessages('', '');
    }
  }, [isError, userErrors, isUserDeleteSuccess]);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };
  const onFilterByNameKeyPress = (e) => {
    if (filterByName && filterByName.length < 3) { return; }
    if (e.key === KEY_ENTER) {
      const [by, order] = sortBy.split(',');
      setPage(0);
      props.fetchUsers({
        name: filterByName,
        email: filterByEmail,
        orgUuids: filterByOrganization,
        roleUuids: filterByRole,
        status: filterByStatus,
        sortBy: by,
        sortOrder: order,
        page: 0,
        rowsPerPage,
      });
    }
  };
  const onFilterByEmailChange = (value) => {
    setFilterByEmail(value);
  };
  const onFilterByEmailKeyPress = (e) => {
    if (filterByEmail && filterByEmail.length < 3) { return; }
    if (e.key === KEY_ENTER) {
      const [by, order] = sortBy.split(',');
      setPage(0);
      props.fetchUsers({
        name: filterByName,
        email: filterByEmail,
        orgUuids: filterByOrganization,
        roleUuids: filterByRole,
        status: filterByStatus,
        sortBy: by,
        sortOrder: order,
        page: 0,
        rowsPerPage,
      });
    }
  };
  const onFilterByOrganizationChange = (newOrganization) => {
    setFilterByOrganization(newOrganization);
    const [by, order] = sortBy.split(',');
    setPage(0);
    props.fetchUsers({
      name: filterByName,
      email: filterByEmail,
      orgUuids: newOrganization,
      roleUuids: filterByRole,
      status: filterByStatus,
      sortBy: by,
      sortOrder: order,
      page: 0,
      rowsPerPage,
    });
  };
  const onFilterByRoleChange = (newRole) => {
    setFilterByRole(newRole);
    const [by, order] = sortBy.split(',');
    setPage(0);
    props.fetchUsers({
      name: filterByName,
      email: filterByEmail,
      orgUuids: filterByOrganization,
      roleUuids: newRole,
      status: filterByStatus,
      sortBy: by,
      sortOrder: order,
      page: 0,
      rowsPerPage,
    });
  };
  const onFilterByStatusChange = (newStatus) => {
    setFilterByStatus(newStatus);
    const [by, order] = sortBy.split(',');
    setPage(0);
    props.fetchUsers({
      name: filterByName,
      email: filterByEmail,
      orgUuids: filterByOrganization,
      roleUuids: filterByRole,
      status: newStatus,
      sortBy: by,
      sortOrder: order,
      page: 0,
      rowsPerPage,
    });
  };

  const onSortByChange = (newSortBy) => {
    setSortBy(newSortBy);
    const [by, order] = newSortBy.split(',');
    props.fetchUsers({
      name: filterByName,
      email: filterByEmail,
      orgUuids: filterByOrganization,
      roleUuids: filterByRole,
      status: filterByStatus,
      sortBy: by,
      sortOrder: order,
      page,
      rowsPerPage,
    });
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    const [by, order] = sortBy.split(',');
    props.fetchUsers({
      name: filterByName,
      email: filterByEmail,
      orgUuids: filterByOrganization,
      roleUuids: filterByRole,
      status: filterByStatus,
      sortBy: by,
      sortOrder: order,
      page: newPage,
      rowsPerPage,
    });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    const [by, order] = sortBy.split(',');
    props.fetchUsers({
      name: filterByName,
      email: filterByEmail,
      orgUuids: filterByOrganization,
      roleUuids: filterByRole,
      status: filterByStatus,
      sortBy: by,
      sortOrder: order,
      page: 0,
      rowsPerPage: newRowsPerPage,
    });
  };

  const onAddUser = () => {
    props.resetApp();
    window.location.href = '/admin/users#add';
  };

  return (
    <ListContainer
      userContext={userContext}
      listPageId="user-list-page"
      isLoading={isLoading}
      notificationId="user-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationMessage}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      onCloseNotification={props.resetDeleteNotification}
      pageHeaderTitle={intl.getI18nMessage('label.user.list.page.title')}
      addButtonLabel={intl.getI18nMessage('label.user.add.button')}
      onAdd={(
        getCanCreateUser(userContext) && onAddUser
      )}
      disableAdd={lockUserCreation}
      filterAndSortContent={(
        <Fragment>
          <FilterByName
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            value={filterByName}
            placeholder={intl.getI18nMessage('label.user.list.page.filter.by.name.placeholder')}
            handleChange={onFilterByNameChange}
            onKeyPress={onFilterByNameKeyPress}
            disabled={filterByEmail.length > 0}
          />
          <FilterByEmail
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            value={filterByEmail}
            placeholder={intl.getI18nMessage('label.user.list.page.filter.by.email.placeholder')}
            handleChange={onFilterByEmailChange}
            onKeyPress={onFilterByEmailKeyPress}
            hideLabel
            disabled={filterByName.length > 0}
          />
          {isPublisher &&
            <FilterByOrganization
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter')}
              data={organizations}
              value={filterByOrganization}
              handleChange={onFilterByOrganizationChange}
              hideLabel
            />
          }
          <FilterByRole
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            selectFieldClass={classes.selectField}
            value={filterByRole}
            handleChange={onFilterByRoleChange}
            hideLabel
            isPublisher={isPublisher}
          />
          <FilterByStatus
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            selectFieldClass={classes.selectField}
            value={filterByStatus}
            handleChange={onFilterByStatusChange}
            hideLabel
          />
          <FilterAndSortSeparator />
          <SortBy
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.sort')}
            selectFieldClass={classes.selectField}
            value={sortBy}
            handleChange={onSortByChange}
            hidden
          />
        </Fragment>
      )}
      columns={USERS_LIST_COLUMNS}
      rows={results}
      noResultsMessage={intl.getI18nMessage('label.user.filter.no.results')}
      page={page}
      totalElements={totalElements}
      totalPages={Math.ceil(totalElements / rowsPerPage)}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onChangePreviousPage={onChangePreviousPage}
      onChangeNextPage={onChangeNextPage}
    />
  );
};

UserList.propTypes = {
  classes: object,
  userContext: object,
  isLoading: bool,
  isError: bool,
  userErrors: arrayOf(object || string),
  totalElements: number,
  results: arrayOf(object),
  isUserDeleteSuccess: bool,
  lockUserCreation: bool,
  resetDeleteNotification: func,
  organizations: arrayOf(object),
  resetApp: func,
  fetchAllOrganizations: func,
  fetchUsers: func,
  fetchLockUserCreation: func,
  push: func,
};

const mapStateToProps = (state) => ({
  config: getConfig(state),
  userContext: getUserDetails(state),
  isError: getIsError(state),
  userErrors: getErrors(state),
  isLoading: false,
  totalElements: getUserListTotalElements(state),
  results: getUserListResults(state),
  isUserDeleteSuccess: getIsUserDeleteSuccess(state),
  lockUserCreation: getLockUserCreation(state),
  organizations: getOrganizationListResults(state),
});

const mapDispatchToProps = {
  push,
  resetApp,
  fetchUsers,
  fetchLockUserCreation,
  resetDeleteNotification,
  fetchAllOrganizations,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(UserList);
