import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
} from '@material-ui/core';

import {
  PROXY_NAME_TITLE,
  UNIQUE_PROXY_NAME_WARNING_MSG,
  MAXLENGTH_PROXY_NAME_WARNING_MSG,
  API_DEPLOYMENT_TYPE_TITLE,
  API_DEPLOYMENT_TYPE_TOOLTIP_TEXT,
  KEY_DEPLOYMENT_TYPE_TITLE,
  KEY_DEPLOYMENT_TYPE_TOOLTIP_TEXT, DETAILS_TAB_TITLE,
} from '../labels';
import { validateProxyUniqueName } from '../../../../actions/apiProxy';
import { FormTextField, FormRadioGroup } from '../../../../components';

const API_PROXY_TYPE_ITEMS = [
  {
    id: 'AUTOMATIC',
    name: 'Automatic',
  },
  {
    id: 'ON_DEMAND',
    name: 'On Demand',
  },
  {
    id: 'MANUAL',
    name: 'Scripted',
  },
];

const API_KEY_PROXY_TYPE_ITEMS = [
  {
    id: 'AUTOMATIC',
    name: 'Automatic',
  },
  {
    id: 'ON_DEMAND',
    name: 'On Demand',
  },
];
const containsError = (errors, key) => errors.filter((e) => e.key === key).length > 0;
const containsErrorByType = (errors, key, type) =>
  errors.filter((e) => e.key === key && e.type === type).length > 0;

const ProxyName = ({
  classes,
  config,
  dispatch,
  currentProxyName,
  setCurrentProxyName,
  proxyDetails,
  errors,
}) => (
  <div id="proxy-name-container" className={classes.fieldContainer}>
    <FormTextField
      id={'proxy-name'}
      name={PROXY_NAME_TITLE}
      value={currentProxyName}
      error={containsError(errors, 'proxyName')}
      handleChange={
        setCurrentProxyName
      }
      handleBlur={
        () => validateProxyUniqueName({
          config,
          dispatch,
          proxyDetails,
          proxyName: currentProxyName,
        })
      }
      helperText={
        containsErrorByType(errors, 'proxyName', 'unique')
        ? UNIQUE_PROXY_NAME_WARNING_MSG
        : MAXLENGTH_PROXY_NAME_WARNING_MSG
      }
      maxLength="255"
    />
  </div>
);

ProxyName.propTypes = {
  classes: PropTypes.object,
  config: PropTypes.object,
  currentProxyName: PropTypes.string,
  setCurrentProxyName: PropTypes.func,
  proxyDetails: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func,
};

const APIProxyTypes = ({
  classes,
  items,
  currentApiProxyType,
  setCurrentApiProxyType,
}) => (
  <div id="api-deployment-type-container" className={classes.fieldContainer}>
    <FormRadioGroup
      id="api-spec-authentication-type"
      name={API_DEPLOYMENT_TYPE_TITLE}
      tooltip={API_DEPLOYMENT_TYPE_TOOLTIP_TEXT}
      selected={currentApiProxyType}
      onChange={setCurrentApiProxyType}
      data={items}
    />
  </div>
);

APIProxyTypes.propTypes = {
  classes: PropTypes.object,
  currentApiProxyType: PropTypes.string,
  setCurrentApiProxyType: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
};

const APIKeyProxyTypes = ({
  classes,
  items,
  currentApiKeyProxyType,
  setCurrentApiKeyProxyType,
}) => (
  <div id="apikey-deployment-type-container" className={classes.fieldContainer}>
    <FormRadioGroup
      id="api-spec-authentication-type"
      name={KEY_DEPLOYMENT_TYPE_TITLE}
      tooltip={KEY_DEPLOYMENT_TYPE_TOOLTIP_TEXT}
      selected={currentApiKeyProxyType}
      onChange={setCurrentApiKeyProxyType}
      data={items}
    />
  </div>
);

APIKeyProxyTypes.propTypes = {
  classes: PropTypes.object,
  currentApiKeyProxyType: PropTypes.string,
  setCurrentApiKeyProxyType: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default function Details(props) {
  const {
    classes,
    config,
    dispatch,
    proxyNameUniqueStatus,
    currentProxyName,
    setCurrentProxyName,
    currentApiProxyType,
    setCurrentApiProxyType,
    currentApiKeyProxyType,
    setCurrentApiKeyProxyType,
    proxyDetails,
    errors,
  } = props;

  return (
    <Fragment>
      <h1 className={classes.pageTitle}>{DETAILS_TAB_TITLE}</h1>
      <Divider />
      <ProxyName
        classes={classes}
        config={config}
        dispatch={dispatch}
        proxyNameUniqueStatus={proxyNameUniqueStatus}
        currentProxyName={currentProxyName}
        setCurrentProxyName={setCurrentProxyName}
        proxyDetails={proxyDetails}
        errors={errors}
      />
      <APIProxyTypes
        classes={classes}
        currentApiProxyType={currentApiProxyType}
        setCurrentApiProxyType={setCurrentApiProxyType}
        items={API_PROXY_TYPE_ITEMS}
      />
      <APIKeyProxyTypes
        classes={classes}
        currentApiKeyProxyType={currentApiKeyProxyType}
        setCurrentApiKeyProxyType={setCurrentApiKeyProxyType}
        items={API_KEY_PROXY_TYPE_ITEMS}
      />
    </Fragment>
  );
}

Details.propTypes = {
  classes: PropTypes.object,
  config: PropTypes.object,
  currentProxyName: PropTypes.string,
  setCurrentProxyName: PropTypes.func,
  proxyNameUniqueStatus: PropTypes.string,
  currentApiProxyType: PropTypes.string,
  setCurrentApiProxyType: PropTypes.func,
  currentApiKeyProxyType: PropTypes.string,
  setCurrentApiKeyProxyType: PropTypes.func,
  proxyDetails: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func,
};
