import { get } from 'lodash';

const styles = ({ color, fontSize, typography, borderRadius, margin, padding }) => ({
  actionsContainer: {
    display: 'inline-block',
    float: 'right',
    borderColor: get(color, 'primaryButtonBackground'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    borderRadius: get(borderRadius, 'br4'),
    margin: `0 ${get(margin, 'm36')}`,
    color: get(color, 'primaryButtonText'),
    '& nav': {
      padding: get(padding, 'p0'),
      '& > div > div > span': {
        color: get(color, 'primaryButtonText'),
      },
    },
    minWidth: 220,
  },
  deleteButtonPopup: {
    backgroundColor: `${get(color, 'error')} !important`,
    borderColor: `${get(color, 'error')} !important`,
  },
  deleteButton: {
    lineHeight: 'inherit',
    color: `${get(color, 'error')} !important`,
    backgroundColor: `${get(color, 'white')} !important`,
    borderColor: `${get(color, 'error')} !important`,
    '&:[disabled]': {
      backgroundColor: `${get(color, 'green')} !important`,
    },
  },
  addApplicationDialog: {
    '& .MuiTypography-colorTextSecondary' : {
      color: get(color, 'bodyText'),
    },
  },
  submitButton: {
    backgroundColor: `${get(color, 'red')} !important`,
  },
});

export default styles;
