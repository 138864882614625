import React from 'react';
import {
  FormLabel,
  TextField,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles';

export default function FormDefaultTextField(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    id,
    name,
    value,
    helperText,
    defaultText,
    isDisabled = false,
    multiline = false,
    rows = 1,
    handleChange,
    handleBlur,
    error = false,
  } = props;
  const defaultClass = error ? clsx(classes.defaultTextField, classes.error)
    : classes.defaultTextField;
  return (
    <div className={classes.fieldContainer} id={id} data-apim-test={id}>
      <div>
        <FormLabel className={classes.formLabel}>
          {name}
        </FormLabel>
      </div>
      <div>
        {defaultText &&
          <div className={defaultClass}>
            {defaultText}
          </div>
        }
        <TextField
          classes={{
            root: classes.textField,
          }}
          variant="outlined"
          multiline={multiline}
          error={error}
          rows={rows}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={(e) => handleBlur && handleBlur(e.target.value)}
          value={value}
          disabled={isDisabled}
        />
      </div>
      <div>
        <FormHelperText className={classes.helperText} error={error}>
          {helperText}
        </FormHelperText>
      </div>
    </div>
  );
}

FormDefaultTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  error: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.string,
  rows: PropTypes.number,
  defaultText: PropTypes.string,
  multiline: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
