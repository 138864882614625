import React, { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, Link, Tooltip } from '@material-ui/core';

import { resetApp, fetchGatewayBundles, resetDeleteNotification } from '../../../actions/gatewayBundle';
import { getConfig, getUserDetails } from '../../../reducers/portalConfig';
import {
  getGatewayBundlesListTotalPages,
  getGatewayBundlesListTotalElements,
  getGatewayBundlesListResults,
  getIsError,
  getErrors,
  getIsBundleDeleteSuccess,
} from '../../../reducers/gatewayBundle';
import styles from './styles';
import ListContainer from '../../list';
import { FilterByName, FilterByGroup, FilterByType, FilterByPortalTemplate, SortBy, FilterAndSortSeparator } from './controls';
import {
  ALERT_SUCCESS,
  ALERT_ERROR,
  SYSTEM_BUNDLE_GROUP_NAME,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
} from '../../../constants';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import getDateTime, { getTimeZoneAbbr } from '../../../utils/datetime';
import { getVersion, getTitleCaseText } from '../../../utils';

const getLinkContent = (value, uuid) => (
  <Link underline="none" href={`/publish/gateway-bundles/details/${uuid}`}>
    {value}
  </Link>
);

const getTooltipContent = (classes) => (
  <Tooltip
    title={getI18nFormattedMessage('label.system.bundle.actions.delete.disabled.tooltip')}
    aria-label={getI18nFormattedMessage('label.system.bundle.actions.delete.disabled.tooltip')}
  >
    <span className={classes.tooltipContainer}>
      {getI18nFormattedMessage('label.system')}
    </span>
  </Tooltip>
);
  
const getGatewayBundleListColumns = (classes) => [{
  id: 'name',
  secondaryId: 'groupName',
  label: getI18nFormattedMessage('label.name'),
  minWidth: 200,
  format: (value, secondaryValue, uuid) => {
    const linkEl = getLinkContent(value, uuid)
    if (secondaryValue === SYSTEM_BUNDLE_GROUP_NAME) {
      const content = getTooltipContent(classes);
      return <div>{linkEl}  {content}</div>;
    }
    return linkEl;
  },
}, {
  id: 'groupName',
  label: getI18nFormattedMessage('label.group'),
  minWidth: 100,
}, {
  id: 'version',
  label: getI18nFormattedMessage('label.version'),
  format: value => getVersion(value),
  minWidth: 100,
}, {
  id: 'type',
  label: getI18nFormattedMessage('label.type'),
  format: value => getTitleCaseText(value),
  minWidth: 100,
}, {
  id: 'l7Template',
  label: getI18nFormattedMessage('label.policyTemplate'),
  minWidth: 100,
  format: value => getTitleCaseText(value.toString()),
}, {
  id: 'createTs',
  label: getI18nFormattedMessage('label.gateway.bundle.date.created', { zone: getTimeZoneAbbr() }),
  format: value => getDateTime(value),
}];

export const GatewayBundleList = (props) => {
  const {
    classes,
    userContext,
    isError,
    gatewayBundleErrors = [],
    isLoading,
    totalPages,
    totalElements = 0,
    results = [],
    isBundleDeleteSuccess,
  } = props;

  const intl = getI18n(useIntl());
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [filterByName, setFilterByName] = useState('');
  const [filterByGroup, setFilterByGroup] = useState('');
  const [filterByType, setFilterByType] = useState('any');
  const [filterByPortalTemplate, setFilterByPortalTemplate] = useState('any');
  const [filterByAnyApplied, setFilterByAnyApplied] = useState(false);
  const [sortBy, setSortBy] = useState('createTs,DESC');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  useEffect(() => {
    props.fetchGatewayBundles(filterByName, filterByGroup, sortBy, filterByType,
      filterByPortalTemplate, page, rowsPerPage);
    if (isBundleDeleteSuccess) {
      notifyMessages(ALERT_SUCCESS, intl.getI18nMessage('label.gateway.bundle.deleted'));
      setTimeout(props.resetDeleteNotification, 2500);
    }
  }, []);

  useEffect(() => {
    if (!userContext.portalAdmin) { props.push('/404'); }
  }, [userContext]);

  useEffect(() => {
    if (isError) {
      notifyMessages(ALERT_ERROR, intl.getI18nMessage('error.gateway.bundle.fetch'));
    } else if (isBundleDeleteSuccess) {
      notifyMessages(ALERT_SUCCESS, intl.getI18nMessage('label.gateway.bundle.deleted'));
    } else {
      notifyMessages('', '');
    }
  }, [isError, gatewayBundleErrors, isBundleDeleteSuccess]);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByGroupChange = (value) => {
    setFilterByGroup(value);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilterByAnyApplied(true);
      setPage(0);
      props.fetchGatewayBundles(filterByName, filterByGroup, sortBy, filterByType,
        filterByPortalTemplate, 0, rowsPerPage);
    }
  };

  const onFilterByGroupKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilterByAnyApplied(true);
      setPage(0);
      props.fetchGatewayBundles(filterByName, filterByGroup, sortBy, filterByType,
        filterByPortalTemplate, 0, rowsPerPage);
    }
  };

  const onFilterByTypeChange = (newType) => {
    setFilterByType(newType);
    setFilterByAnyApplied(true);
    setPage(0);
    props.fetchGatewayBundles(filterByName, filterByGroup, sortBy, newType,
      filterByPortalTemplate, 0, rowsPerPage);
  };
  const onFilterByPortalTemplateChange = (newPortalTemplate) => {
    setFilterByPortalTemplate(newPortalTemplate);
    setFilterByAnyApplied(true);
    setPage(0);
    props.fetchGatewayBundles(filterByName, filterByGroup, sortBy, filterByType,
      newPortalTemplate, 0, rowsPerPage);
  };

  const onSortByChange = (newSortBy) => {
    setSortBy(newSortBy);
    setPage(0);
    props.fetchGatewayBundles(filterByName, filterByGroup, newSortBy, filterByType,
      filterByPortalTemplate, 0, rowsPerPage);
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchGatewayBundles(filterByName, filterByGroup, sortBy, filterByType,
      filterByPortalTemplate, newPage, rowsPerPage);
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchGatewayBundles(filterByName, filterByGroup, sortBy, filterByType,
      filterByPortalTemplate, 0, newRowsPerPage);
  };

  const onAddBundle = () => {
    props.resetApp();
    props.push('/publish/gateway-bundles/add');
  };

  const noResultsMessage = filterByAnyApplied ?
    `${intl.getI18nMessage('label.gateway.bundle.filter.no.results')}\n${intl.getI18nMessage('label.gateway.bundle.list.page.title.help')}\n${intl.getI18nMessage('label.gateway.bundle.use.add.button.help')}` :
    intl.getI18nMessage('label.gateway.bundle.no.results');

  return (
    <ListContainer
      listPageId="gateway-bundle-list-page"
      isLoading={isLoading}
      notificationId="gateway-bundle-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationMessage}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      pageHeaderTitle={intl.getI18nMessage('label.gateway.bundle.list.page.title')}
      pageHeaderTooltipTitle={intl.getI18nMessage('label.gateway.bundle.list.page.title.help')}
      addButtonLabel={intl.getI18nMessage('label.gateway.bundle.add.button')}
      onAdd={onAddBundle}
      filterAndSortContent={(
        <Fragment>
          <FilterByName
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            value={filterByName}
            placeholder={intl.getI18nMessage('label.gateway.bundle.list.page.filter.by.name.placeholder')}
            handleChange={onFilterByNameChange}
            onKeyPress={onFilterByNameKeyPress}
          />
          <FilterByGroup
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            value={filterByGroup}
            placeholder={intl.getI18nMessage('label.gateway.bundle.list.page.filter.by.group.placeholder')}
            handleChange={onFilterByGroupChange}
            onKeyPress={onFilterByGroupKeyPress}
            hideLabel
          />
          <FilterByType
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            selectFieldClass={classes.selectField}
            value={filterByType}
            handleChange={onFilterByTypeChange}
            hideLabel
          />
          <FilterByPortalTemplate
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            selectFieldClass={classes.selectField}
            value={filterByPortalTemplate}
            handleChange={onFilterByPortalTemplateChange}
            hideLabel
          />
          <FilterAndSortSeparator />
          <SortBy
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.sort')}
            selectFieldClass={classes.selectField}
            value={sortBy}
            handleChange={onSortByChange}
          />
        </Fragment>
      )}
      columns={getGatewayBundleListColumns(classes)}
      rows={results}
      noResultsMessage={noResultsMessage}
      page={page}
      totalElements={totalElements}
      totalPages={totalPages}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onChangePreviousPage={onChangePreviousPage}
      onChangeNextPage={onChangeNextPage}
    />
  );
};

GatewayBundleList.propTypes = {
  classes: object,
  userContext: object,
  isLoading: bool,
  isError: bool,
  gatewayBundleErrors: arrayOf(object || string),
  push: func,
  resetApp: func,
  totalPages: number,
  totalElements: number,
  results: arrayOf(object),
  isBundleDeleteSuccess: bool,
  fetchGatewayBundles: func,
  resetDeleteNotification: func,
};

const mapStateToProps = (state) => ({
  config: getConfig(state),
  userContext: getUserDetails(state),
  isError: getIsError(state),
  gatewayBundleErrors: getErrors(state),
  isLoading: false,
  totalPages: getGatewayBundlesListTotalPages(state),
  totalElements: getGatewayBundlesListTotalElements(state),
  results: getGatewayBundlesListResults(state),
  isBundleDeleteSuccess: getIsBundleDeleteSuccess(state),
});

const mapDispatchToProps = {
  push,
  resetApp,
  fetchGatewayBundles,
  resetDeleteNotification,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(GatewayBundleList);
