import { pascalCase } from 'change-case';

const API_STATUS_DISABLED = 'DISABLED';
const API_STATUS_ENABLED = 'ENABLED';
const API_STATUS_NEW = 'NEW';
const API_STATUS_DEPRECATED = 'DEPRECATED';
// const API_STATUS_INCOMPLETE = 'INCOMPLETE';
// const API_STATUS_PENDING_BUNDLE = 'PENDING_BUNDLE';

export const getStatusLabel = (status = '') =>
((status.toUpperCase() === API_STATUS_NEW) ?
  'Unpublished' : pascalCase(status));

export const getStatusColor = (status = '') => {
  const greenStates = [API_STATUS_ENABLED];
  const grayStates = [API_STATUS_DISABLED];
  const orangeStates = [API_STATUS_DEPRECATED];
  const darkBlueStates = [API_STATUS_NEW];
  // red for API_STATUS_INCOMPLETE and PENDING_BUNDLE (api doesn't return api with this state)
  let statusColor = '#d84332';
  const apiStatus = status.toUpperCase();
  if (greenStates.includes(apiStatus)) {
    statusColor = '#59bb52';
  } else if (orangeStates.includes(apiStatus)) {
    statusColor = '#f7922f';
  } else if (grayStates.includes(apiStatus)) {
    statusColor = '#b3bdc2';
  } else if (darkBlueStates.includes(apiStatus)) {
    statusColor = '#20465f';
  }
  return statusColor;
};