import React from 'react';
import { Grid } from '@material-ui/core';

import { FormSelect, FormTextField } from '../../../../components';

import {
  DEPLOYMENT_FILTER_BY_STATE,
} from '../constants';

export const FilterByName = (props) => (
  <Grid item md={3}>
    <FormTextField
      {...props}
      id="gateway-bundle-deployments-filter-by-name"
      data-apim-test="gateway-bundle-deployments-filter-by-name"
    />
  </Grid>
);

export const FilterByDeploymentState = (props) => (
  <Grid item md={3}>
    <FormSelect
      {...props}
      id="gateway-bundle-deployments-filter-by-state"
      data-apim-test="gateway-bundle-deployments-filter-by-state"
      data={DEPLOYMENT_FILTER_BY_STATE}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

