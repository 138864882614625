import React from 'react';
import { Grid } from '@material-ui/core';

import { FormSelect } from '../../../../components';

import {
  PROXY_APIS_SORT_BY,
  PROXY_APIS_STATES_FILTER_BY,
  APIS_PUBLISH_SOURCES_FILTER_BY,
  APIS_DEPLOYMENT_STATUS_FILTER_BY,
} from '../../../../constants';

export const FilterByPublishSources = (props) => (
  <Grid item md={3}>
    <FormSelect
      {...props}
      id="proxy-details-apis-filter-publish-source-by"
      data-apim-test="proxy-details-apis-filter-publish-source-by"
      data={APIS_PUBLISH_SOURCES_FILTER_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterByDeploymentStatus = (props) => (
  <Grid item md={3}>
    <FormSelect
      {...props}
      id="proxy-details-apis-filter-status-by"
      data-apim-test="proxy-details-apis-filter-status-by"
      data={APIS_DEPLOYMENT_STATUS_FILTER_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterByStates = (props) => (
  <Grid item md={2}>
    <FormSelect
      {...props}
      id="proxy-details-apis-filter-state-by"
      data-apim-test="proxy-details-apis-filter-state-by"
      data={PROXY_APIS_STATES_FILTER_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const SortBy = (props) => (
  <Grid item md={3}>
    <FormSelect
      {...props}
      id="proxy-details-apis-sort-by"
      data-apim-test="proxy-details-apis-sort-by"
      data={PROXY_APIS_SORT_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterAndSortSeparator = () => (
  <Grid item md={1} />
);
