import React, { useState, useEffect , Fragment } from 'react';
import { useIntl } from 'react-intl';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import { shape } from 'prop-types';

import {
  fetchOrganization,
  fetchRateLimitQuotas,
  fetchOrganizationNameUnique,
  createOrganization,
  updateOrganization,
  resetApp,
} from '../../../actions/organization';
import styles from './styles';
import {
  getOrganizationDetails,
  getIsFormLoading,
  getIsFormLoadingError,
  getIsOrganizationNameUnique,
  getIsFormValidationError,
  getIsFormCreateError,
  getIsFormUpdateError,
  getUpdateErrorMsg,
  getCreateErrorMsg,
  getIsFormSubmissionSuccessful,
  getRateLimitQuotasPage,
  getRateLimitQuotas,
} from '../../../reducers/organization';
import { getUserDetails } from '../../../reducers/portalConfig';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import {
  hasPublisherRole,
  hasAdminRole,
} from '../../../utils';
import {
  HELP_ITEMS,
} from './labels';
import { DetailsHeader , TabsContainer , TabPanel } from '../../../components';
import DetailsContainer from '../../details';
import { getTabIndex } from '../../../utils';
import OrganizationOverview from './Overview';
import OrganizationAPIAccess from './APIAccess';

const getTabItems = () => [
  {
    id: 'org-overview',
    tabId: 'org-overview-tab',
    label: getI18nFormattedMessage('label.organization.details.page.overview.title'),
    visible: true,
  },
  {
    id: 'org-apiaccess',
    tabId: 'org-apiaccess-tab',
    label: getI18nFormattedMessage('label.apis'),
    visible: true,
  },
];

export const OrganizationDetails = (props) => {
  const {
    orgUuid,
    orgDetails,
    userContext,
    isLoading,
    classes,
  } = props;

  const tabItems = () => {
    const tabs = getTabItems();
    if(get(orgDetails, 'status') !== 'ENABLED') {
      tabs.splice(1,1);
    }
    if(!hasAdminRole(userContext) && hasPublisherRole(userContext)) {
      tabs.splice(0,1);
    }
    return tabs;
  }

  const [currentTab, setCurrentTab] = useState(
    getTabIndex(tabItems, props.match),
  );

  const handleTabChange = (e, newValue) => {
    setCurrentTab(newValue);
  };

  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const intl = getI18n(useIntl());
  
  const getHelpItems = () => HELP_ITEMS;
 
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onDialogClose = () => {
    setIsDialogOpen(false);
  };

  const isAdmin = () => hasAdminRole(userContext);
  const isEnabledOrg = () => get(orgDetails, 'status') === 'ENABLED';
  useEffect(() => {
    setCurrentTab(getTabIndex(tabItems, props.match));
  }, [props.match]);

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  useEffect(() => {
    if (userContext) {
      if (!hasPublisherRole(userContext)) {
        props.push('/404');
      }
    }
  }, [userContext]);
  
  return (<DetailsContainer
    classes={classes}
    detailsPageId="organization-details-page"
    dialogId="organization-unsaved-dialog"
    isDialogOpen={isDialogOpen}
    onDialogClose={onDialogClose}
    dialogSubmitText={intl.getI18nMessage('label.dialog.unsaved.changes.exit')}
    isLoading={isLoading}
    leftSidebarId="organization-edit-left-sidebar"
    mainContentId="organization-edit-main-content"
    notificationId="organization-notifications"
    notificationStatus={notificationStatus}
    setNotificationStatus={setNotificationStatus}
    notificationMessage={notificationMessage}
    setNotificationMessage={setNotificationMessage}
    header={
      <DetailsHeader
        headerId="orgainzation-details-header-container"
        title={intl.getI18nMessage('label.organization.form.page.organization.details')}
        name={get(orgDetails, 'name')}
      />
    }
    tabs={
      <TabsContainer
        id={'org-details-tab-container'}
        tabValue={currentTab}
        orientation="horizontal"
        tabItems={tabItems().filter(({ visible }) => visible)}
        handleTabChange={handleTabChange}
      />
    }
    page={
      <Fragment>
        <TabPanel
          visible={isAdmin()?currentTab === 0 : false}
          id="org-overview-tab-panel"
        >
          <OrganizationOverview
            {...props}
            isCurrentTab={currentTab === 0}
            orgUuid={orgUuid}
            notifyMessages={notifyMessages}
          />
        </TabPanel>
        <TabPanel
          visible={isEnabledOrg() && currentTab === (isAdmin() ? 1 : 0)}
          id="org-apiaccess-tab-panel"
        >
         <OrganizationAPIAccess
            {...props}
            isCurrentTab={currentTab === (isAdmin() ? 1 :0)}
            orgUuid={orgUuid}
            notifyMessages={notifyMessages}
          />
        </TabPanel>
      </Fragment>
    }
    rightSidebarId={'organization-details-right-sidebar'}
    helpItems={getHelpItems()}
  />);
};

OrganizationDetails.propTypes = {
  classes: PropTypes.object,
  isEditMode: PropTypes.bool,
  orgUuid: PropTypes.string,
  isLoading: PropTypes.bool,
  orgDetails: PropTypes.object,
  accountPlans: PropTypes.arrayOf(PropTypes.object),
  isFormLoadingError: PropTypes.bool,
  isFormValidationError: PropTypes.bool,
  history: PropTypes.object,
  isOrgNameUnique: PropTypes.bool,
  isCreateError: PropTypes.bool,
  isUpdateError: PropTypes.bool,
  isFormSubmissionSuccessful: PropTypes.bool,
  createErrorMsg: PropTypes.string,
  updateErrorMsg: PropTypes.string,
  userContext: PropTypes.object,
  resetApp: PropTypes.func,
  fetchRateLimitQuotas: PropTypes.func,
  push: PropTypes.func,
  fetchOrganization: PropTypes.func,
  fetchOrganizationNameUnique: PropTypes.func,
  updateOrganization: PropTypes.func,
  createOrganization: PropTypes.func,
  rateLimitQuotas:PropTypes.arrayOf(PropTypes.object),
  rateLimitQuotasPage: PropTypes.object,
  isRateLimitQuotaLoad: PropTypes.bool,
  match: shape({}),
};

const mapStateToProps = (state, props) => ({
  orgDetails: getOrganizationDetails(state),
  isLoading: getIsFormLoading(state, props.isEditMode),
  isFormLoadingError: getIsFormLoadingError(state),
  isFormValidationError: getIsFormValidationError(state),
  isOrgNameUnique: getIsOrganizationNameUnique(state),
  isCreateError: getIsFormCreateError(state),
  isUpdateError: getIsFormUpdateError(state),
  updateErrorMsg: getUpdateErrorMsg(state),
  createErrorMsg: getCreateErrorMsg(state),
  isFormSubmissionSuccessful: getIsFormSubmissionSuccessful(state),
  userContext: getUserDetails(state),
  rateLimitQuotasPage: getRateLimitQuotasPage(state),
  rateLimitQuotas: getRateLimitQuotas(state),
});

const mapDispatchToProps = {
  fetchOrganization,
  fetchRateLimitQuotas,
  fetchOrganizationNameUnique,
  createOrganization,
  updateOrganization,
  resetApp,
  push,
};

const getOrgUuid = (props) => get(props, 'match.params.orgUuid', '');

const addProps = (props) => ({
  orgUuid: getOrgUuid(props),
  isEditMode: !!getOrgUuid(props),
});

export default compose(
  withStyles(styles),
  withProps(addProps),
  connect(mapStateToProps, mapDispatchToProps),
)(OrganizationDetails);
