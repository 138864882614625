import colorMod from 'color';
import get from 'lodash/get';

export default ({ color, fontSize, typography }) => ({
  Header: {
    boxSizing: 'border-box',
    backgroundColor: get(color, 'headerBackground'),
    padding: '34px 36px 24px 36px',
  },

  HeaderInner: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  HeaderSearch: {
    display: 'inline-flex',
    height: 38,
  },

  Title: {
    color: color.pageTitle,
    fontFamily: typography.pageTitle,
    fontSize: fontSize.pageTitle,
  },

  GoButton: {
    width: 50,
  },

  HeaderGoButton: {
    padding: '0px !important',
    minWidth: 47,
    backgroundColor: color.primaryButtonText,
    width: 47,
    height: 36,
    fontSize: `${fontSize.buttonText} !important`,
    fontFamily: `${typography.buttonText} !important`,
    textTransform: 'none',
    color: color.primaryButtonBackground,
    boxShadow: 'none',
    borderRadius: '0px 3px 3px 0px',
    '&:disabled': {
      color: `${colorMod(color.bodyText).alpha(0.7).string()} !important`,
      backgroundColor: `${colorMod(color.bodyText).alpha(0.1).string()} !important`,
    },
    '&:hover': {
      background: `${colorMod(color.primaryButtonBackground).alpha(0.08).string()} !important`,
      color: `${color.primaryButtonBackground} !important`,
    },
    '&:focus': {
      border: `1px solid ${color.primaryButtonBackground}`,
      boxShadow: `0 0 0 1px ${color.primaryButtonBackground} !important`,
      transition: 'none',
    },
  },

  '@media screen and (min-width: 330px) and (max-width: 930px)': {
    HeaderInner: {
      flexDirection: 'column-reverse',
    },

    Title: {
      fontSize: fontSize.siteTitle,
    },

    HeaderSearch: {
      marginBottom: 34,
    },
  },
});
