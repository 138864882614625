import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { capitalCase } from 'change-case';
import { useIntl } from 'react-intl';
import get from 'lodash/get';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import LensIcon from '@material-ui/icons/Lens';
import HelpIcon from '@material-ui/icons/Help';
import AlertDialog from '../../../../../components/AlertDialog';

import { hasAdminRole, formatStatusMessage } from '../../../../../utils';
import { getDeployedStatusColor, getDeployedStatusLabel } from '../../../../../utils/deployment';

import {
  KEY_DEPLOYMENT_TYPE_LABEL,
  DEPLOYMENT_ACTION_TYPE_DEPLOY,
  DEPLOYMENT_ACTION_TYPE_REDEPLOY,
  DEPLOYMENT_ACTION_TYPE_UNDEPLOY,
  DEPLOYMENT_ACTION_LABEL_DEPLOY,
  DEPLOYMENT_ACTION_LABEL_REDEPLOY,
  DEPLOYMENT_ACTION_LABEL_UNDEPLOY,
  MORE_INFO_TEXT,
  CHECK_NOW_TEXT,
  UPDATING_STATUS_TEXT,
  SECONDS_UNIT_TEXT,
  DEPLOYMENT_STATUS_ERROR,
  DEPLOYMENT_STATUS_LABEL_UNDEPLOYED,
  DEPLOYMENT_STATUS_LABEL_VALIDATION_ERROR,
  DEPLOYMENT_STATUS_LABEL_DEPLOYMENT_ERROR,
  APP_DEPLOYMENT_TYPE_ON_DEMAND,
  INFORMATION_TEXT,
  WARNING_TEXT,
  CONFIRM_ACTION_TEXT,
  OK_TEXT,
  CANCEL_TEXT,
} from '../../labels';

import { getI18n, getI18nFormattedMessage } from '../../../../../utils/intl';

import styles from '../styles';

const renderTitle = (name = '', uuid, classes) => (
  <span data-test={'proxy-title'} className={classes.linkWrapper}>
    <Link href={`/publish/api-proxy/details/${uuid}`}>
      <h4 className={classes.cardTitle}> {name} </h4>
    </Link>
  </span>
);

export const renderHelpText = (classes) => (
  <p className={classes.helpTextContainer}>
    <span className={classes.boldText}>{getI18nFormattedMessage('label.application.key.deployment.tooltip.title')}</span><br />
    <span>{getI18nFormattedMessage('label.application.key.deployment.tooltip.subtitle')}</span><br />
    <span>
      <span className={classes.boldText}>&#8226; {getI18nFormattedMessage('label.application.key.deployment.automatic.tooltip.title')}: </span>
      {getI18nFormattedMessage('label.application.key.deployment.automatic.tooltip.subtitle')}
    </span>
    <br />
    <span>
      <span className={classes.boldText}>&#8226; {getI18nFormattedMessage('label.application.key.deployment.on.demand.tooltip.title')}: </span>
      {getI18nFormattedMessage('label.application.key.deployment.on.demand.tooltip.subtitle')}
    </span>
  </p>
);

export const formatDateString = (dateString) => {
  if (dateString) {
    const arrDateFields = dateString.split(' ');
    const month = arrDateFields[1];
    const date = arrDateFields[2];
    const year = arrDateFields[3];

    let formattedTimeString = '';
    if (arrDateFields[4]) { // time string
      const arrTimeFields = arrDateFields[4].split(':');
      const hour = arrTimeFields[0];
      const mins = arrTimeFields[1];
      formattedTimeString = `${hour}:${mins}`;
    }
    return `${month} ${date} ${year} ${formattedTimeString}`;
  }
  return '';
};

export const getStatusColor = (errorMessage, status) => {
  let statusLabel = '';
  let statusColor = '';
  if (errorMessage) {
    statusLabel = DEPLOYMENT_STATUS_LABEL_VALIDATION_ERROR;
    statusColor = getDeployedStatusColor(DEPLOYMENT_STATUS_ERROR);
  } else {
    statusLabel = (status === DEPLOYMENT_STATUS_ERROR)
      ? DEPLOYMENT_STATUS_LABEL_DEPLOYMENT_ERROR
      : getDeployedStatusLabel(status);
    statusColor = getDeployedStatusColor(status);
  }
  return {
    statusLabel, statusColor,
  }
};

export const DeploymentsCard = ({
  index,
  details: {
    apiKey = '',
    uuid,
    name,
    type,
    status,
    statusMessage,
    modifyTs,
  },
  errorObj = {},
  refreshTime = 10,
  deployApiKey,
  redeployApiKey,
  undeployApiKey,
  checkStatus,
  user,
  classes,
}) => {
  const intl = getI18n(useIntl());
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState('');
  const [updating, setUpdating] = useState(false);
  const [timer, setTimer] = useState(parseInt(refreshTime, 10));

  const lastModified = modifyTs ?
    formatDateString(new Date(modifyTs).toString()) :
    getI18nFormattedMessage('label.not.applicable');
  // Error Info
  const errorMessage = get(errorObj, 'errorMessage');

  const startAction = (action) => {
    switch (action) {
      case DEPLOYMENT_ACTION_TYPE_DEPLOY: {
        deployApiKey(apiKey, uuid);
        break;
      }
      case DEPLOYMENT_ACTION_TYPE_REDEPLOY: {
        redeployApiKey(apiKey, uuid);
        break;
      }
      case DEPLOYMENT_ACTION_TYPE_UNDEPLOY: {
        undeployApiKey(apiKey, uuid);
        break;
      }
      default: {
        break;
      }
    }
    setUpdating(!updating);
  };

  const stopAction = () => {
    setTimer(refreshTime);
    setUpdating(false);
    checkStatus(apiKey);
  };

  useEffect(() => {
    let interval = null;
    if (updating) {
      interval = setInterval(() => {
        setTimer(timer - 1);
        if (timer === 1) {
          stopAction();
          clearInterval(interval);
        }
      }, 1000);
    } else if (!updating && timer !== refreshTime) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [updating, timer]);

  const { statusLabel, statusColor } = getStatusColor(errorMessage, status);
  const statusStyle = { color: statusColor };

  const openStatusDialog = () => setShowStatusDialog(true);
  const closeStatusDialog = () => setShowStatusDialog(false);

  const openConfirmDialog = (action) => {
    setShowConfirmDialog(true);
    setConfirmAction(action);
  };

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false);
    setConfirmAction('');
  };

  const saveConfirmDialog = () => {
    startAction(confirmAction);
    closeConfirmDialog();
  };

  const MofeInfo = () => (
    <span data-test={'moreInfoBtn'}>
      <Button onClick={openStatusDialog}>
        {MORE_INFO_TEXT}
      </Button>
    </span>
  );

  const CheckStatus = () => (
    <span data-test={'checkStatusBtn'}>
      <Button onClick={stopAction}>
        {CHECK_NOW_TEXT}
      </Button>
    </span>
  );

  const DeploymentActions = () => (
    <span>
      {(statusLabel === DEPLOYMENT_STATUS_LABEL_UNDEPLOYED) &&
        <span data-test={'deployBtn'}>
          <Button onClick={() => openConfirmDialog(DEPLOYMENT_ACTION_TYPE_DEPLOY)}>
            {DEPLOYMENT_ACTION_LABEL_DEPLOY}
          </Button>
        </span>
      }
      {(statusLabel !== DEPLOYMENT_STATUS_LABEL_UNDEPLOYED) &&
        <span data-test={'redeployBtn'}>
          <Button onClick={() => openConfirmDialog(DEPLOYMENT_ACTION_TYPE_REDEPLOY)}>
            {DEPLOYMENT_ACTION_LABEL_REDEPLOY}
          </Button>
        </span>
      }
      {(statusLabel !== DEPLOYMENT_STATUS_LABEL_UNDEPLOYED) &&
        <span data-test={'undeployBtn'}>
          <Button onClick={() => openConfirmDialog(DEPLOYMENT_ACTION_TYPE_UNDEPLOY)}>
            {DEPLOYMENT_ACTION_LABEL_UNDEPLOY}
          </Button>
        </span>
      }
    </span>
  );

  return (
    <Fragment>
      {showStatusDialog &&
        <AlertDialog
          isOpen={showStatusDialog}
          title={INFORMATION_TEXT}
          description={errorMessage || formatStatusMessage(statusMessage)}
          submitText={OK_TEXT}
          cancelText={CANCEL_TEXT}
          onClose={closeStatusDialog}
          onSubmit={closeStatusDialog}
          onCancel={closeStatusDialog}
          showCancel={false}
          dialogId={'app-deployment-status-dialog'}
        />
      }
      {showConfirmDialog &&
        <AlertDialog
          isOpen={showConfirmDialog}
          title={WARNING_TEXT}
          description={CONFIRM_ACTION_TEXT}
          submitText={OK_TEXT}
          cancelText={CANCEL_TEXT}
          onClose={closeConfirmDialog}
          onSubmit={saveConfirmDialog}
          onCancel={closeConfirmDialog}
          dialogId={'app-deployment-confirm-dialog'}
        />
      }
      <Card
        id={`app-deployment-card-${index}`} data-layer7-test={`app-deployment-card-${index}`}
        className={classes.card}
        data-test={'app-deployment-card'}
      >
        <CardContent>
          <Typography>
            {hasAdminRole(user) ? renderTitle(name, uuid, classes)
            :
            <h4 className={classes.cardTitle}>{name}</h4>
            }
          </Typography>
          <Typography className={classes.cardLastUpdated}>
            {intl.getI18nMessage('label.last.updated')}: {updating ? intl.getI18nMessage('label.updating') : lastModified}
          </Typography>
          <Divider />
          <Typography className={classes.cardType}>
            <span data-test={'deployment-type-label'}>{KEY_DEPLOYMENT_TYPE_LABEL}</span>: {capitalCase(type)}
            <Tooltip title={renderHelpText(classes)} arrow placement="right">
              <HelpIcon className={classes.helpIcon} />
            </Tooltip>
          </Typography>
          <Typography className={classes.cardStatus}>
            {updating ?
              <span>
                <LensIcon className={classes.cardStatusIcon} /> {`${UPDATING_STATUS_TEXT} ${timer}${SECONDS_UNIT_TEXT}.`}
              </span> :
              <span style={statusStyle}>
                <LensIcon className={classes.cardStatusIcon} /> {statusLabel}
              </span>
            }
            <div className={classes.deploymentButtonsContainer}>
              {(!updating && (errorMessage || statusMessage)) &&
                <MofeInfo />
              }
              {(type === APP_DEPLOYMENT_TYPE_ON_DEMAND) &&
                <span data-test={'app-deployment-actions'}>
                  {updating ? <CheckStatus /> : <DeploymentActions />}
                </span>
              }
            </div>
          </Typography>

        </CardContent>
      </Card>
    </Fragment>
  );
};

DeploymentsCard.propTypes = {
  index: PropTypes.number,
  details: PropTypes.shape({
    applicationUuid: PropTypes.string,
    uuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    statusMessage: PropTypes.string,
    modifyTs: PropTypes.string,
    apiKey: PropTypes.string,
    type: PropTypes.string,
  }),
  errorObj: PropTypes.object,
  refreshTime: PropTypes.number,
  deployApiKey: PropTypes.func,
  redeployApiKey: PropTypes.func,
  undeployApiKey: PropTypes.func,
  checkStatus: PropTypes.func,
  user: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(DeploymentsCard);
