import axios from 'axios';
import get from 'lodash/get';

import * as constants from '../../constants';
import { AXIOS_DEFAULT_OPTIONS } from '../';
import { getConfig } from '../../reducers/portalConfig';
import { getValidationErrors } from '../../utils';
import slugify from 'slugify';
import { getBundleName } from '../../pages/CustomPages/utils';

const initCustomPages = (dispatch, isLoading = true) =>
  dispatch({
    type: constants.APP_LOADING,
    isLoading,
  });

export const fetchCustomPages = () => async (dispatch, getState) => {
  initCustomPages(dispatch);
  const config = getConfig(getState());
  const { portal } = config;

  const url = `${portal.hostname}/api/${portal.tenantPrefix}/custom/1.0/pages`;
  await axios
    .get(url, AXIOS_DEFAULT_OPTIONS)
    .then((response) =>
      dispatch({
        type: constants.FETCH_CUSTOM_PAGES_SUCCESS,
        payload: response.data,
      }),
    )
    .catch((error) =>
      dispatch({
        type: constants.FETCH_CUSTOM_PAGES_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};

export const fetchCustomPagesBundles = () => async (dispatch, getState) => {
  initCustomPages(dispatch);
  const config = getConfig(getState());
  const { portal } = config;

  const url = `${portal.hostname}/admin/content/1.0/bundles`;
  await axios
    .get(url, AXIOS_DEFAULT_OPTIONS)
    .then((response) => {
      // Format name to display name
      const filteredResponse = response.data.map((item) => ({
        ...item,
        name: getBundleName(item),
      }));
      dispatch({
        type: constants.FETCH_CUSTOM_PAGES_BUNDLES_SUCCESS,
        payload: filteredResponse,
      });
    })
    .catch((error) =>
      dispatch({
        type: constants.FETCH_CUSTOM_PAGES_BUNDLES_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};

export const fetchCustomPagesListInBundle =
  (bundleId) => async (dispatch, getState) => {
    // Passing null will return the default bundle page list (Portal Default)
    if (!bundleId) {
      dispatch({
        type: constants.FETCH_CUSTOM_PAGES_LIST_IN_BUNDLE_SUCCESS,
        payload: [],
      });
      return;
    }

    initCustomPages(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/admin/content/1.0/ui-assets/bundle/${bundleId}?type=html`;
    await axios
      .get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        // Format response to match FormSelect options
        const filteredResponse = response.data.map((item) => ({
          uuid: item.title,
          name: item.title,
          url: item.url,
        }));
        dispatch({
          type: constants.FETCH_CUSTOM_PAGES_LIST_IN_BUNDLE_SUCCESS,
          payload: filteredResponse,
        });
      })
      .catch((error) =>
        dispatch({
          type: constants.FETCH_CUSTOM_PAGES_LIST_IN_BUNDLE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const uploadCustomPagesBundle =
  (uploadedBundle) => async (dispatch, getState) => {
    initCustomPages(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const fileName = slugify(uploadedBundle.name);
    const url = `${portal.hostname}/admin/content/1.0/bundles?bundleName=${fileName}`;

    await axios
      .post(url, uploadedBundle, {
        ...AXIOS_DEFAULT_OPTIONS,
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      .then((response) =>
        dispatch({
          type: constants.UPLOAD_CUSTOM_PAGES_BUNDLE_SUCCESS,
          payload: response.data,
        }),
      )
      .catch((error) => {
        const errorMsg = get(error, 'response.data.validationErrors["ui-assets_bundle"]') || error;
        dispatch({
          type: constants.UPLOAD_CUSTOM_PAGES_BUNDLE_ERROR,
          payload: errorMsg,
        });
      },
      );
  };

export const deleteCustomPagesBundle =
  (bundleId) => async (dispatch, getState) => {
    initCustomPages(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/admin/content/1.0/bundles/${bundleId}`;

    await axios
      .delete(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) =>
        dispatch({
          type: constants.DELETE_CUSTOM_PAGES_BUNDLE_SUCCESS,
          payload: response.data,
        }),
      )
      .catch((error) =>
        dispatch({
          type: constants.DELETE_CUSTOM_PAGES_BUNDLE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateCustomPages =
  (assignedPages, bundleId) => async (dispatch, getState) => {
    initCustomPages(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/custom/1.0/pages/assign?bundleUuid=${bundleId}`;
    const isReset = assignedPages.length === 0;

    await axios
      .post(url, assignedPages, AXIOS_DEFAULT_OPTIONS)
      .then(() =>
        dispatch({
          type: constants.SAVE_CUSTOM_PAGES_SUCCESS,
          payload: isReset,
        }),
      )
      .catch((error) =>
        dispatch({
          type: constants.SAVE_CUSTOM_PAGES_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const resetUpdateCustomPagesStatus = () => async (dispatch) => {
  dispatch({
    type: constants.RESET_CUSTOM_PAGES_SUCCESS_MESSAGE,
  });
};
