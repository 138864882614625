import { get } from 'lodash';

const styles = ({ color, fontSize, typography, borderRadius, margin, padding }) => ({
  actionsContainer: {
    display: 'inline-block',
    float: 'right',
    backgroundColor: get(color, 'primaryButtonBackground'),
    borderColor: get(color, 'primaryButtonBackground'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    borderRadius: get(borderRadius, 'br4'),
    margin: `0 ${get(margin, 'm36')}`,
    color: get(color, 'primaryButtonText'),
    '& nav': {
      padding: get(padding, 'p0'),
      '& > div > div > span': {
        color: get(color, 'primaryButtonText'),
      },
    },
    minWidth: 160,
  },
  collapseContainer: {
    position: 'absolute',
    width: '100%',
    backgroundColor: get(color, 'uiBackground'),
    borderColor: get(color, 'uiBackground'),
    borderRadius: get(borderRadius, 'br4'),
    zIndex: 1,
  },
  listButton: {
    paddingTop: get(padding, 'p5'),
    paddingBottom: get(padding, 'p5'),
  },
  list: {
    boxShadow: '0px 4px 8px #00000033',
    '& > div:first-child': {
      borderRadius: '3px 3px 0 0',
    },
    '& > div:last-child': {
      borderRadius: '0 0 3px 3px',
    },
    '& .MuiButtonBase-root.Mui-disabled': {
      pointerEvents: 'auto',
    },
    backgroundColor: get(color, 'white'),
    borderColor: get(color, 'white'),
    color: get(color, 'grayDark'),
  },
  applicationList: {
    maxHeight: 270,
  },
  listItem: {
    color: get(color, 'link'),
    backgroundColor: get(color, 'uiBackground'),
    borderColor: get(color, 'uiBackground'),
    borderRadius: get(borderRadius, 'br4'),
  },
  addApplicationDialog: {
    '& .MuiTypography-colorTextSecondary' : {
      color: get(color, 'bodyText'),
    },
  },
  submitButton: {
    backgroundColor: `${get(color, 'red')} !important`,
  },
});

export default styles;
