import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { number, bool, func, shape, arrayOf, string } from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { get } from 'lodash';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { fetchProxyCertificatesList } from '../../../../actions/proxy';
import {
  getCertificatesListTotalPages,
  getCertificatesListTotalElements,
  getCertificatesListResults,
} from '../../../../reducers/proxy';
import styles from './styles';
import ListContainer from '../../../list';
import {
  GRID_ROWS_PER_PAGE_OPTIONS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
 } from '../../../../constants';
import {
  FilterByName,
  FilterBySubjectDn,
  SortBy,
  FilterAndSortSeparator,
} from './controls';
import { getCerStatus } from "../../../../utils";
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';

export const getListColumns = (classes) => [{
  id: 'name',
  label: getI18nFormattedMessage('label.name'),
  minWidth: 100,
}, {
  id: 'thumbprintSha1',
  label: getI18nFormattedMessage('label.certificate.thumbprintSha1'),
  minWidth: 50,
}, {
  id: 'subjectDn',
  label: getI18nFormattedMessage('label.certificate.subject.domain'),
  minWidth: 50,
}, {
  id: 'notAfter',
  label: getI18nFormattedMessage('label.certificate.expire.date'),
  minWidth: 50,
}, {
  id: 'expiresInDays',
  label: getI18nFormattedMessage('label.certificate.expires.in'),
  minWidth: 50,
  value: (item) => {
    const { status, statusColor } = getCerStatus(item.expiresInDays);
    const statusIconStyle = {
      fill: statusColor,
    };
    return (
      <span>
        <FiberManualRecordIcon size="0.82em" style={statusIconStyle} className={classes.statusIcon} />{status}
      </span>);
  },
}];

export const CertificateListRaw = (props) => {
  const {
    proxyUuid,
    classes,
    isLoading,
    totalPages,
    totalElements = 0,
    results = [],
    notifyMessages,
    featureFlags,
  } = props;

  const intl = getI18n(useIntl());
  const featureFlagL7Manager = JSON.parse(get(featureFlags, 'l7Manager.Value'));
  const [name, setName] = useState('');
  const [subjectDn, setSubjectDn] = useState('');
  const [sortBy, setSortBy] = useState('notAfter,ASC');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [filterByAnyApplied, setFilterByAnyApplied] = useState(false);

  const emptyFunction = () => {};

  useEffect(() => {
    if (proxyUuid && featureFlagL7Manager) {
      props.fetchProxyCertificatesList({ proxyUuid, name, subjectDn,
        sortBy, page, rowsPerPage });
    }
  }, [proxyUuid]);

  const onFilterByNameChange = (value) => {
    setName(value);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilterByAnyApplied(true);
      setPage(0);
      props.fetchProxyCertificatesList({ proxyUuid, name, subjectDn,
        sortBy, page: 0, rowsPerPage });
    }
  };

  const onFilterBySubjectDnChange = (value) => {
    setSubjectDn(value);
  };

  const onFilterBySubjectDnPress = (e) => {
    if (e.key === 'Enter') {
      setFilterByAnyApplied(true);
      setPage(0);
      props.fetchProxyCertificatesList({ proxyUuid, name, subjectDn,
        sortBy, page: 0, rowsPerPage });
    }
  };

  const onSortByChange = (newSortBy) => {
    setSortBy(newSortBy);
    setPage(0);
    props.fetchProxyCertificatesList({ proxyUuid, name, subjectDn,
      sortBy: newSortBy, page: 0, rowsPerPage });
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchProxyCertificatesList({ proxyUuid, name, subjectDn,
      sortBy, page: newPage, rowsPerPage });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchProxyCertificatesList({ proxyUuid, name, subjectDn,
      sortBy, page: 0, rowsPerPage: newRowsPerPage });
  };

  const noResultsMessage = filterByAnyApplied ?
  `${intl.getI18nMessage('label.proxy.details.certificates.search.results')}` :
    intl.getI18nMessage('label.proxy.details.certificates.no.results');

  return (
    <ListContainer
      listPageId="proxy-details-certificates-list"
      isLoading={isLoading}
      notificationId="proxy-details-certificates-notifications"
      notificationStatus={''}
      setNotificationStatus={emptyFunction}
      notificationMessage={''}
      setNotificationMessage={emptyFunction}
      pageHeaderTitle={''}
      pageHeaderTooltipTitle={''}
      addButtonLabel={''}
      onAddBundle={emptyFunction}
      showListHeader={false}
      pageClass={classes.listContent}
      pageBodyClass={classes.pageBodyClass}
      pageFilterAndSortClass={classes.pageFilterAndSortClass}
      filterAndSortContent={(
        <Fragment>
          <FilterByName
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            value={name}
            placeholder={intl.getI18nMessage('label.proxy.details.certificates.filter.by.name.placeholder')}
            handleChange={onFilterByNameChange}
            onKeyPress={onFilterByNameKeyPress}
          />
          <FilterBySubjectDn
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            selectFieldClass={classes.selectField}
            value={subjectDn}
            placeholder={intl.getI18nMessage('label.certificate.subject.domain')}
            handleChange={onFilterBySubjectDnChange}
            onKeyPress={onFilterBySubjectDnPress}
            hideLabel
          />

          <FilterAndSortSeparator />
          <SortBy
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.sort.by')}
            value={sortBy}
            handleChange={onSortByChange}
          />
        </Fragment>
     )}
      columns={getListColumns(classes) || []}
      rows={results}
      noResultsMessage={noResultsMessage}
      page={page}
      totalElements={totalElements}
      totalPages={totalPages}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onChangePreviousPage={onChangePreviousPage}
      onChangeNextPage={onChangeNextPage}
      notifyMessages={notifyMessages}
    />
  );
};

CertificateListRaw.propTypes = {
  classes: shape({}),
  location: shape({}),
  isLoading: bool,
  totalPages: number,
  totalElements: number,
  results: arrayOf(shape({})),
  featureFlags: shape({}),
  fetchProxyCertificatesList: func,
  proxyUuid: string,
  notifyMessages: func,
  isCurrentTab: bool,
};

const mapStateToProps = (state) => ({
  isLoading: false,
  totalPages: getCertificatesListTotalPages(state),
  totalElements: getCertificatesListTotalElements(state),
  results: getCertificatesListResults(state),
});

const mapDispatchToProps = {
  fetchProxyCertificatesList,
};
CertificateListRaw.displayName = 'Certificates';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(CertificateListRaw);
