import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const TabPanel = ({ children, id, visible, classes }) => (
  <Typography
    id={id} data-apim-test={id} data-layer7-test={id}
    aria-labelledby={`aria-${id}`}
    component="div"
    role="tabpanel"
    hidden={!visible}
    classes={{ root: classes }}
  >
    <Box>{children}</Box>
  </Typography>
);

TabPanel.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  visible: PropTypes.bool,
  classes: PropTypes.string,
};

export default TabPanel;
