import { get } from 'lodash';

const styles = ({ color, fontSize, typography, margin, padding }) => ({
  content: {
    margin: get(margin, 'm36'),
    minHeight: 'calc(100vh - 125px)',
    backgroundColor: get(color, 'background'),
    marginTop: get(margin, 'm0'),
  },
  gridContainer: {
    minHeight: 'calc(100vh + 56px)',
    backgroundColor: get(color, 'uiBackground'),
    marginTop: get(margin, 'm10'),
    marginBottom: get(margin, 'm60'),
  },
  leftSideBar: {
    paddingTop: get(padding, 'p50'),
    backgroundColor: get(color, 'uiBackground'),
  },
  mainContent: {
    padding: get(padding, 'p20'),
    paddingTop: get(padding, 'p50'),
    backgroundColor: get(color, 'uiBackground'),
  },
  rightSideBar: {
    padding: get(padding, 'p10'),
    paddingTop: get(padding, 'p50'),
    backgroundColor: get(color, 'uiBackground'),
    borderLeft: `8px solid ${get(color, 'background')}`,
  },
  pageTitle: {
    color: get(color, 'pageTitle'),
    fontFamily: get(typography, 'pageTitle'),
    fontSize: get(fontSize, 'pageTitle'),
    marginTop: get(margin, 'm6'),
  },
  subTitle: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    marginTop: get(margin, 'm6'),
    marginBottom: get(margin, 'm10'),
  },
});

export default styles;
