import { get } from 'lodash';
import * as Constants from '../../constants';

const resetState = {
  isError: false,
  errors: [],
  isUpdateSuccessful: false,
  isUpdateError: false,
  updateErrorMsg: '',
};

const initialState = {
  ...resetState,
  isLoading: false,
  isFormLoadingError: false,
  isFormValidationError: false,
  isUpdateError: false,
  updateErrorMsg: '',
};

const getValidationErrorMessage = (payload) => get(payload, 'error.detail.devErrorMessage');

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.APP_RESET:
      return {
        ...state,
        ...resetState,
      };
    case Constants.APP_LOADING:
      return {
        ...state,
        ...resetState,
        isLoading: action.isLoading,
      };
    case Constants.PUBLIC_KEY_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.PUBLIC_KEY_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        publicKey: action.payload.publicKey,
      };
    case Constants.PUBLIC_KEY_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        isFormLoadingError: true,
        isError: true,
        publicKeyError: action.payload,
      };
    case Constants.PASSWORD_POLICY_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.PASSWORD_POLICY_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        passwordPolicy: action.payload,
      };
    case Constants.PASSWORD_POLICY_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        isFormLoadingError: true,
        isError: true,
        regexError: action.payload,
      };
    case Constants.USER_PROFILE_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        data: action.payload,
      };
    case Constants.USER_PROFILE_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        isFormLoadingError: true,
        isError: true,
        errors: action.payload,
      };
    case Constants.USER_PROFILE_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.USER_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        isUpdatingUserProfile: true,
      };
    case Constants.USER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingUserProfile: false,
        isUpdateSuccessful: true,
        userProfile: action.payload,
      };
    case Constants.USER_PROFILE_UPDATE_ERROR: {
      const updateErrorMsg = getValidationErrorMessage(action.payload);
      return {
        ...state,
        isUpdatingUserProfile: false,
        isUpdateError: true,
        isLoading: false,
        ...(updateErrorMsg ? { updateErrorMsg } : {}),
      };
    }
    default:
      return state;
  }
}

export const getIsLoading = (state) => get(state, 'userProfile.isLoading');

export const getIsError = (state) => get(state, 'userProfile.isError');
export const getErrors = state => get(state, 'userProfile.errors');

export const getIsFormLoadingError = (state) => get(state, 'userProfile.isFormLoadingError');
export const getIsFormValidationError = (state) => get(state, 'userProfile.isFormValidationError');

export const getPublicKey = (state) => get(state, 'userProfile.publicKey');
export const getPublicKeyError = (state) => get(state, 'userProfile.publicKeyError');

export const getRegexPolicy = (state) => get(state, 'userProfile.passwordPolicy.authScheme.passwordPolicies.regexConfig');
export const getRegexError = (state) => get(state, 'userProfile.regexError');

export const getUserProfile = (state) => get(state, 'userProfile.data');
export const getIsFormUpdateError = (state) => get(state, 'userProfile.isUpdateError');
export const getIsUpdateSuccessful = (state) => get(state, 'userProfile.isUpdateSuccessful');
export const getUpdateErrorMsg = (state) => get(state, 'userProfile.updateErrorMsg');

