import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchBarComponent from '../../../components/SearchBar';
import {
  APPLICATION,
  API,
  ORGANIZATION,
} from '../labels';

export default function SearchBar(props) {
  let dispatchAutoSuggest;
  if (props.selectedDropdownValue === API) {
    dispatchAutoSuggest = props.fetchAvailableApis;
  }
  if (props.selectedDropdownValue === APPLICATION) {
    dispatchAutoSuggest = props.fetchAvailableApps;
  }
  if (props.selectedDropdownValue === ORGANIZATION) {
    dispatchAutoSuggest = props.fetchAvailableOrgs;
  }
  const placeHolderText = `Enter ${props.selectedDropdownValue} name`;
  const [inputValue, setInputValue] = useState('');
  const onInputChange = (value) => {
    setInputValue(value);
    props.handleSelection();
  }
  const handleSelection = (option) => {
    if (option && option.value) {
      setInputValue(option.value);
    }
    props.handleSelection(option)
  }
  return (
    <SearchBarComponent
      dispatchAutoSuggest={dispatchAutoSuggest}
      autoSuggestSuggestions={props.autoSuggestSuggestions}
      placeHolderText={placeHolderText}
      selectedOption={props.selectedOption}
      handleSelection={handleSelection}
      handleInputChange={onInputChange}
      inputValue={inputValue}
    />
  );
}

SearchBar.propTypes = {
  handleSelection: PropTypes.func,
  autoSuggestSuggestions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
  selectedDropdownValue: PropTypes.string,
  selectedOption: PropTypes.shape(),
  fetchAvailableApis: PropTypes.func,
  fetchAvailableApps: PropTypes.func,
  fetchAvailableOrgs: PropTypes.func,
};
