import React from 'react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import { MarkdownView } from '../../../../components';
import DocumentToolbar from './DocumentToolbar';
import { arrayOf, func, object, string, bool } from 'prop-types';

const DocumentView = ({
    document,
    entityType,
    entityUuid,
    userCanEdit = true,
    userCanDelete = true,
    hasChildren,
    onEdit,
    userCanAdd,
    onAddNewDocument,
    onDeleteDocument,
    data,
    id,
}) => {
    const classes = useStyles();
    const content = get(data, 'markdown', '');
    return (
        <div id={id} data-apim-test={id}>
            <DocumentToolbar
                document={document}
                entityType={entityType}
                entityUuid={entityUuid}
                userCanEdit={userCanEdit}
                userCanAdd={userCanAdd}
                userCanDelete={userCanDelete}
                hasChildren={hasChildren}
                onEdit={onEdit}
                onAddNewDocument={onAddNewDocument}
                onDeleteDocument={onDeleteDocument}
                id={`${id}-toolbar`}
                data-apim-test={`${id}-toolbar`}
            />
            <MarkdownView
                className={classes.markdown}
                value={content}
                id={`${id}-markdown-view`}
                data-apim-test={`${id}-markdown-view`}
            />
        </div>
    );
};

const useStyles = makeStyles(
    theme => ({
        markdown: {
            margin: theme.spacing(4),
            '& .MuiTypography-colorTextPrimary': {
                color: get(theme, 'color.bodyText'),
            },
            '& code': {
                whiteSpace: 'pre-wrap',
            },
            '& table': {
                fontSize: '14px',
                lineHeight: '1.7',
                maxWidth: '100%',
                overflow: 'auto',
                border: '1px solid #f6f6f6',
                borderCollapse: 'collapse',
                borderSpacing: 0,
                boxSizing: 'border-box',
            },
            '& table th': {
                textAlign: 'center',
                fontWeight: 700,
                border: '1px solid #efefef',
                padding: '10px 6px',
                backgroundColor: '#f5f7fa',
                wordBreak: 'break-word',
            },
            '& table td': {
                border: '1px solid #efefef',
                textAlign: 'left',
                padding: '10px 15px',
                wordBreak: 'break-word',
                minWidth: '60px',
            },
        },
    }),
);

DocumentView.propTypes = {
    document: object,
    entityType: string,
    entityUuid: string,
    userCanEdit: bool,
    userCanDelete: bool,
    userCanAdd: bool,
    hasChildren: object,
    onEdit: func,
    onAddNewDocument: func,
    onDeleteDocument: func,
    data: arrayOf(object),
    id: string,
};

export default DocumentView;