import { get } from 'lodash';
import * as Constants from '../../constants';

const resetState = {
  isError: false,
  errors: [],
};
const initialState = {
  ...resetState,
  isLoading: false,
  isSaveSuccess: false,
  isDeleteSuccess: false,
  rateQuotaList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  apisList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  orgsList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  apisOrgsList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  apisOrgsDetailsList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  details: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.APP_RESET:
      return {
        ...state,
        ...resetState,
        details: {},
        isSaveSuccess: false,
        isDeleteSuccess: false,
      };
    case Constants.RATE_QUOTA_DELETE_RESET:
      return {
        ...state,
        ...resetState,
        isSaveSuccess: false,
        isDeleteSuccess: false,
      };
    case Constants.APP_LOADING:
      return {
        ...state,
        ...resetState,
        isSaveSuccess: false,
        isDeleteSuccess: false,
        isLoading: action.isLoading,
      };
    case Constants.RATE_QUOTA_GET_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        rateQuotaList: action.payload,
      };
    case Constants.RATE_QUOTA_GET_LIST_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
      };
    case Constants.RATE_QUOTA_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        details: action.payload,
      };
    case Constants.RATE_QUOTA_DETAILS_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
      };
    case Constants.RATE_QUOTA_ADD_REQUEST:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isSaveSuccess: false,
      };
    case Constants.RATE_QUOTA_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        details: action.payload,
        isSaveSuccess: true,
      };
    case Constants.RATE_QUOTA_SAVE_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
        isSaveSuccess: false,
      };
    case Constants.RATE_QUOTA_DELETE_SUCCESS:
      return {
        ...state,
        ...resetState,
        deleteErrors: [],
        isDeleteSuccess: true,
      };
    case Constants.RATE_QUOTA_DELETE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        deleteErrors: action.payload,
        isDeleteSuccess: false,
      };
    case Constants.API_LIST_SUCCESS: {
      return {
        ...state,
        apisList: action.payload,
        errors: [],
        isAPIListLoading: false,
        isApiSaveSuccess: false,
        isError: false,
        isLoading: false,
      };
    }
    case Constants.RATE_QUOTA_ORGANIZATION_LIST_GET_SUCCESS: {
      return {
        ...state,
        orgsList: action.payload,
        errors: [],
        isAPIListLoading: false,
        isApiSaveSuccess: false,
        isError: false,
        isLoading: false,
      };
    }
    case Constants.RATE_QUOTA_ORGANIZATION_LIST_GET_ERROR: {
      return {
        ...state,
        errors: action.payload,
        isAPIListLoading: false,
        isApiSaveSuccess: false,
        isError: true,
        isLoading: false,
      };
    }
    case Constants.RATE_QUOTA_APIS_ORGANIZATION_LIST_GET_SUCCESS: {
      return {
        ...state,
        apisOrgsList: action.payload,
        errors: [],
        isAPIListLoading: false,
        isApiSaveSuccess: false,
        isError: false,
        isLoading: false,
      };
    }
    case Constants.RATE_QUOTA_APIS_ORGANIZATION_LIST_GET_ERROR: {
      return {
        ...state,
        errors: action.payload,
        isAPIListLoading: false,
        isApiSaveSuccess: false,
        isError: true,
        isLoading: false,
      };
    }
    case Constants.RATE_QUOTA_APIS_ORGANIZATION_DETAILS_GET_SUCCESS: {
      return {
        ...state,
        apisOrgsDetailsList: action.payload,
        errors: [],
        isAPIListLoading: false,
        isApiSaveSuccess: false,
        isError: false,
        isLoading: false,
      };
    }
    case Constants.RATE_QUOTA_APIS_ORGANIZATION_DETAILS_GET_ERROR: {
      return {
        ...state,
        errors: action.payload,
        isAPIListLoading: false,
        isApiSaveSuccess: false,
        isError: true,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

export const getIsLoading = (state) => get(state, 'rateQuota.isLoading');

export const getIsError = (state) => get(state, 'rateQuota.isError');

export const getErrors = state => get(state, 'rateQuota.errors');

export const getIsSaveSuccess = state => get(state, 'rateQuota.isSaveSuccess');

export const getIsDeleteSuccess = state => get(state, 'rateQuota.isDeleteSuccess');
export const getDeleteErrors = state => get(state, 'rateQuota.deleteErrors');

export const getDetails = state => get(state, 'rateQuota.details');

export const getListTotalPages = (state) => get(state, 'rateQuota.rateQuotaList.totalPages');
export const getListTotalElements = (state) => get(state, 'rateQuota.rateQuotaList.totalElements');
export const getListResults = (state) => get(state, 'rateQuota.rateQuotaList.results');

export const getApisListResults = (state) => get(state, 'rateQuota.apisList.results');
export const getApisListTotalPages = (state) => get(state, 'rateQuota.apisList.totalPages');
export const getApisListTotalElements = (state) => get(state, 'rateQuota.apisList.totalElements');

export const getOrgsListResults = (state) => get(state, 'rateQuota.orgsList.results');
export const getOrgsListTotalPages = (state) => get(state, 'rateQuota.orgsList.totalPages');
export const getOrgsListTotalElements = (state) => get(state, 'rateQuota.orgsList.totalElements');

export const getApisOrgsListResults = (state) => get(state, 'rateQuota.apisOrgsList.results');
export const getApisOrgsListTotalPages = (state) => get(state, 'rateQuota.apisOrgsList.totalPages');
export const getApisOrgsListTotalElements = (state) => get(state, 'rateQuota.apisOrgsList.totalElements');

export const getApisOrgsDetailsListResults = (state) => get(state, 'rateQuota.apisOrgsDetailsList.results');

