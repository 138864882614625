import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { map, isEmpty, get } from 'lodash';
import clsx from 'clsx';
import { bool, shape, arrayOf, func, string, object } from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Collapse,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  AlertDialog,
} from '../../../../components';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import styles from './styles';
import {
  ALERT_ERROR,
  QUOTA_ASSIGNMENT_API,
  QUOTA_ASSIGNMENT_ORG,
} from '../../../../constants';

export const processErrors = (errors, defaultMessage) => {
  let errorMessage;
  if (errors.length > 0) {
    errorMessage = map(errors, (validationError) => validationError.error).join('\n');
  }
  return errorMessage || defaultMessage;
};


export const getActions = ({
  props,
  rqUuid,
  openEditDialog,
  openDeleteDialog,
  userContext,
  apisList,
  orgsList,
  apiOrgsList,
  rateQuotaDetails,
}) => {
  const intl = getI18n(useIntl());
  const isUsed = rateQuotaDetails.assignmentLevel === QUOTA_ASSIGNMENT_API ?
    apisList.length >= 1 : rateQuotaDetails.assignmentLevel === QUOTA_ASSIGNMENT_ORG ?
    orgsList.length >= 1 : apiOrgsList.length >= 1;
  const isDefault = get(rateQuotaDetails, 'defaultRateQuota');

  const hasEdit = get(userContext, 'permissions.RATE_QUOTA', []).includes('UPDATE');
  const hasDelete = get(userContext, 'permissions.RATE_QUOTA', []).includes('DELETE');

  return [
    {
      key: 'edit',
      value: intl.getI18nMessage('label.rate.quota.details.edit.button'),
      onClick: () => {
        if(isUsed) {
          openEditDialog();
        } else {
          props.push(`/admin/rate-quotas/edit/${rqUuid}`);
        }
      },
      visible: hasEdit,
      disabled: false,
    },
    {
      key: 'delete',
      value: intl.getI18nMessage('label.rate.quota.details.delete.button'),
      onClick: () => {
        if(isUsed || isDefault) { return; }
        openDeleteDialog();
      },
      visible: hasDelete,
      disabled: isDefault || isUsed,
      disabledTooltip: isDefault ?
        getI18nFormattedMessage('label.rate.quota.actions.delete.apis.default.disabled.tooltip')
        : isUsed ? rateQuotaDetails.assignmentLevel === QUOTA_ASSIGNMENT_API ?
          getI18nFormattedMessage('label.rate.quota.actions.delete.apis.disabled.tooltip')
          : rateQuotaDetails.assignmentLevel === QUOTA_ASSIGNMENT_ORG ?
          getI18nFormattedMessage('label.rate.quota.actions.delete.orgs.disabled.tooltip')
          : getI18nFormattedMessage('label.rate.quota.actions.delete.apis.orgs.disabled.tooltip')
        : '',
    },
  ];
}

export const ActionsRaw = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    notifyMessages,
    deleteRateQuotaErrors,
    isRateQuotaDeleteSuccess,
    rqUuid,
    userContext,
    rateQuotaDetails,
    apisList,
    orgsList,
    apiOrgsList,
  } = props;

  const intl = getI18n(useIntl());
  const [open, setOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const handleClick = () => { setOpen(!open); };
  const handleClickAway = () => { setOpen(false); };
  const openDeleteDialog = () => setShowDeleteDialog(true);
  const closeDeleteDialog = () => setShowDeleteDialog(false);
  const openEditDialog = () => setShowEditDialog(true);
  const closeEditDialog = () => setShowEditDialog(false);

  const onDelete = () => {
    props.deleteRateQuota(rqUuid);
    closeDeleteDialog();
  };

  const onEdit = () => {
    props.push(`/admin/rate-quotas/edit/${rqUuid}`);
    closeDeleteDialog();
  };

  const actions = getActions({
    props,
    rqUuid,
    openDeleteDialog,
    openEditDialog,
    userContext,
    apisList,
    orgsList,
    apiOrgsList,
    rateQuotaDetails,
  });

  useEffect(() => {
    if (isRateQuotaDeleteSuccess) { props.push('/admin/rate-quotas'); }
  }, [isRateQuotaDeleteSuccess]);

  useEffect(() => {
    if (!isEmpty(deleteRateQuotaErrors)) {
      notifyMessages(
        ALERT_ERROR,
        processErrors(deleteRateQuotaErrors, intl.getI18nMessage('error.rate.quota.delete')),
      );
    }
  }, [deleteRateQuotaErrors]);

  const editTitle = rateQuotaDetails.assignmentLevel === QUOTA_ASSIGNMENT_API ?
    getI18nFormattedMessage('label.rate.quota.edit.apis.confirmation.title')
    : rateQuotaDetails.assignmentLevel === QUOTA_ASSIGNMENT_ORG ?
    getI18nFormattedMessage('label.rate.quota.edit.orgs.confirmation.title')
    : getI18nFormattedMessage('label.rate.quota.edit.apis.orgs.confirmation.title');
    
  const editDescription = rateQuotaDetails.assignmentLevel === QUOTA_ASSIGNMENT_API ?
    getI18nFormattedMessage('label.rate.quota.details.edit.apis.confirmation.text')
    : rateQuotaDetails.assignmentLevel === QUOTA_ASSIGNMENT_ORG ?
    getI18nFormattedMessage('label.rate.quota.details.edit.orgs.confirmation.text')
    : getI18nFormattedMessage('label.rate.quota.details.edit.apis.orgs.confirmation.text');

  return (
    <div
      id="rate-quota-details-actions-container"
      data-apim-test={'rate-quota-details-actions-container'}
      className={classes.actionsContainer}
    >
      {showDeleteDialog &&
        <AlertDialog
          id="rate-quota-details-delete-dialog"
          isOpen={showDeleteDialog}
          title={intl.getI18nMessage('label.delete.confirmation.title')}
          description={intl.getI18nMessage('label.rate.quota.details.delete.confirmation.text')}
          submitText={intl.getI18nMessage('label.delete.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={closeDeleteDialog}
          onSubmit={onDelete}
          onCancel={closeDeleteDialog}
          submitButtonClass={classes.submitButton}
        />
      }
      {showEditDialog &&
        <AlertDialog
          id="rate-quota-details-edit-dialog"
          isOpen={showEditDialog}
          title={editTitle}
          description={editDescription}
          submitText={intl.getI18nMessage('label.rate.quota.edit.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={closeEditDialog}
          onSubmit={onEdit}
          onCancel={closeEditDialog}
        />
      }
      <ClickAwayListener onClickAway={handleClickAway}>
        <List component="nav">
          <ListItem
            id="rate-quota-details-actions-button"
            data-apim-test="rate-quota-details-actions-button"
            classes={{ root: classes.listButton }}
            button
            onClick={handleClick}
          >
            <ListItemText primary={getI18nFormattedMessage('label.actions.button')} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse className={classes.collapseContainer} in={open}>
            <List className={classes.list} component="div" disablePadding>
              {actions
                .filter(({ visible }) => visible)
                .map(({ key, value, disabled, disabledTooltip, onClick }) => (
                  disabledTooltip ?
                    <Tooltip
                      arrow disableFocusListener disableTouchListener placement="top"
                      title={disabledTooltip}
                      key={key}
                    >
                      <ListItem
                        key={key}
                        id={key} data-apim-test={key} data-layer7-test={key}
                        button
                        className={clsx('action', classes.listItem)}
                        disabled={disabled}
                        onClick={onClick}
                      >
                        <ListItemText primary={value} />
                      </ListItem>
                    </Tooltip>
                  :
                    <ListItem
                      key={key}
                      id={key} data-apim-test={key} data-layer7-test={key}
                      button
                      className={clsx('action', classes.listItem)}
                      disabled={disabled}
                      onClick={onClick}
                    >
                      <ListItemText primary={value} />
                    </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </ClickAwayListener>
    </div>
  );
};

ActionsRaw.propTypes = {
  notifyMessages: func,
  rqUuid: string,
  deleteRateQuotaErrors: arrayOf(shape({})),
  userContext: shape({}),
  isRateQuotaDeleteSuccess: bool,
  deleteRateQuota: func,
  apisList: arrayOf(shape({})),
  orgsList: arrayOf(shape({})),
  apiOrgsList: arrayOf(shape({})),
  rateQuotaDetails: object,
  push: func,
};
ActionsRaw.displayName = 'Actions';

export default ActionsRaw;
