import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles, CircularProgress } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';

import CompositeXYChart from '../CompositeXYChart';
import CustomLabelComponent from '../CustomLabelComponent';
import ChartLegendPanel from '../ChartLegendPanel';
import {
  getDateRangeData,
  clearMetricsHoverState,
  getDisplayDate,
  lineColors,
  lightShadeLineColors,
} from '../analytics_utils';
import {
  getDefaultSharedMutations,
  getBucketPermuteIdAndNamesForCustomReport,
  getSourcesIdsFromResponseForCustomReport,
} from '../analytics_utils/permute';
import Theme from './styles';

function changeSharedMutation(props, setSharedMutation) {
  const { chartResp } = props;
  const data =
    chartResp && chartResp.data && chartResp.data.length ? chartResp.data : [];
  if (data.length) {
    const newSourcesIds = getSourcesIdsFromResponseForCustomReport(
      data,
      props.selectedDataSource,
    );
    let sharedMutationsObj = {};
    // reset sharedMutations if sources changes
    if (newSourcesIds.length > 0) {
      sharedMutationsObj = getDefaultSharedMutations(
        newSourcesIds,
        'CUSTOM_METRICS_CURRENT',
      );
      if (Object.keys(sharedMutationsObj).length) {
        setSharedMutation(sharedMutationsObj);
      }
    }
  }
}
function ChartPanelRaw(props) {
  const [sharedMutations, setSharedMutation] = useState({});
  useEffect(() => {
    changeSharedMutation(props, setSharedMutation);
  }, [props.chartResp]);
  const { chartResp, theme } = props;
  const getTimeData = (query, attr) => {
    let retVal = '';
    if (query && attr) {
      retVal = query[attr];
    }
    return retVal;
  };
  const getTimerange = timePeriodStr => {
    switch (timePeriodStr) {
      case '7D':
        return 'week';
      case '24H':
        return 'day';
      default:
        return 'custom';
    }
  };
  const getDateRangeProps = response => {
    let dateRangeData = {};
    if (response && response.query) {
      dateRangeData = {
        timerange: getTimerange(getTimeData(response.query, 'period')),
        timeAgg: getTimeData(response.query, 'aggregation'),
        startDate: getTimeData(response.query, 'startDate'),
        endDate: getTimeData(response.query, 'endDate'),
      };
    }
    return dateRangeData;
  };

  const getPointlevelData = (
    xAxisDates,
    dataPoints,
    dateRangeProps,
    tooltipLabel,
  ) => {
    const data = [];
    const { metric } = props;
    if (_.isUndefined(xAxisDates) || _.isUndefined(dataPoints)) {
      return data;
    }
    _.each(xAxisDates, (day, index) => {
      const date = moment(day);
      let value = 0;
      _.each(dataPoints, item => {
        if (date.isSame(moment(item.date))) {
          if (metric === 'latency') {
            value = item.avg;
          } else if (metric === 'hits') {
            value = item.count;
          }
        }
      });
      const { timerange, timeAgg, startDate, endDate } = dateRangeProps;
      const dateTimeStr = getDisplayDate(
        moment.utc(day).toISOString(),
        timerange,
        `by-${timeAgg}`,
        startDate,
        endDate,
        'tooltip',
      );
      data.push({
        x: index,
        y: value,
        label: `${tooltipLabel.join(' > ')}\n\n${
          'Hits'
        }: ${value}\n${'Date time (UTC)'}:\n${dateTimeStr}\n`,
      });
    });
    return data;
  };
  const createCustomLabelComponent = styles => {
    const StyledCustomLabelComponent = CustomLabelComponent(styles);
    return <StyledCustomLabelComponent />;
  };
  const TooltipStyle = {
    fill: 'white',
    fontSize: 10, // please test tooltips, as changing font sizes affect layout
    paddingLeft: 10,
    textAnchor: 'left',
    fontFamily: _.get(theme, 'typography.bodyText'),
    fontFamilyBold: _.get(theme, 'typography.pageTitle'),
  };
  const getChartData = (response, xAxisData, selectedDataSource) => {
    const chartData = [];
    const { metric } = props;
    if (!response || !response.data || !xAxisData) {
      return chartData;
    }
    let data = [];
    const dateRangeProps = getDateRangeProps(response);
    _.each(response.data, (combination, index) => {
      const entitiesArray = [];
      if (selectedDataSource.length) {
        selectedDataSource.forEach(datasource =>
          entitiesArray.push(datasource.dimension),
        );
      }
      const entitiesStr = entitiesArray.join(',');
      const permuteObject = getBucketPermuteIdAndNamesForCustomReport(
        combination,
        entitiesStr,
        props.chartEntity,
      );
      const tooltipLabel = permuteObject.tooltipLabel.length
        ? permuteObject.tooltipLabel
        : permuteObject.names;
      data = getPointlevelData(
        xAxisData.xAxisTickValuesMap,
        combination.buckets,
        dateRangeProps,
        tooltipLabel,
      );
      const combinationAggregation =
        metric === 'hits' ? combination.count : combination.avg;
      if (data.length && combinationAggregation && combination.buckets.length) {
        chartData.push({
          customLabelComponent: createCustomLabelComponent(TooltipStyle),
          id: permuteObject.permuteId,
          names: permuteObject.names,
          data,
          legendTooltipLabel: tooltipLabel,
          tooltipData: combinationAggregation,
          type: 'CHART_ELEMENT_LINE',
          colorIndex: index,
        });
      }
    });
    return chartData;
  };

  const getLineChartsData = (chartData, metric) => {
    const lineCharts = [];
    _.forEach(chartData, item => {
      const { id, data, names, customLabelComponent, tooltipData } = item;
      let { colorIndex } = item;
      const lineData = [];
      const sourceMutation = sharedMutations[id] || {};
      if (sourceMutation && sourceMutation.colorIndex !== undefined) {
        colorIndex = sourceMutation.colorIndex;
      }
      const metricsMutation = sourceMutation.metrics || {};

      const metricMutation = metricsMutation[metric] || {};
      const highLight = !!metricMutation.hover;
      if (!sourceMutation.show || !metricMutation.show) {
        return;
      }
      _.forEach(data, dataItem => {
        const { x, y, label } = dataItem;
        lineData.push({
          label,
          x,
          y,
        });
      });
      lineCharts.push({
        colorIndex,
        customLabelComponent,
        data: lineData,
        highLight,
        id,
        names,
        metric,
        tooltipData,
        type: 'CHART_ELEMENT_LINE',
      });
    });
    // taking only top 3 elements for the dashboard chart
    return lineCharts.slice(0, 3);
  };
  const legendClickHandler = (key) => {
    const sourceMutation = (sharedMutations && sharedMutations[key]) || {};
    let updatedMutations = sharedMutations;
    updatedMutations = {
      ...sharedMutations,
      [key]: {
        ...sourceMutation,
        show: !sourceMutation.show,
      },
    };
    setSharedMutation(updatedMutations);
  };
  const legendHoverHandler = (key, hoverState) => {
    let currentSharedMutations = sharedMutations || {};
    const sourceMutation =
      (currentSharedMutations && currentSharedMutations[key]) || {};
    const metric = 'CUSTOM_METRICS_CURRENT';
    const metricsMutation = (sourceMutation && sourceMutation.metrics) || {};
    if (hoverState) {
      currentSharedMutations = clearMetricsHoverState(currentSharedMutations);
    }
    const metricMutation = (metricsMutation && metricsMutation[metric]) || {};
    const updatedMutations = {
      ...currentSharedMutations,
      [key]: {
        ...sourceMutation,
        metrics: {
          ...metricsMutation,
          [metric]: {
            ...metricMutation,
            hover: hoverState,
          },
        },
      },
    };
    setSharedMutation(updatedMutations);
  };
  const chartHoverHandler = (id, hoverState) => {
    legendHoverHandler(id, hoverState);
  };
  const getXAxisFormatter = (item, dateRangeObj) => {
    const xAxisTickValuesMap = dateRangeObj.xAxisTickValuesMap;
    if (xAxisTickValuesMap[item]) {
      return getDisplayDate(
        xAxisTickValuesMap[item],
        dateRangeObj.dateRange,
        `by-${dateRangeObj.timeAgg}`,
        dateRangeObj.startDate,
        dateRangeObj.endDate,
      );
    }
    return '';
  };
  const chartScale = { x: 'time' };
  const { timerange, timeAgg, startDate, endDate } = getDateRangeProps(
    chartResp,
  );
  const dateRangeData = getDateRangeData(
    timerange,
    timeAgg,
    startDate,
    endDate,
  );
  const chartData = getChartData(
    chartResp,
    dateRangeData,
    props.selectedDataSource,
  );
  const lineChartData = getLineChartsData(chartData, 'CUSTOM_METRICS_CURRENT');
  const { classes } = props;
  return (
    <div className={classes.chartContainer}>
      <div className={classes.chartBody}>
        {props.isLoading ? (
          <Skeleton variant="rect" animation="wave" className={classes.loadingLegends} />
        ) : (
          <ChartLegendPanel
            chartData={chartData}
            lineColors={lineColors}
            chartEntity={props.chartEntity}
            clickHandler={legendClickHandler}
            hoverHandler={legendHoverHandler}
            isTop3EntitiesSelected={props.isTop3EntitiesSelected}
            isApiOwnerOrOrgPub={props.isApiOwnerOrOrgPub}
            isOrgPublisher={props.isOrgPublisher}
          />
        )}
        {props.isLoading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <CompositeXYChart
            chartData={lineChartData}
            chartScale={chartScale}
            formatXAxisData={(item) => getXAxisFormatter(item, dateRangeData)}
            lineColors={lineColors}
            lightShadeLineColors={lightShadeLineColors}
            xAxisValues={dateRangeData.xAxisTickValues}
            hoverHandler={chartHoverHandler}
          />
        )}
      </div>
    </div>
  );
}

ChartPanelRaw.propTypes = {
  chartResp: PropTypes.object,
  selectedDataSource: PropTypes.arrayOf(PropTypes.sobject),
  classes: PropTypes.object,
  isTop3EntitiesSelected: PropTypes.bool,
  chartEntity: PropTypes.string,
  isLoading: PropTypes.bool,
  isApiOwnerOrOrgPub: PropTypes.bool,
  theme: PropTypes.object,
  metric: PropTypes.object,
  isOrgPublisher: PropTypes.bool,
};
export default compose(withStyles(Theme))(withTheme(ChartPanelRaw));
