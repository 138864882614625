import React, { useState, useEffect, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { has, map } from 'lodash';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { getI18n } from '../../../../utils/intl';


import {
  RadioGroupSection,
  FormActionButtons,
  ErrorContainer,
  SearchTextListContainer,
  FormAutoCompleteCombo,
  AlertDialog,
} from '../../../../components';
import {
  ID_PUBLIC,
  ID_PRIVATE,
  ID_RESTRICTED,
  MANAGEMENT_PERMISSIONS_DATA,
  MANAGEMENT_USER_TITLE,
  MANAGEMENT_USER_SUB_TITLE,
  MANAGEMENT_USER_PLACE_HOLDER_TEXT,
  MANAGEMENT_SELECTED_USER_TITLE,
  MANAGEMENT_SELECTED_USER_SUB_TITLE,
  MANAGEMENT_SELECTED_USER_PLACE_HOLDER_TEXT,
  MANAGEMENT_SELECTED_ITEM_ERROR_MESSAGE,
  SAVE_AND_NEXT_TEXT,
  API_MANAGING_ORG_POPUP_TITLE,
  API_MANAGING_ORG_POPUP_MESSAGE,
  API_MANAGING_ORG_POPUP_SUBMIT_TEXT,
  API_MANAGING_ORG_POPUP_CANCEL_TEXT,
} from '../labels';
import { getSingularRoleName, hasPublisherRole, getApiOwnerUser } from '../../../../utils';

const ADMIN_PERMISSIONS_DATA = MANAGEMENT_PERMISSIONS_DATA.filter(option =>
  option.id !== ID_PRIVATE);


export default function ManagementPermission(props) {
  const {
    classes,
    userContext,
    apiUuid,
    tabValue,
    apiDetails,
    users,
    onCancel,
    errors,
    publisherOrgs,
    defaultSelectedManageOrg,
    isLoadMore,
    onLoadMore,
    organizationsPage,
   } = props;

  const isDisabled = !apiUuid;
  const [isSaveManageOrgApi, setIsSaveManageOrgApi] = useState(false);
  const [isSavePermissionApi, setIsSavePermissionApi] = useState(false);
  const [isChangePermission, setIsChangePermission] = useState(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [selectedPermission, setPermission] = useState('');
  const [selectedManageOrg, setSelectedManageOrg] = useState(defaultSelectedManageOrg);
  const [isManageOrgLoad, setIsManageOrgLoad] = useState(false);
  const [mngOrgVisibilityPopupShown, setMngOrgVisibilityPopupShown] = useState(false);

  const getDefaultPermission = () => {
    let defaultPermission = selectedPermission;
    if (!isChangePermission) {
      if (userContext.portalAdmin) {
        defaultPermission = props.defaultSelectedUsers.length > 0 ? ID_RESTRICTED :
        ID_PUBLIC;
      } else if (userContext.apiOwner) {
        if (props.defaultSelectedUsers.length === 0) {
          defaultPermission = ID_PUBLIC;
        } else if (props.defaultSelectedUsers.length === 1) {
          defaultPermission = ID_PRIVATE;
        } else {
          defaultPermission = ID_RESTRICTED;
        }
      }
    }
    return defaultPermission;
  };
  const intl = getI18n(useIntl());
  const [selectedUsers, setSelectedUsers] = useState(props.selectedUsers);
  const data = userContext.portalAdmin ?
    ADMIN_PERMISSIONS_DATA : MANAGEMENT_PERMISSIONS_DATA;

  const fetchManagementData = async () => {
    await props.fetchApi(apiUuid);
    await props.fetchSelectedUsers(apiUuid);
    await props.fetchAvailableUsers(apiUuid);
  };

  useEffect(() => {
    if ((hasPublisherRole(userContext)) && apiUuid
      && tabValue === 'management-tab') {
      fetchManagementData();
    }
  }, [tabValue]);

  useEffect(() => {
    if (apiDetails.managingOrgUuid && hasPublisherRole(userContext) && tabValue === 'management-tab') {
      props.fetchSelectedManageOrg(apiDetails.managingOrgUuid);
    }
  }, [apiDetails]);

  useEffect(() => {
    setPermission(getDefaultPermission());
  }, [props.selectedUsers]);

  useEffect(() => {
    setSelectedUsers(props.selectedUsers);
  }, [props.selectedUsers]);

  useEffect(() => {
    if (has(defaultSelectedManageOrg, 'uuid')) {
      setSelectedManageOrg(defaultSelectedManageOrg);
    }
  }, [defaultSelectedManageOrg]);

  useEffect(() => {
    setIsManageOrgLoad(isLoadMore);
  }, [isLoadMore]);

  useEffect(() => {
    if (organizationsPage.currentPage < (organizationsPage.totalPages - 1)) {
      onLoadMore();
    }
  }, [organizationsPage]);

  const onManageOrgChange = (e, value) => {
    setIsUnsavedChanges(true);
    localStorage.setItem('isUnSavedChanges', true);
    setIsChangePermission(true);
    setSelectedManageOrg(value);
    setIsSaveManageOrgApi(true);
  };

  const onSearchListChange = (selectedUser) => {
    setSelectedUsers([...selectedUser]);
    setIsUnsavedChanges(true);
    setIsSavePermissionApi(true);
    localStorage.setItem('isUnSavedChanges', true);
  };

  const onChangePermission = (e) => {
    setPermission(e.target.value);
    setIsChangePermission(true);
    setIsUnsavedChanges(true);
    setIsSavePermissionApi(true);
    localStorage.setItem('isUnSavedChanges', true);
  };

  const closeMngOrgVisibilityDialog = () => setMngOrgVisibilityPopupShown(false);

  const onNextManagement = async (permission, manageUsers, manageOrg, retainMngOrgVisibility) => {
    let dataToSave = [];

     // only api owner can choose private option
     // If an API Owner chooses the 'private' option -
     // add the API Owner UUID to the selected users able to access the API
    if (permission === ID_PRIVATE) {
      const apiOwner = getApiOwnerUser(userContext);
      dataToSave = [{ uuid: apiOwner.uuid }];
    }
    if (permission === ID_RESTRICTED) {
      map(manageUsers, object => {
        dataToSave.push({ uuid: object.uuid });
      });
    }
    await props.savePermissions(dataToSave, apiUuid, manageOrg,
      isSavePermissionApi, isSaveManageOrgApi, retainMngOrgVisibility);
  };

  const onNext = (retainMngOrgVisibility) => {
    if (selectedPermission === ID_RESTRICTED && selectedUsers.length === 0) {
      return false;
    }
    if (selectedPermission === ID_PUBLIC) {
      setSelectedUsers([]);
    }
    setIsUnsavedChanges(false);
    localStorage.setItem('isUnSavedChanges', false);
    onNextManagement(selectedPermission, selectedUsers, selectedManageOrg, retainMngOrgVisibility);
    return true;
  };

  const handleMngOrgVisibilityResponse = (retainMngOrgVisibility) => {
    closeMngOrgVisibilityDialog();
    onNext(retainMngOrgVisibility);
  };

  const onClickNext = () => {
    // A popup should be shown when the access is private and the managing org has been changed.
    // and also the existing managing org should not be empty.
    if (apiDetails.accessStatus === 'PRIVATE' && defaultSelectedManageOrg &&
         defaultSelectedManageOrg.uuid &&
         (selectedManageOrg === null || defaultSelectedManageOrg.uuid !== selectedManageOrg.uuid)) {
      setMngOrgVisibilityPopupShown(true);
      return;
    }
    onNext();
  };

  const getItemSecondaryText = (user) => user.roleOrgs.map(role => getSingularRoleName(role));
  return (
    <div id="api-management-container">
      {mngOrgVisibilityPopupShown &&
        <AlertDialog
          isOpen={mngOrgVisibilityPopupShown}
          title={intl.getI18nMessage(API_MANAGING_ORG_POPUP_TITLE)}
          description={intl.getI18nMessage(API_MANAGING_ORG_POPUP_MESSAGE,
            { existingOrg: `'${defaultSelectedManageOrg.name}'` })}
          submitText={intl.getI18nMessage(API_MANAGING_ORG_POPUP_SUBMIT_TEXT)}
          cancelText={intl.getI18nMessage(API_MANAGING_ORG_POPUP_CANCEL_TEXT)}
          onClose={closeMngOrgVisibilityDialog}
          handleClose={closeMngOrgVisibilityDialog}
          showCloseButton
          onSubmit={() => handleMngOrgVisibilityResponse(true)}
          onCancel={() => handleMngOrgVisibilityResponse(false)}
          dialogId="mng-org-visibility-dialog"
        />
      }
      <h1 className={classes.pageTitle}>Management Permissions</h1>
      { hasPublisherRole(userContext) &&
        <Fragment>
          <h2 className={classes.sectionTitle}>Managing Organization</h2>
          <FormAutoCompleteCombo
            data={publisherOrgs}
            selectedValues={selectedManageOrg}
            onChange={(e, value) => onManageOrgChange(e, value)}
            loading={isManageOrgLoad}
            id="managing-organizations"
            inputLabel="Select Managing Organization"
          />
        </Fragment>
      }
      <h2 className={classes.sectionTitle} id="manage-user-container">
        API Owner Permissions
      </h2>
      <RadioGroupSection
        selected={selectedPermission}
        onChange={onChangePermission}
        data={data}
        id="management-radio-group"
      />
      {errors.length > 0 &&
        <ErrorContainer errors={errors} />
      }
      {selectedPermission === ID_RESTRICTED &&
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid md={6} item>
            <SearchTextListContainer
              title={MANAGEMENT_USER_TITLE}
              subtitle={MANAGEMENT_USER_SUB_TITLE}
              placeHolderText={MANAGEMENT_USER_PLACE_HOLDER_TEXT}
              defaultList={users}
              defaultSelectedList={selectedUsers}
              listId={'users-list'}
              isCheckBoxVisible
              onSearchListChange={onSearchListChange}
              isDisableSelectedOption
              disableSelectedOptions={[userContext]}
              userContext={userContext}
              getItemSecondaryText={getItemSecondaryText}
            />
          </Grid>
          <Grid md={6} item>
            <SearchTextListContainer
              title={MANAGEMENT_SELECTED_USER_TITLE}
              subtitle={MANAGEMENT_SELECTED_USER_SUB_TITLE}
              placeHolderText={MANAGEMENT_SELECTED_USER_PLACE_HOLDER_TEXT}
              isSelectedNumberVisible
              defaultList={selectedUsers}
              defaultSelectedList={selectedUsers}
              listId={'users-selected-list'}
              onSearchListChange={onSearchListChange}
              isDeleteIconVisible
              isDisableSelectedOption
              userContext={userContext}
              disableSelectedOptions={[userContext]}
              getItemSecondaryText={getItemSecondaryText}
              selectedItemErrorMessage={MANAGEMENT_SELECTED_ITEM_ERROR_MESSAGE}
            />
          </Grid>
        </Grid>
      }
      <FormActionButtons
        nextText={SAVE_AND_NEXT_TEXT}
        onNextClick={onClickNext}
        onCancelClick={() => onCancel(isUnsavedChanges)}
        id="management-form-buttons"
        isDisabled={isDisabled}
      />
    </div>
  );
}

ManagementPermission.propTypes = {
  classes: PropTypes.object,
  userContext: PropTypes.object,
  selectedUsers: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  onCancel: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.object),
  publisherOrgs: PropTypes.arrayOf(PropTypes.object),
  defaultSelectedManageOrg: PropTypes.object,
  isLoadMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  organizationsPage: PropTypes.object,
  apiUuid: PropTypes.string,
  tabValue: PropTypes.string,
  apiDetails: PropTypes.object,
  defaultSelectedUsers: PropTypes.object,
  fetchApi: PropTypes.func,
  fetchSelectedUsers: PropTypes.func,
  fetchAvailableUsers: PropTypes.func,
  fetchSelectedManageOrg: PropTypes.func,
  savePermissions: PropTypes.func,
};
