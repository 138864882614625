import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { func, shape } from 'prop-types';
import {
  Button,
  Grid,
  withStyles,
} from '@material-ui/core';

import styles from './styles';
import { getI18n } from '../../../../utils/intl';
import { OverviewPaper } from './Paper';
import {
  FormAutoCompleteCombo,
} from '../../../../components';

export const Organizations = (props) => {
  const {
    classes = {},
    proxyOrganizations = {},
    push,
  } = props;

  const intl = getI18n(useIntl());
  const [selectedOrganization, setSelectedOrganization] = useState();

  return (
    <OverviewPaper
      id="organizations"
      title={intl.getI18nMessage('label.organizations')}
      content={proxyOrganizations.totalElements}
      contentMore={
        <Grid container>
          <Grid item md={9}>
            <FormAutoCompleteCombo
              fieldContainerClass={classes.selectOrganization}
              id="select-organization"
              inputLabel={intl.getI18nMessage('label.proxy.details.page.select.organization')}
              name={intl.getI18nMessage('label.go.to')}
              data={proxyOrganizations.results}
              onChange={(e, value) => setSelectedOrganization(value)}
            />
          </Grid>
          <Grid item md={3}>
            <Button
              className={classes.selectOrganizationGo}
              color="primary"
              variant="outlined"
              id="select-organization-go" data-layer7-test="select-organization-go"
              onClick={() => push(`/admin/console/organizations/details/${selectedOrganization.uuid}`)}
              disabled={!selectedOrganization}
            >
              {intl.getI18nFormattedMessage('label.go')}
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

Organizations.propTypes = {
  classes: shape({}),
  proxyOrganizations: shape({}),
  push: func,
};
Organizations.displayName = 'Organizations';

export default (
  withStyles(styles)
)(Organizations);
