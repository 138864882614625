import modifyColour from '../../../utils/modifyColour';
import { get } from 'lodash';

export default ({ color, fontSize, misc, typography }) => ({
  Menu: {
    fontFamily: get(typography, 'bodyText'),
    width: 225,
    padding: '10px 0 8px',
  },
  HeaderRequestsMenu: {
    paddingBottom: 0,
  },
  HeaderRequestsMenuWrapper: {
    padding: '10px 20px',
  },
  HeaderRequestsMenuItem: {
    borderBottom: `1px solid ${get(color, 'grayLight')}`,
    display: 'block',
    margin: '0px -20px',
    padding: '10px 20px',
    color: get(color, 'link'),
    fontSize: get(fontSize, 'smallText'),
    lineHeight: '1.2em',
    textDecoration: 'none',
    transition: get(misc, 'colorTransition'),
    '&:hover': {
      color: modifyColour(get(color, 'link'), 'lighten', 0.4),
    },
    '&:first-of-type': {
      paddingTop: 0,
    },
    '&:last-of-type': {
      paddingBottom: 0,
    },
    '&:last-child': {
      border: 'none',
      marginBottom: 0,
    },
  },
  HeaderRequestsButton: {
    display: 'none',
    '& button': {
      color: get(color, 'headerLink'),
    },
  },
  HeaderRequestsViewMore: {
    borderTop: `1px solid ${get(color, 'gray')}`,
    padding: '20px 0px',
    fontSize: get(fontSize, 'bodyText'),
    textAlign: 'center',
    '& a': {
      color: get(color, 'link'),
      textDecoration: 'none',
    },
  },
  HeaderRequestsNoRequestsIcon: {
    color: `${get(color, 'link')} !important`,
    display: 'inline-block',
    margin: '0 auto',
    paddingTop: '5px',
    textAlign: 'center',
    width: '100%',
  },
  HeaderRequestsNoRequestsText: {
    color: get(color, 'bodyText'),
    fontSize: get(fontSize, 'large'),
    fontWeight: '100',
    margin: '0 auto',
    paddingTop: get(misc, 'gutter'),
    textAlign: 'center',
    width: '100%',
  },
  HeaderRequestsNoRequestsMessage: {
    color: get(color, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    fontWeight: '100',
    margin: '0 auto',
    paddingBottom: get(misc, 'gutter'),
    paddingTop: get(misc, 'gutter'),
    textAlign: 'center',
    width: '100%',
  },
  SubHeader: {
    marginBottom: 15,
    color: get(color, 'gray'),
    fontSize: get(fontSize, 'smallText'),
  },
  SubHeaderUppercase: {
    textTransform: 'uppercase',
  },
  [`@media (min-width: ${get(misc, 'breakpointMedium')}px)`]: {
    HeaderRequestsButton: {
      display: 'inline-block',
    },
  },
});
