import React, { Fragment } from 'react';
import Dropzone from 'react-dropzone';
import { PropTypes } from 'prop-types';
import { makeStyles, FormHelperText } from '@material-ui/core';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';

import ListFilesRaw from './ListFiles';
import styles from './styles';

export default function FileDropzone(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    handleFiles,
    allowedFileExtentions,
    listFileTitles = ['Selected files'],
    onRemoveFile,
    isValidation,
    validationMessage,
    id,
    defaultUploaderText = 'Uploader',
    defaultSelectedText = 'No files selected.',
    isMultipleTitle = false,
    title,
    showSelectedFiles = true,
    selectedFiles,
  } = props;

  return (
    <div id={id} data-apim-test={id}>
      <h4 className={classes.formLabel}>{title}</h4>
      <Fragment>
        <Dropzone
          accept={selectedFiles.acceptFileTypes}
          maxSize={selectedFiles.maxSize}
          maxFiles={selectedFiles.maxFiles}
          onDrop={acceptedFiles => handleFiles(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classes.container}>
              <input {...getInputProps()} />
              <div className={classes.fileLabel}>
                <PublishRoundedIcon color="primary" fontSize="large" />
                <div>
                  {defaultUploaderText}
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        <FormHelperText
          classes={{
            root: classes.root,
          }}
          error={selectedFiles.error}
        >
          <div className={classes.helperText}>
            {selectedFiles.helperText}
          </div>
        </FormHelperText>
      </Fragment>
      {isValidation &&
        <div className={classes.error}>{validationMessage}</div>
      }
      {showSelectedFiles && (
        isMultipleTitle ?
          allowedFileExtentions.map((value, index) => {
            const filterFiles = selectedFiles.files.filter((file) => file.ext === value);
            return (
              <ListFilesRaw
                title={listFileTitles[index] ? listFileTitles[index] : listFileTitles[0]}
                files={filterFiles}
                defaultText={defaultSelectedText}
                onRemoveFile={onRemoveFile}
                id={`selected-container-${index.toString()}`}
                key={`file_${index.toString()}`}
              />
            );
          })
        :
          <ListFilesRaw
            title={listFileTitles[0]}
            files={selectedFiles.files}
            onRemoveFile={onRemoveFile}
            defaultText={defaultSelectedText}
            id={'file-selected-container'}
          />
        )
      }
    </div>
  );
}

FileDropzone.propTypes = {
  showSelectedFiles: PropTypes.bool,
  selectedFiles: PropTypes.object,
  handleFiles: PropTypes.func,
  allowedFileExtentions: PropTypes.arrayOf(PropTypes.string),
  listFileTitles: PropTypes.arrayOf(PropTypes.string),
  onRemoveFile: PropTypes.func,
  isValidation: PropTypes.bool,
  validationMessage: PropTypes.string,
  id: PropTypes.string,
  defaultUploaderText: PropTypes.string,
  defaultSelectedText: PropTypes.string,
  isMultipleTitle: PropTypes.bool,
  title: PropTypes.string,
};
