import { useIntl } from 'react-intl';

import {
  ROLE_PORTAL_ADMIN,
  ROLE_API_OWNER,
  ROLE_ORG_ADMIN,
  ROLE_DEVELOPER,
  ROLE_ORG_PUBLISHER,
} from '../constants';
import { getI18n } from './intl';

export const getUserRole = ({
  portalAdmin, apiOwner, orgAdmin, developer, orgPublisher,
} = {}) => {
  if (portalAdmin) {
    return ROLE_PORTAL_ADMIN;
  } else if (apiOwner) {
    return ROLE_API_OWNER;
  } else if (orgAdmin) {
    return ROLE_ORG_ADMIN;
  } else if (developer) {
    return ROLE_DEVELOPER;
  } else if (orgPublisher) {
    return ROLE_ORG_PUBLISHER;
  }
  return null;
};

export const hasPortalAdminRole = (userContext) => (ROLE_PORTAL_ADMIN === getUserRole(userContext));

export const hasPublisherRole = (userContext) => (
  [ROLE_PORTAL_ADMIN, ROLE_API_OWNER].includes(getUserRole(userContext))
);

export const getRoleLabel = ({ roleName }) => {
  const intl = getI18n(useIntl());
  return intl.getI18nMessage(`label.user.role.${roleName}`);
};

export const getCanListUsers = (userContext) => (
  [
    ROLE_PORTAL_ADMIN, ROLE_API_OWNER, ROLE_ORG_ADMIN,
  ].includes(getUserRole(userContext))
);

export const getCanCreateUser = (userContext) => (
  [
    ROLE_PORTAL_ADMIN, ROLE_ORG_ADMIN,
  ].includes(getUserRole(userContext))
);

const getOrgAdminShouldNotEditOrgPublisher = ({ userContext, access = [] }) => {
  const isUserContextOrgAdmin = getUserRole(userContext) === ROLE_ORG_ADMIN;
  const isOrgPublisher = access.find(role => (role.roleName === ROLE_ORG_PUBLISHER));
  return !(isUserContextOrgAdmin && isOrgPublisher);
};

export const getCanManageUser = ({ uuid, userContext, access = [] }) => {
  const isNotSelf = (userContext.uuid !== uuid);
  const canCreateUser = getCanCreateUser(userContext);
  return (
    isNotSelf &&
    getOrgAdminShouldNotEditOrgPublisher({ userContext, access }) &&
    canCreateUser
  );
};
