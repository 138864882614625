import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';
import IconAdd from '@material-ui/icons/Add';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { bool, func, object, string } from 'prop-types';

/**
 * The toolbar displayed at the top of the document view
 */
const DocumentToolbar = ({
    disabled,
    document,
    entityType,
    entityUuid,
    hasChildren,
    userCanEdit,
    userCanAdd,
    userCanDelete,
    onEdit,
    onAddNewDocument,
    onDeleteDocument,
    id,
}) => {
    const classes = useStyles();

    if (!document || (!userCanEdit && !userCanDelete)) {
        return null;
    }

    return (
        <div id={id} data-apim-test={id}>
            <div className={classes.root}>
                {userCanAdd && (
                    <Button
                        document={document}
                        color="primary"
                        size="small"
                        onClick={onAddNewDocument}
                        disabled={disabled}
                        className={classes.button}
                        startIcon={<IconAdd />}
                        variant="text"
                        id="api-details-new-child-doc-add-button"
                        data-apim-test="api-details-new-child-doc-add-button"
                    >
                        {'NEW CHILD'}
                    </Button>
                )}
                {userCanEdit && (
                    <Button
                        color="primary"
                        size="small"
                        onClick={onEdit}
                        disabled={disabled}
                        className={classes.button}
                        startIcon={<IconEdit />}
                        variant="text"
                        id="api-details-doc-edit-button"
                        data-apim-test="api-details-doc-edit-button"
                    >
                        {'EDIT'}
                    </Button>
                )}
                {userCanDelete && (
                    <Button
                        document={document}
                        entityType={entityType}
                        entityUuid={entityUuid}
                        hasChildren={hasChildren}
                        color="primary"
                        size="small"
                        onClick={onDeleteDocument}
                        disabled={disabled}
                        className={classes.button}
                        startIcon={<IconDelete />}
                        variant="text"
                        id="api-details-doc-delete-button"
                        data-apim-test="api-details-doc-delete-button"
                    >
                        {'DELETE'}
                    </Button>
                )}
            </div>
            <Divider />
        </div>
    );
};

const useStyles = makeStyles(
    theme => ({
        root: {
            padding: theme.spacing(1),
            '& .MuiButton-root.Mui-disabled': {
                marginLeft: theme.spacing(2),
                backgroundColor: 'transparent !important',
                color: `${get(theme, 'color.gray')} !important`,
                border: '0 !important',
            },
        },
        button: {
            '& + &': {
                marginLeft: theme.spacing(2),
            },
        },
    }),
);

DocumentToolbar.propTypes = {
    disabled: bool,
    document: object,
    entityType: string,
    entityUuid: string,
    hasChildren: object,
    userCanEdit: bool,
    userCanAdd: bool,
    userCanDelete: bool,
    onEdit: func,
    onAddNewDocument: func,
    onDeleteDocument: func,
    id: string,
};

export default DocumentToolbar;
