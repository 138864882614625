import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  withStyles,
  Typography,
} from '@material-ui/core';
import LensIcon from '@material-ui/icons/Lens';
import { func, shape, string } from 'prop-types';
import { startCase, capitalize } from 'lodash';
import copyToClipboard from 'copy-to-clipboard';

import {
    DEPLOYMENT_STATUS_ERROR,
    DEPLOYMENT_STATUS_ERROR_UNDEPLOY,
    CLOSE_TEXT,
    COPY_CLOSE_TEXT,
    ALERT_SUCCESS,
    DIALOG_TITLE_ERROR,
} from '../../constants';
import AlertDialog from '../AlertDialog';
import { formatStatusMessage } from '../../utils';
import { getI18n } from '../../utils/intl';
import styles from './styles';


export const StatusContent = (props) => {
  const { id, message, statusLabel, classes, notifyMessages } = props;
  const isError = [
    DEPLOYMENT_STATUS_ERROR,
    DEPLOYMENT_STATUS_ERROR_UNDEPLOY,
  ].includes(statusLabel);
  const [isDialog, setIsDialog] = useState(false);
  const intl = getI18n(useIntl());
  const errorMessage = formatStatusMessage(message)

  const copy = () => {
    setIsDialog(false);
    notifyMessages(ALERT_SUCCESS, intl.getI18nMessage('label.proxy.details.tab.error.copied'));
    copyToClipboard(errorMessage);
    setTimeout(() => notifyMessages('', ''), 3000);
  };

  const closeConfirmDialog = () => {
    setIsDialog(false);
  };

  const showErrorDialog = () => {
    if(isError) {
        setIsDialog(true);
    }
  };

  return (
    <Fragment>
        {isDialog &&
            <AlertDialog
                isOpen={isDialog}
                title={DIALOG_TITLE_ERROR}
                description={errorMessage}
                submitText={COPY_CLOSE_TEXT}
                cancelText={CLOSE_TEXT}
                onClose={closeConfirmDialog}
                onSubmit={copy}
                onCancel={closeConfirmDialog}
                dialogId={'keys-error-dialog'}
                dialogActionClass={classes.dialogAction}
                submitButtonClass={classes.dialogSubmit}
                cancelButtonClass={classes.dialogCancel}
            />
        }
        <Typography id={id} data-apim-test={id} className={classes.cardStatus}>
        <span className={classes[statusLabel]}>
            <LensIcon className={classes.cardStatusIcon} />
        </span>
        <span className={isError ? classes.errorContent : classes.label} onClick={showErrorDialog}>
          {startCase(capitalize(statusLabel))}
        </span>
    </Typography>
  </Fragment>
  );
};

StatusContent.propTypes = {
  id: string,
  message: string,
  classes: shape({}),
  statusLabel: string,
  notifyMessages: func,
};

export default withStyles(styles)(StatusContent);
