import { get } from 'lodash';

const styles = (theme) => ({
  fieldContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formLabel: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontWeight: 700,
    paddingLeft: 0,
  },
});

export default styles;
