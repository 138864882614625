import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import {
  FormActionButtons,
  ErrorContainer,
  FormTextField,
  FormButtonGroup,
  FormDialog,
} from '../../../../components';
import {
  ALERT_SUCCESS,
} from '../../../../constants';
import {
  APPLICATION_AUTH_SELECT_HASHING_DIALOG_SUBTITLE,
  APPLICATION_AUTH_SELECT_HASHING_DIALOG_CHECKBOX_LABEL,
  CANCEL_TEXT,
  CREATE_TEXT,
} from '../labels';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import { hasOrgBoundRole } from '../../../../utils';

export default function Auth(props) {
  const {
    classes,
    userContext,
    headerRef,
    errors = [],
    showLoading,
    push,
    setCurrentTab,
    applicationUuid,
    applicationDetails = {},
    setApplicationDetails,
    appSecretHashingMetadata,
    setShowOneTimePasswordWarning,
    setIsHashedSecret,
    isPlainTextCheckBoxSelected,
    setIsPlainTextCheckBoxSelected,
    onCancel,
    onSaveApplication,
    isSaveApplicationSuccess,
    notifyMessages,
    isApplicationRequestWorkflowEnabled,
    isEditApplicationRequestWorkflowEnabled,
  } = props;

  const intl = getI18n(useIntl());
  const { oauthCallbackUrl, oauthScope, oauthType } = applicationDetails;
  const allowSelectHashing = get(appSecretHashingMetadata, 'plaintextAllowed');

  const [isSelectHashingDialogOpen, setIsSelectHashingDialogOpen] = useState(false);

  const handleChange = (fieldName, fieldValue = null) => {
    localStorage.setItem('isAppUnSavedChanges', true);
    setApplicationDetails({ ...applicationDetails, [fieldName]: fieldValue });
  };

  useEffect(() => {
    if (isSaveApplicationSuccess && applicationDetails.uuid) {
      const isHashedSecretEnforced =
        get(appSecretHashingMetadata, 'algorithm') && !get(appSecretHashingMetadata, 'plaintextAllowed');
      const isHashedSecretSelected =
        get(appSecretHashingMetadata, 'plaintextAllowed') && !isPlainTextCheckBoxSelected;
      const isHashedSecret = isHashedSecretEnforced || isHashedSecretSelected;

      setIsHashedSecret(isHashedSecret);
      if (!applicationUuid) {
        setShowOneTimePasswordWarning(isHashedSecret);
      }
      setCurrentTab('key-tab');
      push(`/publish/applications/edit/${applicationDetails.uuid}/key`);
      showLoading(false);
      notifyMessages(
        hasOrgBoundRole(userContext) && isApplicationRequestWorkflowEnabled ?
          intl.getI18nMessage('label.application.request.submit.success') :
          intl.getI18nMessage('label.application.create.success'),
        ALERT_SUCCESS,
      );
      window.scrollTo(0, headerRef.offsetTop);
    }
  }, [isSaveApplicationSuccess, applicationDetails]);

  const onNextUpdate = async () => {
    setCurrentTab('key-tab');
    window.scrollTo(0, headerRef.offsetTop);
  };

  const onNextCreate = async () => {
    localStorage.setItem('isAppUnSavedChanges', false);
    if (allowSelectHashing) {
      setIsSelectHashingDialogOpen(true);
    } else {
      await showLoading(true);
      await onSaveApplication();
    }
  };

  const submitChangeRequestLabel = getI18nFormattedMessage('label.application.request.submit');
  const createApplicationLabel =
    hasOrgBoundRole(userContext) && isApplicationRequestWorkflowEnabled
    ? submitChangeRequestLabel : CREATE_TEXT;
  const createApplicationDialogLabel =
    hasOrgBoundRole(userContext) && isApplicationRequestWorkflowEnabled
    ? intl.getI18nMessage('label.application.create.after.approval.select.hashing')
    : intl.getI18nMessage('label.application.create.select.hashing');

  const createApplicationAssistText =
    hasOrgBoundRole(userContext) && isApplicationRequestWorkflowEnabled
    ? intl.getI18nMessage('label.application.create.after.approval.assist.text')
    : intl.getI18nMessage('label.application.create.assist.text');

  const getNewSecretLabel =
    hasOrgBoundRole(userContext) && isEditApplicationRequestWorkflowEnabled
      ? intl.getI18nMessage('label.application.key.shared.secret.request.new')
      : intl.getI18nMessage('label.application.key.shared.secret.generate.new');

  return (
    <Fragment>
      <Typography variant="h1" className={classes.pageTitle}>
        Authentication
      </Typography>
      {errors.length > 0 &&
        <ErrorContainer errors={errors} />
      }
      <FormDialog
        dialogId="application-select-hashing-dialog"
        isOpen={isSelectHashingDialogOpen}
        title={getNewSecretLabel}
        description={APPLICATION_AUTH_SELECT_HASHING_DIALOG_SUBTITLE}
        submitText={createApplicationDialogLabel}
        onSubmit={async () => {
          setIsSelectHashingDialogOpen(false);
          await showLoading(true);
          await onSaveApplication(!isPlainTextCheckBoxSelected);
          notifyMessages(intl.getI18nMessage('label.application.create.success'), ALERT_SUCCESS);
        }}
        cancelText={CANCEL_TEXT}
        onCancel={() => {
          setIsSelectHashingDialogOpen(false);
        }}
      >
        <Fragment>
          <Typography component={'span'}>
            {APPLICATION_AUTH_SELECT_HASHING_DIALOG_SUBTITLE}
          </Typography>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                classes={{ root: classes.iconRoot }}
                checked={isPlainTextCheckBoxSelected}
                onChange={(e) => setIsPlainTextCheckBoxSelected(e.target.checked)}
              />
            }
            label={APPLICATION_AUTH_SELECT_HASHING_DIALOG_CHECKBOX_LABEL}
          />
        </Fragment>
      </FormDialog>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid md={12} item>
          <FormTextField
            id={'application-details-oauth-callback-url'}
            name={'Callback/Redirect URL(s)'}
            value={oauthCallbackUrl || ''}
            handleChange={(value) => { handleChange('oauthCallbackUrl', value || ''); }}
            helperText={'Maximum length is 2048 characters. Use commas to separate entries.'}
            multiline
            rows={4}
            optional
            maxLength="2048"
          />
          <FormTextField
            id={'application-details-scope'}
            name={'Scope'}
            value={oauthScope || ''}
            handleChange={(value) => { handleChange('oauthScope', value || ''); }}
            helperText={'Maximum length is 4000 characters. Use spaces to separate entries. Maximum length is 450 characters for OTK versions older than 4.3.'}
            multiline
            rows={4}
            optional
            maxLength="4000"
          />
          <FormButtonGroup
            id={'application-details-oauth-type'}
            name={'Type'}
            value={oauthType}
            data={[
              { uuid: 'PUBLIC', name: 'Public' },
              { uuid: 'CONFIDENTIAL', name: 'Confidential' },
            ]}
            handleChange={(value) => { handleChange('oauthType', value); }}
          />
        </Grid>
      </Grid>
      <FormActionButtons
        onNextClick={applicationUuid ? onNextUpdate : onNextCreate}
        assistText={applicationUuid ? '' : createApplicationAssistText}
        nextText={applicationUuid ? getI18nFormattedMessage('label.next.button') : createApplicationLabel}
        onCancelClick={onCancel}
        id="authentication-form-buttons"
      />
    </Fragment>
  );
}

Auth.propTypes = {
  classes: PropTypes.object,
  userContext: PropTypes.object,
  headerRef: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  showLoading: PropTypes.func,
  push: PropTypes.func,
  setCurrentTab: PropTypes.func,
  applicationUuid: PropTypes.string,
  applicationDetails: PropTypes.object,
  setApplicationDetails: PropTypes.func,
  appSecretHashingMetadata: PropTypes.object,
  setShowOneTimePasswordWarning: PropTypes.func,
  setIsHashedSecret: PropTypes.func,
  isPlainTextCheckBoxSelected: PropTypes.bool,
  setIsPlainTextCheckBoxSelected: PropTypes.func,
  onCancel: PropTypes.func,
  onSaveApplication: PropTypes.func,
  isSaveApplicationSuccess: PropTypes.bool,
  isApplicationRequestWorkflowEnabled: PropTypes.bool,
  isEditApplicationRequestWorkflowEnabled: PropTypes.bool,
  notifyMessages: PropTypes.func,
};
