import axios from 'axios';
import { isEmpty } from 'lodash';
import { stringify } from 'query-string';

import * as Constants from '../../constants';
import { getConfig } from '../../reducers/portalConfig';
import { getValidationErrors } from '../../utils';
import { AXIOS_DEFAULT_OPTIONS } from '..';

const init = (dispatch, isLoading = true) =>
  dispatch({
    type: Constants.APP_LOADING,
    isLoading,
  });

export const resetApp = () => dispatch => dispatch({ type: Constants.APP_RESET });

export const showLoading = isLoading => dispatch => init(dispatch, isLoading);

export const fetchList = ({ filterByName = null, filterByLevel = null, filterByLimit = null,
   sortBy, page = 0, rowsPerPage = 12 }) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    
    const params = stringify({
      name: !isEmpty(filterByName) ? filterByName : undefined,
      assignmentLevel: filterByLevel !== 'all' ? filterByLevel : undefined,
      hasQuota: filterByLimit === 'quota' ? true: undefined,
      hasRate: filterByLimit === 'rate' ? true: undefined,
      sort: sortBy,
      page,
      size: rowsPerPage,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/rate-quotas?${params}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.RATE_QUOTA_GET_LIST_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.RATE_QUOTA_GET_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
    };

export const fetch = (uuid) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/rate-quotas/${uuid}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.RATE_QUOTA_DETAILS_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.RATE_QUOTA_DETAILS_ERROR,
          payload: getValidationErrors(error),
        }),
      );
    };

export const save = ({ name, assignmentLevel, description,
  quota, quotaInterval, rateLimit, spreadWindow, maxConcurrency }) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = {
      name,
      assignmentLevel,
      description,
      quota: {
        quota,
        interval: quotaInterval,
      },
      rateLimit: {
        maxRequestsPerSecond: rateLimit,
        windowSizeInSeconds: spreadWindow,
        maxConcurrency: maxConcurrency,
      },
    };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/rate-quotas`;
    await axios.post(url, data, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.RATE_QUOTA_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.RATE_QUOTA_SAVE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const update = ({ name, assignmentLevel, description,
  quota, quotaInterval, rateLimit, uuid, spreadWindow, maxConcurrency }) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = {
      name,
      assignmentLevel,
      description,
      quota: {
        quota,
        interval: quotaInterval,
      },
      rateLimit: {
        maxRequestsPerSecond: rateLimit,
        windowSizeInSeconds: spreadWindow,
        maxConcurrency: maxConcurrency,
      },
      uuid,
    };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/rate-quotas/${uuid}`;
    await axios.put(url, data, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        response = { data: { uuid: uuid } };
        dispatch({
          type: Constants.RATE_QUOTA_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.RATE_QUOTA_SAVE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const deleteRateQuota = (uuid) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/rate-quotas/${uuid}`;
    await axios.delete(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.RATE_QUOTA_DELETE_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.RATE_QUOTA_DELETE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

// Todo: integrate with papi to fetch rate quota related apis
export const fetchApis = ({ filterByName = null, filterByLevel = null, filterByLimit = null,
  sortBy, page = 0, rowsPerPage = 12 }) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    
    const params = stringify({
      name: !isEmpty(filterByName) ? filterByName : undefined,
      assignmentLevel: filterByLevel !== 'all' ? filterByLevel : undefined,
      hasQuota: filterByLimit === 'quota' ? true: undefined,
      hasRate: filterByLimit === 'rate' ? true: undefined,
      sort: sortBy,
      page,
      size: rowsPerPage,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/rate-quotas?${params}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.RATE_QUOTA_GET_LIST_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.RATE_QUOTA_GET_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchOrganizations = ({
  name = null,
  accountPlanUuid,
  type = Constants.ANY,
  status = Constants.ALL,
  page = 0,
  size = 12,
} = {}) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      name: !isEmpty(name) ? name : undefined,
      accountPlanUuid: !isEmpty(accountPlanUuid) ? accountPlanUuid : undefined,
      type: (type !== Constants.ANY) ? type : undefined,
      status: (status !== Constants.ALL) ? status : undefined,
      page,
      size,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/1.0/organizations?${params}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.RATE_QUOTA_ORGANIZATION_LIST_GET_SUCCESS,
          payload: response.data,
        }),
      )
      .catch((error) =>
        dispatch({
          type: Constants.RATE_QUOTA_ORGANIZATION_LIST_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApisOrganizations = ({
  rqUuid,
  name = null,
  tags = null,
  hasApiOrgRateQuota = false,
  page = 0,
  size = 12,
} = {}) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      name: !isEmpty(name) ? name : undefined,
      tags: !isEmpty(tags) ? tags : undefined,
      hasApiOrgRateQuota: hasApiOrgRateQuota,
      publishedByPortal: true,
      page,
      size,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/internal/access/apis-rate-quota/${rqUuid}?${params}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.RATE_QUOTA_APIS_ORGANIZATION_LIST_GET_SUCCESS,
          payload: response.data,
        }),
      )
      .catch((error) =>
        dispatch({
          type: Constants.RATE_QUOTA_APIS_ORGANIZATION_LIST_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

  export const fetchApisOrganizationsForDetails = ({
    rqUuid,
    name = null,
    tags = null,
    hasApiOrgRateQuota = false,
    page = 0,
    size = 12,
  } = {}) =>
    async (dispatch, getState) => {
      init(dispatch);
      const config = getConfig(getState());
      const { portal } = config;
  
      const params = stringify({
        name: !isEmpty(name) ? name : undefined,
        tags: !isEmpty(tags) ? tags : undefined,
        hasApiOrgRateQuota: hasApiOrgRateQuota,
        publishedByPortal: true,
        page,
        size,
      }, true);
  
      const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/internal/access/apis-rate-quota/${rqUuid}?${params}`;
      await axios.get(url, AXIOS_DEFAULT_OPTIONS)
        .then(response =>
          dispatch({
            type: Constants.RATE_QUOTA_APIS_ORGANIZATION_DETAILS_GET_SUCCESS,
            payload: response.data,
          }),
        )
        .catch((error) =>
          dispatch({
            type: Constants.RATE_QUOTA_APIS_ORGANIZATION_DETAILS_GET_ERROR,
            payload: getValidationErrors(error),
          }),
        );
    };

