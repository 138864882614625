import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import {
  FormActionButtons,
  FormSelect,
  LoadingDialog,
} from '../../../components';
import styles from './styles';
import { getI18n } from '../../../utils/intl';

import { processErrors } from '../../../utils';
import {
  FAIL,
  SAVE_CUSTOM_PAGES_RESET_SUCCESS,
  SUCCESS,
} from '../../../constants';

import { makeStyles, Typography } from '@material-ui/core';
import { getCustomPagePayload } from '../utils';
import { PageTypes } from '../utils';

export const AssignPagesRaw = ({
  bundles,
  publishedBundle,
  customPages,
  bundlePagesList,
  saveCustomPagesStatus,
  fetchCustomPages,
  fetchCustomPagesBundles,
  fetchCustomPagesListInBundle,
  updateCustomPages,
  resetUpdateCustomPagesStatus,
  setNotificationStatus,
  setNotificationMessage,
  errors,
}) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const intl = getI18n(useIntl());

  // Add useState for each FromSelect
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [homeCustomPage, setHomeCustomPage] = useState(null);
  const [loginCustomPage, setLoginCustomPage] = useState(null);
  const [signUpCustomPage, setSignUpCustomPage] = useState(null);
  const [accountSetupCustomPage, setAccountSetupCustomPage] = useState(null);
  const [resetPasswordCustomPage, setResetPasswordCustomPage] = useState(null);
  const [setNewPasswordCustomPage, setSetNewPasswordCustomPage] =
    useState(null);

  const getAssignedPages = () => ({
    [PageTypes.HOME]: homeCustomPage,
    [PageTypes.LOGIN]: loginCustomPage,
    [PageTypes.REGISTRATION]: signUpCustomPage,
    [PageTypes.ACCOUNT_SETUP]: accountSetupCustomPage,
    [PageTypes.REQUEST_RESET_PASSWORD]: resetPasswordCustomPage,
    [PageTypes.RESET_PASSWORD]: setNewPasswordCustomPage,
  });

  const [showLoadingDialog, setShowLoadingDialog] = useState(false);

  const handleAssignPages = async () => {
    setShowLoadingDialog(true);
    // Build payload for each pages using getCustomPagePayload()
    const assignedPages = getAssignedPages();
    const assignPagesPayload = Object.keys(assignedPages).flatMap(
      (pageType) => {
        const page = assignedPages[pageType];
        if (page) {
          return getCustomPagePayload(pageType, page);
        }
        return [];
      },
    );
    await updateCustomPages(assignPagesPayload, selectedBundle);
    await fetchCustomPages();
    await fetchCustomPagesBundles();
    setShowLoadingDialog(false);
  };

  useEffect(() => {
    if (saveCustomPagesStatus === SUCCESS) {
      setNotificationStatus('success');
      setNotificationMessage(
        intl.getI18nFormattedMessage('label.custom.pages.save.success'),
      );
      fetchCustomPagesBundles();
    } else if (saveCustomPagesStatus === SAVE_CUSTOM_PAGES_RESET_SUCCESS) {
      setNotificationStatus('success');
      setNotificationMessage(
        intl.getI18nFormattedMessage('label.custom.pages.save.reset.success'),
      );
      fetchCustomPagesBundles();
    } else if (saveCustomPagesStatus === FAIL) {
      const defaultErrorMessage = intl.getI18nMessage(
        'label.custom.pages.save.fail',
      );
      const errorMessage = processErrors(errors, defaultErrorMessage);
      setNotificationStatus('error');
      setNotificationMessage(errorMessage);
      fetchCustomPagesBundles();
    }
    setTimeout(() => {
      setNotificationStatus('');
      setNotificationMessage('');
      resetUpdateCustomPagesStatus();
    }, 3000);
  }, [saveCustomPagesStatus]);

  useEffect(() => {
    fetchCustomPagesListInBundle(selectedBundle);
  }, [selectedBundle]);

  useEffect(() => {
    // If selectedBundle is the publishedBundle set the FormSelects with correct value
    if (publishedBundle && selectedBundle === publishedBundle.uuid) {
      // Loop on customPages and find the one that matches the page in bundlePagesList
      customPages.forEach((customPage) => {
        const page = bundlePagesList.find(
          (page) => page.name === customPage.name,
        );
        if (page) {
          switch (customPage.pageType) {
            case PageTypes.HOME:
              setHomeCustomPage(page.name);
              break;
            case PageTypes.LOGIN:
              setLoginCustomPage(page.name);
              break;
            case PageTypes.REGISTRATION:
              setSignUpCustomPage(page.name);
              break;
            case PageTypes.ACCOUNT_SETUP:
              setAccountSetupCustomPage(page.name);
              break;
            case PageTypes.REQUEST_RESET_PASSWORD:
              setResetPasswordCustomPage(page.name);
              break;
            case PageTypes.RESET_PASSWORD:
              setSetNewPasswordCustomPage(page.name);
              break;
            default:
              break;
          }
        }
      });
    } else {
      setHomeCustomPage(null);
      setLoginCustomPage(null);
      setSignUpCustomPage(null);
      setAccountSetupCustomPage(null);
      setResetPasswordCustomPage(null);
      setSetNewPasswordCustomPage(null);
    }
  }, [bundlePagesList]);

  // Set the selectedBundle to the one published
  useEffect(() => {
    publishedBundle && setSelectedBundle(publishedBundle.uuid);
  }, [publishedBundle]);

  return (
    <div
      id="custom-pages-assign-pages-container"
      data-apim-test="custom-pages-assign-pages-container"
      className={classes.formContainer}
    >
      <LoadingDialog
        isOpen={showLoadingDialog}
        title={intl.getI18nMessage('label.custom.pages.publishing')}
        dialogId="publishing-custom-pages-dialog"
      />
      <Typography variant="h3" className={classes.pageTitle}>
        {intl.getI18nMessage('label.custom.pages.assign.pages.title')}
      </Typography>
      <div className={classes.formGroup}>
        <FormSelect
          id="custom-pages-bundle-select"
          name={intl.getI18nMessage('label.custom.pages.select.bundle')}
          data={bundles}
          value={selectedBundle}
          handleChange={(e) => setSelectedBundle(e.target.value)}
          defaultText={intl.getI18nMessage(
            'label.custom.pages.text.portal.default',
          )}
          helperText={intl.getI18nMessage(
            'label.custom.pages.select.bundle.helper',
          )}
        />
      </div>

      <hr />

      <Typography variant="h4" className={classes.pageTitle}>
        {intl.getI18nMessage('label.custom.pages.assigned.pages.section.title')}
      </Typography>
      <p className={classes.pageDescription}>
        {intl.getI18nMessage('label.custom.pages.assigned.pages.section.desc')}
      </p>
      <div className={classes.formGroup}>
        <FormSelect
          id="custom-pages-home-select"
          name={intl.getI18nMessage('label.custom.pages.select.home')}
          data={bundlePagesList}
          value={homeCustomPage}
          handleChange={(e) =>
            e.target.value === '0'
              ? setHomeCustomPage(null)
              : setHomeCustomPage(e.target.value)
          }
          defaultText={intl.getI18nMessage(
            'label.custom.pages.text.portal.default',
          )}
        />
      </div>
      <div className={classes.formGroup}>
        <FormSelect
          id="custom-pages-login-select"
          name={intl.getI18nMessage('label.custom.pages.select.login')}
          data={bundlePagesList}
          value={loginCustomPage}
          handleChange={(e) =>
            e.target.value === '0'
              ? setLoginCustomPage(null)
              : setLoginCustomPage(e.target.value)
          }
          defaultText={intl.getI18nMessage(
            'label.custom.pages.text.portal.default',
          )}
          helperText={intl.getI18nMessage(
            'label.custom.pages.select.login.helper',
          )}
        />
      </div>
      <div className={classes.formGroup}>
        <FormSelect
          id="custom-pages-signup-select"
          name={intl.getI18nMessage('label.custom.pages.select.sign.up')}
          data={bundlePagesList}
          value={signUpCustomPage}
          handleChange={(e) =>
            e.target.value === '0'
              ? setSignUpCustomPage(null)
              : setSignUpCustomPage(e.target.value)
          }
          defaultText={intl.getI18nMessage(
            'label.custom.pages.text.portal.default',
          )}
        />
      </div>
      <div className={classes.formGroup}>
        <FormSelect
          id="custom-pages-account-setup-select"
          name={intl.getI18nMessage('label.custom.pages.select.account.setup')}
          data={bundlePagesList}
          value={accountSetupCustomPage}
          handleChange={(e) =>
            e.target.value === '0'
              ? setAccountSetupCustomPage(null)
              : setAccountSetupCustomPage(e.target.value)
          }
          defaultText={intl.getI18nMessage(
            'label.custom.pages.text.portal.default',
          )}
        />
      </div>
      <div className={classes.formGroup}>
        <FormSelect
          id="custom-pages-reset-password-select"
          name={intl.getI18nMessage('label.custom.pages.select.reset.password')}
          data={bundlePagesList}
          value={resetPasswordCustomPage}
          handleChange={(e) =>
            e.target.value === '0'
              ? setResetPasswordCustomPage(null)
              : setResetPasswordCustomPage(e.target.value)
          }
          defaultText={intl.getI18nMessage(
            'label.custom.pages.text.portal.default',
          )}
        />
      </div>
      <div className={classes.formGroup}>
        <FormSelect
          id="custom-pages-set-new-password-select"
          name={intl.getI18nMessage(
            'label.custom.pages.select.set.new.password',
          )}
          data={bundlePagesList}
          value={setNewPasswordCustomPage}
          handleChange={(e) =>
            e.target.value === '0'
              ? setSetNewPasswordCustomPage(null)
              : setSetNewPasswordCustomPage(e.target.value)
          }
          defaultText={intl.getI18nMessage(
            'label.custom.pages.text.portal.default',
          )}
        />
      </div>
      <FormActionButtons
        onNextClick={handleAssignPages}
        nextText={intl.getI18nMessage('label.custom.pages.assign.pages.button')}
        onCancelClick={() => history.go(0)}
        id="tag-form-buttons"
      />
    </div>
  );
};

AssignPagesRaw.propTypes = {
  bundles: PropTypes.arrayOf(PropTypes.object),
  publishedBundle: PropTypes.object,
  customPages: PropTypes.arrayOf(PropTypes.object),
  bundlePagesList: PropTypes.arrayOf(PropTypes.object),
  saveCustomPagesStatus: PropTypes.string,
  fetchCustomPages: PropTypes.func,
  fetchCustomPagesBundles: PropTypes.func,
  fetchCustomPagesListInBundle: PropTypes.func,
  updateCustomPages: PropTypes.func,
  resetUpdateCustomPagesStatus: PropTypes.func,
  setNotificationStatus: PropTypes.func,
  setNotificationMessage: PropTypes.func,
  errors: PropTypes.array,
};

AssignPagesRaw.displayName = 'Actions';

export default AssignPagesRaw;
