import React, { Fragment } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import {
  Collapse, Divider, List, ListItem, ListItemText, Typography, makeStyles, IconButton,
} from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

import styles from './styles';

const ExpandedIcon = ({ expanded }) => (
  expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />
);

ExpandedIcon.propTypes = {
  expanded: bool,
};

const MultiValueCell = ({
  items = [], expanded = false, uniqueKey, text, entity,
}) => {
  const isGreaterThanOne = (items.length > 1);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const getListItem = (item, index) => (
    <ListItem
      divider={isGreaterThanOne && (index < items.length - 1)}
      dense disableGutters
      key={uniqueKey(item)}
    >
      <ListItemText
        primary={text && text(item)}
      />
    </ListItem>
  );
  return (
    <div className={classes.multiValueCellContainer}>
      {isGreaterThanOne ?
        <Fragment>
          <IconButton
            className={classes.multiValueCellHeading}
            id="multivalue-cell" data-layer7-test="multivalue-cell" aria-label="multivalue cell"
          >
            <Typography variant="body1">{items.length} {entity}</Typography>
            <ExpandedIcon expanded={expanded} />
          </IconButton>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Divider />
            <List disablePadding>
              {items.map(getListItem)}
            </List>
          </Collapse>
        </Fragment> :
        <List disablePadding>
          {items.map(getListItem)}
        </List>
      }
    </div>
  );
};

MultiValueCell.propTypes = {
  items: arrayOf(shape({})),
  expanded: bool,
  uniqueKey: func,
  text: func,
  entity: string,
};

export default MultiValueCell;
