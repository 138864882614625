import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createRootReducer from '../reducers';


export const history = createBrowserHistory();

const loggerMiddleware = createLogger({
  level: 'info',
  collapsed: true,
});

const isDevelopmentMode = process.env.NODE_ENV !== 'production';

export default function configureStore(initialState, enableLogger = true) {
  // initialState = JSON.parse(window.localStorage.getItem('state')) || initialState;
  const headerState = window.localStorage
    && window.localStorage.getItem('publish')
    && JSON.parse(window.localStorage.getItem('publish'));

  const store = createStore(
    createRootReducer(history),
    initialState,
    compose(
      applyMiddleware(
        thunkMiddleware,
        routerMiddleware(history),
        ...(isDevelopmentMode && enableLogger ? [
          loggerMiddleware,
        ] : []),
      ),
      ...(isDevelopmentMode && window.devToolsExtension ? [
        window.devToolsExtension(),
      ] : []),
    ),
  );

  store.subscribe(() => {
    const persist = {
      services: headerState && headerState.services,
      headerServices: headerState && headerState.headerServices,
      headerSettings: headerState && headerState.headerSettings,
      navigation: headerState && headerState.navigation,
      headerNavigation: headerState && headerState.headerNavigation,
    };

    if (window.localStorage) { window.localStorage.setItem('state', JSON.stringify(persist)); }
  });
  return store;
}
