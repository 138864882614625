import React from 'react';
import { useIntl } from 'react-intl';
import { string, number, shape, func, arrayOf, bool, object } from 'prop-types';
import {
  IconButton,
  TablePagination as MuiTablePagination,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import MuiPagination from '@material-ui/lab/Pagination';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';

import styles from './styles';
import { getI18nFormattedMessage, getI18n } from '../../../utils/intl';

export function Pagination({
  classes = {}, page, pageCount, onChangePage, onChangePreviousPage, onChangeNextPage,
}) {
  return (
    <div className={classes.tablePagination}>
      <IconButton
        className={classes.tablePaginationItem}
        id="previous-page" data-layer7-test="previous-page"
        onClick={onChangePreviousPage}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft /> {getI18nFormattedMessage('label.pagination.prev')}
      </IconButton>
      <MuiPagination
        className={classes.tablePaginationItem}
        hideNextButton
        hidePrevButton
        count={pageCount}
        page={page + 1}
        onChange={onChangePage}
        shape="rounded"
      />
      <IconButton
        className={classes.tablePaginationItem}
        id="next-page" data-layer7-test="next-page"
        onClick={onChangeNextPage}
        disabled={page >= (pageCount - 1)}
        aria-label="next page"
      >
        {getI18nFormattedMessage('label.pagination.next')} <KeyboardArrowRight />
      </IconButton>
    </div>
  );
}

Pagination.propTypes = {
  classes: shape({}),
  page: number,
  pageCount: number,
  onChangePage: func,
  onChangePreviousPage: func,
  onChangeNextPage: func,
};

export const MuiTablePaginationContent = (props) => {
  const intl = getI18n(useIntl());
  const {
    classes,
    count,
    rowsPerPageOptions = [10, 20],
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    labelRowsPerPage = intl.getI18nMessage('label.pagination.rows.per.page'),
    pageCount,
    onChangePreviousPage,
    onChangeNextPage,
    hasPaginationDisabled,
  } = props;
 
  const containerClass = hasPaginationDisabled ?
    clsx(classes.tablePaginationContainer, classes.disabled) : classes.tablePaginationContainer

  return(
    <MuiTablePagination
      classes={{
        root: containerClass,
        selectRoot: classes.selectRoot,
        select: classes.select,
        selectIcon: classes.selectIcon,
      }}
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      page={page}
      count={count}
      rowsPerPage={rowsPerPage}
      onChangePage={(e, newPage) => { onChangePage(newPage - 1); }}
      onChangeRowsPerPage={e => onChangeRowsPerPage(e.target.value)}
      labelRowsPerPage={labelRowsPerPage}
      ActionsComponent={() => (
        <Pagination
          classes={classes}
          page={page}
          onChangePage={(e, newPage) => { onChangePage(newPage - 1); }}
          pageCount={pageCount}
          onChangePreviousPage={onChangePreviousPage}
          onChangeNextPage={onChangeNextPage}
        />
      )}
    />
  )
};

MuiTablePaginationContent.propTypes = {
  classes: object,
  page: number,
  count: number,
  pageCount: number,
  rowsPerPage: number,
  onChangeRowsPerPage: func,
  onChangePage: func,
  onChangePreviousPage: func,
  onChangeNextPage: func,
  rowsPerPageOptions: arrayOf(number),
  labelRowsPerPage: string,
  hasPaginationDisabled: bool,
};

export function TablePagination(props) {
  const {
    id, hasPaginationDisabled = false, paginationTooltipMessage = '',
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  return (
    <div id={id} data-apim-test={id} data-layer7-test={id}>
      {hasPaginationDisabled ?
        <Tooltip title={paginationTooltipMessage}>
          <div>
            <MuiTablePaginationContent {...props} classes={classes} />
          </div>
        </Tooltip>
      :
        <MuiTablePaginationContent {...props} classes={classes} />
      }
    </div>
  );
}

TablePagination.propTypes = {
  id: string,
  hasPaginationDisabled: bool,
  paginationTooltipMessage: string,
};
