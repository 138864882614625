import { useState } from 'react';
import { get } from 'lodash';
import { createCookie } from '../utils/cookie';

export const usePrefs = (userPrefs) => {
    const [prefs, setPrefs] = useState(userPrefs);
    const getUserPref = (username, tenantId, key) => {
        return get(prefs, `uiPrefs.${tenantId}.${username}.${key}.value`);
    };
    const setUserPref = (username, tenantId, key, value) => {
        const uiPrefs = get(prefs, 'uiPrefs', {});
        const tenantPrefs = get(prefs, `uiPrefs.${tenantId}`, {});
        const userPrefs = get(prefs, `uiPrefs.${tenantId}.${username}`, {});
        const updatedUIPrefs = {
            ...uiPrefs,
            [tenantId]: {
                ...tenantPrefs,
                [username]:{
                    ...userPrefs,
                    [key]: { value },
                },
            },
        }
        const updatedPrefs = { uiPrefs: updatedUIPrefs };
        setPrefs(updatedPrefs);
        createCookie('userPrefs', JSON.stringify(updatedPrefs), 365, location.pathname);
    };
    return [getUserPref, setUserPref];
  };
export default usePrefs;
