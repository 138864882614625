import { get } from 'lodash';

const styles = ({ margin, padding, color, lineHeight }) => ({
  statusIcon: {
    height: 15,
    marginRight: 3,
    width: 15,
  },
  listContent: {
    margin: get(margin, 'm0'),
  },
  pageBodyClass: {
    paddingTop: get(padding, 'p0'),
  },
  pageFilterAndSortClass: {
    marginTop: get(margin, 'm24'),
  },
  fieldContainer: {
    margin: `${get(margin, 'm0')} !important`,
    marginRight: '5px !important',
  },
  tagsContainer: {
    marginTop: `${get(margin, 'm31')} !important`,
    marginRight: `${get(margin, 'm8')} !important`,
    flexBasis: '33%',
  },
  selectField: {
    padding: '2px 10px !important',
  },
  helpIcon: {
    color: get(color, 'primaryButtonBackground'),
    marginLeft: 6,
    marginTop: 3,
    height: 16,
    width: 16,
  },
  helpIconContainer: {
    display: 'flex',
  },
  sortContainerClass: {
    padding: '10px 0',
    width: '100%',
    '& button': {
      lineHeight: get(lineHeight, 'lh20'),
    },
    '& .MuiButton-outlinedPrimary:hover': {
      backgroundColor: `${get(color, 'primaryButtonBackground')} !important`,
      color: get(color, 'primaryButtonText'),
    },
  },
  activeButton: {
    textTransform: 'none',
    color: get(color, 'primaryButtonText'),
    backgroundColor: get(color, 'primaryButtonBackground'),
    borderColor: get(color, 'primaryButtonBackground'),
    lineHeight: get(lineHeight, 'lh20'),
  },
  count: {
    textAlign: 'center',
  },
});

export default styles;
