import colorMod from 'color';
import { get } from 'lodash';

export default ({ color, fontSize, misc, typography }) => ({
  HeaderSettingsButton: {
    display: 'inline-block',
    marginRight: 8,
    '& button': {
      padding: 8,
      color: get(color, 'headerLink'),
      border: '1px solid',
      borderRadius: 3,
      borderColor: 'rgba(0, 0, 0, 0)',
      '&:hover': {
        color: colorMod(get(color, 'headerLink')).alpha(0.7).string(),
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      '&:focus': {
        borderColor: colorMod(get(color, 'headerLink')).alpha(0.7).string(),
      },
      '&:active': {
        borderColor: get(color, 'headerLink'),
      },
    },
    '.skipPrint &': {
      '@media print': {
        display: 'none',
      },
    },
  },

  SettingsIcon: {
    height: 24,
    width: 24,
  },

  Popper: {
    marginTop: 4,
  },

  MenuPaper: {
    borderRadius: 3,
    boxShadow: 'none',
    filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))',
  },

  Menu: {
    margin: '0 0 0 0 !important',
    maxWidth: 225,
    minWidth: 180,
    padding: '4px 0 12px 0 !important',
  },
  HeaderSettingsMenuItem: {
    borderLeft: '3px solid !important',
    borderColor: 'rgba(0, 0, 0, 0) !important',
    color: `${get(color, 'bodyText')} !important`,
    display: 'block',
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
    lineHeight: '36px !important',
    padding: '0 12px 0 12px !important',
    textDecoration: 'none',
    transition: get(misc, 'colorTransition'),
    '&:hover': {
      backgroundColor: `${colorMod(get(color, 'headerLink')).alpha(0.08).string()} !important`,
    },
    '&:focus': {
      backgroundColor: `${colorMod(get(color, 'headerLink')).alpha(0.08).string()} !important`,
      borderColor: `${get(color, 'headerLink')} !important`,
      outline: 'none !important',
    },
  },
});
