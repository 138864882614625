import { get } from 'lodash';
import * as constants from '../../constants';

const initialState = {
  isLoading: false,
  isError: false,
  suggestions: [],
  entities: [],
  chartData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.DASHBOARD_AUTOSUGGEST_SHOW_SUCCESS:
      return {
        ...state,
        suggestions:
          action.payload &&
          action.payload.suggestions &&
          action.payload.suggestions.length
            ? action.payload.suggestions
            : [],
        isError: false,
      };
    case constants.DASHBOARD_GET_ENTITY_COUNT_SUCCESS:
      return {
        ...state,
        entities: action.payload,
      };
    case constants.DASHBOARD_CHART_DATA_SUCCESS:
      return {
        ...state,
        chartData:
          { ...state.chartData, [action.entity]: action.payload },
      };
    default:
      return state;
  }
}

export const getAutoSuggest = state => get(state, 'dashboard.suggestions');
export const getDashboardEntityCount = state => get(state, 'dashboard.entities');
export const getDashboardChartData = (state) => get(state, 'dashboard.chartData');
