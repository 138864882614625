import { get } from 'lodash';

const styles = ({ color, fontSize, typography, padding, margin }) => ({
  content: {
    backgroundColor: get(color, 'uiBackground'),
    margin: '0 36px',
    minHeight: 'calc(100vh - 125px)',
    marginBottom: '170px',
  },
  gridContainer: {
    minHeight: 'calc(100vh - 125px)',
    backgroundColor: get(color, 'uiBackground'),
    marginTop: get(margin, 'm0'),
    marginBottom: get(margin, 'm60'),
  },
  pageHeaderTitle: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    fontWeight: 'bold',
    marginBottom: get(margin, 'm0'),
    marginTop: get(margin, 'm10'),
    wordWrap: 'break-word',
  },
  leftSidebar: {
    backgroundColor: get(color, 'uiBackground'),
    paddingTop: get(padding, 'p50'),
  },
  mainContent: {
    backgroundColor: get(color, 'uiBackground'),
    padding: get(padding, 'p20'),
    paddingTop: get(padding, 'p50'),
    borderLeft: `8px solid ${get(color, 'background')}`,
  },
  rightSidebar: {
    backgroundColor: get(color, 'uiBackground'),
    padding: get(padding, 'p10'),
    paddingTop: get(padding, 'p50'),
    borderLeft: `8px solid ${get(color, 'background')}`,
  },
});

export default styles;
