import React from 'react';
import get from 'lodash/get';

import { DocumentCreateForm } from './DocumentCreateForm';
import {
    getMaxOrdinalFromDocuments,
    getSiblingsDocuments,
} from '../../../../components/TreeViewNav/tree';
import { slugifyURI } from '../../../../utils';

const DocumentCreate = props => {
    return <DocumentCreateForm {...props} />;
};

export const createNewDocument = (
    title,
    parentDocument,
    items,
    entityType,
    entityUuid,
    locale,
) => {
    const navtitle = slugifyURI(title);
    const parentUuid = get(parentDocument, 'uuid', undefined);
    const siblingsItems = getSiblingsDocuments(items, parentUuid);
    const ordinal = getMaxOrdinalFromDocuments(siblingsItems) + 1;

    return {
        id: 'new-document',
        type: get(parentDocument, 'type', entityType),
        typeUuid: get(parentDocument, 'typeUuid', entityUuid),
        status: 'PUBLISHED',
        ordinal,
        locale,
        ...(parentUuid !== null && { parentUuid }),
        title,
        navtitle,
        markdown: '',
    };
};

export default DocumentCreate;
