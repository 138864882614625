import React from 'react';
import { makeStyles } from '@material-ui/core';
import styles from './styles';

import {
  Footer,
} from '../../../components';

import {
  GUEST_USER_TITLE,
  GUEST_USER_MESSAGE,
} from '../labels';

const GuestDashboard = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div>
      <div
        className={classes.dashboard}
        id="dashboard-main"
        data-apim-test="guest-dashboard-main"
      >
        <div className={classes.title}>
          {GUEST_USER_TITLE}
        </div>
        <div className={classes.subTitle}>
          {GUEST_USER_MESSAGE}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GuestDashboard;
