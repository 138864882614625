// @flow

import React from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import PropTypes from 'prop-types';
import colorMod from 'color';
import theme from './styles';
import {
  APPLICATION,
  API,
  ORGANIZATION,
} from '../labels';

// workaround till we update react-scripts > 2.0.0, which supports adding svg images
const expandSVG = <path d="M12.023 16.9557L12.0229 16.9556L11.977 17L4.86808 10.1204C4.37731 9.64543 4.37731 8.87541 4.86808 8.40047C5.35884 7.92553 6.15453 7.92553 6.6453 8.40047L11.9771 13.5603L17.3547 8.3562C17.8455 7.88127 18.6412 7.88127 19.1319 8.3562C19.6227 8.83114 19.6227 9.60117 19.1319 10.0761L12.023 16.9557Z" />;

export default function HeaderDropdown(props) {
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleChange = (event) => {
    props.handleDropdownUpdate(event.target.value);
  };
  const appTheme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const muiTheme = createMuiTheme({
    overrides: {
      MuiSelect: {
        icon: {
          color: appTheme.color.primaryButtonBackground,
        },
      },
      MuiInput: {
        root: {
          border: `1px solid ${colorMod(appTheme.color.primaryButtonBackground).alpha(0.4).string()}`,
          borderRadius: '3px 0px 0px 3px',
          zIndex: 5,
          '&:hover': {
            border: `1px solid ${appTheme.color.primaryButtonHover}`,
            zIndex: 10,
          },
          '&$focused': {
            border: `1px solid ${appTheme.color.primaryButtonBackground}`,
            boxShadow: `0 0 0 1px ${appTheme.color.primaryButtonBackground}`,
          },
        },
        focused: {

        },
      },
      MuiPaper: {
        root: {
          marginTop: 10,
          marginLeft: -8,
          width: 192,
          boxShadow: '0px 4px 8px #00000033 !important',
        },
        rounded: {
          borderRadius: '3px 3px 0px 0px',
        },
      },
      MuiList: {
        padding: {
          paddingTop: '8px !important',
          paddingBottom: '12px !important',
        },
      },
      MuiListItem: {
        root: {
          '&$selected': {
            backgroundColor: '#fff',
          },
          '&$selected:hover': {
            backgroundColor: `${colorMod(appTheme.color.primaryButtonBackground).alpha(0.08).string()} !important`,
          },
          '&:hover': {
            backgroundColor: `${colorMod(appTheme.color.primaryButtonBackground).alpha(0.08).string()} !important`,
          },
        },
      },
    },
  });
  const ExpandIcon = createSvgIcon(expandSVG, 'expand');

  return (
    <div
      className={classes.dropdown}
      id="dashboard-header-dropdown"
      data-apim-test="dashboard-header-dropdown"
    >
      <ThemeProvider theme={muiTheme}>
        <Select
          className={classes.selectDropdown}
          classes={{
            select: classes.selectDropdownSelect,
          }}
          disableUnderline
          IconComponent={ExpandIcon}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={props.selectedDropdownValue}
          onChange={handleChange}
          MenuProps={{
            disableScrollLock: true,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          tabIndex={-1}
        >
          <MenuItem className={classes.menuItems} value="API">{API}</MenuItem>
          <MenuItem className={classes.menuItems} value="Application">{APPLICATION}</MenuItem>
          {props.isOnlyPortalAdmin &&
          <MenuItem className={classes.menuItems} value="Organization">{ORGANIZATION}</MenuItem>
          }
        </Select>
      </ThemeProvider>
    </div>
  );
}

HeaderDropdown.propTypes = {
  selectedDropdownValue: PropTypes.string,
  isOnlyPortalAdmin: PropTypes.bool,
  handleDropdownUpdate: PropTypes.func,
};
