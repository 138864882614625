import axios from 'axios';
import get from 'lodash/get';

import * as Constants from '../../constants';
import { getValidationErrors } from '../../utils';
import { getConfig, getFeatureFlags } from '../../reducers/portalConfig';
import { stringifyUrl } from '../../utils/actions';
import { AXIOS_DEFAULT_OPTIONS } from '../';

export const checkSession = async (state) => {
  const config = getConfig(state);
  const { portal } = config;
  return axios.get(`${portal.hostname}/admin/sessionCheck`, { credentials: 'include' })
    .then(res => get(res, 'data.status', ''))
    .catch(err => get(err, 'response.data.status', ''));
};

export const updateUserContexts = (data) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/userContexts`;

    axios.put(url, data, AXIOS_DEFAULT_OPTIONS)
      .then(() => {
        dispatch({
          type: Constants.USER_CONTEXTS_UPDATE_SUCCESS,
          isLoading: false,
        });
        window.location.href = '/admin/app/dashboard';
      })
      .catch((error) => {
        dispatch({
          type: Constants.API_ERROR,
          isLoading: false,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchRequests = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/Requests?$filter=RequestStatus eq 'PENDING'`;

    axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.FETCH_REQUESTS_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.FETCH_REQUESTS_ERROR,
          isLoading: false,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchAppSecretHashingMetadata = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/Settings('APP_SECRET_HASHING_METADATA')`;

    axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.FETCH_APP_SECRET_HASHING_METADATA_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.FETCH_APP_SECRET_HASHING_METADATA_ERROR,
          isLoading: false,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchDeploymentRefreshTime = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/Settings('DEPLOYMENT_REFRESH_COUNTDOWN_IN_SECONDS')`;

    axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.FETCH_DEPLOYMENT_REFRESH_COUNTDOWN_IN_SECONDS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.FETCH_DEPLOYMENT_REFRESH_COUNTDOWN_IN_SECONDS_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchApplicationRequestSetting = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/Settings('APPLICATION_REQUEST_WORKFLOW')`;
    axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.FETCH_APPLICATION_REQUEST_SETTING_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() => {});
  };

export const fetchApplicationEditRequestSetting = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/Settings('EDIT_APPLICATION_REQUEST_WORKFLOW')`;
    axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.FETCH_APPLICATION_EDIT_REQUEST_SETTING_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() => {});
  };

export const fetchApplicationDeleteRequestSetting = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/Settings('DELETE_APPLICATION_REQUEST_WORKFLOW')`;
    axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.FETCH_APPLICATION_DELETE_REQUEST_SETTING_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() => {});
  };

export const fetchLockUserCreation = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/Settings('LOCK_USER_CREATION')`;
    axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.FETCH_SETTING_USER_LOCK_CREATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() => {});
  };

  export const fetchProxyStatus = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const featureFlags = getFeatureFlags(getState());
    const featureFlagL7Manager = JSON.parse(get(featureFlags, 'l7Manager.Value', false));
    const requests = [
      axios.get(
        `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/proxies`,
        AXIOS_DEFAULT_OPTIONS,
      ),
      axios.get(
        stringifyUrl({
          url: `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/status`,
          query: {
            status: [Constants.DEPLOYMENT_STATUS_ERROR, Constants.DEPLOYMENT_STATUS_ERROR_UNDEPLOY],
          },
        }),
        AXIOS_DEFAULT_OPTIONS,
      ),
    ];
    if (featureFlagL7Manager) {
      requests.push(axios.get(
        `${portal.hostname}/api/${portal.tenantPrefix}/gateway-management/0.1/status/trusted-certs`,
        AXIOS_DEFAULT_OPTIONS,
      ));
    }
    await Promise.all(requests)
    .then((response = []) => {
      dispatch({
        type: Constants.HEADER_PROXY_STATUS_GET_SUCCESS,
        payload: {
          connectionStatus: response[0].data,
          deploymentStatus: response[1].data,
          certificatesStatus: featureFlagL7Manager ? response[2].data : [],
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.HEADER_PROXY_STATUS_GET_ERROR,
        payload: getValidationErrors(error),
      });
    });
  };
