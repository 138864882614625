import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  withStyles,
} from '@material-ui/core';

import {
  FormActionButtons,
  TabsContainer,
  TabPanel,
} from '../../../components';
import Details from './Details';
import OrganizationAssignment from './OrganizationAssignment';
import {
    saveProxy,
    updateProxyOrganizations,
    fetchProxyDetails,
    fetchProxyDeploymentTypes,
    fetchAllOrganizations,
    fetchSelectedOrganizations,
    reinitializeState,
} from '../../../actions/apiProxy';
import {
    getProxyUuid,
    getProxyName,
    getProxyNameUniqueStatus,
    getApiProxyType,
    getApiKeyProxyType,
    getProxyDetails,
    getOrganizations,
    getSelectedOrganizations,
    getSaveProxyStatus,
    getUpdateKeyDeploymentTypeStatus,
    getUpdateProxyOrgsStatus,
    getIsLoading,
} from '../../../reducers/apiProxy';
import { getConfig } from '../../../reducers/portalConfig';
import {
  SUCCESS, FAIL,
  CREATE_SUCCESS, CREATE_FAIL,
  UPDATE_SUCCESS, UPDATE_FAIL,
  ALERT_SUCCESS, ALERT_ERROR,
} from '../../../constants';
import {
  UNIQUE_PROXY_NAME_ERROR_MSG,
  EMPTY_PROXY_NAME_ERROR_MSG,
  MAXLENGTH_PROXY_NAME_ERROR_MSG,
  PROXY_CREATE_SUCCESS_MSG,
  PROXY_CREATE_FAIL_MSG,
  PROXY_UPDATE_SUCCESS_MSG,
  PROXY_UPDATE_FAIL_MSG,
  PROXY_ORGS_UPDATE_FAIL_MSG,
  ADD_PROXY_LABEL,
  EDIT_PROXY_LABEL,
  HELP_ITEMS,
  UNSAVED_DIALOG_SUBMIT_TEXT,
} from './labels';
import Tabs from './tabs';
import EditContainer from '../../edit';
import { getI18n } from '../../../utils/intl';
import styles from './styles';

const getHelpItems = (screen) => HELP_ITEMS[screen].details;

const getTabs = (proxyUuid) => Tabs
  .filter(({ isHidden }) => !(isHidden && isHidden(proxyUuid)))
  .map(tab => ({
    ...tab,
    disabled: tab.isDisabled && tab.isDisabled(proxyUuid),
  }));

const isEditJourney = (props) => get(props, 'match.path')
  && get(props, 'match.path').includes('/edit/')
  && get(props, 'match.params.apiProxyUuid');

export const ApiProxy = (props) => {
  const {
    config,
    proxyName,
    proxyNameUniqueStatus,
    apiProxyType,
    apiKeyProxyType,
    proxyDetails,
    organizations,
    selectedOrganizations,
    saveProxyStatus,
    updateKeyDeploymentTypeStatus,
    updateProxyOrgsStatus,
    isLoading,
    history,
  } = props;

  // Edit Journey
  let { proxyUuid } = props;
  if (isEditJourney(props)) {
    proxyUuid = get(props, 'match.params.apiProxyUuid');
  }

  const tabs = getTabs(proxyUuid);
  const intl = getI18n(useIntl());
  const [currentTab, setCurrentTab] = useState(tabs[0].tabId);
  const [pageErrors, setPageErrors] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [currentProxyName, setCurrentProxyName] = useState('');
  const [currentApiProxyType, setCurrentApiProxyType] = useState('');
  const [currentApiKeyProxyType, setCurrentApiKeyProxyType] = useState('');
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const headerRef = useRef(null);

  useEffect(() => { setCurrentProxyName(proxyName); }, [proxyName]);
  useEffect(() => { setCurrentApiProxyType(apiProxyType); }, [apiProxyType]);
  useEffect(() => { setCurrentApiKeyProxyType(apiKeyProxyType); }, [apiKeyProxyType]);
  useEffect(() => { setSelectedOrgs(selectedOrganizations); }, [selectedOrganizations]);
  useEffect(() => {
    if (proxyNameUniqueStatus === FAIL) {
      setPageErrors([{
        key: 'proxyName',
        type: 'unique',
        message: UNIQUE_PROXY_NAME_ERROR_MSG,
      }]);
    } else {
      setPageErrors([]);
    }
  }, [proxyNameUniqueStatus]);

  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    if (currentTab !== newValue) {
      setCurrentTab(newValue);
      setNotificationMessage('');
      setNotificationStatus('');
    }
    window.scrollTo(0, headerRef.offsetTop);
  };

  const validateForm = () => {
    let errors = [];
    switch (currentTab) {
      case 'details-tab': {
        if (currentProxyName === '') {
          errors = errors.concat([{
            key: 'proxyName',
            type: 'empty',
            message: EMPTY_PROXY_NAME_ERROR_MSG,
          }]);
        } else if (currentProxyName && currentProxyName.length > 255) {
          errors = errors.concat([{
            key: 'proxyName',
            type: 'maxlength',
            message: MAXLENGTH_PROXY_NAME_ERROR_MSG,
          }]);
        }
        setPageErrors(errors);
        break;
      }
      case 'organization-assignment-tab':
        break;
      default:
        break;
    }
  };

  const onNext = () => {
    validateForm();

    const isValid = pageErrors.length === 0;
    switch (currentTab) {
      case 'details-tab': {
        if (isValid) {
          const data = { name: currentProxyName, deploymentType: currentApiProxyType };
          saveProxy({ config, dispatch, data, proxyUuid, apiKeyProxyType: currentApiKeyProxyType });
        }
        break;
      }
      case 'organization-assignment-tab': {
        if (isValid) {
          const data = selectedOrgs.map(selectedOrg => ({ uuid: selectedOrg.uuid }));
          updateProxyOrganizations({ config, dispatch, proxyUuid, data });
        }
        break;
      }
      default:
        break;
    }
  };

  const onCancel = () => {
    setShowAlert(true);
  };

  const onDialogClose = () => {
    setShowAlert(false);
  };

  const onDialogSubmit = () => {
    window.location.href = '/admin/api-proxy';
  };

  useEffect(() => {
    if (currentTab === 'details-tab') {
      if (saveProxyStatus === '') {
        if (proxyUuid) {
          fetchProxyDetails({ config, dispatch, proxyUuid });
          fetchProxyDeploymentTypes({ config, dispatch, proxyUuid });
          fetchAllOrganizations({ config, dispatch });
          fetchSelectedOrganizations({ config, dispatch, proxyUuid });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (currentTab === 'details-tab') {
      if (updateKeyDeploymentTypeStatus === SUCCESS &&
          (saveProxyStatus === CREATE_SUCCESS || saveProxyStatus === UPDATE_SUCCESS)) {
        if (proxyUuid) {
          fetchProxyDetails({ config, dispatch, proxyUuid });
          fetchAllOrganizations({ config, dispatch });
          fetchSelectedOrganizations({ config, dispatch, proxyUuid });
          setCurrentTab('organization-assignment-tab');
          window.scrollTo(0, headerRef.offsetTop);
        }
      }
      switch (saveProxyStatus) {
        case CREATE_SUCCESS:
          if (updateKeyDeploymentTypeStatus === SUCCESS) {
            setNotificationMessage(PROXY_CREATE_SUCCESS_MSG);
            setNotificationStatus(ALERT_SUCCESS);
          } else if (updateKeyDeploymentTypeStatus === FAIL) {
            setNotificationMessage(PROXY_CREATE_FAIL_MSG);
            setNotificationStatus(ALERT_ERROR);
          }
          break;
        case CREATE_FAIL:
          setNotificationMessage(PROXY_CREATE_FAIL_MSG);
          setNotificationStatus('error');
          break;
        case UPDATE_SUCCESS:
          if (updateKeyDeploymentTypeStatus === SUCCESS) {
            setNotificationMessage(PROXY_UPDATE_SUCCESS_MSG);
            setNotificationStatus('success');
          } else if (updateKeyDeploymentTypeStatus === FAIL) {
            setNotificationMessage(PROXY_UPDATE_FAIL_MSG);
            setNotificationStatus('error');
          }
          break;
        case UPDATE_FAIL:
          setNotificationMessage(PROXY_UPDATE_FAIL_MSG);
          setNotificationStatus('error');
          break;
        default:
          break;
      }
    }
    if (currentTab === 'organization-assignment-tab') {
      if (updateProxyOrgsStatus === SUCCESS) {
        reinitializeState({ dispatch });
        history.push(`/publish/api-proxy/details/${proxyUuid}`);
      }
      if (updateProxyOrgsStatus === FAIL) {
        setNotificationMessage(PROXY_ORGS_UPDATE_FAIL_MSG);
        setNotificationStatus('error');
      }
    }
  }, [proxyUuid, saveProxyStatus, updateKeyDeploymentTypeStatus, updateProxyOrgsStatus]);

  return (
    <Fragment>
      <EditContainer
        editPageId="api-proxy-page"
        dialogId="api-proxy-dialog"
        isDialogOpen={showAlert}
        onDialogClose={onDialogClose}
        dialogSubmitText={UNSAVED_DIALOG_SUBMIT_TEXT}
        onDialogSubmit={onDialogSubmit}
        isLoading={isLoading}
        leftSidebarId="api-proxy-left-sidebar"
        mainContentId="api-proxy-main-content"
        notificationId="api-proxy-notification"
        notificationStatus={notificationStatus}
        setNotificationStatus={setNotificationMessage}
        notificationMessage={notificationMessage}
        setNotificationMessage={setNotificationMessage}
        tabsContainer={(
          <TabsContainer
            id={'api-proxy-tab-container'}
            useTabId
            tabValue={currentTab}
            tabItems={tabs}
            handleTabChange={handleChange}
          />
        )}
        pageHeaderRef={headerRef}
        pageHeaderTitle={proxyUuid ? `${EDIT_PROXY_LABEL}: ${currentProxyName}` : ADD_PROXY_LABEL}
        pageContent={
          <Fragment>
            <TabPanel
              id="details-tab-panel"
              visible={currentTab === 'details-tab'}
            >
              <Details
                {...props}
                proxyNameUniqueStatus={proxyNameUniqueStatus}
                currentProxyName={currentProxyName}
                setCurrentProxyName={setCurrentProxyName}
                currentApiProxyType={currentApiProxyType}
                setCurrentApiProxyType={setCurrentApiProxyType}
                currentApiKeyProxyType={currentApiKeyProxyType}
                setCurrentApiKeyProxyType={setCurrentApiKeyProxyType}
                proxyDetails={proxyDetails}
                config={config}
                dispatch={dispatch}
                errors={pageErrors}
              />
            </TabPanel>
            <TabPanel
              id="organization-assignment-tab-panel"
              visible={currentTab === 'organization-assignment-tab'}
            >
              <OrganizationAssignment
                {...props}
                orgs={organizations}
                selectedOrgs={selectedOrgs}
                setSelectedOrgs={setSelectedOrgs}
              />
            </TabPanel>
          </Fragment>
        }
        rightSidebarId="api-proxy-right-sidebar"
        helpItems={
          (currentTab === 'details-tab') ? getHelpItems(0) : getHelpItems(1)
        }
      />
      <FormActionButtons
        onNextClick={onNext}
        onCancelClick={onCancel}
        nextText={
          (currentTab === 'details-tab') ?
            intl.getI18nMessage('label.save.and.next.button') :
            intl.getI18nMessage('label.save.button')
        }
        id="api-proxy-form-buttons"
      />
    </Fragment>
  );
};
const mapStateToProps = state => ({
  config: getConfig(state),
  proxyUuid: getProxyUuid(state),
  proxyName: getProxyName(state),
  proxyNameUniqueStatus: getProxyNameUniqueStatus(state),
  apiProxyType: getApiProxyType(state),
  apiKeyProxyType: getApiKeyProxyType(state),
  proxyDetails: getProxyDetails(state),
  organizations: getOrganizations(state),
  selectedOrganizations: getSelectedOrganizations(state),
  saveProxyStatus: getSaveProxyStatus(state),
  updateKeyDeploymentTypeStatus: getUpdateKeyDeploymentTypeStatus(state),
  updateProxyOrgsStatus: getUpdateProxyOrgsStatus(state),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = {
  saveProxy,
  updateProxyOrganizations,
  fetchProxyDetails,
  fetchProxyDeploymentTypes,
  fetchAllOrganizations,
  fetchSelectedOrganizations,
};

ApiProxy.propTypes = {
  config: PropTypes.object,
  proxyUuid: PropTypes.string,
  proxyName: PropTypes.string,
  proxyNameUniqueStatus: PropTypes.string,
  apiProxyType: PropTypes.string,
  apiKeyProxyType: PropTypes.string,
  proxyDetails: PropTypes.object,
  organizations: PropTypes.arrayOf(PropTypes.object),
  selectedOrganizations: PropTypes.arrayOf(PropTypes.object),
  saveProxyStatus: PropTypes.string,
  updateKeyDeploymentTypeStatus: PropTypes.string,
  updateProxyOrgsStatus: PropTypes.string,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ApiProxy);
