import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import permissions from './api';
import apiProxy from './apiProxy';
import application from './application';
import dashboard from './dashboard';
import gatewayBundle from './gatewayBundle';
import organization from './organization';
import portalConfig from './portalConfig';
import proxy from './proxy';
import user from './user';
import eula from './eula';
import userProfile from './userProfile';
import rateQuota from './rateQuota';
import requestSettings from './requestSettings';
import customPages from './customPages';

const reducers = {
  permissions,
  application,
  apiProxy,
  dashboard,
  gatewayBundle,
  organization,
  portalConfig,
  proxy,
  user,
  userProfile,
  eula,
  rateQuota,
  requestSettings,
  customPages,
};
const createRootReducer = (history) =>
  combineReducers(
    history ? { ...reducers, router: connectRouter(history) } : reducers,
  );

export default createRootReducer;
