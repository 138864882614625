import { useIntl } from 'react-intl';
import { sentenceCase } from 'change-case';

import {
  APPLICATION_STATUS_ENABLED, APPLICATION_STATUS_DISABLED, APPLICATION_STATUS_PENDING_APPROVAL,
  APPLICATION_STATUS_EDIT_PENDING_APPROVAL, DEPLOYMENT_STATUS_DEPLOYED,
  DEPLOYMENT_STATUS_PENDING_DEPLOYMENT, DEPLOYMENT_STATUS_PENDING_UNDEPLOYMENT,
  DEPLOYMENT_STATUS_ERROR, DEPLOYMENT_STATUS_ERROR_UNDEPLOY, DEPLOYMENT_STATUS_NOT_DEPLOYED,
  PROXY_DEPLOYMENT_TYPE_AUTOMATIC, PROXY_DEPLOYMENT_TYPE_ON_DEMAND, PROXY_DEPLOYMENT_TYPE_SCRIPTED,
} from '../constants';
import { getI18n } from './intl';

export const getDeployedStatusLabel = (status = 'Not deployed') => sentenceCase(status);
export const getDeployedStatusColor = (status = 'Not deployed') => {
  const deploymentStatus = status.toUpperCase();

  let statusColor = '';
  switch (deploymentStatus) {
    case DEPLOYMENT_STATUS_DEPLOYED:
      statusColor = '#5cb449';
      break;
    case DEPLOYMENT_STATUS_PENDING_DEPLOYMENT:
      statusColor = '#845120';
      break;
    case DEPLOYMENT_STATUS_PENDING_UNDEPLOYMENT:
      statusColor = '#845120';
      break;
    case DEPLOYMENT_STATUS_ERROR:
      statusColor = '#d84332';
      break;
    case DEPLOYMENT_STATUS_ERROR_UNDEPLOY:
      statusColor = '#d84332';
      break;
    case DEPLOYMENT_STATUS_NOT_DEPLOYED:
      statusColor = '#677b87';
      break;
    default:
      statusColor = '#677b87'; // Not deployed API
      break;
  }
  return statusColor;
};

export const getApplicationStatusLabel = (status = '') => sentenceCase(status);

export const getApplicationStatusColor = (status = '') => {
  const greenStates = [APPLICATION_STATUS_ENABLED];
  const grayStates = [APPLICATION_STATUS_DISABLED];
  const orangeStates = [
    APPLICATION_STATUS_PENDING_APPROVAL,
    APPLICATION_STATUS_EDIT_PENDING_APPROVAL,
  ];
  // red for APPLICATION_STATUS_REJECTED (endpoint doesn't return Application with this state)
  let statusColor = '#d84332';
  const applicationStatus = status.toUpperCase();
  if (greenStates.includes(applicationStatus)) {
    statusColor = '#59bb52';
  } else if (orangeStates.includes(applicationStatus)) {
    statusColor = '#f7922f';
  } else if (grayStates.includes(applicationStatus)) {
    statusColor = '#b3bdc2';
  }
  return statusColor;
};

export const getDeploymentTypeLabel = (deploymentType) => {
  const intl = getI18n(useIntl());
  const DEPLOYMENT_TYPE_TO_LABEL = {
    [PROXY_DEPLOYMENT_TYPE_AUTOMATIC]: intl.getI18nMessage('label.proxy.details.page.overview.deployment.type.automatic'),
    [PROXY_DEPLOYMENT_TYPE_ON_DEMAND]: intl.getI18nMessage('label.proxy.details.page.overview.deployment.type.on.demand'),
    [PROXY_DEPLOYMENT_TYPE_SCRIPTED]: intl.getI18nMessage('label.proxy.details.page.overview.deployment.type.scripted'),
  };
  return DEPLOYMENT_TYPE_TO_LABEL[deploymentType];
}
