import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styles from './styles';

export default function FormRadioGroup(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    id,
    name,
    data,
    selected,
    tooltip,
    onChange,
  } = props;

  return (
    <FormControl
      component="fieldset"
      className={classes.formControl}
      id={id}
    >
      {name &&
        <FormLabel
          component="h3"
          className={classes.formLabel}
        >
          <span>{name}</span>
          {tooltip &&
            <Tooltip placement="right" title={tooltip}>
              <HelpIcon className={classes.helpIcon} />
            </Tooltip>
          }
        </FormLabel>
      }
      <RadioGroup
        value={selected}
        onChange={(e) => { onChange(get(e, 'target.value')); }}
        name={name}
      >
        {data.map(option => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={<Radio classes={{ root: classes.radio }} />}
            label={<Typography className={classes.radioLabel}>{option.name}</Typography>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

FormRadioGroup.propTypes = {
  selected: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  tooltip: PropTypes.string,
};
