import { get } from 'lodash';

const styles = ({ color, fontSize, typography, theme }) => ({
  pageTitle: {
    color: get(color, 'pageTitle'),
    fontFamily: get(typography, 'pageTitle'),
    fontSize: get(fontSize, 'pageTitle'),
    marginTop: 6,
  },
  pageHeaderTitle: {
    display: 'none',
  },
  mainContent: {
    paddingTop: 0,
  },
  rightSidebar: {
    paddingTop: 0,
    marginBottom: 10,
  },
  content: {
    marginBottom: 0,
    minHeight: 0,
  },
  gridContainer: {
    marginBottom: 0,
    minHeight: 'calc(100vh - 290px)',
  },
  formLabel: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontWeight: 700,
    paddingLeft: 0,
  },
  typeContainer: {
    display: 'grid',
  },
});

export default styles;
