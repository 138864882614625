import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './styles';
import PanelDropdown from '../PanelDropdown';
import GridPanelContainer from '../GridPanelContainer';

import {
  APIS_TRAFFIC,
  APPLICATIONS_TRAFFIC,
  ORGANIZATIONS_TRAFFIC,
  APIS_PANEL_TITLE,
  PROXIES_TRAFFIC,
  ORGS_PANEL_TITLE,
  APPS_PANEL_TITLE,
  PROXIES_PANEL_TITLE,
} from '../labels';
import ChartPanel from '../ChartPanel';
import { buildQuery } from '../analytics_utils/customMetrics';

export default function ChartContainer(props) {
  const [isLoading, setLoading] = useState(true);
  const [isTop3EntitiesSelected, setTop3Entities] = useState(true);
  const { fetchDashboardChartData, isAnalyticsEnabled } = props;
  const useStyles = makeStyles(styles);
  const panelLabels = {
    api: APIS_PANEL_TITLE,
    org: ORGS_PANEL_TITLE,
    proxy: PROXIES_PANEL_TITLE,
    app: APPS_PANEL_TITLE,
  };
  const entityIdMap = {
    api: 'apiId',
    org: 'orgId',
    proxy: 'gatewayServerId',
    app: 'appId',
  };
  const trafficMap = {
    api: APIS_TRAFFIC,
    org: ORGANIZATIONS_TRAFFIC,
    app: APPLICATIONS_TRAFFIC,
    proxy: PROXIES_TRAFFIC,
  };
  const classes = useStyles();
  const getchartQueryParams = () => ({
    metrics: { type: 'hits', aggregation: 'count' },
    timeRange: { type: 'period', period: '30D', aggregation: 'day' },
    dimensions: { groupBy: [{ dimension: entityIdMap[props.chartEntity], type: 'top', limit: 3 }] },
  });
  const loadChart = () => {
    const query = buildQuery(getchartQueryParams());
    fetchDashboardChartData(query, props.chartEntity).then(() => setLoading(false));
  };
  const toggleTop3Entities = () => {
    setTop3Entities(!isTop3EntitiesSelected);
    loadChart();
  };
  // Constructing date string (from 30 days back to today)
  const dateRange = () =>
    `${moment((new Date()).getTime() - (29 * 24 * 60 * 60 * 1000)).format('ddd MMM DD, YYYY')} - ${moment(new Date()).format('ddd MMM DD, YYYY')}`;

  if (isAnalyticsEnabled) {
    useEffect(() => {
      loadChart();
    }, [fetchDashboardChartData]);
  }

  return (
    <div className={classes.dashboardChartContainer}>
      <div className={classes.chartPanel}>
        <div className={classes.chartHeader}>
          <span className={classes.chartTitle}>
            {isAnalyticsEnabled
              ? trafficMap[props.chartEntity]
              : panelLabels[props.chartEntity]}
          </span>
          <div className={classes.titleRight}>
            {isAnalyticsEnabled && (
              <div className={classes.dateSpan}>{dateRange()}</div>
            )}
            <PanelDropdown
              panelName={props.chartEntity}
              setCollapsed={props.onCollapse}
              isCollapsed={props.panelData.isCollapsed}
              onMovePanel={props.onMovePanel}
              chartEntity={props.chartEntity}
              chartIndex={props.chartIndex}
              chartsCount={props.chartsCount}
              isTop3EntitiesSelected={isTop3EntitiesSelected}
              onToggleTop3Entities={toggleTop3Entities}
              isChartEnabled={props.panelData.isChart}
              onToggleChartEnabled={props.onChartToggle}
              isAnalyticsEnabled={isAnalyticsEnabled}
            />
          </div>
        </div>
        {isAnalyticsEnabled && props.panelData.isChart && !props.panelData.isCollapsed ? (
          <div
            className={
              props.panelData.isCollapsed
                ? classes.fadeInPanel__hide
                : classes.fadeInPanel__show
            }
          >
            <div className={classes.chart}>
              <ChartPanel
                id={`${props.chartEntity}-ChartPanel`}
                metric="hits"
                selectedDataSource={[
                  {
                    dimension: entityIdMap[props.chartEntity],
                    type: 'top',
                    limit: 3,
                  },
                ]}
                chartResp={props.chartData}
                chartEntity={props.chartEntity}
                isTop3EntitiesSelected={isTop3EntitiesSelected}
                isLoading={isLoading}
                isApiOwnerOrOrgPub={props.isApiOwnerOrOrgPub}
                isOrgPublisher={props.isOrgPublisher}
              />
            </div>
          </div>
        ) : (
          <div
            className={
              props.panelData.isCollapsed
                ? classes.fadeInPanel__hide
                : classes.fadeInPanel__show
            }
          >
            <GridPanelContainer
              isApiOwnerOrOrgPub={props.isApiOwnerOrOrgPub}
              chartEntity={props.chartEntity}
              analytics={isAnalyticsEnabled}
            />
          </div>
        )}
      </div>
    </div>
  );
}

ChartContainer.propTypes = {
  isCollapsed: PropTypes.bool,
  chartEntity: PropTypes.string,
  onMovePanel: PropTypes.func,
  onCollapse: PropTypes.func,
  chartIndex: PropTypes.number,
  chartsCount: PropTypes.number,
  isApiOwnerOrOrgPub: PropTypes.bool,
  isOrgPublisher: PropTypes.bool,
  fetchDashboardChartData: PropTypes.func,
  panelData: PropTypes.shape({
    isChart: PropTypes.bool,
    isCollapsed: PropTypes.bool,
  }),
  onChartToggle: PropTypes.func,
  isAnalyticsEnabled: PropTypes.bool,
  chartData: PropTypes.shape({
    query: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
    data: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }),
};
