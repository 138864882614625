import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ExpandPanelContainer } from '../../../../components';
import {
  APPLICATION_API_AVAILABLE_APIS_TITLE,
  APPLICATION_API_SEARCH_AVAILABLE_APIS_PLACEHOLDER,
  APPLICATION_API_AVAILABLE_API_GROUPS_TITLE,
  APPLICATION_API_SEARCH_AVAILABLE_API_GROUPS_PLACEHOLDER,
} from '../labels';
import { hasOrgBoundRole } from '../../../../utils';


export const availableApisTabPanel = ({
    applicationDetails,
    availableApis,
    fetchApiEula,
    setApiUuidToAdd,
    onLoadMoreApisProps,
    SecondaryLabelComponent,
    ExternalFilter,
    onAPISearchTextChange,
}) => (
  <ExpandPanelContainer
    id="application-available-apis"
    defaultList={availableApis}
    hasTagSearch
    SecondaryLabelComponent={SecondaryLabelComponent}
    ExternalFilter={ExternalFilter}
    onSearchTextChange={onAPISearchTextChange}
    emptyListMessage={'No available APIs to be selected.'}
    subtitle={APPLICATION_API_AVAILABLE_APIS_TITLE}
    placeHolderText={APPLICATION_API_SEARCH_AVAILABLE_APIS_PLACEHOLDER}
    isAddIcon
    isApiDetails
    isAdded={({ uuid }) => applicationDetails.apiIds.includes(uuid)}
    onAddItem={
      async ({ uuid, apiEulaUuid }) => {
        await fetchApiEula(apiEulaUuid);
        setApiUuidToAdd(uuid);
      }
    }
    {...onLoadMoreApisProps}
    hasSearch={() => {}}
  />
);

export const availableApiGroupsTabPanel = ({
  userContext = {},
  userActiveOrgUuid,
  applicationDetails,
  availableApiGroups,
  fetchApiGroupEulas,
  setApiGroupUuidToAdd,
  onLoadMoreApiGroupsProps,
  onAPIGroupSearchTextChange,
}) => (
  <Fragment>
    <ExpandPanelContainer
      id="application-available-api-groups"
      defaultList={availableApiGroups}
      onSearchTextChange={onAPIGroupSearchTextChange}
      subtitle={APPLICATION_API_AVAILABLE_API_GROUPS_TITLE}
      placeHolderText={APPLICATION_API_SEARCH_AVAILABLE_API_GROUPS_PLACEHOLDER}
      isAddIcon
      isApiGroup
      emptyListMessage={'No available API Groups to be selected.'}
      isAdded={({ uuid }) => applicationDetails.apiGroupIds.includes(uuid)}
      onAddItem={
        async ({ uuid }) => {
          await fetchApiGroupEulas(
            uuid,
            hasOrgBoundRole(userContext) ?
              userActiveOrgUuid :
              applicationDetails.organizationUuid,
            );
          setApiGroupUuidToAdd(uuid);
        }
      }
      {...onLoadMoreApiGroupsProps}
      hasSearch={() => {}}
    />
  </Fragment>
);

export default [
  {
    tabId: 'available-apis-tab',
    label: 'APIs',
    tabPanel: availableApisTabPanel,
  },
  {
    tabId: 'available-api-groups-tab',
    label: 'API Groups',
    isHidden: ({ featureFlagApiPlans }) => (!!featureFlagApiPlans),
    tabPanel: availableApiGroupsTabPanel,
  },
];

availableApisTabPanel.propTypes = {
  applicationDetails: PropTypes.shape({}),
  availableApis: PropTypes.arrayOf(PropTypes.shape({})),
  fetchApiEula: PropTypes.func,
  setApiUuidToAdd: PropTypes.func,
  onLoadMoreApisProps: PropTypes.func,
  onAPISearchTextChange: PropTypes.func,
  SecondaryLabelComponent: PropTypes.func,
  ExternalFilter: PropTypes.func,
};

availableApiGroupsTabPanel.propTypes = {
  userContext: PropTypes.shape({}),
  userActiveOrgUuid: PropTypes.string,
  applicationDetails: PropTypes.shape({}),
  availableApiGroups: PropTypes.arrayOf(PropTypes.shape({})),
  fetchApiGroupEulas: PropTypes.func,
  setApiGroupUuidToAdd: PropTypes.func,
  onLoadMoreApiGroupsProps: PropTypes.func,
  onAPIGroupSearchTextChange: PropTypes.func,
};
