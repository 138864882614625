import { get } from 'lodash';

const styles = ({ color, fontSize, typography }) => ({
  pageTitle: {
    color: get(color, 'pageTitle'),
    fontFamily: get(typography, 'pageTitle'),
    fontSize: get(fontSize, 'pageTitle'),
    marginTop: 6,
  },
  sectionTitle: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'sectionTitle'),
  },
  fieldContainer: {
    margin: '20px 0',
  },
});

export default styles;
