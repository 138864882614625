import React from 'react';
import { FormattedMessage } from 'react-intl';

export default (dict) => (label) => (
  <FormattedMessage id={label} defaultMessage={dict[label]} />
);

// Configuration Tab
export const KEY_NAME_LABEL = 'Key Name';
export const STATUS_LABEL = 'Status';

// APIs Tab
export const VERSION_TEXT = 'Version';
export const CHANGE_ACTION_TEXT = 'Change';
export const ADD_APIPLAN_ACTION_TEXT = 'Add API Plan';

// Deployments Tab
export const KEY_DEPLOYMENT_TYPE_LABEL = 'Key Deployment Type';
export const LAST_UPDATED_LABEL = 'Last Updated';

export const NO_RESULTS_TEXT = 'No results';
export const DEPLOYMENT_ACTION_TYPE_DEPLOY = 'DEPLOY';
export const DEPLOYMENT_ACTION_TYPE_REDEPLOY = 'REDEPLOY';
export const DEPLOYMENT_ACTION_TYPE_UNDEPLOY = 'UNDEPLOY';

export const DEPLOYMENT_ACTION_LABEL_DEPLOY = 'Deploy';
export const DEPLOYMENT_ACTION_LABEL_REDEPLOY = 'Redeploy';
export const DEPLOYMENT_ACTION_LABEL_UNDEPLOY = 'Undeploy';

export const MORE_INFO_TEXT = 'More Info';
export const CHECK_NOW_TEXT = 'Check now';
export const UPDATING_TEXT = 'Updating...';
export const VALIDATING_TEXT = 'Validating...';
export const UPDATING_STATUS_TEXT = 'Updating status: check in';
export const SECONDS_UNIT_TEXT = 's';

export const DEPLOYMENT_STATUS_ERROR = 'ERROR';
export const DEPLOYMENT_STATUS_LABEL_UNDEPLOYED = 'Not deployed';
export const DEPLOYMENT_STATUS_LABEL_VALIDATION_ERROR = 'Validation error';
export const DEPLOYMENT_STATUS_LABEL_DEPLOYMENT_ERROR = 'Deployment error';

export const APP_DEPLOYMENT_TYPE_AUTOMATIC = 'AUTOMATIC';
export const APP_DEPLOYMENT_TYPE_ON_DEMAND = 'ON_DEMAND';

export const APP_STATUS_ENABLED = 'ENABLED';
export const APP_STATUS_DISABLED = 'DISABLED';
export const KEY_STATUS_ENABLED = 'ENABLED';
export const KEY_STATUS_DISABLED = 'DISABLED';

export const INFORMATION_TEXT = 'Information';
export const WARNING_TEXT = 'Warning';
export const CONFIRM_ACTION_TEXT = 'Are you sure you want to perform this action?';
export const OK_TEXT = 'Ok';
export const CANCEL_TEXT = 'Cancel';
export const NOT_APPLICABLE_TEXT = 'n/a';
