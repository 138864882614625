import colorMod from 'color';
import { get } from 'lodash';

const styles = ({ color, fontSize, typography }) => ({
  tabs: {
    flexDirection: 'column',
    minHeight: '40px',
  },
  tabLabel: {
    color: get(color, 'link'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    fontWeight: 'bold',
    textAlign: 'left',
    alignItems: 'flex-start !important',
    textTransform: 'none',
    minWidth: 0,
    whiteSpace: 'nowrap',
    maxWidth: '99%',
    height: '3.0em',
    minHeight: 'unset',
    opacity: '1 !important',
    '& span': {
      alignSelf: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  },
  tabActive: {
    color: `${get(color, 'bodyText')} !important`,
    backgroundColor: `${colorMod(get(color, 'primaryButtonBackground')).alpha(0.08).string()} !important`,
  },
  tabIndicator: {
    color: get(color, 'link'),
  },
  tabDisabled: {
    color: colorMod(get(color, 'bodyText')).alpha(0.7).string(),
  },
});

export default styles;
