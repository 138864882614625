import { map, isEmpty } from 'lodash';

import { DETAILS_TAB_TITLE, ORG_ASSIGNMENT_TAB_TITLE } from './labels';

const tabs = [
  {
    id: 'details',
    label: DETAILS_TAB_TITLE,
  },
  {
    id: 'organization-assignment',
    label: ORG_ASSIGNMENT_TAB_TITLE,
    isDisabled: (proxyUuid) => isEmpty(proxyUuid),
  },
];

export default map(tabs, tab => ({ ...tab, tabId: `${tab.id}-tab` }));
