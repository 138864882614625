import { get } from 'lodash';

const styles = ({ color, fontSize, typography }) => ({
  helpContainer: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    lineHeight: 1.6,
  },
  helpTitle: {
    fontSize: get(fontSize, 'sectionTitle'),
    margin: 0,
  },
  helpDescription: {
    marginBottom: 20,
  },
  helpTextHead: {
    fontWeight: 'bold',
  },
  helpTextBody: {
  },
  helpSubContainer: {
    marginTop: 20,
  },
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  iconText: {
    color: get(color, 'bodyText'),
    fontSize: get(fontSize, 'smallText'),
  },
  listIconRoot: {
    minWidth: '20px',
  },
});

export default styles;
