import { get } from 'lodash';

export default ({ misc }) => ({
  HeaderNav: {
    display: 'none',
    height: '100%',
  },

  HeaderDesktopNav: {
    flex: 1,
    marginLeft: 20,
  },

  [`@media (min-width: ${get(misc, 'breakpointMedium')}px)`]: {
    HeaderNav: {
      display: 'inline-block',
    },
  },

  HeaderNavLinks: {
    display: 'inline-block',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    position: 'relative',
    top: 2,
  },

  VerticalAlign: {
    height: '100%',
    display: 'table',
    textAlign: 'center',
  },

  VerticalAlignInner: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
});
