import { forEach, find } from 'lodash';

export const getCreateData = (
  userContext,
  {
    apiName,
    version,
    eulaUuid,
    privateDescription,
    serviceType,
    publicDescription,
    proxyUrl,
    location,
    accessStatus,
  }) => ({
    name: apiName,
    version,
    apiEulaUuid: eulaUuid,
    privateDescription,
    accessStatus,
    description: publicDescription,
    managingOrgUuid: userContext.organizationUuid,
    orgUuid: null, // default to no Organization assigned on API Creation
    ssgUrl: proxyUrl,
    authenticationType: 'NONE',
    authenticationParameters: '{}',
    locationUrl: location,
    apiServiceType: serviceType,
    type: 'BASIC',
  });

export const getUpdateDetails = (apiUuid, {
  apiName, name,
  version,
  eulaUuid, apiEulaUuid,
  privateDescription,
  accessStatus,
  portalStatus,
  serviceType, apiServiceType,
  publicDescription, description,
  proxyUrl, ssgUrl,
  authenticationType,
  authenticationParameters,
  location, locationUrl,
  publishedByPortal,
  type,
  possibleStatuses,
  managingOrgUuid,
}) => ({
  uuid: apiUuid,
  name: apiName || name,
  version,
  apiEulaUuid: eulaUuid || apiEulaUuid,
  privateDescription,
  accessStatus,
  portalStatus,
  apiServiceType: serviceType || apiServiceType,
  description: publicDescription || description,
  ssgUrl: proxyUrl || ssgUrl,
  authenticationType,
  authenticationParameters: authenticationParameters
    && JSON.stringify(authenticationParameters),
  locationUrl: location || locationUrl,
  publishedByPortal,
  type,
  possibleStatuses,
  managingOrgUuid,
});

export const getUpdateData = (apiUuid, {
  apiName, name,
  version,
  eulaUuid, apiEulaUuid,
  privateDescription,
  accessStatus,
  portalStatus,
  serviceType, apiServiceType,
  publicDescription, description,
  proxyUrl, ssgUrl,
  authenticationType,
  authenticationParameters,
  location, locationUrl,
  publishedByPortal,
  type,
  possibleStatuses,
  managingOrgUuid,
}) => ({
  uuid: apiUuid,
  name: apiName || name,
  version,
  apiEulaUuid: eulaUuid || apiEulaUuid,
  privateDescription,
  accessStatus,
  portalStatus,
  apiServiceType: serviceType || apiServiceType,
  description: publicDescription || description,
  ssgUrl: proxyUrl || ssgUrl,
  authenticationType,
  authenticationParameters: authenticationParameters
    && JSON.stringify(authenticationParameters),
  locationUrl: location || locationUrl,
  publishedByPortal,
  type,
  possibleStatuses,
  ...(managingOrgUuid && { managingOrgUuid }),
});

export const getUpdateFormData = (apiUuid, details, initialAssets = [], uploadedAssets) => {
  const formData = new FormData();
  const { policyEntities, customFieldValues } = details;

  forEach(
    getUpdateData(apiUuid, details),
    (value, key) => formData.append(key, value),
  );

  // append Policy Entities
  forEach(policyEntities, ({ policyEntityUuid, policyTemplateArguments }, peIndex) => {
    formData.append(`policyEntities[${peIndex}].policyEntityUuid`, policyEntityUuid);
    forEach(policyTemplateArguments, (pta, ptaIndex) => {
      const policyTemplateArgumentProp = `policyEntities[${peIndex}].policyTemplateArguments`;
      formData.append(`${policyTemplateArgumentProp}[${ptaIndex}].name`, pta.name);
      formData.append(`${policyTemplateArgumentProp}[${ptaIndex}].value`, pta.value);
    });
  });

  // append Custom Fields
  forEach(customFieldValues, ({ customFieldUuid, value }, cfIndex) => {
    formData.append(`customFieldValues[${cfIndex}].customFieldUuid`, customFieldUuid);
    formData.append(`customFieldValues[${cfIndex}].value`, value);
  });

  // append Assets
  if (uploadedAssets) {
    forEach(uploadedAssets.files, ({ blob, name }) => {
      if (blob) { formData.append('files', blob, name); }
    });
  }

  // remove Assets
  forEach(initialAssets, (initialAsset) => {
    const isPresent = find(
      uploadedAssets.files,
      (uploadedAsset) => (uploadedAsset.uuid === initialAsset.uuid),
    );
    if (!isPresent) {
      // formData.append('filesToDelete', initialAsset.uuid);
    }
  });

  return formData;
};

export const buildDocumentId = (entityType, entityUuid, navtitle, locale) => {
  return `${entityType}/${entityUuid}/${navtitle}/${locale}`;
};

export default {};
