import { get } from 'lodash';
import { convertHexToRGB } from '../../../../utils';

const styles = ({ color, typography, fontSize }) => ({
  topPanelContainer: {
    backgroundColor: `${convertHexToRGB(get(color, 'borderColor'), 0.1)}`,
    minHeight: '120px',
    padding: '0 36px',
  },
  marginPanelContainer: {
    minHeight: '350px',
  },
  detailsContainer: {
    padding: '20px 36px',
  },
  colTitle: {
    marginTop: '20px',
    marginBottom: '2px',
    textTransform: 'capitalize',
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    fontWeight: '700',
  },
});

export default styles;
