import React from 'react';
import Select, { components as SelectComponents } from 'react-select';
import { withStyles, useTheme } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import compose from 'recompose/compose';
import { map, get } from 'lodash';

import styles from './index.theme';
import { convertHexToRGB } from '../../utils';

type Props = {
  classes: Object,
  menuIsOpen: boolean,
  optionsData: Array<Object>,
  value: Array<string>,
  onChange: Function,
};

const getValueOptions = (values) => map(values, item => ({
  value: item,
  label: item,
}));

const sortFn = (item1, item2) =>
  (item1 && item1.label &&
  item1.label.toLowerCase().localeCompare(item2 && item2.label && item2.label.toLowerCase()));

const MultiSelectRaw = (props: Props) => {
  const { optionsData, menuIsOpen, value, ...otherProps } = props;
  const sortedOptions = optionsData.sort(sortFn);

  const theme = useTheme();
  let customStyles = {
    container: (provided) => ({
      ...provided,
      minHeight: 30,
      zIndex: 10,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: get(theme, 'color.uiBackground'),
      borderRadius: get(theme, 'borderRadius.br3'),
      borderColor: convertHexToRGB(get(theme, 'color.primaryButtonBackground'), 0.4),
      minHeight: '40px',
      '&:active, &:focus': {
        borderColor: get(theme, 'color.primaryButtonBackground'),
        boxShadow: 'none',
      },
      '&:hover': {
        borderColor: get(theme, 'color.primaryButtonHover'),
        boxShadow: 'none',
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 14,
      color: get(theme, 'color.bodyText'),
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: get(theme, 'color.uiBackground'),
      border: `1px solid ${get(theme, 'color.borderColor', '#C8D1E0')}`,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: 12,
      fontWeight: 'bold',
      color: get(theme, 'color.accent', '#09AEEF'),
      lineHeight: '14px',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      fontSize: get(theme, 'fontSize.bodyText'),
      '&:hover': {
        backgroundColor: get(theme, 'color.uiBackground', '#FFF'),
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: get(theme, 'fontSize.bodyText'),
      color: get(theme, 'color.bodyText'),
      opacity: 0.4,
    }),
  };

  if (menuIsOpen) {
    customStyles = {
      ...customStyles,
      menu: (base) => ({
        ...base,
        position: 'relative',
      }),
    };
  }

  const IndicatorsContainer = () => (
    <ArrowDropDownIcon
      className={props.classes.IndicatorsContainer}
    />
  );

  const MultiValueLabel = (multiValueLabelProps) =>
    (<SelectComponents.MultiValueLabel {...multiValueLabelProps} />);

  const MultiValueRemove = (multiValueRemoveProps) =>
    (<SelectComponents.MultiValueRemove {...multiValueRemoveProps}>
      <span className={props.classes.MultiValueClose}>X</span>
    </SelectComponents.MultiValueRemove>);

  return (
    <div className={props.classes.SelectWrapper}>
      <Select
        /*
          disabling captureMenuScroll, as it causing issues application flow in 2.4.4.
          To reproduce the issue, select a tag.
          If it is fixed after 2.4.4, captureMenuScroll can enabled.
        */
        captureMenuScroll={false}
        isMulti
        components={{
          IndicatorsContainer,
          MultiValueLabel,
          MultiValueRemove,
        }}
        menuIsOpen={menuIsOpen}
        styles={customStyles}
        options={sortedOptions}
        value={getValueOptions(value)}
        {...otherProps}
      />
    </div>
  );
};

export default compose(
  withStyles(styles),
)(MultiSelectRaw);
