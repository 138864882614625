import * as React from 'react';
import { Grid } from '@material-ui/core';
import { MultiSelect } from '../../../components';

import {
  APIS_LIST_VISIBILITIES_FILTER_OPTIONS,
  APIS_LIST_SORT_BY,
  APIS_LIST_STATES_FILTER_OPTIONS,
} from '../../../constants';

import { FormSelect } from '../../../components';

// eslint-disable-next-line react/prop-types
export const FilterByVisibility = ({ fieldContainerClass = '', ...props }) => (
  <Grid item className={fieldContainerClass}>
    <FormSelect
      {...props}
      id="apis-filter-by-visibility"
      data-apim-test="apis-filter-by-visibility"
      data={APIS_LIST_VISIBILITIES_FILTER_OPTIONS}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

// eslint-disable-next-line react/prop-types
export const FilterByState = ({ fieldContainerClass = '', ...props }) => (
  <Grid item className={fieldContainerClass}>
    <FormSelect
      {...props}
      id="apis-filter-by-state"
      data-apim-test="apis-filter-by-state"
      data={APIS_LIST_STATES_FILTER_OPTIONS}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

// eslint-disable-next-line react/prop-types
export const SortBy = ({ fieldContainerClass = '', ...props }) => (
  <Grid className={fieldContainerClass} item>
    <FormSelect
      {...props}
      id="apis-sort-by"
      data-apim-test="apis-sort-by"
      data={APIS_LIST_SORT_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

// eslint-disable-next-line react/prop-types
export const FilterByTags = ({ fieldContainerClass = '', ...props }) => (
  <Grid data-apim-test={'apis-filter-by-tags'} className={fieldContainerClass} item>
    <MultiSelect
      {...props}
    />
  </Grid>
);