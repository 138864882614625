import React from 'react';

import {
  Tabs,
  Tab,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

const TabsContainer = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    id,
    tabValue,
    handleTabChange,
    tabItems,
    useTabId,
    orientation = 'vertical',
    tabActiveClass = classes.tabActive,
    tabDisabledClass = classes.tabDisabled,
    tabIndicatorClass = classes.tabIndicator,
  } = props;

  return (
    <Tabs
      id={id} data-apim-test={id} data-layer7-test={id}
      orientation={orientation}
      variant={'standard'}
      aria-label={`aria-${id}`}
      classes={{
        root: classes.tabs,
        indicator: tabIndicatorClass,
      }}
      value={tabValue}
      onChange={handleTabChange}
    >
      {tabItems.map(
        item =>
          <Tab
            key={item.tabId}
            id={item.tabId} data-apim-test={item.tabId} data-layer7-test={item.tabId}
            label={item.label}
            {...(useTabId && { value: item.tabId })}
            classes={{
              root: classes.tabLabel,
              selected: tabActiveClass,
              disabled: tabDisabledClass,
            }}
            disabled={item.disabled}
          />,
        )
      }
    </Tabs>
  );
};

TabsContainer.propTypes = {
  id: PropTypes.string,
  handleTabChange: PropTypes.func,
  tabItems: PropTypes.arrayOf(PropTypes.shape({})),
  tabValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  useTabId: PropTypes.bool,
  orientation: PropTypes.string,
  tabActiveClass: PropTypes.node,
  tabDisabledClass: PropTypes.node,
  tabIndicatorClass: PropTypes.node,
};

export default TabsContainer;
