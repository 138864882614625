import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  makeStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Checkbox,
  TextField,
  Button,
  Grid,
  Tooltip,
  Typography,
 } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/CancelRounded';
import { find, findIndex, remove, isEmpty, some } from 'lodash';
import PropTypes from 'prop-types';
import styles from './styles';
import { getI18n } from '../../utils/intl';

const loadMoreText = 'label.react.components.searchtextlistcontainer.loadmore';
const selectedDefaultErrorMessage = 'error.react.components.searchtextlistcontainer.default.unselected.errormessage';
const addItemLabel = 'label.react.components.searchtextlistcontainer.additem';
const defaultValidationErrMsg = 'error.react.components.searchtextlistcontainer.default.newitem.errormessage';

const defaultGetSecondaryTextFn = (item) => item.email || '';
const defaultSortFn = (item1, item2) => item1 && item1.name
  && item1.name.toLowerCase().localeCompare(item2 && item2.name && item2.name.toLowerCase());
const DefaultSubTitleComponent = ({ classes, subtitle }) =>
  <div className={classes && classes.listSubtitle}>{subtitle}</div>;

const SearchTextListContainer = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    title,
    subtitle,
    placeHolderText,
    defaultList,
    defaultSelectedList,
    listId,
    isLoadMore,
    isCheckBoxVisible,
    isDeleteIconVisible,
    isSelectedNumberVisible,
    onSearchListChange,
    onLoadMore,
    isMandatory = true,
    isDisableSelectedOption = false,
    disableSelectedOptions = [],
    disableSelection = false,
    canAddItem = false,
    validateItem,
    getItemSecondaryText = defaultGetSecondaryTextFn,
    onAddItem,
    SubTitleComponent = DefaultSubTitleComponent,
    intl,
  } = props;
  const { getI18nMessage } = getI18n(intl);
  const {
    selectedItemErrorMessage = getI18nMessage(selectedDefaultErrorMessage),
    addItemText = getI18nMessage(addItemLabel),
    validationErrorMsg = getI18nMessage(defaultValidationErrMsg),
  } = props;

  const [searchText, setSearchText] = useState('');
  const [selectedList, setSelectedList] = useState(defaultSelectedList);
  const [filteredList, setFilterdList] = useState(defaultList);
  const [exactMatchAvailable, setExactMatchAvail] = useState(false);

  const updateFilteredListAndExactMatch = (defaultListParam, searchTerm) => {
    const filtered = defaultListParam.filter((item) => (
      item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    ));
    setFilterdList(filtered);

    const foundExactMatch = findIndex(defaultListParam, (item) =>
      searchTerm && item.name &&
      item.name.trim().toLowerCase() === searchTerm.trim().toLowerCase(),
    );
    setExactMatchAvail(foundExactMatch !== -1);
  };

  useEffect(() => {
    setSelectedList(defaultSelectedList);
    updateFilteredListAndExactMatch(defaultList, searchText);
  }, [defaultList, defaultSelectedList]);

  const onTextChange = (e) => {
    const searchTerm = e.target.value;
    updateFilteredListAndExactMatch(defaultList, searchTerm);
    setSearchText(searchTerm);
  };

  const onListCheck = (selectedUuid, wasSelected) => {
    const selectedItem = filteredList.filter((item) => (item.uuid === selectedUuid));

    let list = selectedList;
    if (!wasSelected) {
      list.push(selectedItem[0]);
    } else {
      list = remove([...selectedList], (currentObject) => currentObject.uuid !== selectedUuid);
    }
    setSelectedList([...list]);
    onSearchListChange(list);
  };

  const onListDelete = (selectedUuid) => {
    const list = remove([...selectedList], (currentObject) => currentObject.uuid !== selectedUuid);
    setSelectedList([...list]);
    onSearchListChange(list);
  };

  const loadMoreClick = () => {
    setSearchText('');
    onLoadMore();
  };

  const getSecondaryItems = (text) => (
    <Tooltip title={text} aria-label={text}>
      <Typography
        component={'span'}
        classes={{
          root: classes.secondaryListText,
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  );

  const getIsDisabled = (item) => {
    if (isDisableSelectedOption) {
      return some(disableSelectedOptions, ['uuid', item.uuid]);
    }
    return false;
  };
  const trimmedSearchText = searchText.trim();
  const showAddOption = canAddItem && searchText
    && trimmedSearchText.length > 0 && !exactMatchAvailable;
  let isValidNewItem = true;
  if (showAddOption && validateItem && trimmedSearchText) {
    isValidNewItem = validateItem(trimmedSearchText);
  }
  const clickHandler = isDeleteIconVisible ? onListDelete : onListCheck;

  return (
    <div>
      <h3 className={classes.listTitle}>
        {title} {(isSelectedNumberVisible && selectedList.length > 0) ? `(${selectedList.length})` : '' }
      </h3>
      <SubTitleComponent classes={classes} subtitle={subtitle} />
      <TextField
        classes={{
          root: classes.textField,
        }}
        id="org-search"
        placeholder={placeHolderText}
        margin="normal"
        variant="outlined"
        onChange={(e) => onTextChange(e)}
        InputProps={{
          type: 'search',
        }}
        value={searchText}
      />
      <List className={classes.listContainer} id={listId}>
        {(isMandatory && isDeleteIconVisible && selectedList.length === 0) &&
          <div>
            <Divider className={classes.lineError} />
            <ListItem button divider className={classes.listError}>
              {selectedItemErrorMessage}
            </ListItem>
          </div>
        }
        {showAddOption && isValidNewItem &&
          <ListItem button divider className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={searchText}
              title={searchText}
            />
            <Button
              onClick={() => onAddItem(searchText)}
              className={classes.addItem}
              color="secondary"
            >
              {addItemText}
            </Button>
          </ListItem>
        }
        {showAddOption && !isValidNewItem &&
          <ListItem divider className={classes.listError}>
            {validationErrorMsg}
          </ListItem>
        }
        {filteredList.sort(defaultSortFn)
          .map((item, i) => {
            const selecteItem = find(selectedList, { uuid: item.uuid });
            const secodaryText = getItemSecondaryText(item);
            const secondaryItem = getSecondaryItems(secodaryText);
            let isDisabled = getIsDisabled(item);
            const isSelected = !isEmpty(selecteItem);
            isDisabled = (disableSelection && isCheckBoxVisible && !isSelected) ? true : isDisabled;
            return (
              <div key={`${item.uuid}`}>
                {i === 0 && <Divider className={classes.divider} />}
                <ListItem
                  button
                  divider
                  className={classes.listItem}
                  disabled={isDisabled}
                  onClick={isDisabled ? null : (() => clickHandler(item.uuid, isSelected))}
                >
                  {isCheckBoxVisible &&
                    <ListItemIcon className={classes.listItemIcon}>
                      <Checkbox
                        size="small"
                        edge="start"
                        disableRipple
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                        value={item.uuid}
                        checked={isSelected}
                        disabled={isDisabled}
                      />
                    </ListItemIcon>
                  }
                  {isDeleteIconVisible &&
                    <IconButton
                      className={classes.listItemOption}
                      size="small"
                      disabled={isDisabled}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                  <ListItemText
                    className={classes.listItemText}
                    primary={item.name}
                    secondary={secondaryItem || ''}
                    title={item.name}
                  />
                </ListItem>
              </div>
            );
          })
        }
        {isLoadMore &&
          <ListItem key="button-loadmore">
            <Grid container justify="center">
              <Button
                variant="contained"
                color="secondary"
                className={classes.loadMore}
                onClick={loadMoreClick}
              >
                {getI18nMessage(loadMoreText)}
              </Button>
            </Grid>
          </ListItem>
        }
      </List>
    </div>
  );
};

export default injectIntl(SearchTextListContainer);

SearchTextListContainer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  placeHolderText: PropTypes.string,
  defaultList: PropTypes.arrayOf(PropTypes.object),
  defaultSelectedList: PropTypes.arrayOf(PropTypes.object),
  listId: PropTypes.string,
  isLoadMore: PropTypes.bool,
  isCheckBoxVisible: PropTypes.bool,
  isDeleteIconVisible: PropTypes.bool,
  isSelectedNumberVisible: PropTypes.bool,
  onSearchListChange: PropTypes.func,
  onLoadMore: PropTypes.func,
  isMandatory: PropTypes.bool,
  isDisableSelectedOption: PropTypes.bool,
  disableSelectedOptions: PropTypes.arrayOf(PropTypes.object),
  disableSelection: PropTypes.bool,
  canAddItem: PropTypes.bool,
  addItemText: PropTypes.string,
  getItemSecondaryText: PropTypes.func,
  validateItem: PropTypes.func,
  validationErrorMsg: PropTypes.string,
  onAddItem: PropTypes.func,
  selectedItemErrorMessage: PropTypes.string,
  SubTitleComponent: PropTypes.func,
  intl: PropTypes.object,
};

DefaultSubTitleComponent.propTypes = {
  classes: PropTypes.object,
  subtitle: PropTypes.string,
};
