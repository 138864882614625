import modifyColour from '../../../utils/modifyColour';
import { get } from 'lodash';

export default ({ color, fontSize, misc, typography }) => ({
  HeaderServices: {
    fontFamily: get(typography, 'bodyText'),
    zIndex: 100,
  },

  Menu: {
    fontFamily: get(typography, 'bodyText'),
    width: 225,
    padding: '10px 0 8px',
  },

  HeaderServicesPanel: {
    alignItems: 'baseline',
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: get(typography, 'bodyText'),
    padding: '20px 15px',
    width: '290px',
  },

  HeaderService: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    width: `${100 / 3}%`,

    color: get(color, 'grayDark'),
    fontSize: get(fontSize, 'smallText'),
    textAlign: 'center',
    textDecoration: 'none',
  },

  HeaderServiceImage: {
    marginBottom: '8px',
    maxHeight: 28,
    maxWidth: 28,
  },

  HeaderServiceLink: {
    alignItems: 'center',
    boxSizing: 'border-box',
    color: get(color, 'link'),
    display: 'flex',
    flexDirection: 'column',
    fontSize: get(fontSize, 'smallText'),
    padding: '10px',
    width: `${100 / 3}%`,
    textAlign: 'center',
  },

  HeaderServicesButton: {
    display: 'none',

    '& button': {
      color: get(color, 'headerLink'),
    },
  },

  ServiceLink: {
    textDecoration: 'none',
    transition: get(misc, 'colorTransition'),

    '&:hover': {
      color: modifyColour(get(color, 'link'), 'lighten', 0.4),
    },
  },

  ServiceLinkImageContainer: {
    margin: '4px',
    width: 30,
    height: 30,
    textAlign: 'center',
    backgroundColor: get(color, 'link'),
  },

  ServiceLinkImage: {
    maxHeight: 30,
    maxWidth: 30,
  },

  [`@media (min-width: ${get(misc, 'breakpointMedium')}px)`]: {
    HeaderServicesButton: {
      display: 'inline-block',
    },
  },
});
