import React from 'react';
import { Grid } from '@material-ui/core';

import { FormSelect, FormTextField } from '../../../../components';

import {
  PROXY_CERTIFICATES_SORT_BY,
} from '../../../../constants';


export const FilterByName = (props) => (
  <Grid item md={3}>
    <FormTextField
      {...props}
      id="proxy-details-certificates-filter-by-name"
      data-apim-test="proxy-details-certificates-filter-by-name"
    />
  </Grid>
);

export const FilterBySubjectDn = (props) => (
  <Grid item md={3}>
    <FormTextField
      {...props}
      id="proxy-details-certificates-filter-by-subjectDn"
      data-apim-test="proxy-details-certificates-filter-subjectDn"
    />
  </Grid>
);

export const SortBy = (props) => (
  <Grid item md={3}>
    <FormSelect
      {...props}
      id="proxy-details-certificates-sort-by"
      data-apim-test="proxy-details-certificates-sort-by"
      data={PROXY_CERTIFICATES_SORT_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterAndSortSeparator = () => (
  <Grid item md={3} />
);
