import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { includes, some } from 'lodash';

import {
  FormActionButtons,
  ErrorContainer,
  FormTextField,
  FormSelect,
  FormRadioGroup,
} from '../../../../components';
import {
  SAVE_AND_NEXT_TEXT,
  API_SPEC_AUTHENTICATION_TYPE_LABEL,
  API_SPEC_AUTHENTICATION_TYPES,
  API_SPEC_AUTHENTICATION_TYPE_API_KEY,
  API_SPEC_AUTHENTICATION_TYPE_OAUTH2,
  API_SPEC_AUTHENTICATION_TYPE_API_KEY_TYPE_QUERY,
  API_SPEC_AUTHENTICATION_TYPE_OAUTH2_GRANT_TYPE_CODE,
} from '../labels';

export const getDefaultAuthenticationParameters = (authenticationType) => ({
  ...(authenticationType === API_SPEC_AUTHENTICATION_TYPE_API_KEY
    && { ApiKeyType: API_SPEC_AUTHENTICATION_TYPE_API_KEY_TYPE_QUERY }),
  ...(authenticationType === API_SPEC_AUTHENTICATION_TYPE_OAUTH2
    && { GrantType: API_SPEC_AUTHENTICATION_TYPE_OAUTH2_GRANT_TYPE_CODE }),
});

export default function SpecAuthentication(props) {
  const {
    classes,
    errors,
    apiUuid,
    apiDetails,
    updateApi,
    showLoading,
    onCancel,
  } = props;

  const [authenticationType, setAuthenticationType] = useState(apiDetails.authenticationType);
  const [authenticationParameters, setAuthenticationParameters] = useState(
    getDefaultAuthenticationParameters(authenticationType),
  );
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);

  useEffect(() => {
    setAuthenticationType(apiDetails.authenticationType);
    if (apiDetails.authenticationParameters) {
      setAuthenticationParameters({ ...apiDetails.authenticationParameters });
    }
  }, [apiDetails.authenticationType, apiDetails.authenticationParameters]);

  const handleChange = (fieldName, value) => {
    localStorage.setItem('isUnSavedChanges', true);
    setIsUnsavedChanges(true);
    switch (fieldName) {
      case 'authenticationType': {
        setAuthenticationType(value);
        setAuthenticationParameters(getDefaultAuthenticationParameters(value));
        break;
      }
      case 'authenticationParameters': {
        setAuthenticationParameters(value);
        break;
      }
      default:
    }
  };

  const onNext = async () => {
    localStorage.setItem('isUnSavedChanges', false);
    setIsUnsavedChanges(false);
    await showLoading(true);
    await updateApi(apiUuid, {
      ...apiDetails, authenticationType, authenticationParameters,
    });
  };

  return (
    <div id="api-spec-auth-container">
      <h1 className={classes.pageTitle}>Spec Authentication</h1>
      {errors.length > 0 &&
        <ErrorContainer errors={errors} />
      }
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid md={12} item>
          {authenticationType &&
            <FormRadioGroup
              id="api-spec-authentication-type"
              name={`${API_SPEC_AUTHENTICATION_TYPE_LABEL}:`}
              selected={authenticationType}
              onChange={(value) => handleChange('authenticationType', value)}
              data={API_SPEC_AUTHENTICATION_TYPES}
            />
          }
          {(authenticationType === API_SPEC_AUTHENTICATION_TYPE_API_KEY) &&
            <FormSelect
              id={'api-spec-authentication-api-key-type'}
              name={'API Key Type'}
              value={authenticationParameters.ApiKeyType}
              data={[
                { uuid: 'query', name: 'Query' },
                { uuid: 'header', name: 'Header' },
              ]}
              handleChange={(ApiKeyType) => handleChange('authenticationParameters', {
                ...authenticationParameters,
                ApiKeyType,
              })}
              noNoneOption
              noNativeSelect
            />
          }
          {(authenticationType === API_SPEC_AUTHENTICATION_TYPE_OAUTH2) &&
            <FormSelect
              id={'api-spec-authentication-grant-type'}
              name={'Grant Type'}
              value={authenticationParameters.GrantType}
              data={[
                { uuid: 'CODE', name: 'Authorization Code' },
                { uuid: 'TOKEN', name: 'Implicit' },
                { uuid: 'PASSWORD', name: 'Resource Owner Password Credentials' },
                { uuid: 'CLIENT_CREDENTIALS', name: 'Client Credentials' },
              ]}
              handleChange={(GrantType) => handleChange('authenticationParameters', {
                ...authenticationParameters,
                GrantType,
              })}
              noNoneOption
              noNativeSelect
            />
          }
          {(authenticationType === API_SPEC_AUTHENTICATION_TYPE_OAUTH2) &&
            includes(['CODE', 'TOKEN'], authenticationParameters.GrantType) &&
            <FormTextField
              id={'api-spec-authentication-authorization-endpoint'}
              name={'Authorization Endpoint'}
              value={authenticationParameters.AuthorizeEndpoint}
              error={some(errors, ({ field }) => (field === 'AuthorizeEndpoint'))}
              handleChange={(AuthorizeEndpoint) => handleChange('authenticationParameters', {
                ...authenticationParameters,
                AuthorizeEndpoint,
              })}
              helperText={'Maximum length is 900 characters. Must be a valid URL.'}
            />
          }
          {(authenticationType === API_SPEC_AUTHENTICATION_TYPE_OAUTH2) &&
            includes(['CODE', 'PASSWORD', 'CLIENT_CREDENTIALS'], authenticationParameters.GrantType) &&
            <FormTextField
              id={'api-spec-authentication-token-endpoint'}
              name={'Token Endpoint'}
              value={authenticationParameters.TokenEndpoint}
              error={some(errors, ({ field }) => (field === 'TokenEndpoint'))}
              handleChange={(TokenEndpoint) => handleChange('authenticationParameters', {
                ...authenticationParameters,
                TokenEndpoint,
              })}
              helperText={'Maximum length is 900 characters. Must be a valid URL.'}
            />
          }
        </Grid>
      </Grid>
      <FormActionButtons
        onNextClick={onNext}
        nextText={SAVE_AND_NEXT_TEXT}
        onCancelClick={() => onCancel(isUnsavedChanges)}
        id="spec-form-buttons"
      />
    </div>
  );
}

SpecAuthentication.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  apiUuid: PropTypes.string,
  apiDetails: PropTypes.object,
  updateApi: PropTypes.func,
  showLoading: PropTypes.func,
  onCancel: PropTypes.func,
};
