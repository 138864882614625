import get from 'lodash/get';

const styles = ({ color, fontSize, margin, padding, typography }) => ({
  headerContainer: {
    padding: `0 ${get(padding, 'p36')}`,
  },
  pageTitle: {
    margin: `${get(margin, 'm5')} 0`,
    wordWrap: 'break-word',
  },
  subTitle: {
    paddingBottom: `${get(padding, 'p10')}`,
    fontSize: `${get(fontSize, 'bodyText')}`,
    wordWrap: 'break-word',
  },
  statusBar: {
    margin: `${get(margin, 'm5')} 0 ${get(margin, 'm21')} 0`,
    '& span': {
      position: 'relative',
      display: 'inline-block',
      marginBottom: '5px',
      paddingLeft: '15px',
      verticalAlign: 'middle',
      fontSize: get(fontSize, 'smallText'),
      letterSpacing: 0,
    },
  },
  status: {
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    '&:before': {
      display: 'inline-block',
      position: 'absolute',
      content: '""',
      left: 0,
      top: '50%',
      marginTop: '-6px',
      height: '10px',
      width: '10px',
      borderRadius: '10px',
    },
  },
  available: {
    color: get(color, 'success'),
    '&:before': {
      backgroundColor: get(color, 'success'),
    },
  },
  pendingEnrollment: {
    color: get(color, 'disabledGraphic'),
    '&:before': {
      backgroundColor: get(color, 'disabledGraphic'),
    },
  },
  markerContainer: {
    backgroundColor: get(color, 'gray'),
    padding: get(padding, 'p5'),
    borderRadius: get(padding, 'p10'),
    marginLeft: get(margin, 'm4'),
    fontFamily: get(typography, 'bodyText'),
  },
});

export default styles;
