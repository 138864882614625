import React from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import copyToClipboard from 'copy-to-clipboard';
import { bool, func, shape, string } from 'prop-types';

import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import styles from './styles';
import { HelpContainer, StatusIndicator } from '../../../../components';
import {
  ALERT_SUCCESS,
  PROXY_ENROLMENT_STATUS_PENDING,
  PROXY_ENROLLMENT_HELP_ITEMS,
} from '../../../../constants';

export const copy = ({ intl, enrollmentUrl, notifyMessages }) => {
  notifyMessages(ALERT_SUCCESS, intl.getI18nMessage('label.proxy.details.pending.enrollment.enrollment.url.copied'));
  copyToClipboard(enrollmentUrl);
  setTimeout(() => {
    notifyMessages('', '');
  }, 3000);
};

export const GatewayDetailsLoading = ({ classes }) => (
  <IconButton
    className={classes.checkingStatus}
    id="checking-status" data-layer7-test="checking-status"
  >
    <AutorenewIcon />
    <Typography variant="body1" className={classes.checkingStatus}>
      {getI18nFormattedMessage('label.proxy.details.pending.enrollment.checking')}
    </Typography>
  </IconButton>
);

GatewayDetailsLoading.propTypes = {
  classes: shape({}),
};
GatewayDetailsLoading.displayName = 'GatewayDetailsLoading';

export const GatewayDetailsLoaded = ({
  classes = {},
  fetchProxyGateway,
  proxyUuid,
}) => (
  <div
    className={classes.checkStatus}
    id="check-status" data-layer7-test="check-status"
  >
    <StatusIndicator inline status={PROXY_ENROLMENT_STATUS_PENDING} />
    <IconButton onClick={() => fetchProxyGateway(proxyUuid)}>
      <Typography variant="body1">
        {getI18nFormattedMessage('label.proxy.details.pending.enrollment.check.status')}
      </Typography>
    </IconButton>
  </div>
);

GatewayDetailsLoaded.propTypes = {
  classes: shape({}),
  fetchProxyGateway: func,
  proxyUuid: string,
};
GatewayDetailsLoaded.displayName = 'GatewayDetailsLoaded';

export const PendingEnrollment = (props) => {
  const {
    classes,
    gatewayDetailsLoading,
    proxy = {},
    notifyMessages,
  } = props;

  const intl = getI18n(useIntl());

  const { enrollmentUrl = '' } = proxy;

  return (
    <div
      className={classes.pendingEnrollmentContainer}
      id="proxy-details-overview-pending-enrollment-container"
      data-apim-test="proxy-details-pending-enrollment-container"
      data-layer7-test="proxy-details-pending-enrollment-container"
    >
      <Grid container>
        <Grid item md={9} sm={12} xs={12}>
          <Typography variant="h2">
            {intl.getI18nMessage('label.proxy.details.pending.enrollment.title')}
          </Typography>
          <Typography variant="h4">
            {intl.getI18nMessage('label.proxy.details.pending.enrollment.copy.url.title')}
          </Typography>
          <FormLabel className={classes.formLabel}>
            {intl.getI18nMessage('label.proxy.details.pending.enrollment.enrollment.url')}
          </FormLabel>
          <TextField
            variant="outlined"
            size="small"
            disabled
            classes={{ root: classes.enrollmentUrl }}
            id="enrollmentUrl" data-layer7-test="enrollmentUrl"
            value={enrollmentUrl}
            helperText={intl.getI18nMessage('label.proxy.details.pending.enrollment.expires.text')}
          />
          <Button
            color="secondary"
            variant="contained"
            className={classes.copyEnrollmentUrl}
            onClick={() => copy({ intl, enrollmentUrl, notifyMessages })}
          >
            {intl.getI18nMessage('label.copy.button')}
          </Button>
          <Typography variant="h4">
            {intl.getI18nMessage('label.proxy.details.pending.enrollment.confirm.enrollment.title')}
          </Typography>
          <Typography variant="body1">
            {intl.getI18nMessage('label.proxy.details.pending.enrollment.post.enrollment.note')}
          </Typography>
          {gatewayDetailsLoading ?
            <GatewayDetailsLoading {...props} /> :
            <GatewayDetailsLoaded {...props} />
          }
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
          <HelpContainer id="proxy-details-pending-enrollment-help" helpItems={PROXY_ENROLLMENT_HELP_ITEMS} />
        </Grid>
      </Grid>
    </div>
  );
};

PendingEnrollment.propTypes = {
  classes: shape({}),
  gatewayDetailsLoading: bool,
  proxy: shape({}),
  notifyMessages: func,
};
PendingEnrollment.displayName = 'PendingEnrollment';

export default withStyles(styles)(PendingEnrollment);
