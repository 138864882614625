import get from 'lodash/get';

const styles = ({ color, margin, padding, typography, fontSize, fontWeight, lineHeight } = {}) => ({
  fieldContainer: {
    margin: `${get(margin, 'm2')} ${get(margin, 'm4')} ${get(margin, 'm2')} ${get(margin, 'm0')} !important`,
    padding: '10px 0',
  },
  selectField: {
    padding: `${get(padding, 'p2')} ${get(padding, 'p10')} !important`,
  },
  filterLabel: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'fontSize.bodyText'),
    fontWeight: get(fontWeight, 'semiBold'),
    lineHeight: get(lineHeight, 'lh21'),
  },
  searchBarContainerWrapper: {
    alignSelf: 'flex-end',
    paddingBottom: 17,
  },
  searchBarContainer: {
    marginBottom: 5,
    padding: '10px 0',
    width: 250,
  },
  visibilityContainer: {
    margin: `${get(margin, 'm2')} ${get(margin, 'm4')} ${get(margin, 'm2')} ${get(margin, 'm0')} !important`,
    padding: '10px 0',
    width: 150,
  },
  tagsContainer: {
    alignSelf: 'flex-end',
    paddingBottom: 32,
    width: 400,
  },
  sortContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  viewBtn: {
    padding: '2px!important',
    marginLeft: '0px!important',
    marginBottom: 32,
    minWidth: 28,
    backgroundColor: 'transparent!important',
    border: '0px transparent!important',
    '&:hover': {
      opacity: 0.5,
    },
    color: `${get(color, 'primaryButtonBackground')} !important`,
  },
  viewBtn__selected: {
    padding: '2px!important',
    marginLeft: '0px!important',
    marginBottom: 32,
    minWidth: 28,
    backgroundColor: 'transparent!important',
    border: '0px transparent!important',
    color: `${get(color, 'primaryButtonBackground')} !important`,
  },
  viewIcon: {
    color: `${get(color, 'primaryButtonBackground')} !important`,
    height: '24px',
    width: '24px',
  },
  viewTypeBtns: {
    height: 30,
    paddingBottom: 15,
  },
  tagsGroupContainer: {
    maxWidth: 320,
  },
  selectWrapper: {
    minHeight: 370,
    width: 500,
  },
  itemState: {
    overflow: 'hidden',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    color: color.white,
    borderRadius: '20px',
  },
});

export default styles;
