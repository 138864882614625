import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Link,
  withStyles,
} from '@material-ui/core';

import styles from './styles';
import {
  APPLICATION_STATUS_ENABLED,
  APPLICATION_STATUS_PENDING_APPROVAL,
  APPLICATION_STATUS_EDIT_PENDING_APPROVAL,
  APPLICATION_STATUS_REJECTED,
  APPLICATION_STATUS_DELETE_PENDING_APPROVAL,
} from '../../../../constants';

export const getStatusColor = (classes, status) => {
  let statusLabel = '';
  let statusColorClass = '';
  switch (status) {
    case APPLICATION_STATUS_ENABLED:
      statusLabel = 'Enabled';
      statusColorClass = classes.available;
      break;
    case APPLICATION_STATUS_PENDING_APPROVAL:
    case APPLICATION_STATUS_EDIT_PENDING_APPROVAL:
    case APPLICATION_STATUS_DELETE_PENDING_APPROVAL:
      statusLabel = 'Pending Approval';
      statusColorClass = classes.pending;
      break;
    case APPLICATION_STATUS_REJECTED:
      statusLabel = 'Rejected';
      statusColorClass = classes.unavailable;
      break;
    default:
      statusLabel = 'Disabled';
      statusColorClass = classes.unavailable;
      break;
  }
  return {
    statusLabel, statusColorClass,
  }
};

export const HeaderRaw = (props) => {
  const {
    appUuid,
    applicationDetails,
    isEditAppDisabled,
    history,
    classes,
  } = props;
  const { name, status } = applicationDetails;
  const { statusLabel, statusColorClass } = getStatusColor(classes, status);

  const goToEditApplication = () => {
    history.push(`/publish/applications/edit/${appUuid}/details`);
  };

  return (
    <div
      className={classes.appHeaderContainer}
      id="app-header-container" data-layer7-test="app-header-container"
    >
      <h1 className={classes.pageTitle}>{name}</h1>
      <div className={classes.statusBar}>
        {statusLabel && <span className={cx(classes.status, statusColorClass)}>{statusLabel}</span>}
        <span
          className={
            cx(classes.linkWrapper, (status === APPLICATION_STATUS_REJECTED || isEditAppDisabled) ? classes.disabledClass : '')
          }
        >
          <Link onClick={goToEditApplication}>{'(Change)'}</Link>
        </span>
      </div>
    </div>
  );
};

HeaderRaw.propTypes = {
  appUuid: PropTypes.string,
  applicationDetails: PropTypes.object,
  isEditAppDisabled: PropTypes.bool,
  history: PropTypes.object,
  classes: PropTypes.object,
  hideDeployments: PropTypes.func,
  fetchApplication: PropTypes.func,
  fetchSingleApplication: PropTypes.func,
};
HeaderRaw.displayName = 'Header';

export default withStyles(styles)(HeaderRaw);
