import { getI18nFormattedMessage } from '../../../utils/intl';

export const HELP_ITEMS = {
  bundleHelp: {
    title: getI18nFormattedMessage('label.gateway.bundle.edit.help.title'),
    descriptions: '',
    subItems: [
      {
        id: 0,
        title: '',
        descriptions: getI18nFormattedMessage('label.gateway.bundle.edit.help.0.desc'),
      },
      {
        id: 1,
        title: '',
        descriptions: getI18nFormattedMessage('label.gateway.bundle.edit.help.1.desc'),
        list: {
          type: 'circle',
          items: [
            getI18nFormattedMessage('label.gateway.bundle.edit.help.1.0'),
            getI18nFormattedMessage('label.gateway.bundle.edit.help.1.1'),
            getI18nFormattedMessage('label.gateway.bundle.edit.help.1.2'),
            getI18nFormattedMessage('label.gateway.bundle.edit.help.1.3'),
          ],
        },
      },
      {
        id: 2,
        title: '',
        descriptions: getI18nFormattedMessage('label.gateway.bundle.edit.help.2.desc'),
      },
    ],
  },
};

export const BUNDLE_FILE_TYPE = '.yml,.bundle';
export const ALLOWED_FILE_EXTENSIONS = ['metadata.yml', 'install.bundle', 'delete.bundle'];
export const YML_FILE_EXT = 'metadata.yml';
export const BUNDLE_FILE_EXT = 'install.bundle';
export const DELETE_BUNDLE_FILE_EXT = 'delete.bundle';
export const MAX_BUNDLE_SIZE_IN_MB = 15;
export const MAX_FILE_SIZE = MAX_BUNDLE_SIZE_IN_MB * 1024 * 1024;
export const MIME_TYPES = {
  'metadata.yml': 'text/yml',
  'install.bundle': 'application/octet-stream',
  'delete.bundle': 'application/octet-stream',
};
