
export default ({ color, fontSize, typography }) => ({
  dropdown: {
    display: 'inline-block',
    height: 36,
    width: 118,
    boxSizing: 'content-box',
  },
  selectDropdown: {
    backgroundColor: color.uiBackground,
    width: 118,
    height: 36,
    color: color.bodyText,
  },
  selectDropdownSelect: {
    color: color.bodyText,
    fontFamily: typography.bodyText,
    fontSize: fontSize.bodyText,
    padding: '0 !important',
    margin: '9px 8px 8px',
    '&:focus': {
      backgroundColor: color.uiBackground,
    },
  },
  menuItems: {
    color: color.bodyText,
    fontFamily: typography.bodyText,
    fontSize: fontSize.bodyText,
  },
  '@media screen and (min-width: 330px) and (max-width: 630px)': {
    dropdown: {
      width: 76,
    },
    selectDropdown: {
      width: 76,
    },
  },
});
