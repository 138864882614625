import colorMod from 'color';
import { get } from 'lodash';

export default ({ color, fontSize, typography }) => ({
  root: {
    '& h1': {
      color: get(color, 'pageTitle'),
      fontFamily: get(typography, 'siteTitle'),
      fontSize: get(fontSize, 'siteTitle'),
      fontWeight: '100',
      maxWidth: '1160px',
      padding: '0px 25px',
      margin: '45px auto 0',
    },
  },

  autoCompleteRoot: {
    backgroundColor: get(color, 'uiBackground'),
    border: `1px solid ${colorMod(get(color, 'primaryButtonBackground')).alpha(0.4).string()}`,
    height: 34,
    width: '100%',
  },

  autoCompleteFocused: {
    border: `1px solid ${get(color, 'primaryButtonBackground')}`,
    boxShadow: `0 0 0 1px ${get(color, 'primaryButtonBackground')}`,
    zIndex: 15,
  },

  autoCompleteInput: {
    padding: '0 !important',
    backgroundColor: `${get(color, 'uiBackground')} !important`,
    fontFamily: typography.bodyText,
    fontSize: get(fontSize, 'bodyTextget'),
    width: '100% !important',
    height: '18px!important',
    margin: '8px 8px 8px 8px !important',
    borderRadius: '0!important',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  autoCompleteInputRoot: {
    borderRadius: 0,
    padding: '0 !important',
    '& input': {
      border: 'none !important',
      '&::placeholder': {
        color: `${colorMod(get(color, 'bodyText')).alpha(0.7).string()} !important`,
      },
    },
    '&::before': {
      borderBottom: 'none !important',
      transition: 'none !important',
    },
    '&::after': {
      borderBottom: 'none !important',
      transition: 'none !important',
      transform: 'none !important',
    },
  },

  autoCompleteHidden: {
    display: 'none',
  },

  autoCompleteShow: {
    display: 'block',
  },

  autoCompletePaper: {
    color: '#4B5060',
    '& ul li:hover': {
      backgroundColor: '#EFF3FA',
    },
  },

  autoCompleteTFRoot: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },

  recentSearches: {
    marginBottom: 0,
    marginLeft: 8,
    marginTop: 8,
    fontSize: get(fontSize, 'bodyText'),
    color: get(color, 'gray'),
  },

  suggestionText__Text: {
    fontWeight: 'normal',
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
  },

  suggestionText__highlightedText: {
    fontWeight: 'bold',
    fontFamily: get(typography, 'buttonText'),
    fontSize: get(fontSize, 'bodyText'),
  },
});
