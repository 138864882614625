import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles, Grid, Box } from '@material-ui/core';

import { Footer } from '../../../../components';
import {
  fetchDeploymentRefreshTime,
} from '../../../../actions/portalConfig';
import {
  getDeploymentRefreshTime,
} from '../../../../reducers/portalConfig';
import {
  fetchDeploymentProxyList,
  fetchDeploymentByApi,
  deployApi,
  redeployApi,
  undeployApi,
} from '../../../../actions/api';
import {
  getProxyListResults,
  getApiDeploymentList,
  getProxyListErrors,
  getProxyErrors,
} from '../../../../reducers/api';
import DeploymentCard from './DeploymentCard';
import styles from './styles';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import {
  ALERT_ERROR,
} from '../../../../constants';


const getDeploymentList = (deployments, apiDeployments) => {
  const combinedList = [];
  deployments.map((deployment) => {
    const matchedDeployment = apiDeployments.find(apiDeployment =>
      apiDeployment.proxyUuid === deployment.uuid);
    if (matchedDeployment) {
      combinedList.push(Object.assign({}, matchedDeployment, deployment));
    } else {
      combinedList.push(Object.assign({}, deployment));
    }
    return true;
  });
  return combinedList;
};

export const DeploymentsRaw = (props) => {
  const {
    apiUuid,
    userContext,
    canEdit,
    apiDetails,
    deploymentProxyList = [],
    apiDeployments = [],
    deploymentErrors = [],
    listProxyErrors = [],
    notifyMessages,
    classes,
  } = props;

  const deploymentList = getDeploymentList(deploymentProxyList, apiDeployments);
  const intl = getI18n(useIntl());

  useEffect(() => {
    if (apiUuid) {
      props.fetchDeploymentProxyList(apiUuid);
      props.fetchDeploymentByApi(apiUuid);
      props.fetchDeploymentRefreshTime();
    }
  }, [apiUuid]);

  useEffect(() => {
    if (listProxyErrors.length > 0) {
      notifyMessages(ALERT_ERROR, intl.getI18nMessage('error.apis.proxy.list.fetch'));
    } else {
      notifyMessages('', '');
    }
  }, [listProxyErrors]);

  const checkStatus = () => {
    props.fetchDeploymentByApi(apiUuid);
  };

  return (
    <div
      id="api-details-deployments-container"
      data-apim-test={'api-details-deployments-container'}
      className={classes.deploymentsContainer}
    >
      <Grid container className={classes.bodyContent}>
        {deploymentList.length > 0 ?
          deploymentList.map((obj, idx) => (
            <Box width="25%" key={obj.uuid} className={classes.deploymentCard}>
              <DeploymentCard
                index={idx}
                userContext={userContext}
                canEdit={canEdit}
                apiDetails={apiDetails}
                details={{
                  name: obj.name,
                  status: obj.status,
                  statusMessage: obj.message,
                  modifyTs: obj.lastTimeDeployed,
                  apiUuid: apiUuid,
                  proxyUuid: obj.uuid,
                  type:obj.deploymentType,
                }}
                errorObj={deploymentErrors.find(err => (err.proxyUuid === obj.uuid))}
                refreshTime={props.refreshTime}
                deployApi={props.deployApi}
                redeployApi={props.redeployApi}
                undeployApi={props.undeployApi}
                checkStatus={checkStatus}
                classes={classes}
              />
            </Box>
          )) :
          <Box className={classes.messageContent} width="100%">
            {getI18nFormattedMessage('label.api.details.deployments.no.proxy.organizations')}
          </Box>
        }
      </Grid>
      <Footer />
    </div>
  );
};

const mapStateToProps = state => ({
  deploymentProxyList: getProxyListResults(state),
  apiDeployments: getApiDeploymentList(state),
  refreshTime: getDeploymentRefreshTime(state),
  deploymentErrors: getProxyErrors(state),
  listProxyErrors: getProxyListErrors(state),
});

const mapDispatchToProps = {
  fetchDeploymentProxyList,
  fetchDeploymentByApi,
  fetchDeploymentRefreshTime,
  deployApi,
  undeployApi,
  redeployApi,
};

DeploymentsRaw.propTypes = {
  apiUuid: PropTypes.string,
  apiDetails: PropTypes.object,
  userContext: PropTypes.object,
  canEdit: PropTypes.bool,
  deploymentProxyList: PropTypes.arrayOf(PropTypes.object),
  apiDeployments: PropTypes.arrayOf(PropTypes.object),
  deploymentErrors: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  listProxyErrors: PropTypes.arrayOf(PropTypes.object),
  notifyMessages: PropTypes.func,
  fetchDeploymentProxyList: PropTypes.func,
  fetchDeploymentRefreshTime: PropTypes.func,
  fetchDeploymentByApi: PropTypes.func,
  refreshTime: PropTypes.func,
  deployApi: PropTypes.func,
  undeployApi: PropTypes.func,
  redeployApi: PropTypes.func,
};
DeploymentsRaw.displayName = 'Deployments';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(DeploymentsRaw);
