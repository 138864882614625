import React from 'react';
import classNames from 'classnames';
import { makeStyles, FormHelperText, FormLabel } from '@material-ui/core';
import { func, string, object, bool } from 'prop-types';

import {
    MarkdownEditor,
    markdownRenderer as defaultMarkdownRenderer,
} from '.';
import { get } from 'lodash';

export const MarkdownInput = ({
    markdownRenderer = defaultMarkdownRenderer,
    options = {},
    name,
    helperText,
    formClassName,
    onChange,
    value,
    error,
    className,
    id = 'markdown-editor',
    ...rest
}) => {
    const classes = useStyles(rest);

    return (
        <div
            className={classNames(formClassName, className)}
            {...rest}
            id={id}
            data-apim-test={id}
        >
            <div className={classes.editorContainer}>
                <FormLabel className={classes.labelContainer}>{name}</FormLabel>
                <div className={error ? classes.errorEditor : classes.editor }>
                    <MarkdownEditor
                        value={value}
                        markdownRenderer={markdownRenderer}
                        options={options}
                        onChange={onChange}
                    />
                    <FormHelperText className={classes.helpTextField} error={error} variant="filled" margin="dense">
                        {helperText}
                    </FormHelperText>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles(
    theme => ({
        editorContainer: {
            '& .rc-md-editor': {
                width: '100%',
                height: '35vh',
            },
            '& .MuiTypography-colorTextPrimary': {
                color: `${get(theme, 'color.bodyText')} !important`,
            },
            '& table': {
                fontSize: '14px',
                lineHeight: '1.7',
                maxWidth: '100%',
                overflow: 'auto',
                border: '1px solid #f6f6f6',
                borderCollapse: 'collapse',
                borderSpacing: 0,
                boxSizing: 'border-box',
            },
            '& table th': {
                textAlign: 'center',
                fontWeight: 700,
                border: '1px solid #efefef',
                padding: '10px 6px',
                backgroundColor: '#f5f7fa',
                wordBreak: 'break-word',
            },
            '& table td': {
                border: '1px solid #efefef',
                textAlign: 'left',
                padding: '10px 15px',
                wordBreak: 'break-word',
                minWidth: '60px',
            },
            '& code': {
                whiteSpace: 'pre-wrap',
            },
        },
        errorEditor: {
            '& .rc-md-editor': {
                borderColor: get(theme, 'color.error'),
            },
        },
        editor: {
            '& .rc-md-editor': {
                borderColor: get(theme, 'color.gray'),
            },
        },
        labelContainer: {
            marginBottom: theme.spacing(2),
        },
    }),
);

MarkdownInput.propTypes = {
    markdownRenderer: object,
    options: object,
    name: string,
    helperText: string,
    formClassName: string,
    onChange: func,
    value: string,
    error: bool,
    className: object,
    id : string,
};
