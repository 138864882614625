import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { get, map } from 'lodash';
import { arrayOf, bool, func, object, oneOfType } from 'prop-types';

import { fetchTags } from '../../../../actions/organization';
import { getI18n } from '../../../../utils/intl';
import { FormAutoCompleteCombo } from '../../../../components';

export const FilterByTag = (props) => {
  const {
    config = {},
    isUpdatingOrganizationTags,
    fetchTags,
    defaultOptions = [],
  } = props;
  const intl = getI18n(useIntl());
  const [options, setOptions] = useState(defaultOptions);
  const [name, setName] = useState('');
  const setResponse = props.setResponse ||
    ((response) => setOptions(get(response, 'results')));

  useEffect(() => {
    if(!isUpdatingOrganizationTags) {
      fetchTags({ config, name, setResponse });
    }
  }, [isUpdatingOrganizationTags, name]);

  const onChange = (e, newValue) => {
    props.handleChange(map(newValue, ({ name }) => (name)));
  }

  return (
    <Grid item md={3} sm={3} xs={12}>
      <FormAutoCompleteCombo
        {...props}
        id={'organizations-filter-by-tags'}
        inputLabel={intl.getI18nMessage('label.search.tag.placeholder')}
        options={options}
        value={map(props.value, (name) => ({ name }))}
        onChange={onChange}
        searchText={name}
        onSearchTextChange={(e) => {setName(e.target.value)}}
        noMargin
        hideHelper
        multiple
      />
    </Grid>
  );
}

FilterByTag.propTypes = {
  config: object,
  isUpdatingOrganizationTags: bool,
  handleChange: func,
  value: oneOfType([object, arrayOf(object)]),
  defaultOptions: arrayOf(object),
  fetchTags: func,
  setResponse: func,
};

export default (props) => (
  <FilterByTag
    {...props}
    fetchTags={fetchTags}
  />
);
