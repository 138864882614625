import React from 'react';
import {
  FormLabel,
  FormHelperText,
  Button,
  ButtonGroup,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './styles';

export default function FormButtonGroup(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    data = [],
    helperText,
    handleChange,
    error = false,
    id,
    value,
    disabled = false,
    filedContainerClass,
  } = props;

  const isSelected = (uuid) => (uuid === null && value === undefined) || (uuid === value);

  return (
    <div className={filedContainerClass || classes.fieldContainer} id={id}>
      <FormLabel className={classes.formLabel}>
        {props.name}
      </FormLabel>
      <ButtonGroup
        className={classes.buttonGroupField}
        disabled={disabled}
      >
        {data && data.map(({ uuid, name }) =>
          <Button
            key={uuid}
            data-value={uuid}
            variant={isSelected(uuid) ? 'contained' : 'outlined'}
            color="secondary"
            onClick={(e) => {
              handleChange(e.currentTarget.dataset.value);
            }}
            classes={{
              contained: classes.contained,
            }}
          >
            {name}
          </Button>,
        )}
      </ButtonGroup>
      <FormHelperText
        error={error}
      >
        {helperText}
      </FormHelperText>
    </div>
  );
}

FormButtonGroup.propTypes = {
  name: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  helperText: PropTypes.string,
  handleChange: PropTypes.func,
  error: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  filedContainerClass: PropTypes.string,
};
