import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { makeStyles, MenuList, MenuItem, Paper, Popper,
  ClickAwayListener, Grow } from '@material-ui/core';
import styles from './styles';
import {
  TOP_3_APIS,
  TOP_3_APPS,
  TOP_3_ORGS,
  TOP_3_PROXIES,
  CHART,
  MOVE_PANEL_DOWN,
  MOVE_PANEL_UP,
  COLLAPSE_PANEL,
  EXPAND_PANEL,
} from '../labels';

export default function PanelDropdown(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [anchorEl = false, setAnchorEl] = useState(null);
  const top3EntitiesMap = {
    api: TOP_3_APIS,
    org: TOP_3_ORGS,
    proxy: TOP_3_PROXIES,
    app: TOP_3_APPS,
  };
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleChart = () => {
    handleClose();
    props.onToggleChartEnabled(props.panelName);
  };
  const toggleTop3Entities = () => {
    handleClose();
    props.onToggleTop3Entities();
  };
  const setPanelCollapsed = () => {
    handleClose();
    props.setCollapsed(props.panelName);
  };
  const movePanel = (isUpward) => {
    handleClose();
    props.onMovePanel(props.chartEntity, isUpward);
  };
  return (
    <div className={classes.rootPanel}>
      <IconButton
        onClick={handleClick}
        className={classes.DropDownIcon}
      >
        {anchorEl ? (
          <ExpandLessIcon className={classes.DropDownLabel} />
        ) : (
          <ExpandMoreIcon className={classes.DropDownLabel} />
        )}
      </IconButton>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        className={classes.Popper}
        role={undefined}
        transition
        placement={'bottom-end'}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-end' ? 'center top' : 'center bottom',
              zIndex: 50,
            }}
          >
            <Paper
              classes={{
                root: classes.MenuPaper,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {!props.isCollapsed && false && (
                    // false &&
                    // Hiding Top 3 Entities Menu till other entries like
                    // favorites and recents arrive.
                    <MenuItem
                      className={classes.MenuItem}
                      onClick={() => toggleTop3Entities()}
                    >
                      {top3EntitiesMap[props.chartEntity]}
                      {props.isTop3EntitiesSelected ? (
                        <CheckIcon className={classes.CheckIcon} />
                      ) : null}
                    </MenuItem>
                  )}
                  {!props.isCollapsed && props.isAnalyticsEnabled && (
                    <MenuItem
                      className={classes.MenuItem}
                      onClick={() => toggleChart()}
                    >
                      <span>{CHART}</span>
                      {props.isChartEnabled ? (
                        <CheckIcon className={classes.CheckIcon} />
                      ) : null}
                    </MenuItem>
                  )}
                  <MenuItem
                    className={classes.MenuItem}
                    onClick={() => movePanel(true)}
                    disabled={props.chartIndex === 0}
                  >
                    {MOVE_PANEL_UP}
                  </MenuItem>
                  <MenuItem
                    className={classes.MenuItem}
                    onClick={() => movePanel(false)}
                    disabled={props.chartIndex === props.chartsCount - 1}
                  >
                    {MOVE_PANEL_DOWN}
                  </MenuItem>
                  <MenuItem
                    className={classes.MenuItem}
                    onClick={() => setPanelCollapsed()}
                  >
                    {props.isCollapsed ? EXPAND_PANEL : COLLAPSE_PANEL}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

PanelDropdown.propTypes = {
  isCollapsed: PropTypes.bool,
  onMovePanel: PropTypes.func,
  chartEntity: PropTypes.string,
  chartIndex: PropTypes.number,
  chartsCount: PropTypes.number,
  isTop3EntitiesSelected: PropTypes.bool,
  isChartEnabled: PropTypes.bool,
  isAnalyticsEnabled: PropTypes.bool,
  onToggleChartEnabled: PropTypes.func,
  panelName: PropTypes.string,
  onToggleTop3Entities: PropTypes.func,
  setCollapsed: PropTypes.func,
};
