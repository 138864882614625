import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { number, bool, func, shape, arrayOf, string } from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';

import { fetchGatewayBundlesApis } from '../../../../actions/gatewayBundle';
import {
  getGatewayBundlesApisListTotalPages,
  getGatewayBundlesApisListTotalElements,
  getGatewayBundlesApisListResults,
} from '../../../../reducers/gatewayBundle';
import styles from './styles';
import ListContainer from '../../../list';
import {
  GRID_ROWS_PER_PAGE_OPTIONS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
 } from '../../../../constants';
import { FilterByApiName, FilterByProxyName, FilterByStates, SortBy, FilterAndSortSeparator } from './controls';
import getDateTime, { getTimeZoneAbbr } from '../../../../utils/datetime';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';

const GATEWAY_BUNDLES_LIST_COLUMNS = [{
  id: 'apiName',
  label: getI18nFormattedMessage('label.api.name'),
  minWidth: 100,
  link: '/publish/apis/details',
}, {
  id: 'apiVersion',
  label: getI18nFormattedMessage('label.api.version'),
  minWidth: 50,
}, {
  id: 'apiProxies',
  label: getI18nFormattedMessage('label.api.deployed.proxies'),
  minWidth: 50,
}, {
  id: 'apiModifyTs',
  label: getI18nFormattedMessage('label.api.date.modified', { zone: getTimeZoneAbbr() }),
  format: value => getDateTime(value),
  minWidth: 50,
}, {
  id: 'apiPortalStatus',
  label: getI18nFormattedMessage('label.api.state'),
  minWidth: 50,
}];

export const ApiListRaw = (props) => {
  const {
    bundleUuid,
    classes,
    isLoading,
    totalPages,
    totalElements = 0,
    results = [],
  } = props;

  const intl = getI18n(useIntl());
  const [filterByName, setFilterByName] = useState('');
  const [filterByProxyName, setFilterByProxyName] = useState('');
  const [filterByState, setFilterByState] = useState('all');
  const [filterByNameApplied, setFilterByNameApplied] = useState(false);
  const [sortBy, setSortBy] = useState('apiModifyTs,DESC');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);

  const emptyFunction = () => {};

  useEffect(() => {
    if (bundleUuid) {
      props.fetchGatewayBundlesApis(bundleUuid, filterByName, filterByProxyName,
        filterByState, sortBy, page, rowsPerPage);
    }
  }, []);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilterByNameApplied(true);
      props.fetchGatewayBundlesApis(bundleUuid, filterByName, filterByProxyName,
        filterByState, sortBy, page, rowsPerPage);
    }
  };

  const onFilterByProxyNameChange = (value) => {
    setFilterByProxyName(value);
  };

  const onFilterByProxyNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilterByNameApplied(true);
      props.fetchGatewayBundlesApis(bundleUuid, filterByName, filterByProxyName,
        filterByState, sortBy, page, rowsPerPage);
    }
  };

  const onFilterByStateChange = (newStateBy) => {
    setFilterByState(newStateBy);
    props.fetchGatewayBundlesApis(bundleUuid, filterByName, filterByProxyName,
      newStateBy, sortBy, page, rowsPerPage);
  };

  const onSortByChange = (newSortBy) => {
    setSortBy(newSortBy);
    props.fetchGatewayBundlesApis(bundleUuid, filterByName, filterByProxyName,
      filterByState, newSortBy, page, rowsPerPage);
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchGatewayBundlesApis(bundleUuid, filterByName, filterByProxyName,
      filterByState, sortBy, newPage, rowsPerPage);
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchGatewayBundlesApis(bundleUuid, filterByName, filterByProxyName,
      filterByState, sortBy, 0, newRowsPerPage);
  };

  const noResultsMessage = filterByNameApplied ?
    `${intl.getI18nMessage('label.gateway.bundle.apis.filter.no.results')}` :
    intl.getI18nMessage('label.gateway.bundle.apis.no.results');

  return (
    <ListContainer
      listPageId="gateway-bundle-apis-list"
      isLoading={isLoading}
      notificationId="gateway-bundle-notifications"
      notificationStatus={''}
      setNotificationStatus={emptyFunction}
      notificationMessage={''}
      setNotificationMessage={emptyFunction}
      pageHeaderTitle={''}
      pageHeaderTooltipTitle={''}
      addButtonLabel={''}
      onAddBundle={emptyFunction}
      showListHeader={false}
      pageClass={classes.listContent}
      pageBodyClass={classes.pageBodyClass}
      pageFilterAndSortClass={classes.pageFilterAndSortClass}
      filterAndSortContent={(
        <Fragment>
          <FilterByApiName
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            value={filterByName}
            placeholder={intl.getI18nMessage('label.gateway.bundle.apis.filter.by.name.placeholder')}
            handleChange={onFilterByNameChange}
            onKeyPress={onFilterByNameKeyPress}
          />
          <FilterByProxyName
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            value={filterByProxyName}
            placeholder={intl.getI18nMessage('label.gateway.bundle.apis.filter.by.proxy.name.placeholder')}
            handleChange={onFilterByProxyNameChange}
            onKeyPress={onFilterByProxyNameKeyPress}
            hideLabel
          />
          <FilterByStates
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            selectFieldClass={classes.selectField}
            value={filterByState}
            handleChange={onFilterByStateChange}
            hideLabel
          />
          <FilterAndSortSeparator />
          <SortBy
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.sort.by')}
            value={sortBy}
            handleChange={onSortByChange}
          />
        </Fragment>
     )}
      columns={GATEWAY_BUNDLES_LIST_COLUMNS}
      rows={results}
      noResultsMessage={noResultsMessage}
      page={page}
      totalElements={totalElements}
      totalPages={totalPages}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onChangePreviousPage={onChangePreviousPage}
      onChangeNextPage={onChangeNextPage}
    />
  );
};

ApiListRaw.propTypes = {
  classes: shape({}),
  isLoading: bool,
  totalPages: number,
  totalElements: number,
  results: arrayOf(shape({})),
  fetchGatewayBundlesApis: func,
  bundleUuid: string,
};

const mapStateToProps = (state) => ({
  isLoading: false,
  totalPages: getGatewayBundlesApisListTotalPages(state),
  totalElements: getGatewayBundlesApisListTotalElements(state),
  results: getGatewayBundlesApisListResults(state),
});

const mapDispatchToProps = {
  fetchGatewayBundlesApis,
};
ApiListRaw.displayName = 'Apis';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ApiListRaw);
