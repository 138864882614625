import {
  withStyles,
} from '@material-ui/core';

import Paper from './paper';
import PaperWithStatus from './paperWithStatus';
import { TableHead, TableRow } from './table';
import styles from './styles';

export const OverviewPaper = (
  withStyles(styles)
)(Paper);

export const OverviewPaperWithStatus = (
  withStyles(styles)
)(PaperWithStatus);

export const OverviewPaperWithStatusTableHead = (
  withStyles(styles)
)(TableHead);

export const OverviewPaperWithStatusTableRow = (
  withStyles(styles)
)(TableRow);
