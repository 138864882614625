import React, { useState } from 'react';
import { Switch, withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import cx from 'classnames';

import styles from './index.theme';

type Props = {
  classes: Object,
  checked: Boolean,
  id: String,
  onChange: Function,
  disabled: Boolean,
};

const SwitchRaw = (props: Props) => {
  const { classes, checked, disabled, id, onChange } = props;
  const [ hasFocus, setHasFocus ] = useState(false);
  const setFocus = () => setHasFocus(true);
  const clearFocus = () => setHasFocus(false);
  let switchBaseClasses = disabled ?
    cx(classes.switchBase, classes.switchBaseDisabled) : classes.switchBase;
  switchBaseClasses = hasFocus ?
    cx(switchBaseClasses, classes.switchBaseFocused) : switchBaseClasses;
  const handleBtnKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onChange(!checked, id);
    }
  };
  return (
    <Switch
      onFocus={setFocus}
      onBlur={clearFocus}
      checked={checked}
      data-apim-test={id}
      disabled={disabled}
      onChange={(e) => onChange(e.target.checked, id)}
      onKeyDown={handleBtnKeyDown}
      classes={{
        root: hasFocus ? cx(classes.root, classes.rootFocused) : classes.root,
        switchBase: switchBaseClasses,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
    />
  );
};

export default compose(
  withStyles(styles),
)(SwitchRaw);
