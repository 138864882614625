import React, { Fragment, useState } from 'react';
import {
  ClickAwayListener,
  Grow,
  Badge,
  IconButton,
  MenuList,
  MenuItem,
  Paper,
  Popper,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { isEmpty, get, isArray, sumBy } from 'lodash';
import { getI18nFormattedMessage } from '../../../utils/intl';
import clsx from 'clsx';
import PropTypes from 'prop-types';


import {
  PROXY_PORTAL_API_TYPE,
  PROXY_KEY_TYPE,
  PROXY_GATEWAY_API_TYPE,
  PROXY_CERTIFICATE_EXPIRED_TYPE,
  PROXY_CERTIFICATE_EXPIRING_TYPE,
} from '../../../constants';

const renderProxyItem = (proxyStatus,i, classes) => {
  const name = get(proxyStatus, 'name');
  const type = get(proxyStatus, 'type') ? `${get(proxyStatus, 'type')}`: '';
  const count = get(proxyStatus, 'count') ? `${get(proxyStatus, 'count')}`: '';
  const label = `${name}`;
  const url = `//${window.location.hostname}/publish/api-proxy/details/${proxyStatus.uuid}`;
  let proxyUrl = url;
  let errorType = getI18nFormattedMessage('label.header.proxy.connection.error');
  if (type === PROXY_KEY_TYPE) {
    proxyUrl = `${url}/keys?status=ERROR`;
    errorType = getI18nFormattedMessage('label.header.proxy.key.error');
  } else if(type === PROXY_PORTAL_API_TYPE || type === PROXY_GATEWAY_API_TYPE) {
    proxyUrl = `${url}/apis?status=ERROR`;
    errorType = getI18nFormattedMessage('label.header.proxy.api.error');
  } else if(type === PROXY_CERTIFICATE_EXPIRED_TYPE) {
    proxyUrl = `${url}/certificates`;
    errorType = getI18nFormattedMessage('label.header.proxy.certificate.expired.error');
  } else if(type === PROXY_CERTIFICATE_EXPIRING_TYPE) {
    proxyUrl = `${url}/certificates`;
    errorType = getI18nFormattedMessage('label.header.proxy.certificate.expiring.warning');
  }
  let badgeClass = classes.BadgePrimary;
  if (type === PROXY_CERTIFICATE_EXPIRING_TYPE) {
    badgeClass = classes.BadgeWarning;
  }
  return (
    <div key={`${name}_${i}`} className={classes.menuContainer}>
      <MenuItem classes={{
        root: classes.menuItemRoot,
      }}>
        <a
          className={clsx(classes.HeaderRequestsMenuItem, classes.Capitalize)}
          href={proxyUrl}
          key={`${proxyStatus.uuid}-${i}`}
          data-apim-test={`${proxyStatus.uuid}-${i}`}
        >
          {label}
        </a>
        <div className={classes.errorText}>{errorType}</div>
    </MenuItem>
    {type &&
      <Badge
        badgeContent={count}
        color="primary"
        classes={{
          root: classes.count,
          colorPrimary: badgeClass,
        }}
      />
    }
   </div>
  );
};
  
const renderProxyItems = (proxyItems, classes) =>
  proxyItems.map((item, i) => renderProxyItem(item, i, classes));

export const Status = (props) => {
  const {
    classes = {},
    proxyStatus = [],
    errors = [],
  } = props;
  
  const [anchorEl, setAnchorEl] = useState(false);
  const count = isArray(proxyStatus) ? sumBy(proxyStatus, 'count') : 0;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleListKeyDown = (event) => {
    if ((event.key === 'Tab') || (event.key === 'Escape')) {
        event.preventDefault();
        anchorEl.focus();
        setAnchorEl(false);
    }
  };

  const handleBtnKeyDown = (event) => {
    if (event.key === 'Escape') {
        event.preventDefault();
        setAnchorEl(false);
    } else if ((isEmpty(proxyStatus)) && (event.key === 'Tab')) {
        setAnchorEl(false);
    }
  };
  return (
    <Fragment>
        <IconButton
        onKeyDown={handleBtnKeyDown}
        onClick={handleClick}
        data-apim-test="header-proxy-status"
        >
            <Badge
                badgeContent={count}
                color="primary"
                classes={{
                colorPrimary: classes.BadgePrimary,
                }}
            >
                {!anchorEl ?
                    <CheckCircleIcon
                    className={count ? clsx(classes.NotificationsIcon, classes.error)
                        : clsx(classes.NotificationsIcon, classes.success)}
                    />
                    :
                    <ExpandLessIcon className={classes.NotificationsIcon} />
                }
            </Badge>
        </IconButton>
        <Popper
            open={!!anchorEl}
            anchorEl={anchorEl}
            role={undefined}
            transition
            className={classes.Popper}
            disablePortal
            placement={'bottom-end'}
            modifiers={{
                preventOverflow: {
                enabled: true,
                boundariesElement: 'viewport',
                },
            }}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{
                    transformOrigin:
                    placement === 'bottom-end' ? 'center top' : 'center bottom',
                }}
                >
                <div>
                    <Paper
                        classes={{
                        root: classes.MenuPaper,
                        }}
                    >
                        <ClickAwayListener onClickAway={handleClose}>
                        <div className={classes.Menu}>
                            <h5
                              className={classes.title}
                              data-apim-test="proxy-status-title"
                            >
                             {getI18nFormattedMessage('label.header.proxy.errors')}
                            </h5>
                            {isEmpty(errors) && isEmpty(proxyStatus) &&
                            <div className={classes.message} data-apim-test={'no-proxy-errors'}>
                               {getI18nFormattedMessage('label.none')}
                            </div>
                            }
                        
                            {isEmpty(errors) && !isEmpty(proxyStatus) &&
                            <MenuList
                                classes={{
                                root: classes.Menu,
                                }}
                                onKeyDown={handleListKeyDown}
                            >
                                {renderProxyItems(proxyStatus, classes)}
                            </MenuList>
                            }
                        </div>
                        </ClickAwayListener>
                    </Paper>
                </div>
                </Grow>
            )}
        </Popper>
    </Fragment>
  );
};

Status.propTypes = {
    classes: PropTypes.shape(),
    proxyStatus: PropTypes.arrayOf(PropTypes.shape()),
    errors: PropTypes.arrayOf(PropTypes.shape()),
  };
  
export default Status;