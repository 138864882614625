import { get } from 'lodash';

const styles = theme => ({
  formControl: {
    fontSize: 12,
  },
  formLabel: {
    color: `${get(theme, 'color.bodyText')} !important`, // avoiding overwrite by white color
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.title'),
    fontWeight: 700,
    marginBottom: 10,
    '& span': {
      verticalAlign: 'middle',
    },
  },
  radioLabel: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.bodyText'),
    fontWeight: 700,
  },
  helpIcon: {
    color: get(theme, 'color.link'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.title'),
    marginLeft: 10,
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  radio: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.bodyText'),
    fontWeight: 700,
    '& svg': {
      width: 16,
      height: 16,
    },
  },
});

export default styles;
