import { get } from 'lodash';

const styles = (theme) => ({
  fieldContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formLabel: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontWeight: 700,
    paddingLeft: 0,
  },
  buttonGroupField: {
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.bodyText'),
    width: '99%',
    borderRadius: 4,
    padding: '10px 0',
    '& .MuiButton-root': {
      textTransform: 'none',
    },
    '& .MuiButton-outlined': {
      color: get(theme, 'color.bodyText'),
      borderColor: get(theme, 'color.borderColor'),
    },
  },
  contained: {
    color: get(theme, 'color.primaryButtonText'),
    backgroundColor: get(theme, 'color.primaryButtonBackground'),
  },
  helperText: {
    color: `${get(theme, 'color.smallText')} !important`,
    fontFamily: `${get(theme, 'typography.smallText')} !important`,
    fontSize: `${get(theme, 'fontSize.smallText')} !important`,
  },
});

export default styles;
