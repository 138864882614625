import React, { useState, useEffect } from 'react';
import { withStyles, TextField, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Autocomplete from '@material-ui/lab/Autocomplete';
import match from 'autosuggest-highlight/umd/match';
import parse from 'autosuggest-highlight/umd/parse';
import defaultTheme from './index.theme';

function SearchBar(props) {
  const {
    autoSuggestSuggestions = [],
    classes,
    dispatchAutoSuggest,
    fetchRecentSearch,
    handleSelection = () => {},
    handleTextChange = () => {},
    handleInputChange = () => {},
    inputValue = '',
    placeHolderText,
    recentSearches = [],
    selectedOption = {},
  } = props;
  const [showAutoSuggestions, setShowAutoSuggestions] = useState(false);
  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    if (hasFocus) {
      if (!inputValue) {
        setShowRecentSearches(true);
        setShowAutoSuggestions(false);
        if (fetchRecentSearch) {
          fetchRecentSearch();
        }
      } else if (inputValue.length < 3) {
        setShowRecentSearches(false);
        setShowAutoSuggestions(false);
      } else {
        setShowAutoSuggestions(true);
        setShowRecentSearches(false);
      }
    } else {
      setShowRecentSearches(false);
      setShowAutoSuggestions(false);
    }
  }, [hasFocus, inputValue]);

  const onInputChange = (event: Event) => {
    // getting null for event, looks like a bug in component
    if (!event) {
      return;
    }
    // ignoring option selection
    if (event.type === 'click') {
      return;
    }
    const { value: newValue } = event && event.target;
    handleInputChange(newValue);
    if (newValue && newValue.length >= 3) {
      dispatchAutoSuggest(newValue);
    }
  };

  const onSuggestionSelected = (event: Event, suggestionValue) => {
    handleSelection(suggestionValue);
  };

  const renderOption = (option) => {
    const { value: name } = option;
    const matches = match(name, inputValue);
    const parts = parse(name, matches);

    return (
      <div data-apim-test="search-bar-options-container">
        {parts.map((part) => {
          const className = part.highlight ?
            classes.suggestionText__highlightedText : classes.suggestionText__Text;
          return (
            <span key={part.text} className={className}>{part.text}</span>
          );
        })}
      </div>
    );
  };

  const getOptionSelected = (option, valueParam) => (option.key === valueParam.key);

  const getOptionLabel = (option) => (option && option.value) || '';

  const onFocus = () => {
    setHasFocus(true);
  };

  const onBlur = () => {
    setHasFocus(false);
    handleTextChange();
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      setShowRecentSearches(false);
      setShowAutoSuggestions(false);
      handleTextChange();
      event.preventDefault();
    }
  };

  let suggestions = [];
  if (showAutoSuggestions) {
    suggestions = autoSuggestSuggestions;
  } else if (showRecentSearches) {
    suggestions = recentSearches;
  }

  const renderInput = (params) => (
    <TextField
      data-apim-test="search-bar-textfield"
      {...params}
      classes={{
        root: classes.autoCompleteTFRoot,
      }}
      fullWidth
      inputProps={{
        ...params.inputProps,
        maxLength: 255,
      }}
      margin="none"
      placeholder={placeHolderText}
    />
  );

  const renderRecentSeachesTitle = () =>
    (<p className={classes.recentSearches}>
      <span>Recent Searches</span>
    </p>);

  const renderPaper = (params) => {
    const { children, ...paperProps } = params;
    return (
      <Paper {...paperProps}>
        {showRecentSearches && renderRecentSeachesTitle()}
        {children}
      </Paper>
    );
  };

  const showPopper = showAutoSuggestions || (fetchRecentSearch && showRecentSearches);

  return (
    <Autocomplete
      classes={{
        root: classes.autoCompleteRoot,
        focused: classes.autoCompleteFocused,
        inputRoot: classes.autoCompleteInputRoot,
        input: classes.autoCompleteInput,
        popupIndicator: classes.autoCompleteHidden,
        paper: classes.autoCompletePaper,
        popper: showPopper ?
          classes.autoCompleteShow : classes.autoCompleteHidden,
      }}
      disableClearable
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      inputValue={inputValue}
      onChange={onSuggestionSelected}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      onInputChange={onInputChange}
      options={suggestions}
      PaperComponent={renderPaper}
      renderInput={renderInput}
      renderOption={renderOption}
      size="small"
      value={selectedOption}
    />
  );
}

SearchBar.propTypes = {
  autoSuggestSuggestions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
  classes: PropTypes.object,
  dispatchAutoSuggest: PropTypes.func,
  fetchRecentSearch: PropTypes.func,
  handleSelection: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  inputValue: PropTypes.string,
  placeHolderText: PropTypes.string,
  recentSearches: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
  selectedOption: PropTypes.object,
};

export default compose(
    withStyles(defaultTheme),
  )(SearchBar);
