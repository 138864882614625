import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { get, map, isEmpty } from 'lodash';
import clsx from 'clsx';
import { bool, object, arrayOf, func, string } from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Collapse,
  makeStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  AlertDialog,
} from '../../../../components';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import styles from './styles';
import {
  ALERT_ERROR,
  PROXY_ENROLMENT_STATUS_ACTIVE,
} from '../../../../constants';

export const processErrors = (errors, defaultMessage) => {
  let errorMessage;
  if (errors.length > 0) {
    errorMessage = map(errors, (validationError) => validationError.error).join('\n');
  }
  return errorMessage || defaultMessage;
};

export const isNotLastActiveProxy = ({ enrollmentStatus, gateways }) => {
  if(enrollmentStatus === PROXY_ENROLMENT_STATUS_ACTIVE) {
    const activeProxies = gateways.filter(
      gateway => (get(gateway, 'EnrollmentStatus') === PROXY_ENROLMENT_STATUS_ACTIVE),
    );
    return (activeProxies.length > 1);
  }
  return true;
}

export const getActions = ({
  props,
  proxyUuid,
  openDeleteDialog,
  enrollmentStatus,
  gateways,
}) => {
  const intl = getI18n(useIntl());
  // Proxy Delete not allowed if it is the last Active Proxy
  const isProxyDeleteNotAllowed = !isNotLastActiveProxy({ enrollmentStatus, gateways });
  return [
    {
      key: 'edit',
      value: intl.getI18nMessage('label.proxy.details.edit.button'),
      onClick: () => {
        props.push(`/publish/api-proxy/edit/${proxyUuid}`);
      },
      visible: true,
      disabled: false,
    },
    {
      key: 'delete',
      value: intl.getI18nMessage('label.proxy.details.delete.button'),
      onClick: () => {
        if(isProxyDeleteNotAllowed) { return; }
        openDeleteDialog();
      },
      visible: true,
      disabled: isProxyDeleteNotAllowed,
    },
  ];
}

export const ActionsRaw = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    notifyMessages,
    deleteProxyErrors,
    isProxyDeleteSuccess,
    proxyUuid,
    enrollmentStatus,
    gateways,
  } = props;

  const intl = getI18n(useIntl());
  const [open, setOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleClick = () => { setOpen(!open); };
  const handleClickAway = () => { setOpen(false); };
  const openDeleteDialog = () => setShowDeleteDialog(true);
  const closeDeleteDialog = () => setShowDeleteDialog(false);

  const onDelete = () => {
    props.deleteProxy(proxyUuid);
    closeDeleteDialog();
  };

  const actions = getActions({
    props,
    proxyUuid,
    openDeleteDialog,
    enrollmentStatus,
    gateways,
  });

  useEffect(() => {
    if (isProxyDeleteSuccess) { window.location.href = '/admin/api-proxy'; }
  }, [isProxyDeleteSuccess]);

  useEffect(() => {
    if (!isEmpty(deleteProxyErrors)) {
      notifyMessages(
        ALERT_ERROR,
        processErrors(deleteProxyErrors, intl.getI18nMessage('error.proxy.delete')),
      );
    }
  }, [deleteProxyErrors]);

  return (
    <div
      id="proxy-details-actions-container"
      data-apim-test={'proxy-details-actions-container'}
      className={classes.actionsContainer}
    >
      {showDeleteDialog &&
        <AlertDialog
          id="proxy-details-delete-dialog"
          isOpen={showDeleteDialog}
          title={intl.getI18nMessage('label.delete.confirmation.title')}
          description={intl.getI18nMessage('label.proxy.details.delete.confirmation.text')}
          submitText={intl.getI18nMessage('label.delete.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={closeDeleteDialog}
          onSubmit={onDelete}
          onCancel={closeDeleteDialog}
          submitButtonClass={classes.submitButton}
        />
      }
      <ClickAwayListener onClickAway={handleClickAway}>
        <List component="nav">
          <ListItem id="proxy-details-actions-button" data-apim-test="proxy-details-actions-button" classes={{ root: classes.listButton }} button onClick={handleClick}>
            <ListItemText primary={getI18nFormattedMessage('label.actions.button')} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse className={classes.collapseContainer} in={open}>
            <List className={classes.list} component="div" disablePadding>
              {actions
                .filter(({ visible }) => visible)
                .map(({ key, value, disabled, onClick }) => (
                  <ListItem
                    key={key}
                    id={key} data-apim-test={key} data-layer7-test={key}
                    button
                    className={clsx('action', classes.listItem)}
                    disabled={disabled}
                    onClick={onClick}
                  >
                    <ListItemText primary={value} />
                  </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </ClickAwayListener>
    </div>
  );
};

ActionsRaw.propTypes = {
  notifyMessages: func,
  proxyUuid: string,
  deleteProxyErrors: arrayOf(object),
  isProxyDeleteSuccess: bool,
  enrollmentStatus: string,
  gateways: arrayOf(object),
  deleteProxy: func,
};
ActionsRaw.displayName = 'Actions';

export default ActionsRaw;
