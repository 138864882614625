import modifyColour from '../../../../utils/modifyColour';
import get from 'lodash/get';

export default ({ color, typography, fontSize, misc }) => ({
  HeaderNavLink: {
    display: 'block',
    padding: '7px 15px 7px 0',

    '& a': {
      color: get(color, 'headerLink'),
      display: 'inline-block',
      fontSize: get(fontSize, 'bodyText'),
      fontFamily: get(typography, 'bodyText'),
      textDecoration: 'none',
      transition: get(misc, 'colorTransition'),

      '&:hover': {
        color: modifyColour(get(color, 'headerLink'), 'lighten', 0.4),
      },
    },
  },

  [`@media (min-width: ${get(misc, 'breakpointMedium')}px)`]: {
    HeaderNavLink: {
      display: 'inline-block',
    },
  },
});
