import colorMod from 'color';
import { get } from 'lodash';

export default ({ color, fontSize, misc, typography }) => ({
  HeaderBrandingWrapper: {
    color: get(color, 'title'),
    textDecoration: 'none',
    '&:focus': {
      outline: 'none',
      '& span': {
        color: get(color, 'headerLink'),
        outline: 'none',
        textDecoration: 'underline',
        transition: 'none',
      },
    },
  },

  HeaderBrandingLogo: {
    alignItems: 'center',
    display: 'flex',
  },

  HeaderBrandingLogoImageWrapper: {
    padding: 5,
    blockSize: '38px',
    boxSizing: 'content-box',
  },

  HeaderBrandingLogoImage: {
    width: 'auto',
    height: 38,
  },

  HeaderBrandingSiteTitle: {
    color: get(color, 'siteTitle'),
    marginLeft: 8,
    fontFamily: get(typography, 'siteTitle'),
    fontSize: get(fontSize, 'siteTitle'),
    fontStyle: 'normal',
    fontWeight: 600,
    height: '100%',

    '&:hover': {
      color: get(color, 'headerLink'),
      textDecoration: 'none',
    },

    '&:active': {
      color: colorMod(get(color, 'headerLink')).alpha(0.75).string(),
      textDecoration: 'none',
    },

    '&:focus': {
      color: get(color, 'headerLink'),
      transition: 'none',
      outline: 'none',
      textDecoration: 'underline',
    },

    [`@media screen and (max-width: ${get(misc, 'breakpointSmall')}px)`]: {
      display: 'none',
    },
  },
});
