import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { capitalCase } from 'change-case';
import { get } from 'lodash';
import {
  withStyles,
  Button,
  Card,
  CardContent,
  Link,
  Typography,
  Divider,
  Tooltip,
 } from '@material-ui/core';
import LensIcon from '@material-ui/icons/Lens';
import HelpIcon from '@material-ui/icons/Help';

import AlertDialog from '../../../../../components/AlertDialog';
import { getDeployedStatusColor, getDeployedStatusLabel } from '../../../../../utils/deployment';
import {
  formatStatusMessage,
  canAPIPerformAction,
  hasAdminRole,
} from '../../../../../utils';
import {
  DEPLOYMENT_STATUS_ERROR,
  DEPLOYMENT_ACTION_TYPE_DEPLOY,
  DEPLOYMENT_ACTION_TYPE_REDEPLOY,
  DEPLOYMENT_ACTION_TYPE_UNDEPLOY,
  DEPLOYMENT_STATUS_NOT_DEPLOYED,
  DEPLOYMENT_STATUS_UNDEPLOYED_ERROR,
  PROXY_DEPLOYMENT_TYPE_ON_DEMAND,
  API_STATUS_INCOMPLETE,
} from '../../../../../constants';
import styles from '../styles';
import getDateTime from '../../../../../utils/datetime';
import { getI18n, getI18nFormattedMessage } from '../../../../../utils/intl';

export const DeploymentsCard = ({
  index,
  userContext,
  canEdit,
  apiDetails,
  details: {
    apiUuid,
    proxyUuid,
    name,
    type,
    status = DEPLOYMENT_STATUS_NOT_DEPLOYED,
    statusMessage,
    modifyTs,
  },
  errorObj = {},
  refreshTime = 10,
  deployApi,
  redeployApi,
  undeployApi,
  checkStatus,
  classes,
}) => {
  const intl = getI18n(useIntl());
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState('');
  const [updating, setUpdating] = useState(false);
  const [timer, setTimer] = useState(parseInt(refreshTime, 10));
  let lastModified = modifyTs ?
    getDateTime(modifyTs) : getI18nFormattedMessage('label.not.applicable');
  const errorMessage = get(errorObj, 'errorMessage');

  const startAction = (action) => {
    switch (action) {
      case DEPLOYMENT_ACTION_TYPE_DEPLOY: {
        deployApi(apiUuid, proxyUuid);
        break;
      }
      case DEPLOYMENT_ACTION_TYPE_REDEPLOY: {
        redeployApi(apiUuid, proxyUuid);
        break;
      }
      case DEPLOYMENT_ACTION_TYPE_UNDEPLOY: {
        undeployApi(apiUuid, proxyUuid);
        break;
      }
      default: {
        break;
      }
    }
    setUpdating(!updating);
  };

  const stopAction = () => {
    setTimer(refreshTime);
    setUpdating(false);
    checkStatus(proxyUuid);
  };

  useEffect(() => {
    let interval = null;
    if (updating) {
      interval = setInterval(() => {
        setTimer(timer - 1);
        if (timer === 1) {
          stopAction();
          clearInterval(interval);
        }
      }, 1000);
    } else if (!updating && timer !== refreshTime) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [updating, timer]);

  let statusLabel = '';
  let statusColor = '';
  if (errorMessage) {
    statusLabel = getI18nFormattedMessage('error.validation.error');
    statusColor = getDeployedStatusColor(DEPLOYMENT_STATUS_ERROR);
  } else {
    statusLabel = (status === DEPLOYMENT_STATUS_ERROR)
      ? getI18nFormattedMessage('error.deployment.error')
      : getDeployedStatusLabel(status);
    statusColor = getDeployedStatusColor(status);
  }
  lastModified = modifyTs ?
  getDateTime(modifyTs) : getI18nFormattedMessage('label.not.applicable');

  const statusStyle = {
    color: statusColor,
  };

  const openStatusDialog = () => setShowStatusDialog(true);
  const closeStatusDialog = () => setShowStatusDialog(false);

  const openConfirmDialog = (action) => {
    setShowConfirmDialog(true);
    setConfirmAction(action);
  };

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false);
    setConfirmAction('');
  };

  const saveConfirmDialog = () => {
    startAction(confirmAction);
    closeConfirmDialog();
  };

  const MoreInfo = () => (
    <span data-test={'moreInfoBtn'}>
      <Button onClick={() => openStatusDialog()}>
        {getI18nFormattedMessage('label.more.info')}
      </Button>
    </span>
  );

  const CheckStatus = () => (
    <span data-test={'checkStatusBtn'}>
      <Button onClick={() => stopAction()}>
        {getI18nFormattedMessage('label.check.now')}
      </Button>
    </span>
  );

  const DeploymentActions = () => (
    <span>
      {((status === DEPLOYMENT_STATUS_NOT_DEPLOYED) &&
         canAPIPerformAction(userContext, apiDetails, canEdit, 'CREATE')) &&
        <span data-test={'deployBtn'}>
          <Button onClick={() => openConfirmDialog(DEPLOYMENT_ACTION_TYPE_DEPLOY)}>
            {getI18nFormattedMessage('label.deploy')}
          </Button>
        </span>
      }
      {(status !== DEPLOYMENT_STATUS_NOT_DEPLOYED &&
        status !== DEPLOYMENT_STATUS_UNDEPLOYED_ERROR &&
        canAPIPerformAction(userContext, apiDetails, canEdit, 'UPDATE')) &&
          <span data-test={'redeployBtn'}>
            <Button onClick={() => openConfirmDialog(DEPLOYMENT_ACTION_TYPE_REDEPLOY)}>
              {getI18nFormattedMessage('label.redeploy')}
            </Button>
          </span>
      }
      {((status !== DEPLOYMENT_STATUS_NOT_DEPLOYED) &&
        canAPIPerformAction(userContext, apiDetails, canEdit, 'DELETE')) &&
        <span data-test={'undeployBtn'}>
          <Button onClick={() => openConfirmDialog(DEPLOYMENT_ACTION_TYPE_UNDEPLOY)}>
            {getI18nFormattedMessage('label.undeploy')}
          </Button>
        </span>
      }
    </span>
  );

  const renderHelpText = () => (
    <p className={classes.helpTextContainer}>
      <span className={classes.boldText}>Deployment Types</span><br />
      <span>Can be changed in proxy settings.</span><br />
      <span>
        <span className={classes.boldText}>&#8226; Automatic: </span>
        Deployed to proxy right away.
      </span>
      <br />
      <span>
        <span className={classes.boldText}>&#8226; On Demand: </span>
        API deployments can be triggered here manually or on-demand by calling the deployment APIs.
        These APIs can be accessed from the Portal APIs link in the navigation menu.
      </span>
      <br />
      <span>
        <span className={classes.boldText}>&#8226; Scripted: </span>
        API deployments can be integrated into your existing CICD workflow by
        leveraging the deployment APIs and invoking them from your deployment script.
      </span>
    </p>
  );

  return (
    <Fragment>
      {showStatusDialog &&
        <AlertDialog
          isOpen={showStatusDialog}
          title={intl.getI18nMessage('label.information')}
          description={errorMessage || formatStatusMessage(statusMessage)}
          submitText={intl.getI18nMessage('label.ok.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={() => closeStatusDialog()}
          onSubmit={() => closeStatusDialog()}
          onCancel={() => closeStatusDialog()}
          showCancel={false}
          dialogId="api-proxy-status-dialog"
        />
      }
      {showConfirmDialog &&
        <AlertDialog
          isOpen={showConfirmDialog}
          title={intl.getI18nMessage('warning.status')}
          description={intl.getI18nMessage('label.action.confirmation.text')}
          submitText={intl.getI18nMessage('label.ok.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={() => closeConfirmDialog()}
          onSubmit={() => saveConfirmDialog()}
          onCancel={() => closeConfirmDialog()}
          dialogId="api-proxy-action-dialog"
        />
      }
      <Card
        key={`api-deployment-card-${index}`}
        id={`api-deployment-card-${index}`}
        data-apim-test={'api-deployment-card'}
        className={classes.card}
      >
        <CardContent>
          <Typography>
            <span data-test={'proxy-title'} className={classes.linkWrapper}>
              {hasAdminRole(userContext) ? (
                <Link href={`/publish/api-proxy/details/${proxyUuid}`}>
                  <h4 className={classes.cardTitle}>{name}</h4>
                </Link>
              ) : (
                <h4 className={classes.cardTitle}>{name}</h4>
              )}
            </span>
          </Typography>
          <Typography className={classes.cardLastUpdated}>
            {intl.getI18nMessage('label.last.updated')}: {updating ? intl.getI18nMessage('label.updating') : lastModified}
          </Typography>
          <Divider />
          <Typography className={classes.cardType}>
            <span data-test={'deployment-type-label'}>{intl.getI18nMessage('label.deployment.type')}</span>
            <span>: {capitalCase(type)}</span>
            <Tooltip title={renderHelpText()} arrow placement="right">
              <HelpIcon className={classes.helpIcon} />
            </Tooltip>
          </Typography>
          <Typography component="div" className={classes.cardStatus}>
            {updating ?
              <span>
                <LensIcon className={classes.cardStatusIcon} />
                {` ${intl.getI18nMessage('label.updating.status.check.in')} ${timer}${intl.getI18nMessage('label.unit.seconds')}.`}
              </span> :
              <span>
                <span style={statusStyle}>
                  <LensIcon className={classes.cardStatusIcon} /> {statusLabel}
                </span>
              </span>
            }
            <div className={classes.deploymentButtonsContainer}>
            {(type === PROXY_DEPLOYMENT_TYPE_ON_DEMAND &&
                apiDetails.portalStatus !== API_STATUS_INCOMPLETE) &&
                <span data-test={'api-deployment-actions'}>
                  {updating ? <CheckStatus /> : <DeploymentActions />}
                </span>
              }
              {(!updating && (errorMessage || statusMessage)) &&
                <MoreInfo />
              }
            </div>
          </Typography>
        </CardContent>
      </Card>
    </Fragment>
  );
};

DeploymentsCard.propTypes = {
  index: PropTypes.number,
  userContext: PropTypes.object,
  canEdit: PropTypes.bool,
  apiDetails: PropTypes.object,
  details: PropTypes.shape({
    proxyUuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    statusMessage: PropTypes.string,
    modifyTs: PropTypes.number,
    apiUuid: PropTypes.string,
    type: PropTypes.string,
  }),
  errorObj: PropTypes.object,
  refreshTime: PropTypes.string,
  deployApi: PropTypes.func,
  redeployApi: PropTypes.func,
  undeployApi: PropTypes.func,
  checkStatus: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(DeploymentsCard);
