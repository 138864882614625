// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import theme from './index.theme';

const Branding = ({
  classes = {},
  homeHref,
  logo,
  serviceTitle,
  siteTitle,
}: Object) => (
  <a
    className={classes.HeaderBrandingWrapper}
    href={homeHref}
  >
    <div className={classes.HeaderBrandingLogo}>
      <img
        className={classes.HeaderBrandingLogoImage}
        alt="Logo"
        src={logo}
      />
      <div>
        <span className={classes.HeaderBrandingServiceTitle}>{serviceTitle}</span>
        <span className={classes.HeaderBrandingSiteTitle}>{siteTitle}</span>
      </div>
    </div>
  </a>
);

export default withStyles(theme)(Branding);
