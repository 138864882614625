import modifyColour from '../../../utils/modifyColour';
import { get } from 'lodash';

export default ({ color, fontSize, misc, typography }) => ({
  HeaderSettings: {
    zIndex: 100,
  },

  Menu: {
    fontFamily: get(typography, 'bodyText'),
    width: 225,
    padding: '10px 0 8px',
  },

  HeaderSettingsMenuItem: {
    display: 'block',
    color: get(color, 'link'),
    fontSize: get(fontSize, 'bodyText'),
    lineHeight: '1.2em',
    marginBottom: 10,
    textDecoration: 'none',
    transition: get(misc, 'colorTransition'),

    '&:hover': {
      color: modifyColour(get(color, 'link'), 'lighten', 0.4),
    },

    '&:last-child': {
      marginBottom: 0,
    },
  },

  HeaderSettingsButton: {
    display: 'none',
    '& button': {
      color: get(color, 'headerLink'),
    },
  },

  HeaderSettingsMenuWrapper: {
    padding: '10px 20px 7px',
  },

  SubHeader: {
    marginBottom: 15,
    color: get(color, 'gray'),
    fontSize: get(fontSize, 'smallText'),
  },

  SubHeaderUppercase: {
    textTransform: 'uppercase',
  },

  [`@media (min-width: ${get(misc, 'breakpointMedium')}px)`]: {
    HeaderSettingsButton: {
      display: 'inline-block',
    },
  },
});
